<mat-slider
class="example-margin"
[disabled]="disabled"
[invert]="invert"
[max]="max"
[min]="min"
[step]="step"
[thumbLabel]="thumbLabel"
[tickInterval]="tickInterval"
[(ngModel)]="someRange"
[vertical]="vertical">
</mat-slider>
