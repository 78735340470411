import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';

import { BaseDataStore, GenericHttpParams, UtilityTypeEnum, BooleanResult, IntegerResult } from '@shared/types';
import { VeeRule } from '@shared/models/appModels/VeeRule.model';
import { VeeRuleHttpParamG } from '@shared/types/http/veeRuleHttpConfig';
import { VeeRuleService } from '@shared/services/http/veeRule.service';
import { CommonAppDataService } from '@shared/services/commonAppData.service';
import { VeeRuleTypeEnum } from '@shared/types/modelTypes/veeRuleTypes';
import { RestEnumMpapper } from '@shared/models/RestSupport';
import { ValidateImplementation } from '@shared/models/appModels/validateImplementation.model';


@Injectable()
export class VeeRulesDataStoreService extends BaseDataStore<GenericHttpParams<VeeRule>, VeeRuleHttpParamG> {

  static UtilityTypeMapper: RestEnumMpapper<typeof UtilityTypeEnum> = new RestEnumMpapper<typeof UtilityTypeEnum>();
  static RuleTypeMapper: RestEnumMpapper<typeof VeeRuleTypeEnum> = new RestEnumMpapper<typeof VeeRuleTypeEnum>();

  private veeRuleSubject$: Subject<VeeRule> = new Subject<VeeRule>();
  private veeRuleObservable$: Observable<VeeRule> = this.veeRuleSubject$.asObservable();

  constructor(
    private veeRuleHttpService: VeeRuleService,
    cs: CommonAppDataService) {
    super(cs);
  }

  getChangeObservable(): Observable<VeeRule> {
    return this.veeRuleObservable$;
  }

  getVeeRules(allowEmpty: boolean = true): Observable<VeeRule[]> {
    const params = this.getEmptyParams();
    params.config.strict = !allowEmpty;
    return this.veeRuleHttpService.getVeeRulesList(params);
  }

  getVeeRuleById(id: number): Observable<VeeRule> {
    const params = this.getEmptyParams();
    params.path.idVeeRule = '' + id;
    return this.veeRuleHttpService.getVeeRuleById(params);
  }

  postVeeRules(body: VeeRule, allowEmpty: boolean = true): Observable<VeeRule> {
    const params = this.getEmptyParams();
    params.config.strict = !allowEmpty;
    params.body = body;
    return this.veeRuleHttpService.postVeeRule(params);
  }

  postValidateImplementationVeeRule(body: ValidateImplementation, allowEmpty: boolean = true): Observable<any>/*Observable<ValidateImplementation>*/ {
    const params = this.getEmptyParams();
    params.config.strict = !allowEmpty;
    params.body = body;
    return this.veeRuleHttpService.postValidateVeeRule(params);
  }

  putRuleById(id: number, body: VeeRule): Observable<VeeRule> {
    const params = this.getEmptyParams();
    params.path.idVeeRule = '' + id;
    params.body = body;
    return this.veeRuleHttpService.putVeeRuleById(params);
  }

  deleteRuleById(id: number): Observable<VeeRule> {
    const params = this.getEmptyParams();
    params.path.idVeeRule = '' + id;
    return this.veeRuleHttpService.deleteVeeRuleById(params);
  }

  protected getEmptyParams(): VeeRuleHttpParamG {
    const r: VeeRuleHttpParamG = { body: {} as VeeRule, config: {}, headers: {}, path: {}, queryParams: {} };
    return r;

  }
}
