<h2>{{dataModelTxt.EDIT_VARIABLE_FORM}}</h2>
<form [formGroup]="editVariableForm" (ngSubmit)="onSubmitChanges()" novalidate>
    <div id="container" class="container-form">
        <div class="row">
            <mat-form-field class="col">
                <input matInput placeholder="{{dataModelTxt.DESCRIPTION}}" formControlName="description" name="description">
                <mat-error *ngIf="editVariableForm.get('description').touched && editVariableForm.get('description').errors?.required">
                    {{dataModelTxt.DESCRIPTION_IS_REQUIRED}}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field class="col">
                <input matInput placeholder="{{dataModelTxt.UNIT}}" formControlName="unit" name="unit">
            </mat-form-field>
        </div>
        <div class="row">
            <label style="margin-right: 10px;padding:0 10px;">{{dataModelTxt.IS_PRIMARY}}</label>
            <mat-checkbox matSelect formControlName="is_primary" [(ngModel)]="isPrimInForm" value="true">
                {{dataModelTxt.YES}}
            </mat-checkbox>
        </div>
        <div class="form-btn right-btn">
            <button id="saveBtn" mat-raised-button color="accent" class="btn btn-action" type="submit" [disabled]="disableSubmitBtn || editVariableForm.invalid">{{dataModelTxt.SUBMIT_CHANGES}}</button>
        </div>
    </div>
</form>