import { IGetInterfaceType } from '@shared/types/applicationTypes';

export interface IValidateMeasurement {
    position_id: number;
    meter_point_id: string;
    validation_rule_ids: number[];
}

export class ValidateMeasurement implements IValidateMeasurement, IGetInterfaceType<IValidateMeasurement, ValidateMeasurement> {

    position_id: number;
    meter_point_id: string;
    validation_rule_ids: number[];

    constructor(iValidateMeasurement: IValidateMeasurement) {
        this.position_id = (typeof (iValidateMeasurement.position_id) !== 'undefined') ? iValidateMeasurement.position_id : undefined;
        this.meter_point_id = (typeof (iValidateMeasurement.meter_point_id) !== 'undefined') ? iValidateMeasurement.meter_point_id : undefined;
        this.validation_rule_ids = (typeof (iValidateMeasurement.validation_rule_ids) !== 'undefined') ? iValidateMeasurement.validation_rule_ids : undefined;

    }

    getInterface(webInterface: boolean): IValidateMeasurement {
        const emptyValue = (webInterface) ? null : undefined;
        return {
            position_id: this.position_id,
            meter_point_id: this.meter_point_id,
            validation_rule_ids: this.validation_rule_ids || emptyValue,
        };
    }

}
