import { IPositionEvent, PositionEvent } from '@shared/models/appModels/positionEvent.model';

import { EventType, EventStatus } from '@shared/types/modelTypes/eventType';
import { IGetInterfaceType } from '@shared/types/applicationTypes';
import { PositionTransitions } from '@shared/types/modelTypes/positionTypes';

export interface IPosition {
  idPosition: number;     /* Unique ID of position in DB */
  idSchedule: number;     /* Relation to schedule that includes this position in DB */
  name: string;           /* Position string name */
  orderInSchedule: number; /* Human-readable ordering ID of position */
  events: Array<IPositionEvent>;
  activeScheduler: boolean;
}

export class Position implements IPosition, IGetInterfaceType<IPosition, Position> {

  idPosition: number;
  idSchedule: number;
  name: string;
  orderInSchedule: number;
  events: Array<PositionEvent>;
  activeScheduler: boolean;

  constructor(iPos: IPosition) {
    if (!iPos) { throw new TypeError('Customer initialization: params is undefined !'); }

    this.idPosition = (typeof (iPos.idPosition) !== 'undefined') ? iPos.idPosition : undefined;
    this.idSchedule = (typeof (iPos.idSchedule) !== 'undefined') ? iPos.idSchedule : undefined;
    this.name = (typeof (iPos.name) !== 'undefined') ? iPos.name : undefined;
    this.orderInSchedule = (typeof (iPos.orderInSchedule) !== 'undefined') ? iPos.orderInSchedule : undefined;

    this.events = [];
    if (iPos.events && iPos.events.length > 0) {
      iPos.events.forEach((iposEv: IPositionEvent) => {
        this.events.push(new PositionEvent(iposEv, this.orderInSchedule));
      });
    }

    this.activeScheduler = (typeof (iPos.activeScheduler) !== 'undefined') ? iPos.activeScheduler : undefined;

  }

  static positionStateMachine(p: Position, transition: PositionTransitions): Position {
    switch (transition) {
      case PositionTransitions.UPLOAD_FINISH:
      case PositionTransitions.VALIDATION_FAILED: {
          p.events.forEach((singleEvent: PositionEvent) => {
            if (singleEvent.typeOf === EventType.UPLOAD) { singleEvent.status = EventStatus.DONE; }
            if (singleEvent.typeOf === EventType.VALIDATION) { singleEvent.status = EventStatus.TODO; }
            if (singleEvent.typeOf === EventType.EXPORT) { singleEvent.status = EventStatus.LOCKED; }
            if (singleEvent.typeOf === EventType.ANALYSIS) { singleEvent.status = EventStatus.LOCKED; }
          });
          break;
        }
      case PositionTransitions.VALIDATION_START: {
            p.events.forEach((singleEvent: PositionEvent) => {
              if (singleEvent.typeOf === EventType.UPLOAD) { singleEvent.status = EventStatus.DONE; }
              if (singleEvent.typeOf === EventType.VALIDATION) { singleEvent.status = EventStatus.IN_PROGRESS; }
              if (singleEvent.typeOf === EventType.EXPORT) { singleEvent.status = EventStatus.LOCKED; }
              if (singleEvent.typeOf === EventType.ANALYSIS) { singleEvent.status = EventStatus.LOCKED; }
            });
            break;
          }
      case PositionTransitions.VALIDATION_FINISH: {
          p.events.forEach((singleEvent: PositionEvent) => {
            if (singleEvent.typeOf === EventType.UPLOAD) { singleEvent.status = EventStatus.DONE; }
            if (singleEvent.typeOf === EventType.VALIDATION) { singleEvent.status = EventStatus.DONE; }
            if (singleEvent.typeOf === EventType.EXPORT) { singleEvent.status = EventStatus.LOCKED; }
            if (singleEvent.typeOf === EventType.ANALYSIS) { singleEvent.status = EventStatus.LOCKED; }
          });
          break;
        }
      case PositionTransitions.VALIDATION_FREEZED: {
          p.events.forEach((singleEvent: PositionEvent) => {
            if (singleEvent.typeOf === EventType.UPLOAD) { singleEvent.status = EventStatus.DONE; }
            if (singleEvent.typeOf === EventType.VALIDATION) { singleEvent.status = EventStatus.DONE; }
            if (singleEvent.typeOf === EventType.EXPORT) { singleEvent.status = EventStatus.TODO; }
            if (singleEvent.typeOf === EventType.ANALYSIS) { singleEvent.status = EventStatus.TODO; }
          });
          break;
        }
      case PositionTransitions.EXPORT_DONE: {
          p.events.forEach((singleEvent: PositionEvent) => {
            if (singleEvent.typeOf === EventType.UPLOAD) { singleEvent.status = EventStatus.DONE; }
            if (singleEvent.typeOf === EventType.VALIDATION) { singleEvent.status = EventStatus.DONE; }
            if (singleEvent.typeOf === EventType.EXPORT) { singleEvent.status = EventStatus.DONE; }
            if (singleEvent.typeOf === EventType.ANALYSIS && singleEvent.status == EventStatus.TODO) {
              singleEvent.status = EventStatus.TODO;
            } else if (singleEvent.typeOf === EventType.ANALYSIS) {
              singleEvent.status = EventStatus.DONE;
            }
          });
          break;
        }
      case PositionTransitions.ANALYSIS_FAILED: {
          p.events.forEach((singleEvent: PositionEvent) => {
            if (singleEvent.typeOf === EventType.UPLOAD) { singleEvent.status = EventStatus.DONE; }
            if (singleEvent.typeOf === EventType.VALIDATION) { singleEvent.status = EventStatus.DONE; }
            if (singleEvent.typeOf === EventType.EXPORT && singleEvent.status == EventStatus.TODO) {
              singleEvent.status = EventStatus.TODO;
            } else if (singleEvent.typeOf === EventType.EXPORT) {
              singleEvent.status = EventStatus.DONE;
            }
            if (singleEvent.typeOf === EventType.EXPORT) { singleEvent.status = EventStatus.DONE; }
            if (singleEvent.typeOf === EventType.ANALYSIS) { singleEvent.status = EventStatus.TODO; }
          });
          break;
        }
      case PositionTransitions.ANALYSIS_START: {
          p.events.forEach((singleEvent: PositionEvent) => {
            if (singleEvent.typeOf === EventType.UPLOAD) { singleEvent.status = EventStatus.DONE; }
            if (singleEvent.typeOf === EventType.VALIDATION) { singleEvent.status = EventStatus.DONE; }
            if (singleEvent.typeOf === EventType.EXPORT && singleEvent.status == EventStatus.TODO) {
              singleEvent.status = EventStatus.TODO;
            } else if (singleEvent.typeOf === EventType.EXPORT) {
              singleEvent.status = EventStatus.DONE;
            }
            if (singleEvent.typeOf === EventType.ANALYSIS) { singleEvent.status = EventStatus.IN_PROGRESS; }
          });
          break;
        }
      case PositionTransitions.ANALYSIS_FINISH: {
          p.events.forEach((singleEvent: PositionEvent) => {
            if (singleEvent.typeOf === EventType.UPLOAD) { singleEvent.status = EventStatus.DONE; }
            if (singleEvent.typeOf === EventType.VALIDATION) { singleEvent.status = EventStatus.DONE; }
            if (singleEvent.typeOf === EventType.EXPORT && singleEvent.status == EventStatus.TODO) {
              singleEvent.status = EventStatus.TODO;
            } else if (singleEvent.typeOf === EventType.EXPORT) {
              singleEvent.status = EventStatus.DONE;
            }
            if (singleEvent.typeOf === EventType.ANALYSIS) { singleEvent.status = EventStatus.DONE; }
          });
          break;
        }
    }
    return p;
  }

  getName(options?: { addPrefix: boolean }): string {
    if (options && options.addPrefix) {
      return `${this.name} #${this.orderInSchedule}`;
    } else {
      return `${this.name}`;
    }
  }

  getId(): number {
    return this.idPosition;
  }

  getEvents(): PositionEvent[] {
    if (this.events) {
      return this.events.slice(0);
    } else {
      throw new Error('Position without events - incorrect datat type');
    }
  }

  getInterface(webInterface: boolean = true): IPosition {
    return {
      idPosition: this.idPosition,
      idSchedule: this.idSchedule,
      name: this.name,
      orderInSchedule: this.orderInSchedule,
      events: this.events.map(e => e.getInterface(webInterface)),
      activeScheduler: this.activeScheduler
    };
  }
}
