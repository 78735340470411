// Angular modules import
import { Injectable } from '@angular/core';

// RxJS imports
/* Rx classes */
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
/* Static class extensions */
import 'rxjs/add/operator/combineAll';
import 'rxjs/add/operator/reduce';
import 'rxjs/add/operator/last';
import 'rxjs/add/operator/defaultIfEmpty';
import 'rxjs/add/operator/toArray';
import 'rxjs/add/observable/zip';

/* Datatypes */
import { SensorData } from '@shared/models/appModels/sensorData.model';

/* Services */
import { SensorDataService } from '@shared/services/http/sensorData.service';

import { environment } from '@env/environment';
import { GenericHttpParams, BaseDataStore } from '@shared/types';
import { CommonAppDataService } from '@shared/services/commonAppData.service';
import { SensorDataHttpParamG } from '@shared/types/http/sensorDataHttpConfig';

@Injectable()
export class SensorDataDataStoreService extends BaseDataStore<GenericHttpParams<SensorData>, SensorDataHttpParamG> {

  private debugMode: boolean = environment.debug;

  private scheduleDataSubject$: Subject<SensorData> = new Subject<SensorData>();
  private scheduleDataObservable$: Observable<SensorData> = this.scheduleDataSubject$.asObservable();

  constructor(
    private sensorData: SensorDataService,
    cs: CommonAppDataService) {
    super(cs);
  }

  getChangeObservable(): Observable<SensorData> {
    return this.scheduleDataObservable$;
  }

  getSensorDataState(allowEmpty: boolean = true): Observable<SensorData> {

    const params = this.getEmptyParams();
    params.config.strict = !allowEmpty;

    return this.sensorData.getSensorDataState(params);
  }

  postSensorDataState(body: SensorData): Observable<SensorData> {
    const params: SensorDataHttpParamG = this.getEmptyParams();
    params.body = body;
    return this.sensorData.postSensorDataState(params);
  }

  protected getEmptyParams(): SensorDataHttpParamG {
    const r: SensorDataHttpParamG = { body: {} as SensorData, headers: {}, path: {}, queryParams: {}, config: {} };
    return r;
  }

}
