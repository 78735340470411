import { Component, OnInit, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, AbstractControl } from '@angular/forms';
/* External libraries */

import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/switchMap';

/* Application services */
import { ScheduleDataStoreService } from '@shared/services/dataStore/scheduleDataStore.service';
import { PositionDataStoreService } from '@shared/services/dataStore/positionDataStore.service';

/* Application datatypes */
import { Position } from '@shared/models/appModels/position.model';
import { Schedule } from '@shared/models/appModels/schedule.model';
// Env
import { environment as env } from '@env/environment';
import { SchedulePositionSelectorLocalisation as loc } from './select-page.localisation';
import { ScheduleDialogsService } from '../../schedule/service/schedule-dialog.service';
import { CommonAppDataService } from '@shared/services/commonAppData.service';

@Component({

  // tslint:disable-next-line:component-selector
  selector: 'sv-select',
  templateUrl: 'select-page.component.html'
})

export class SelectPageComponent implements OnInit, OnDestroy {
  @Output() selectionEvent: EventEmitter<{ idSchedule?: number, idPosition?: number }>;
  @Output() creationEvent: EventEmitter<any>;

  @Input() createNewAllowed: boolean;

  readonly debugMode: boolean = env.debug;

  filterForm: FormGroup;

  /* Reference copy of schedules & positions arrays */
  schedules: Schedule[] = [];
  positions: Position[] = [];

  navigationContext: {
    schedule?: number,
    position?: number,
  };

  actualSelect: {
    idSchedule?: number,
    idPosition?: number
  };

  queryParams$: Subscription;
  scheduleSelect$: Subscription;
  positionsSelect$: Subscription;
  externaChangeSchedule$: Subscription;
  externaChangePosition$: Subscription;
  newPositionAddedFlag: boolean = false;

  constructor(
    private positionDataStoreService: PositionDataStoreService,
    public scheduleDataStoreService: ScheduleDataStoreService,
    private route: ActivatedRoute,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private fb: FormBuilder,
    private communicationService: CommonAppDataService,
    private schDialogService: ScheduleDialogsService
  ) {

    this.filterForm = this.fb.group({
      'scheduleSelect': new FormControl(),
      'positionSelect': new FormControl()
    });

    this.selectionEvent = new EventEmitter<{ idSchedule?: number, idPosition?: number }>();
    this.creationEvent = new EventEmitter<any>();

    this.navigationContext = { position: undefined, schedule: undefined };
    this.actualSelect = { idPosition: undefined, idSchedule: undefined };
  }

  ngOnInit() {
    this.initScheduleFetch();
  }

  private initScheduleFetch(): void {
    this.scheduleDataStoreService.getAllSchedulesNoPositions({ limit: 50, offset: 0 }, true) /* TODO: filter only active schedules */
      .do((schedules: Schedule[]) => {
        const tmp = this.getCurrentMediaTypeSchedules(schedules);
        this.positionSelect.disable({ emitEvent: false });
        if (typeof (tmp) !== 'undefined' && tmp.length > 0) {
          this.scheduleSelect.enable({ emitEvent: false });
        } else {
          this.schedules = [];
          this.scheduleSelect.setValue('empty', { emitEvent: false });
        }
      })
      .subscribe((schedules: Schedule[]) => {
      },
        (error: any) => {
          console.error(`Error while fetching schedule list to control: ${error}`);
        },
        () => {
          this.scheduleSelect$ = this.onScheduleControlSelect();
          this.positionsSelect$ = this.onPositionControlSelect();
          this.externaChangeSchedule$ = this.onExternalScheduleChange();
          this.externaChangePosition$ = this.onExternalPositionChange();
          this.queryParams$ = this.subscribeQueryParams();
          if (this.debugMode) { console.log('initScheduleFetch completed for control'); }
        });
  }
  getCurrentMediaTypeSchedules(newSchedules: Schedule[]): Schedule[] {
    let tmp: Schedule[] = [];
    newSchedules.forEach(element => {
      if (element.utilityType === this.communicationService.getCurrentMediaType()) {
        tmp.push(element);
      }
    });
    if (typeof (tmp) !== 'undefined' && tmp.length > 0) {
      this.schedules = tmp.slice(0);
    }
    return tmp;
  }
  private onExternalScheduleChange(): Subscription {

    return this.scheduleDataStoreService.getChangeObservable()
      .concatMap<Schedule, Schedule[]>((schArr: Schedule) => {
        return this.scheduleDataStoreService.getAllSchedulesNoPositions({ limit: 50, offset: 0 }, true); /* TODO: filter only active schedules */
      })
      .subscribe((newSchedules: Schedule[]) => {
        const tmp = this.getCurrentMediaTypeSchedules(newSchedules);
        this.positionSelect.disable();
        if (tmp !== undefined && tmp.length > 0) {
          this.actualSelect.idSchedule = (this.actualSelect.idSchedule) ? this.actualSelect.idSchedule : tmp[0].idSchedule;
          this.actualSelect.idPosition = undefined;
          this.scheduleSelect.setValue(this.actualSelect.idSchedule, { emitEvent: true });
        }
      }, error => {
        console.error('Unable to fetch schedules');
        console.error(error);
      }, () => {
        if (this.debugMode) { console.error('Schedules changes observable terminated'); }
      });
  }

  private onExternalPositionChange(): Subscription {
    return this.positionDataStoreService.getChangeObservable()
      .filter(pos => (this.actualSelect.idSchedule && pos.idSchedule === this.actualSelect.idSchedule))
      .concatMap<Position, Position[]>((pos: Position) => {
        return this.scheduleDataStoreService.getSchedulePositions(pos.idSchedule, 0, 50, true);
      })
      .subscribe((positions: Position[]) => {
        this.positions = positions.slice(0);
        this.actualSelect.idPosition = (this.actualSelect.idPosition) ? this.actualSelect.idPosition : this.positions[0].idPosition;
        this.positionSelect.setValue(this.actualSelect.idPosition, { emitEvent: true });
        // this.selectionEvent.emit(this.actualSelect);
      },
        (error) => { console.error('Unable to fetch positions'); },
        () => {
          if (this.debugMode) {
            console.error('Positions changes observable completed');
          }
        });
  }

  private onScheduleControlSelect(): Subscription {
    return this.scheduleSelect.valueChanges
      .concatMap<any, Position[]>((id: any) => {
        if (typeof (id) === 'undefined' || id === null) {
          return Observable.throw('Unknown form value');
        } else if ('' + id === 'create') {
          this.clearControls();
          return this.handleScheduleCreation();
        } else {
          this.actualSelect.idSchedule = +id;
          if (this.positions.length > 0) {
          this.actualSelect.idPosition = this.positions[0].idPosition;
          this.positionSelect.setValue(this.actualSelect.idPosition, {emitEvent: false});
          }
          this.actualSelect.idPosition = undefined;
          this.positionSelect.disable({ emitEvent: false });
          return this.scheduleDataStoreService.getSchedulePositions(id);
        }
      })
      .subscribe((positions: Position[]) => {
        this.positions = positions.slice(0);
        this.positionSelect.enable({ emitEvent: false });
        this.changePathId();
      },
        (err) => {
          console.error(`Error: ${err}`);
        },
        () => {
          console.log(`onScheduleControlSelect subscription completed`);
        });
  }

  private isNewPositionAdded(id: number) {
    return ((this.actualSelect.idPosition > +id) && this.newPositionAddedFlag);
  }

  private onPositionControlSelect(): Subscription {
    return this.positionSelect.valueChanges
      .concatMap<any, number>((id: string) => {
        if (typeof (id) === 'undefined' || id === null) {
          return Observable.of(undefined);
        } else if ('' + id === 'create') {
          this.clearControls();
          return this.handleScheduleEdition();
        } else if (this.isNewPositionAdded(+id)) {
          this.newPositionAddedFlag = false;
          return Observable.of(this.actualSelect.idPosition);
        } else {
          this.actualSelect.idPosition = +id;
          return Observable.of(+id);
        }
      })
      .subscribe((id: number) => {
        this.changePathId();
      },
        (err) => {
          console.error('Error: ' + err);
        },
        () => {
          console.log(`onPositionControlSelect subscription completed`);
        });
  }

  private clearControls(emitEvent: boolean = false) {
    this.scheduleSelect.setValue('', { emitEvent: emitEvent });
    this.positionSelect.setValue('', { emitEvent: emitEvent });
  }

  changePathId() {
    this.router.navigate([], {
      queryParams: {
        schedule: typeof (this.actualSelect.idSchedule) !== 'undefined' ? this.actualSelect.idSchedule : undefined,
        position: typeof (this.actualSelect.idPosition) !== 'undefined' ? this.actualSelect.idPosition : undefined,
      }
    });
    this.selectionEvent.emit(this.actualSelect);
  }

  private handleScheduleCreation(): Observable<Position[]> {
    return this.schDialogService.invokeCreateScheduleModal()
      .concatMap<Schedule, Position[]>((el: Schedule) => {
        if (el !== undefined) {
          return this.scheduleDataStoreService.addSchedule(el[0], { emitEvent: false })
            .concatMap<Schedule, Schedule>((addedSchedule: Schedule) => {
              return this.scheduleDataStoreService.getAllSchedulesNoPositions({ limit: 50, offset: 0 }, true)
                .concatMap<Schedule[], Schedule>((schedules: Schedule[]) => {
                  this.getCurrentMediaTypeSchedules(schedules);
                  return Observable.of(addedSchedule);
                });
            })
            .concatMap<Schedule, Position[]>((newSchedule: Schedule) => {
              this.scheduleSelect.setValue(newSchedule.getId(), { emitEvent: false });
              this.positionSelect.setValue('', { emitEvent: false });
              this.positionSelect.disable({ emitEvent: false });
              this.actualSelect.idSchedule = newSchedule.getId();
              this.actualSelect.idPosition = undefined;
              return Observable.of(newSchedule.getPositions());
            });
        } else { /* Modal get's calceled, stick to currently selected */
          if (typeof (this.actualSelect.idSchedule) !== 'undefined') {
            return this.scheduleDataStoreService.getSchedulePositions(this.actualSelect.idSchedule);
          } else {
            return Observable.of([] as Position[]);
          }
        }
      });
  }

  private handleScheduleEdition(): Observable<number> {

    return this.scheduleDataStoreService.getSingleScheduleWithPositinos(this.actualSelect.idSchedule, 0, 50, true)
      .concatMap<Schedule, number>((templateSchedule: Schedule) => {
        // const orginalPositionIds: number[] = templateSchedule.getPositions().map(p => p.getId()).sort();
        return this.schDialogService.invokeEditScheduleModal(templateSchedule)
          .concatMap<Schedule, number>((sch) => {
            if (sch !== undefined) {
              return this.scheduleDataStoreService.updateSchedule(sch, { emitEvent: false })
                .concatMap<Schedule, number>((updatedSchedule: Schedule) => {
                  this.scheduleSelect.setValue(updatedSchedule.getId(), { emitEvent: false });
                  /* FIXME: Logic of selecting new position is impossible to implement */
                  /* There are no restrictions on edit modal screet to allow creation of single element */
                  this.positions = updatedSchedule.getPositions().slice(0);
                  if (this.positions.length > 0) {
                    this.positionSelect.setValue(this.positions[this.positions.length - 1].getId(), { emitEvent: false });
                    this.actualSelect.idPosition = this.positions[this.positions.length - 1].getId();
                    this.newPositionAddedFlag = true;
                  } else {
                    this.positionSelect.setValue('', { emitEvent: false });
                    this.actualSelect.idPosition = undefined;
                  }
                  return Observable.of(this.actualSelect.idPosition);
                });
            } else { /* Modal get's calceled, stick to currently selected */
              return Observable.of(this.actualSelect.idPosition);
            }
          });
      });
  }

  private subscribeQueryParams(): Subscription {
    return this.activeRoute.queryParams.subscribe(params => {
      if (this.debugMode) {
        console.log(`New query params:` + params);
      }
      this.navigationContext.schedule = +params['schedule'] || undefined;
      this.navigationContext.position = +params['position'] || undefined;
      if (this.navigationContext.schedule) {
        this.setControlsByNavigationContext(this.navigationContext);
      }
    }, (error) => {
      console.error(error);
    }, () => {
      if (this.debugMode) { console.log(`Query params subscription finalized`); }
    });
  }

  private setControlsByNavigationContext(navContext: { schedule?: number, position?: number, used?: boolean }): void {
    /* Handle schedule navigation context AND position context if is presnet */
    if (navContext && typeof (navContext.schedule) !== 'undefined') {
      this.handleContextBySchedule(navContext);
      /* Reverse handle via position context - this is kinda awkward combination but possible */
    } else if (navContext && typeof (navContext.position) !== 'undefined') {
      this.handleContextByPosition(navContext);
    }
  }

  private handleContextBySchedule(navContext: { schedule?: number, position?: number, used?: boolean }) {
    if (-1 === this.schedules.findIndex(sch => {
      return sch.idSchedule === navContext.schedule;
    })) {
      console.error(`Schedule ID passes in query parameters (${navContext.schedule}) is not present in currently fetched set of Schedules\n
      Default placeholder will be set on control.`);
      this.actualSelect.idSchedule = undefined;
    } else {
      this.actualSelect.idSchedule = navContext.schedule;
    }
    this.actualSelect.idPosition = undefined;
    this.scheduleSelect.setValue(this.actualSelect.idSchedule, { emitEvent: false });
    this.scheduleDataStoreService.getSchedulePositions(this.actualSelect.idSchedule)
      .subscribe((pos: Position[]) => {
        this.positions = pos.slice(0);
        this.positionSelect.enable({ emitEvent: false });
        if (typeof (navContext.position) !== 'undefined') {
          const idx = this.positions.findIndex(position => position.idPosition === navContext.position);
          if (idx !== -1) {
            this.actualSelect.idPosition = this.positions[idx].idPosition;
            this.positionSelect.setValue(this.actualSelect.idPosition, { emitEvent: false });
          } else {
            this.actualSelect.idPosition = undefined;
            /*console.error(`Position ID passed in query parameter(${navContext.position}) does not match to any of positions of selected schedule\n
          Default placeholder will be set on control`);*/
          }
        } else {
          if (this.positions.length > 0) {
          this.actualSelect.idPosition = this.positions[0].idPosition;
          this.positionSelect.setValue(this.actualSelect.idPosition, { emitEvent: false });
          } else {
          this.actualSelect.idPosition = undefined;
          }
        }
      },
        (err) => {
          console.log(err);
        },
        () => {
          this.changePathId();
        });
  }

  private handleContextByPosition(navContext: { schedule?: number, position?: number, used?: boolean }) {
    this.positionDataStoreService.getPositionById(navContext.position, true)
      .concatMap((pos: Position) => {
        const idx = this.schedules.findIndex(sch => sch.idSchedule === pos.idSchedule);
        if (idx === -1) {
          console.error(`Position ID passed in query parameter(${navContext.position}) does not match to any of positions of schedule list\n
        Control will be supplied with default placeholder`);
          this.scheduleSelect.setValue(undefined, { emitEvent: false });
        } else {
          this.actualSelect.idSchedule = this.schedules[idx].idSchedule;
        }
        this.actualSelect.idPosition = navContext.position;
        this.scheduleSelect.setValue(this.actualSelect.idSchedule, { emitEvent: false });
        return this.scheduleDataStoreService.getSchedulePositions(this.actualSelect.idSchedule);
      })
      .subscribe((pos: Position[]) => {
        this.positions = pos.slice(0);
        this.positionSelect.enable({ emitEvent: false });
        this.positionSelect.setValue(this.actualSelect.idPosition, { emitEvent: false });
      },
        (err) => {
          console.error(err);
        },
        () => {
          this.changePathId();
        });
  }

  /* Common controls getters */
  get scheduleSelect(): AbstractControl {
    return this.filterForm.get('scheduleSelect');
  }

  get positionSelect(): AbstractControl {
    return this.filterForm.get('positionSelect');
  }

  private setNewValues(values: { schedule?: number, position?: number },
    options: { emitEvent: boolean } = { emitEvent: true }) {
    if (values.schedule) {
      this.actualSelect.idSchedule = values.schedule;
      this.scheduleSelect.setValue(this.actualSelect.idSchedule);
    }
    if (values.position) {
      this.actualSelect.idPosition = values.position;
    }
    if (options.emitEvent === true) {
      // ToDoo
    }
  }

  getControlCreationText(control: 'schedule' | 'position') {
    switch (control) {
      case 'schedule': {
        return loc[env.language].texts.control.creationText.SCHEDULE;
      }
      case 'position': {
        return loc[env.language].texts.control.creationText.POSITION;
      }
    }
  }

  getControlPlaceholderText(control: 'schedule' | 'position') {
    switch (control) {
      case 'schedule': {
        if (this.schedules && this.schedules.length === 0) {
          return loc[env.language].texts.control.emptyText.SCHEDULE;
        } else {
          return loc[env.language].texts.control.placeholder.SCHEDULE;
        }
      }
      case 'position': {
        return loc[env.language].texts.control.placeholder.POSITION;
      }
      default: {
        throw new Error('Incorrect parameter passed as control identifier');
      }
    }
  }

  ngOnDestroy(): void {
    try { /* Any of subscriptions may be null if some subscription throw an erorr */
      if (this.queryParams$) {
        this.queryParams$.unsubscribe();
      }
    } catch (e) {
      console.error(e);
    } finally {
      if (this.scheduleSelect$) {
        this.scheduleSelect$.unsubscribe();
      }
      if (this.positionsSelect$) {
        this.positionsSelect$.unsubscribe();
      }
      if (this.externaChangePosition$) {
        this.externaChangePosition$.unsubscribe();
      }
      if (this.externaChangeSchedule$) {
        this.externaChangeSchedule$.unsubscribe();
      }
    }
  }

}
