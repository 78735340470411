import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  OnDestroy
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormBuilder } from "@angular/forms";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatPaginator } from "@angular/material/paginator";

/* External libraries */
import "rxjs/add/operator/switchMap";

import { ReadlistDataSource } from "../dataSource/readlistDataSource";

/* Application services */
import { CustomerDataStoreService } from "@shared/services/dataStore/customerDataStore.service";
import { PositionDataStoreService } from "@shared/services/dataStore/positionDataStore.service";

/* Application datatypes */
import { Position } from "@shared/models/appModels/position.model";

// Env
import { environment } from '@env/environment';
import { measurementOriginNamesMap } from '@shared/types/modelTypes/measurementType';
import { CustomerSegmentNames } from '@shared/types/modelTypes/customerTypes';
import { typesLocalisation } from '@shared/types/localisation';
import { MeterPoint } from '@shared/models/appModels/meterPoint.model';
import { AnalysisLocalisation as loc } from '../../data-analysis-localisation';
import { IAnalysisAlgorithm } from '@shared/models/appModels/analysisAlgorithm.model';
import { CommonAppDataService } from '@shared/services/commonAppData.service';
import { FileTemperatureDataDataStoreService } from '@shared/services/dataStore/fileTemperaturesDataDataStore.service';
import { UtilityTypeEnum } from '@shared/types';
import { InteractiveTutorialService, NextComponentName } from '@shared/services/interactiveTutorialService.service';
import { DataAnalaysisInteractiveTutorialService } from '../data-analysis-interactive-tutorial-service.service';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import * as moment from "moment";

interface EnabledMeterPoint {
  enabled: boolean;
  meterPoint: MeterPoint;
}

@Component({
  selector: "sv-data-analysis-readlist",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "data-analysis-readlist.component.html",
  styleUrls: ["data-analysis-readlist.component.sass"],
})
export class DataAnalysisReadlistComponent implements OnInit, OnDestroy {
  @Output() buttonStartAnalysisClick = new EventEmitter();
  changeFilterForm: FormGroup;
  @ViewChild("filter") filter: ElementRef;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  readonly debugMode: boolean = environment.debug;
  @Output() analysisEvent: EventEmitter<{
    aggregation?: string;
    startDate?: number;
    endDate?: number;
    meter: string;
    address: string;
  }>;
  @Input() listEnabledAlgorithm: IAnalysisAlgorithm[];
  startDate: moment.Moment;
  endDate: moment.Moment;
  minDate: moment.Moment;
  maxDate: moment.Moment;
  temperaturesCount: number;

  readonly selectedCustomerSegmentNames = CustomerSegmentNames;
  readonly selectedMeasurementTypeNames = measurementOriginNamesMap;
  readonly itemsPage = typesLocalisation.ItemsPage[environment.language].texts;
  readonly page = loc[environment.language].texts.page;
  readonly assetDataHeader =
    loc[environment.language].texts.asset.assetDataPreview.header;
  readonly parametersField =
    loc[environment.language].texts.asset.assetDataPreview.parametersField;
  readonly parametrsLabel =
    loc[environment.language].texts.asset.assetDataPreview.parametrsLabel;
  readonly tableHeaders =
    loc[environment.language].texts.asset.assetDataPreview.tableHeaders;
  readonly buttonLabel =
    loc[environment.language].texts.asset.assetDataPreview.buttonLabel;
  readonly noData =
    loc[environment.language].texts.asset.assetDataPreview.noData;
  readonly select =
    loc[environment.language].texts.asset.assetDataPreview.select;
  readonly noTemperatures =
    loc[environment.language].texts.asset.assetDataPreview.noTemperatures;

  //// Table
  displayedColumns: string[] = [
    "idMeter",
    "location",
    "measurementType",
    "customer",
    "segment",
  ];
  dataSource: ReadlistDataSource;
  form: FormGroup;
  private positionId: number;
  actual_position = 0;
  listEnabledMeter: EnabledMeterPoint[];
  isSelect: boolean = true;
  isDate: boolean = true;
  interactiveTutrialSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public customerDataStoreService: CustomerDataStoreService,
    private positionDataStoreService: PositionDataStoreService,
    private fb: FormBuilder,
    private fileTemperaturesDataService: FileTemperatureDataDataStoreService,
    private cs: CommonAppDataService,
    private cdr: ChangeDetectorRef,
    private interactiveTutorialService: InteractiveTutorialService,
    private dataAnalysisInteractiveTutorialService: DataAnalaysisInteractiveTutorialService,
  ) {
    this.changeFilterForm = this.fb.group({
      meter: [""],
      aggregation: ["d"],
      startDate: [this.startDate],
      endDate: [this.endDate],
      address: [""],
    });
    this.listEnabledMeter = [];
    this.analysisEvent = new EventEmitter<{
      aggregation?: string;
      startDate?: number;
      endDate?: number;
      meter: string;
      address: string;
    }>();
  }

  ngOnInit() {
    this.getTemperature();
    this.interactiveTutrialSubscription = this.interactiveTutorialService.canGoToNextStepSubject.pipe(debounceTime(100)).subscribe(val => {
      if(val === NextComponentName.DATA_ANALYSIS_READLIST){
        this.startInteractiveTutorial();
      }
    });
    this.route.queryParams.subscribe(params => {
      const positionId = +params.position || -1;
      this.paginator._intl.itemsPerPageLabel = this.itemsPage.itemsPage;
      this.paginator._intl.nextPageLabel = this.itemsPage.nextPage;
      this.paginator._intl.previousPageLabel = this.itemsPage.previousPage;
      this.paginator._intl.lastPageLabel = this.itemsPage.lastPage;
      this.paginator._intl.firstPageLabel = this.itemsPage.firstPage;
      if (positionId !== -1) {
        this.actual_position = +params.position;
        this.positionDataStoreService.getPositionById(positionId).subscribe(
          (v: Position) => {
            this.setStartAndEndDate();
            this.dataSource = new ReadlistDataSource(
              this.positionDataStoreService,
              positionId,
              this.paginator
            );
            if (v.idPosition !== positionId) {
              throw new Error("Data Conflict");
            } else {
              this.positionId = v.idPosition;
            }
            if (v.idPosition !== positionId) {
              throw new Error("Data Conflict");
            } else {
              this.positionId = v.idPosition;
            }
            this.positionDataStoreService
              .getPositionMeterPointsCount(this.positionId)
              .subscribe((num) => {
                this.positionDataStoreService
                  .getPositionMeterPoints(this.positionId, num, 0)
                  .subscribe((metersPoint) => {
                    metersPoint.forEach((element) => {
                      this.listEnabledMeter.push({
                        enabled: true,
                        meterPoint: element,
                      });
                    });
                    this.cdr.markForCheck();
                  });
              });
          },
          (error: any) => this.router.navigate(["/schedule"]),
          () => { }
        );
      }
    });

    this.changeFilterForm.valueChanges.subscribe((change) => {
      this.getTemperature();
      if (!this.dataSource) {
        return;
      }
      this.dataSource.filter =
        change.meter.toLocaleLowerCase() +
        "   " +
        change.address.toLocaleLowerCase();
      if (
        change.meter.toLocaleLowerCase() !== "" ||
        change.address.toLocaleLowerCase() !== ""
      ) {
        let tmp = [];
        tmp = this.pushFilterData(this.dataSource.renderedData);
        tmp.push(this.pushFilterData(this.dataSource.allData));
        this.listEnabledMeter = tmp;
      }
    });
    this.cdr.detectChanges();
  }

  startInteractiveTutorial(){
    const steps = this.dataAnalysisInteractiveTutorialService.getDataAnalysisReadlistInteractiveTutorialService();
    this.interactiveTutorialService.startInteractiveTutorial(steps);
  }

  ngOnDestroy(){
    if(this.interactiveTutrialSubscription){
      this.interactiveTutrialSubscription.unsubscribe();
    }
  }

  pushFilterData(data: MeterPoint[]) {
    let tmp = [];
    data.forEach((element) => {
      tmp.push({
        enabled: this.listEnabledMeter.find(
          (v) => v.meterPoint.idMeterPoint === element.idMeterPoint
        )
          ? this.listEnabledMeter.find(
            (v) => v.meterPoint.idMeterPoint === element.idMeterPoint
          ).enabled
          : true,
        meterPoint: element,
      });
    });
    return tmp;
  }

  getDataSourceLength(): number {
    if (this.dataSource) {
      return this.dataSource.paginatorLength;
    }
  }

  isLoading(): boolean {
    if (this.dataSource && this.dataSource.filteredData.length === 0) {
      return true;
    }
    if (this.startDate && this.endDate && this.isDate) {
      this.minDate = this.startDate;
      this.maxDate = this.endDate;
      this.changeFilterForm.setValue({
        meter: this.changeFilterForm.get("meter").value,
        aggregation: this.changeFilterForm.get("aggregation").value,
        startDate: this.startDate,
        endDate: this.endDate,
        address: this.changeFilterForm.get("address").value,
      });
      this.isDate = false;
      this.cdr.markForCheck();
    }
  }

  startAnalysis(): void {
    const enabledMeter = this.listEnabledMeter.filter((v) => v.enabled);
    if (enabledMeter.length !== 0) {
      if (
        this.changeFilterForm.get("meter").value === "" &&
        this.changeFilterForm.get("address").value === "" &&
        enabledMeter.length === this.listEnabledMeter.length
      ) {
        this.setAnalysisEvent("all", "all");
      } else if (
        this.changeFilterForm.get("meter").value === "" &&
        enabledMeter.length === this.listEnabledMeter.length
      ) {
        this.setAnalysisEvent(
          "all",
          this.changeFilterForm.get("address").value
        );
      } else {
        let serialNumbersList: string = "";
        for (let i = 0; i < enabledMeter.length; i++) {
          i === enabledMeter.length - 1
            ? (serialNumbersList =
              serialNumbersList + enabledMeter[i].meterPoint.serialNumber)
            : (serialNumbersList =
              serialNumbersList +
              enabledMeter[i].meterPoint.serialNumber +
              ",");
        }
        this.setAnalysisEvent(
          serialNumbersList,
          loc[environment.language].texts.summary.userSelected
        );
      }
    } else {
      this.setAnalysisEvent(
        "",
        loc[environment.language].texts.summary.userSelected
      );
    }
    this.buttonStartAnalysisClick.emit();
  }

  setAnalysisEvent(meter: any, address: any) {
    this.analysisEvent.emit({
      aggregation: this.changeFilterForm.get("aggregation").value,
      startDate: +this.changeFilterForm.get("startDate")?.value,
      endDate: +this.changeFilterForm.get("endDate")?.value,
      meter: meter,
      address: address,
    });
  }

  changeEnabled(meter: MeterPoint, event: MatCheckboxChange) {
    this.listEnabledMeter.find(
      (v) => v.meterPoint.idMeterPoint === meter.idMeterPoint
    ).enabled = event.checked;
  }

  getEnabled(meter: MeterPoint) {
    return this.listEnabledMeter.find(
      (v) => v.meterPoint.idMeterPoint === meter.idMeterPoint
    )
      ? this.listEnabledMeter.find(
        (v) => v.meterPoint.idMeterPoint === meter.idMeterPoint
      ).enabled
      : true;
  }

  selectOrUnselectAll() {
    this.isSelect = !this.isSelect;
    this.listEnabledMeter.forEach((element) => {
      element.enabled = this.isSelect;
    });
  }

  invalidDates() {
    const startDate = this.changeFilterForm.get("startDate").value;
    const endDate = this.changeFilterForm.get("endDate").value;
    return startDate == null || endDate == null || startDate.isAfter(endDate);
  }

  getTemperature() {
    if (this.cs.getCurrentMediaType() === UtilityTypeEnum.HEAT) {
      this.fileTemperaturesDataService
        .getTemperatureDataListCount({
          from: +this.changeFilterForm.get("startDate").value || null,
          to: +this.changeFilterForm.get("endDate").value || null,
        })
        .subscribe((num) => {
          this.temperaturesCount = num;
          this.cdr.markForCheck();
        });
    }
  }

  setStartAndEndDate() {
    this.positionDataStoreService.getPositionMeterDataCount(this.actual_position).subscribe(meterDataCount => {
      this.positionDataStoreService.getPositionMeterData(this.actual_position, { limit: 1, offset: 0 }).subscribe(first => {
        this.positionDataStoreService.getPositionMeterData(this.actual_position, {
          limit: 1, offset: meterDataCount > 0 ? meterDataCount - 1 : meterDataCount
        }).subscribe(end => {
          if (first.length !== 0 && end.length !== 0) {
            this.startDate = moment.utc(first[0].timestamp);
            this.endDate = moment.utc(end[0].timestamp);
            this.cdr.markForCheck();
          }
        });
      });
    });
  }
}
