<div class="container">
	<h1>{{header}}</h1>
	<sv-select id="svSelect" (selectionEvent)="handleSchedulePositionSelection($event)"></sv-select>
	<mat-accordion class="expansion-box">
		<mat-expansion-panel [expanded]="panelsState">
			<mat-expansion-panel-header>
				<i class="material-icons notranslate">{{algorithmsMdIcon}}</i> {{header}}
			</mat-expansion-panel-header>
			<mat-tab-group class="algorithms-tab-group">
				<mat-tab label="{{basicDashbords}}">
					<form>
						<div id="svDashboardSelect" dnd-sortable-container [sortableData]="listExplorationAlgorithm">

							<ul class="card-body list-group">
								<li *ngFor="let item of listExplorationAlgorithm">
									<mat-checkbox [checked]="item.enabled" [disabled]="!item.switchable"
										(change)="onRuleClick($event, item)">
										{{getTranslatedAlgorithmName(item.name)}}</mat-checkbox>
								</li>
							</ul>
						</div>
					</form>
				</mat-tab>
			</mat-tab-group>
		</mat-expansion-panel>
	</mat-accordion>
		<div matTooltip="Freeze the data first." i18n-matTooltip="freeze data tooltip|A button tooltip that suggests freezing the analyzed data@@export/freeze data tooltip" [matTooltipDisabled]="!explorationButtonDisabled" matTooltipPosition="below"
		matTooltipShowDelay="500" class="tooltip-div">
		<button id="generateBtn" mat-raised-button color="accent" class="btn" [disabled]="explorationButtonDisabled || !diagramId"
			(click)="showResults()">
			{{generateResults}}
		</button>
	</div>

	<div *ngIf='explorationButtonVisable'>
		<sv-data-exploration-summary [diagramId]="diagramId"></sv-data-exploration-summary>
	</div>

	<div *ngIf='showNodesPreview'>
		<sv-nodes-preview></sv-nodes-preview>
	</div>

</div>