// Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { GraphicsModule } from '../graphics/graphics.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { DndModule } from 'ng2-dnd';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { GestureConfig } from '@angular/material/core';

// This Module's Components
import { DataAnalysisPageComponent } from './page/data-analysis-page.component';
import { DataAnalysisReadlistComponent } from './page/readlist/data-analysis-readlist.component';
import { DataAnalysisSummaryComponent } from './page/summary/data-analysis-summary.component';
import { DetailComponent } from './page/summary/detail/detail.component';
import { DataAnalysisProcessComponent } from './page/process/data-analysis-process.component';

import { DataAnalysisWidgetComponent } from './widget/data-analysis-widget.component';
import { CommonComponentsModule } from '../common-component/commonComponents.module';
import { CommonPipeModule } from '../../utils/commonPipe.module';
import { UploadModule } from '../upload/upload.module';
import { GrafanaModule } from '../grafana-dashboards/grafana.module';

@NgModule({
  imports: [
    CommonModule,
    CommonComponentsModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    RouterModule,
    GraphicsModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatInputModule,
    MatRippleModule,
    MatSelectModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatTabsModule,
    MatExpansionModule,
    CommonPipeModule,
    MatSliderModule,
    MatFormFieldModule,
    UploadModule,
    BrowserModule,
    DndModule.forRoot(),
    GrafanaModule,
  ],
  declarations: [
    DataAnalysisPageComponent,
    DataAnalysisReadlistComponent,
    DataAnalysisSummaryComponent,
    DataAnalysisWidgetComponent,
    DetailComponent,
    DataAnalysisProcessComponent
  ],
  exports: [
    DataAnalysisPageComponent,
    DataAnalysisWidgetComponent,
  ],
  providers: [
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
  ]
})
export class DataAnalysisModule {

}
