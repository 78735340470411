// Angular modules import
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Application services
import { CommonAppDataService } from '@shared/services/commonAppData.service';

// RjJS imports
import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import 'rxjs/add/operator/pluck';
import 'rxjs/add/operator/max';
import 'rxjs/add/operator/do';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/concatMap';

import { ISchedule, Schedule } from '@shared/models/appModels/schedule.model';
import { IPosition, Position } from '@shared/models/appModels/position.model';
import { RestTypeMapper } from '@shared/models/RestSupport/restTypeMapper.model';
import { ScheduleHttpParamG } from '@shared/types/http/scheduleHttpConfig';
import { EndpointsEnum } from '@shared/types/http/endpointEnum';
/* Datasource */
import { MockSchedule } from '@shared/mock/schedule.mock';
import { MockPosition } from '@shared/mock/position.mock';

import { environment } from '@env/environment';
import { RequestService } from './requestService.class';

@Injectable()
export class ScheduleService extends RequestService {

  /* Database simulation */
  schedules: ISchedule[];
  positions: IPosition[];


  private scheduleMapper: RestTypeMapper<ISchedule, Schedule>;
  private positionMapper: RestTypeMapper<IPosition, Position>;

  /* Debug data */
  debugMode: boolean = environment.debug;

  constructor(http: HttpClient, cs: CommonAppDataService) {
    super(http, cs, EndpointsEnum.SCHEDULES);
    if (this.MOCK_SERVICE) {
      this.schedules = MockSchedule.slice(0);
      this.positions = MockPosition.slice(0);
    } else {
      this.schedules = undefined;
      this.positions = undefined;
    }
    /* Initialize type MAPPERS */
    this.scheduleMapper = new RestTypeMapper<ISchedule, Schedule>();
    this.positionMapper = new RestTypeMapper<IPosition, Position>();
  }

  getSchedulesList(params: ScheduleHttpParamG): Observable<Schedule[]> {
    const thisApiID = 'getSchedulesList';
    if (this.MOCK_SERVICE) {
      return Observable.create((observer: Observer<Schedule[]>) => {
        observer.next(this.scheduleMapper.mapInterfaceToObject(this.schedules, Schedule) as Schedule[]);
        observer.complete();
      })
        .delay(this.MOCK_TIME);
    } else {
      return this.getRequest(params, this.scheduleMapper, thisApiID, Schedule);
    }
  }

  postSchedules(params: ScheduleHttpParamG): Observable<Schedule> {
    const thisApiID = 'postSchedules';
    if (this.MOCK_SERVICE) {
      return Observable.create((observer: Observer<Schedule>) => {
        /* Map data to interface type */
        const iSch: ISchedule = this.scheduleMapper.mapObjectToInterface(params.body);
        const maxId = Math.max(...this.schedules.map(sch => sch.idSchedule));
        iSch.idSchedule = maxId + 1;
        this.schedules.push(iSch);
        const response: Schedule = this.scheduleMapper.mapInterfaceToObject(iSch, Schedule) as Schedule;
        observer.next(response);
        observer.complete();
      });
    } else {
      const bodyToSend: ISchedule = this.scheduleMapper.mapObjectToInterface(params.body);
      return this.postRequest(bodyToSend, params, this.scheduleMapper, thisApiID, Schedule);
    }
  }

  getSchedulesCount(params: ScheduleHttpParamG): Observable<number> {
    const thisApiID = 'getSchedulesCount';
    if (this.MOCK_SERVICE) {
      return Observable.create((observer: Observer<number>) => {
        const numbers: number[] = this.schedules.map((s: Schedule) => s.scheduleNumber);
        const max = Math.max(...numbers);
        observer.next(max);
        observer.complete();
      });
    } else {
      return this.getCountRequest(params, thisApiID);
    }
  }

  getSchedulesById(params: ScheduleHttpParamG): Observable<Schedule> {
    const thisApiID: string = 'getSchedulesById';
    if (this.MOCK_SERVICE) {
      const idSchedule = +params.path.idSchedule;
      return Observable.create((observer: Observer<Schedule>) => {
        const findIdx: number = this.schedules.findIndex((sch: Schedule) => sch.idSchedule === +idSchedule);
        if (findIdx !== -1) {
          const schResult: Schedule = this.scheduleMapper.mapInterfaceToObject(this.schedules[findIdx], Schedule) as Schedule;
          observer.next(schResult);
          observer.complete();
        } else {
          observer.error('No such schedule ID: ' + idSchedule);
        }
      });
    } else {
      return this.getRequest(params, this.scheduleMapper, thisApiID, Schedule);
    }
  }

  putSchedulesById(params: ScheduleHttpParamG): Observable<Schedule> {
    const thisApiID = 'putSchedulesById';
    if (this.MOCK_SERVICE) {
      const updatedSchedule = params.body;
      /* DB operation simulation */
      return Observable.create((observer: Observer<Schedule>) => {
        const findIndex = this.schedules.findIndex((sch: Schedule, num: number): boolean => {
          if (sch.idSchedule === updatedSchedule.idSchedule) {
            return true;
          }
        });
        if (findIndex !== -1) {
          this.schedules[findIndex] = updatedSchedule as ISchedule;
          const response: Schedule = this.scheduleMapper.mapInterfaceToObject(this.schedules[findIndex], Schedule) as Schedule;
          observer.next(response);
          observer.complete();
        } else {
          observer.error('Unexisting index od schedule to update');
        }
      });
    } else {
      const bodyToSend = this.scheduleMapper.mapObjectToInterface(params.body);
      return this.putRequest(bodyToSend, params, this.scheduleMapper, thisApiID, Schedule);
    }
  }

  deleteSchedulesById(params: ScheduleHttpParamG): Observable<Schedule> {
    const thisApiID: string = 'deleteSchedulesById';
    if (this.MOCK_SERVICE) {
      const idToDelete: number = +params.path.idSchedule;
      return Observable.create((observer: Observer<Schedule>) => {
        const findIndex = this.schedules.findIndex((item: Schedule, num: number): boolean => item.idSchedule === idToDelete);
        if (findIndex === -1) {
          observer.error('Unexisting index od schedule to delete');
        } else {
          const deleted = this.schedules.splice(findIndex, 1)[0];
          observer.next(new Schedule(deleted));
          observer.complete();
        }
      });
    } else {
      return this.deleteRequest(params, this.scheduleMapper, thisApiID, Schedule);
    }
  }

  getSchedulesPositions(params: ScheduleHttpParamG): Observable<Position[]> {
    const thisApiID: string = 'getSchedulesPositions';
    if (this.MOCK_SERVICE) {
      const related = this.positions.filter(pos => pos.idSchedule === +params.path.idSchedule);
      return Observable.of(related.map((p: Position) => {
        return (this.positionMapper.mapInterfaceToObject(p, Position) as Position);
      }));
    } else {
      return this.getRequest(params, this.positionMapper, thisApiID, Position);
    }
  }
}
