import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'sv-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['../../../../sass/slider.sass', './slider.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class SliderComponent implements OnInit {
  someRange: number[] = [0, 1];
  private _tickInterval = 1;
  autoTicks = false;
  disabled = false;
  invert = false;
  max = 100;
  min = 0;
  showTicks = false;
  step = 1;
  thumbLabel = false;
  value = 0;
  vertical = false;
  constructor() { }

  get tickInterval(): number | 'auto' {
    return this.showTicks ? (this.autoTicks ? 'auto' : this._tickInterval) : 0;
  }
  set tickInterval(v) {
    this._tickInterval = Number(v);
  }

  ngOnInit() {
  }




}
