<form [formGroup]="addReadingsForm" (ngSubmit)="onFormSubmit($event)" class="dialog-form">
	<h3 class="dialog-title">{{name}}</h3>

	<h4 class="caption">{{labels.measurement}}</h4>
<div id="measurementDetails">
	<div class="row">
		<mat-form-field class="col">
			<input matInput formControlName="serialMeter" placeholder="Meter ID">
			<mat-error *ngIf="serialMeter.status === 'PENDING'">{{labels.checking}}</mat-error>
			<mat-error *ngIf="serialMeter.hasError('required')">{{labels.serialMeter}}
				<strong>{{labels.required}}</strong></mat-error>
			<mat-error *ngIf="serialMeter.hasError('nonExistingMeterPointSerial')">
				{{labels.enter}} <a (click)="goClientData()" class="link">{{labels.operationalData}}</a>
			</mat-error>
		</mat-form-field>

		<mat-form-field class="col">
			<mat-label>{{labels.chooseData}}</mat-label>
			<input matInput [matDatepicker]="picker" formControlName="date">
			<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
			<mat-datepicker #picker></mat-datepicker>
		</mat-form-field>
	</div>

	<div class="row">
		<mat-form-field class="col">
			<mat-select formControlName="selectedStatusFlagControl" placeholder="{{labels.status}}">
				<mat-option *ngFor="let measurement of selectStatusFlag" [value]="measurement.id">
					{{ measurement.name }}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>

	<div formArrayName="values" class="row">
		<mat-form-field class="col" *ngFor="let value of getValuesControls() let i = index">
			<div [formGroupName]="i">
				<input matInput formControlName="value" placeholder="{{units.units[i].description}}">
				<label matSuffix>[{{ unitHelper.getElementUnit(units.units[i].name, measurementsDefinitions) }}]</label>
			</div>
		</mat-form-field>
	</div>
</div>

	<h4 class="caption caption-btn">
		{{labels.optionalValues}}

		<button id="addOptionalValue" type="button" (click)="addOptionalValueField()" mat-icon-button color="primary"
			class="btn-new-position">
			<i class="material-icons notranslate">{{addMdIcon}}</i>
		</button>
	</h4>

	<mat-accordion formArrayName="optionalValues">
		<mat-expansion-panel id="optionalValue{{idx}}" [expanded]="isLast"
			*ngFor="let element of optionalValues.controls; let idx = index; last as isLast;" [formGroupName]="idx"
			hideToggle="true">
			<mat-expansion-panel-header class="header-btn">
				<mat-panel-title>{{labels.optionalValue}}</mat-panel-title>

				<mat-panel-description>
					<button id="deleteOptionalValue{{idx}}" type="button" (click)="deleteOptionalValueField(idx)" mat-icon-button color="primary">
						<i class="material-icons notranslate">{{removeMdIcon}}</i>
					</button>
				</mat-panel-description>
			</mat-expansion-panel-header>

			<div class="row">
				<mat-form-field class="col">
					<input matInput formControlName="name" placeholder="{{labels.name}}">
				</mat-form-field>

				<mat-form-field class="col">
					<input matInput formControlName="value" placeholder="{{labels.value}}">
				</mat-form-field>
			</div>
		</mat-expansion-panel>
	</mat-accordion>

	<div class="form-btn">
		<button type="button" mat-button color="primary" class="btn"
			(click)="dialogRef.close()">{{labels.cancel}}</button>
		<button id="save" type="submit" mat-raised-button color="accent" class="btn"
			[disabled]="addReadingsForm.invalid">{{labels.save}}</button>
	</div>
</form>