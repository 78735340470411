import { AfterViewInit, ElementRef, Renderer2, Directive, ChangeDetectorRef } from '@angular/core';
import { MeasurementMapperService } from './measurementMapper.service';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[existingMappingsDirective]'
})

export class ExistingMappingDirective implements AfterViewInit {

    constructor(private elRef: ElementRef, private renderer: Renderer2,
        private measurementMapperService: MeasurementMapperService,
        private cdr: ChangeDetectorRef) { }

    ngAfterViewInit(): void {
        setTimeout(() => {
            for (let j = 0; j < this.measurementMapperService.existingMappings.length; j++) {
                if (this.elRef.nativeElement.textContent === this.measurementMapperService.existingMappings[j].name) {
                    this.renderer.setStyle(this.elRef.nativeElement, 'font-weight', 'bold');
                    const id = this.elRef.nativeElement.id.replace(/\D/g, '');
                    this.measurementMapperService.exsistingMappingsTooltips[id] = true;
                    this.measurementMapperService.exsistingMappingsTooltipsSubject.next(this.measurementMapperService.exsistingMappingsTooltips);
                }
            }
            this.measurementMapperService.isExpansionPanelDisbaled = false;

        }, 1200);
    }
}
