
import { GenericHttpParams, IApiEndpointConfig } from '@shared/types/http/httpType';
import { FileOperationalData } from '@shared/models/appModels/fileOperationalData';

export const fileOperationalDataApiConf: IApiEndpointConfig = {
  rootApiPath: 'fileoperationaldata/',
  paths: [
    {/* 1 */
      url: '',
      swaggerId: 'getFileOperationaldataList',
      pathParams: [],
      responseIsArray: true,
    },
    {/* 2 */
      url: '',
      swaggerId: 'postFileOperationaldata',
      pathParams: [],
      responseIsArray: false,
    },
    {/* 3 */
      url: 'id/{idFileOperationalData}',
      swaggerId: 'getFileOperationaldataById',
      pathParams: [
        { name: 'idFileOperationalData', pattern: '{idFileOperationalData}'},
      ],
      responseIsArray: false,
    },
    {/* 4 */
      url: 'id/{idFileOperationalData}',
      swaggerId: 'deleteFileOperationaldataById',
      pathParams: [
        { name: 'idFileOperationalData', pattern: '{idFileOperationalData}' },
      ],
      responseIsArray: false,
    },
    {/* 5 */
      url: 'count',
      swaggerId: 'getFileOperationaldataListCount',
      pathParams: [],
      responseIsArray: false,
    }
  ]
};

export interface FileOperationalDataQueryParameters {
  [name: string]: string | string[];
  limit?:    string;
  offset?:   string;
  idFileOperationalData?: string[];
  svUtilityType?: string;
  sort?: string;
}

export interface FileOperationalDataHttpParamG extends GenericHttpParams<FileOperationalData> {
  queryParams: FileOperationalDataQueryParameters;
  reportProgress?: boolean;
  path?: {
    idFileOperationalData?:  string;
  };
  headers?: {
    svIdPosition?: string;
    svIdSchedule?: string;
  };
}
