<div id="summary" class="card alert alert-info">
	<div class="row">
		<div *ngIf="analysisEvent.address===userSelected">
			<span class="col filter">{{tableHeaders.METER_ID}}
				<b>{{analysisEvent.address}}</b>
			</span>
		</div>
		<div *ngIf="analysisEvent.address!==userSelected">
			<span class="col filter">{{tableHeaders.LOCATION}}
				<b>{{analysisEvent.address}}</b>
			</span>
		</div>
		<span class="col filter">
			<div *ngIf="analysisEvent.aggregation === 'h'">
				{{fieldsName.DateFrom}}:
				<b>{{analysisEvent.startDate | svDateTime}}</b>
			</div>
			<div *ngIf="analysisEvent.aggregation === 'd'">
				{{fieldsName.DateFrom}}:
				<b>{{analysisEvent.startDate | svDate}}</b>
			</div>
		</span>
		<span class="col filter">
			<div *ngIf="analysisEvent.aggregation === 'h'">
				{{fieldsName.DateTo}}:
				<b>{{analysisEvent.endDate | svDateTime}}</b>
			</div>
			<div *ngIf="analysisEvent.aggregation === 'd'">
				{{fieldsName.DateTo}}:
				<b>{{analysisEvent.endDate | svDate}}</b>
			</div>
		</span>
		<span class="col filter">
			<div *ngIf="analysisEvent.aggregation === 'm'">
				{{fieldsName.AGGREGATION}}:
				<b>{{fieldsName.MONTH}}</b>
			</div>
			<div *ngIf="analysisEvent.aggregation === 'd'">
				{{fieldsName.AGGREGATION}}:
				<b>{{fieldsName.DAY}}</b>
			</div>
			<div *ngIf="analysisEvent.aggregation === 'h'">
				{{fieldsName.AGGREGATION}}:
				<b>{{fieldsName.HOUR}}</b>
			</div>
		</span>
	</div>
</div>

<h2>{{summary}}</h2>

<div class="card table-summary table-filter">
	<div class="card-header input-filter">
		<div [formGroup]="changeFilterForm">
			<mat-form-field id="meterFilter" class="form-group">
				<input matInput type="text" placeholder="{{tableHeaders.METER_ID}}" formControlName="meter">
			</mat-form-field>
			<mat-form-field id="locationFilter" class="form-group">
				<input matInput type="text" placeholder="{{tableHeaders.LOCATION}}" formControlName="address">
			</mat-form-field>

			<label id="deviation" *ngIf="actualAnalysisAlgorithm.algorithmType === 0" style="size: 8px;  color:gray;">{{deviation}}:
				{{sliderValue}}%
				<mat-slider thumbLabel="true" min="0" max="200.0" step="10" value="0"
					(input)="changeSliderValue($event)"></mat-slider>
			</label>
		</div>
	</div>
	<mat-table id="mainTable" #table [dataSource]="dataSourceAnalysis">
		<ng-container *ngFor="let column of columns.getDynamicColumns(); index as i" [matColumnDef]="column.columnDef">
			<mat-header-cell *matHeaderCellDef>{{ column.header }}</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<!-- Row is measurementData type -->
				<div *ngIf="column.type === 'idMeter'">
					{{row.serialNumber}}
				</div>
				<div *ngIf="column.type === 'location'">
					{{row.meterPlacement}}
				</div>
				<div *ngIf="column.type === 'measurementType'">
					{{row.getMesurementTypeaAsString()}}
				</div>
				<div *ngIf="column.type === 'customer'">
					{{row.customer ? row.customer.denotation : ''}}
				</div>
				<div *ngIf="column.type === 'segment'">
					{{row.customer ? element.customer.getCustomerSegmentAsString() : ''}}
				</div>
			</mat-cell>
		</ng-container>

		<!-- Action Column -->
		<ng-container matColumnDef="action">
			<mat-header-cell *matHeaderCellDef class="text-right">{{tableHeaders.ACTIONS}}</mat-header-cell>
			<mat-cell *matCellDef="let row" class="text-right">
				<i
					class="material-icons notranslate">{{ isThisDetailElement(row) ? hideDetailsMdIcon : seeDetailsMdIcon }}</i>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="expandedDetail">
			<mat-cell *matCellDef="let detailRow" class="detail-data">
				<ng-container *ngIf="detailsVisible(detailRow)">
					<sv-detail [detail]="detailRow" [scheduleId]="scheduleId" [positionId]="positionId"
						[analysisEvent]="analysisEvent" [isDataInAlgorithm]="isDataInAlgorithm" [start]="start"
						[end]="end" [actualAnalysisAlgorithm]="actualAnalysisAlgorithm" [title]="title"></sv-detail>
				</ng-container>
			</mat-cell>
		</ng-container>
		<mat-header-row *matHeaderRowDef="columns.getAllColumnDef()"></mat-header-row>
		<mat-row *matRowDef="let row; columns: columns.getAllColumnDef();" matRipple class="element-row"
			[class.expanded]="isThisRowExpanded(row)" (click)="handleMainRowClick(row)"></mat-row>

		<mat-row *matRowDef="let detailRow; columns: ['expandedDetail']; when: rowCanBeExpanded"
			[@detailExpand]="isThisRowExpanded(detailRow) ? 'expanded' : 'collapsed' "></mat-row>
	</mat-table>
	<div class="no-result" [style.display]="!isLoading() && getDataSourceLength() === 0 ? '' : 'none'">
		{{noData}}
	</div>

	<div class="no-result" [style.display]="isLoading() ? '' : 'none'">
		<mat-spinner [strokeWidth]="3" [diameter]="20" class="notranslate"></mat-spinner>
	</div>

	<mat-paginator #paginator [length]="getDataSourceLength()" [pageIndex]="0" [pageSize]="10"
		[pageSizeOptions]="[5, 10, 20]" showFirstLastButtons class="notranslate">
	</mat-paginator>
</div>