// Angular modules import
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RjJS imports
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/pluck';
import 'rxjs/add/operator/max';
import 'rxjs/add/operator/do';
import 'rxjs/add/observable/throw';

import { environment } from '@env/environment';

import { RestTypeMapper } from '@shared/models/RestSupport/restTypeMapper.model';
import { IFileMeterData, FileMeterData } from '@shared/models/appModels/fileMeasurementData.model';

import { FileMeterDataHttpParamG } from '@shared/types/http/fileMeterDataHttpConfig';
import { EndpointsEnum } from '@shared/types/http/endpointEnum';
import { IApiEndpointConfig } from '@shared/types/http/httpType';
// Application services
import { CommonAppDataService } from '@shared/services/commonAppData.service';
// Mock
import { MockFileMeterData } from '@shared/mock';
import { RequestService } from './requestService.class';

@Injectable()
export class FileMeterDataService extends RequestService {

  mockFileMeterData: FileMeterData[];

  apiCfg: IApiEndpointConfig;
  fileMeterDataMapper: RestTypeMapper<IFileMeterData, FileMeterData>;

  /* Debug data */
  readonly debugMode: boolean = environment.debug;
  readonly apiPatching: boolean = false;

  constructor(http: HttpClient, cs: CommonAppDataService) {

    super(http, cs, EndpointsEnum.FILE_METER_DATA);
    if (this.MOCK_SERVICE) {
      this.mockFileMeterData = MockFileMeterData.slice(0).map(v => new FileMeterData(v) );
    } else {
      this.mockFileMeterData = undefined;
    }
    /* Get API configuration from common service */
    this.apiCfg = cs.getApiEndpointConfig(EndpointsEnum.FILE_METER_DATA);

    /* Initialize type MAPPERS */
    this.fileMeterDataMapper = new RestTypeMapper<IFileMeterData, FileMeterData>();
  }

  getFileMeterDataList(params: FileMeterDataHttpParamG = { queryParams: { limit: 50, offset: 0 } }): Observable<FileMeterData[]> {
    const thisApiID = 'getFilemeterdataList';
    if (this.MOCK_SERVICE) {
      return Observable.of(new Object())
        .mapTo(this.mockFileMeterData)
        .delay(this.MOCK_TIME)
        .catch((error: any) => Observable.throw(error || 'Server error'));
    } else {
      return this.getRequest(params, this.fileMeterDataMapper, thisApiID, FileMeterData);
    }
  }

  postFileMeterData(params: FileMeterDataHttpParamG): Observable<FileMeterData> {
    const thisApiID = 'postFilemeterdataList';
    if (this.MOCK_SERVICE) {
      const item = params.body;
      const maxId = Math.max(...this.mockFileMeterData.map(file => +file.idFileMeterData));
      item.idFileMeterData = maxId + 1;
      this.mockFileMeterData.forEach((el: FileMeterData, idx: number) => {
        if (el.id === item.id) { throw new Error(`Customer with ID ${el.id} already exists`); }
      });
      this.mockFileMeterData.push(item);
      const obs: Observable<FileMeterData> = Observable.of(item);
      return obs;
    } else {
      const formData = new FormData();
      formData.append('upfile', params.body.nativeFile, params.body.nativeFile.name);
      params.reportProgress = true;
      return this.postFileRequest(formData, params, this.fileMeterDataMapper, thisApiID, FileMeterData);
    }
  }

  getFileMeterDataById(params: FileMeterDataHttpParamG): Observable<FileMeterData> {
    const thisApiID = 'getFilemeterdataById';
    if (this.MOCK_SERVICE) {
      throw new Error('Missing mock implementation');
    } else {
      return this.getRequest(params, this.fileMeterDataMapper, thisApiID, FileMeterData);
    }
  }

  deleteFileMeterDataById(params: FileMeterDataHttpParamG): Observable<FileMeterData> {
    const thisApiID = 'deleteFilemeterdataById';
    if (this.MOCK_SERVICE) {
      throw new Error('Missing mock implementation');
    } else {
      return this.deleteRequest(params, this.fileMeterDataMapper, thisApiID, FileMeterData);
    }
  }

  getFileMeterdataListCount(params: FileMeterDataHttpParamG): Observable<number> {
    const thisApiID = 'getFilemeterdataListCount';
    if (this.MOCK_SERVICE) {
      return Observable.of(this.mockFileMeterData.length);
    } else {
      return this.getCountRequest(params, thisApiID);
    }
  }

  getBillingFile(params: FileMeterDataHttpParamG): Observable<File> {
    const thisApiID = 'getBillingFile';
    if (this.MOCK_SERVICE) {
      throw new Error('Missing mock implementation');
    } else {
      return this.getCsvFile(params, thisApiID);
    }
  }
}
