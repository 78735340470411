// Angular modules import
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RjJS imports
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/pluck';
import 'rxjs/add/operator/max';
import 'rxjs/add/operator/do';
import 'rxjs/add/observable/throw';

import { environment } from '@env/environment';

import { RestTypeMapper } from '@shared/models/RestSupport/restTypeMapper.model';
import { FileTemperatureData, IFileTemperatureData } from '@shared/models/appModels/fileTemperatureData';

import { EndpointsEnum } from '@shared/types/http/endpointEnum';

// Application services
import { CommonAppDataService } from '@shared/services/commonAppData.service';
// Mock

import { RequestService } from './requestService.class';
import { FileTemperatureDataHttpParamG } from '@shared/types/http/fileTemperaturesDataHttpConfig';
import { MockFileTemperatureData } from '@shared/mock/fileTemperatureData.mock';
import { TemperatureData, ITemperatureData } from '@shared/models/appModels/temperatureData.model';

@Injectable()
export class FileTemperatureDataService extends RequestService {

  MockfileTemperatureData: IFileTemperatureData[] = [];
  MockTemperatureData: ITemperatureData[] = [];

  fileTemperatureDataMapper: RestTypeMapper<IFileTemperatureData, FileTemperatureData>;
  temperatureDataMapper: RestTypeMapper<ITemperatureData, TemperatureData>;

  debugMode: boolean = environment.debug;
  apiPatching: boolean = false;

  constructor(http: HttpClient, cs: CommonAppDataService) {
    super(http, cs, EndpointsEnum.FILE_TEMPERATUTE_DATA);
    if (this.MOCK_SERVICE) {
      this.MockfileTemperatureData = MockFileTemperatureData.slice(0);
    }
    this.fileTemperatureDataMapper = new RestTypeMapper<IFileTemperatureData, FileTemperatureData>();
    this.temperatureDataMapper = new RestTypeMapper<ITemperatureData, TemperatureData>();
  }

  getTemperatureDataList(
    params: FileTemperatureDataHttpParamG = {
      queryParams: { limit: '50', offset: '0' },
      headers: {}
    }): Observable<TemperatureData[]> {
    const thisApiID = 'getTemperatureDataList';
    if (this.MOCK_SERVICE) {
      return Observable.of(new Object())
        .mapTo(this.MockTemperatureData)
        .map<ITemperatureData[], TemperatureData[]>(arr => {
          return arr.map(el => this.temperatureDataMapper.mapInterfaceToObject(el, TemperatureData) as TemperatureData);
        })
        .delay(this.MOCK_TIME)
        .catch((error: any) => Observable.throw(error || 'Server error'));
    } else {
      return this.getRequest(params, this.temperatureDataMapper, thisApiID, TemperatureData);
    }
  }

  postFileTemperatureData(params: FileTemperatureDataHttpParamG): Observable<FileTemperatureData> {
    const thisApiID = 'postFileTemperatureData';
    if (this.MOCK_SERVICE) {
      const item = params.body;
      this.MockfileTemperatureData.push(item);
      return Observable.of(item);
    } else {
      const formData = new FormData();
      formData.append('upfile', params.body.nativeFile, params.body.nativeFile.name);
      params.reportProgress = true;
      return this.postFileRequest(formData, params, this.fileTemperatureDataMapper, thisApiID, FileTemperatureData);
    }
  }

  getTemperatureDataListCount(params: FileTemperatureDataHttpParamG): Observable<number> {
    const thisApiID = 'getTemperatureDataListCount';
    if (this.MOCK_SERVICE) {
      const relatedData = this.MockTemperatureData;
      return Observable.of(relatedData.length);
    } else {
      return this.getCountRequest(params, thisApiID);
    }
  }
}
