import { Directive, ElementRef, OnInit, OnDestroy, HostListener } from '@angular/core';
import { UploadOutput, UploadOutputType } from '../classes/interfaces';

import { UploadClass } from './UploadClass.class';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[ngFileDrop]'
})
export class NgFileDropDirective extends UploadClass implements OnInit, OnDestroy {

  constructor(elementRef: ElementRef) {
    super(elementRef);
  }

  ngOnInit() {
    super.ngOnInit();
    this.el.addEventListener('drop', this.stopEvent, false);
    this.el.addEventListener('dragenter', this.stopEvent, false);
    this.el.addEventListener('dragover', this.stopEvent, false);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  stopEvent = (e: Event) => {
    e.stopPropagation();
    e.preventDefault();
  }

  @HostListener('drop', ['$event'])
  public onDrop(e: any) {
    e.stopPropagation();
    e.preventDefault();

    const event: UploadOutput = { type: 'drop' };
    this.uploadOutput.emit(event);
    this.upload.handleFiles(e.dataTransfer.files);
  }

  @HostListener('dragover', ['$event'])
  public onDragOver(e: Event) {
    this.emitEvent(e, 'dragOver');
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(e: Event) {
    this.emitEvent(e, 'dragOut');
  }

  emitEvent(e: Event, type: UploadOutputType) {
    if (!e) {
      return;
    }
    const event: UploadOutput = { type: type };
    this.uploadOutput.emit(event);
  }
}
