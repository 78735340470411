import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

import { DataValidationCreateComponent } from '../create/data-validation-create.component';
import { VeeRule } from '@shared/models/appModels/VeeRule.model';

@Injectable()
export class DataValidationDialogsService {

    constructor(private dialog: MatDialog) { }

    public invokeCreateRuleModal(data?: VeeRule): Observable<any> {

        let dialogRef: MatDialogRef<DataValidationCreateComponent, VeeRule>;
        dialogRef = this.dialog.open(DataValidationCreateComponent, {
            panelClass: 'dialog-overlay',
            data: {
                veeRule: data
            },
            restoreFocus: false,
        });
        return dialogRef.afterClosed();
    }
}
