import { ISensorData } from '@shared/models/appModels/sensorData.model';

export const MockSensorData: ISensorData[] = [
  {
    id: 1,
    beginDate: new Date('2016-11-01').getTime(),
    endDate: new Date('2016-12-01').getTime(),
    active: true,
    interval: 1
  },
];
