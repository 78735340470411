import { IGetInterfaceType } from '@shared/types/applicationTypes';

export class RestTypeMapper<Ifc, Obj extends Ifc & IGetInterfaceType<Ifc, Obj>> {

  constructor() {}

  mapInterfaceToObject(iIn: Ifc | Ifc[] , o: new(iIn: Ifc) => Obj): Obj | Obj[] {
    if (!Array.isArray(iIn)) {
      return new o(iIn);
    } else {
      return this.mapInterfaceArrToObjectArr(iIn, o);
    }
  }

  mapObjectToInterface(o: Obj, webApi: boolean = true): Ifc {
      return o.getInterface(webApi);
  }

  private mapInterfaceArrToObjectArr(iInArray: Ifc[], o: new(iIn: Ifc) => Obj): Obj[] {
    const retArr: Obj[] = [];
    iInArray.forEach((iIn: Ifc) => {
      retArr.push(new o(iIn));
    });
    return retArr;
  }

}
