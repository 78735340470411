import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';

import { RestTypeMapper } from '@shared/models/RestSupport/restTypeMapper.model';

import { CommonAppDataService } from '@shared/services/commonAppData.service';
import { EndpointsEnum } from '@shared/types/http/endpointEnum';
import { UtilityTypeEnum } from '@shared/types/utilityType';
/* MOCKS */

import { MediaTypeParameter, IMediaTypeParameter } from '@shared/models/appModels/MediaTypeParameter.mode';
import { parameterMeasurement_mock, parameterMeterPoint_mock } from '@shared/mock/parameters.mock';
import { ParameterHttpParamG } from '@shared/types/http/parameterHttpConfig';
import { RequestService } from './requestService.class';

@Injectable()
export class ParametersService extends RequestService {

  parametersMeasurementsMock: IMediaTypeParameter[] = [];
  parametersMeterPointMock: IMediaTypeParameter[] = [];
  unitsMapper: RestTypeMapper<IMediaTypeParameter, MediaTypeParameter>;


  constructor(
    http: HttpClient,
    cs: CommonAppDataService) {
    super(http, cs, EndpointsEnum.PARAMETERS);
    if (this.MOCK_SERVICE) {
      this.parametersMeasurementsMock = parameterMeasurement_mock;
      this.parametersMeasurementsMock = parameterMeterPoint_mock;
    } else {
      this.parametersMeasurementsMock = undefined;
      this.parametersMeasurementsMock = undefined;
    }
    /* Initialize type MAPPERS */
    this.unitsMapper = new RestTypeMapper<IMediaTypeParameter, MediaTypeParameter>();
  }

  getOperationalDataModel(params: ParameterHttpParamG): Observable<MediaTypeParameter[]> {
    return this.getMediaTypeParameters(params, 'getOperationalDataModel');
  }

  getMediaTypeParameters(params: ParameterHttpParamG, thisApiID: string): Observable<MediaTypeParameter[]> {
    if (this.MOCK_SERVICE) {
      const mediaIdx = UtilityTypeEnum[params.queryParams.svUtilityType.toUpperCase()];
      const ret = new MediaTypeParameter(this.parametersMeasurementsMock[mediaIdx]);
      return Observable.of([ret]);
    } else {
      return this.getRequest(params, this.unitsMapper, thisApiID, MediaTypeParameter);
    }
  }

}
