import { INameMapper, IPrintableNameMapper } from '@shared/types/applicationTypes';
import { environment as env } from '@env/environment';
import { typesLocalisation as loc } from '@shared/types/localisation';

export enum MeasurementTypeEnum {
  'TELEMETRY' = 0,
  'MANUAL' = 1,
  'OTHER' = 2,
  'DEFAULT'
}

export enum MeterNodeTypeEnum {
  'METER' = 0,
  'BALANCING' = 1,
  'SUBMETER' = 2,
  'VIRTUAL' = 3,
  'OTHER' = 4,
}

export const MeasurementTypeNames: INameMapper[] =
[
 {id: MeasurementTypeEnum.TELEMETRY, name: loc.MeasurementTypeNames[env.language].MeasurementType.TELEMETRY, classType: 'telemetry'},
 {id: MeasurementTypeEnum.MANUAL, name: loc.MeasurementTypeNames[env.language].MeasurementType.MANUAL, classType: 'manual'},
 {id: MeasurementTypeEnum.OTHER, name: loc.MeasurementTypeNames[env.language].MeasurementType.OTHER, classType: 'manual-mes'},
 {id: MeasurementTypeEnum.DEFAULT, name: loc.MeasurementTypeNames[env.language].MeasurementType.DEFAULT, classType: 'manual-mes'}
];

export const MeterNodeTypeNames: IPrintableNameMapper[] =
[
 {id: MeterNodeTypeEnum.METER, name: loc.MeterNodeTypeNames[env.language].MeterNodeType.METER, classType: 'master', isPrintable: true},
 {id: MeterNodeTypeEnum.BALANCING, name: loc.MeterNodeTypeNames[env.language].MeterNodeType.BALANCING, classType: 'balancing', isPrintable: true},
 {id: MeterNodeTypeEnum.SUBMETER, name: loc.MeterNodeTypeNames[env.language].MeterNodeType.SUBMETER, classType: 'submeter', isPrintable: true},
 {id: MeterNodeTypeEnum.VIRTUAL, name: loc.MeterNodeTypeNames[env.language].MeterNodeType.VIRTUAL, classType: 'virtual', isPrintable: true},
 {id: MeterNodeTypeEnum.OTHER, name: loc.MeterNodeTypeNames[env.language].MeterNodeType.OTHER, classType: 'defaulr-meter', isPrintable: false}
];


