import { IParameters, ArrayedParameters, ParameterPrimityveType } from '@shared/types';
import { BaseModel } from '@shared/models/baseModel';
import { FormArray, FormControl, FormGroup, AbstractControl } from '@angular/forms';
import { MeasurementDataModel } from './appModels/measurementDataModel.model';

interface IParm {
  name: string;
  value: ParameterPrimityveType;
  unit?: any;
}

export abstract class BaseOptionalParamsMapper extends BaseModel {
  constructor() {
    super();
  }

  static parseParametersFromForm(
    inputControl: FormArray,
    names: {
      nameControlName: string, valueControlName: string, keyControlName: string
    } =
      { keyControlName: 'key', nameControlName: 'name', valueControlName: 'value' },
    config: { dropEmpty: boolean } = { dropEmpty: true }
  ): IParameters {
    if (!inputControl.controls.every((ele: AbstractControl, idx: number) => {
      return (typeof (ele.get(names.nameControlName)) !== 'undefined' &&
        typeof (ele.get(names.valueControlName)) !== 'undefined' &&
        typeof (ele.get(names.keyControlName)) !== 'undefined');
    })) {
      throw new Error('Control ' + inputControl + ' is missing required optional parameters fields');
    }
    const optParams = inputControl.controls
      .reduce<IParameters>((curr: IParameters, fc: FormGroup) => {
        if (config.dropEmpty && BaseOptionalParamsMapper.isParamControlEmpty(fc, names)) {
          return { ...curr };
        } else {
          const tmp = { ...curr, [fc.get(names.keyControlName).value as string]: fc.get(names.valueControlName).value };
          return tmp;
        }
      }, {} as IParameters);
    return optParams;
  }

  static generateEmptyParamControl(names: {
    nameControlName: string, valueControlName: string, keyControlName: string
  } =
    { keyControlName: 'key', nameControlName: 'name', valueControlName: 'value' }): FormGroup {
    return (new FormGroup({
      [names.keyControlName]: new FormControl(''),
      [names.valueControlName]: new FormControl(''),
      [names.nameControlName]: new FormControl(''),
    }));
  }

  static isParamControlEmpty(fc: FormGroup,
    names: { nameControlName: string, valueControlName: string, keyControlName: string } =
      { keyControlName: 'key', nameControlName: 'name', valueControlName: 'value' },): boolean {
    return (fc.get(names.nameControlName).value as string === '' || fc.get(names.nameControlName).value === null);
  }

  getParamsByKeyList(keys: string[], fieldToSearch: IParameters): IParameters {
    const ret = {} as IParameters;
    keys.forEach((k: string) => {
      if (typeof (fieldToSearch[k]) !== 'undefined') {
        ret[k] = fieldToSearch[k];
      }
    });
    return ret;
  }

  getParamByKey(key: string, fieldToSearch: IParameters): { [key: string]: ParameterPrimityveType } | undefined {
    return (typeof (fieldToSearch[key]) !== 'undefined') ? { [key]: fieldToSearch[key] } : undefined;
  }

  generateValueControl(key: string, value: any, paramDesc: any): FormGroup {
    const pVal = typeof (paramDesc) !== 'undefined' ? paramDesc.description : key;
    return (new FormGroup({
      name: new FormControl(pVal),
      value: new FormControl(value),
      key: new FormControl(key),
    }));
  }

  public convertParamsToArray(paramObj: IParameters, measDefinitions?: MeasurementDataModel[]): ArrayedParameters[] {
    const keys = Object.keys(paramObj);
    let ret: ArrayedParameters[] = [];
    ret = keys.map<ArrayedParameters>((key: string) => {
      let unit;
      if (measDefinitions) {
        const measDefinition = measDefinitions.find(element => element.name === key);
        if (measDefinition) {
          unit = measDefinition.unit;
        }
      }
      let parm: IParm;
      parm = {
        name: key,
        value: paramObj[key],
      };
      if (typeof (unit) !== 'undefined') {
        parm.unit = unit;
      }
      return parm;
    });
    return ret;
  }

  public nonEmptyParams(field: IParameters): boolean {
    return Object.keys(field).length > 0;
  }

  public getParamsKeys(field: IParameters): string[] {
    return Object.keys(field);
  }

  public isParamPresent(key: string, field: IParameters): boolean {
    return typeof (field[key]) !== 'undefined';
  }

  public getParamsToRestApi(paramObj: IParameters | ArrayedParameters[], fallbackValue?: IParameters): IParameters {
    if (paramObj) {
      if (Array.isArray(paramObj)) {
        return paramObj.reduce<IParameters>((accum, val) => {
          const tmp = { ...accum };
          if (typeof (val.name) !== 'undefined') {
            tmp[val.name] = val.value;
          } else {
            console.warn('Missing \'name\' index');
          }
          return tmp;
        }, {});
      } else {
        return Object.assign({}, paramObj);
      }
    } else {
      return fallbackValue;
    }
  }

}
