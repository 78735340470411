<h3 class="dialog-title">{{assetLabels.assign}} {{serialNumber}}</h3>
<mat-table id="assignCustomer" #table [dataSource]="dataSource">

  <ng-container matColumnDef="id">
    <mat-header-cell *matHeaderCellDef>{{assetLabels.id}}</mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.idCustomer}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="denotation">
    <mat-header-cell *matHeaderCellDef>{{assetLabels.denotation}}</mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.denotation}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="segment">
    <mat-header-cell *matHeaderCellDef>{{assetLabels.tableHeaders.SEGMENT}}</mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.getCustomerSegmentAsString()}}</mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;" class="clicable-row" (click)="onAssignCustomer(row)">
  </mat-row>
</mat-table>

<mat-paginator #paginator [length]="dataLength" [pageIndex]="0" [pageSize]="10" [pageSizeOptions]="[5, 10, 20]"
  showFirstLastButtons
  class="notranslate">
</mat-paginator>