import { Observable } from 'rxjs/Observable';
import { CommonAppDataService } from '../commonAppData.service';
import { EndpointsEnum, IntegerResult } from '@shared/types';
import { environment } from '@env/environment';
import { BaseHttpService } from '@shared/models/baseHttpService.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpResponse } from '@angular/common/http';
import { RestTypeMapper, HttpErrorHandler } from '@shared/models/RestSupport';
import { concatMap, catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';

export class RequestService extends BaseHttpService<any, any> {
    constructor(private http: HttpClient,
        cs: CommonAppDataService, endpointsEnum: EndpointsEnum) {
        super(environment.servcesConfiguration[endpointsEnum], cs);
    }

    public getRequest(params: any, anyMapper: RestTypeMapper<any, any>, thisApiID: string, classMap: any, basePath?: any): Observable<any> {
        const url = this.getParametrizedUrl(thisApiID, params.path);
        if (this.VERBOSE) { console.log(`Query request to ${url}`); }
        return this.http.get<any>(url, { observe: 'response', headers: params.headers, params: params.queryParams }).pipe(
            concatMap<HttpResponse<any>, any>((resp: HttpResponse<any>) => {
                if (this.VERBOSE) { console.log(resp); }
                return Observable.of<any>(anyMapper.mapInterfaceToObject(resp.body, classMap));
            }),
            catchError(new HttpErrorHandler<any, any>().handleError(params, this.isApiArrayed(thisApiID, this.apiCfg))));
    }

    public getCountRequest(params: any, thisApiID: string): Observable<number> {
        const url = this.getParametrizedUrl(thisApiID, params.path);
        return this.http.get<IntegerResult>(url, { observe: 'response', params: params.queryParams, headers: params.headers }).pipe(
            concatMap<HttpResponse<IntegerResult>, Observable<number>>((resp: HttpResponse<IntegerResult>) => {
                return of(resp.body.value);
            }),
            catchError(new HttpErrorHandler<number, any>().handleError(params, this.isApiArrayed(thisApiID, this.apiCfg))));
    }

    public postRequest(reqBody: any, params: any, anyMapper: RestTypeMapper<any, any>, thisApiID: string, classMap: any, basePath?: any): Observable<any> {
        const url = this.getParametrizedUrl(thisApiID, params.path);
        if (this.VERBOSE) { this.requestLogger(thisApiID, url, params); }
        return this.http.post<any>(url, reqBody, { observe: 'response', params: params.queryParams, headers: params.headers }).pipe(
            concatMap<HttpResponse<any>, any>((resp) => {
                if (this.VERBOSE) { console.log(resp); }
                return of<any>(anyMapper.mapInterfaceToObject(resp.body, classMap));
            }),
            catchError(new HttpErrorHandler<any, any>().handleError(params, this.isApiArrayed(thisApiID, this.apiCfg))));
    }

    public postFileRequest(reqBody: any, params: any, anyMapper: RestTypeMapper<any, any>, thisApiID: string, classMap: any, basePath?: any): Observable<any> {
        const url = this.getParametrizedUrl(thisApiID, params.path);
        if (this.VERBOSE) { this.requestLogger(thisApiID, url, params); }
        return this.http.post<any>(url, reqBody, { observe: 'events', params: params.queryParams, headers: params.headers, reportProgress: true }).pipe(
            catchError(new HttpErrorHandler<any, any>().handleError(params, this.isApiArrayed(thisApiID, this.apiCfg))));
    }

    public putRequest(reqBody: any, params: any, anyMapper: RestTypeMapper<any, any>, thisApiID: string, classMap: any, basePath?: any): Observable<any> {
        const url = this.getParametrizedUrl(thisApiID, params.path);
        if (this.VERBOSE) { this.requestLogger(thisApiID, url, params); }
        return this.http.put<any>(url, reqBody, { observe: 'response', headers: params.headers, params: params.queryParams })
            .concatMap<HttpResponse<any>, any>((resp) => {
                if (this.VERBOSE) { console.log(resp); }
                return Observable.of<any>(anyMapper.mapInterfaceToObject(resp.body, classMap));
            })
            .catch(new HttpErrorHandler<any, any>().handleError(params, this.isApiArrayed(thisApiID, this.apiCfg)));
    }

    public deleteRequest(params: any, anyMapper: RestTypeMapper<any, any>, thisApiID: string, classMap: any, basePath?: any): Observable<any> {
        const url = this.getParametrizedUrl(thisApiID, params.path);
        this.requestLogger(thisApiID, url, params);
        return this.http.delete<any>(url, { observe: 'response', params: this.getQueryParams(params), headers: this.getHeaders(params) })
            .concatMap<HttpResponse<any>, any>((resp: HttpResponse<any>) => {
                this.responseLogger(resp);
                return Observable.of<any>(anyMapper.mapInterfaceToObject(resp.body, classMap));
            })
            .catch(new HttpErrorHandler<any, any>().handleError(params, this.isApiArrayed(thisApiID, this.apiCfg)));
    }

    // tslint:disable-next-line: max-line-length
    public deleteMeasurementMappingRequest(params: any, anyMapper: RestTypeMapper<any, any>, thisApiID: string, classMap: any, basePath?: any): Observable<any> {
      const url = this.getParametrizedUrl(thisApiID, params.path);
      this.requestLogger(thisApiID, url, params);
        const httpDeleteOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            body: params.body
        };
        return this.http.delete<any>(url, httpDeleteOptions)
            .concatMap<HttpResponse<any>, any>((resp: HttpResponse<any>) => {
                this.responseLogger(resp);
                return Observable.of<any>(resp.body);
            })
            .catch(new HttpErrorHandler<any, any>().handleError(params, this.isApiArrayed(thisApiID, this.apiCfg)));
    }

    getCsvFile(params: any, thisApiID: string, fileName?: string): Observable<File> {
        const url = this.getParametrizedUrl(thisApiID, params.path);
        this.requestLogger(thisApiID, url, params);
        let apiCall;
        if(thisApiID === 'getBillingFile'){
        apiCall = this.http.get(url, {
          observe: 'response',
          responseType: 'blob',
          params: this.getQueryParams(params),
          headers: this.getHeaders(params).set('Accept', 'text/csv') })
        } else {
            apiCall = this.http.post<any>(url, params.body, {
                observe: 'response',
                responseType: 'blob' as 'json',
                params: this.getQueryParams(params),
                headers: this.getHeaders(params).set('Accept', 'text/csv') })
        }
        return apiCall.pipe(
            map((resp:HttpResponse<any>) => {
                const headers = resp.headers;
                let name = fileName ? fileName : 'Billing.csv';
                const contentDispositionHeader = headers.get('Content-Disposition');
                if (contentDispositionHeader !== null) {
                    const value = contentDispositionHeader.split(';');
                    if (value.length >= 1) {
                        name = value[1].trim().split('=')[1];
                    }
                }
                return new File([resp.body], name, { type: headers.get('content-type') });
            }))
            .catch(new HttpErrorHandler<File, any>().handleError(params, this.isApiArrayed(thisApiID, this.apiCfg)));
    }
}
