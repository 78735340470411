// Angular modules
import { NgModule } from "@angular/core";
import { SettingsAlgorithmsComponent } from "./settings-algorithms/settings-algorithms.component";
import { SettingsApplicationComponent } from "./settings-application/settings-application.component";
import { SettingsRulesComponent } from "./settings-rules/settings-rules.component";
import { SettingsDataModelComponent } from "./settings-dataModel/settings-dataModel.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTableModule } from "@angular/material/table";
import { CommonModule } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatTabsModule } from "@angular/material/tabs";
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatRadioModule } from "@angular/material/radio";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { DataModelCreateVariableComponent } from "./settings-dataModel/create-variable/dataModel-variable-create.component";
import { DataModelEditVariableComponent } from "./settings-dataModel/edit-variable/dataModel-variable-edit.component";
import { MatMomentDateModule } from "@angular/material-moment-adapter";

@NgModule({
  declarations: [
    SettingsAlgorithmsComponent,
    SettingsApplicationComponent,
    SettingsRulesComponent,
    SettingsDataModelComponent,
    DataModelCreateVariableComponent,
    DataModelEditVariableComponent,
  ],
  exports: [
    SettingsAlgorithmsComponent,
    SettingsApplicationComponent,
    SettingsRulesComponent,
    SettingsDataModelComponent,
    DataModelCreateVariableComponent,
    DataModelEditVariableComponent,
  ],
  imports: [
    MatTabsModule,
    MatExpansionModule,
    CommonModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatSelectModule,
    MatMomentDateModule,
    MatDatepickerModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatMenuModule,
    MatIconModule,
    MatRadioModule,
    MatCheckboxModule,
    MatButtonModule,
  ],
  entryComponents: [
    DataModelCreateVariableComponent,
    DataModelEditVariableComponent,
  ],
})
export class SettingsModule {}
