
import { CustomerSegmentType, CustomerSegmentNames } from '@shared/types/modelTypes/customerTypes';
import { RestEnumMpapper } from '@shared/models/RestSupport/restEnumMapper.model';
import { IGetInterfaceType } from '@shared/types/applicationTypes';
import { BaseModel } from '@shared/models/baseModel';


export interface ICustomer {
  idCustomer: number;
  idFileOperationalData: number;
  denotation: string;
  segmentType: CustomerSegmentType | string;
  active: boolean;
}


export class Customer extends BaseModel implements ICustomer, IGetInterfaceType<ICustomer, Customer> {

  private static typeOfEnumMapper: RestEnumMpapper<typeof CustomerSegmentType> = new RestEnumMpapper<typeof CustomerSegmentType>();
  idCustomer: number;
  idFileOperationalData: number;
  denotation: string;
  segmentType: CustomerSegmentType;
  active: boolean;

  constructor(iCustomer: ICustomer) {
    super();
    if (!iCustomer) { throw new TypeError('Customer initialization: params is undefined !'); }
    this.idCustomer = this.assignSimpleType<number>(iCustomer.idCustomer);
    this.idFileOperationalData = this.assignSimpleType<number>(iCustomer.idFileOperationalData);
    this.denotation = this.assignSimpleType<string>(iCustomer.denotation);
    this.segmentType =
    (typeof(iCustomer.segmentType) !== 'undefined') ? this.assignSegment(iCustomer.segmentType) : undefined;
    this.active = ( typeof(iCustomer.active) !== 'undefined' ) ? iCustomer.active : undefined;
  }

  private assignSegment(iCustomer: CustomerSegmentType | string): CustomerSegmentType {
    try {
      return Customer.typeOfEnumMapper.getEnumAsNumber(CustomerSegmentType, iCustomer);
    } catch (e) {
      console.warn(e);
      return CustomerSegmentType.DEFAULT;
    }
  }

  getCustomerSegmentAsString(): string {
    try {
      return CustomerSegmentNames[this.segmentType].name;
    } catch (e) {
      return CustomerSegmentNames[CustomerSegmentType.DEFAULT].name;
    }
  }

  getInterface(webInterface: boolean = true): ICustomer {
    const emptyValue = (webInterface) ? null : undefined;
    return {
      idCustomer: this.idCustomer,
      idFileOperationalData: (typeof(this.idFileOperationalData) !== 'undefined') ? this.idFileOperationalData : emptyValue,
      active: this.active,
      denotation: (typeof(this.denotation !== 'undefined')) ? this.denotation : emptyValue,
      segmentType: Customer.typeOfEnumMapper.getEnumAsString(CustomerSegmentType, this.segmentType)
    };
  }
}
