import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SelectPageComponent } from './select-shcedule-position/select-page.component';

// Material design controls
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ScheduleModule } from '../schedule/schedule.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { InputWithAutoCompAndChipsComponent } from './input-with-autocomplete-and-chips/input-with-autoComp-and-chips.component.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
  declarations: [
    SelectPageComponent,
    InputWithAutoCompAndChipsComponent
  ],

  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatNativeDateModule,
    MatDatepickerModule,
    ScheduleModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatIconModule,
  ],

  exports: [
    SelectPageComponent,
    InputWithAutoCompAndChipsComponent,
  ],

  providers: [],
})
export class CommonComponentsModule { }
