import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'sv-pie-chart',
  templateUrl: './pie.component.html',
  styleUrls: ['./pie.component.sass']
})
export class PieComponent implements OnInit, OnChanges {

  /* All original chart.js inputs defined by documentation */
  @Input() data: Array<number[]> | number[] = [];
  @Input() datasets: Array<{data: Array<number[]> | number[], label: string}> = [];
  @Input() labels: string[] = [];
  @Input() type: string = '';
  @Input() options: {} = {};
  @Input() colors: Array<any> = [];
  @Input() legend: boolean = true;
  /* All original chart.js inputs defined by documentation */
  @Output() onChartClick: EventEmitter<any> = new EventEmitter();
  @Output() onChartHover: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}
  ngOnChanges() {}

  public chartClicked(e: any): void {
    this.onChartClick.emit(e);
  }

  public chartHovered(e: any): void {
    this.onChartHover.emit(e);
  }

}
