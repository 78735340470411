import { IPosition } from '@shared/models/appModels/position.model';
import { EventType, EventStatus } from '@shared/types/modelTypes/eventType';
import { UtilityTypeEnum } from '@shared/types/utilityType';

export const MockPosition: IPosition[] = [
  {
    idPosition: 1,
    idSchedule: 1,
    name: 'Position 1 Schedule 1',
    orderInSchedule: 1,
    events: [{ date: new Date('2016-11-07').getTime(), typeOf: EventType.UPLOAD, status: EventStatus.UNDEFINED },
    { date: new Date('2016-11-12').getTime(), typeOf: EventType.VALIDATION, status: EventStatus.UNDEFINED },
    { date: new Date('2016-11-17').getTime(), typeOf: EventType.EXPORT, status: EventStatus.UNDEFINED },
    { date: new Date('2016-11-17').getTime(), typeOf: EventType.ANALYSIS, status: EventStatus.UNDEFINED }],
    activeScheduler: false
  },
  {
    idPosition: 2,
    idSchedule: 1,
    orderInSchedule: 2,
    name: 'Position 2 Schedule 1',
    events: [{ date: new Date('2017-11-03').getTime(), typeOf: EventType.UPLOAD, status: EventStatus.UNDEFINED },
    { date: new Date('2017-11-05').getTime(), typeOf: EventType.VALIDATION, status: EventStatus.UNDEFINED },
    { date: new Date('2017-11-11').getTime(), typeOf: EventType.EXPORT, status: EventStatus.UNDEFINED },
    { date: new Date('2017-11-11').getTime(), typeOf: EventType.ANALYSIS, status: EventStatus.UNDEFINED }],
    activeScheduler: false
  },
  {
    idPosition: 3,
    idSchedule: 2,
    orderInSchedule: 1,
    name: 'Position 1 Schedule 2',
    events: [{ date: new Date('2017-11-04').getTime(), typeOf: EventType.UPLOAD, status: EventStatus.TODO },
    { date: new Date('2017-11-24').getTime(), typeOf: EventType.VALIDATION, status: EventStatus.LOCKED },
    { date: new Date('2017-11-24').getTime(), typeOf: EventType.EXPORT, status: EventStatus.LOCKED },
    { date: new Date('2017-11-29').getTime(), typeOf: EventType.ANALYSIS, status: EventStatus.LOCKED }],
    activeScheduler: false
  },
  {
    idPosition: 4,
    idSchedule: 3,
    orderInSchedule: 1,
    name: 'Position 1 Schedule 3',
    events: [{ date: new Date('2018-05-10').getTime(), typeOf: EventType.UPLOAD, status: EventStatus.IN_PROGRESS },
    { date: new Date('2018-05-14').getTime(), typeOf: EventType.VALIDATION, status: EventStatus.IN_PROGRESS},
    { date: new Date('2018-05-22').getTime(), typeOf: EventType.EXPORT, status: EventStatus.LOCKED },
    { date: new Date('2018-05-22').getTime(), typeOf: EventType.ANALYSIS, status: EventStatus.LOCKED }],
    activeScheduler: false
  },
  {
    idPosition: 5,
    idSchedule: 3,
    orderInSchedule: 2,
    name: 'Position 1 Schedule 3',
    events: [{ date: new Date('2018-05-04').getTime(), typeOf: EventType.UPLOAD, status: EventStatus.UNDEFINED },
    { date: new Date('2018-05-14').getTime(), typeOf: EventType.VALIDATION, status: EventStatus.DONE },
    { date: new Date('2018-05-17').getTime(), typeOf: EventType.EXPORT, status: EventStatus.UNDEFINED },
    { date: new Date('2018-05-17').getTime(), typeOf: EventType.ANALYSIS, status: EventStatus.UNDEFINED }],
    activeScheduler: false
  },
  // {
  //   id: 5,
  //   idSchedule: 4,
  //   orderInSchedule: 1,
  //   name: 'Position 1 Schedule 4',
  //   events: [{name: 'Upload', date: new Date('2018-05-04'), typeOf: EventType.UPLOAD,        status: EventState.UNDEFINED},
  //           {name: 'Validation', date: new Date('2018-05-14'), typeOf: EventType.VALIDATION, status: EventState.DONE},
  //           {name: 'Export', date: new Date('2018-05-17'), typeOf: EventType.EXPORT,         status: EventState.UNDEFINED},
  //           {name: 'Analysis', date: new Date('2018-05-17'), typeOf: EventType.ANALYSIS,     status: EventState.UNDEFINED}]
  // }
];
