import { Observable } from 'rxjs/Observable';
import { MatPaginator } from '@angular/material/paginator';

import { CommonAppDataService } from '@shared/services/commonAppData.service';
import { ValidationResultEnum } from '@shared/types';
import { MeasurementOriginEnum, VeeStatusEnum } from '@shared/types/modelTypes/measurementType';
import { environment } from '@env/environment.prod';


/* This file contains app-wide general types */

/* Type used to match enum values with respecive string literal ane description */
export interface INameMapper {
  id: number;
  name: string;
  classType: string;
  description?: string;
  icon?: string;
}

/* Extension to skip enums that are not intent to be presented on viev (i.e. DEFAULT, ALL, e.c.t.) */
export interface IPrintableNameMapper extends INameMapper {
  isPrintable: boolean;
}

export type ParameterPrimityveType = number | string | boolean;

export interface IParameters {
  [key: string]: ParameterPrimityveType;
}

export interface ArrayedParameters {
  name: string;
  value: ParameterPrimityveType;
  unit?: string;
}

export interface ParamsFormsFieldNames {
  nameControlName: string;
  valueControlName: string;
  keyControlName: string;
}

export const utilityTypeLocalStorageKey = 'utilityType';

export const interactiveTutorialSteps = 'interactiveTutorialSteps';

export const showInteractiveTutorial = 'showInteractiveTutorial';

export enum FileExtensionEnum {
  'CSV' = 0,
  'TXT',
  'XLS',
  'XLSX'
}

export type SortType = 'ASC' | 'DESC';

export type ParameterType = 'STRING' | 'INTEGER' | 'DOUBLE' | 'BOOLEAN';

export interface ApiDataShift {
  limit: number;
  offset: number;
}

export interface SubDataShift {
  limit: number;
}

export interface IPolledData<T> {
  data: T;
  pollTime: number;
  countNotValidatedMeasurementData?: number;
  positionId?: number;
}

export const FileExtensionNameMapping: INameMapper[] = [
  { id: FileExtensionEnum.CSV, name: '.CSV', classType: 'csv-file' },
  { id: FileExtensionEnum.CSV, name: '.TXT', classType: 'txt-file' },
  { id: FileExtensionEnum.CSV, name: '.XLS', classType: 'xls-file' },
  { id: FileExtensionEnum.CSV, name: '.XLSX', classType: 'xlsx-file' },
];


/* Type used to enformce application type class to return it's interface representation */
export interface IGetInterfaceType<Ifc, Obj extends Ifc> {
  getInterface(webInterface: boolean): Ifc;
}

export interface IGetArrayedParams {
  getArrayedParams(paramObj: IParameters): ArrayedParameters[];
}

/* HTTP services shall extend this class to be able to generate blank http params */
export abstract class BaseDataStore<gen, spec extends gen> {
  protected defaultLimit = environment.defaultReqLimit;
  protected defaultOffset = 0;
  constructor(protected cs: CommonAppDataService) { }
  protected abstract getEmptyParams(): spec;

  public responseIsEmpty(resp: Object | any[]): boolean {
    if (Array.isArray(resp) && resp.length === 0) {
      return true;
    } else if (Object.keys(resp).length === 0) {
      return true;
    } else {
      return false;
    }
  }
}

/* Interface that components with nestetd tables shall implement */
export interface NestedTableHost<T> {
  expandedElement: T;
  isThisRowExpanded(row: T): boolean;
  handleMainRowClick(row: T, actual?: T): T;
  rowCanBeExpanded(index: number, row: T): boolean;
  isThisDetailElement(row: T): boolean;
}

/* Components which host angular material table shall extend this interface */
export interface TableHost<T> {
  /* Represent initial data load in component */
  dataFetched: boolean;
  /* Represent data refreshing */
  dataLoading: boolean;
  /* Data length */
  dataLength: number;
  /* Loads data set suitable to feed table data source */
  loadData(limit: number, offset: number, otherParams: { [name: string]: any }): Observable<T[]>;
  /* Convert each paginator event to set of new set of data */
  onPaginatorEvents(paginator: MatPaginator): Observable<T[]>;
  /* Converts each external data change to new set of table data */
  onExternalDataChangeEvent(changeInductors: Observable<any>[]): Observable<T[]>;
  /* Converts each filter change to new set of table data */
  onFilterEvents(): Observable<T[]>;
  /* Converts each clear filters event on new set of table data */
  onClearFilters(): Observable<T[]>;
}

export interface LoadableTable {
  dataLength: number;
  dataFetched: boolean;
  isLoading(): boolean;
  getDataSourceLength(): number;
}

export interface MeterDataFilters {
  idMeterPoint?: number;
  from?: number;
  to?: number;
  validationResult?: ValidationResultEnum | string;
  sort?: SortType;
}

export interface AnalysisFilters {
  idMeterPoint?: number;
  algorithm?: string;
  anomaly?: boolean;
}

export interface MeasurementsDataFilters {
  idMeterPoint?: number;
  from?: number;
  to?: number;
  validationResult?: ValidationResultEnum | string;
  estimation?: VeeStatusEnum | string;
  validation?: VeeStatusEnum | string;
  sort?: SortType;
  origin?: MeasurementOriginEnum | string;
  idEstimationRule?: number;
}

export interface IButtonState {
  label: string;
  disabled: boolean;
}
