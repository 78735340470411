import { INameMapper, IPrintableNameMapper } from '@shared/types/applicationTypes';
import { environment as env } from '@env/environment';
import { typesLocalisation as loc } from '@shared/types/localisation';

export enum VeeProcessStageEnum  {
  'PREPROCESS' = 0,
  'USAGES_CALCULATION' = 1,
  'VALIDATION' = 2,
  'FREEZE' = 3,
  // 'DEFAULT'
}

export enum VeeProcessStatusEnum {
  'IN_PROGRESS' = 0,
  'DONE' = 1,
  'FAILED' = 2
}

export enum VeeProcessFailureEnum {
  'DATA_MISSING' = 0,
  'OTHER'
}

export function veeProcessNameMapper(id: number): IPrintableNameMapper {
  switch (id) {
    case VeeProcessStageEnum.PREPROCESS: {
      return {
        id: VeeProcessStageEnum.PREPROCESS,
        name: $localize`:@@data validation/vee process name mapper/stage name/preprocess:Preprocessing data`,
        classType: 'vee-preprocess',
        isPrintable: true,
      };
    }
    case VeeProcessStageEnum.USAGES_CALCULATION: {
      return {
        id: VeeProcessStageEnum.USAGES_CALCULATION,
        name: $localize`:@@data validation/vee process name mapper/stage name/usages calculation:Calculating usages`,
        classType: 'vee-usage-calc',
        isPrintable: true,
      };
    }
    case VeeProcessStageEnum.VALIDATION: {
      return   {
        id: VeeProcessStageEnum.VALIDATION,
        name: $localize`:@@data validation/vee process name mapper/stage name/validation:Validating data`,
        classType: 'vee-valid',
        isPrintable: true,
      };
    }
    case VeeProcessStageEnum.FREEZE: {
      return   {
        id: VeeProcessStageEnum.FREEZE,
        name: $localize`:@@data validation/vee process name mapper/stage name/freeze:Freezing results`,
        classType: 'vee-valid',
        isPrintable: false,
       };
    }
    default: {
      return   {
        id: VeeProcessStageEnum.PREPROCESS,
        name: $localize`:@@data validation/vee process name mapper/stage name/default:Default VEE process state`,
        classType: 'vee-default',
        isPrintable: false,
      };
    }
  }
}

export function veeProcessNames(): IPrintableNameMapper[] {
  return [
      {
        id: VeeProcessStageEnum.PREPROCESS,
        name: $localize`:@@data validation/vee process name mapper/stage name/preprocess:Preprocessing data`,
        classType: 'vee-preprocess',
        isPrintable: true,
      },
      {
        id: VeeProcessStageEnum.USAGES_CALCULATION,
        name: $localize`:@@data validation/vee process name mapper/stage name/usages calculation:Calculating usages`,
        classType: 'vee-usage-calc',
        isPrintable: true,
      },
      {
        id: VeeProcessStageEnum.VALIDATION,
        name: $localize`:@@data validation/vee process name mapper/stage name/validation:Validating data`,
        classType: 'vee-valid',
        isPrintable: true,
      },
      {
        id: VeeProcessStageEnum.FREEZE,
        name: $localize`:@@data validation/vee process name mapper/stage name/freeze:Freezing results`,
        classType: 'vee-valid',
        isPrintable: false,
       },
      {
        id: VeeProcessStageEnum.PREPROCESS,
        name: $localize`:@@data validation/vee process name mapper/stage name/default:Default VEE process state`,
        classType: 'vee-default',
        isPrintable: false,
      },
    ];
}

export const VeeProcessStatusMapper: INameMapper[] = [
  { id: VeeProcessStatusEnum.IN_PROGRESS, name: loc.VeeProcessStatusMapper[env.language].statusName.IN_PROGRESS, classType: 'vee-preprocess' },
  { id: VeeProcessStatusEnum.DONE, name: loc.VeeProcessStatusMapper[env.language].statusName.DONE, classType: 'vee-usage-calc' },
  { id: VeeProcessStatusEnum.FAILED, name: loc.VeeProcessStatusMapper[env.language].statusName.FAILED, classType: 'vee-valid' },
];

export const VeeProcessFailureMapper: INameMapper[] = [
  {id: VeeProcessFailureEnum.DATA_MISSING, name: loc.VeeProcessFailureMapper[env.language].VeeFailureText.DATA_MISSING, classType: 'vee-fail-data-missing'},
  {id: VeeProcessFailureEnum.OTHER, name: loc.VeeProcessFailureMapper[env.language].VeeFailureText.OTHER, classType: 'vee-fail-other'}
];


