import { Component, OnInit, OnDestroy, Inject, AfterViewInit } from '@angular/core';
import { ApplicationUnitsDataStoreService } from '@shared/services/dataStore/applicationUnitsDataStore.service';
import { CommonAppDataService } from '@shared/services/commonAppData.service';
import { UtilityTypeEnum } from '@shared/types';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { VeeRule } from '@shared/models/appModels/VeeRule.model';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { VeeRulesDataStoreService } from '@shared/services/dataStore/veeRulesDataStore';
import { ValidateImplementation } from '@shared/models/appModels/validateImplementation.model';
import { typesLocalisation } from '@shared/types/localisation';
import { environment } from '@env/environment';
import { InteractiveTutorialService } from '@shared/services/interactiveTutorialService.service';
import { DataValidationInteractiveTutorialService } from '../../data-validation-interactive-tutorial-steps';

@Component({
    selector: 'sv-data-validation-create-next-step',
    templateUrl: 'data-validation-create-next-step.component.html',
    styleUrls: ['data-validation-create-next-step.component.sass']
})
export class DataValidationCreateNextStepComponent implements OnInit, OnDestroy, AfterViewInit {

    private measurementParam: { name?: string, cumulative?: boolean, description?: string }[] = [];
    private measurementParamOther: { name?: string, cumulative?: boolean, description?: string }[] = [];
    private utilityType: UtilityTypeEnum;
    operators: string[] = ['==', '!=', '>=', '<=', '>', '<', '(', ')', '!', '+', '-', '/', '*', '**', '%', 'in'];
    operatorsForDisplay: string[] = ['== != >= <=', '> < ( )', '+ - / *', '** % ! in'];
    private forbiddenFirstElementsOfImplementation: string[] = ['=', '>', '<', ')', '/', '*', '%', 'in'];
    availableVariables: { name?: string, description?: string }[] = [];
    private prefixes: string[] = ['prev_', 'usage_', 'prev_usage_'];
    private validateBody: ValidateImplementation;
    validateResp: string = '';
    submitBtnDisabled: boolean = true;
    currentCaretPos: number = 0;
    ruleForm: FormGroup;
    disableAnimation = true;
    startTutFunc;

    constructor(@Inject(MAT_DIALOG_DATA) private data: { veeRule: VeeRule },
        public dialogRef: MatDialogRef<DataValidationCreateNextStepComponent>,
        private appUnitsDataService: ApplicationUnitsDataStoreService,
        private formBuilder: FormBuilder,
        private cs: CommonAppDataService,
        private veeRuleService: VeeRulesDataStoreService,
        private interactiveTutorialService: InteractiveTutorialService,
        private dataValidationInteractiveTutorial: DataValidationInteractiveTutorialService) { }

    ngOnInit() {
        this.utilityType = this.cs.getCurrentMediaType();

        this.ruleForm = this.formBuilder.group({
            'implementation': new FormControl('', [Validators.required, this.forbiddenFirstLettersValidation.bind(this),
            this.checkClosingBrackets.bind(this)])
        });

        this.appUnitsDataService.getMeasurementDefinitions()
            .map(v => {
                let r = [];
                v.forEach(e => {
                    let obj: { name?: string, cumulative?: boolean, description?: string } = {};
                    obj.name = e.name;
                    obj.cumulative = e.isCumulative;
                    obj.description = e.description;
                    r.push(obj);
                });
                return r;
            }).subscribe(
                data => {
                    this.measurementParam = data;
                    this.createAvailableVariables(this.measurementParam);
                    this.startTutFunc = setTimeout(()=>this.startInteractiveTutorial(),1500);
                }
            );

        this.dialogRef.afterClosed().subscribe(
            result => {
                console.log('Dialog was closed');
            }
        );
    }

    ngAfterViewInit() {
        setTimeout(() => this.disableAnimation = false);
    }

    startInteractiveTutorial(){
        const steps = this.dataValidationInteractiveTutorial.getDataValidationCreateNextStepRuleInteractiveTutorialSteps();
        this.interactiveTutorialService.startInteractiveTutorial(steps);
    }

    createAvailableVariables(params) {
        params.forEach(elem => {
            if (this.availableVariables.find(name => name.name === elem.name) === undefined){
               this.availableVariables.push({ name: elem.name, description: elem.description }); 
            }
        });
        for (const elem of params) {
            for (const prefix of this.prefixes) {
                if (!((prefix === 'usage_' || prefix === 'prev_usage_') && elem.cumulative === false)) {
                    if (prefix === 'prev_') {
                        this.availableVariables.push({ name: prefix + elem.name, description: 'Wartość poprzedzająca dla zmiennej: ' + elem.description.toLowerCase() });
                    } else if (prefix === 'prev_usage_') {
                        this.availableVariables.push({ name: prefix + elem.name, description: 'Wartość poprzedzająca dla wyliczonego zużycia zmiennej: ' + elem.description.toLowerCase() });
                    } else {
                        this.availableVariables.push({ name: prefix + elem.name, description: 'Wartość wyliczonego zużycia dla zmiennej: ' + elem.description.toLowerCase() });
                    }
                }
            }
        }
        this.availableVariables.sort((a, b) => (a.name > b.name) ? 1 : -1);
    }

    getCaretPos(event) {
        const start = (<HTMLTextAreaElement>event.target).selectionStart;
        this.currentCaretPos = start;
    }


    ngOnDestroy(): void {
        this.availableVariables = [];
        clearTimeout(this.startTutFunc);
    }

    onSubmit() {
        this.data.veeRule.implementation = this.ruleForm.value.implementation;
        const veeRule = this.data.veeRule;
        this.dialogRef.close(veeRule);
    }

    onValidate() {
        this.validateBody = new ValidateImplementation({
            implementation: this.ruleForm.value.implementation,
            utility_type: UtilityTypeEnum[this.utilityType]
        });
        this.veeRuleService.postValidateImplementationVeeRule(this.validateBody).subscribe(
            resp => {
                this.validateResp = resp;
                if (this.validateResp.toString() === 'VALID') {
                    this.submitBtnDisabled = false;
                    this.ruleForm.markAsUntouched();
                } else {
                    this.submitBtnDisabled = true;
                }
            },
            err => {
                console.log(err);
            }
        );
    }

    onHandleError() {
        this.validateResp = '';
    }

    handleErrorInfo(validateResp): string {
        switch (validateResp.toString()) {
            case 'TYPE_ERROR': {
                return $localize`:@@data validatation/new rule modal/errors/type error:Incompatible data type.`;
            }
            case 'SYNTAX_ERROR': {
                return $localize`:@@data validatation/new rule modal/errors/syntax error:Error in expression syntax.`;
            }
            case 'INVALID_EXPRESSION': {
                return $localize`:@@data validatation/new rule modal/errors/invalid expression:An illegal expression appeared.`;
            }
            case 'NAME_NOT_DEFINED': {
                return $localize`:@@data validatation/new rule modal/errors/name not defined:Non-existing variable used.`;
            }
            case 'FUNCTION_NOT_DEFINED': {
                return $localize`:@@data validatation/new rule modal/errors/function not defined:Non-existing function used.`;
            }
            case 'VALID': {
                return $localize`:@@data validatation/new rule modal/errors/valid:Expression built correctly.`;
            }
        }
    }

    forbiddenFirstLettersValidation(control: FormControl): { [s: string]: boolean } {
        if (this.forbiddenFirstElementsOfImplementation.indexOf(control.value[0]) !== -1) {
            return { forbiddenCharAtBeginning: true };
        }
    }

    checkClosingBrackets(control: FormControl): { [s: string]: boolean } {
        let occurencesOfOpeningBracket = 0;
        let occurencesOfClosingBracket = 0;
        for (let i = 0; i < control.value.length; i++) {
            if (control.value[i] === '(') {
                occurencesOfOpeningBracket += 1;
            } else if (control.value[i] === ')') {
                occurencesOfClosingBracket += 1;
            }
        }
        if (occurencesOfOpeningBracket !== occurencesOfClosingBracket) {
            return { lackOfClosingBracket: true };
        }
    }
}
