// Angular modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgUploaderModule } from '../../plugins/ngx-uploader';
import { RouterModule } from '@angular/router';

import { GraphicsModule } from '../graphics/graphics.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatListModule} from '@angular/material/list';
import {MatDividerModule} from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';

import { MeasurementsUploadComponent } from './measurements/measurements-upload.component';
import { AssetDataUploadComponent } from './asset-data/asset-data.component';
import { TemperaturesComponent } from './temperatures/temperatures.component';
import { ScheduleModule } from '../schedule/schedule.module';

import { ReadingsPreviewModuleModule } from './measurements/measurements-preview/measurements-preview.module';
import { ReadingsFilePreviewModuleModule } from './measurements/measurements-preview-files/measurements-file-preview.module';
import { ClientDataPreviewModuleModule } from './asset-data/asset-data-preview/asset-data-preview.module';
import { ClientDataFilesPreviewModule } from './asset-data/asset-data-files-preview/asset-data-files-preview.module';
import { MeasurementsFilesPreviewModule } from './measurements/measurements-files-preview/measurements-files-preview.module';

import { CommonComponentsModule } from '../common-component/commonComponents.module';
import { ReadingsPreviewViewDialogComponent } from './measurements/measurements-preview/dialog/readings-preview-view-dialog/readings-preview-view-dialog.component';
import {MeasurementMapperComponent} from './measurements/measurements-mapper/measurements-mapper.component';
import { ExistingMappingDirective } from './measurements/measurements-mapper/existingMappings.directive';
import { MeasurementMapperService } from './measurements/measurements-mapper/measurementMapper.service';
import { CommonPipeModule } from 'app/utils/commonPipe.module';

@NgModule({
  imports: [
    CommonModule,
    CommonPipeModule,
    BrowserAnimationsModule,
    GraphicsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    NgUploaderModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    ScheduleModule,
    ReadingsPreviewModuleModule,
    ReadingsFilePreviewModuleModule,
    MeasurementsFilesPreviewModule,
    ClientDataPreviewModuleModule,
    ClientDataFilesPreviewModule,
    CommonComponentsModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatTableModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatListModule,
    MatDividerModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatIconModule,
    MatProgressBarModule,
  ],
  declarations: [
    MeasurementsUploadComponent,
    AssetDataUploadComponent,
    TemperaturesComponent,
    MeasurementMapperComponent,
    ExistingMappingDirective,
  ],
  exports: [
    MeasurementsUploadComponent,
    AssetDataUploadComponent,
    TemperaturesComponent,
    ReadingsPreviewViewDialogComponent,
    MeasurementMapperComponent,
  ],
  providers: [
    MeasurementMapperService,
  ],
  entryComponents: [
    MeasurementMapperComponent
  ],
})
export class UploadModule { }
