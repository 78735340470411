import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject} from 'rxjs';
import { ShepherdService } from 'angular-shepherd';
import { ActivatedRoute, Router } from '@angular/router';

export interface CustomStep {
   attachTo: {element: string, on: string},
   text: string,
   title: string,
   buttons: string[],
   showOn? : Function,
   when?: Object,
   scrollTo?: boolean | {behavior: string, block: string},
}

export enum TutorialButtonLabel{
  BACK = 'back',
  NEXT = 'next',
  COMPLETE = 'complete',
  COMPLETE_NEXT_COMPONENT = 'complete-next-component'
}

export enum NextComponentName{
  ASSET_UPLOAD_FILES_PREVIEW = 'asset-upload-files-preview',
  ASSET_UPLOAD_DATA_PREVIEW = 'asset-upload-data-preview',
  DATA_ANALYSIS_READLIST = 'data-analysis-readlist',
  TIMELINE_DATA_ANALYSIS_WIDGET = 'timeline-data-analysis-widget',
  TIMELINE_DATA_EXPLORATION_WIDGET = 'timeline-data-exploration-widget',
  TIMELINE_DATA_VALIDATION_WIDGET = 'timeline-data-validation-widget',
  EXPORT_MEASUREMENTS_FIRST_STEP = 'export-custom-csv',
  EXPORT_MEASUREMENTS_SECOND_STEP = 'export-custom-csv-next-step',
  EXPORT_MEASUREMENTS_SUMMARY = 'export-custom-csv-summary',
  DATA_VALIDATION_SUMMARY = 'data-validation-summary',
}

@Injectable({
    providedIn: 'root'
})


export class InteractiveTutorialService {
    showTutorialHints: boolean = false;
    showTutorialSubject: BehaviorSubject<boolean> = new BehaviorSubject(this.showTutorialHints);
    canGoToNextStepSubject: Subject<any> = new Subject();
    buttons: Map<string, any> = new Map();

    constructor(private shepherdService: ShepherdService, public route: ActivatedRoute, public router: Router) {
        this.buttons.set(TutorialButtonLabel.BACK, {
            action() {
              return this.back();
            },
            text: $localize`:@@interactive tutorial/back btn:Back`,
            classes: 'btn-interactive-tutorial',
            secondary: true
          });
          this.buttons.set(TutorialButtonLabel.NEXT, {
            action() {
              return this.next();
            },
            text: $localize`:@@interactive tutorial/next btn:Next`,
            classes: 'btn-interactive-tutorial',
            secondary: true
          });
          this.buttons.set(TutorialButtonLabel.COMPLETE, {
            action() {
              this.getCurrentStep().complete();
              return this.complete();
            },
            text: $localize`:@@interactive tutorial/complete btn:Complete`,
            classes: 'btn-interactive-tutorial',
            secondary: true,
          });
          this.buttons.set(TutorialButtonLabel.COMPLETE_NEXT_COMPONENT, {
            action() {
              this.getCurrentStep().complete();
              return this.complete();
            },
            text: $localize`:@@interactive tutorial/next btn:Next`,
            classes: 'btn-interactive-tutorial',
            secondary: true
          });
    }

    startInteractiveTutorial(steps: any[] | unknown[]) {
        if (this.showTutorialHints && !this.shepherdService.isActive) {
          this.shepherdService.defaultStepOptions = {
            scrollTo: false,
            useModalOverlay: true,
            cancelIcon: {
              enabled: true
            },
            canClickTarget: false,
            popperOptions: {
              modifiers: [
                {
                  name: 'offset',
                  enabled: true,
                  options: {
                    offset: [0,10]
                  }
                }
              ]
            }
          };
        this.shepherdService.modal = true;
        this.shepherdService.confirmCancel = false;
        this.shepherdService.addSteps(steps);
        this.shepherdService.start();
        }
    }

    cleanNotEndedTutorials() {
      if (this.shepherdService.isActive) {
        this.shepherdService.complete();
      }
    }


    getActiveComponent(path: string) {
       return this.router.isActive(path, false);
    }

    generateStep(attachTo: string, position: string,text: string, title: string, buttons: TutorialButtonLabel[], showOn?: Function ,
       scrollTo?: boolean, when?: Object) {
      const step: CustomStep = {
        attachTo: { element: attachTo, on: position },
        scrollTo: scrollTo? {behavior: 'smooth', block: 'center'} : undefined,
        showOn: showOn? showOn : undefined,
        when: when? when : undefined,
        text: text,
        title: title,
        buttons: new Array<any>(),
      };
      buttons.forEach( el => {
        try{
          step.buttons.push(this.buttons.get(el));
        } catch {
          console.warn('There is no such button available');
        }
      });
      return step;
    }


}
