/* Angular modules import */
import { Injectable } from '@angular/core';

/* RjJS imports */
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

/* Models */
import { ICustomer, Customer } from '@shared/models/appModels/customer.model';

/* Datatypes */
import { environment } from '@env/environment';
import { GenericHttpParams, BooleanResult } from '@shared/types/http/httpType';
import { BaseDataStore } from '@shared/types/applicationTypes';
import { CustomerHttpParamG } from '@shared/types/http/customersHttpConfig';

/* DataStore services */

/* HTTP Services */
import { CustomerService } from '@shared/services/http/customer.service';
import { CommonAppDataService } from '@shared/services/commonAppData.service';


@Injectable()
export class CustomerDataStoreService extends BaseDataStore<GenericHttpParams<Customer>, CustomerHttpParamG> {

  /* Cache tables */
  customersCahce: Customer[] = [];

  private customerDataSubject$: Subject<Customer> = new Subject<Customer>();
  private readonly customerDataObservable$: Observable<Customer> = this.customerDataSubject$.asObservable();

  constructor(private customerService: CustomerService, cs: CommonAppDataService) {
    super(cs);
  }

  getChangeObservable(): Observable<Customer> {
    return this.customerDataObservable$;
  }

  getAllCustomers(
    limit: number = environment.defaultReqLimit,
    offset: number = 0,
    allowEmpty: boolean = true): Observable<Customer[]> {
    const params: CustomerHttpParamG = this.getEmptyParams();
    params.queryParams.offset = '' + offset;
    params.queryParams.limit = '' + limit;
    params.config.strict = !allowEmpty;

    return this.getCustomersList(params);
  }

  getCustomersListByName(
    limit: number = environment.defaultReqLimit,
    offset: number = 0,
    name: string,
    allowEmpty: boolean = true): Observable<Customer[]> {
    const params: CustomerHttpParamG = this.getEmptyParams();
    params.queryParams.offset = '' + offset;
    params.queryParams.limit = '' + limit;
    params.config.strict = !allowEmpty;
    params.queryParams.name = name;

    return this.getCustomersList(params);
  }

  getSingleCustomerByName(
    name: string,
    allowEmpty: boolean = true): Observable<Customer> {
    const params: CustomerHttpParamG = this.getEmptyParams();
    params.queryParams.offset = '' + 0;
    params.queryParams.limit = '' + environment.defaultReqLimit;
    params.config.strict = !allowEmpty;
    params.queryParams.name = name;

    return this.getCustomersList(params)
      .concatMap<Customer[], Customer>(mp => {
        return Observable.from(mp);
      })
      .filter(mp => mp.denotation === name)
      .defaultIfEmpty({} as Customer);
  }

  getSingleCustomerExistanceByName(
    limit: number = environment.defaultReqLimit,
    offset: number = 0,
    name: string,
    allowEmpty: boolean = true): Observable<boolean> {
    const params: CustomerHttpParamG = this.getEmptyParams();
    params.queryParams.offset = '' + offset;
    params.queryParams.limit = '' + limit;
    params.config.strict = !allowEmpty;
    params.queryParams.name = name;

    return this.getCustomersList(params)
      .concatMap<Customer[], Customer>(mp => {
        return Observable.from(mp);
      })
      .filter(mp => mp.denotation === name)
      .defaultIfEmpty({} as Customer)
      .map<Customer, boolean>((mp: Customer) => {
        if (Object.keys(mp).length !== 0) {
          return true;
        } else {
          return false;
        }
      });
  }

  getCustomerById(id: number, allowEmpty: boolean = false): Observable<Customer> {
    const params: CustomerHttpParamG = this.getEmptyParams();
    params.path.idCustomer = '' + id;
    params.config.strict = !allowEmpty;

    return this.customerService.getCustomerById(params);
  }

  addCustomer(item: Customer,
    options: { emitEvent: boolean } = { emitEvent: true }): Observable<Customer> {
    const params: CustomerHttpParamG = this.getEmptyParams();
    params.body = item;
    params.path.idCustomer = '' + item.idCustomer;
    return this.customerService.postCustomers(params)
      .do((added: Customer) => {
        this.customersCahce.push(added);
        if (options.emitEvent) {
          this.customerDataSubject$.next(item);
        }
      });
  }

  updateCustomer(item: Customer,
    options: { emitEvent: boolean } = { emitEvent: true }): Observable<Customer> {
    const params: CustomerHttpParamG = this.getEmptyParams();
    params.body = item;
    params.path.idCustomer = '' + item.idCustomer;

    return this.customerService.putCustomersById(params)
      .do((updated: Customer) => {
        this.customersCahce.forEach((el: ICustomer, idx: number) => {
          if (el.idCustomer === item.idCustomer) { this.customersCahce[idx] = item; console.log(el); }
        });
        if (options.emitEvent) {
          this.customerDataSubject$.next(item);
        }
      });
  }

  deleteCustomer(id: number,
    options: { emitEvent: boolean } = { emitEvent: true }): Observable<Customer> {
    const params: CustomerHttpParamG = this.getEmptyParams();
    params.path.idCustomer = '' + id;

    return this.customerService.deleteCustomersById(params)
      .do((deleted: Customer) => {
        const findId = this.customersCahce.findIndex(el => el.idCustomer === deleted.idCustomer);
        if (findId !== -1) {
          this.customersCahce.splice(findId, 1);
        }
        if (options.emitEvent) {
          this.customerDataSubject$.next(deleted);
        }
      });
  }

  activateCustomer(id: number,
    options: { emitEvent: boolean } = { emitEvent: true }): Observable<Customer> {
    return this.setActiveCustomer(id, options, true);
  }

  deactivateCustomer(id: number,
    options: { emitEvent: boolean } = { emitEvent: true }): Observable<Customer> {
    return this.setActiveCustomer(id, options, false);
  }

  setActiveCustomer(id: number,
    options: { emitEvent: boolean } = { emitEvent: true }, state: boolean): Observable<Customer> {
    const params: CustomerHttpParamG = this.getEmptyParams();
    params.path.idCustomer = '' + id;
    params.body = { value: state } as BooleanResult;
    return this.setCustomerActivation(params, options)
      .do(cust => {
        const findId = this.customersCahce.findIndex(el => el.idCustomer === cust.idCustomer);
        if (findId !== -1) {
          this.customersCahce.splice(findId, 1);
        }
      });
  }

  private setCustomerActivation(params: CustomerHttpParamG, options: { emitEvent: boolean }): Observable<Customer> {
    return this.customerService.putCustomerActivation(params)
      .do(cus => {
        if (options.emitEvent) {
          this.customerDataSubject$.next(cus);
        }
      });
  }

  private getCustomersList(params): Observable<Customer[]> {
    return this.customerService.getCustomersList(params);
  }

  private getCustomerListByName(params: CustomerHttpParamG): Observable<Customer[]> {
    let queryName: string;
    try {
      queryName = '' + params.queryParams.name;
    } catch (e) {
      throw new Error('missing serial qiery paraneter value');
    }
    return this.getCustomersList(params);
  }

  protected getEmptyParams(): CustomerHttpParamG {
    const r: CustomerHttpParamG = { body: {} as Customer, headers: {}, path: {}, queryParams: {}, config: {} };
    return r;
  }

}
