<h2>{{labels.listFile}}</h2>
<div>
	<mat-table #table [dataSource]="dataSource">

		<ng-container matColumnDef="name">
			<mat-header-cell *matHeaderCellDef> {{labels.name}} </mat-header-cell>
			<mat-cell *matCellDef="let element">
				{{element.name}}
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="timestamp">
			<mat-header-cell *matHeaderCellDef> {{labels.dateCreate}} </mat-header-cell>
			<mat-cell *matCellDef="let element">{{ element.creationDate | svDateTime }}</mat-cell>
		</ng-container>

		<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
		<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
	</mat-table>


	<div class="no-result" [style.display]="!isLoading() && getDataLength() === 0 ? '' : 'none'">
		{{noData}}
	</div>

	<div class="no-result" [style.display]="isLoading() ? '' : 'none'">
		<mat-spinner [strokeWidth]="3" [diameter]="20" class="notranslate"></mat-spinner>
	</div>

	<mat-paginator #paginator [length]="getDataLength()" [pageIndex]="0" [pageSize]="10"
		[pageSizeOptions]="[5, 10, 20]" showFirstLastButtons class="notranslate">
	</mat-paginator>

</div>