<div class="custom-container">
    <form [formGroup]="selectedDataForm" novalidate>
        <div class="container-form">
            <div class="row">
                <mat-form-field [ngClass]="{'mat-form-field-invalid': this.selectedDataForm.get('selectedData').touched && dataFromInput?.length===0 && !disabledInput}" class="custom-col">
                    <mat-chip-list #chipList aria-label="Data selection">
                        <mat-chip
                          *ngFor="let data of dataFromInput"
                          [selectable]="selectable"
                          [removable]="removable"
                          (removed)="remove(data)">
                          {{data.valueForDisplay}}
                          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                        </mat-chip>
                          <input
                          [placeholder]="inputPlaceholder"
                          #Input
                          name="selectedData"
                          [formControl]="inputCtrl"
                          [matAutocomplete]="auto"
                          [matChipInputFor]="chipList"
                          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                          (matChipInputTokenEnd)="add($event)">
                      </mat-chip-list>
                      <mat-error *ngIf="chipList.errorState && notValidValue">{{wrongElement}}</mat-error>
                      <mat-hint class="invalid" *ngIf="this.selectedDataForm.get('selectedData').touched && dataFromInput?.length===0 && !disabledInput">{{elementRequired}}</mat-hint>
                      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                        <mat-option *ngFor="let data of allData" [value]="data">
                          {{data.valueForDisplay}}
                        </mat-option>
                        <mat-option disabled *ngIf="fetchingData" class="small">
                          <span matPrefix style="vertical-align: middle">
                            <mat-spinner diameter="14" style="display: inline-block; margin-right: 5px;"></mat-spinner>
                          </span>
                          <span i18n="@@export/loading elements">
                            Loading suggestions...
                          </span>
                        </mat-option>
                        <mat-option disabled *ngIf="!fetchingData && allData.length === 0" 
					              class="small" i18n="@@export/lack of matching elements">No matching elements found.</mat-option>
                      </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>
    </form>
    </div>