import { IGetInterfaceType } from '@shared/types';


export interface ITimeGroup {
  id: number;
  timeGroupName: string;
  description?: string;
}

export class TimeGroup implements ITimeGroup, IGetInterfaceType<ITimeGroup, TimeGroup> {

  id: number;
  timeGroupName: string;
  description?: string;

  constructor(iIn: ITimeGroup) {
    this.id = (typeof(iIn.id) !== 'undefined') ? iIn.id : undefined;
    this.timeGroupName = (typeof(iIn.timeGroupName) !== 'undefined') ? iIn.timeGroupName : undefined;
    this.description = (typeof(iIn.description) !== 'undefined') ? iIn.description : undefined;
  }

  getInterface(webInterface: boolean): ITimeGroup {
    const emptyValue = (webInterface) ? null : undefined;
    return {
      id: this.id || emptyValue,
      timeGroupName: this.timeGroupName || emptyValue,
      description: this.description || emptyValue,
    };
  }

}
