export enum LanguagesEnum {
  'ENG' = 0,
  'GER' = 1,
  'PL' = 2
}

export const typesLocalisation = {
  CustomerSegmentNames: [
    {
      lang: 'ENG',
      segmentType: {
        ENTERPRISE: 'Enterprise',
        SERVICE_COMPANY: 'Service Company',
        PUBLIC_ADMINISTRATION: 'Public Administration',
        HEALTHCARE: 'Healthcare',
        EDUCATION: 'Education',
        MULTI_FAMILY_HOUSING: 'Multi-family housing',
        SINGLE_FAMILY_HOUSING: 'Single-family housing',
        DEFAULT: 'Default segment'
      }
    },
    {
      lang: 'GER',
      segmentType: {
        ENTERPRISE: 'Unternehmen',
        SERVICE_COMPANY: 'Dienstleistungsunternehmen',
        PUBLIC_ADMINISTRATION: 'Öffentliche Verwaltung',
        HEALTHCARE: 'Gesundheitswesen',
        EDUCATION: 'Bildung und Erziehung',
        MULTI_FAMILY_HOUSING: 'Mehrfamilienhäuser',
        SINGLE_FAMILY_HOUSING: 'Einfamilienhäuser',
        DEFAULT: 'Standard-Segment'
      }
    },
    {
      lang: 'PL',
      segmentType: {
        ENTERPRISE: 'Przedsiębiorstwo',
        SERVICE_COMPANY: 'Segment usług',
        PUBLIC_ADMINISTRATION: 'Administracja publiczna',
        HEALTHCARE: 'Healthcare',
        EDUCATION: 'Edukacja i szkolnictwo',
        MULTI_FAMILY_HOUSING: 'Zabudowa wielorodzinna',
        SINGLE_FAMILY_HOUSING: 'Zabudowa jednorodzinna',
        DEFAULT: 'Domyslny segment'
      }
    }
  ],

  ItemsPage: [
    {
      lang: 'ENG',
      texts: {
        itemsPage: 'Items per page',
        nextPage: 'Next page',
        previousPage: 'Previous page',
        lastPage: 'Last page',
        firstPage: 'First page'
      }
    },
    {
      lang: 'GER',
      texts: {
        itemsPage: 'Objekte pro Seite',
        nextPage: 'Nächste Seite',
        previousPage: 'Vorherige Seite',
        lastPage: 'Letzte Seite',
        firstPage: 'Erste Seite'
      }
    },
    {
      lang: 'PL',
      texts: {
        itemsPage: 'Elementów na stronie',
        nextPage: 'Następna strona',
        previousPage: 'Poprzednia strona',
        lastPage: 'Ostatnia strona',
        firstPage: 'Pierwsza strona'
      }
    }
  ],

  measurementOriginNamesMap: [
    {
      lang: 'ENG',
      measurementOrigin: {
        FROM_FILE: 'From file',
        MANUALLY_ADDED: 'Manually added',
        EDITED: 'Edited'
      }
    },
    {
      lang: 'GER',
      measurementOrigin: {
        FROM_FILE: 'Aus Datei',
        MANUALLY_ADDED: 'Manuell hinzugefügt',
        EDITED: 'Bearbeitet'
      }
    },
    {
      lang: 'PL',
      measurementOrigin: {
        FROM_FILE: 'Z pliku',
        MANUALLY_ADDED: 'Ddoane ręcznie',
        EDITED: 'Edytowane'
      }
    }
  ],

  estimationStatusNamesMap: [
    {
      lang: 'ENG',
      estimationStatus: {
        REQUIRED: 'Required',
        NOT_REQUIRED: 'Not required',
        DONE: 'Done',
        FAILED: 'Failed',
        UNDEFINED: 'Undefined'
      }
    },
    {
      lang: 'GER',
      estimationStatus: {
        REQUIRED: 'Erforderlich',
        NOT_REQUIRED: 'Nicht erforderlich',
        DONE: 'erledigt',
        FAILED: 'Fehlerhaft',
        UNDEFINED: 'Unbestimmt'
      }
    },
    {
      lang: 'PL',
      estimationStatus: {
        REQUIRED: 'Wymagana',
        NOT_REQUIRED: 'Nie wymagana',
        DONE: 'Wykonana',
        FAILED: 'Nie udała się',
        UNDEFINED: 'Niezdefiniowana'
      }
    }
  ],

  validationStatusNamesMap: [
    {
      lang: 'ENG',
      validationStatus: {
        REQUIRED: 'Required',
        NOT_REQUIRED: 'Not required',
        DONE: 'Done',
        FAILED: 'Failed',
        UNDEFINED: 'Undefined'
      }
    },
    {
      lang: 'GER',
      validationStatus: {
        REQUIRED: 'Erforderlich',
        NOT_REQUIRED: 'Nicht erforderlich',
        DONE: 'Erledigt',
        FAILED: 'Fehlerhaft',
        UNDEFINED: 'Unbestimmt'
      }
    },
    {
      lang: 'PL',
      validationStatus: {
        REQUIRED: 'Wymagana',
        NOT_REQUIRED: 'Nie wymagana',
        DONE: 'Wykonana',
        FAILED: 'Nie udała się',
        UNDEFINED: 'Niezdefiniowana'
      }
    }
  ],
  analysisStatusNamesMap: [
    {
      lang: 'ENG',
      analysisStatus: {
        IN_PROGRESS: 'In progress',
        DONE: 'Done',
        FAILED: 'Failed',
        UNDEFINED: 'Undefined'
      }
    },
    {
      lang: 'GER',
      analysisStatus: {
        IN_PROGRESS: 'in Bearbeitung',
        DONE: 'Erledigt',
        FAILED: 'Fehlerhaft',
        UNDEFINED: 'Unbestimmt'
      }
    },
    {
      lang: 'PL',
      analysisStatus: {
        IN_PROGRESS: 'W trakcie',
        DONE: 'Wykonana',
        FAILED: 'Nie udała się',
        UNDEFINED: 'Niezdefiniowana'
      }
    }
  ],

  statusFlagStringName: [
    {
      lang: 'ENG',
      statusFlag: {
        NORMAL: 'Normal',
        CONTROL: 'Control',
        DISCONNECTION: 'Disconnection',
        MOUNT: 'Mount',
        FAILURE: 'Failure',
        UNAVAILABLE: 'Unavailable',
        ERROR: 'Error',
        ESTIMATED: 'Estimated'
      }
    },
    {
      lang: 'GER',
      statusFlag: {
        NORMAL: 'Normal',
        CONTROL: 'Kontrollmessung',
        DISCONNECTION: 'Abschaltung',
        MOUNT: 'Montage',
        FAILURE: 'Störung',
        UNAVAILABLE: 'Nicht verfügbar',
        ERROR: 'Fehler',
        ESTIMATED: 'Schätzwert'
      }
    },
    {
      lang: 'PL',
      statusFlag: {
        NORMAL: 'Normalny',
        CONTROL: 'Kontrolny',
        DISCONNECTION: 'Odłączeniowy',
        MOUNT: 'Montażowy',
        FAILURE: 'Awaryjny',
        UNAVAILABLE: 'Niedostępny',
        ERROR: 'Błędny',
        ESTIMATED: 'Estymowany'
      }
    }
  ],

  MeasurementTypeNames: [
    {
      lang: 'ENG',
      MeasurementType: {
        TELEMETRY: 'Telemetry',
        MANUAL: 'Manual',
        OTHER: 'Default measurement type',
        DEFAULT: 'Default measurement type'
      }
    },
    {
      lang: 'GER',
      MeasurementType: {
        TELEMETRY: 'Telemetrie',
        MANUAL: 'Manuell',
        OTHER: 'Standard-Messwert',
        DEFAULT: 'Standard-Messwert'
      }
    },
    {
      lang: 'PL',
      MeasurementType: {
        TELEMETRY: 'Telemetria',
        MANUAL: 'Odczyt ręczny',
        OTHER: 'Domyślny odczyt',
        DEFAULT: 'Domyślny odczyt'
      }
    }
  ],

  MeterNodeTypeNames: [
    {
      lang: 'ENG',
      MeterNodeType: {
        METER: 'Normal meter type',
        BALANCING: 'Balancing',
        SUBMETER: 'Submeter',
        VIRTUAL: 'Virtual',
        OTHER: 'Default meter type'
      }
    },
    {
      lang: 'GER',
      MeterNodeType: {
        METER: 'Normaler Zählertyp',
        BALANCING: 'Vergleichszähler',
        SUBMETER: 'Submeter',
        VIRTUAL: 'Virtuell',
        OTHER: 'Standard Zählertyp'
      }
    },
    {
      lang: 'PL',
      MeterNodeType: {
        METER: 'Normalny licznik',
        BALANCING: 'Bilansujący',
        SUBMETER: 'Podlicznik',
        VIRTUAL: 'Wirtualny',
        OTHER: 'Domyślny typ licznika'
      }
    }
  ],

  PositionProcessTransition: [
    {
      lang: 'ENG',
      PositionTransitions: {
        UPLOAD_FINISH: 'Upload finished',
        VALIDATION_START: 'Validation started',
        VALIDATION_FINISH: 'Validation finished',
        VALIDATION_FAILED: 'Validation failed',
        VALIDATION_FREEZED: 'Validation results freeze',
        ANALYSIS_START: 'Analysis started',
        ANALYSIS_FINISH: 'Analysis finished',
        ANALYSIS_FAILED: 'Analysis failed',
      }
    },
    {
      lang: 'GER',
      PositionTransitions: {
        UPLOAD_FINISH: 'Import beendet',
        VALIDATION_START: 'Validierung gestarted',
        VALIDATION_FINISH: 'Validierung beendet',
        VALIDATION_FAILED: 'Validierung fehlgeschlagen',
        VALIDATION_FREEZED: 'Validierungsergebnisse fixieren',
        ANALYSIS_START: 'Analysis started',
        ANALYSIS_FINISH: 'Analysis finished',
        ANALYSIS_FAILED: 'Analysis failed',
      }
    },
    {
      lang: 'PL',
      PositionTransitions: {
        UPLOAD_FINISH: 'Zakończono ładowanie',
        VALIDATION_START: 'Rozpoczęto walidację',
        VALIDATION_FINISH: 'Zakończono walidację',
        VALIDATION_FAILED: 'Nie udało się ukończyć walidacji',
        VALIDATION_FREEZED: 'Validation results freeze',
        ANALYSIS_START: 'Analysis started',
        ANALYSIS_FINISH: 'Analysis finished',
        ANALYSIS_FAILED: 'Analysis failed',
      }
    }
  ],

  actionsTextsArr: [
    {
      lang: 'ENG',
      actionsTextsArr: {
        /* upload */ /* validation */ /* Export */ /* Analysis */
        UNDEFINED: [
          'Unknown status',
          'Unknown status',
          'Unknown status',
          'Unknown status'
        ],
        TODO: [
          'Start upload',
          'Start data validation',
          'Start data export',
          'Start data analysis'
        ],
        IN_PROGRESS: [
          'Continue upload',
          'Continue validation',
          'Continue export',
          'Continue analysis'
        ],
        DONE: [
          'Reupload',
          'Restart validation',
          'Restart export',
          'Restart analysis'
        ],
        CLOSED: [
          'See results',
          'See results',
          'See results',
          'See results'],
        LOCKED: [
          'Start upload',
          'Start data validation',
          'Start data export',
          'Start data analysis'
        ]
      }
    },
    {
      lang: 'GER',
      actionsTextsArr: {
        UNDEFINED: [
          'Unbekannter Status',
          'Unbekannter Status',
          'Unbekannter Status',
          'Unbekannter Status'
        ],
        TODO: [
          'Starte Import',
          'Starte Datenvalidierung',
          'Starte Datenexport',
          'Starte Datenanalyse'
        ],
        IN_PROGRESS: [
          'Import fortsetzen',
          'Validierung fortsetzen',
          'Export fortsetzen',
          'Analyse fortsetzen'
        ],
        DONE: [
          'Import wiederholen',
          'Validierung wiederholen',
          'Eport wiederholen',
          'Analyse wiederholen'
        ],
        CLOSED: [
          'Ergebnisse anschauen',
          'Ergebnisse anschauen',
          'Ergebnisse anschauen',
          'Ergebnisse anschauen'],
        LOCKED: [
          'Starte Import',
          'Starte Datenvalidierung',
          'Starte Datenexport',
          'Starte Datenanalyse'
        ]
      }
    },
    {
      lang: 'PL',
      actionsTextsArr: {
        UNDEFINED: [
          'Nieznany status',
          'Nieznany status',
          'Nieznany status',
          'Nieznany status'
        ],
        TODO: [
          'Rozpocznij ładowanie',
          'Rozpocznij walidację',
          'Rozpocznij eksport',
          'Rozpocznij analizę'
        ],
        IN_PROGRESS: [
          'Kontynuuj ładowanie',
          'Kontynuuj walidację',
          'Kontynuuj eksport',
          'Kontynuuj analizę'
        ],
        DONE: [
          'Załaduj dane ponownie',
          'Ponów walidację',
          'Ponów eksport',
          'Ponów analizę'
        ],
        CLOSED: ['Wyniki', 'Wyniki', 'Wyniki', 'Wyniki'],
        LOCKED: [
          'Rozpocznij ładowanie',
          'Rozpocznij walidację',
          'Rozpocznij eksport',
          'Rozpocznij analizę'
        ]
      }
    }
  ],
  promptText: [
    {
      lang: 'ENG',
      promptText: {
        UNDEFINED: [
          'Undefined status',
          'Undefined status',
          'Undefined status',
          'Undefined status'
        ],
        TODO: [
          'Import data',
          'Start data validation',
          'Start data export',
          'Start data analysis'
        ],
        IN_PROGRESS: ['', '', '', ''],
        DONE: ['', '', '', ''],
        CLOSED: ['', '', '', 'Incorrect State'],
        LOCKED: [
          '',
          'Import data first',
          'Make data validation first',
          'Make data validation first'
        ]
      }
    },
    {
      lang: 'GER',
      promptText: {
        UNDEFINED: [
          'Undefinierter Zustand',
          'Undefinierter Zustand',
          'Undefinierter Zustand',
          'Undefinierter Zustand',
        ],
        TODO: [
          'Daten importieren',
          'Starten Sie die Datenvalidierung',
          'Starten Sie den Datenexport',
          'Starten Sie die Datenanalyse'
        ],
        IN_PROGRESS: ['', '', '', ''],
        DONE: ['', '', '', ''],
        CLOSED: ['', '', '', 'Falscher Zustand'],
        LOCKED: [
          '',
          'Daten zuerst importieren',
          'Stellen Sie zuerst die Datenvalidierung her',
          'Stellen Sie zuerst die Datenvalidierung her'
        ]
      }
    },
    {
      lang: 'PL',
      promptText: {
        UNDEFINED: [
          'Nieznany status',
          'Nieznany status',
          'Nieznany status',
          'Nieznany status'
        ],
        TODO: [
          'Importuj dane',
          'Rozpocznij walidację',
          'Rozpocznij eksport',
          'Rozpocznij analizę'
        ],
        IN_PROGRESS: ['', '', '', ''],
        DONE: ['', '', '', ''],
        CLOSED: ['', '', '', 'Niepoprawny status'],
        LOCKED: [
          '',
          'Najpierw zaimportuj dane',
          'Najpierw wykonaj walidację',
          'Najpierw wykonaj walidację'
        ]
      }
    }
  ],
  eventTimelineNames: [
    {
      lang: 'ENG',
      eventTimelineNames: {
        UPLOAD: 'Import data',
        VALIDATION: 'Validation',
        EXPORT: 'Export',
        ANALYSIS: 'Analysis',
        ALL_EVENTS: 'All events'
      }
    },
    {
      lang: 'GER',
      eventTimelineNames: {
        UPLOAD: 'Datenimport',
        VALIDATION: 'Validierung',
        EXPORT: 'Datenexport',
        ANALYSIS: 'Analyse',
        ALL_EVENTS: 'Alle Veranstaltungen'
      }
    },
    {
      lang: 'PL',
      eventTimelineNames: {
        UPLOAD: 'Import danych',
        VALIDATION: 'Walidacja',
        EXPORT: 'Eksport',
        ANALYSIS: 'Analiza',
        ALL_EVENTS: 'Wszystkie wydarzenia'
      }
    }
  ],

  successErorrNames: [
    {
      lang: 'ENG',
      successErorrNames: {
        UPLOAD: ['Imported', 'Invalid'],
        VALIDATION: ['Validated', 'Invalid'],
        EXPORT: ['To export', 'Exported'],
        ANALYSIS: ['Analysed', 'Anomalies', 'Number of meter', 'Forecasts'],
        ALL_EVENTS: ['Error', 'Error']
      }
    },
    {
      lang: 'GER',
      successErorrNames: {
        UPLOAD: ['Importiert', 'Invalid'],
        VALIDATION: ['Validiert', 'Invalid'],
        EXPORT: ['Exportieren', 'Exportiert'],
        ANALYSIS: ['Analysiert', 'Anomalien', 'Anzahl der Zähler', 'Prognosen'],
        ALL_EVENTS: ['Error', 'Error']
      }
    },
    {
      lang: 'PL',
      successErorrNames: {
        UPLOAD: ['Zaimportowano', 'Niepoprawne'],
        VALIDATION: ['Zwalidowano', 'Niepoprawne'],
        EXPORT: ['Do wyeksportowania', 'Wyeksportowano'],
        ANALYSIS: ['Przeanalizowano', 'Anomalie', 'Liczba mierników', 'Prognozy'],
        ALL_EVENTS: ['Błąd', 'Błąd']
      }
    }
  ],

  todayTexts: [
    {
      lang: 'ENG',
      texts: {
        todayHeader: 'Today',
        daysRemaining: 'Days remaining',
        delayedTasks: 'Delayed tasks'
      }
    },
    {
      lang: 'GER',
      texts: {
        todayHeader: 'Heute',
        daysRemaining: 'Verbleibende Tage',
        delayedTasks: 'Verzögerte Aufgaben'
      }
    },
    {
      lang: 'PL',
      texts: {
        todayHeader: 'Dzisiaj',
        daysRemaining: 'Pozostałe dni',
        delayedTasks: 'Opóźnione zadania'
      }
    }
  ],

  userRoles: [
    {
      lang: 'ENG'
    },
    {
      lang: 'GER'
    },
    {
      lang: 'PL'
    }
  ],

  roleNamesMapping: [
    {
      lang: 'ENG',
      roles: {
        ANALYTIC: 'Analityc',
        VIEWER: 'Viewer',
        ADMIN: 'Administrator',
        UNKNOWN: 'Unknown role',
        NOT_LOGGED: 'Not logged in'
      }
    },
    {
      lang: 'GER',
      roles: {
        ANALYTIC: 'Analytisch',
        VIEWER: 'Zuschauer',
        ADMIN: 'Administrator',
        UNKNOWN: 'Unbekannte Rolle',
        NOT_LOGGED: 'Nicht eingeloggt'
      }
    },
    {
      lang: 'PL',
      roles: {
        ANALYTIC: 'Analityk',
        VIEWER: 'Obserwator',
        ADMIN: 'Administrator',
        UNKNOWN: 'Nieznana rola',
        NOT_LOGGED: 'Nie zalogowany'
      }
    }
  ],

  VeeProcessStepsList: [
    {
      lang: 'ENG',
      stageName: {
        PREPROCESS: 'Preprocessing data',
        USAGES_CALCULATION: 'Calculationg usages',
        VALIDATION: 'Validating data',
        FREEZE: 'Freezing results',
        DEFAULT: 'Default VEE process statet'
      }
    },
    {
      lang: 'GER',
      stageName: {
        PREPROCESS: 'Vorverarbeitung von Daten',
        USAGES_CALCULATION: 'Berechnung von Nutzungen',
        VALIDATION: 'Datenvalidierung',
        FREEZE: 'Daten frieren ein',
        DEFAULT: 'Standardprozessstatus'
      }
    },
    {
      lang: 'PL',
      stageName: {
        PREPROCESS: 'Przygotowanie danych',
        USAGES_CALCULATION: 'Obliczanie zużyć',
        VALIDATION: 'Walidacja danych',
        FREEZE: 'Zapisywanie wyników',
        DEFAULT: 'Domyslny stan procesu'
      }
    }
  ],

  VeeProcessStatusMapper: [
    {
      lang: 'ENG',
      statusName: {
        IN_PROGRESS: 'In progress',
        DONE: 'Done',
        FAILED: 'Failed'
      }
    },
    {
      lang: 'GER',
      statusName: {
        IN_PROGRESS: 'in Bearbeitung',
        DONE: 'Erledigt',
        FAILED: 'Gescheitert'
      }
    },
    {
      lang: 'PL',
      statusName: {
        IN_PROGRESS: 'W trakcie',
        DONE: 'Wykonany',
        FAILED: 'Nie udał się'
      }
    }
  ],

  VeeProcessFailureMapper: [
    {
      lang: 'ENG',
      VeeFailureText: {
        DATA_MISSING: 'Data for Vee process were incomplete',
        OTHER: 'Vee process failed due to unknown reason'
      }
    },
    {
      lang: 'GER',
      VeeFailureText: {
        DATA_MISSING: 'Daten für den Vee-Prozess waren unvollständig',
        OTHER: 'Vee-Prozess ist aus unbekannten Gründen fehlgeschlagen'
      }
    },
    {
      lang: 'PL',
      VeeFailureText: {
        DATA_MISSING: 'Dane dla procesu VEE nie były kompletne',
        OTHER: 'Proces VEE nie udał się z nieznanego powodu'
      }
    }
  ],

  VeeProcessStepsStatus: [
    {
      lang: 'ENG',
      statusName: {
        IN_PROGRESS: 'In progress',
        DONE: 'Done',
        FAILED: 'Failed'
      }
    },
    {
      lang: 'GER',
      statusName: {
        IN_PROGRESS: 'In Bearbeitung',
        DONE: 'Erledigt',
        FAILED: 'Gescheitert'
      }
    },
    {
      lang: 'PL',
      statusName: {
        IN_PROGRESS: 'W trakcie',
        DONE: 'Wykonany',
        FAILED: 'Nie udał się'
      }
    }
  ],
  AnalysisProcessNameMapper: [
    {
      lang: 'ENG',
      stageName: {
        DETECTION_OF_ANOMALIES: 'Detection of anomalies',
        FORECASTING: 'Forecasting',
        DEFAULT: 'Default Analysis process state'
      }
    },
    {
      lang: 'GER',
      stageName: {
        DETECTION_OF_ANOMALIES: 'Erkennung von Anomalien',
        FORECASTING: 'Prognose',
        DEFAULT: 'Standardanalyse Prozessstatus'
      }
    },
    {
      lang: 'PL',
      stageName: {
        DETECTION_OF_ANOMALIES: 'Wykrywanie anomalii',
        FORECASTING: 'Prognozowanie',
        DEFAULT: 'Domyślny stan procesu analizy'
      }
    }
  ],

  AnalysisProcessStepsList: [
    {
      lang: 'ENG',
      stageName: {
        DETECTION_OF_ANOMALIES: 'Detection of anomalies',
        FORECASTING: 'Forecasting',
        DEFAULT: 'Default Analysis process state'
      }
    },
    {
      lang: 'GER',
      stageName: {
        DETECTION_OF_ANOMALIES: 'Erkennung von Anomalien',
        FORECASTING: 'Prognose',
        DEFAULT: 'Standardanalyse Prozessstatus'
      }
    },
    {
      lang: 'PL',
      stageName: {
        DETECTION_OF_ANOMALIES: 'Wykrywanie anomalii',
        FORECASTING: 'Prognozowanie',
        DEFAULT: 'Domyślny stan procesu analizy'
      }
    }
  ],

  AnalysisProcessStatusMapper: [
    {
      lang: 'ENG',
      statusName: {
        IN_PROGRESS: 'In progress',
        DONE: 'Done',
        FAILED: 'Failed'
      }
    },
    {
      lang: 'GER',
      statusName: {
        IN_PROGRESS: 'In Bearbeitung',
        DONE: 'Erledigt',
        FAILED: 'Gescheitert'
      }
    },
    {
      lang: 'PL',
      statusName: {
        IN_PROGRESS: 'W trakcie',
        DONE: 'Wykonany',
        FAILED: 'Nie udał się'
      }
    }
  ],

  AnalysisProcessFailureMapper: [
    {
      lang: 'ENG',
      AnalysisFailureText: {
        DATA_MISSING: 'Data for analysis process were incomplete',
        OTHER: 'Analysis process failed due to unknown reason'
      }
    },
    {
      lang: 'GER',
      AnalysisFailureText: {
        DATA_MISSING: 'Daten für den Analyse-Prozess waren unvollständig',
        OTHER: 'Analyse-Prozess ist aus unbekannten Gründen fehlgeschlagen'
      }
    },
    {
      lang: 'PL',
      AnalysisFailureText: {
        DATA_MISSING: 'Dane dla procesu analizy nie były kompletne',
        OTHER: 'Proces analizy nie udał się z nieznanego powodu'
      }
    }
  ],

  AnalysisProcessStepsStatus: [
    {
      lang: 'ENG',
      statusName: {
        IN_PROGRESS: 'In progress',
        DONE: 'Done',
        FAILED: 'Failed'
      }
    },
    {
      lang: 'GER',
      statusName: {
        IN_PROGRESS: 'In Bearbeitung',
        DONE: 'Erledigt',
        FAILED: 'Gescheitert'
      }
    },
    {
      lang: 'PL',
      statusName: {
        IN_PROGRESS: 'W trakcie',
        DONE: 'Wykonany',
        FAILED: 'Nie udał się'
      }
    }
  ],
  VeeErorrRule: [
    {
      lang: 'ENG',
      errorRuleDescription: 'Unable to load rules'
    },
    {
      lang: 'ENG',
      errorRuleDescription: 'Regeln konnten nicht geladen werden'
    },
    {
      lang: 'ENG',
      errorRuleDescription: 'Nie udało się pobrać reguł'
    }
  ],

  VeeRuleSeverityNameMapping: [
    {
      lang: 'ENG',
      VeeRuleSeverityEnum: { INFO: 'Info', WARNING: 'Warning', ERROR: 'Error' }
    },
    {
      lang: 'GER',
      VeeRuleSeverityEnum: { INFO: 'Info', WARNING: 'Warnung', ERROR: 'Error' }
    },
    {
      lang: 'PL',
      VeeRuleSeverityEnum: { INFO: 'Informacja', WARNING: 'Ostrzeżenie', ERROR: 'Błąd' }
    }
  ],

  VeeRuleTypeNameMapping: [
    {
      lang: 'ENG',
      VeeRuleTypeName: { ESTIMATION: 'Estimation', VALIDATION: 'Validation' }
    },
    {
      lang: 'GER',
      VeeRuleTypeName: { ESTIMATION: 'Einschätzung', VALIDATION: 'Validierung' }
    },
    {
      lang: 'PL',
      VeeRuleTypeName: { ESTIMATION: 'Estymacja', VALIDATION: 'Walidacja' }
    }
  ],

  VeeErorrAnalysisAlgorithm: [
    {
      lang: 'ENG',
      errorRuleDescription: 'Unable to load algorithms'
    },
    {
      lang: 'ENG',
      errorRuleDescription: 'Algorithmen konnten nicht geladen werden'
    },
    {
      lang: 'ENG',
      errorRuleDescription: 'Nie udało się pobrać algorytmów'
    }
  ],

  AnalysisAlgorithmSeverityNameMapping: [
    {
      lang: 'ENG',
      AnalysisAlgorithmSeverityEnum: { INFO: 'Info', WARNING: 'Warning', ERROR: 'Error' }
    },
    {
      lang: 'GER',
      AnalysisAlgorithmSeverityEnum: { INFO: 'Info', WARNING: 'Warnung', ERROR: 'Error' }
    },
    {
      lang: 'PL',
      AnalysisAlgorithmSeverityEnum: { INFO: 'Informacja', WARNING: 'Ostrzeżenie', ERROR: 'Błąd' }
    }
  ],

  AnalysisAlgorithmTypeNameMapping: [
    {
      lang: 'ENG',
      AnalysisAlgorithmTypeName: {
        DETECTION_OF_ANOMALIES: 'Detection of anomalies', FORECASTING: 'Forecasting',
        EXPLORATION: 'Exploration'
      }
    },
    {
      lang: 'GER',
      AnalysisAlgorithmTypeName: {
        DETECTION_OF_ANOMALIES: 'Erkennung von anomalien', FORECASTING: 'Vorhersage',
        EXPLORATION: 'Erkundung'
      }
    },
    {
      lang: 'PL',
      AnalysisAlgorithmTypeName: {
        DETECTION_OF_ANOMALIES: 'Wykrywanie anomalii', FORECASTING: 'Prognozowanie',
        EXPLORATION: 'Eksploracja'
      }
    }
  ],

  DataModelTexts: [
    {
      lang: 'ENG',
      texts: {
        DATA_MODEL: 'Data Model',
        NAME: 'Name',
        PARSED_NAME: 'Parsed Name',
        DESCRIPTION: 'Description',
        UNIT: 'Unit',
        TYPE: 'Type',
        IS_BILLED: 'IsBilled',
        IS_CUMULATIVE: 'IsCumulative',
        IS_PRIMARY: 'IsPrimary',
        MORE: 'More',
        EDIT: 'Edit',
        YES: 'Yes',
        EDIT_VARIABLE_FORM: 'Edit Variable Form',
        CREATE_NEW_VARIABLE_FORM: 'Create New Variable Form',
        SUBMIT: 'Submit',
        SUBMIT_CHANGES: 'Submit Changes',
        NEW_VARIABLE: 'New Variable',
        NAME_IS_REQUIRED: 'Name is required!',
        NAME_IS_OCCUPIED: 'Name is occupied!',
        PARSED_NAME_IS_REQUIRED: 'Parsed name is required!',
        PARSED_NAME_IS_OCCUPIED: 'Parsed name is occupied!',
        DESCRIPTION_IS_REQUIRED: 'Description is required!',
      }
    },
    {
      lang: 'GER',
      texts: {
        DATA_MODEL: 'Datenmodell',
        NAME: 'Name',
        PARSED_NAME: 'Analysierter Name',
        DESCRIPTION: 'Beschreibung',
        UNIT: 'Einheit',
        TYPE: 'Typ',
        IS_BILLED: 'WirdInRechnungGestellt',
        IS_CUMULATIVE: 'IstKumulativ',
        IS_PRIMARY: 'IstPrimär',
        MORE: 'Mehr',
        EDIT: 'Bearbeiten',
        YES: 'Ja',
        EDIT_VARIABLE_FORM: 'Variable Form bearbeiten',
        CREATE_NEW_VARIABLE_FORM: 'Neues variables Formular erstellen',
        SUBMIT: 'Einreichen',
        SUBMIT_CHANGES: 'Änderungen einreichen',
        NEW_VARIABLE: 'Neue Variable',
        NAME_IS_REQUIRED: 'Name ist erforderlich!',
        NAME_IS_OCCUPIED: 'Name ist besetzt!',
        PARSED_NAME_IS_REQUIRED: 'Analysierter Name ist erforderlich!',
        PARSED_NAME_IS_OCCUPIED: 'Analysierter Name ist belegt!',
        DESCRIPTION_IS_REQUIRED: 'Beschreibung ist erforderlich!',
      }
    },
    {
      lang: 'PL',
      texts: {
        DATA_MODEL: 'Model danych',
        NAME: 'Nazwa',
        PARSED_NAME: 'Sparsowana nazwa',
        DESCRIPTION: 'Opis',
        UNIT: 'Jednostka',
        TYPE: 'Typ',
        IS_BILLED: 'JestRozliczany',
        IS_CUMULATIVE: 'JestKumulatywny',
        IS_PRIMARY: 'JestPrymarny',
        MORE: 'Więcej',
        EDIT: 'Edytuj',
        YES: 'Tak',
        EDIT_VARIABLE_FORM: 'Formularz Edycji Zmiennej',
        CREATE_NEW_VARIABLE_FORM: 'Formularz Tworzenia Nowej Zmiennej',
        SUBMIT: 'Wyślij',
        SUBMIT_CHANGES: 'Wyślij zmiany',
        NEW_VARIABLE: 'Nowa Zmienna',
        NAME_IS_REQUIRED: 'Nazwa jest wymagana!',
        NAME_IS_OCCUPIED: 'Nazwa jest już zajęta!',
        PARSED_NAME_IS_REQUIRED: 'Sparsowana nazwa jest wymagana!',
        PARSED_NAME_IS_OCCUPIED: 'Sparsowana nazwa jest już zajęta!',
        DESCRIPTION_IS_REQUIRED: 'Opis jest wymagany!',
      }
    }
  ],
  MeasurementDefinitionMapper: [
    {
      lang: 'ENG',
      texts: {
        CONFIGURE: 'Configure',
        MAPPING_ERROR: 'You cannot map two model variables ​​to the same file column.',
        ADD_NEW_VARIABLE: 'Add new Variable',
        MAPPED_TO: 'Mapped to =>',
        MAPPER: 'Mapper',
        VALIDATE: 'Validate',
        MAP: 'Map',
        PRIMARY_MODEL: 'Primary Model',
        OPTIONAL_PARAMETERS: 'Optional Parameters',
        WRONG_FILE_STRUCTURE: 'Wrong file structure, lack of primary columns.',
        WRONG_FILE_FORMAT: 'Wrong file format.',
        PRIMARY_VARIABLES_MUST_BE_MAPPED: 'Primary variables must be mapped.',
        NOT_EXISTING_COLUMN: 'Cannot map on column which does not exist.',
        DELETE_MAPPING: 'Delete mapping',
        CHANGED_EXISTING_MAPPINGS: 'You changed existing mappings, be aware that you can loose  continuity of measurement history.',
        EXISTING_MAPPING: 'This is existing mapping, be carefull while changing this kind of mappings',
        MANDATORY_VARIABLES: 'Mandatory Variables -> you must create mapping for them',
        OPTIONAL_VARIABLES: 'Optional Variables',
        LACK_OF_COLUMN_IN_FILE: 'There is no such column in the file.',
      }
    },
    {
      lang: 'GER',
      texts: {
        CONFIGURE: 'Konfigurieren',
        MAPPING_ERROR: 'Sie können nicht zwei Modellvariablen derselben Dateispalte zuordnen.',
        ADD_NEW_VARIABLE: 'Neue Variable hinzufügen',
        MAPPED_TO: 'Zugeordnet zu =>',
        MAPPER: 'Mapper',
        VALIDATE: 'Bestätigen',
        MAP: 'Map',
        PRIMARY_MODEL: 'Primäres Modell',
        OPTIONAL_PARAMETERS: 'Optionale Parameter',
        WRONG_FILE_STRUCTURE: 'Falsche Dateistruktur, fehlende Primärspalten.',
        WRONG_FILE_FORMAT: 'Falsches Dateiformat.',
        PRIMARY_VARIABLES_MUST_BE_MAPPED: 'Primärvariablen müssen zugeordnet werden.',
        NOT_EXISTING_COLUMN: 'Kann nicht auf eine nicht vorhandene Spalte abgebildet werden.',
        DELETE_MAPPING: 'Zuordnung löschen',
        CHANGED_EXISTING_MAPPINGS: 'Sie haben vorhandene Zuordnungen geändert. Beachten Sie, dass Sie die Kontinuität des Messverlaufs verlieren können.',
        EXISTING_MAPPING: 'Dies ist eine vorhandene Zuordnung. Seien Sie vorsichtig, während Sie diese Art der Zuordnung ändern',
        MANDATORY_VARIABLES: 'Obligatorische Variablen -> Sie müssen eine Zuordnung für sie erstellen',
        OPTIONAL_VARIABLES: 'Optionale Variablen',
        LACK_OF_COLUMN_IN_FILE: 'Die Datei enthält keine solche Spalte.',
      }
    },
    {
      lang: 'PL',
      texts: {
        CONFIGURE: 'Konfiguruj',
        MAPPING_ERROR: 'Nie można zmapować dwóch zmiennych modelu na tę samą kolumnę pliku.',
        ADD_NEW_VARIABLE: 'Dodaj zmienną',
        MAPPED_TO: 'Zmapowane na =>',
        MAPPER: 'Mapper',
        VALIDATE: 'Waliduj',
        MAP: 'Mapuj',
        PRIMARY_MODEL: 'Model Podstawowy',
        OPTIONAL_PARAMETERS: 'Parametry Opcjonalne',
        WRONG_FILE_STRUCTURE: 'Błędna struktura pliku, brak wymaganych kolumn.',
        WRONG_FILE_FORMAT: 'Błędny format pliku.',
        PRIMARY_VARIABLES_MUST_BE_MAPPED: 'Zmienne podstawowe muszą zostać zmapowane.',
        NOT_EXISTING_COLUMN: 'Nie można dokonać mapowania na nieistniejącą kolumnę.',
        DELETE_MAPPING: 'Usuń mapowanie',
        CHANGED_EXISTING_MAPPINGS: 'Zmieniłeś/aś istniejące mapowania, pamiętaj, że możesz stracić ciągłość historii pomiarów.',
        EXISTING_MAPPING: 'To jest istniejące mapowanie, bądź ostrożny przy zmianie tego rodzaju mapowania',
        MANDATORY_VARIABLES: 'Zmienne obowiązkowe -> musisz utworzyć dla nich mapowania',
        OPTIONAL_VARIABLES: 'Zmienne opcjonalne',
        LACK_OF_COLUMN_IN_FILE: 'Nie ma takiej kolumny w pliku.',
      }
    }
  ]

};
