<h2>{{ assetDataHeader }}</h2>

<div class="card table-filter">
	<div class="card-header input-filter">
		<div [formGroup]="changeFilterForm">
			<b>{{ parametrsLabel }}</b>

			<mat-form-field id="metersFilter" class="form-group">
				<input matInput type="text" placeholder="{{parametersField.METER_ID}}" formControlName="meter">
			</mat-form-field>

			<mat-form-field id="aggregationSelector" class="form-group">
				<mat-select placeholder="{{parametersField.AGGREGATION}}" formControlName="aggregation">
					<mat-option value="d">{{ parametersField.DAY }}</mat-option>
					<mat-option value="m">{{ parametersField.MONTH }}</mat-option>
					<mat-option value="h">{{ parametersField.HOUR }}</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field id="startDate" class="form-group">
				<input matInput [matDatepicker]="startDatePicker" 
					placeholder="{{parametersField.DateFrom}}" formControlName="startDate"
					[min]="minDate" [max]="maxDate" required>
				<mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
				<mat-datepicker #startDatePicker></mat-datepicker>
			</mat-form-field>

			<mat-form-field id="endDate" class="form-group">
				<input matInput [matDatepicker]="endDatePicker" 
					placeholder="{{parametersField.DateTo}}" formControlName="endDate"
					[min]="minDate" [max]="maxDate" required>
				<mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
				<mat-datepicker #endDatePicker></mat-datepicker>
			</mat-form-field>

			<mat-form-field id="location" class="form-group">
				<input matInput type="text" placeholder="{{tableHeaders.LOCATION}}" formControlName="address">
			</mat-form-field>
		</div>
		<div class="smaller-screens-btns-data-analysis">
			<button id="selectAll" (click)="selectOrUnselectAll()" mat-raised-button color="accent" class="btn">
				{{select}}
			</button>

			<button id="startAnalysis" [disabled]="listEnabledAlgorithm.length === 0 || invalidDates() || temperaturesCount === 0"
				(click)="startAnalysis()" mat-raised-button color="accent" class="btn">
				<a [matTooltip]="noTemperatures" [matTooltipDisabled]="temperaturesCount !== 0"
					matTooltipPosition="below" matTooltipShowDelay="500">
					{{buttonLabel}}
				</a>
			</button>
		</div>

	</div>
	<mat-table id="metersTable" #table [dataSource]="dataSource">

		<ng-container matColumnDef="idMeter">
			<mat-header-cell *matHeaderCellDef> {{ tableHeaders.METER_ID }} </mat-header-cell>
			<mat-cell *matCellDef="let element">
				<mat-checkbox #checkbox [checked]="getEnabled(element)" (change)="changeEnabled(element,$event)">
					{{element.serialNumber}}
				</mat-checkbox>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="location">
			<mat-header-cell *matHeaderCellDef> {{ tableHeaders.LOCATION }} </mat-header-cell>
			<mat-cell *matCellDef="let element">{{element.meterPlacement}}</mat-cell>
		</ng-container>

		<ng-container matColumnDef="measurementType">
			<mat-header-cell *matHeaderCellDef> {{ tableHeaders.MES_TYPE }} </mat-header-cell>
			<mat-cell *matCellDef="let element">{{element.getMesurementTypeaAsString()}} </mat-cell>
		</ng-container>

		<ng-container matColumnDef="customer">
			<mat-header-cell *matHeaderCellDef> {{ tableHeaders.CUSTOMER }} </mat-header-cell>
			<mat-cell *matCellDef="let element">{{element.customer ? element.customer.denotation : ''}} </mat-cell>
		</ng-container>

		<ng-container matColumnDef="segment">
			<mat-header-cell *matHeaderCellDef> {{ tableHeaders.SEGMENT }} </mat-header-cell>
			<mat-cell *matCellDef="let element">
				{{element.customer ? element.customer.getCustomerSegmentAsString() : ''}} </mat-cell>
		</ng-container>

		<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
		<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
	</mat-table>

	<div class="no-result" [style.display]="!isLoading() && getDataSourceLength() === 0 ? '' : 'none'">
		{{noData}}
	</div>

	<div class="no-result" [style.display]="isLoading() ? '' : 'none'">
		<mat-spinner [strokeWidth]="3" [diameter]="20" class="notranslate"></mat-spinner>
	</div>

	<mat-paginator #paginator [length]="getDataSourceLength()" [pageIndex]="0" [pageSize]="10"
		[pageSizeOptions]="[5, 10, 20]" showFirstLastButtons class="notranslate">
	</mat-paginator>
</div>