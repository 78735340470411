import { IMeasurementData } from '@shared/models/appModels/measurementData.model';
import { StatusFlagEnum, ValidationResultEnum } from '@shared/types/modelTypes/meterDataTypes';
import { MeasurementOriginEnum, VeeStatusEnum } from '@shared/types/modelTypes/measurementType';
import { environment } from '@env/environment';

const mockMeasurementData_Met1: IMeasurementData[] = [
  {
    idMeasurementData: '39485796', idMeterPoint: 1, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2017-06-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 1, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum.NORMAL, measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2017-07-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 1, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2017-08-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 1, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2017-09-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 1, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2017-10-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 1, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2017-11-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 1, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2017-12-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 1, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2018-01-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 1, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2018-02-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 1, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2018-03-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
];

const mockMeasurementData_Met2: IMeasurementData[] = [
  {
    idMeasurementData: '39485796', idMeterPoint: 2, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2017-06-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 2, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum.NORMAL, measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2017-07-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 2, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2017-08-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 2, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2017-09-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 2, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2017-10-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 2, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2017-11-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 2, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2017-12-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 2, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2018-01-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 2, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2018-02-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 2, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2018-03-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
];

const mockMeasurementData_Met3: IMeasurementData[] = [
  {
    idMeasurementData: '39485796', idMeterPoint: 3, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2017-06-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 3, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum.NORMAL, measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2017-07-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 3, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2017-08-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 3, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2017-09-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 3, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2017-10-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 3, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2017-11-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 3, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2017-12-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 3, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2018-01-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 3, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2018-02-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 3, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2018-03-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
];

const mockMeasurementData_Met4: IMeasurementData[] = [
  {
    idMeasurementData: '39485796', idMeterPoint: 4, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2017-06-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 4, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum.NORMAL, measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2017-07-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 4, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2017-08-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 4, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2017-09-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 4, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2017-10-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 4, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2017-11-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 4, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2017-12-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 4, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2018-01-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 4, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2018-02-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 4, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2018-03-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
];

const mockMeasurementData_Met5: IMeasurementData[] = [
  {
    idMeasurementData: '39485796', idMeterPoint: 5, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2017-06-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 5, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum.NORMAL, measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2017-07-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 5, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2017-08-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 5, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2017-09-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 5, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2017-10-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 5, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2017-11-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 5, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2017-12-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 5, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2018-01-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 5, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2018-02-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
  {
    idMeasurementData: '39485796', idMeterPoint: 5, idPosition: 1, idFileMeterData: 1,
    statusFlag: StatusFlagEnum[StatusFlagEnum.NORMAL], measurementOrigin: MeasurementOriginEnum.FROM_FILE, validationResult: ValidationResultEnum.VALID,
    estimation: VeeStatusEnum.NOT_REQUIRED, validation: VeeStatusEnum.NOT_REQUIRED,
    timestamp: +new Date('2018-03-15'),
    userAcceptance: false, recordValid: true,
    measurementValues: {},
  },
];

const mockAgregate: IMeasurementData[][] = [
  mockMeasurementData_Met1,
  mockMeasurementData_Met2,
  mockMeasurementData_Met3,
  mockMeasurementData_Met4,
  mockMeasurementData_Met5
];

export function getMockMeasurmeentData(): IMeasurementData[] {
  let lineDataArray: IMeasurementData[] = [];
  lineDataArray = mockAgregate.reduce((prev: IMeasurementData[], current: IMeasurementData[]): IMeasurementData[] => {
    return prev.concat(current);
  }).slice(0);

  if (environment.randomizeMock) {
    for (let i = 0; i < lineDataArray.length; i++) {
      lineDataArray[i].measurementValues = {
        'v1': Math.floor((Math.random() * 10) + 1),
        'v2': Math.floor((Math.random() * 10) + 1),
        'v3': Math.floor((Math.random() * 10) + 1)
      };
    }
  }

  return lineDataArray.slice(0);
}
