import { NgModule } from "@angular/core";
import { DateAdapter } from "@angular/material/core";
import { SV_DATETIME_FORMAT, SV_DATE_FORMAT } from "./date-formats";
import { DatePipe, DateTimePipe } from "./date.pipe";

@NgModule({
  imports: [],
  declarations: [DatePipe, DateTimePipe],
  exports: [DatePipe, DateTimePipe],
  providers: [
    {
      provide: DatePipe,
      useClass: DatePipe,
      deps: [SV_DATE_FORMAT, DateAdapter],
    },
    {
      provide: DateTimePipe,
      useClass: DateTimePipe,
      deps: [SV_DATETIME_FORMAT, DateAdapter],
    },
  ],
})
export class CommonPipeModule {}
