export const MockListAlgorithms1 = [
  {
    name: 'Power factor',
    params: [
      {name: 'Thermal energy consumption less than 300GJ or larger than 600GJ', checked: 'checked'},
      {name: 'The use of power less than 80% or larger than 120%', checked: ''},
      {name: 'Change WWM less than 10% or larger than 40%', checked: 'checked'},
      {name: 'Declared power between 0,1MW and 0,2MW', checked: ''}
    ]
  },
  {
    name: 'Percentage deviation of the GJ/STD indicator',
    params: [
      {name: 'Indicator deviation less than 10% or larger than 90%', checked: 'checked'},
      {name: 'Month of reference: November 2017', checked: 'checked'}
    ]
  }
];

export const MockListAlgorithms2 = [
  {
    name: 'Use the deviation Qzm/T',
    params: [
      {name: 'Consumption deviation less than 10% or larger than 90%', checked: 'checked'}
    ]
  }
];
