import { IMeterData } from '@shared/models/appModels/meterData.model';
import { StatusFlagEnum, ValidationResultEnum } from '@shared/types/modelTypes/meterDataTypes';
import { environment } from '@env/environment';

export const mockMeter1: IMeterData[] = [
  {idPosition: 1, idMeterData: '' + 12, idMeterPoint: 1,
   timestamp: + new Date('2017-07-15'),
   usageValues: {'energy': 50},
    statusFlag: StatusFlagEnum.NORMAL,  validationResult: ValidationResultEnum.VALID},
  {idPosition: 1, idMeterData: '' + 13, idMeterPoint: 1,
   timestamp: + new Date('2017-08-15'),
   usageValues: {'energy': 50},
    statusFlag: StatusFlagEnum.NORMAL,  validationResult: ValidationResultEnum.VALID},
  {idPosition: 1, idMeterData: '' + 14, idMeterPoint: 1,
   timestamp: + new Date('2017-09-15'),
   usageValues: {'energy': 50},
    statusFlag: StatusFlagEnum.NORMAL,  validationResult: ValidationResultEnum.VALID},
  {idPosition: 1, idMeterData: '' + 15, idMeterPoint: 1,
   timestamp: + new Date('2017-10-15'),
   usageValues: {'energy': 50},
    statusFlag: StatusFlagEnum.NORMAL,  validationResult: ValidationResultEnum.VALID},
  {idPosition: 1, idMeterData: '' + 16, idMeterPoint: 1,
   timestamp: + new Date('2017-11-15'),
   usageValues: {'energy': 50},
    statusFlag: StatusFlagEnum.NORMAL,  validationResult: ValidationResultEnum.VALID},
  {idPosition: 1, idMeterData: '' + 17, idMeterPoint: 1,
   timestamp: + new Date('2017-12-15'),
   usageValues: {'energy': 50},
    statusFlag: StatusFlagEnum.NORMAL,  validationResult: ValidationResultEnum.VALID},
  {idPosition: 1, idMeterData: '' + 18, idMeterPoint: 1,
   timestamp: + new Date('2018-01-15'),
   usageValues: {'energy': 50},
     statusFlag: StatusFlagEnum.NORMAL,  validationResult: ValidationResultEnum.VALID},
  {idPosition: 1, idMeterData: '' + 19, idMeterPoint: 1,
   timestamp: + new Date('2018-02-15'),
   usageValues: {'energy': 50},
    statusFlag: StatusFlagEnum.NORMAL,  validationResult: ValidationResultEnum.INVALID},
    {idPosition: 1, idMeterData: '' + 11, idMeterPoint: 1,
    timestamp: + new Date('2018-03-15'),
    usageValues: {'energy': 50},
     statusFlag: StatusFlagEnum.NORMAL,  validationResult: ValidationResultEnum.INVALID},
];

export const mockMeter2: IMeterData[] = [
  {idPosition: 1, idMeterData: '' + 2, idMeterPoint: 2,
   timestamp: + new Date('2017-07-15'),
  usageValues: {'energy': 50},
    statusFlag: StatusFlagEnum.NORMAL, validationResult: ValidationResultEnum.VALID},
  {idPosition: 1, idMeterData: '' + 3, idMeterPoint: 2,
   timestamp: + new Date('2017-08-15'),
  usageValues: {'energy': 50},
    statusFlag: StatusFlagEnum.NORMAL, validationResult: ValidationResultEnum.VALID},
  {idPosition: 1, idMeterData: '' + 4, idMeterPoint: 2,
   timestamp: + new Date('2017-09-15'),
  usageValues: {'energy': 50},
    statusFlag: StatusFlagEnum.NORMAL, validationResult: ValidationResultEnum.VALID},
  {idPosition: 1, idMeterData: '' + 6, idMeterPoint: 2,
   timestamp: + new Date('2017-10-15'),
  usageValues: {'energy': 50},
    statusFlag: StatusFlagEnum.NORMAL, validationResult: ValidationResultEnum.VALID},
  {idPosition: 1, idMeterData: '' + 7, idMeterPoint: 2,
   timestamp: + new Date('2017-11-15'),
  usageValues: {'energy': 50},
    statusFlag: StatusFlagEnum.NORMAL, validationResult: ValidationResultEnum.VALID},
  {idPosition: 1, idMeterData: '' + 8, idMeterPoint: 2,
   timestamp: + new Date('2017-12-15'),
  usageValues: {'energy': 50},
    statusFlag: StatusFlagEnum.NORMAL, validationResult: ValidationResultEnum.VALID},
  {idPosition: 1, idMeterData: '' + 9, idMeterPoint: 2,
   timestamp: + new Date('2018-01-15'),
  usageValues: {'energy': 50},
    statusFlag: StatusFlagEnum.NORMAL, validationResult: ValidationResultEnum.VALID},
  {idPosition: 1, idMeterData: '' + 10, idMeterPoint: 2,
   timestamp: + new Date('2018-02-15'),
  usageValues: {'energy': 50},
    statusFlag: StatusFlagEnum.NORMAL, validationResult: ValidationResultEnum.VALID},
  {idPosition: 1, idMeterData: '' + 1, idMeterPoint: 2,
   timestamp: + new Date('2018-03-15'),
   usageValues: {'energy': 50},
    statusFlag: StatusFlagEnum.NORMAL, validationResult: ValidationResultEnum.INVALID},
];

export const mockMeter3: IMeterData[] = [
  {idPosition: 1, idMeterData: '' + 22, idMeterPoint: 3,
   timestamp: + new Date('2017-07-15'),
   usageValues: {'energy': 50},
    statusFlag: StatusFlagEnum.NORMAL,  validationResult: ValidationResultEnum.VALID},
  {idPosition: 1, idMeterData: '' + 23, idMeterPoint: 3,
   timestamp: + new Date('2017-08-15'),
   usageValues: {'energy': 50},
    statusFlag: StatusFlagEnum.CONTROL,  validationResult: ValidationResultEnum.VALID},
  {idPosition: 1, idMeterData: '' + 24, idMeterPoint: 3,
   timestamp: + new Date('2017-09-15'),
   usageValues: {'energy': 50},
    statusFlag: StatusFlagEnum.NORMAL,  validationResult: ValidationResultEnum.VALID},
  {idPosition: 1, idMeterData: '' + 25, idMeterPoint: 3,
   timestamp: + new Date('2017-10-15'),
   usageValues: {'energy': 50},
    statusFlag: StatusFlagEnum.NORMAL,  validationResult: ValidationResultEnum.VALID},
  {idPosition: 1, idMeterData: '' + 26, idMeterPoint: 3,
   timestamp: + new Date('2017-11-15'),
   usageValues: {'energy': 50},
    statusFlag: StatusFlagEnum.NORMAL,  validationResult: ValidationResultEnum.VALID},
  {idPosition: 1, idMeterData: '' + 27, idMeterPoint: 3,
   timestamp: + new Date('2017-12-15'),
   usageValues: {'energy': 50},
    statusFlag: StatusFlagEnum.NORMAL,  validationResult: ValidationResultEnum.VALID},
  {idPosition: 1, idMeterData: '' + 28, idMeterPoint: 3,
   timestamp: + new Date('2018-01-15'),
   usageValues: {'energy': 50},
    statusFlag: StatusFlagEnum.NORMAL,  validationResult: ValidationResultEnum.VALID},
  {idPosition: 1, idMeterData: '' + 29, idMeterPoint: 3,
   timestamp: + new Date('2018-02-15'),
   usageValues: {'energy': 50},
    statusFlag: StatusFlagEnum.NORMAL,  validationResult: ValidationResultEnum.VALID},
  {idPosition: 1, idMeterData: '' + 21, idMeterPoint: 3,
   timestamp: + new Date('2018-03-15'),
   usageValues: {'energy': 50},
    statusFlag: StatusFlagEnum.NORMAL,  validationResult: ValidationResultEnum.AUTOCORRECTION},
];

export const mockMeter4: IMeterData[] = [
  {idPosition: 1, idMeterData: '' + 42, idMeterPoint: 4,
   timestamp: + new Date('2017-07-15'),
   usageValues: {'energy': 50},
    statusFlag: StatusFlagEnum.NORMAL,  validationResult: ValidationResultEnum.VALID},
  {idPosition: 1, idMeterData: '' + 43, idMeterPoint: 4,
   timestamp: + new Date('2017-08-15'),
   usageValues: {'energy': 50},
    statusFlag: StatusFlagEnum.NORMAL,  validationResult: ValidationResultEnum.VALID},
  {idPosition: 1, idMeterData: '' + 44, idMeterPoint: 4,
   timestamp: + new Date('2017-09-15'),
   usageValues: {'energy': 50},
    statusFlag: StatusFlagEnum.NORMAL,  validationResult: ValidationResultEnum.VALID},
  {idPosition: 1, idMeterData: '' + 45, idMeterPoint: 4,
   timestamp: + new Date('2017-10-15'),
   usageValues: {'energy': 50},
    statusFlag: StatusFlagEnum.NORMAL,  validationResult: ValidationResultEnum.VALID},
  {idPosition: 1, idMeterData: '' + 46, idMeterPoint: 4,
   timestamp: + new Date('2017-11-15'),
   usageValues: {'energy': 50},
     statusFlag: StatusFlagEnum.NORMAL,  validationResult: ValidationResultEnum.VALID},
  {idPosition: 1, idMeterData: '' + 47, idMeterPoint: 4,
   timestamp: + new Date('2017-12-15'),
   usageValues: {'energy': 50},
    statusFlag: StatusFlagEnum.NORMAL,  validationResult: ValidationResultEnum.VALID},
  {idPosition: 1, idMeterData: '' + 48, idMeterPoint: 4,
   timestamp: + new Date('2018-01-15'),
   usageValues: {'energy': 50},
    statusFlag: StatusFlagEnum.NORMAL,  validationResult: ValidationResultEnum.VALID},
  {idPosition: 1, idMeterData: '' + 49, idMeterPoint: 4,
   timestamp: + new Date('2018-02-15'),
   usageValues: {'energy': 50},
    statusFlag: StatusFlagEnum.NORMAL,  validationResult: ValidationResultEnum.INVALID},
    {idPosition: 1, idMeterData: '' + 41, idMeterPoint: 4,
    timestamp: + new Date('2018-03-15'),
    usageValues: {'energy': 50},
     statusFlag: StatusFlagEnum.NORMAL,  validationResult: ValidationResultEnum.INVALID},
];

export const mockMeter5: IMeterData[] = [
  {idPosition: 1, idMeterData: '' + 32, idMeterPoint: 5,
   timestamp: + new Date('2017-07-15'),
   usageValues: {'energy': 50},
     statusFlag: StatusFlagEnum.NORMAL,  validationResult: ValidationResultEnum.VALID},
  {idPosition: 1, idMeterData: '' + 33, idMeterPoint: 5,
   timestamp: + new Date('2017-08-15'),
   usageValues: {'energy': 50},
     statusFlag: StatusFlagEnum.NORMAL,  validationResult: ValidationResultEnum.VALID},
  {idPosition: 1, idMeterData: '' + 34, idMeterPoint: 5,
   timestamp: + new Date('2017-09-15'),
   usageValues: {'energy': 50},
    statusFlag: StatusFlagEnum.NORMAL,  validationResult: ValidationResultEnum.INVALID},
  {idPosition: 1, idMeterData: '' + 35, idMeterPoint: 5,
   timestamp: + new Date('2017-10-15'),
   usageValues: {'energy': 50},
     statusFlag: StatusFlagEnum.NORMAL,  validationResult: ValidationResultEnum.VALID},
  {idPosition: 1, idMeterData: '' + 36, idMeterPoint: 5,
   timestamp: + new Date('2017-11-15'),
   usageValues: {'energy': 50},
    statusFlag: StatusFlagEnum.NORMAL,  validationResult: ValidationResultEnum.VALID},
  {idPosition: 1, idMeterData: '' + 37, idMeterPoint: 5,
   timestamp: + new Date('2017-12-15'),
   usageValues: {'energy': 50},
     statusFlag: StatusFlagEnum.NORMAL,  validationResult: ValidationResultEnum.VALID},
  {idPosition: 1, idMeterData: '' + 38, idMeterPoint: 5,
   timestamp: + new Date('2018-01-15'),
   usageValues: {'energy': 50},
     statusFlag: StatusFlagEnum.NORMAL,  validationResult: ValidationResultEnum.VALID},
  {idPosition: 1, idMeterData: '' + 39, idMeterPoint: 5,
   timestamp: + new Date('2018-02-15'),
   usageValues: {'energy': 50},
    statusFlag: StatusFlagEnum.NORMAL,  validationResult: ValidationResultEnum.INVALID},
    {idPosition: 1, idMeterData: '' + 31, idMeterPoint: 5,
    timestamp: + new Date('2018-03-15'),
    usageValues: {'energy': 50},
      statusFlag: StatusFlagEnum.NORMAL,  validationResult: ValidationResultEnum.VALID},
];


const mockMeterAgregate: IMeterData[][] = [mockMeter1, mockMeter2, mockMeter3, mockMeter4, mockMeter5];

export function getMockMeterData(): IMeterData[] {
  let lineDataArray: IMeterData[] = [];
  lineDataArray = mockMeterAgregate.reduce((prev: IMeterData[], current: IMeterData[]): IMeterData[] => {
    return prev.concat(current);
  })
  .map(v => {
    v.idMeterData = v.timestamp + '_' + v.idMeterPoint;
    return v;
  })
  .slice(0);

  if (environment.randomizeMock) {
    for (let i = 0; i < lineDataArray.length; i++) {
      lineDataArray[i].usageValues = {
        'v1': Math.floor((Math.random() * 10) + 1),
        'v2': Math.floor((Math.random() * 10) + 1),
        'v3': Math.floor((Math.random() * 10) + 1),
      };
    }
  }

  return lineDataArray.slice(0);
}
