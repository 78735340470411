<div class="custom-container">
  <div id="selectMeterPoints">
  <h3 i18n="@@export/custom billings modal/choose meter points">Choose meter points:</h3>
  <br />
  <sv-input-with-autocomplete-and-chips *ngIf="initComponent" [wrongElement]="wrongMeterPoint"
    [elementRequired]="meterPointsRequired" [disabledInput]="disableMeterPointsInput"
    (selectedDataEventEmitter)="selectedDataEvent($event)" [inputPlaceholder]="metersPlaceholder"
    (typingEventEmitter)="onTypingEvent($event)"
    [fetchingData] = "fetchingData"
    [allData]="serialNumbers"></sv-input-with-autocomplete-and-chips>
  <br />
  <div class="row padd-left">
    <mat-checkbox (change)="onSelectAllMeters($event)" i18n="@@export/custom billings modal/select all meter points">
      Select all meter points</mat-checkbox>
  </div>
  </div>
  <div class="custom-btn">
    <div matTooltip="You have to add some values ​​to be able to move on." i18n-matTooltip="@@export/custom billing modal/add values tooltip" [matTooltipDisabled]="!(meters.length===0 && !allMeterPointsChecked)" matTooltipPosition="below"
		matTooltipShowDelay="500" class="tooltip-div">
    <button id="nextstep" mat-raised-button color="accent" class="btn btn-action" (click)="onNextStep()"
      [disabled]="meters.length===0 && !allMeterPointsChecked" i18n="@@export/custom billings modal/next step">
      Next step
    </button>
  </div>
  </div>
</div>