import { Component, OnInit, ViewChild, ChangeDetectionStrategy, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';

import { MatPaginator, PageEvent } from '@angular/material/paginator';

import { OperationalDataSource } from './dataSource/operationalDataSource';

import { AssetDataDialogService } from './service/asset-dialogs.service';
import { MeterPoint } from '@shared/models';
import { Customer } from '@shared/models/appModels';
import { CustomerDataStoreService } from '@shared/services/dataStore/customerDataStore.service';
import { MeterPointDataStoreService } from '@shared/services/dataStore/meterPointDataStore.service';
import { FileOperationalDataStoreService } from '@shared/services/dataStore/fileOperationalDataStore.service';
import { FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { environment } from '@env/environment';

import { TableHost } from '@shared/types/applicationTypes';
import { TimeGroupDataStoreService } from '@shared/services/dataStore/timeGroupDataStore.service';
import { TimeGroup } from '@models/appModels/timeGroup.model';
import { UploadLocalisation as loc } from './../../upload.localisation';
import { typesLocalisation } from '@shared/types/localisation';
import { CommonAppDataService } from '@shared/services/commonAppData.service';
// rxjs 6
import { Observable, merge, of, Subscription } from 'rxjs';
import { concatMap, map, debounceTime, filter, tap, switchMap } from 'rxjs/operators';
import { InteractiveTutorialService, NextComponentName } from '@shared/services/interactiveTutorialService.service';
import { AssetUploadInteractiveTutorialService } from '../assetUploadInteractiveTutorialService.service';

@Component({

  // tslint:disable-next-line:component-selector
  selector: 'sv-asset-data-preview',
  templateUrl: './asset-data-preview.component.html',
  styleUrls: ['./../../data-preview.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientDataPreviewComponent implements OnInit, AfterViewInit, OnDestroy, TableHost<MeterPoint> {

  /* View references */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  readonly menuMdIcon: string = 'menu';
  readonly configureMdIcon: string = 'mode_edit';
  readonly deleteMdIcon: string = 'close';
  readonly checkMdIcon: string = 'check';
  readonly debugMode = environment.debug;
  displayedColumns = ['serialMeter', 'address', 'measurementType', 'denotation', 'segment', 'action'];
  dataSource: OperationalDataSource;
  private checkboxFilterGroup: FormGroup;
  public checkboxName = ['manually added (0)', 'from file (11)'];

  dataLength: number;
  dataLoading: boolean;
  dataFetched: boolean = false;
  loadDataSubscription: Subscription;
  paginatorEventsSubscription: Subscription;
  filterEventsSubscription: Subscription;
  externalDataChangeSubscription: Subscription;
  clearFiltersSubscription: Subscription;
  canStartInteractiveTut$: Subscription;

  readonly tableHeaders = loc[environment.language].texts.asset.assetDataPreview.tableHeaders;
  readonly header = loc[environment.language].texts.asset.assetDataPreview.header;
  readonly actions = loc[environment.language].texts.asset.assetDataPreview.actions;
  readonly noData = loc[environment.language].texts.asset.assetDataPreview.noData;
  readonly addNewButton = loc[environment.language].texts.asset.assetDataPreview.addNewButton;
  readonly filtersLabel = loc[environment.language].texts.asset.assetDataPreview.filtersLabel;
  readonly noCustomer = loc[environment.language].texts.asset.assetDataPreview.noCustomer;
  readonly itemsPage = typesLocalisation.ItemsPage[environment.language].texts;

  public noCustomerString = {
    customer: this.noCustomer,
    active: this.noCustomer,
    segment: this.noCustomer,
  };


  constructor(private dialogsService: AssetDataDialogService,
    private customerDataStoreService: CustomerDataStoreService,
    private meterPointDataStoreService: MeterPointDataStoreService,
    private fileOperationalDataStoreService: FileOperationalDataStoreService,
    private timeGroupService: TimeGroupDataStoreService,
    private communicationService: CommonAppDataService,
    private cdr: ChangeDetectorRef,
    private interactiveTutorialServ: InteractiveTutorialService,
    private assetUploadInteractiveTutorialService: AssetUploadInteractiveTutorialService) {

  }

  ngOnInit() {
    this.dataSource = new OperationalDataSource(this.communicationService);
    this.checkboxFilterGroup = new FormGroup({
      filterbyMeterPoint: new FormControl(''),
    });
    this.dataLength = 0;
    this.canStartInteractiveTut$ = this.interactiveTutorialServ.canGoToNextStepSubject.pipe(debounceTime(100)).subscribe(val => {
      if(val === NextComponentName.ASSET_UPLOAD_DATA_PREVIEW){
        this.startInteractiveTutorial();
      }
    })
  }

  startInteractiveTutorial(){
    const steps = this.assetUploadInteractiveTutorialService.getAssetUploadDataPreviewInteractiveTutorialSteps();
    this.interactiveTutorialServ.startInteractiveTutorial(steps);
  }

  ngAfterViewInit(): void {
    this.paginator._intl.itemsPerPageLabel = this.itemsPage.itemsPage;
    this.paginator._intl.nextPageLabel = this.itemsPage.nextPage;
    this.paginator._intl.previousPageLabel = this.itemsPage.previousPage;
    this.paginator._intl.lastPageLabel = this.itemsPage.lastPage;
    this.paginator._intl.firstPageLabel = this.itemsPage.firstPage;
    const limit = this.paginator.pageSize;
    const offset = this.paginator.pageIndex * this.paginator.pageSize;

    this.loadDataSubscription = this.loadData(limit, offset)
      .subscribe((meters: MeterPoint[]) => {
        this.dataSource.setNewData(meters);
      },
        (err: any) => { if (this.debugMode) { console.log(err); } },
        () => {
          if (this.debugMode) { console.log(`Init data fetch Finished`); }
          this.dataFetched = true;
          this.cdr.detectChanges();
        },
      );
    this.paginatorEventsSubscription = this.onPaginatorEvents(this.paginator)
      .subscribe((pagedData: MeterPoint[]) => {
        if (this.debugMode) { console.warn(`New Data triggered by paginator event`); }
        this.dataSource.setNewData(pagedData);
      },
        (err: any) => { if (this.debugMode) { console.log(err); } },
        () => { if (this.debugMode) { console.log(`Finished`); } },
      );

    this.filterEventsSubscription = this.onFilterEvents()
      .subscribe((pagedData: MeterPoint[]) => {
        if (this.debugMode) { console.warn(`New Data triggered by filtering event`); }
        if (this.debugMode) { console.log(pagedData); }
        this.dataSource.setNewData(pagedData);
        this.cdr.detectChanges();
      },
        (err) => { if (this.debugMode) { console.log(err); } },
        () => { if (this.debugMode) { console.log(`Finished`); } }
      );

    this.externalDataChangeSubscription = this.onExternalDataChangeEvent([
      this.meterPointDataStoreService.getChangeObservable(),
      this.customerDataStoreService.getChangeObservable(),
      this.fileOperationalDataStoreService.getChangeObservable()
    ])
      .subscribe((pagedData: MeterPoint[]) => {
        if (this.debugMode) { console.warn(`New Data triggered by external data change event`); }
        this.dataSource.setNewData(pagedData);
      },
        (err) => { if (this.debugMode) { console.log(err); } },
        () => { if (this.debugMode) { console.log(`Finished`); } }
      );

    this.clearFiltersSubscription = this.onClearFilters()
      .subscribe((pagedData: MeterPoint[]) => {
        if (this.debugMode) { console.warn(`New Data triggered by clear filter event`); }
        if (this.debugMode) { console.log(pagedData); }
        this.dataSource.setNewData(pagedData);
      },
        (err) => { if (this.debugMode) { console.log(err); } },
        () => { if (this.debugMode) { console.log(`Finished`); } }
      );
  }

  loadData(limit: number = 50, offset: number = 0,
    otherParams?: { filterString: string }): Observable<MeterPoint[]> {

    let dataFetchObservable: Observable<MeterPoint[]>;
    let countObservable: Observable<number>;
    if (!otherParams) {
      dataFetchObservable = this.meterPointDataStoreService.getMetersListWithCustomer({ limit: limit, offset: offset }, true);
      countObservable = this.meterPointDataStoreService.getMeterPointCount();
    } else if (otherParams.filterString) {
      dataFetchObservable = this.meterPointDataStoreService.getMetersListWithCustomer(
        { limit: limit, offset: offset },
        true,
        { name: otherParams.filterString });
      countObservable = this.meterPointDataStoreService.getMeterPointCount({ name: otherParams.filterString });
    } else {
      throw new Error('incompaatibe parameters');
    }

    return dataFetchObservable.pipe(
      concatMap<MeterPoint[], Observable<MeterPoint[]>>(meters => {
        return countObservable.pipe(
          map<number, MeterPoint[]>((length: number) => {
            this.dataLength = length;
            return meters;
          }));
      }));
  }

  onPaginatorEvents(paginator: MatPaginator): Observable<MeterPoint[]> {
    /* Subscribe for changes that re-render table view */
    return paginator.page.pipe(
      concatMap<PageEvent, Observable<MeterPoint[]>>((pageEvent: PageEvent) => {
        const limit = pageEvent.pageSize;
        const offset = pageEvent.pageIndex * pageEvent.pageSize;
        if (this.checkboxFilterGroup.get('filterbyMeterPoint').value !== '') {
          return this.loadData(limit, offset, { filterString: this.checkboxFilterGroup.get('filterbyMeterPoint').value });
        } else {
          return this.loadData(limit, offset);
        }
      }));
  }

  onExternalDataChangeEvent(changers: Observable<any>[]): Observable<MeterPoint[]> {
    return merge(...changers).pipe(
      concatMap<any, Observable<MeterPoint[]>>((v: any) => {
        const limit = this.paginator.pageSize;
        const offset = this.paginator.pageIndex * limit;
        // this.paginator.pageIndex = 0;
        return this.loadData(limit, offset);
      }));
  }

  onFilterEvents(): Observable<MeterPoint[]> {
    return this.checkboxFilterGroup.get('filterbyMeterPoint').valueChanges.pipe(
      debounceTime(500),
      map<any, string>((v: any) => '' + v),
      filter((v: string) => (v.length > 0)),
      tap(v => {
        if (this.debugMode) { console.log('Filter change'); }
      }),
      switchMap<string, Observable<MeterPoint[]>>((filterString: string) => {
        this.paginator.pageIndex = 0;
        const limit = this.paginator.pageSize;
        return this.loadData(limit, 0, { filterString: filterString });
      }));
  }

  onClearFilters(): Observable<MeterPoint[]> {
    return this.checkboxFilterGroup.get('filterbyMeterPoint').valueChanges.pipe(
      filter(v => v.length === 0),
      concatMap(emptyFilter => {
        const limit = this.paginator.pageSize;
        const offset = this.paginator.pageIndex * limit;
        this.paginator.pageIndex = 0;
        return this.loadData(limit, offset);
      }));
  }

  isLoading(): boolean {
    return !this.dataFetched;
  }

  getDataSourceLength(): number {
    return this.dataLength;
  }

  get filterbyMeterPoint(): AbstractControl {
    return this.checkboxFilterGroup.get('filterbyMeterPoint');
  }

  addElement(): void {
    this.dialogsService
      .invokeEditDialog(null)
      .subscribe((res: MeterPoint) => {

        of(res).pipe(
          filter(v => typeof (v) !== 'undefined'),
          concatMap<MeterPoint, Observable<MeterPoint>>((createdMeterPoint: MeterPoint) => {
            return this.changeMeterPoint(createdMeterPoint);
          }),
          concatMap<MeterPoint, Observable<MeterPoint>>((mp: MeterPoint) => {
            return this.meterPointDataStoreService.addMeterPoint(mp);
          }))
          .subscribe((addadMp: MeterPoint) => {
          });
      });
  }

  private generateAddCustomerObservable(responseMeterpoint: MeterPoint): Observable<Customer | undefined> {
    let customerObservable: Observable<Customer>;
    if (typeof (responseMeterpoint.getCustomer()) !== 'undefined') {
      if (responseMeterpoint.idCustomer !== -1) { /* Existing customer */
        customerObservable = of(responseMeterpoint.getCustomer());
      } else { /* New Customer */
        customerObservable = this.customerDataStoreService.addCustomer(responseMeterpoint.getCustomer());
      }
    } else { /* There were no customer added */
      customerObservable = of(undefined);
    }
    return customerObservable;
  }

  private generateAddTimeGroupObservable(responseMeterpoint: MeterPoint): Observable<TimeGroup | undefined> {
    let stgObservable: Observable<TimeGroup | undefined>;
    /* No sameTime Group filled in control */
    if (typeof (responseMeterpoint.timeGroupName) === 'undefined') {
      stgObservable = of(undefined);
    } else {
      stgObservable = this.timeGroupService.getSingleTimeGroupsByName(responseMeterpoint.timeGroupName, true).pipe(
        concatMap<TimeGroup, Observable<TimeGroup>>((stg: TimeGroup) => {
          if (this.timeGroupService.responseIsEmpty(stg)) {
            const newTg: TimeGroup = new TimeGroup({
              id: -1,
              timeGroupName: responseMeterpoint.timeGroupName,
              description: '',
            });
            return this.timeGroupService.addTimeGroup(newTg); /* New time group */
          } else {
            return of(stg); /* existing time group */
          }
        }));
    }
    return stgObservable;
  }

  editElement(element): void {
    this.dialogsService
      .invokeEditDialog(element, 'Edit')
      .subscribe((res: MeterPoint) => {

        of(res).pipe(
          filter(v => typeof (v) !== 'undefined'),
          concatMap<MeterPoint, Observable<MeterPoint>>((createdMeterPoint: MeterPoint) => {
            return this.changeMeterPoint(createdMeterPoint);
          }),
          concatMap<MeterPoint, Observable<MeterPoint>>((mp: MeterPoint) => {
            return this.meterPointDataStoreService.updateMeterPoint(mp);
          }))
          .subscribe((addadMp: MeterPoint) => {
          });

      });
  }

  changeMeterPoint(createdMeterPoint: MeterPoint) {
    return of(createdMeterPoint).pipe(
      concatMap((v: MeterPoint) => {
        return this.generateAddCustomerObservable(createdMeterPoint);
      }),
      concatMap((cus: Customer) => {
        if (cus) {
          createdMeterPoint.idCustomer = cus.idCustomer;
        }
        return this.generateAddTimeGroupObservable(createdMeterPoint);
      }),
      concatMap((stg: TimeGroup | undefined) => {
        if (stg) {
          createdMeterPoint.idTimeGroup = stg.id;
        }
        return of(createdMeterPoint);
      }));
  }
  onDeactivateMeter(element: MeterPoint): void {
    if (this.debugMode) { console.log(element); }
    this.meterPointDataStoreService.deactivateMeterPoint(element.idMeterPoint)
      .subscribe();
  }

  onActivateMeter(element) {
    if (this.debugMode) { console.log(element); }
    this.meterPointDataStoreService.activateMeterPoint(element.idMeterPoint)
      .subscribe();
  }

  onAssignCustomer(rowMeterPoint: MeterPoint) {
    /* INVOKE dialog */
    this.dialogsService.invokeCustomerAssignmentDialog(rowMeterPoint)
      .subscribe((asignee: Customer) => {
        if (asignee !== undefined) {
          const toUpdate = rowMeterPoint;
          toUpdate.idCustomer = asignee.idCustomer;
          this.meterPointDataStoreService.updateMeterPoint(toUpdate)
            .subscribe((v: MeterPoint) => {
            },
              (err) => {
                console.log(err);
              },
              () => { console.log('Complete'); });
        }
      });
  }

  onUnassignCustomer(rowMeterPoint: MeterPoint) {
    const updateEl = rowMeterPoint;
    updateEl.customer = undefined;
    updateEl.idCustomer = undefined;
    this.meterPointDataStoreService.updateMeterPoint(updateEl)
      .subscribe();
  }

  ngOnDestroy() {
    if (this.loadDataSubscription) {
      this.loadDataSubscription.unsubscribe();
    }
    if (this.paginatorEventsSubscription) {
      this.paginatorEventsSubscription.unsubscribe();
    }
    if (this.filterEventsSubscription) {
      this.filterEventsSubscription.unsubscribe();
    }
    if (this.externalDataChangeSubscription) {
      this.externalDataChangeSubscription.unsubscribe();
    }
    if (this.clearFiltersSubscription) {
      this.clearFiltersSubscription.unsubscribe();
    }
    if(this.canStartInteractiveTut$){
      this.canStartInteractiveTut$.unsubscribe();
    }
   }

  isRowMeterPointActive(rowMeterPoint: MeterPoint): boolean {
    return (rowMeterPoint.active && rowMeterPoint.active === true);
  }

  isRowWithCustomer(rowMeterPoint: MeterPoint): boolean {
    return (typeof (rowMeterPoint.customer) !== 'undefined');
  }
}
