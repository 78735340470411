// Angular imports
import { Component, OnInit, OnDestroy } from "@angular/core";

// Enviroments
import { environment } from "@env/environment";

// RxJS
import { Subject } from "rxjs/Subject";
import { Subscription } from "rxjs/Subscription";

import { CommonAppDataService } from "@shared/services/commonAppData.service";
import {
  getUtilityTypesNames,
  getUtilityTypeName,
} from "@shared/types/utilityTypeMap";
import { UtilityTypeEnum } from "@shared/types/utilityType";
import { KeycloakService } from "@shared/services/auth/keycloak.service";
import { Router } from "@angular/router";

import { RolesEnum, getRoleName } from "@shared/types/authTypes";
import {
  utilityTypeLocalStorageKey,
  IPrintableNameMapper,
  showInteractiveTutorial,
} from "@shared/types";
import { InteractiveTutorialService } from '@shared/services/interactiveTutorialService.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: "sv-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.sass"],
})
export class NavbarComponent implements OnInit, OnDestroy {
  readonly utilityTypesNames: IPrintableNameMapper[] = getUtilityTypesNames();
  readonly heatSupport = environment.heatSupport;
  readonly waterSupport = environment.waterSupport;
  readonly electricitySupport = environment.electricitySupport;
  readonly logo = environment.keyCloakConstructParams.realm.includes("smartX"); //FIXME
  readonly support: boolean[] = [
    this.heatSupport === "true",
    this.waterSupport === "true",
    this.electricitySupport === "true",
  ];
  private activity$: Subscription;
  private sidebar$: Subscription;

  private mediaType$: Subject<UtilityTypeEnum>;

  mediaIcon: string;
  mediaText: string;
  isExpanded: boolean = false;
  displayedActivity: string;
  mainActivity: string;
  showTutorial: boolean = false;

  constructor(
    private communicationService: CommonAppDataService,
    private keyCloakService: KeycloakService,
    private router: Router,
    private interactiveTutorialService: InteractiveTutorialService
  ) {
    this.displayedActivity = "Dashboard";
    this.mainActivity = "Dashboard";
  }

  ngOnInit() {
    this.activity$ = this.communicationService
      .getObservable("activity")
      .subscribe((activity) => {
        this.displayedActivity = activity;
      });

    this.sidebar$ = this.communicationService
      .getObservable("sidebar")
      .subscribe((expanded) => {
        this.isExpanded = expanded;
      });

    this.mediaType$ = this.communicationService.getSubject("utilityType");

    this.initMediaTypeButton(this.communicationService.getCurrentMediaType());

    const showTut = localStorage.getItem(showInteractiveTutorial);
    if (showTut !== null) {
      this.showTutorial = JSON.parse(showTut);
      this.interactiveTutorialService.showTutorialSubject.next(this.showTutorial);
    }
  }

  onMediaSelectClick(type: UtilityTypeEnum) {
    const utilityType = getUtilityTypeName(type);
    this.mediaIcon = utilityType.icon;
    this.mediaText = utilityType.name;
    localStorage.setItem(utilityTypeLocalStorageKey, UtilityTypeEnum[type]);
    this.mediaType$.next(type);
    this.router.navigate(["/home"]).then((v) => {
      window.location.reload();
    });
  }

  toggleSidebar(toggle?: boolean) {
    if (toggle === undefined) {
      this.isExpanded = !this.isExpanded;
    } else {
      this.isExpanded = toggle;
    }
    this.communicationService.sendMessageToSubject("sidebar", this.isExpanded);
  }

  setActivity(actName?: string) {
    actName = actName || this.mainActivity;
    this.communicationService.sendMessageToSubject("activity", actName);
    this.scrollToTop();
  }

  scrollToTop(): void {
    window.scrollTo(0, 0);
  }

  initMediaTypeButton(mediaType: UtilityTypeEnum) {
    const utilityType = getUtilityTypeName(mediaType);
    this.mediaIcon = utilityType.icon;
    this.mediaText = utilityType.name;
  }

  ngOnDestroy() {
    try {
      this.activity$.unsubscribe();
      this.sidebar$.unsubscribe();
    } catch (e) {
      console.error("Failed to unsubscribe from subject", e);
    }
  }

  showTut(event: any) {
    this.showTutorial = event.checked;
    localStorage.setItem(showInteractiveTutorial, this.showTutorial.toString());
    if (this.showTutorial) {
      this.interactiveTutorialService.cleanNotEndedTutorials();
    }
    this.interactiveTutorialService.showTutorialSubject.next(this.showTutorial);
  }

  onLoginClick() {
    this.setActivity("Login");
    this.toggleSidebar(false);
    this.keyCloakService.login().then(
      (auths) => {
        console.log(auths);
      },
      (reason) => {
        console.warn(reason);
      }
    );
  }

  isLoggedIn(): boolean {
    if (environment.authConfig.authRequired) {
      return KeycloakService.isAuthenticated();
    }

    return false;
  }

  logout(): void {
    if (environment.authConfig.authRequired) {
      KeycloakService.logout();
    }
  }

  getUserName(): string {
    if (this.isAuthenticated()) {
      return KeycloakService.getFullName();
    }

    return undefined;
  }

  getRoleName(): string {
    if (!this.isAuthenticated()) {
      return undefined;
    }

    try {
      return getRoleName(KeycloakService.getRole()).name;
    } catch {
      return getRoleName(RolesEnum.UNKNOWN).name;
    }
  }

  getRole = KeycloakService.getRole;

  private isAuthenticated(): boolean {
    return environment.authConfig.authRequired && KeycloakService.isAuthenticated();
  }

}
