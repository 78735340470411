import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";

import { CommonAppDataService } from "@shared/services/commonAppData.service";

import { environment } from "@env/environment";

import { ActivatedRoute, Params } from "@angular/router";
import { NavigationContext } from "@shared/models/navigationCtx.model";
import { Subscription } from "rxjs/Subscription";
import { UtilityTypeEnum } from "@shared/types";

export interface subLinks {
  url?: string;
  text: string;
  mdIcon: string;
  interactiveTutorialTooltip?: string;
}

export interface sideBarLink extends subLinks {
  url?: string;
  text: string;
  mdIcon: string;
  sublist: subLinks[];
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: "sv-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.sass"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Input() isExpanded: boolean = false;

  linksConfig: sideBarLink[];
  concatSubMenu: boolean;
  sidebarSubj$: Subscription;

  constructor(
    private communicationService: CommonAppDataService,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
  ) {
    this.concatSubMenu = false;
    let screen = [
      {
        url: "/upload/readings",
        text: $localize`:measurments label|Sidebar menu label@@sidebar/upload measurements:Measurements`,
        mdIcon: "list",
      },
      {
        url: "/upload/client-data",
        text: $localize`:assets label|Sidebar menu label@@sidebar/upload assets:Assets`,
        mdIcon: "card_travel",
      },
    ];
    if (
      this.communicationService.getCurrentMediaType() === UtilityTypeEnum.HEAT
    ) {
      screen.push({
        url: "/upload/temperatures",
        text: $localize`:temperatures label|Sidebar menu label@@sidebar/upload temperatures:Temperatures`,
        mdIcon: "cloud",
      });
    }
    this.linksConfig = [
      {
        url: "/home",
        text: $localize`:dashboard label|Sidebar menu label@@sidebar/dashboard:Dashboard`,
        mdIcon: "home",
        sublist: [],
      },
      {
        text: $localize`:upload label|Sidebar menu label@@sidebar/upload:Import data`,
        mdIcon: "file_upload",
        sublist:
          screen.length === 2
            ? [screen[0], screen[1]]
            : [screen[0], screen[1], screen[2]],
      },
      {
        url: "/schedule",
        text: $localize`:schedule label|Sidebar menu label@@sidebar/schedule:Settlement Schedule`,
        mdIcon: "insert_invitation",
        sublist: [],
      },
      {
        url: "/data-validation",
        text: $localize`:data validation label|Sidebar menu label@@sidebar/validation:Data Validation`,
        mdIcon: "border_color",
        sublist: [],
      },
      {
        url: "/data-analysis",
        text: $localize`:data analysis label|Sidebar menu label@@sidebar/analysis:Data Analysis`,
        mdIcon: "timeline",
        sublist: [],
      },
      {
        url: "/data-exploration",
        text: $localize`:data exploration label|Sidebar menu label@@sidebar/exploration:Data Exploration`,
        mdIcon: "equalizer",
        sublist: [],
      },
      {
        text: $localize`:configuration label|Sidebar menu label@@sidebar/configuration:Configuration`,
        mdIcon: "settings",
        sublist: [
          {
            url: "config/dataModel",
            text: $localize`:data model label|Sidebar menu label@@sidebar/configuration data model:Data Model `,
            mdIcon: "folder_open",
          },
          // environment.automaticScheduler === "true"
            // ? {
            //     url: "config/settings",
            //     text: $localize`:settings label|Sidebar menu label@@sidebar/configuration settings:Settings`,
            //     mdIcon: "build",
            //   } :
             {
                text: $localize`:settings label|Sidebar menu label@@sidebar/configuration settings:Settings`,
                mdIcon: "build",
              },
        ],
      },
      {
        url: "/export",
        text: $localize`:export label|Sidebar menu label@@sidebar/export:Data Export`,
        mdIcon: "file_download",
        sublist: [],
      },
    ];
  }

  ngOnInit() {
    this.sidebarSubj$ = this.communicationService
      .getSubject("sidebar")
      .subscribe((val) => {
        this.isExpanded = val;
        this.cdr.markForCheck();
      });
  }

  ngOnDestroy() {
    try {
      this.sidebarSubj$.unsubscribe();
    } catch (e) {}
  }

  hideSidebar() {
    this.communicationService.sendMessageToSubject("sidebar", false);
  }

  getNavigationContext(navItem: sideBarLink | subLinks): Params {
    const params = this.activatedRoute.snapshot.queryParams;
    const template: NavigationContext = new NavigationContext(params);
    let retParams = {};
    Object.keys(template).forEach((key: string) => {
      if (typeof params[key] !== "undefined") {
        retParams[key] = params[key];
      }
    });
    return retParams;
  }

  getLink(item: sideBarLink | subLinks): string[] {
    return [item.url];
  }
}
