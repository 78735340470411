<div class="card table-summary table-filter">
    <div class="card-header input-filter">
        <div [formGroup]="changeFilterForm">
            <mat-form-field id="svMeterFilter" class="form-group">
                <input matInput type="text" placeholder="{{parametersField.METER_ID}}" formControlName="meter">
            </mat-form-field>

            <mat-form-field id="svAggregationFilter" class="form-group">
                <mat-select placeholder="{{parametersField.AGGREGATION}}" formControlName="aggregation">
                    <mat-option value="1d">{{ parametersField.DAY }}</mat-option>
                    <mat-option value="30d">{{ parametersField.MONTH }}</mat-option>
                    <mat-option value="1h">{{ parametersField.HOUR }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field id="svLocationFilter" class="form-group">
                <input matInput type="text" placeholder="{{tableHeaders.LOCATION}}" formControlName="address">
            </mat-form-field>
        </div>

    </div>
    <mat-table id="mainTable" #table [dataSource]="dataSourceExploration">
        <ng-container *ngFor="let column of columns.getDynamicColumns(); index as i" [matColumnDef]="column.columnDef">
            <mat-header-cell *matHeaderCellDef>{{ column.header }}</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <div *ngIf="column.type === 'idMeter'">
                    {{row.serialNumber}}
                </div>
                <div *ngIf="column.type === 'location'">
                    {{row.meterPlacement}}
                </div>
                <div *ngIf="column.type === 'measurementType'">
                    {{row.getMesurementTypeaAsString()}}
                </div>
                <div *ngIf="column.type === 'customer'">
                    {{row.customer ? row.customer.denotation : ''}}
                </div>
                <div *ngIf="column.type === 'segment'">
                    {{row.customer ? element.customer.getCustomerSegmentAsString() : ''}}
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef class="text-right">{{actions}}</mat-header-cell>
            <mat-cell *matCellDef="let row" class="text-right">
                <i class="material-icons notranslate">{{ isThisDetailElement(row) ? hideDetailsMdIcon : seeDetailsMdIcon }}</i>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
            <mat-cell *matCellDef="let detailRow" class="detail-data">
                <div *ngIf="detailsVisible(detailRow)">
                    <sv-detail [aggregation]="aggregation" [detail]="detailRow" [scheduleId]="scheduleId"
                        [positionId]="actual_position" [diagramId]="diagramId"></sv-detail>
                </div>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columns.getAllColumnDef()"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columns.getAllColumnDef();" matRipple class="element-row"
            [class.expanded]="isThisRowExpanded(row)" (click)="handleMainRowClick(row)"></mat-row>

        <mat-row *matRowDef="let detailRow; columns: ['expandedDetail']; when: rowCanBeExpanded"
            [@detailExpand]="isThisRowExpanded(detailRow) ? 'expanded' : 'collapsed' "></mat-row>
    </mat-table>
    <div class="no-result" [style.display]="!isLoading() && getDataSourceLength() === 0 ? '' : 'none'">
        {{noData}}
    </div>

    <div class="no-result" [style.display]="isLoading() ? '' : 'none'">
        <mat-spinner [strokeWidth]="3" [diameter]="20" class="notranslate"></mat-spinner>
    </div>

    <mat-paginator #paginator [length]="getDataSourceLength()" [pageIndex]="0" [pageSize]="10"
        [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons class="notranslate">
    </mat-paginator>
</div>