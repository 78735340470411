import { HttpHeaders, HttpResponse } from '@angular/common/http';

import { IServiceConfiguration } from '@env/environmentIfc';

import { GenericHttpParams, IApiEndpointConfig, IPathConfig } from '@shared/types/http/httpType';
import { CommonAppDataService } from '@shared/services/commonAppData.service';
import { environment } from '@env/environment.ci';


export abstract class BaseHttpService<ModelT, HttpParamT extends GenericHttpParams<ModelT>> {

  protected readonly MOCK_SERVICE: boolean;
  protected readonly MOCK_TIME: number;
  protected readonly VERBOSE: boolean;

  protected readonly apiCfg: IApiEndpointConfig;
  protected readonly basePath: string;

  constructor(servConf: IServiceConfiguration, cs: CommonAppDataService) {
    this.MOCK_SERVICE = servConf.mockEnabled;
    this.MOCK_TIME = servConf.time;
    this.VERBOSE = servConf.verbose;
    this.basePath = cs.getApiBasePath();
    this.apiCfg = cs.getApiEndpointConfig(servConf.endpointE);
  }

  protected sanitizeParams(p: { [name: string]: string | string[] }): { [name: string]: string | string[] } {
    try {
      return JSON.parse(JSON.stringify(p));
    } catch (e) {
      throw new Error(`Unable to sanitize params object ${p}`);
    }
  }

  protected getQueryParams(p: HttpParamT): { [name: string]: string | string[] } {
    return this.sanitizeParams(p.queryParams);
  }

  protected getHeaders(p: HttpParamT): HttpHeaders {
    // return this.sanitizeParams(p.headers);
    return new HttpHeaders(p.headers); // .append('Content-Type', 'application/x-www-form-urlencoded');
  }

  protected replacePathParams(
    serviceID: string,
    apiCfg: IApiEndpointConfig,
    paramValues: { [name: string]: string }
  ): string {
    const idx = apiCfg.paths.findIndex(pth => serviceID === pth.swaggerId);
    if (idx === -1) {
      throw new Error(
        `Wrong SwaggerID - configuration for ${serviceID} is not existing`
      );
    }
    const thisPathCfg: IPathConfig = apiCfg.paths[idx];
    if (thisPathCfg.pathParams && thisPathCfg.pathParams.length > 0) {
      const expectedParams = thisPathCfg.pathParams.map(par => '\n' + par.name);
      if (!paramValues) {
        throw new Error(`Missing replace path parameters array for requested API ${serviceID}\n Expected: ${thisPathCfg.pathParams.map(v => v.name)}`);
      }
      if (
        (Object.keys(paramValues).length === 0 &&
          paramValues.constructor === Object) ||
        typeof (paramValues) === 'undefined'
      ) {
        throw new Error(
          `Empty replace array object passed, expected params:${expectedParams}`
        );
      }
      if (Object.keys(paramValues).length < thisPathCfg.pathParams.length) {
        throw new Error(
          `not enought replace params.\nExpected ${
          thisPathCfg.pathParams.length
          }: ${expectedParams}\nGot ${
          Object.keys(paramValues).length
          }:\n${Object.keys(paramValues)}`
        );
      }
      let tempPath = thisPathCfg.url; /* Path with parameters to replace */
      thisPathCfg.pathParams.forEach(param => {
        if (typeof paramValues[param.name] === 'undefined') {
          throw new Error(
            `Replace parameter names don't math, parameter '${
            param.name
            }' not found`
          );
        }
        tempPath = tempPath.replace(param.pattern, '' + paramValues[param.name]
        );
      });
      return apiCfg.rootApiPath + tempPath.replace(/\/$/, '');
    } else {
      /* Path is not parametrized */
      let root, endpoint;
      if (!(apiCfg.rootApiPath.endsWith('/')) && thisPathCfg.url !== '') {
        root = apiCfg.rootApiPath + '/';
      } else {
        root = apiCfg.rootApiPath;
      }
      endpoint = thisPathCfg.url.replace(/[/]*$/, '');
      return (root + endpoint).replace(/\/$/, '');
    }
  }

  protected isApiArrayed(serviceID: string, apiCfg: IApiEndpointConfig): boolean {
    const idx = apiCfg.paths.findIndex(pth => serviceID === pth.swaggerId);
    if (idx !== -1) {
      if (typeof (apiCfg.paths[idx].responseIsArray) !== 'undefined') {
        if (apiCfg.paths[idx].responseIsArray) {
          return true;
        } else {
          return false;
        }
      } else {
        throw new Error(
          `Missing array configuration - array configuration for ${serviceID} not existing`
        );
      }
    } else {
      /* Each path shall have it's configuration */
      throw new Error(
        `Wrong SwaggerID - configuration for ${serviceID} is not existing`
      );
    }
  }

  protected getParametrizedUrl(swaggerId: string, params: { [name: string]: string }): string {
    return this.basePath + this.replacePathParams(swaggerId, this.apiCfg, params);
  }

  protected requestLogger(apiId: string, url: string, params: HttpParamT, ) {
    if (this.VERBOSE) {
      console.log(`Request to ${url} from API ${apiId} \n`, 'With params: ', params);
    }
  }

  protected responseLogger(resp: HttpResponse<any>) {
    if (this.VERBOSE) {
      console.log(`Response from:${resp.url}\nStatus: ${resp.status} - ${resp.statusText}\nHeaders: `, resp.headers, '\nBody:  ', resp.body);
    }
  }

  protected logger() {
    if (this.VERBOSE) {
      console.log('BaseHttpService logger');
    }
  }
}
