import { IApiEndpointConfig, GenericHttpParams } from '@shared/types/http/httpType';
import { AnalysisStatus } from '@shared/models/appModels/analysisStatus.model';

export const analysisAlgorithmApiConf: IApiEndpointConfig = {
  rootApiPath: 'process/',
  paths: [
    {
      swaggerId: 'getAnalysisAlgorithmsList',
      url: 'algorithm',
      pathParams: [],
      responseIsArray: true,
    },
    {
      swaggerId: 'postAlgorithmsEnabled',
      url: 'algorithm/id/{idAnalysisAlgorithm}/enabled',
      pathParams: [{
        name: 'idAnalysisAlgorithm', pattern: '{idAnalysisAlgorithm}'
      }],
      responseIsArray: false,
    },
    {
      swaggerId: 'getAnalysisAlgorithmById',
      url: 'algorithm/id/{idAnalysisAlgorithm}',
      pathParams: [{
        name: 'idAnalysisAlgorithm', pattern: '{idAnalysisAlgorithm}'
      }],
      responseIsArray: false,
    },
    {
      swaggerId: 'postAlgorithmsAnalysis',
      url: 'algorithm/id/{idAnalysisAlgorithm}/analysis',
      pathParams: [{
        name: 'idAnalysisAlgorithm', pattern: '{idAnalysisAlgorithm}'
      }],
      responseIsArray: false,
    },
  ],
};

export interface AnalysisAlgorithmHttpParamG extends GenericHttpParams<AnalysisStatus> {
  path?: {
    idAnalysisAlgorithm?: string
  };
  queryParams?: {
    svAnalysisAlgorithmType?: string;
    svUtilityType?: string;
  };
}

