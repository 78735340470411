<div ngFileDrop class="drop-container" [options]="fileDropOptions" (uploadOutput)="onUploadOutput($event)"
	[uploadInput]="uploadInput" [ngClass]="{ 'is-drop-over': dragOver }" #measur>

	<div class="import-overlay">
	</div>

	<h1>{{header}}</h1>

	<div class="upload-info-container">
		<div id="svUpload" class="text-import column text-highlight">
			{{uploadPrompt.part1}}
			<label class="btn-upload">
				{{uploadPrompt.part2}}
				<input #reloadFile ngFileSelect type="file" [options]="fileSelectOptions"
					(uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput">
			</label>
			{{uploadPrompt.part3}}
		</div>
		<div class="column">
			<div class="card">
				<div class="card-body overflow-hide">
					<div>
						<div class="container-card-info">
							<div id="svSelect">
							<sv-select createNewAllowed='true' (selectionEvent)="handleSelection($event)"></sv-select>
							</div>
							<div class="btn-right">
								<input type="file" (change)="onFileSelected($event)" ngFileSelect accept=".csv"
									style="display: none;" #file>
								<button id="configBtn" mat-raised-button color="accent" (click)="file.click()"
									class="btn btn-create-new"
									style="margin-bottom:14px">{{dataModelTxt.CONFIGURE}}</button>
							</div>
						</div>
					</div>
					<div *ngIf="displayableUpload.length > 0" class="card progress-card">
						<div *ngFor="let f of displayableUpload; let i = index;">
							<div class="elements-space-between">
								<span class="truncated-name notranslate" [matTooltip]="f.name"
									matTooltipClass="tooltip-not-truncated" matTooltipPosition="left">{{f.name}}</span>
								<span>{{ '  '+ humanizeBytes(f.size)}}</span>
							</div>
							<mat-progress-bar mode="determinate" [value]="f.progress?.data?.percentage">
							</mat-progress-bar>
							<div class="elements-space-between notranslate">
								<span>{{f.progress?.data?.percentage + '%' + ' done'}}</span>
								<span>{{ f.progress?.data?.speedHuman }}</span>
							</div>
						</div>
					</div>
					<div *ngIf="displayResults()" class="card alert alert-info" [ngClass]="getResultClass()">
						<span *ngIf="getUploadResults().success">{{success}}</span>
						<span *ngIf="getUploadResults().success"> <br/>{{numberOfImportedMeasurements}} <span class="notranslate">{{importedMeasurementsCount}}</span></span>
						<span *ngIf="getUploadResults().success"> <br/> 
							{{numberOfMeasurementsWithoutReadingValue}} <span class="notranslate">{{anomalyCount}}</span></span>
						<span *ngIf="getUploadResults().error">{{error}} {{getUploadResults()?.errorCause}}
							<label class="link btn-upload">
								<a (click)="reuploadFileSelect(currentSelection.positionId)">{{reload}}</a>
							</label>
						</span>
					</div>
					<div class="button-on-bottom-container">
						<div [matTooltip]="reuploadNotSupported" [matTooltipDisabled]="!reuploadDisabled"
							matTooltipPosition="below" matTooltipShowDelay="500" class="tooltip-div">
							<!-- div added because hints do not work on the disabled button -->
							<button id="svUploadBtn" type="button" (click)="startUpload()"
								[disabled]="getUploadResults().error || veeRunPosition || !ifValidSelect() || displayableUpload.length === 0"
								mat-raised-button color="accent" class="btn">
								{{ buttonText }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- <div id="mainTable"> -->
	<sv-measurements-files-preview id="mainTable" (changePreviewFile)="changePreviewFileId($event)"
		(changePreview)="changePreviewId($event)" (reloadFile)="reuploadFileSelect($event)"
		(validationNavigate)="handleNavigation($event)"></sv-measurements-files-preview>
	<!-- </div> -->

	<sv-measurements-preview *ngIf="previewIsVisible() && !showDetails" [idPosition]="dataPreviewPositionId"
		[idFile]="dataPreviewFileId"></sv-measurements-preview>

	<sv-measurements-file-preview *ngIf="previewFileIsVisible() && showDetails" [idPosition]="dataPreviewFilePositionId"
		[fileList]="fileList"></sv-measurements-file-preview>

</div>