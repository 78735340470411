<div class="app" (click)="mainAppClick($event)">

  <sv-navbar id="sv-app-navbar"></sv-navbar>

  <sv-sidebar id="sv-app-sidebar"></sv-sidebar>
  <div class="app-activity">
    <!-- TODO: fix -->
    <!-- <h1>{{currentActivity}}</h1> -->
  </div>

	<router-outlet id="sv-app-router-outlet"></router-outlet>
</div>
