import { Injectable } from "@angular/core";
import { InteractiveTutorialService, TutorialButtonLabel } from "@shared/services/interactiveTutorialService.service";

@Injectable({ providedIn: "root" })
export class DataExplorationInteractiveTutorialService {
  constructor(private interactiveTutorialService: InteractiveTutorialService) {}

  getDataExplorationMainPageInteractiveTutorialSteps() {
    const step = this.interactiveTutorialService.generateStep(
      "#svSelect",
      "bottom",
      $localize`:@@interactive tutorial/data analysis/schedule and position selector:With these two fields you can specify the current schedule and position.`,
      $localize`:@@interactive tutorial/data analysis/schedule and position selector title:Schedule and position selector`,
      [TutorialButtonLabel.NEXT]
    );
    const step1 = this.interactiveTutorialService.generateStep(
      "#svDashboardSelect",
      "top",
      $localize`:@@interactive tutorial/data exploration/main page/dashboard select:Here you can select the type of dashboard that will be generated as a result of data exploration.`,
      $localize`:@@interactive tutorial/data exploration/main page/dashboard select title:Data exploration dashboard selector`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
    );
    const step2 = this.interactiveTutorialService.generateStep(
      "#generateBtn",
      "right",
      $localize`:@@interactive tutorial/data exploration/main page/generate btn:Click this button to generate the selected dashboards.`,
      $localize`:@@interactive tutorial/data exploration/main page/generate btn title:Generating data exploration dasboards`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.COMPLETE]
    );
    const steps: unknown[] = [step, step1, step2];
    return steps;
  }

  getDataExplorationSummaryInteractiveTutorialSteps() {
    const step = this.interactiveTutorialService.generateStep(
      "#svMeterFilter",
      "top",
      $localize`:@@interactive tutorial/asset upload/data preview/meter filter:This field allows you to filter the data in the table below by meter ID.`,
      $localize`:@@interactive tutorial/asset upload/data preview/meter filter title:Filter by meter ID`,
      [TutorialButtonLabel.NEXT]
    );

    const step1 = this.interactiveTutorialService.generateStep(
      "#svAggregationFilter",
      "top",
      $localize`:@@interactive tutorial/data analysis/aggregation filter:This field allows you to specify the type of data aggregation.`,
      $localize`:@@interactive tutorial/data analysis/aggregation filter title:Aggregation Filter`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
    );

    const step2 = this.interactiveTutorialService.generateStep(
      "#svLocationFilter",
      "top",
      $localize`:@@interactive tutorial/data analysis/summary/location:This field allows you to filter the data in the table below by the location of the counters.`,
      $localize`:@@interactive tutorial/data analysis/summary/location title:Location`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
    );

    const step3 = this.interactiveTutorialService.generateStep(
      "#mainTable",
      "top",
      $localize`:@@interactive tutorial/data exploration/summary/main table:This table displays a set of meter points on which data has been explored. After clicking on a specific meter point, you will be presented with a data exploration dashboard.`,
      $localize`:@@interactive tutorial/data exploration/summary/main table title:Data exploration results`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.COMPLETE]
    );

    const steps: unknown[] = [step, step1, step2, step3];
    return steps;
  }

  getDataExploartionDetailsInteractiveTutorialSteps() {
    const step = this.interactiveTutorialService.generateStep(
      "#explorationGraph",
      "top",
      $localize`:@@interactive tutorial/data exploration/detail/exploration graph:This dashboard presents the results of the data exploration performed.`,
      $localize`:@@interactive tutorial/data exploration/detail/exploration graph title:Data exploration dashboard`,
      [TutorialButtonLabel.COMPLETE],
      undefined,
      true
    );

    const steps: unknown[] = [step];
    return steps;
  }
}
