import { Component, OnInit, ViewChild, Inject, AfterViewInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Observable } from 'rxjs/Observable';

import { CustomerDataStoreService } from '@shared/services/dataStore/customerDataStore.service';
import { TableHost } from '@shared/types';
import { MeterPoint } from '@shared/models';
import { Customer } from '@shared/models/appModels';
import { CustomerDataSource } from '../../dataSource/customerDataSource';
import { environment } from '@env/environment';
import { UploadLocalisation as loc } from '../../../../upload.localisation';
import { typesLocalisation } from '@shared/types/localisation';
import { AssetUploadInteractiveTutorialService } from '../../../assetUploadInteractiveTutorialService.service';
import { InteractiveTutorialService } from '@shared/services/interactiveTutorialService.service';
@Component({
  selector: 'sv-assign-customer',
  templateUrl: './assign-customer.component.html',
  styleUrls: ['./assign-customer.component.sass']
})
export class AssignCustomerComponent implements OnInit, AfterViewInit, OnDestroy, TableHost<Customer> {

  /* View references */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  public displayedColumns = ['id', 'denotation', 'segment'];
  public dataSource: CustomerDataSource;
  public serialNumber;
  private debugMode = environment.debug;
  readonly itemsPage = typesLocalisation.ItemsPage[environment.language].texts;

  public dataLength = 0;
  dataFetched: boolean = false;
  dataLoading: boolean;
  showTutFunc;
  readonly assetLabels = loc[environment.language].texts.asset.assetDataPreview;
  constructor(@Inject(MAT_DIALOG_DATA) private data: { meterPoint: MeterPoint },
    public dialogRef: MatDialogRef<AssignCustomerComponent>,
    private customerService: CustomerDataStoreService,
    private interactiveTutorialService: InteractiveTutorialService,
    private assetDataInteractiveTutorialService: AssetUploadInteractiveTutorialService) {
    this.dataSource = new CustomerDataSource();
  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    this.paginator._intl.itemsPerPageLabel = this.itemsPage.itemsPage;
    this.paginator._intl.nextPageLabel = this.itemsPage.nextPage;
    this.paginator._intl.previousPageLabel = this.itemsPage.previousPage;
    this.paginator._intl.lastPageLabel = this.itemsPage.lastPage;
    this.paginator._intl.firstPageLabel = this.itemsPage.firstPage;
    const limit = this.paginator.pageSize;
    const offset = this.paginator.pageIndex * this.paginator.pageSize;

    this.showTutFunc = setTimeout(() => this.startInteractiveTutorial(), 1500);

    this.loadData(limit, offset)
      .subscribe((pagedData: Customer[]) => {
        this.dataSource.setNewData(pagedData);
      });

    this.onPaginatorEvents(this.paginator)
      .subscribe((pagedData: Customer[]) => {
        if (this.debugMode) { console.warn(`New Data triggered by paginator event`); }
        this.dataSource.setNewData(pagedData);
      },
        (err: any) => { if (this.debugMode) { console.log(err); } },
        () => { if (this.debugMode) { console.log(`Finished`); } },
      );
  }

  loadData(limit: number = 50, offset: number = 0, otherParams?: { [name: string]: any; }): Observable<Customer[]> {
    if (typeof (otherParams) === 'undefined') {
      return this.customerService.getAllCustomers(limit, offset);
    } else {
      throw new Error('No support for filtering');
    }
  }

  startInteractiveTutorial(){
    const steps = this.assetDataInteractiveTutorialService.getAssetUploadAssignCustomerInteractiveTutorialSteps();
    this.interactiveTutorialService.startInteractiveTutorial(steps);
  }

  ngOnDestroy(){
    clearTimeout(this.showTutFunc);
  }

  onPaginatorEvents(paginator: MatPaginator): Observable<Customer[]> {
    return paginator.page
      .concatMap<PageEvent, Customer[]>((pageEvent: PageEvent) => {
        const limit = pageEvent.pageSize;
        const offset = pageEvent.pageIndex * pageEvent.pageSize;
        return this.loadData(limit, offset);
      });
  }
  /* External data change is not supported */
  onExternalDataChangeEvent(changeInductors: Observable<any>[]): Observable<Customer[]> {
    throw new Error('Method not implemented.');
  }
  /* No filters section */
  onFilterEvents(): Observable<Customer[]> {
    throw new Error('Method not implemented.');
  }
  /* No filters section */
  onClearFilters(): Observable<Customer[]> {
    throw new Error('Method not implemented.');
  }

  onAssignCustomer(customer: Customer) {
    this.dialogRef.close(customer);
  }

}
