import { Component, ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';

import { FileOperationalDataStoreService } from '@shared/services/dataStore/fileOperationalDataStore.service';

import { CommonAppDataService } from '@shared/services/commonAppData.service';
import { CommonUploadComponent } from '../CommonUpload.class';
import { FileOperationalData } from '@shared/models';
import { environment } from '@env/environment';
import { UploadLocalisation as loc } from '../upload.localisation';
import { UploadInput } from '@ngx-uploader';
import { Subscription } from 'rxjs';
import { AssetUploadInteractiveTutorialService } from './assetUploadInteractiveTutorialService.service';
import { InteractiveTutorialService } from '@shared/services/interactiveTutorialService.service';
import { ActivatedRoute } from '@angular/router';
import { debounceTime } from 'rxjs/operators';

@Component({

  // tslint:disable-next-line:component-selector
  selector: 'sv-asset-data',
  templateUrl: 'asset-data.component.html',
  styleUrls: ['../upload.sass'],
  // providers: [FileOperationalDataStoreService]
})
export class AssetDataUploadComponent extends CommonUploadComponent<FileOperationalData> implements OnInit,OnDestroy {

  readonly header = loc[environment.language].texts.asset.componentTitle;

  countFileSubs: Subscription;
  showTutorialSubscription: Subscription;

  constructor(private fileOperationalDataService: FileOperationalDataStoreService, cs: CommonAppDataService, cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private assetUploadInteractiveTutServ: AssetUploadInteractiveTutorialService,
    private interactiveTutorialService: InteractiveTutorialService) {
    super(cs, false, cdr);
    this.uploadExecutor = (body) => this.fileOperationalDataService.addFileOperationalData(body, { emitEvent: true });
    this.countHandler = () => this.fileOperationalDataService.getFileOperationalDataCount();

    this.countFileSubs = this.countFileSubject.subscribe((data) => {
      // this is situation when error occurred during send process and user tries to drag another file omitting clicking reload button
      if (data === 1 && this.uploadResults.error) {
        this.reuploadFileSelect();
      }
    });
  }

  ngOnInit(){
    this.showTutorialSubscription = this.interactiveTutorialService.showTutorialSubject.pipe(debounceTime(1500)).subscribe(isActive => {
      if (this.interactiveTutorialService.getActiveComponent(this.route.routeConfig.path) && isActive) {
      this.startInteractiveTutorial();
      }
    });
  }

  btnDisabled() {
    return this.displayableUpload.length === 0;
  }

  startInteractiveTutorial(){
    const steps = this.assetUploadInteractiveTutServ.getAssetUploadMainComponentInteractiveTutorialSteps();
    this.interactiveTutorialService.startInteractiveTutorial(steps);
  }

  startUpload(): void {
    const event: UploadInput = {
      type: 'uploadAll',
      uploadExecutor: (file) => this.fileOperationalDataService.addFileOperationalData(
        file,
        { emitEvent: true }),
    };
    this.uploadResults.pending = true;
    this.uploadInput.emit(event);
  }

  clearData() {
    this.uploadResults.error = false;
    this.uploadResults.errorCause = '';
    this.uploadResults.pending = false;
    this.uploadResults.success = false;
    this.cdr.detectChanges();
  }

  reuploadFileSelect() {
    try {
        this.clearData();
    } catch (e) {
        console.error(e);
     }
  }

  ngOnDestroy() {
    this.countFileSubs.unsubscribe();
    if(this.showTutorialSubscription){
      this.showTutorialSubscription.unsubscribe();
    }
  }
}
