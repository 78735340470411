import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { environment } from '@env/environment';

import { RestTypeMapper } from '@shared/models/RestSupport';
import { AnalysisAlgorithm, IAnalysisAlgorithm } from '@shared/models/appModels/analysisAlgorithm.model';

import { EndpointsEnum } from '@shared/types/http/endpointEnum';
import { IntegerResult, AnalysisResult } from '@shared/types/http/httpType';
import { AnalysisAlgorithmHttpParamG } from '@shared/types/http/analysisAlgorithmHttpConfig';
import { AnalysisAlgorithmTypeEnum } from '@shared/types/modelTypes/analysisAlgorithmTypes';
/* MOCKS */
import { analysisAlgorithmMock } from '@shared/mock/analysisAlgorithm.mock';
import { CommonAppDataService } from '@shared/services/commonAppData.service';
import { RequestService } from './requestService.class';

@Injectable()
export class AnalysisAlgorithmService extends RequestService {

  analysisAlgorithm_mock: IAnalysisAlgorithm[];

  private analysisAlgorithmMapper: RestTypeMapper<IAnalysisAlgorithm, AnalysisAlgorithm>;

  /* Debug data */
  debugMode: boolean = environment.debug;

  constructor(
    http: HttpClient,
    cs: CommonAppDataService) {
    super(http, cs, EndpointsEnum.ANALYSIS_ALGORITHM);
    if (this.MOCK_SERVICE) {
      this.analysisAlgorithm_mock = analysisAlgorithmMock;
    } else {
      this.analysisAlgorithm_mock = undefined;
    }
    /* Initialize type MAPPERS */
    this.analysisAlgorithmMapper = new RestTypeMapper<IAnalysisAlgorithm, AnalysisAlgorithm>();
  }

  getAnalysisAlgorithmsList(params: AnalysisAlgorithmHttpParamG): Observable<AnalysisAlgorithm[]> {
    const thisApiID = 'getAnalysisAlgorithmsList';
    if (this.MOCK_SERVICE) {
      const rulesSet: IAnalysisAlgorithm[] = this.getRulesSet(params.queryParams.svAnalysisAlgorithmType);
      return Observable.of(rulesSet.map(r => new AnalysisAlgorithm(r)));
    } else {
      return this.getRequest(params, this.analysisAlgorithmMapper, thisApiID, AnalysisAlgorithm);
    }
  }

  getAnalysisAlgorithmById(params: AnalysisAlgorithmHttpParamG): Observable<AnalysisAlgorithm> {
    const thisApiID = 'getAnalysisAlgorithmById';
    if (this.MOCK_SERVICE) {
      const rulesSet: IAnalysisAlgorithm = this.getRulesSet(params.queryParams.svAnalysisAlgorithmType)[0];
      return Observable.of(new AnalysisAlgorithm(rulesSet));
    } else {
      return this.getRequest(params, this.analysisAlgorithmMapper, thisApiID, AnalysisAlgorithm);
    }
  }

  postAlgorithmsEnabled(params: AnalysisAlgorithmHttpParamG): Observable<AnalysisAlgorithm> {
    const thisApiID = 'postAlgorithmsEnabled';
    return this.postAnalysis(params, thisApiID);
  }

  postAlgorithmsAnalysis(params: AnalysisAlgorithmHttpParamG): Observable<AnalysisAlgorithm> {
    const thisApiID = 'postAlgorithmsAnalysis';
    return this.postAnalysis(params, thisApiID);
  }

  postAnalysis(params: AnalysisAlgorithmHttpParamG, thisApiID: string): Observable<AnalysisAlgorithm> {
    if (this.MOCK_SERVICE) {
      const rulesSet: IAnalysisAlgorithm[] = this.getRulesSet(params.queryParams.svAnalysisAlgorithmType);
      const idx: number = rulesSet.findIndex(rule => rule.idAnalysisAlgorithm === +params.path.idAnalysisAlgorithm);
      if (idx !== -1) {
        rulesSet[idx].analysis = (params.body as AnalysisResult).value;
        return Observable.of(new AnalysisAlgorithm(rulesSet[idx]));
      } else {
        throw new Error('Unexisting AnalysisAlgorithm ID requested');
      }
    } else {
      const body = params.body as IntegerResult;
      return this.postRequest(body, params, this.analysisAlgorithmMapper, thisApiID, AnalysisAlgorithm);
    }
  }

  private getRulesSet(ruleType: string): IAnalysisAlgorithm[] {
    let rulesSet: IAnalysisAlgorithm[];
    if (ruleType === AnalysisAlgorithmTypeEnum[AnalysisAlgorithmTypeEnum.DETECTION_OF_ANOMALIES]) {
      rulesSet = this.analysisAlgorithm_mock;
    } else {
      throw new Error('Incorrect parameter passed as Algorithm Type');
    }
    return rulesSet;
  }

}
