import { BaseOptionalParamsMapper } from '@shared/models/baseOptionalParams.mode';
import { IGetInterfaceType } from '@shared/types';

export class IAnalysis {
  idMeterPoint: number;
  analysedPositionId: number;
  timestamp: number;
  energy: number;
  intervalMin: number;
  intervalMax: number;
  measurementDiff: number;
  measurementValue: number;
  deviation: number;
  serialNumber: string;
  algorithm: string;
  avgOutTemp: number;
  regulator: number;
  numMeters: number;
}

export class Analysis extends BaseOptionalParamsMapper implements IAnalysis, IGetInterfaceType<IAnalysis, Analysis>  {
  idMeterPoint: number;
  analysedPositionId: number;
  timestamp: number;
  energy: number;
  intervalMin: number;
  intervalMax: number;
  measurementDiff: number;
  measurementValue: number;
  deviation: number;
  serialNumber: string;
  algorithm: string;
  avgOutTemp: number;
  regulator: number;
  numMeters: number;

  constructor(iAnalysis: IAnalysis) {
    super();
    if (!iAnalysis) { throw new TypeError('Analysis initialization: params is undefined !'); }
    this.idMeterPoint = (typeof (iAnalysis.idMeterPoint) !== 'undefined') ? iAnalysis.idMeterPoint : undefined;
    this.analysedPositionId = (typeof (iAnalysis.analysedPositionId) !== 'undefined') ? iAnalysis.analysedPositionId : undefined;
    this.timestamp = (typeof (iAnalysis.timestamp) !== 'undefined') ? iAnalysis.timestamp : undefined;
    this.energy = (typeof (iAnalysis.energy) !== 'undefined') ? iAnalysis.energy : undefined;
    this.intervalMin = (typeof (iAnalysis.intervalMin) !== 'undefined') ? iAnalysis.intervalMin : undefined;
    this.intervalMax = (typeof (iAnalysis.intervalMax) !== 'undefined') ? iAnalysis.intervalMax : undefined;
    this.measurementDiff = (typeof (iAnalysis.measurementDiff) !== 'undefined') ? iAnalysis.measurementDiff : undefined;
    this.measurementValue = (typeof (iAnalysis.measurementValue) !== 'undefined') ? iAnalysis.measurementValue : undefined;
    this.deviation = (typeof (iAnalysis.deviation) !== 'undefined') ? iAnalysis.deviation : undefined;
    this.serialNumber = (typeof (iAnalysis.serialNumber) !== 'undefined') ? iAnalysis.serialNumber : undefined;
    this.algorithm = (typeof (iAnalysis.algorithm) !== 'undefined') ? iAnalysis.algorithm : undefined;
    this.avgOutTemp = (typeof (iAnalysis.avgOutTemp) !== 'undefined') ? iAnalysis.avgOutTemp : undefined;
    this.regulator = (typeof (iAnalysis.regulator) !== 'undefined') ? iAnalysis.regulator : undefined;
    this.numMeters = (typeof (iAnalysis.numMeters) !== 'undefined') ? iAnalysis.numMeters : undefined;
  }

  getInterface(webInterface: boolean = true): IAnalysis {
    return {
      idMeterPoint: this.idMeterPoint,
      analysedPositionId: this.analysedPositionId,
      timestamp: this.timestamp,
      energy: this.energy,
      intervalMin: this.intervalMin,
      intervalMax: this.intervalMax,
      measurementDiff: this.measurementDiff,
      measurementValue: this.measurementValue,
      deviation: this.deviation,
      serialNumber: this.serialNumber,
      algorithm: this.algorithm,
      avgOutTemp: this.avgOutTemp,
      regulator: this.regulator,
      numMeters: this.numMeters
    };
  }
}
