
export const DataExploarationLocalisation = [
    {
        lang: 'ENG',
        texts: {
            page: {
                dataExploration: 'Data Exploration',
                basicDashboards: 'Basic dashboards',
                exploration: 'Exploration',
                all: 'All data',
                generateResults: 'Generate'
            },
            asset: {
                assetDataPreview: {
                    header: 'Asset list',
                    tableHeaders: {
                        METER_ID: 'Meter ID',
                        LOCATION: 'Location',
                        MES_TYPE: 'Measurement type',
                        CUSTOMER: 'Customer',
                        SEGMENT: 'Segment',
                        ACTIONS: 'Actions',
                    },
                    parametersField: {
                        METER_ID: 'Meter ID',
                        AGGREGATION: 'Aggregation',
                        DAY: 'day',
                        MONTH: 'month',
                        HOUR: 'hour',
                    },
                    noData: 'No data found.'
                }
            },
        },
    },
    {
        lang: 'GER',
        texts: {
            page: {
                dataExploration: 'Datenexploration',
                basicDashboards: 'Einfache Dashboards',
                exploration: 'Erkundung',
                all: 'Alle Daten',
                generateResults: 'Generieren'
            },
            asset: {
                assetDataPreview: {
                    header: 'Assetliste',
                    tableHeaders: {
                        METER_ID: 'Zähler ID',
                        LOCATION: 'Adresse',
                        MES_TYPE: 'Messart',
                        CUSTOMER: 'Kunde',
                        SEGMENT: 'Segment',
                        ACTIONS: 'Aktionen',
                    },
                    parametersField: {
                        METER_ID: 'Zähler ID',
                        AGGREGATION: 'Anhäufung',
                        DAY: 'Tag',
                        MONTH: 'Monat',
                        HOUR: 'Stunde',
                    },
                    noData: 'Keine Daten gefunden.'
                }
            },
        },
    },
    {
        lang: 'PL',
        texts: {
            page: {
                dataExploration: 'Eksploracja danych',
                basicDashboards: 'Podstawowe panele',
                exploration: 'Eksploracja',
                all: 'Wszystkie dane',
                generateResults: 'Generuj'
            },
            asset: {
                assetDataPreview: {
                    header: 'Czytaj',
                    tableHeaders: {
                        METER_ID: 'ID Licznika',
                        LOCATION: 'Lokalizacja',
                        MES_TYPE: 'Rodzaj pomiaru',
                        CUSTOMER: 'Klient',
                        SEGMENT: 'Segment',
                        ACTIONS: 'Akcje',
                    },
                    parametersField: {
                        METER_ID: 'ID Licznika',
                        AGGREGATION: 'Agregacja',
                        DAY: 'dzień',
                        MONTH: 'miesiąc',
                        HOUR: 'godzina',
                    },
                    noData: 'Brak danych.'
                }
            },
        },
    },
];
