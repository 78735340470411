<div class="chart">
  <canvas baseChart
    [datasets]="dataToGraph"
    [labels]="xAxisLabels"
    [chartType]="'line'"
    [options]="options"
    [legend]="legend"
    [colors]="lineChartColors"
    (chartClick)="chartClicked($event)"
    (chartHover)="chartHovered($event)"
    >
  </canvas>
</div>
