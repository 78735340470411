import { Component, OnInit, ViewChild, ElementRef, ChangeDetectionStrategy, Input, AfterViewInit, ChangeDetectorRef, OnDestroy } from '@angular/core';

import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Observable } from 'rxjs/Observable';

import { FileOperationalDataSource, FileOperationalTableElement } from './dataSource/fileOperationalDataSource';
import { ScheduleDataStoreService } from '@shared/services/dataStore/scheduleDataStore.service';
import { PositionDataStoreService } from '@shared/services/dataStore/positionDataStore.service';
import { FileOperationalDataStoreService } from '@shared/services/dataStore/fileOperationalDataStore.service';
import { NestedTableHost, TableHost } from '@shared/types';
import { FileOperationalData } from '@shared/models/appModels/fileOperationalData';
import { environment } from '@env/environment';

import { UploadLocalisation as loc } from './../../upload.localisation';
import { typesLocalisation } from '@shared/types/localisation';
import { Subscription } from 'rxjs';
import { CommunicationBetweenComponentsService } from '../asset-data-preview/service/communicationBetweenComponents.service';
import { AssetUploadInteractiveTutorialService } from '../assetUploadInteractiveTutorialService.service';
import { InteractiveTutorialService, NextComponentName } from '@shared/services/interactiveTutorialService.service';
import { debounceTime } from 'rxjs/operators';


@Component({

  // tslint:disable-next-line:component-selector
  selector: 'sv-asset-data-files-preview',
  templateUrl: './asset-data-files-preview.component.html',
  styleUrls: ['./../../files-preview.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ClientDataFilesPreviewComponent implements
  OnInit, AfterViewInit, OnDestroy, NestedTableHost<FileOperationalTableElement>, TableHost<FileOperationalData> {

  @Input() idFile: number;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('filter') filter: ElementRef;

  readonly seeDetailsMdIcon: string = 'expand_more';
  readonly hideDetailsMdIcon: string = 'expand_less';
  readonly debugMode = environment.debug;

  readonly tableHeaders = loc[environment.language].texts.asset.assetFilesPreview.tableHeaders;
  readonly noData = loc[environment.language].texts.asset.assetFilesPreview.noData;
  readonly numerator = loc[environment.language].texts.asset.assetFilesPreview.numerator;
  readonly itemsPage = typesLocalisation.ItemsPage[environment.language].texts;
  displayedColumnsFiles = ['name', 'uploadDate', 'action'];
  dataSource: FileOperationalDataSource;
  expandedElement: FileOperationalTableElement = null;

  dataLength: number = 0;
  dataFetched: boolean;
  dataLoading: boolean;
  loadDataSubscription: Subscription;
  paginatorEventsSubscription: Subscription;
  externalDataChangeEventsSubscription: Subscription;
  canStartInteractiveTut$: Subscription;

  constructor(
    private fileOperationalDataStoreService: FileOperationalDataStoreService,
    private scheduleService: ScheduleDataStoreService,
    private positionService: PositionDataStoreService,
    private cdr: ChangeDetectorRef,
    private communicationBetweenCompServ: CommunicationBetweenComponentsService,
    private interactiveTutorialServ: InteractiveTutorialService,
    private assetUploadInterTutServ: AssetUploadInteractiveTutorialService
  ) { }

  ngOnInit() {
    this.dataSource = new FileOperationalDataSource();
    this.loadDataSubscription = this.loadData()
      .subscribe((meters: FileOperationalData[]) => {
        this.dataSource.setNewData(meters);
      },
      (err: any) => {
        if (this.debugMode) { console.log(err); }
      },
      () => {
        if (this.debugMode) { console.log(`Init data fetch Finished`); }
        this.dataFetched = true;
        this.cdr.detectChanges();
      },
    );
    this.canStartInteractiveTut$ = this.interactiveTutorialServ.canGoToNextStepSubject.pipe(debounceTime(100)).subscribe(val => {
      if(val === NextComponentName.ASSET_UPLOAD_FILES_PREVIEW){
        this.startInteractiveTutorial();
      }
    })
  }

  startInteractiveTutorial(){
    const steps = this.assetUploadInterTutServ.getAssetUploadFilesPreviewInteractiveTutorialSteps();
    this.interactiveTutorialServ.startInteractiveTutorial(steps);
  }

  ngOnDestroy(): void {
    if (this.loadDataSubscription) {
      this.loadDataSubscription.unsubscribe();
    }
    if (this.externalDataChangeEventsSubscription) {
      this.externalDataChangeEventsSubscription.unsubscribe();
    }
    if (this.paginatorEventsSubscription) {
      this.paginatorEventsSubscription.unsubscribe();
    }
    if (this.canStartInteractiveTut$) {
      this.canStartInteractiveTut$.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.paginator._intl.itemsPerPageLabel = this.itemsPage.itemsPage;
    this.paginator._intl.nextPageLabel = this.itemsPage.nextPage;
    this.paginator._intl.previousPageLabel = this.itemsPage.previousPage;
    this.paginator._intl.lastPageLabel = this.itemsPage.lastPage;
    this.paginator._intl.firstPageLabel = this.itemsPage.firstPage;
    this.paginatorEventsSubscription = this.onPaginatorEvents(this.paginator)
      .subscribe((pagedData: FileOperationalData[]) => {
        if (this.debugMode) { console.log(`New Data triggered by paginator event`); }
        this.dataSource.setNewData(pagedData);
      },
        (err: any) => { if (this.debugMode) { console.log(err); } },
        () => { if (this.debugMode) { console.log(`Finished`); } },
      );

    this.externalDataChangeEventsSubscription = this.onExternalDataChangeEvent([
      this.fileOperationalDataStoreService.getChangeObservable(),
      this.scheduleService.getChangeObservable(),
      this.positionService.getChangeObservable()])
      .subscribe((pagedData: FileOperationalData[]) => {
        if (this.debugMode) { console.log(`New Data triggered by external data change event`); }
        this.dataSource.setNewData(pagedData);
      },
        (err) => { if (this.debugMode) { console.log(err); } },
        () => {
          if (this.debugMode) { console.log(`Finished`); }
        }
      );
  }

  loadData(limit: number = 10, offset: number = 0): Observable<FileOperationalData[]> {
    return this.fileOperationalDataStoreService.getFileOperationaldataList({ limit: limit, offset: offset }, true, { sort: 'DESC' })
      .concatMap<FileOperationalData[], FileOperationalData[]>((filesOp: FileOperationalData[]) => {
        this.communicationBetweenCompServ.sendFileOperaionalDataInfo.next(filesOp);
        return this.fileOperationalDataStoreService.getFileOperationalDataCount()
          .map<number, FileOperationalData[]>((length: number) => {
            /* FIXME: remove soring when it will be supported via API */
            this.dataLength = length;
            return filesOp.sort((f1, f2) => {
              return f2.creationDate - f1.creationDate;
            });
          });
      });
  }

  onPaginatorEvents(paginator: MatPaginator): Observable<FileOperationalData[]> {
    /* Subscribe for changes that re-render table view */
    return paginator.page
      .concatMap<PageEvent, FileOperationalData[]>((pageEvent: PageEvent) => {
        const limit = pageEvent.pageSize;
        const offset = pageEvent.pageIndex * pageEvent.pageSize;
        if (this.debugMode) { console.log(limit, offset); }
        return this.loadData(limit, offset);
      });
  }

  onExternalDataChangeEvent(changers: Observable<any>[]): Observable<FileOperationalData[]> {
    /* All change emitters related to operational data */
    return Observable.merge(...changers)
      .concatMap<any, FileOperationalData[]>((v: any) => {
        const limit = this.paginator.pageSize;
        const offset = this.paginator.pageIndex * limit;
        this.paginator.pageIndex = 0;
        return this.loadData(limit, offset);
      });
  }

  onFilterEvents(): Observable<FileOperationalData[]> {
    return this.loadData();
  }

  onClearFilters(): Observable<FileOperationalData[]> {
    throw new Error('Method not implemented.');
  }

  getDataSourceLength(): number {
    return this.dataLength;
  }

  isLoading(): boolean {
    return !this.dataFetched;
  }

  isThisRowExpanded(row: FileOperationalTableElement): boolean {
    if (this.expandedElement !== null) {
      return this.expandedElement.rowId === row.rowId;
    } else { /* No expanded element */
      return false;
    }
  }

  handleMainRowClick(rowElement: FileOperationalTableElement): FileOperationalTableElement {
    if (this.expandedElement !== null
      && this.expandedElement.rowId === rowElement.rowId) {
      /* Same element clicked twice, hide expansion */
      this.expandedElement = null;
    } else {
      /* New element clicked, expand it */
      this.expandedElement = rowElement;
    }
    return this.expandedElement;
  }

  rowCanBeExpanded(index: number, row: FileOperationalTableElement): boolean {
    return (row.detailRow === true);
  }

  isThisDetailElement(row: FileOperationalTableElement) {
    return (this.expandedElement !== null
      && this.expandedElement.rowId === +row.rowId);
  }

}
