import { Component, OnInit, ChangeDetectorRef, ViewChild, OnDestroy, AfterViewInit, Input } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';

/* External libraries */
import 'rxjs/add/operator/switchMap';

import { Subscription } from 'rxjs/Subscription';

/* Application services */
import { ScheduleDataStoreService } from '@shared/services/dataStore/scheduleDataStore.service';
import { PositionDataStoreService } from '@shared/services/dataStore/positionDataStore.service';

// Env
import { environment } from '@env/environment';
import { VeeStatusDataStoreService } from '@shared/services/dataStore/veeProcessDataStore.service';
import { DataExploarationLocalisation as eLoc } from '../../data-exploration-localisation';
import { DynamicColumn, DynamicColumnsHandler } from '@shared/models/viewModels/tableDynamicColumns';
import { MeterPoint } from '@shared/models';
import { MeterPointDataSource } from '../dataSource/meterPointDataSource';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { typesLocalisation } from '@shared/types/localisation';
import { TableHost, NestedTableHost } from '@shared/types';
import { Observable } from 'rxjs/Observable';
import { AnalysisAlgorithm } from '@shared/models/appModels/analysisAlgorithm.model';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { concatMap } from 'rxjs/operators';
import { DataExplorationInteractiveTutorialService } from '../../data-exploration-interactive-tutorial.service';
import { InteractiveTutorialService } from '@shared/services/interactiveTutorialService.service';

enum ColumnGroupsEnum {
  'META_DATA' = 0,
  'VALUES' = 1
}


@Component({

  // tslint:disable-next-line:component-selector
  selector: 'sv-data-exploration-summary',
  templateUrl: 'data-exploration-summary.component.html',
  styleUrls: ['data-exploration-summary.component.sass'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(.4, 0, .2, 1)')),
    ]),
  ],
})
export class DataExplorationSummaryComponent implements OnInit, OnDestroy, AfterViewInit, TableHost<MeterPoint>, NestedTableHost<MeterPoint> {
  expandedElement: any = null;
  actualShowElement: number;
  readonly debugMode: boolean = environment.debug;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  private scheduleId: number;
  private positionId: number;
  @Input() diagramId: number;
  explorationButtonDisabled: boolean = true;
  explorationButtonVisable: boolean = false;
  filterForm: FormGroup;
  changeFilterForm: FormGroup;
  dataSourceExploration: MeterPointDataSource;
  columns: DynamicColumnsHandler<MeterPoint>;
  readonly itemsPage = typesLocalisation.ItemsPage[environment.language].texts;
  analysisMeterPoints = new Array<MeterPoint>();
  filterMeterPoints = new Array<MeterPoint>();
  actualSelect: {
    idSchedule?: number,
    idPosition?: number
  };
  aggregation: string = '1d';
  actual_position: number;
  queryParams$: Subscription;
  scheduleSelect$: Subscription;
  positionsSelect$: Subscription;
  readonly tableHeaders = eLoc[environment.language].texts.asset.assetDataPreview.tableHeaders;
  readonly header = eLoc[environment.language].texts.page.dataExploration;
  readonly noData = eLoc[environment.language].texts.asset.assetDataPreview.noData;
  readonly actions = eLoc[environment.language].texts.asset.assetDataPreview.tableHeaders.ACTIONS;
  readonly parametersField = eLoc[environment.language].texts.asset.assetDataPreview.parametersField;
  readonly generateResults = eLoc[environment.language].texts.page.generateResults;
  readonly exploration = eLoc[environment.language].texts.page.exploration;
  readonly seeDetailsMdIcon: string = 'expand_more';
  readonly hideDetailsMdIcon: string = 'expand_less';
  readonly algorithmsMdIcon: string = 'equalizer';
  length: number;
  listExplorationAlgorithm: Array<AnalysisAlgorithm>;
  dataFetched: boolean;
  dataLoading: boolean;
  dataLength: number;
  isExpansionDetailRow = (index, row) => row.hasOwnProperty('detailRow');
  isExpandedElement = (row: MeterPoint) => (this.actualShowElement === row.idMeterPoint && this.expandedElement !== null);

  startTutorialFunc;

  constructor(
    private positionDataStoreService: PositionDataStoreService,
    public scheduleDataStoreService: ScheduleDataStoreService,
    private fb: FormBuilder,
    public veeStatusService: VeeStatusDataStoreService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private interactiveTutorialService: InteractiveTutorialService,
    private dataExplorationInteractiveTutorialService: DataExplorationInteractiveTutorialService
  ) {
    this.filterForm = this.fb.group({
      'scheduleSelect': new FormControl(),
      'positionSelect': new FormControl()
    });
    this.changeFilterForm = this.fb.group({
      meter: [''],
      aggregation: ['1d'],
      address: [''],
    });
    this.actualSelect = { idPosition: undefined, idSchedule: undefined };
    this.listExplorationAlgorithm = new Array<AnalysisAlgorithm>();
  }


  ngOnInit() {
    this.columns = new DynamicColumnsHandler<MeterPoint>([], this.generateBaseDynamicColumns(), ['action']);
    this.route.queryParams.subscribe(params => {
      this.paginator._intl.itemsPerPageLabel = this.itemsPage.itemsPage;
      this.paginator._intl.nextPageLabel = this.itemsPage.nextPage;
      this.paginator._intl.previousPageLabel = this.itemsPage.previousPage;
      this.paginator._intl.lastPageLabel = this.itemsPage.lastPage;
      this.paginator._intl.firstPageLabel = this.itemsPage.firstPage;
      const positionId = +params.position || -1;
      const scheduleId = +params.schedule || -1;
      if (positionId !== -1) {
        this.actual_position = +params.position;
        this.dataSourceExploration = new MeterPointDataSource();
        this.dataSourceExploration.setNewData(this.filterMeterPoints);
        this.length = this.analysisMeterPoints.length;
        this.initalMeterPoint();
        this.cdr.markForCheck();
      }
      if (scheduleId !== -1) {
        this.scheduleId = scheduleId;
      }
    });
  }

  ngAfterViewInit(): void {
    this.loadCounters()
      .subscribe(v => { });

    this.onPaginatorEvents(this.paginator)
      .subscribe((pagedData: MeterPoint[]) => {
        if (this.debugMode) { console.warn(`New Data triggered by paginator event`); }
        this.dataSourceExploration.setNewData(pagedData);
        this.cdr.markForCheck();
      });
    this.onFilterEvents()
      .subscribe((pagedData: MeterPoint[]) => {
        if (this.debugMode) { console.warn(`New Data triggered by filtering event`); }
        this.dataSourceExploration.setNewData(pagedData);
        this.cdr.markForCheck();
      });

    this.paginator._intl.itemsPerPageLabel = this.itemsPage.itemsPage;
    this.paginator._intl.nextPageLabel = this.itemsPage.nextPage;
    this.paginator._intl.previousPageLabel = this.itemsPage.previousPage;
    this.startTutorialFunc = setTimeout(() => this.startInteractiveTutorial(), 1500);
  }

  startInteractiveTutorial(){
    const steps = this.dataExplorationInteractiveTutorialService.getDataExplorationSummaryInteractiveTutorialSteps();
    this.interactiveTutorialService.startInteractiveTutorial(steps);
  }

  initalMeterPoint() {
    if (this.actual_position != undefined) {
      this.positionDataStoreService.getPositionMeterPointsCount(this.actual_position).subscribe(num => {
        this.positionDataStoreService.getPositionMeterPoints(this.actual_position, num, 0).subscribe(v => {
          this.analysisMeterPoints = v;
          this.filterMeterPoints = v;
          const limit = this.paginator.pageSize;
          const offset = this.paginator.pageIndex * this.paginator.pageSize;
          this.loadData(limit, offset)
            .subscribe((pagedData: MeterPoint[]) => {
              this.dataSourceExploration.setNewData(pagedData);
            },
              (e) => {
                console.error(e);
              },
              () => {
                this.dataFetched = true;
              });
          this.explorationButtonVisable = true;
          this.cdr.markForCheck();
        });
      });
    }
  }

  onPaginatorEvents(paginator: MatPaginator): Observable<any[]> {
    return paginator.page
      .concatMap<PageEvent, MeterPoint[]>((pageEvent: PageEvent) => {
        return this.loadData(pageEvent.pageSize, pageEvent.pageIndex * pageEvent.pageSize);
      });
  }

  onExternalDataChangeEvent(changeInductors: Observable<any>[]): Observable<any[]> {
    return Observable.merge(...changeInductors)
      .concatMap<any, MeterPoint[]>((v: any) => {
        return this.loadData(this.paginator.pageSize, this.paginator.pageIndex * this.paginator.pageSize);
      });
  }

  onFilterEvents(): Observable<any[]> {
    return this.changeFilterForm.valueChanges.pipe(concatMap(change => {
      if (!this.dataSourceExploration) { return; }
      this.filterMeterPoints = this.analysisMeterPoints;
      if (change.address != 'all' && change.address != '') {
        this.filterMeterPoints = this.filterMeterPoints.filter(v => v.meterPlacement &&
          v.meterPlacement.toLocaleLowerCase().startsWith(change.address.toLocaleLowerCase()));
      }
      if (change.meter != 'all' && change.meter != '') {
        this.filterMeterPoints = this.filterMeterPoints.filter(v => v.serialNumber &&
          v.serialNumber.toLocaleLowerCase().startsWith(change.meter.toLocaleLowerCase()));
      }
      this.aggregation = change.aggregation;
      const limit = this.paginator.pageSize;
      const offset = this.paginator.pageIndex * limit;
      this.dataSourceExploration.setNewData(this.filterMeterPoints);
      return this.loadData(limit, offset);
    }));
  }

  onClearFilters(): Observable<any[]> {
    return this.loadData(this.paginator.pageSize, this.paginator.pageIndex * this.paginator.pageSize);
  }

  isThisRowExpanded(row: any): boolean {
    if (this.expandedElement !== null) {
      return this.expandedElement.rowId === row.rowId;
    } else { /* No expanded element */
      return false;
    }
  }

  handleMainRowClick(rowElement: any, actual?: any) {
    if (this.expandedElement !== null
      && this.expandedElement.rowId === rowElement.rowId) {
      /* Same element clicked twice, hide expansion */
      console.warn('Hiding same');
      this.expandedElement = null;
    } else {
      /* New element clicked, expand it */
      console.warn('Estting new');
      this.expandedElement = rowElement;
    }
    return this.expandedElement;
  }

  rowCanBeExpanded(index: number, row: any): boolean {
    return (row.detailRow === true);
  }

  alwaysTrue(index: number, row: any): boolean {
    return false;
  }

  isThisDetailElement(row: any): boolean {
    return (this.expandedElement !== null
      && this.expandedElement.rowId === +row.rowId);
  }

  loadCounters(): Observable<number> {
    let Obs: Observable<any>[] = [
    ];
    return Observable.merge(...Obs);
  }

  ngOnDestroy(): void {
    try { /* Any of subscriptions may be null if some subscription throw an erorr */
      if (this.queryParams$) {
        this.queryParams$.unsubscribe();
      }
    } catch (e) {
      console.error(e);
    } finally {
      if (this.scheduleSelect$) {
        this.scheduleSelect$.unsubscribe();
      }
      if (this.positionsSelect$) {
        this.positionsSelect$.unsubscribe();
      }
      clearTimeout(this.startTutorialFunc);
    }
  }

  private generateBaseDynamicColumns(): DynamicColumn<MeterPoint>[] {
    const dataColumns: DynamicColumn<MeterPoint>[] = [
      {
        group: ColumnGroupsEnum.META_DATA,
        sort: false,
        columnDef: 'idMeter',
        header: this.tableHeaders.METER_ID,
        type: 'idMeter',
        valueKey: 'idMeter',
        classType: (row: MeterPoint) => `bla`,
        generateCellContent: (row: MeterPoint) => `bla`
      },
      {
        group: ColumnGroupsEnum.META_DATA,
        sort: false,
        columnDef: 'location',
        header: this.tableHeaders.LOCATION,
        type: 'location',
        valueKey: 'location',
        classType: (row: MeterPoint) => `bla`,
        generateCellContent: (row: MeterPoint) => `bla`
      },
      {
        group: ColumnGroupsEnum.META_DATA,
        sort: false,
        columnDef: 'measurementType',
        header: this.tableHeaders.MES_TYPE,
        type: 'measurementType',
        valueKey: 'measurementType',
        classType: (row: MeterPoint) => `bla`,
        generateCellContent: (row: MeterPoint) => `bla`
      },
      {
        group: ColumnGroupsEnum.META_DATA,
        sort: false,
        columnDef: 'customer',
        header: this.tableHeaders.CUSTOMER,
        type: 'customer',
        valueKey: 'customer',
        classType: (row: MeterPoint) => `bla`,
        generateCellContent: (row: MeterPoint) => `bla`
      },
      {
        group: ColumnGroupsEnum.META_DATA,
        sort: false,
        columnDef: 'segment',
        header: this.tableHeaders.SEGMENT,
        type: 'segment',
        valueKey: 'segment',
        classType: (row: MeterPoint) => `bla`,
        generateCellContent: (row: MeterPoint) => `bla`
      }
    ];
    this.cdr.markForCheck();
    return dataColumns;
  }

  getDataSourceLength(): number {
    if (this.filterMeterPoints) {
      return this.filterMeterPoints.length;
    }
  }



  loadData(limit: number, offset: number): Observable<any[]> {
    this.dataLoading = true;
    let initialObservable: Observable<MeterPoint[]>;

    let tmp = new Array<MeterPoint>();
    let i = 0;
    this.filterMeterPoints.forEach(element => {
      if (i >= offset && i < (limit + offset)) {
        tmp.push(element);
      }
      i = i + 1;
    });
    initialObservable = Observable.of(tmp);
    initialObservable.subscribe(v => {
      this.cdr.markForCheck();
    });
    return initialObservable;
  }

  isLoading(): boolean {
    if (this.actual_position && (!this.dataSourceExploration || !this.analysisMeterPoints)) {
      return true;
    }
  }

  detailsVisible(detail) {
    return (this.expandedElement !== null) ? detail.rowId === this.expandedElement.rowId : false;
  }
}
