<div class="chart">
  <canvas baseChart
    [data]="data"
    [datasets]="datasets"
    [labels]="labels"
    [chartType]="'doughnut'"
    [options]="options"
    [colors]="colors"
    [legend]="legend"
    (chartClick)="chartClicked($event)"
    (chartHover)="chartHovered($event)"
    >
  </canvas>
</div>
