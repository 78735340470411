import { HttpErrorResponse } from '@angular/common/http';
import * as HttpStatus from 'http-status-codes';

import { Observable } from 'rxjs/Observable';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/from';

import { environment } from '@env/environment';
import { GenericHttpParams } from '@shared/types/http/httpType';
import { KeycloakService } from '@shared/services/auth/keycloak.service';

export class HttpErrorHandler<T, P extends GenericHttpParams<T>> {

  readonly VERBOSE: boolean;
  constructor(verbose: boolean = environment.debug) {
    this.VERBOSE = verbose;
   }

  public handleError(params: P, isArray: boolean):
  (err: HttpErrorResponse, source: Observable<T>) => Observable<T> | ErrorObservable<any> {
    return (err: HttpErrorResponse, source: Observable<T>) => {
          if (err instanceof HttpErrorResponse) {
            switch (err.status) {
              case HttpStatus.BAD_REQUEST: { /* Incorrect query - user did something wrong */
                return Observable.throw(`${err.status}: ${err.message} ${err.statusText}`);
              }
              case HttpStatus.NOT_FOUND: {  /* Not found - in strict mode it's error, in non-strict shall return empty object/array */
                if (typeof(params.config) !== 'undefined' && typeof(params.config.strict) !== 'undefined') {
                  if (params.config.strict === true) {
                    /* API works in strict mode, no empty reponse is allowed */
                    const throwableTxt: string = `
                      \nError: ${err.error}
                      \nMessage: ${err.message}
                      \nStatus: ${err.status}
                      \nStatusText: ${err.statusText}
                      \nUrl: ${err.url}`;
                    return Observable.throw(`No results obtained in strict mode ${throwableTxt}`);
                  } else {
                    /* API works in non-strict mode */
                    let baseObj;
                    if (!isArray) {
                      if (this.VERBOSE) { console.warn(`Empty object returned in non-strict API mode in response to ${err.url}`); }
                      baseObj = {} as T;
                    } else {
                      if (this.VERBOSE) { console.warn(`Empty array returned in non-strict API mode`); }
                      baseObj = [];
                    }
                    return Observable.of(baseObj) as Observable<T>;
                  }
                } else {
                  return Observable.throw(`Strict mode not passed in parameters. Used strict by default\n${err.message} ${err.statusText}`);
                }
              }
              case HttpStatus.INTERNAL_SERVER_ERROR: { /* Internal server error - server did something wrong */
                return Observable.throw(`${err.status}: ${err.message} \n${err.statusText}`);
              }
              case HttpStatus.UNAUTHORIZED: { /* Unauthorized */
                setTimeout(() => {
                  KeycloakService.logout();
                }, 1500);
                return new ErrorObservable(err.error);
              }
              case HttpStatus.REQUEST_TOO_LONG: {
                return Observable.throw(err);
              }
              case 0: {
                return Observable.throw(err);
              }
              default: { /* Other status codes */
                return Observable.throw(`${err.status}: ${err.message} \n${err.statusText}`);
              }
            }
          } else {
            /* Unhandled exception */
            return Observable.throw(err);
          }
        };
      }

}
