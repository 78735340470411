// Angular modules import
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// RjJS imports
import { Observable } from 'rxjs/Observable';

import { MeasurementData, IMeasurementData } from '@shared/models/appModels/measurementData.model';
import { RestTypeMapper } from '@shared/models/RestSupport';

import { MeasurementDataHttpParamG } from '@shared/types/http/measurementDataHttpConfig';
import { EndpointsEnum } from '@shared/types/http/endpointEnum';

import { getMockMeasurmeentData } from '@shared/mock/measurementData.mock';
import { CommonAppDataService } from '@shared/services/commonAppData.service';
import { RequestService } from './requestService.class';

@Injectable()
export class MeasurementDataService extends RequestService {

  mockMeasurmeentData: IMeasurementData[] = [];

  private measurementDataMapper: RestTypeMapper<IMeasurementData, MeasurementData>;

  constructor(http: HttpClient, cs: CommonAppDataService) {
    super(http, cs, EndpointsEnum.MEASUREMENT_DATA);
    if (this.MOCK_SERVICE) {
      this.mockMeasurmeentData = getMockMeasurmeentData();
    } else {
      /* Unpin mocking services - this will give ref error ASAP */
      this.mockMeasurmeentData = undefined;
    }
    /* Init mappers */
    this.measurementDataMapper = new RestTypeMapper<IMeasurementData, MeasurementData>();
  }

  getMeasurementDataList(params: MeasurementDataHttpParamG): Observable<MeasurementData[]> {
    const thisApiID = 'getMeasurementDataList';
    if (this.MOCK_SERVICE) {
      /* Filtering logic */
      let resultsArrayed = [];
      if (params.queryParams.id) {
        resultsArrayed = params.queryParams.id.map((idSearch: string) => this.mockMeasurmeentData.filter(mesData => mesData.idMeasurementData === idSearch));
      } else if (params.queryParams.from || params.queryParams.to) {
        if (params.queryParams.from) {
          resultsArrayed = this.mockMeasurmeentData.filter(mesData => mesData.timestamp >= +params.queryParams.from);
        }
        if (params.queryParams.to) {
          resultsArrayed = this.mockMeasurmeentData.filter(mesData => mesData.timestamp <= +params.queryParams.to);
        }
      } else if (params.queryParams.meterId) {
        resultsArrayed = this.mockMeasurmeentData.filter(mesData => mesData.idMeterPoint === +params.queryParams.meterId);
      } else if (params.queryParams.idPosition) {
        resultsArrayed = this.mockMeasurmeentData.filter(mesData => mesData.idPosition === +params.queryParams.idPosition);
      } else { /* No filter parammeters provided */
        resultsArrayed = this.mockMeasurmeentData;
      }
      const flattern = [].concat.apply([], resultsArrayed);
      return Observable.of(flattern.map(m => new MeasurementData(m)));
    } else {
      return this.getRequest(params, this.measurementDataMapper, thisApiID, MeasurementData);
    }
  }

  postMeasurementData(params: MeasurementDataHttpParamG): Observable<MeasurementData> {
    const thisApiID = 'postMeasurementData';
    if (this.MOCK_SERVICE) {
      return Observable.of(params.body);
    } else {
      const bodyTosend: IMeasurementData = this.measurementDataMapper.mapObjectToInterface(params.body);
      return this.postRequest(bodyTosend, params, this.measurementDataMapper, thisApiID, MeasurementData);
    }
  }

  getMeasurementDataById(params: MeasurementDataHttpParamG): Observable<MeasurementData> {
    const thisApiID = 'getMeasurementDataById';
    if (this.MOCK_SERVICE) {
      const id = this.mockMeasurmeentData.findIndex((mData) => mData.idMeasurementData === params.body.idMeasurementData);
      if (id !== -1) {
        const emit = this.mockMeasurmeentData[id];
        return Observable.of(new MeasurementData(emit));
      } else {
        throw new Error('Unexisting ID');
      }
    } else {
      return this.getRequest(params, this.measurementDataMapper, thisApiID, MeasurementData);
    }
  }

  putMeasurementDataById(params: MeasurementDataHttpParamG[]): Observable<MeasurementData[]> {
    const thisApiID = 'putMeasurementDataById';
    if (this.MOCK_SERVICE) {
      const measuremenetDataArray: MeasurementData[] = new Array<MeasurementData>();
      params.forEach(p => {
        const id = this.mockMeasurmeentData.findIndex((mData) => mData.idMeasurementData === p.body.idMeasurementData);
        if (id !== -1) {
          this.mockMeasurmeentData[id] = p.body;
          measuremenetDataArray.push(new MeasurementData(this.mockMeasurmeentData[id]));
        } else {
          throw new Error('Unexisting ID');
        }
      });
      return Observable.of(measuremenetDataArray);

    } else {
      let bodyTosend: IMeasurementData[] = new Array<IMeasurementData>();
      params.forEach((p, index) => {
        const OneIMeasurement = this.measurementDataMapper.mapObjectToInterface(params[index].body);
        bodyTosend.push(OneIMeasurement);
      });
      return this.putRequest(bodyTosend, params[0], this.measurementDataMapper, thisApiID, MeasurementData);
    }
  }

  deleteMeasurementDataById(params: MeasurementDataHttpParamG): Observable<MeasurementData> {
    const thisApiID = 'deleteMeasurementDataById';
    if (this.MOCK_SERVICE) {
      const id = this.mockMeasurmeentData.findIndex((mData) => mData.idMeasurementData === params.body.idMeasurementData);
      if (id !== -1) {
        const emit = this.mockMeasurmeentData.splice(id, 1)[0];
        return Observable.of(new MeasurementData(emit));
      } else {
        throw new Error('Unexisting ID');
      }
    } else {
      return this.deleteRequest(params, this.measurementDataMapper, thisApiID, MeasurementData);
    }
  }

  getMeasurementCsvFile(params: MeasurementDataHttpParamG): Observable<File> {
    const thisApiID = 'exportMeasurements';
    return this.getCsvFile(params, thisApiID);
  }

}
