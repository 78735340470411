// Angular modules import
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RjJS imports
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/pluck';
import 'rxjs/add/operator/max';
import 'rxjs/add/operator/do';
import 'rxjs/add/observable/throw';

import { environment } from '@env/environment';

import { RestTypeMapper } from '@shared/models/RestSupport/restTypeMapper.model';
import { FileOperationalData, IFileOperationalData } from '@shared/models/appModels/fileOperationalData';

import { EndpointsEnum } from '@shared/types/http/endpointEnum';
import { FileOperationalDataHttpParamG } from '@shared/types/http/fileOperatinalDataHttpConfig';
// Application services
import { CommonAppDataService } from '@shared/services/commonAppData.service';
// Mock
import { MockFileOperationalData } from '@shared/mock/fileOperationalData.mock';
import { RequestService } from './requestService.class';

@Injectable()
export class FileOperationalDataService extends RequestService {

  MockfileOprationalData: IFileOperationalData[] = [];

  fileOperationalDataMapper: RestTypeMapper<IFileOperationalData, FileOperationalData>;

  /* Debug data */
  debugMode: boolean = environment.debug;
  apiPatching: boolean = false;

  constructor(http: HttpClient, cs: CommonAppDataService) {
    super(http, cs, EndpointsEnum.FILE_OPERATIONAL_DATA);
    if (this.MOCK_SERVICE) {
      this.MockfileOprationalData = MockFileOperationalData.slice(0);
    }
    /* Initialize type MAPPERS */
    this.fileOperationalDataMapper = new RestTypeMapper<IFileOperationalData, FileOperationalData>();
  }

  getFileOperationaldataList(
    params: FileOperationalDataHttpParamG = {
      queryParams: { limit: '50', offset: '0' },
      headers: {}
    }): Observable<FileOperationalData[]> {
    const thisApiID = 'getFileOperationaldataList';
    if (this.MOCK_SERVICE) {
      return Observable.of(new Object())
        .mapTo(this.MockfileOprationalData)
        .map<IFileOperationalData[], FileOperationalData[]>(arr => {
          return arr.map(el => this.fileOperationalDataMapper.mapInterfaceToObject(el, FileOperationalData) as FileOperationalData);
        })
        .delay(this.MOCK_TIME)
        .catch((error: any) => Observable.throw(error || 'Server error'));
    } else {
      return this.getRequest(params, this.fileOperationalDataMapper, thisApiID, FileOperationalData);
    }
  }

  postFileOperationaldata(params: FileOperationalDataHttpParamG): Observable<FileOperationalData> {
    const thisApiID = 'postFileOperationaldata';
    if (this.MOCK_SERVICE) {
      const item = params.body;
      const maxId = Math.max(...this.MockfileOprationalData.map(sch => +sch.idFileOperationalData));
      item.idFileOperationalData = maxId + 1;

      this.MockfileOprationalData.forEach((el: FileOperationalData, idx: number) => {
        if (el.id === item.id) { throw new Error(`Customer with ID ${el.id} is already existing`); }
      });
      this.MockfileOprationalData.push(item);
      return Observable.of(item);

    } else {
      const formData = new FormData();
      formData.append('upfile', params.body.nativeFile, params.body.nativeFile.name);
      params.reportProgress = true;
      return this.postFileRequest(formData, params, this.fileOperationalDataMapper, thisApiID, FileOperationalData);
    }
  }

  getFileOperationaldataById(params: FileOperationalDataHttpParamG): Observable<FileOperationalData> {
    const thisApiID = 'getFileOperationaldataById';
    if (this.MOCK_SERVICE) {
      throw new Error('Missing mock implemnetation');
    } else {
      return this.getRequest(params, this.fileOperationalDataMapper, thisApiID, FileOperationalData);
    }
  }

  deleteFileOperationaldataById(params: FileOperationalDataHttpParamG): Observable<FileOperationalData> {
    const thisApiID = 'deleteFileOperationaldataById';
    if (this.MOCK_SERVICE) {
      throw new Error('Missing mock implemnetation');
    } else {
      return this.deleteRequest(params, this.fileOperationalDataMapper, thisApiID, FileOperationalData);
    }
  }

  getFileOperationaldataListCount(params: FileOperationalDataHttpParamG): Observable<number> {
    const thisApiID = 'getFileOperationaldataListCount';
    return this.MOCK_SERVICE ? Observable.of(this.MockfileOprationalData.length) : this.getCountRequest(params, thisApiID);
  }
}
