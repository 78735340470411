import { Injectable } from "@angular/core";
import {
  InteractiveTutorialService,
  NextComponentName,
  TutorialButtonLabel,
} from "@shared/services/interactiveTutorialService.service";

@Injectable({ providedIn: "root" })
export class AssetUploadInteractiveTutorialService {
  constructor(private interactiveTutorialServ: InteractiveTutorialService) {}

  getAssetUploadMainComponentInteractiveTutorialSteps() {
    const step = this.interactiveTutorialServ.generateStep(
      "#svUpload",
      "bottom",
      $localize`:@@interactive tutorial/asset upload/main view/upload:Start by selecting the appropriate csv file`,
      $localize`:@@interactive tutorial/measurements upload/main component/svUpload title:Select file`,
      [TutorialButtonLabel.NEXT]
    );

    const step1 = this.interactiveTutorialServ.generateStep(
      "#svUploadBtn",
      "bottom-end",
      $localize`:@@interactive tutorial/measurements upload/main component/svUploadBtn:Finalize your upload by clicking on this button.`,
      $localize`:@@interactive tutorial/measurements upload/main component/svUploadBtn title:Finalize upload`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.COMPLETE_NEXT_COMPONENT],
      undefined,
      undefined,
      {
        complete: () => {
          this.interactiveTutorialServ.canGoToNextStepSubject.next(
            NextComponentName.ASSET_UPLOAD_FILES_PREVIEW
          );
        },
      }
    );

    const steps: unknown[] = [step, step1];
    return steps;
  }

  getAssetUploadFilesPreviewInteractiveTutorialSteps() {
    const step = this.interactiveTutorialServ.generateStep(
      "#svFilesPreview",
      "bottom",
      $localize`:@@interactive tutorial/asset upload/files preview/table:The table lists the asset files imported by you.`,
      $localize`:@@interactive tutorial/asset upload/files preview/table title:Asset files table`,
      [TutorialButtonLabel.COMPLETE_NEXT_COMPONENT],
      undefined,
      true,
      {
        complete: () => {
          this.interactiveTutorialServ.canGoToNextStepSubject.next(
            NextComponentName.ASSET_UPLOAD_DATA_PREVIEW
          );
        },
      }
    );
    const steps: unknown[] = [step];
    return steps;
  }

  getAssetUploadDataPreviewInteractiveTutorialSteps() {
    const step = this.interactiveTutorialServ.generateStep(
      "#svMeterFilter",
      "top",
      $localize`:@@interactive tutorial/asset upload/data preview/meter filter:This field allows you to filter the data in the table below by meter ID.`,
      $localize`:@@interactive tutorial/asset upload/data preview/meter filter title:Filter by meter ID`,
      [TutorialButtonLabel.NEXT],
      undefined,
      true
    );
    const step1 = this.interactiveTutorialServ.generateStep(
      "#svAddNewElement",
      "top",
      $localize`:@@interactive tutorial/asset upload/data preview/add new meter:By clicking onto this button, you will go to the wizard for manually adding a new meter.`,
      $localize`:@@interactive tutorial/asset upload/data preview/add new meter title:Manually adding a new meter point`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
    );
    const step2 = this.interactiveTutorialServ.generateStep(
      "#svMetersTable",
      "top",
      $localize`:@@interactive tutorial/asset upload/data preview/meter table:In this table you can view the available meter points, edit them and deactivate or add a specific customer to them.`,
      $localize`:@@interactive tutorial/asset upload/data preview/meter table title:Meter points table`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.COMPLETE]
    );
    const steps: unknown[] = [step, step1, step2];
    return steps;
  }

  getAssetUploadAddNewAssetModalInteractiveTutorialSteps(
    optionalValues: number
  ) {
    const step = this.interactiveTutorialServ.generateStep(
      "#firstSection",
      "right",
      $localize`:@@interactive tutorial/asset upload/add new asset modal/basic info:Here you can define basic information about the meter. It is e.g. a unique identifier of a meter (required value), its location address, the type of readings it takes and the type of the meter itself. At this point, we can also define the belonging of the meter to a given reading group, the maximum reading that the meter can make, the meter superior to the meter, as well as information whether it is an active meter (it collects readings all the time) and whether it is billed (will it be participated in various types of settlements).`,
      $localize`:@@interactive tutorial/asset upload/add new asset modal/basic info title:Basic Data`,
      [TutorialButtonLabel.NEXT]
    );

    const step1 = this.interactiveTutorialServ.generateStep(
      "#addCustomer",
      "right",
      $localize`:@@interactive tutorial/asset upload/add new asset modal/add customer:By using this slider, you can decide whether you assign a given customer to this meter. When you decide to add a client, you will be asked to enter his unique identifier and define his operating segment.`,
      $localize`:@@interactive tutorial/asset upload/add new asset modal/add customer title:Adding customer`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
    );

    const step2 = this.interactiveTutorialServ.generateStep(
      "#addOptionalValue",
      "right",
      $localize`:@@interactive tutorial/asset upload/add new asset modal/add optional value:By clicking this button, you can add a new optional value to the counter. Optional values ​​are used to store additional information about the counter.`,
      $localize`:@@interactive tutorial/asset upload/add new asset modal/add optional value title:Adding optional values`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT],
      undefined,
      true
    );

    const step3 = this.interactiveTutorialServ.generateStep(
      "#deleteOptionalValue",
      "right",
      $localize`:@@interactive tutorial/asset upload/add new asset modal/delete optional value:By clicking on this button, you can delete a given optional value.`,
      $localize`:@@interactive tutorial/asset upload/add new asset modal/delete optional value title:Deleting optional value`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT],
      () => {
        if (optionalValues > 0) {
          return true;
        } else {
          return false;
        }
      },
      true
    );

    const step4 = this.interactiveTutorialServ.generateStep(
      "#optionalValue",
      "right",
      $localize`:@@interactive tutorial/asset upload/add new asset modal/optional value:Here you define the name and value of the new optional feature.`,
      $localize`:@@interactive tutorial/asset upload/add new asset modal/optional value title:Optional value`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT],
      () => {
        if (optionalValues > 0) {
          return true;
        } else {
          return false;
        }
      },
      true
    );

    const step5 = this.interactiveTutorialServ.generateStep(
      "#save",
      "right",
      $localize`:@@interactive tutorial/save btn:When you are ready, end the process by clicking on this button`,
      $localize`:@@interactive tutorial/save btn title:Save`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.COMPLETE],
      undefined,
      true
    );

    const steps: unknown[] = [step, step1, step2, step3, step4, step5];
    return steps;
  }

  getAssetUploadAssignCustomerInteractiveTutorialSteps() {
    const step = this.interactiveTutorialServ.generateStep(
      "#assignCustomer",
      "right",
      $localize`:@@interactive tutorial/asset upload/assign customer/main table:From among the clients displayed in this table, select the one you want to add to the currently selected meter.`,
      $localize`:@@interactive tutorial/asset upload/assign customer/main table title:Assign customer`,
      [TutorialButtonLabel.COMPLETE]
    );

    const steps: unknown[] = [step];
    return steps;
  }
}
