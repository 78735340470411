import { Injectable } from "@angular/core";
import { InteractiveTutorialService, TutorialButtonLabel } from "@shared/services/interactiveTutorialService.service";

@Injectable({ providedIn: "root" })
export class SettingsInteractiveTutorialService {
  constructor(private interactiveTutorialService: InteractiveTutorialService) {}

  getSettingsDataModelInteractiveTutorialSteps() {
    const step = this.interactiveTutorialService.generateStep(
      "#mainTable",
      "bottom",
      $localize`:@@interactive tutorial/settings/data model/main table:This table shows the collection of variables used in the Smartvee application. These variables represent various kinds of values ​​read by the meter points. Your variable set may be different from the default smartvee set. In order to eliminate the differences, the variables should be mapped, to do this, go to the measurements upload screen.`,
      $localize`:@@interactive tutorial/settings/data model/main table title:Smartvee application variables`,
      [TutorialButtonLabel.NEXT]
    );

    const step1 = this.interactiveTutorialService.generateStep(
      "#addNewVariableBtn",
      "left",
      $localize`:@@interactive tutorial/settings/data model/new variable btn:By clicking this button you will be taken to the wizard for creating a new variable.`,
      $localize`:@@interactive tutorial/settings/data model/new variable btn title:Create a new variable in the application`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.COMPLETE]
    );

    const steps: unknown[] = [step, step1];
    return steps;
  }

  getSettingsDataModelNewVariableModalInteractiveTutorialSteps() {
    const step = this.interactiveTutorialService.generateStep(
      "#container",
      "right",
      $localize`:@@interactive tutorial/settings/data model/new variable modal/main container:In this wizard you can define variable name,parsed name, description, variable unit and type (variable name,parsed name and description are required values).`,
      $localize`:@@interactive tutorial/settings/data model/new variable modal/main container title:New variable wizard`,
      [TutorialButtonLabel.NEXT]
    );

    const step1 = this.interactiveTutorialService.generateStep(
      "#isPrimary",
      "right",
      $localize`:@@interactive tutorial/settings/data model/new variable modal/primary values:This value determines whether the application considers the data record to be valid. If the variable marked with the primary flag does not appear in the read record, it will be considered invalid.`,
      $localize`:@@interactive tutorial/settings/data model/new variable modal/primary values title:Primary value`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
    );

    const step2 = this.interactiveTutorialService.generateStep(
      "#saveBtn",
      "right",
      $localize`:@@interactive tutorial/settings/data model/new variable modal/save btn:When you will be ready, finish creation of new variable, by clicking onto this button`,
      $localize`:@@interactive tutorial/settings/data model/new variable modal/save btn title:Save new variable`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.COMPLETE]
    );

    const steps: unknown[] = [step, step1, step2];
    return steps;
  }

  getSettingsDataModelEditVariableModalInteractiveTutorialSteps() {
    const step = this.interactiveTutorialService.generateStep(
      "#container",
      "right",
      $localize`:@@interactive tutorial/settings/data model/edit new variable modal/main container:Here you can edit existing variables. You cannot change the name and the parsed name of an existing variable as this could adversely affect data continuity.`,
      $localize`:@@interactive tutorial/settings/data model/edit new variable modal/main container title:Edit existing variable`,
      [TutorialButtonLabel.NEXT]
    );

    const step1 = this.interactiveTutorialService.generateStep(
      "#saveBtn",
      "right",
      $localize`:@@interactive tutorial/settings/data model/edit new variable modal/save btn:When you will be ready, finish edition of variable, by clicking onto this button.`,
      $localize`:@@interactive tutorial/settings/data model/edit new variable modal/save btn title:Save changes`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.COMPLETE]
    );

    const steps: unknown[] = [step, step1];
    return steps;
  }

  getSettingsApplicationInteractiveTutorialSteps() {
    const step = this.interactiveTutorialService.generateStep(
      "#startDate",
      "bottom",
      $localize`:@@interactive tutorial/settings/application settings/start date:On this screen you can configure cyclical dump of data from the application to csv files. We start the whole process by setting the start date. Once established, the data is downloaded from the start date to today and is exported to a csv file.`,
      $localize`:@@interactive tutorial/settings/application settings/start date title:Application settings - data download`,
      [TutorialButtonLabel.NEXT]
    );

    const step1 = this.interactiveTutorialService.generateStep(
      "#interval",
      "bottom",
      $localize`:@@interactive tutorial/settings/application settings/interval:After determining the starting date, we move on to determining the time interval. After determining the interval, the data will be cyclically dumped into csv files.`,
      $localize`:@@interactive tutorial/settings/application settings/interval title:Time interval`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
    );

    const step2 = this.interactiveTutorialService.generateStep(
      "#enableDownloadingData",
      "bottom",
      $localize`:@@interactive tutorial/settings/application settings/enable download data:After setting the previous parameters, we can turn on or off the operation of the entire process.`,
      $localize`:@@interactive tutorial/settings/application settings/enable download data title:Start the process running`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.COMPLETE]
    );

    const steps: unknown[] = [step, step1, step2];
    return steps;
  }
}
