import { IGetInterfaceType } from '@shared/types/applicationTypes';

export interface ISensorData {
    id: number;
    active: boolean;
    beginDate: number;
    endDate: number;
    interval: number;

}

export class SensorData implements ISensorData, IGetInterfaceType<ISensorData, SensorData> {
    id: number;
    active: boolean;
    beginDate: number;
    endDate: number;
    interval: number;


    constructor(iSensorData: ISensorData) {
        this.id = (typeof (iSensorData.id) !== 'undefined') ? iSensorData.id : undefined;
        this.active = (typeof (iSensorData.active) !== 'undefined') ? iSensorData.active : undefined;
        this.beginDate = (typeof (iSensorData.beginDate) !== 'undefined') ? iSensorData.beginDate : undefined;
        this.endDate = (typeof (iSensorData.endDate) !== 'undefined') ? iSensorData.endDate : undefined;
        this.interval = (typeof (iSensorData.interval) !== 'undefined') ? iSensorData.interval : undefined;

    }

    getInterface(webInterface: boolean): ISensorData {
        const emptyValue = (webInterface) ? null : undefined;
        return {
            id: this.id,
            active: this.active,
            beginDate: this.beginDate || emptyValue,
            endDate: this.endDate || emptyValue,
            interval: this.interval

        };
    }

}
