<div class="container schedules">
  <h1 i18n="schedules panel title|The title of schedules list section@@schedules/schedules panel title">Settlement schedules</h1>

  <div class="schedule-top">
    <div class="schedule-search" [formGroup]="filterForm">
      <mat-form-field id="filterByMeter" class="form-group">
        <input type="text" placeholder="Schedule"
          i18n-placeholder="schedule filter by name|Schedule filter by name combobox name@@application/schedule filter name"
          formControlName="criteriaAutoComplete" #critSel matInput [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option [value]="allSchedulesFilterValue" i18n="all filter option|All schedules dropdown option@@schedules/all filter option">All schedules</mat-option>
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option.name">
            {{option.name}}
          </mat-option>
          <!-- <mat-option *ngFor="let option of schedules" [value]="option.name">
            {{option.name}}
          </mat-option> -->
        </mat-autocomplete>
      </mat-form-field>     
    </div>
    <div>
    <button (click)="onCreateNewSchedule()" mat-raised-button color="accent"
      class="btn btn-create-new"
      id="addNewSchedule"
      i18n="new schedule button label|The label of a button for opening a new schedule dialog@@schedules/new schedule button label">New schedule</button>
    </div>
  </div>

  <mat-table id="schedulesTab" #tableFiles [dataSource]="dataSource">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef
      i18n="schedule table header|A header in the table for displaying schedules@@schedules/schedule table header name">Name</mat-header-cell>
      <mat-cell *matCellDef="let schedule">{{ schedule.getScheduleName() }}
        <span *ngIf="schedule.isScheduleClosed()" class="badge closed" i18n="badge text|An information badge caption@@schedules/badge closed">closed</span>
        <span *ngIf="schedule.isScheduleFuture()" class="badge future" i18n="badge text|An information badge caption@@schedules/badge future">future</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef
      i18n="schedule table header|A header in the table for displaying schedules@@schedules/schedule table header status">Status</mat-header-cell>
      <mat-cell *matCellDef="let schedule">
        <span *ngIf="schedule.closed else openBlock">
          <span class="badge" i18n="closed status|A caption indicating schedule closed status@@schedules/closed status">Closed</span>
        </span>
        <ng-template #openBlock>
          <span class="badge" i18n="open status|A caption indicating schedule open status@@schedules/open status">Open</span>
        </ng-template>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef
      i18n="schedule table header|A header in the table for displaying schedules@@schedules/schedule table header actions">Actions</mat-header-cell>
      <mat-cell *matCellDef="let schedule">
        <div class="actions edit">
          <button mat-icon-button [matMenuTriggerFor]="menu" (click)="hasFrozenPosition(schedule)"
            (click)="$event.stopPropagation()">
            <i class="material-icons notranslate">menu</i>
          </button>

          <mat-menu #menu="matMenu" class="menu-box">
            <button mat-menu-item *ngIf="!schedule.isScheduleClosed()" (click)="onShowEditScreen(schedule)">
              <i class="material-icons notranslate">mode_edit</i>
              <span i18n="edit schedule action|A context menu label@@schedules/edit schedule action">Edit</span>
            </button>

            <button mat-menu-item *ngIf="schedule.isScheduleClosed()" (click)="onCreateNewSchedule()">
              <i class="material-icons notranslate">content_copy</i>
              <span i18n="copy schedule action|A context menu label@@schedules/copy schedule action">Copy</span>
            </button>

            <button mat-menu-item *ngIf="!schedule.isScheduleClosed()" [disabled]="isFrozen"
              (click)="onDeleteSchedule(schedule)">
              <i class="material-icons notranslate">close</i>
              <span i18n="delete schedule action|A context menu label@@schedules/delete schedule action">Delete</span>
            </button>
          </mat-menu>

          <i class="material-icons md-24 tomek-button notranslate">expand_more</i>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <mat-cell *matCellDef="let scheduleDetail" class="detail-data">
        <div *ngIf="detailsVisible(scheduleDetail)">
          <sv-schedule-details [inputSchedule]="scheduleDetail"></sv-schedule-details>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumnsFiles"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumnsFiles;" matRipple class="element-row notranslate"
      [class.expanded]="isThisRowExpanded(row)" (click)="handleMainRowClick(row)"></mat-row>

    <mat-row *matRowDef="let detailRow; columns: ['expandedDetail']; when: rowCanBeExpanded"
      [@detailExpand]="isThisRowExpanded(detailRow) ? 'expanded' : 'collapsed' " class="notranslate">
    </mat-row>
  </mat-table>

  <div class="no-result" *ngIf="!isLoading() && ((scheduleCount$ | async) === 0)">
    <span i18n="no schedule data label|A label displayed when schedule table contains no records@@schedules/no schedule data">No data found.</span>
  </div>

  <div class="no-result" *ngIf="isLoading()">
    <mat-spinner [strokeWidth]="3" [diameter]="20" class="notranslate"></mat-spinner>
  </div>

  <mat-paginator #paginator [length]="(scheduleCount$ | async) || 0" [pageIndex]="0" [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons class="notranslate">
  </mat-paginator>
