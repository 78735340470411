<div class="container">
	<h1>{{pageLabel.dataAnalysis}}</h1>
	<sv-select id="svSelect" (selectionEvent)="handleSchedulePositionSelection($event)"></sv-select>
	<mat-accordion class="expansion-box">
		<mat-expansion-panel [expanded]="panelsState">
			<mat-expansion-panel-header>
				<i class="material-icons notranslate">{{algorithmsMdIcon}}</i> {{pageLabel.header}}
			</mat-expansion-panel-header>

			<mat-tab-group id="svAlgorithmsTypeSelect"  class="algorithms-tab-group">
				<mat-tab label="Anomaly Detection" i18n-label="@@analysis/anomaly detection header">
					<form>
						<div id="svAlgortihmSelect" dnd-sortable-container [sortableData]="listAlgorithmsDetectionOfAnomalies">

							<ul class="card-body list-group">
								<li *ngFor="let item of listAlgorithmsDetectionOfAnomalies">
									<mat-checkbox [checked]="item.enabled" [disabled]="!item.switchable"
										(change)="onRuleClick($event, item, AnalysisAlgorithmTypeEnum.DETECTION_OF_ANOMALIES)">
										{{getTranslatedAlgorithmName(item.name)}}</mat-checkbox>
								</li>
							</ul>
						</div>
					</form>
				</mat-tab>
				<mat-tab label="Forecasting" i18n-label="@@analysis/forecasting header">
					<form>
						<div id="svAlgortihmSelect" dnd-sortable-container [sortableData]="listAlgorithmsForecasting">

							<ul class="card-body list-group">
								<li *ngFor="let item of listAlgorithmsForecasting">
									<mat-checkbox [checked]="item.enabled" [disabled]="!item.switchable"
										(change)="onRuleClick($event, item, AnalysisAlgorithmTypeEnum.FORECASTING)">
										{{getTranslatedAlgorithmName(item.name)}}</mat-checkbox>
								</li>
							</ul>
						</div>
					</form>
				</mat-tab>
			</mat-tab-group>
		</mat-expansion-panel>
	</mat-accordion>
	<div *ngIf="!analysisDisabled">
		<sv-data-analysis-readlist *ngIf="!toggleAnalysis" (buttonStartAnalysisClick)="onStartAnalysisClicked($event)"
			(analysisEvent)="onAnalysisEvent($event)" [listEnabledAlgorithm]="listEnabledAlgorithm">
		</sv-data-analysis-readlist>
		<sv-data-analysis-process *ngIf="toggleAnalysis" [positionId]="actualSelect.idPosition"
			[scheduleId]="actualSelect.idSchedule" [analysisEvent]="analysis"
			(buttonNewAnalysisClick)="onNewAnalysisClicked()" (buttonShowResult)="onShowResultClicked($event)"
			[listAlgorithmsForecasting]="listAlgorithmsForecasting"
			[listAlgorithmsDetectionOfAnomalies]="listAlgorithmsDetectionOfAnomalies"
			[actualAnalysisAlgorithm]="actualAnalysisAlgorithm" [listEnabledAlgorithm]="listEnabledAlgorithm">
		</sv-data-analysis-process>
		<sv-data-analysis-summary *ngIf="panelShowResult" [analysisStatus]="analysisStatus" [analysisEvent]="analysis"
			[analysisD]="analysisD" [positionId]="actualSelect.idPosition" [scheduleId]="actualSelect.idSchedule"
			[actualAnalysisAlgorithm]="actualAnalysisAlgorithm" [listAlgorithmsForecasting]="listAlgorithmsForecasting"
			[listAlgorithmsDetectionOfAnomalies]="listAlgorithmsDetectionOfAnomalies" [title]="title">
		</sv-data-analysis-summary>
	</div>

</div>