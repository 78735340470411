import { RequestService } from './requestService.class';
import { Injectable } from '@angular/core';
import { RestTypeMapper } from '@shared/models/RestSupport';
import { HttpClient } from '@angular/common/http';
import { CommonAppDataService } from '../commonAppData.service';
import { EndpointsEnum } from '@shared/types';
import { ParameterHttpParamG } from '@shared/types/http/parameterHttpConfig';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class MeasurementDefinitionMappingService extends RequestService {
    private measurementDefinitionMappingMapper: RestTypeMapper<any, any>;

    constructor(http: HttpClient,
        cs: CommonAppDataService) {
            super(http, cs, EndpointsEnum.DATA_MODEL_TYPE);
            this.measurementDefinitionMappingMapper = new RestTypeMapper<any, any>();
        }

        putMeasurementDataModel(params: ParameterHttpParamG): Observable<any> {
        const thisApiId = 'putMeasurementDataModel';
        const body = params.body;
        return this.putRequest(body, params, this.measurementDefinitionMappingMapper, thisApiId, Object);
    }

}

