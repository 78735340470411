<div class="card">
	<mat-table id="svFilesPreview" #tableFiles [dataSource]="dataSource" matSort>
		<ng-container matColumnDef="name">
			<mat-header-cell *matHeaderCellDef>{{tableHeaders.NAME}}</mat-header-cell>
			<mat-cell *matCellDef="let element">{{element.name}}</mat-cell>
		</ng-container>

		<ng-container matColumnDef="uploadDate">
			<mat-header-cell *matHeaderCellDef mat-sort-header>{{tableHeaders.UPLOAD_DATE}}</mat-header-cell>
			<mat-cell *matCellDef="let element">{{element.creationDate | svDate}}</mat-cell>
		</ng-container>

		<ng-container matColumnDef="action">
			<mat-header-cell *matHeaderCellDef class="text-right">{{tableHeaders.ACTIONS}}</mat-header-cell>
			<mat-cell *matCellDef="let row" class="text-right">
				<i class="material-icons notranslate">{{ isThisDetailElement(row) ? hideDetailsMdIcon : seeDetailsMdIcon }}</i>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="expandedDetail">
			<mat-cell *matCellDef="let detail" class="detail-data">
				<div class="data-text-box">
					<span class="data-text-inline"><b>{{detail.quantity}}</b>{{numerator}}</span>
				</div>
			</mat-cell>
		</ng-container>

		<mat-header-row *matHeaderRowDef="displayedColumnsFiles"></mat-header-row>
		<mat-row *matRowDef="let row; columns: displayedColumnsFiles;" matRipple class="element-row"
			[class.expanded]="isThisRowExpanded(row)" (click)="handleMainRowClick(row)"></mat-row>

		<mat-row *matRowDef="let detailRow; columns: ['expandedDetail']; when: rowCanBeExpanded"
			[@detailExpand]="isThisRowExpanded(detailRow) ? 'expanded' : 'collapsed' ">
		</mat-row>
	</mat-table>


	<div class="no-result" *ngIf="!isLoading() && getDataSourceLength() === 0">
      {{noData}}
  </div>

  <div class="no-result" *ngIf="isLoading()">
      <mat-spinner [strokeWidth]="3" [diameter]="20" class="notranslate"></mat-spinner>
  </div>

	<mat-paginator #paginator
		[length]="dataLength"
		[pageIndex]="0"
		[pageSize]="10"
	[pageSizeOptions]="[2, 10, 20]"
	showFirstLastButtons
	class="notranslate">
	</mat-paginator>
</div>