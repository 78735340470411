<div id="validationWidgetContainer">
<div class="card-header">
	<h3 i18n="@@validation/validation panel title">Data Validation</h3>
	<span class="header-element">
		<i class="material-icons notranslate">border_color</i>
	</span>
</div>
<div class="card-body">
	<h4 i18n="@@validation/widget/pending validation header">Ongoing data validation</h4>
	<div class="content">
		<ng-container *ngIf="(ongoingValidationPosition$ | async) as position; else noOngoingValidation">
			<ng-container *ngIf="ongoingValidationData$ | async as status">
				<sv-progress *ngIf="status.validCount > 0" [labelBox]="false"
					[completeness]="validationProgress(status.validCount, status.invalidCount)"
					unit="%">
				</sv-progress>

				<table>
					<tr>
						<td i18n="@@validation/widget/invalid measurements count label">Invalid</td>
						<td class="center">
							<mat-spinner *ngIf="status?.loading; else invalidContent" diameter="14"></mat-spinner>
							<ng-template #invalidContent>{{ status.invalidCount || "-"}}</ng-template>
						</td>
					</tr>
					<tr>
						<td i18n="@@validation/widget/valid count label">Valid</td>
						<td class="center">
							<mat-spinner *ngIf="status?.loading; else validContent" diameter="14"></mat-spinner>
							<ng-template #validContent>{{ status.validCount || "-"}}</ng-template>
						</td>
					</tr>
					<tr>
						<td i18n="@@validation/widget/manual correction count label">Manual correction</td>
						<td class="center">
							<mat-spinner *ngIf="status?.loading; else manualOngoingValidationContent" diameter="14"></mat-spinner>
							<ng-template #manualOngoingValidationContent>{{ status.manualCorrectionCount || "-" }}</ng-template>
						</td>
					</tr>
					<tr>
						<td i18n="@@validation/widget/auto correction count label">Autocorrection</td>
						<td class="center">
							<mat-spinner *ngIf="status?.loading; else autoOngoingValidationContent" diameter="14"></mat-spinner>
							<ng-template #autoOngoingValidationContent>{{ status.autoCorrectionCount || "-" }}</ng-template>
						</td>
					</tr>
				</table>

				<div class="card-body">
					<div class="text-center">
						<a *ngIf="status.validCount > 0" (click)="navigateToValidation(position)" mat-raised-button color="accent" class="btn" i18n="@@validation/widget/continue validation button label">Continue validation</a>
					</div>
				</div>
			</ng-container>
		</ng-container>
		<ng-template #noOngoingValidation>
			<div class="info" i18n="@@validation/widget/no pending process label">
				No pending validation for this schedule.
			</div>
		</ng-template>
	</div>

	<h4 i18n="@@validation/widget/completed validation header">Previous data validation</h4>
	<div class="content">
		<ng-container *ngIf="(lastValidatedPosition$ | async) as position; else noPreviousValidation">
			<table *ngIf="lastCompletedValidationData$ | async as status">
				<tr>
					<td i18n="@@validation/widget/total measurements count label">Total</td>
					<td class="center">
						<mat-spinner *ngIf="status?.loading; else totalContent" diameter="14"></mat-spinner>
						<ng-template #totalContent>{{ status.totalCount }}</ng-template>
					</td>
				</tr>
				<tr>
					<td i18n="@@validation/widget/manual correction count label">Manual correction</td>
					<td class="center">
						<mat-spinner *ngIf="status?.loading; else manualContent" diameter="14"></mat-spinner>
						<ng-template #manualContent>{{ status.manualCorrectionCount || "-" }}</ng-template>
					</td>
				</tr>
				<tr>
					<td i18n="@@validation/widget/auto correction count label">Autocorrection</td>
					<td class="center">
						<mat-spinner *ngIf="status?.loading; else autoContent" diameter="14"></mat-spinner>
						<ng-template #autoContent>{{ status.autoCorrectionCount || "-" }}</ng-template>
					</td>
				</tr>
			</table>

			<div [ngClass]="{ 'overlay': (lastCompletedValidationData$ | async)?.loading }">
				<sv-pie-chart *ngIf="(lastCompletedValidationChartData$ | async) as data" 
					[datasets]="data" [labels]="chartLabels">
				</sv-pie-chart>
			</div>
			<div class="text-center">
				<a (click)="navigateToValidation(position)" mat-raised-button color="primary" 
					class="btn" i18n="@@widget/process details button label">
					Details
				</a>
			</div>
		</ng-container>
		<ng-template #noPreviousValidation>
			<div class="info" i18n="@@validation/widget/no completed process label">
				No completed validation for this schedule.
			</div>
		</ng-template>
	</div>

</div>
</div>