// Angular modules import
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// RjJS imports
import { Observable } from 'rxjs/Observable';


/* Datasource */
import { getMockMeterData } from '@shared/mock/meterData.mock';
import { IMeterData, MeterData } from '@shared/models/appModels/meterData.model';

import { environment } from '@env/environment';
import { CommonAppDataService } from '@shared/services/commonAppData.service';
import { EndpointsEnum } from '@shared/types/http/endpointEnum';
import { RestTypeMapper } from '@shared/models/RestSupport/restTypeMapper.model';
import { MeterDataHttpParamG } from '@shared/types/http/meterDataHttpConfig';
import { RequestService } from './requestService.class';

@Injectable()
export class MeterDataHTTPService extends RequestService {

  readonly debugMode: boolean = environment.debug;

  mockMeterData: IMeterData[];
  private measurementDataMapper: RestTypeMapper<IMeterData, MeterData>;

  constructor(http: HttpClient, cs: CommonAppDataService) {
    super(http, cs, EndpointsEnum.METER_DATA);
    if (this.MOCK_SERVICE) {
      this.mockMeterData = getMockMeterData();
    }
  }

  /* REAL */
  getMeterdataList(params: MeterDataHttpParamG): Observable<MeterData[]> {
    const thisApiID: string = 'getMeterDataList';
    const url: string = this.basePath
      + this.replacePathParams(thisApiID, this.apiCfg, params.path);
    if (this.MOCK_SERVICE) {
      return Observable.of(this.mockMeterData.map(m => new MeterData(m)));
    } else {
      return this.getRequest(params, this.measurementDataMapper, thisApiID, MeterData, url);
    }
  }

  postMeterData(params: MeterDataHttpParamG): Observable<MeterData> {
    const thisApiID = 'postMeterData';
    if (this.MOCK_SERVICE) {
      const postItem = params.body;
      this.mockMeterData.push(postItem);
      return Observable.of(postItem);
    } else {
      const postBody: IMeterData = this.measurementDataMapper.mapObjectToInterface(params.body);
      return this.postRequest(postBody, params, this.measurementDataMapper, thisApiID, MeterData);
    }
  }

  getMeterdataById(params: MeterDataHttpParamG): Observable<MeterData> {
    const thisApiID = 'getMeterdataById';
    if (this.MOCK_SERVICE) {
      const idx = this.mockMeterData.findIndex(m => m.idMeterData === params.path.idMeterData);
      if (idx !== -1) {
        return Observable.of(new MeterData(this.mockMeterData[idx]));
      } else {
        return Observable.throw('Unexisting MeterData ID requested');
      }
    } else {
      return this.getRequest(params, this.measurementDataMapper, thisApiID, MeterData);
    }
  }

  putMeterDataById(params: MeterDataHttpParamG): Observable<MeterData> {
    const thisApiID = 'putMeterDataById';
    if (this.MOCK_SERVICE) {
      return Observable.of(this.mockMeterData[params.path.idMeterData]);
    } else {
      const postBody: IMeterData = this.measurementDataMapper.mapObjectToInterface(params.body);
      return this.putRequest(postBody, params, this.measurementDataMapper, thisApiID, MeterData);
    }
  }

  deleteMeterDataById(params: MeterDataHttpParamG): Observable<MeterData> {
    const thisApiID = 'deleteMeterDataById';
    if (this.MOCK_SERVICE) {
      return Observable.of(this.mockMeterData[params.path.idMeterData]);
    } else {
      return this.deleteRequest(params, this.measurementDataMapper, thisApiID, MeterData);
    }
  }

  getMeterdataListCount(params: MeterDataHttpParamG): Observable<number> {
    const thisApiID = 'getMeterdataListCount';
    if (this.MOCK_SERVICE) {
      return Observable.of(this.mockMeterData.length);
    } else {
      return this.getCountRequest(params, thisApiID);
    }
  }

  /* Delete all after this point */
  getData(): Observable<MeterData[]> {
    return Observable.of(new Object())
      .mapTo<Object, IMeterData[]>(this.mockMeterData)
      .delay<IMeterData[]>(this.MOCK_TIME)
      .map<IMeterData[], MeterData[]>((listData: IMeterData[]): MeterData[] =>
        listData.map((x: IMeterData) => new MeterData(x)))
      .catch((error: any) => Observable.throw(error || new Error('Server error')));
  }

  getPreValidData(): Observable<MeterData[]> {
    const preValid = this.mockMeterData.filter(item => (item.validationResult === 4));
    return Observable.of(new Object())
      .mapTo<Object, IMeterData[]>(preValid)
      .delay<IMeterData[]>(this.MOCK_TIME)
      .map<IMeterData[], MeterData[]>((listData: IMeterData[]): MeterData[] =>
        listData.map((x: IMeterData) => new MeterData(x)))
      .catch((error: any) => Observable.throw(error || new Error('Server error')));
  }

  getDataById(id: number): Observable<MeterData> {
    return Observable.of(new Object())
      .mapTo(this.mockMeterData)
      .delay<IMeterData[]>(this.MOCK_TIME)
      .map<IMeterData[], IMeterData>((listData: IMeterData[]): IMeterData => listData.find((x: IMeterData) => x.idMeterData === '' + id))
      .map<IMeterData, MeterData>((item: IMeterData): MeterData =>
        new MeterData(item))
      .catch((error: any) => Observable.throw(error || new Error('Server error')));
  }

  getDataByIds(ids: Array<number>): Observable<MeterData[]> {
    return Observable.of(new Object())
      .mapTo(this.mockMeterData)
      .delay<IMeterData[]>(this.MOCK_TIME)
      .map<IMeterData[], IMeterData[]>((listData: IMeterData[]): IMeterData[] =>
        listData.filter((x: IMeterData) => {
          for (const el of ids) {
            if (x.idMeterPoint === el) {
              return true;
            }
          }
        })
      )
      .map<IMeterData[], MeterData[]>((listData: IMeterData[]): MeterData[] =>
        listData.map((x: IMeterData) => new MeterData(x)))
      .catch((error: any) => Observable.throw(error || new Error('Server error')));
  }

  getMeterDataByMeterId(id: number): Observable<MeterData[]> {
    return Observable.of(new Object())
      .mapTo(this.mockMeterData)
      .delay<IMeterData[]>(this.MOCK_TIME)
      .map<IMeterData[], IMeterData[]>((listData: IMeterData[]): IMeterData[] =>
        listData.filter((x: IMeterData) => x.idMeterPoint === id))
      .map<IMeterData[], MeterData[]>((listData: IMeterData[]): MeterData[] =>
        listData.map((x: IMeterData) => new MeterData(x)))
      .catch((error: any) => Observable.throw(error || new Error('Server error')));
  }

  getMeterDataByPostionId(params: MeterDataHttpParamG): Observable<MeterData[]> {
    return Observable.of(new Object())
      .mapTo(this.mockMeterData)
      .delay<IMeterData[]>(this.MOCK_TIME)
      .map<IMeterData[], MeterData[]>((listData: IMeterData[]): MeterData[] =>
        listData.map((x: IMeterData) => new MeterData(x)))
      .catch((error: any) => Observable.throw(error || new Error('Server error')));
  }

  getCountsForPosition(params: MeterDataHttpParamG): Observable<number> {
    return Observable.of(this.mockMeterData.length);
  }

  addData(body: MeterData): Observable<MeterData> {
    const maxId = Math.random().toString(36).substr(2, 10); // Math.max(...this.mockMeterData.map(md => md.idMeterData));
    body.idMeterData = maxId;

    this.mockMeterData.forEach((el: MeterData, idx: number) => {
      if (el.idMeterData === body.idMeterData) { throw new Error(`chedule with ID ${el.idMeterData} is already existing`); }
    });

    this.mockMeterData.push(body);
    const obs: Observable<MeterData> = Observable.of(body);
    return obs;
  }
}
