import { Component, Inject, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MeterPoint, MeterData } from '@shared/models';
import { ICustomer, Customer } from '@shared/models/appModels';
import { MeterPointDataStoreService } from '@shared/services/dataStore/meterPointDataStore.service';

import { CustomerSegmentNames, CustomerSegmentType } from '@shared/types/modelTypes/customerTypes';
import { MeasurementTypeEnum, MeasurementTypeNames, MeterNodeTypeEnum, MeterNodeTypeNames, IParameters, ArrayedParameters, ParamsFormsFieldNames } from '@shared/types';
import { IMeterPoint } from '@shared/models/appModels/meterPoint.model';
import { MeterValidator } from '@shared/validators/async-meter-validator';
import { CommonAppDataService } from '@shared/services/commonAppData.service';
import { CustomerDataStoreService } from '@shared/services/dataStore/customerDataStore.service';
import { TimeGroupDataStoreService } from '@shared/services/dataStore/timeGroupDataStore.service';
import { TimeGroup } from '@shared/models/appModels/timeGroup.model';
import { ApplicationUnitsDataStoreService } from '@shared/services/dataStore/applicationUnitsDataStore.service';
import { MediaTypeParameter } from '@shared/models/appModels/MediaTypeParameter.mode';
import { environment } from '@env/environment';
import { UploadLocalisation as loc } from '../../../../upload.localisation';
import { map, filter, switchMap } from 'rxjs/operators';
import { CommunicationBetweenComponentsService } from '../../service/communicationBetweenComponents.service';
import { Subscription } from 'rxjs';
import { InteractiveTutorialService } from '@shared/services/interactiveTutorialService.service';
import { AssetUploadInteractiveTutorialService } from '../../../assetUploadInteractiveTutorialService.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'sv-client-data-preview-dialog',
  templateUrl: 'client-data-preview-dialog.component.html',
  styleUrls: ['client-data-preview-dialog.component.sass'],
})
export class ClientDataPreviewDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  readonly addMdIcon: string = 'add_box';
  readonly removeMdIcon: string = 'indeterminate_check_box';

  /* Local copy of name mappers */
  readonly selectSegments: typeof CustomerSegmentNames = CustomerSegmentNames;
  readonly selectedMeasurements: typeof MeasurementTypeNames = MeasurementTypeNames;
  readonly selectedMeterNode: typeof MeterNodeTypeNames = MeterNodeTypeNames;

  addCustomer = new FormControl(false);

  public name: string;
  public addAssetForm: FormGroup;
  public customerData: FormGroup;
  element: MeterPoint = undefined;
  mergeCustomer: { add: boolean, existing: boolean, customer?: Customer } = { add: false, customer: undefined, existing: false };
  meterPointParameters: MediaTypeParameter[] = [];
  fileMeterData$: Subscription;
  showPowerOrderedHint: boolean = false;
  readonly assetLabels = loc[environment.language].texts.asset.assetDataPreview;
  readonly measurementsLabels = loc[environment.language].texts.measurement;
  showTutFunc;

  constructor(@Inject(MAT_DIALOG_DATA) private data: { meterPoint: MeterPoint },
    public dialogRef: MatDialogRef<ClientDataPreviewDialogComponent>,
    private router: Router,
    private meterPointService: MeterPointDataStoreService,
    private customerService: CustomerDataStoreService,
    private imeGroupService: TimeGroupDataStoreService,
    private unitService: ApplicationUnitsDataStoreService,
    private cs: CommonAppDataService,
    private communicationBetweenCompServ: CommunicationBetweenComponentsService,
    private interactiveTutorialService: InteractiveTutorialService,
    private assetUploadInteractiveTutorialService: AssetUploadInteractiveTutorialService) {

    this.addAssetForm = new FormGroup({
      serialMeter: new FormControl('', [Validators.required], [MeterValidator.checkSerialNumberAvilibility(this.meterPointService)]),
      masterSerialMeter: new FormControl('', [], [MeterValidator.checkMeterPointExistance(this.meterPointService)]),
      address: new FormControl(''),
      selectedMeasurementTypeControl: new FormControl(this.selectedMeasurements[MeasurementTypeEnum.DEFAULT].id, Validators.required),
      selectedMeterNodeTypeControl: new FormControl(this.selectedMeterNode[MeterNodeTypeEnum.OTHER].id, Validators.required),
      isMeterBilled: new FormControl(true),
      isMeterActive: new FormControl(true),
      timeGroup: new FormControl(''),

      config: new FormGroup({
        maxRead: new FormControl('', [Validators.pattern(/^[0-9]*$/)]), // TODO: this does not allow for entering floating point values...
      }),

      optionalValues: new FormArray([])
    });

    this.customerData = new FormGroup({
      customerDenotation: new FormControl('', Validators.required),
      customerSegment: new FormControl(this.selectSegments[CustomerSegmentType.DEFAULT].id, Validators.required)
    });
  }

  ngOnInit() {
    this.fileMeterData$ = this.communicationBetweenCompServ.sendFileOperaionalDataInfo.subscribe(fileData => {
      if (fileData.length > 0) {
        this.showPowerOrderedHint = true;
      }
    });
    if (this.data.meterPoint) {
      this.element = this.data.meterPoint;
      this.address.setValue(this.element.meterPlacement);
      this.selectedMeasurementTypeControl.setValue(this.element.measurementType);
      this.serialMeter.setValue(this.element.serialNumber);
      this.serialMeter.disable();
      this.serialMeter.clearAsyncValidators();
      this.selectedMeterNodeTypeControl.setValue(this.element.meterPointType);
      this.isMeterBilled.setValue(this.element.isMeterBilled);
      if (this.element.idTimeGroup) {
        this.imeGroupService.getTimeGroupById(this.element.idTimeGroup, false)
          .subscribe((stg: TimeGroup) => {
            this.timeGroup.setValue(stg.timeGroupName);
          });
      }
      if (this.element.getCustomer()) {
        this.denotation.setValue(this.element.getCustomer().denotation, { emitEvent: false });
        this.selectedSegmentControl.setValue(this.element.getCustomer().segmentType);
        this.selectedSegmentControl.disable();
        this.addCustomer.setValue(true);
        this.mergeCustomer.add = true;
        this.mergeCustomer.existing = true;
        this.mergeCustomer.customer = this.element.getCustomer();
      }

      if (this.element.meterConfigMaxRead !== null) {
        this.element.meterConfigMaxRead = Math.round(this.element.meterConfigMaxRead);
      }
      this.unitService.getOperationalDataModel()
        .subscribe((mpp: MediaTypeParameter[]) => {
          this.meterPointParameters = mpp;
          this.element.convertParamsToArray(this.element.optionalValues)
            .forEach((optParam: ArrayedParameters) => {
              const matchedDescriptor = mpp.find(mp => mp.name === optParam.name);
              this.optionalValues.push(this.element.generateValueControl(optParam.name, optParam.value, matchedDescriptor));
              if (typeof (matchedDescriptor) !== 'undefined') {
                (this.optionalValues.controls[this.optionalValues.length - 1] as FormGroup).get('name').disable();
              }
            });
        });
    } else {
      /* No input element provided */
      this.optionalValues.push(MeterData.generateEmptyParamControl());
    }



    this.denotation.valueChanges.pipe(
      map<any, string>((v: any) => '' + v),
      filter((v: string) => (v.length > 0)),
      switchMap((denotation: string) => {
        return this.customerService.getSingleCustomerByName(denotation, true);
      }),
      ).subscribe((c: Customer) => {
        this.mergeCustomer.add = true;
        if (Object.keys(c).length !== 0) {
          this.mergeCustomer.existing = true;
          this.mergeCustomer.customer = c;
          this.customerData.get('customerSegment').setValue(c.segmentType);
          this.customerData.get('customerSegment').disable();
        } else {
          this.mergeCustomer.existing = false;
          this.customerData.get('customerSegment').enable();
        }
      });

    this.addCustomer.valueChanges
      .subscribe((v: boolean) => {
        if (v && v === true) {
          this.addAssetForm.addControl('customerData', this.customerData);
          this.customerData.get('customerDenotation').markAsTouched();
          this.customerData.get('customerDenotation').markAsDirty();
          this.customerData.get('customerDenotation').updateValueAndValidity();
          this.mergeCustomer.add = true;
        } else {
          this.addAssetForm.removeControl('customerData');
          this.mergeCustomer.add = false;
        }
      });
  }

  startInteractiveTutorial(){
    const steps = this.assetUploadInteractiveTutorialService.getAssetUploadAddNewAssetModalInteractiveTutorialSteps(this.optionalValues.controls.length);
    this.interactiveTutorialService.startInteractiveTutorial(steps);
  }

  ngAfterViewInit(){
    this.showTutFunc = setTimeout(() => this.startInteractiveTutorial(), 1500);
  }

  ngOnDestroy(): void {
    if (this.fileMeterData$) {
    this.fileMeterData$.unsubscribe();
    }
    clearTimeout(this.showTutFunc);
  }

  get serialMeter(): AbstractControl {
    return this.addAssetForm.get('serialMeter');
  }

  get address(): AbstractControl {
    return this.addAssetForm.get('address');
  }

  get selectedMeasurementTypeControl(): AbstractControl {
    return this.addAssetForm.get('selectedMeasurementTypeControl');
  }

  get selectedMeterNodeTypeControl(): AbstractControl {
    return this.addAssetForm.get('selectedMeterNodeTypeControl');
  }

  get isMeterBilled(): AbstractControl {
    return this.addAssetForm.get('isMeterBilled');
  }

  get isMeterActive(): AbstractControl {
    return this.addAssetForm.get('isMeterActive');
  }

  get optionalValues(): FormArray {
    return this.addAssetForm.get('optionalValues') as FormArray;
  }

  get timeGroup(): AbstractControl {
    return this.addAssetForm.get('timeGroup');
  }

  get denotation(): AbstractControl {
    return this.customerData.get('customerDenotation');
  }

  get selectedSegmentControl(): AbstractControl {
    return this.customerData.get('customerSegment');
  }

  get maxRead(): AbstractControl {
    return this.addAssetForm.get('config').get('maxRead');
  }

  dynamicFormNameValue(item: FormGroup, name: string): string {
    return item.get(name).value;
  }

  addOptionalValueField(): void {
    this.optionalValues.push(MeterPoint.generateEmptyParamControl());
  }

  deleteUserField(index: number): void {
    this.optionalValues.removeAt(index);
  }

  goClientData(): void {
    this.serialMeter.errors.asyncInvalid();
    this.router.navigate(['upload/client-data']);
    this.dialogRef.close();
  }

  onFormSubmit(event): void {

    const lmeter: IMeterPoint = {
      idMeterPoint: typeof (this.element) !== 'undefined' ? this.element.idMeterPoint : -1,
      idCustomer: undefined,
      serialNumber: this.serialMeter.value,
      isMeterBilled: this.isMeterBilled.value,
      // idTimeGroup: this.TimeGroup.value,
      measurementType: this.selectedMeasurementTypeControl.value,
      meterPointType: this.selectedMeterNodeTypeControl.value,
      utilityType: this.cs.getCurrentMediaType(),
      meterPlacement: this.address.value,
      active: this.isMeterActive.value,
      optionalValues: this.parseParams(),
      meterConfigMaxRead: this.maxRead.value,
    };
    let meter: MeterPoint;
    try {
      meter = new MeterPoint(lmeter);
      meter.timeGroupName = (this.timeGroup.value !== null && this.timeGroup.value !== '') ? this.timeGroup.value : undefined;
    } catch (e) {
      throw new Error('Unable to parse form values to create meterPoint instancde:' + JSON.stringify(lmeter));
    }

    if (this.mergeCustomer.add) {
      if (this.mergeCustomer.existing) {
        meter.idCustomer = this.mergeCustomer.customer.idCustomer;
        meter.setCustomer(this.mergeCustomer.customer);
      } else {
        const lcustomer: ICustomer = {
          idCustomer: -1,
          denotation: this.denotation.value,
          segmentType: this.selectedSegmentControl.value,
          active: true,
          idFileOperationalData: undefined
        };
        let customer: Customer;
        try {
          customer = new Customer(lcustomer);
          meter.setCustomer(customer);
          meter.idCustomer = -1;
        } catch (e) {
          throw new Error('Unable to parse form values to create customer instancde: ' + JSON.stringify(lcustomer));
        }
      }
    }
    this.dialogRef.close(meter);
  }

  private parseParams(): IParameters {
    this.optionalValues.controls.forEach((ctrlG: FormGroup) => {
      if (ctrlG.get('key').value !== null) { /* This is already existing parameter */
        const correcpondingDescription = this.meterPointParameters.find(mpp => mpp.name === ctrlG.get('key').value);
        if (typeof (correcpondingDescription) === 'undefined') { /* Parameter has no correspoding name/key in defined values */
          ctrlG.get('key').setValue(ctrlG.get('name').value);
        } else {
          ctrlG.get('key').setValue((correcpondingDescription).name);
        }
      } else { /* This is new parameter */
        /* Name reffers to already existing */
        const correcpondingDescription = this.meterPointParameters.find(mpp => mpp.description === ctrlG.get('name').value);
        if (typeof (correcpondingDescription) === 'undefined') { /* Such parameter does not exist */
          ctrlG.get('key').setValue(ctrlG.get('name').value);
        } else {
          ctrlG.get('key').setValue(correcpondingDescription.name);
        }
      }
    });
    return MeterPoint.parseParametersFromForm(this.optionalValues);
  }

  private assignKeysForNewParameters(cg: FormGroup,
    names: ParamsFormsFieldNames = { keyControlName: 'key', valueControlName: 'value', nameControlName: 'name' }) {

  }
}

