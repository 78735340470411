// Angular modules import
import { Injectable } from "@angular/core";
import { FormGroup, FormControl, FormBuilder } from "@angular/forms";

/* App components */
import { Schedule } from "@shared/models/appModels/schedule.model";
import {
  DateOffsets,
  EventType,
  EventStatus,
} from "@shared/types/modelTypes/eventType";
import { SchedulePrefixSeparator } from "@shared/types/modelTypes/scheduleTypes";

import { environment } from "@env/environment";

// External libraries
import * as moment from "moment";

/* RxJs Operators */
import "rxjs/add/operator/map";
import "rxjs/add/operator/toPromise";
import "rxjs/add/operator/concatMap";
import "rxjs/add/observable/forkJoin";
import "rxjs/add/observable/from";
import "rxjs/add/observable/of";
import { PositionEvent } from "@shared/models";
import { RestEnumMpapper } from "@shared/models/RestSupport";

export interface PositionEventControl {
  name: FormControl;
  date: FormControl;
  typeOf: FormControl;
  status: FormControl;
}

@Injectable()
export class ScheduleSupportService {
  debug: boolean = environment.debug;

  constructor(private fb: FormBuilder) {}

  createNewEventControlGroup(
    baseDate: moment.Moment,
    eventType: EventType = EventType.UPLOAD,
    config?: { offsetDates: boolean }
  ): FormGroup {
    let date = moment(baseDate);
    if (config?.offsetDates) {
      date.add(this.getOffsetForEvent(eventType), "days");
    }

    return this.fb.group({
      name: new FormControl(EventType[eventType]),
      date: new FormControl(date),
      typeOf: eventType,
      status:
        eventType === EventType.UPLOAD ? EventStatus.TODO : EventStatus.LOCKED,
    });
  }

  createEventControlGroupFromTemplate(
    templateEvent: PositionEvent,
    positionOrder?: number
  ): FormGroup {
    const typeMapper: RestEnumMpapper<typeof EventType> = new RestEnumMpapper<
      typeof EventType
    >();
    const stateMapper: RestEnumMpapper<
      typeof EventStatus
    > = new RestEnumMpapper<typeof EventStatus>();

    return this.fb.group({
      name: new FormControl(EventType[templateEvent.typeOf]),
      date: new FormControl(moment.utc(templateEvent.date)),
      typeOf: new FormControl(
        typeMapper.getEnumAsNumber(EventType, templateEvent.typeOf)
      ),
      status: new FormControl(
        stateMapper.getEnumAsNumber(EventStatus, templateEvent.status)
      ),
    });
  }

  getOffsetForEvent(eventType: EventType) {
    return DateOffsets[eventType];
  }

  /* Used in template */
  getScheduleName(
    schedule: Schedule,
    options?: { addPrefix: boolean }
  ): string {
    return options?.addPrefix
      ? `${schedule.name} ${SchedulePrefixSeparator}${schedule.scheduleNumber}`
      : `${schedule.name}`;
  }

  getEventTypeName(eventType: EventType): string {
    switch (eventType) {
      case EventType.UPLOAD:
        return $localize`:upload position event|Upload event name@@schedules/upload position event:Data upload`;
      case EventType.VALIDATION:
        return $localize`:validation position event|Validation event name@@schedules/validation position event:Data validation`;
      case EventType.ANALYSIS:
        return $localize`:analysis position event|Analysis event name@@schedules/analysis position event:Data analysis`;
      case EventType.EXPORT:
        return $localize`:export position event|Data export event name@@schedules/export position event:Data export`;
      default:
        return "";
    }
  }

  getEventStatusName(eventStatus: EventStatus): string {
    switch (eventStatus) {
      case EventStatus.UNDEFINED:
        return $localize`:position event status undefined|A position event status name@@schedules/position event status undefined:Undefined`;
      case EventStatus.TODO:
        return $localize`:position event status todo|A position event status name@@schedules/position event status todo:Todo`;
      case EventStatus.IN_PROGRESS:
        return $localize`:position event status in progress|A position event status name@@schedules/position event status in progress:In progress`;
      case EventStatus.DONE:
        return $localize`:position event status completed|A position event status name@@schedules/position event status completed:Completed`;
      case EventStatus.CLOSED:
        return $localize`:position event status closed|A position event status name@@schedules/position event status closed:Closed`;
      case EventStatus.LOCKED:
        return $localize`:position event status locked|A position event status name@@schedules/position event status locked:Locked`;
    }
  }
}
