import { IApiEndpointConfig, GenericHttpParams } from '@shared/types/http/httpType';
import { AnalysisStatus } from '@shared/models/appModels/analysisStatus.model';

export const analysisStatusApiConf: IApiEndpointConfig = {
  rootApiPath: 'process/algorithm/',
  paths: [
    {/* 1 */
      swaggerId: 'getAnalysisProcessState',
      url: 'position/{idPosition}',
      pathParams: [
        { name: 'idPosition', pattern: '{idPosition}' }
      ],
      responseIsArray: false,
    },
    {/* 2 */
      swaggerId: 'postAnalysisProcessState',
      url: 'position/{idPosition}',
      pathParams: [
        { name: 'idPosition', pattern: '{idPosition}' }
      ],
      responseIsArray: false,
    },
    {/* 3 */
      swaggerId: 'deleteAnalysisProcessState',
      url: 'position/{idPosition}',
      pathParams: [
        { name: 'idPosition', pattern: '{idPosition}' }
      ],
      responseIsArray: false,
    },
  ],
};

export interface AnalysisStatusHttpParamG extends GenericHttpParams<AnalysisStatus> {
  queryParams?: {
    algorithmType?: string;
    svUtilityType?: string
  };
  path?: {
    idPosition?: string;
  };
}

