
import { environment } from '@env/environment';
import { UtilityTypeEnum } from '@shared/types/utilityType';

export enum TableElementType {
  'BASIC' = 0,
  'SPAN' = 1,
  'DATE' = 2,
  'FORM' = 3,
}

export interface DynamicColumn<T> {
  sort: boolean;
  columnDef: string;
  header: string;
  group?: number;
  type: string;
  mediaType?: UtilityTypeEnum;
  valueKey: string;
  classType(element: T): any;
  generateCellContent(element: T): any;
}

export class DynamicColumnsHandler<T> {
  preDynamicStaticDef: string[];
  dynamicColumns: DynamicColumn<T>[];
  postDynamicStaticDef: string[];
  debug = environment.debug;

  constructor(preDynamicStaticDef: Array<string> = [], dynamicColumns: DynamicColumn<T>[], postDynamicStaticDef: Array<string> = []) {
    this.dynamicColumns = dynamicColumns;
    this.preDynamicStaticDef = preDynamicStaticDef;
    this.postDynamicStaticDef = postDynamicStaticDef;
  }

  getDynamicColumns(): DynamicColumn<T>[] {
    return this.dynamicColumns;
  }

  getAllColumnDef(): string[] {
    const dynamicColumsDef = this.dynamicColumns.map(x => x.columnDef);
    return this.preDynamicStaticDef.concat(dynamicColumsDef.concat(...this.postDynamicStaticDef));
  }

  getDynamicColumnsDef(): string[] {
    return this.dynamicColumns.map((dc) => dc.columnDef);
  }

  getPreDynamicDef(): string[] {
    return this.preDynamicStaticDef;
  }

  getPostDynamicDef(): string[] {
    return this.postDynamicStaticDef;
  }

  addDynamicColumn(item: DynamicColumn<T>): void {
    if (!(this.dynamicColumns).find(x => x.columnDef === item.columnDef)) {
      this.dynamicColumns.push(item);
      if (this.debug) { console.log('----------addElement ', item); }
    } else {
      if (this.debug) { console.log('----------addElement - element already exist ', item); }
    }
  }

  addStaticElementBeforeColumns(item: string): void {
    if (!(this.preDynamicStaticDef).find(x => x === item)) {
      this.preDynamicStaticDef.push(item);
    } else {
      if (this.debug) { console.log('----------addStaticElementBeforeColumns ', item); }
    }
  }

  addStaticElementAfterColumns(item: string) {
    if (!(this.postDynamicStaticDef).find(x => x === item)) {
      this.postDynamicStaticDef.push(item);
    } else {
      if (this.debug) { console.log('----------addStaticElementAfterColumns ', item); }
    }
  }

  removeElement(item: string) {
    const is = this.preDynamicStaticDef.findIndex(x => x === item);
    if (environment.debug) { console.log('----------removeElementis ', is); }
    if (!is) {
      this.preDynamicStaticDef.splice(is);
    }
  }

  removerGroup(groupId: number): void {
    if (this.dynamicColumns.find(x => x.group === groupId)) {
      this.dynamicColumns = this.dynamicColumns.filter(x => x.group !== groupId);
    }
  }

  removeAllDynamicDef(): void {
    this.dynamicColumns = [];
  }

  getElementType(): TableElementType {
    return;
  }

}
