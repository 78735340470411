import { Component, OnInit, ChangeDetectorRef, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MatCheckboxChange } from '@angular/material/checkbox';

/* RxJS */
import { Observable, Subscription, of, throwError, Subject } from 'rxjs';
import { distinct, map, concatMap, tap, takeWhile, switchMap, debounceTime, takeUntil } from 'rxjs/operators';

/* Application services */
import { CommonAppDataService } from '@shared/services/commonAppData.service';
import { FileMeterDataStoreService } from '@shared/services/dataStore/fileMeterDataDataStore.service';
import { PositionDataStoreService } from '@shared/services/dataStore/positionDataStore.service';
import { VeeRulesDataStoreService } from '@shared/services/dataStore/veeRulesDataStore';
import { VeeStatusDataStoreService } from '@shared/services/dataStore/veeProcessDataStore.service';

/* Application datatypes */
import { Position } from '@shared/models/appModels/position.model';
import { FileMeterData } from '@shared/models/appModels/fileMeasurementData.model';
import { IVeeRule, VeeRule } from '@shared/models/appModels/VeeRule.model';

/* Types */
import { PositionTransitions, EventType, EventStatus, UtilityTypeEnum, ValidationResultEnum } from '@shared/types';
import {
  VeeProcessStageEnum, VeeProcessStatusEnum,
  VeeProcessStatusMapper, VeeProcessFailureEnum, VeeProcessFailureMapper, veeProcessNames, veeProcessNameMapper
} from '@shared/types/modelTypes/veeProcessTypes';
import { IPolledData } from '@shared/types/applicationTypes';
/* Models */
import { VeeStatus, IVeeRequest } from '@shared/models/appModels/VeeStatus.model';

/* Environment */
import { environment } from '@env/environment';

/* Localisation */
import { DataValidationLocalisation as loc } from '../data-validation-localisation';
import { RestEnumMpapper } from '@shared/models/RestSupport';
import { VeeProcessErorr } from '@shared/models/RestSupport/VeeProcessError.model';
import { VeeRuleTypeEnum } from '@shared/types/modelTypes/veeRuleTypes';
import { DataValidationDialogsService } from './service/data-validation-dialog.service';
import { ValidateMeasurement, IValidateMeasurement } from '@shared/models/appModels/validateMeasurement.mode';
import { typesLocalisation } from '@shared/types/localisation';
import { MeasurementData, PositionEvent } from '@shared/models';
import { DataValidationTranslationsHelper } from './service/data-validation-translations-helper.service';
import { HttpErrorResponse } from '@angular/common/http';
import { InteractiveTutorialService } from '@shared/services/interactiveTutorialService.service';
import { DataValidationInteractiveTutorialService } from '../data-validation-interactive-tutorial-steps';

interface UpdateList {
  listUpdate: boolean;
  requestUpdate: boolean;
}

interface ButtonState {
  text: string;
  isVisible: boolean;
  isDisabled: boolean;
}

interface VeeRulesPanels {
  panelOpenStateValidation: boolean;
  panelOpenStateEstimation: boolean;
}

enum ButtonsStateEnum {
  'EMPTY' = 0,
  'COMPLETE',
  'PENDING',
  'NO_DATA',
  'FAILED',
  'ERROR',
  'FREEZED',
  'IMPORT'
}

@Component({

  selector: 'sv-data-validation',
  // changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'data-validation-page.component.html',
  styleUrls: ['data-validation-page.component.sass']
})
export class DataValidationPageComponent implements OnInit, OnDestroy, AfterViewInit {

  private static statusMapper: RestEnumMpapper<typeof VeeProcessStageEnum> = new RestEnumMpapper<typeof VeeProcessStageEnum>();

  VeeProcessStageEnum: typeof VeeProcessStageEnum = VeeProcessStageEnum;
  VeeProcessStatusEnum: typeof VeeProcessStatusEnum = VeeProcessStatusEnum;
  VeeRuleTypeEnum: typeof VeeRuleTypeEnum = VeeRuleTypeEnum;

  private longPollSubs$: Subscription;
  readonly debugMode: boolean = environment.debug;

  readonly restartButtonTxt: string = $localize`:@@data validation/restart button text:Clear validation data`;
  readonly inProgress = $localize`:@@data validation/vee process status mapper/status name/in progress:In progress`;
  readonly preprocessingData = $localize`:@@data validation/vee process steps list/stage name/preprocess:Preprocessing data`;
  rulesMdIcon = 'swap_horiz';

  veeProcessSteps: { stage: string, status: string, statusEnum: VeeProcessStatusEnum, positionId: number }[] = [];
  lastQueryState: VeeStatus;

  toggleValidation: boolean = false;
  showProcessProgression: boolean = false;

  public positionId: number;
  public scheduleId: number;

  validationButtonState: ButtonState;
  seeResultsButtonState: ButtonState;
  uploadButton: ButtonState;
  maxId: number;
  maxPriority: number;

  panelsState: VeeRulesPanels = { panelOpenStateValidation: true, panelOpenStateEstimation: false };

  listRulesValidation: IVeeRule[] = [];
  listRulesEstimation: IVeeRule[] = [];
  lastPolledState: IPolledData<VeeStatus>;
  showTutorialSubscription: Subscription;
  private readonly destroy$ = new Subject();

  showFreezeTooltip: boolean = false;
  showImportTooltip: boolean = false;
  header = $localize`:data validation label|Data validation header@@data validation/widget/header:Data Validation`;
  tooltip: string;
  countMeasurementData: number;
  countNotValidatedMeasurementData: number;
  validationLabel = $localize`:@@data validation/vee process name mapper/stage name/validation:Validating data`;
  constructor(
    private positionDataStoreService: PositionDataStoreService,
    private veeRuleService: VeeRulesDataStoreService,
    private veeProcessService: VeeStatusDataStoreService,
    private fileMeterDataService: FileMeterDataStoreService,
    private dataValidationTranslationsHelper: DataValidationTranslationsHelper,
    private cs: CommonAppDataService,
    private router: Router,
    private route: ActivatedRoute,
    private dataValidationService: DataValidationDialogsService,
    private interactiveTutorialService: InteractiveTutorialService,
    private dataValidationInteractiveTutorial: DataValidationInteractiveTutorialService,
  ) {
    this.positionId = undefined;
    this.lastQueryState = undefined;
    this.validationButtonState = { isDisabled: true, isVisible: false, text: $localize`:@@data validation/validation button text/todo:Start validation` };
    this.seeResultsButtonState = { isDisabled: true, isVisible: false, text: $localize`:@@data validation/results button text/show:Show validation results` };
    this.uploadButton = { isDisabled: true, isVisible: false, text: $localize`:@@data validation/upload button text:Upload data for this position` };
  }

  ngOnInit() {
    this.getSortedVeeRules();
    this.showProcessProgression = false;
    this.showTutorialSubscription = this.interactiveTutorialService.showTutorialSubject.pipe(debounceTime(1500)).subscribe(isActive => {
      if (this.interactiveTutorialService.getActiveComponent(this.route.routeConfig.path) && isActive) {
        this.startInteractiveTutorial();
      }
    });
  }

  ngAfterViewInit() {

  }

  getSortedVeeRules() {
    this.veeRuleService.getVeeRules()
      .pipe(
        map<VeeRule[], VeeRule[]>((rules) => {
          return rules.sort((a, b) => a.priority - b.priority);
        }))
      .subscribe((veeRules: VeeRule[]) => {
        const list = veeRules.slice(0).filter(v => (v.media_type === UtilityTypeEnum[this.cs.getCurrentMediaType()] ||
          v.media_type === UtilityTypeEnum[UtilityTypeEnum.OTHER]));
        this.listRulesValidation = list.filter(v => v.rule_type === VeeRuleTypeEnum[VeeRuleTypeEnum.VALIDATION]);
        this.listRulesEstimation = list.filter(v => v.rule_type === VeeRuleTypeEnum[VeeRuleTypeEnum.ESTIMATION]);
        list.sort((a, b) => b.id - a.id);
        this.maxId = list[0].id;
        list.sort((a, b) => b.priority - a.priority);
        this.maxPriority = list[0].priority;
      },
        (e) => {
          console.error('Unable to fetch rules');
          console.error(e);
          this.listRulesValidation = (this.listRulesValidation.length === 0) ? [] : this.listRulesValidation;
          this.listRulesEstimation = (this.listRulesEstimation.length === 0) ? [] : this.listRulesEstimation;
        },
        () => {
          if (this.debugMode) { console.log('Validation/Estimation rules fetch complete'); }
        });
  }

  setValidationFailed(err: any) {
    if (this.lastPolledState && this.lastPolledState.data && this.lastPolledState.data.stage == VeeProcessStageEnum.USAGES_CALCULATION) {
      const request: IVeeRequest = {
        requestedStage: VeeProcessStageEnum.VALIDATION,
        requestedStatus: VeeProcessStatusEnum.FAILED
      };
      this.veeProcessService.setVeeStateForPosition(this.positionId, new VeeStatus(request), false).subscribe(v => {
        this.veeProcessSteps.push({
          stage: this.validationLabel,
          status: typesLocalisation.VeeProcessStatusMapper[environment.language].statusName.FAILED,
          statusEnum: VeeProcessStatusEnum.FAILED,
          positionId: this.positionId
        });
      });
    }
    return this.positionDataStoreService.getPositionById(this.positionId)
      .pipe(
        concatMap((p: Position) => {
          return this.setPositionValidationEvent(p, PositionTransitions.VALIDATION_FAILED);
        })).subscribe((p: Position) => {
          this.setValidationButtonText(p);
          this.setButtonsState(ButtonsStateEnum.FAILED);
        });

  }

  onValidationAction(): void {
    /* Hide rules screens */
    this.panelsState.panelOpenStateValidation = false;
    this.panelsState.panelOpenStateEstimation = false;
    this.toggleValidation = false;
    this.seeResultsButtonState.text = $localize`:@@data validation/results button text/show:Show validation results`;
    this.veeProcessService.getVeeStateForPosition(this.positionId, true)
      .pipe(
        concatMap<VeeStatus, Observable<Position>>(status => {
          this.setButtonsState(ButtonsStateEnum.PENDING);
          this.veeProcessSteps = [];
          this.veeProcessSteps.push({ stage: this.preprocessingData, status: this.inProgress, statusEnum: VeeProcessStatusEnum.IN_PROGRESS, positionId: this.positionId });
          this.showProcessProgression = true;
          this.lastQueryState = undefined;
          return this.getInitialTriggerObservable(status);
        }),
        /* Request init step for Vee Process */
        concatMap<Position, Observable<VeeStatus>>((p: Position) => {
          this.setValidationButtonText(p);
          const request: IVeeRequest = {
            requestedStage: VeeProcessStageEnum.PREPROCESS,
            requestedStatus: VeeProcessStatusEnum.IN_PROGRESS
          };
          return this.veeProcessService.setVeeStateForPosition(this.positionId, new VeeStatus(request));
        }),
        /* After - start polling state and wait for completeness */
        concatMap<VeeStatus, Observable<IPolledData<VeeStatus>>>((stepResponse: VeeStatus) => {
          return this.startPollingSequence();
        }))
      .subscribe((updatedPos: IPolledData<VeeStatus>) => {
        this.lastPolledState = updatedPos;
      },
        (err: HttpErrorResponse) => {
          //   this.setValidationFailed(err);
        },
        () => {
          this.setValidationFinish(PositionTransitions.VALIDATION_FINISH);
        });
  }

  private getInitialTriggerObservable(veeStatus: VeeStatus): Observable<Position> {
    let retObs: Observable<Position>;
    if (!this.isProcessEmpty(veeStatus)) {
      retObs = this.clearValidationData()
        .pipe(
          concatMap<VeeStatus, Observable<Position>>((vs: VeeStatus) => {
            return this.positionDataStoreService.getPositionById(this.positionId).pipe(
              concatMap<Position, Observable<Position>>((p: Position) => {
                return this.setPositionValidationEvent(p, PositionTransitions.VALIDATION_START);
              }));
          }));
    } else {
      retObs = this.positionDataStoreService.getPositionById(this.positionId)
        .pipe(
          concatMap<Position, Observable<Position>>((p: Position) => {
            return this.setPositionValidationEvent(p, PositionTransitions.VALIDATION_START);
          }));
    }
    return retObs;
  }

  onToggleShowResults() {
    this.veeProcessService.getVeeStateForPosition(this.positionId, true)
      .subscribe((vs: VeeStatus) => {
        if (this.isWholeProcessComplete(vs) || this.isProcessFreezed(vs)) {
          this.toggleValidation = !this.toggleValidation;
          this.seeResultsButtonState.text = this.toggleValidation ?
            $localize`:@@data validation/results button text/hide:Hide validation results` :
            $localize`:@@data validation/results button text/show:Show validation results`;
        } else {
          throw new Error('Requested results from incomplete VeeProcess');
        }
      });
  }

  onSchedulePositionSelect(e: { idSchedule?: number, idPosition?: number }) {
    if (this.longPollSubs$) {
      this.longPollSubs$.unsubscribe();
    }
    if (e.idPosition && e.idSchedule) {
      this.toggleValidation = false;
      this.positionId = e.idPosition;
      this.scheduleId = e.idSchedule;
      this.seeResultsButtonState.text = $localize`:@@data validation/results button text/show:Show validation results`;
      this.veeProcessService.getVeeStateForPosition(this.positionId, true).subscribe(v => {
        if (v.stage === VeeProcessStageEnum.VALIDATION && v.status === VeeProcessStatusEnum.IN_PROGRESS) {
          this.positionDataStoreService.getPositionMeasurementDataCount(this.positionId).subscribe(count => {
            this.countMeasurementData = count;
          });
          this.positionDataStoreService.getPositionMeasurementDataCount(this.positionId, { validationResult: ValidationResultEnum.UNDEFINED }).subscribe(count => {
            this.countNotValidatedMeasurementData = count;
          });
        } else {
          this.countMeasurementData = 0;
          this.countNotValidatedMeasurementData = 0;
        }
        this.initVeeProcessStatusProgression(v);
      });
    } else { /* Both schedule & position selection are required */
      this.setButtonsState(ButtonsStateEnum.NO_DATA);
      this.toggleValidation = false;
    }
  }

  onUpload() {
    this.positionDataStoreService.getPositionById(this.positionId, false)
      .subscribe((pos: Position) => {
        this.router.navigate(['/upload/readings'], { queryParams: { schedule: pos.idSchedule, position: pos.idPosition } });
      });
  }

  private setValidationButtonText(position: Position, veeStatus?: VeeStatus) {
    switch (position.events[EventType.VALIDATION].status) {
      case EventStatus.TODO: {
        this.validationButtonState.text = $localize`:@@data validation/validation button text/todo:Start validation`;
        break;
      }
      case EventStatus.IN_PROGRESS: {
        if (veeStatus && veeStatus.stage === VeeProcessStageEnum.VALIDATION && veeStatus.status === VeeProcessStatusEnum.DONE) {
          this.validationButtonState.text = $localize`:@@data validation/validation button text/not freezed:Restart validation`;
        }
        break;
      }
      case EventStatus.DONE: {
        this.validationButtonState.text = $localize`:@@data validation/validation button text/done:Restart validation`;
        break;
      }
      default: {
        this.validationButtonState.text = $localize`:@@data validation/validation button text/other:Start validation`;
        break;
      }
    }
  }

  private startInteractiveTutorial() {
    const steps = this.dataValidationInteractiveTutorial.getDataValidationPageInteractiveTutorialSteps(this.uploadButton.isVisible, this.seeResultsButtonState.isVisible, this.validationButtonState.isVisible);
    this.interactiveTutorialService.startInteractiveTutorial(steps);
  }

  private initVeeProcessStatusProgression(status: VeeStatus) {
    this.positionDataStoreService.getPositionById(this.positionId, true)
      .pipe(
        tap(position => {
          this.showFreezeTooltip = false;
          this.setValidationButtonText(position);
          this.setButtonsState(ButtonsStateEnum.NO_DATA);
        }))
      .subscribe((p: Position) => {
        if (this.isProcessEmpty(status)) {
          this.positionDataStoreService.getPositionMeasurementData(this.positionId, { limit: 50, offset: 0 }, true)
            .subscribe((measurementDefinitions: MeasurementData[]) => {
              var positionEvent = p.events.find((p: PositionEvent) => p.typeOf === EventType.UPLOAD && p.status === EventStatus.DONE)
              if (measurementDefinitions.length === 0) {
                this.setState(ButtonsStateEnum.NO_DATA, false, true);
                this.uploadButton.isVisible = true;
                this.uploadButton.isDisabled = false;
              } else {
                if (positionEvent != undefined) {
                  this.setState(ButtonsStateEnum.EMPTY, false, true);
                  this.setButtonsState(ButtonsStateEnum.EMPTY);
                } else {
                  this.setState(ButtonsStateEnum.NO_DATA, false, true);
                  this.setButtonsState(ButtonsStateEnum.IMPORT);
                }

              }
              this.clearProgressionList();
            });
        } else if (this.isWholeProcessComplete(status)) {
          this.setState(ButtonsStateEnum.COMPLETE, true, false, p, status);
        } else if (this.isProcessFreezed(status)) {
          this.setState(ButtonsStateEnum.FREEZED, true, false, p, status);
          this.showFreezeTooltip = true;
        } else if(this.isProcessFailed(status)){
          this.setButtonsState(ButtonsStateEnum.FAILED);
          this.hendlePendingValidation(status);
        }
        else{
          this.setButtonsState(ButtonsStateEnum.PENDING);
          this.hendlePendingValidation(status);
        }
      },
        (err) => {
          // this.setButtonsState(ButtonsStateEnum.E)
        });
  }

  setState(buttonState: any, showProcess: boolean, flag: boolean, p?: Position, status?: VeeStatus) {
    if (flag) {
      this.setButtonsState(buttonState);
      this.showProcessProgression = showProcess;
    } else {
      this.setButtonsState(buttonState);
      this.setValidationButtonText(p, VeeStatus.generateValidationDoneState());
      this.buildProgressionList(status);
      this.showProcessProgression = showProcess;
    }
  }

  private clearValidationData(): Observable<VeeStatus> {
    return this.veeProcessService.clearVeeStateForPosition(this.positionId);
  }

  private setPositionValidationEvent(p: Position, state: PositionTransitions): Observable<Position> {
    p.activeScheduler = false;
    const pos = Position.positionStateMachine(p, state);
    return this.positionDataStoreService.updatePosition(pos, { emitEvent: false });
  }

  private hendlePendingValidation(status: VeeStatus) {

    this.buildProgressionList(status);
    this.showProcessProgression = true;

    /* Vee process is pending for this position, request polling changes */
    this.longPollSubs$ = this.startPollingSequence()
      .subscribe((polledState) => {
        console.log(polledState);
      },
        (err) => {
          // this.setValidationFailed(err);
        },
        () => {
          this.setValidationFinish();
        });
  }

  setValidationFinish(state?: any) {
    console.log('Vee process Complete');
    // this.buildProgressionList(this.lastQueryState);
    this.positionDataStoreService.getPositionById(this.positionId)
      .pipe(
        concatMap<Position, Observable<Position>>((p: Position) => {
          return this.setPositionValidationEvent(p, PositionTransitions.VALIDATION_FINISH);
        }))
      .subscribe(p => {
        state !== undefined ? this.setValidationButtonText(p, state) : this.setValidationButtonText(p);
        this.setButtonsState(ButtonsStateEnum.COMPLETE);
      });
  }

  private startPollingSequence(): Observable<any> {
    let updates: UpdateList = { listUpdate: false, requestUpdate: false };
    let done: boolean;
    return this.veeProcessService.longPollingState(this.positionId, 1000, { sequence: true })
      .pipe(
        distinct(), concatMap<IPolledData<VeeStatus>, any>((state: IPolledData<VeeStatus>) => {
          if (this.positionId !== state.positionId) {
            return throwError(new Error('oops!'))
          }
          return of(state);
        }),
        takeUntil(this.destroy$),
        tap((distinctState: IPolledData<VeeStatus>) => {
          this.countNotValidatedMeasurementData = distinctState.countNotValidatedMeasurementData;
          updates = this.setLastQueryState(distinctState.data);
          this.buildProgressionList(distinctState.data);
          if (this.isProcessFailed(distinctState.data) === true) {
            /* TODO: get real erorr reason */
            throw new VeeProcessErorr(
              `Vee process failed due to reason: ${VeeProcessFailureMapper[VeeProcessFailureEnum.OTHER].name}`,
              VeeProcessFailureEnum.OTHER);
          }
        }),
        takeWhile((state: IPolledData<VeeStatus>) => {
          done = this.isWholeProcessComplete(state.data);
          return !done;
        }),
        concatMap<IPolledData<VeeStatus>, any>((state: IPolledData<VeeStatus>) => {
          if (updates.requestUpdate) {
            const request: IVeeRequest = {
              requestedStage: this.generateNextVeeStep(state.data),
              requestedStatus: VeeProcessStatusEnum.IN_PROGRESS
            };
            if (this.debugMode) { console.log('Requesting new state'); }
            if (this.generateNextVeeStep(state.data) === VeeProcessStageEnum.VALIDATION) {
              return this.veeProcessService.setVeeStateForPosition(this.positionId, new VeeStatus(request), false).pipe(
                concatMap<VeeStatus, Observable<VeeStatus>>(v => {
                  return this.startValidation(state);
                }));
            } else {
              return this.veeProcessService.setVeeStateForPosition(this.positionId, new VeeStatus(request), false)
                .pipe(
                  concatMap<VeeStatus, Observable<IPolledData<VeeStatus>>>(posStatus => {
                    return of({ data: posStatus, pollTime: state.pollTime, positionId: this.positionId } as IPolledData<VeeStatus>);
                  }));
            }

          } else {
            return of(state);
          }
        }));
  }

  private startValidation(state: IPolledData<VeeStatus>) {
    let ids = [];
    const list = this.listRulesValidation.filter(r => r.enabled);
    list.forEach(element => {
      ids.push(element.id);
    });
    this.positionDataStoreService.getPositionMeasurementDataCount(this.positionId).subscribe(count => {
      this.countMeasurementData = count;
    });
    const validateMeasurement: IValidateMeasurement = { position_id: this.positionId, meter_point_id: 'all', validation_rule_ids: ids };
    return this.veeProcessService.postValidationStateForPosition(new ValidateMeasurement(validateMeasurement))
      .switchMap<ValidateMeasurement, VeeStatus>(d => {
        return this.veeProcessService.getVeeStateForPosition(this.positionId, false)
          .pipe(
            switchMap<VeeStatus, Observable<IPolledData<VeeStatus>>>(posStatus => {
              return of({ data: posStatus, pollTime: state.pollTime, positionId: this.positionId } as IPolledData<VeeStatus>);
            }));
      });
  }

  private isWholeProcessComplete(status: VeeStatus): boolean {
    return status.stage === VeeProcessStageEnum.VALIDATION && status.status === VeeProcessStatusEnum.DONE;
  }

  private isProcessFreezed(status: VeeStatus): boolean {
    return status.stage === VeeProcessStageEnum.FREEZE && status.status === VeeProcessStatusEnum.DONE;
  }

  private isProcessFailed(status: VeeStatus): boolean {
    return status.status === VeeProcessStatusEnum.FAILED;
  }

  private generateNextVeeStep(status: VeeStatus): VeeProcessStageEnum {
    return DataValidationPageComponent.statusMapper.getEnumAsNumber(VeeProcessStageEnum, status.stage) + 1;
  }

  private clearProgressionList() {
    this.veeProcessSteps = [];
    this.showProcessProgression = false;
  }

  private buildProgressionList(status: VeeStatus) {
    this.veeProcessSteps = [];
    veeProcessNames()
      .filter(proc => proc.isPrintable)
      .forEach((p, idx) => {
        if (idx <= status.stage) {
          const veeProc = veeProcessNameMapper(idx);
          this.veeProcessSteps.push({
            stage: veeProc.name,
            status: this.handleStatus(status, veeProc.id),
            statusEnum: this.handleStatusEnum(status, veeProc.id),
            positionId: this.positionId
          });
        }
      });
  }

  private handleStatus(currentVeeStatus: VeeStatus, checkedState: VeeProcessStageEnum): string {
    return currentVeeStatus.stage === checkedState ?
      VeeProcessStatusMapper[currentVeeStatus.status].name :
      VeeProcessStatusMapper[VeeProcessStatusEnum.DONE].name;
  }

  private handleStatusEnum(currentVeeStatus: VeeStatus, checkedState: VeeProcessStageEnum): VeeProcessStatusEnum {
    return currentVeeStatus.stage === checkedState ? currentVeeStatus.status as VeeProcessStatusEnum : VeeProcessStatusEnum.DONE;
  }

  private isProcessEmpty(status: VeeStatus): boolean {
    return Object.keys(status).length === 0;
  }

  private setLastQueryState(state: VeeStatus): UpdateList {
    let listUpdateRequired: boolean = false;
    let requestUpdateRequired: boolean = false;
    if (typeof (this.lastQueryState) === 'undefined' || this.lastQueryState !== state) {
      this.lastQueryState = state;
      if (this.lastQueryState.status === VeeProcessStatusEnum.DONE) {
        requestUpdateRequired = true;
      }
      listUpdateRequired = true;
    } else {
      /* State did not changed since last poll */
    }
    return { listUpdate: listUpdateRequired, requestUpdate: requestUpdateRequired };
  }

  onRuleClick($event: MatCheckboxChange, rule: VeeRule) {
    if (rule.media_type !== UtilityTypeEnum[UtilityTypeEnum.OTHER] && rule.media_type !== UtilityTypeEnum[this.cs.getCurrentMediaType()]) {
      rule.media_type = UtilityTypeEnum[rule.media_type];
    }
    rule.enabled = $event.checked;
    this.veeRuleService.putRuleById(rule.id, rule).subscribe();
  }

  onRuleDrop($event: MatCheckboxChange, item: VeeRule, ruleType: VeeRuleTypeEnum, idx: number) {
    console.log($event);
  }

  setButtonsState(state: ButtonsStateEnum) {
    switch (state) {
      case ButtonsStateEnum.EMPTY:
      case ButtonsStateEnum.FAILED: {
        this.showImportTooltip = false;
        this.validationButtonState.isVisible = true;
        this.validationButtonState.isDisabled = false;

        this.seeResultsButtonState.isVisible = true;
        this.seeResultsButtonState.isDisabled = true;

        break;
      }
      case ButtonsStateEnum.COMPLETE: {
        this.showImportTooltip = false;
        this.validationButtonState.isVisible = true;
        this.validationButtonState.isDisabled = false;

        this.seeResultsButtonState.isVisible = true;
        this.seeResultsButtonState.isDisabled = false;

        break;
      }
      case ButtonsStateEnum.IMPORT:
        this.showImportTooltip = true;
      case ButtonsStateEnum.PENDING:
      case ButtonsStateEnum.ERROR: {
        this.validationButtonState.isVisible = true;
        this.validationButtonState.isDisabled = true;
        this.seeResultsButtonState.isVisible = true;
        this.seeResultsButtonState.isDisabled = true;
        break;
      }
      case ButtonsStateEnum.NO_DATA: {
        this.showImportTooltip = false;
        this.validationButtonState.isVisible = false;
        this.validationButtonState.isDisabled = false;

        this.seeResultsButtonState.isVisible = false;
        this.seeResultsButtonState.isDisabled = true;

        this.uploadButton.isVisible = false;

        break;
      }

      case ButtonsStateEnum.FREEZED: {
        this.showImportTooltip = false;
        this.validationButtonState.isVisible = true;
        this.validationButtonState.isDisabled = true;

        this.seeResultsButtonState.isVisible = true;
        this.seeResultsButtonState.isDisabled = false;

        break;
      }
    }
  }

  createRule(veeRuleTemplate: VeeRule = null) {
    this.dataValidationService.invokeCreateRuleModal()
      .subscribe((newVeeRule: VeeRule) => {
        if (typeof (newVeeRule) !== 'undefined') {
          newVeeRule.id = this.maxId + 1;
          newVeeRule.priority = this.maxPriority + 1;
          this.veeRuleService.postVeeRules(newVeeRule)
            .subscribe(saved => {
              this.maxId = newVeeRule.id;
              this.maxPriority = saved.priority;
              this.getSortedVeeRules();
            },
              err => {
                console.log(err);
              });
        }
      });
  }

  onDataDrop($event: any, rule: VeeRule, type: VeeRuleTypeEnum, prio: number) {

    let newPrio;
    if (type === VeeRuleTypeEnum.ESTIMATION) {
      newPrio = this.listRulesEstimation.length - prio;
    } else if (type === VeeRuleTypeEnum.VALIDATION) {
      newPrio = this.listRulesValidation.length - prio;
    }
    rule.priority = newPrio;
    this.veeRuleService.putRuleById(rule.id, rule)
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    try {
      this.longPollSubs$.unsubscribe();
    } catch (e) {
    }
    if (this.showTutorialSubscription) {
      this.showTutorialSubscription.unsubscribe();
    }
  }

  getButtonTooltip() {
    if (this.showFreezeTooltip)
      return $localize`:@@data validation/tooltitle:Validation data are already frozen`;
    if (this.showImportTooltip) {
      return $localize`:@@data validation/import:The data is being imported to the position`;
    }
  }

}
