<div>
	<mat-table *ngIf="!dataLoading; else spinner" id="readingsPreview{{detail.idMeterPoint}}" [dataSource]="dataSource" class="scroll">

		<ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
			<mat-header-cell *matHeaderCellDef> <span
					*ngIf="column!==ValidationDetailColumn.SELECT">{{getColumnName(column)}} </span>
				<mat-checkbox *ngIf="column===ValidationDetailColumn.SELECT" (change)="selectUnselectAll()"
					[checked]="selectAll" [disabled]="dataSource.data.length===0"
					i18n="@@data validation/detail/select all">Select all</mat-checkbox>
			</mat-header-cell>
			<mat-cell *matCellDef="let element; let i=index">
				<span *ngIf="column===ValidationDetailColumn.DATE">{{element.timestamp | svDateTime}}</span>
				<span *ngIf="column===ValidationDetailColumn.VALIDATION_STATUS" class="badge" [ngClass]="getValidationStatusFlag(element.validationResult)">{{getValidationStatusLabel(getValidationStatus(element.validationResult))}}</span>
				<mat-form-field *ngIf="column===ValidationDetailColumn.ENERGY || column ===ValidationDetailColumn.VOLUME">
								<input #control=matInput (click)="onInputClick($event,element,control);" [readonly]="positionFrozen" matInput #control=matInput
								name="column.columnDef"
								(change)="onMeasureInputClick($event,element,control)"
								[value]="getEnergyOrVolumeValue(element)">
						<button [disabled]="positionFrozen"
							(click)="onSaveValue(element,control)" matSuffix mat-button
							color="primary" class="btn" i18n="@@data validation/detail/save">Save</button>
				</mat-form-field >
				<span *ngIf="column===ValidationDetailColumn.USAGE">{{ getUsageValue(element) }}</span>
				<mat-checkbox *ngIf="column===ValidationDetailColumn.SELECT" (click)="$event.stopPropagation();"
					(change)="changeChecboxValue(element)" [checked]="isThisChecked(element)"></mat-checkbox>
				<button *ngIf="column===ValidationDetailColumn.ACTIONS" mat-icon-button [matMenuTriggerFor]="menu">
					<mat-icon class="material-icons">menu</mat-icon>
				</button>
				<mat-menu #menu="matMenu" class="menu-box">
					<button mat-menu-item (click)="viewElement(element)">
						<i class="material-icons">visibility</i>
						<span i18n="@@data validation/detal/view">View</span>
					</button>
				</mat-menu>
			</mat-cell>
		</ng-container>

		<mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
		<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
	</mat-table>

	<ng-template #spinner>
		<mat-spinner *ngIf="dataLoading"></mat-spinner>
	</ng-template>

	<div class="no-result" *ngIf="(getDataSourceDataLength() === 0) && !dataLoading">
		<span i18n="no data label|A label displayed when there is no data@@application/texts/no data found">No data
			found.</span>
	</div>

	<mat-paginator #paginator [pageSizeOptions]="[5, 10, 20]" [length]="getDataLength()" showFirstLastButtons>
	</mat-paginator>
	<br />
	<br />
	<h4 i18n="@@data validation/detail/meter data">Meter data</h4>
	<div id="plot{{detail.idMeterPoint}}">
		<ng-container *ngIf="plottable">
			<div class="legend">
				<span class="history" i18n="@@data validation/detail/history">Usages history</span>
				<span class="valid" i18n="@@validation/plot/valid series label">Valid</span>
				<span class="invalid" i18n="@@validation/plot/invalid series label">Invalid</span>
				<span class="autocor" i18n="@@data validation/detail/auto correction">Auto correction</span>
				<span class="manualcor" i18n="@@data validation/detail/manual correction">Manual correction</span>
			</div>

			<sv-chart *ngIf="graphDataAvailable" [meterDataInput]="chartData" [legend]="plotLegend"
				[colors]="lineChartColors" [class.graph-data-ready]="graphDataAvailable" [key]="actialDisplayKey"
				[options]="chartOptions">
			</sv-chart>

			<div class="loading" [style.display]="isDataAvailable() ? 'none' : ''">
				<mat-spinner [strokeWidth]="3" [diameter]="20" class="notranslate"></mat-spinner>
			</div>
		</ng-container>
		<ng-container *ngIf="!plottable">
			<h4 i18n="@@data validation/detail/plot placeholder">
				Not enought plot data for meter ID {{detail?.meterPointRef?.serialNumber//i18n(ph='SERIAL_NUMBER')}}
			</h4>
		</ng-container>
	</div>
</div>