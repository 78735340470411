import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/interval';
import 'rxjs/add/operator/takeWhile';

import { BaseDataStore, GenericHttpParams, UtilityTypeEnum, ValidationResultEnum, VeeStatusEnum } from '@shared/types';
import { VeeStatusHttpParamG } from '@shared/types/http/veeProcessHttpConfig';
import { VeeStatus } from '@shared/models/appModels/VeeStatus.model';
import { VeeStatusService } from '@shared/services/http/veeStatus.service';
import { VeeProcessStageEnum, VeeProcessStatusEnum } from '@shared/types/modelTypes/veeProcessTypes';
import { IVeeRequest } from '@shared/models/appModels/VeeStatus.model';
import { CommonAppDataService } from '@shared/services/commonAppData.service';


import { IPolledData } from '@shared/types/applicationTypes';
import { ValidateMeasurement } from '@shared/models/appModels/validateMeasurement.mode';
import { PositionDataStoreService } from './positionDataStore.service';


@Injectable()
export class VeeStatusDataStoreService extends BaseDataStore<GenericHttpParams<VeeStatus>, VeeStatusHttpParamG> {

  private veeStatusDataSubject$: Subject<VeeStatus> = new Subject<VeeStatus>();
  private veeStatusObservable$: Observable<VeeStatus> = this.veeStatusDataSubject$.asObservable();

  constructor(
    private veeStatusHttpService: VeeStatusService,
    private positionDataStoreService: PositionDataStoreService, cs: CommonAppDataService) {
    super(cs);
  }

  getChangeObservable(): Observable<VeeStatus> {
    return this.veeStatusObservable$;
  }

  getVeeStateForPosition(
    idPosition: number,
    allowEmpty: boolean = false
  ): Observable<VeeStatus> {
    const params = this.getEmptyParams();
    params.path.idPosition = '' + idPosition;
    params.queryParams.svUtilityType = UtilityTypeEnum[this.cs.getCurrentMediaType()];
    params.config.strict = !allowEmpty;
    return this.veeStatusHttpService.getProcessState(params);
  }

  setVeeStateForPosition(id: number, state: VeeStatus, allowEmpty: boolean = true, options?: { emitEvent: boolean }): Observable<VeeStatus> {
    const params = this.getEmptyParams();
    params.path.idPosition = '' + id;
    params.body = state;
    params.queryParams.svUtilityType = UtilityTypeEnum[this.cs.getCurrentMediaType()];
    params.config.strict = !allowEmpty;
    return this.veeStatusHttpService.postProcessState(params);
  }

  postValidationStateForPosition(validateMeasurement: ValidateMeasurement, allowEmpty: boolean = true): Observable<ValidateMeasurement> {
    const params = this.getEmptyParams();
    params.body = validateMeasurement;
    params.config.strict = !allowEmpty;
    return this.veeStatusHttpService.postValidationProcessState(params);
  }

  clearVeeStateForPosition(id: number, options?: { emitEvent: boolean }): Observable<VeeStatus> {
    const params = this.getEmptyParams();
    params.path.idPosition = '' + id;
    params.queryParams.svUtilityType = UtilityTypeEnum[this.cs.getCurrentMediaType()];
    return this.veeStatusHttpService.deleteProcessState(params)
      .do((vs: VeeStatus) => {
        if (options && options.emitEvent) {
          this.veeStatusDataSubject$.next(vs);
        }
      });
  }

  longPollingState(
    idPosition: number,
    interval: number = 500,
    mockReq?: { state?: VeeProcessStageEnum, status?: VeeProcessStatusEnum, sequence?: boolean, mockErorr?: boolean }): Observable<IPolledData<VeeStatus>> {
    return Observable.interval(interval)
      .concatMap<number, VeeStatus>((count: number) => {
        return this.getVeeStateForPosition(idPosition, true);
      })
      .concatMap<VeeStatus, any>((veeStatus: VeeStatus, idx: number) => {
        if (veeStatus.stage === VeeProcessStageEnum.VALIDATION) {
          return this.positionDataStoreService.getPositionMeasurementDataCount(idPosition, { validationResult: ValidationResultEnum.UNDEFINED })
            .concatMap<number, IPolledData<VeeStatus>>((count: number) => {
              return Observable.of({ data: veeStatus, pollTime: idx * interval, countNotValidatedMeasurementData: count, positionId: idPosition });
            });
        } else {
          return Observable.of({ data: veeStatus, pollTime: idx * interval, positionId: idPosition });
        }

      });
  }

  remoteStatusTriggering(
    idPosition: number,
    interval: number = 500,
    processFn: (oIn: IPolledData<VeeStatus>) => Observable<IPolledData<VeeStatus>>,
    mockReq?: { state?: VeeProcessStageEnum, status?: VeeProcessStatusEnum, sequence?: boolean }): Observable<IPolledData<VeeStatus>> {

    return Observable.interval(interval)
      .concatMap<number, VeeStatus>((idx: number) => {
        return this.getVeeStateForPosition(idPosition, true);
      })
      .concatMap<VeeStatus, IPolledData<VeeStatus>>((s: VeeStatus, idx: number) => {
        return Observable.of({ data: s, pollTime: idx * interval });
      })
      .concatMap<IPolledData<VeeStatus>, IPolledData<VeeStatus>>((i) => {
        return processFn(i);
      });
  }

  protected getEmptyParams(): VeeStatusHttpParamG {
    const r: VeeStatusHttpParamG = { body: {} as VeeStatus, config: {}, headers: {}, path: {}, queryParams: {} };
    return r;
  }
}
