import { Injectable } from "@angular/core";
import { InteractiveTutorialService, NextComponentName, TutorialButtonLabel } from "@shared/services/interactiveTutorialService.service";

@Injectable({providedIn: 'root'})

export class ExportInteractiveTutorialService {

    constructor(private interactiveTutorialService: InteractiveTutorialService) {}

    getExportMainComponentInteractiveTutorialSteps(dataOnPositionAvailable: boolean, selectedExportOption: string) {
        const step = this.interactiveTutorialService.generateStep(
            '#startDate',
            'right',
            $localize`:@@interactive tutorial/export/start date:With this field you can specify the start date from which the data for export will be downloaded.`,
            $localize`:@@interactive tutorial/export/start date title: Start date`,
            [TutorialButtonLabel.NEXT]
        );
        const step1 = this.interactiveTutorialService.generateStep(
            '#endDate',
            'right',
            $localize`:@@interactive tutorial/export/end date:With this field you can specify the end date until which the data for export will be downloaded.`,
            $localize`:@@interactive tutorial/export/end date title: End date`,
            [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
        );
        const step2 = this.interactiveTutorialService.generateStep(
            '#basicExport',
            'left',
            $localize`:@@interactive tutorial/export/basic export:
            Here is a set of switches for toggling the wizard type shown below. Currently, only Basic Export can be selected.`,
            $localize`:@@interactive tutorial/export/basic export title:Selecting the export wizard`,
            [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT],
            () => dataOnPositionAvailable ? true : false
        );
        const step3 = this.interactiveTutorialService.generateStep(
            '#exportBillingData',
            'left',
            $localize`:@@interactive tutorial/export/billing export:By clicking on this button you will generate a billing file.`,
            $localize`:@@interactive tutorial/export/billing export title:Data billing`,
            [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT],
            undefined,
            false,
            {
                next: () => {
                    if (!dataOnPositionAvailable && selectedExportOption !== 'GenerateCsvFile') {
                    this.interactiveTutorialService.canGoToNextStepSubject.next(
                        NextComponentName.EXPORT_MEASUREMENTS_FIRST_STEP
                  );
                }
                },
              }
        );
        const step4 = this.interactiveTutorialService.generateStep(
            '#wizard',
            'bottom',
            $localize`:@@interactive tutorial/export/wizard:This is a wizard that will guide you through the selection of data necessary to generate a file compatible with the selected export type.`,
            $localize`:@@interactive tutorial/export/wizard title:Wizard`,
            [TutorialButtonLabel.BACK, TutorialButtonLabel.COMPLETE],
            () => (selectedExportOption === 'GenerateCsvFile' && dataOnPositionAvailable) ? true : false,
            false,
            {
                complete: () => {
                  this.interactiveTutorialService.canGoToNextStepSubject.next(
                    NextComponentName.EXPORT_MEASUREMENTS_FIRST_STEP
                  );
                },
              }
        );
        const steps: unknown[] = [step, step1, step2, step3, step4];
        return steps;
    }

    getExportCustomCsvInteractiveTutorialSteps(){
        const step = this.interactiveTutorialService.generateStep(
            '#selectMeterPoints',
            'bottom',
            $localize`:@@interactive tutorial/export/custom csv/description:This is the first step in which you have to choose the meters that interest you. Without making a choice, you will not proceed to the next step.`,
            $localize`:@@interactive tutorial/export/custom csv/description title:Meter points selector`,
            [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT],
        );
        const step1 = this.interactiveTutorialService.generateStep(
            '#nextstep',
            'bottom',
            $localize`:@@interactive tutorial/export/custom csv/next step btn:To go to the next step, click the 'Next Step' button`,
            $localize`:@@interactive tutorial/export/custom csv/next step btn title:Next step`,
            [TutorialButtonLabel.BACK, TutorialButtonLabel.COMPLETE],
        );
        const steps: unknown[] = [step, step1];
        return steps;
    }

    getExportCustomCsvNextStepInteractiveTutorialSteps(){
        const step = this.interactiveTutorialService.generateStep(
            '#selectVariables',
            'bottom',
            $localize`:@@interactive tutorial/export/custom csv next step/description:This is the second step in which you need to select the data model variables that interest you. Without making a choice, you won't go to the next step.`,
            $localize`:@@interactive tutorial/export/custom csv next step/description title:Variables selector`,
            [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT],
        );
        const step1 = this.interactiveTutorialService.generateStep(
            '#summaryBtn',
            'bottom',
            $localize`:@@interactive tutorial/export/custom csv next step/next step btn:To go to the last step, click the Summary button.`,
            $localize`:@@interactive tutorial/export/custom csv next step/next step btn title:Last step`,
            [TutorialButtonLabel.BACK, TutorialButtonLabel.COMPLETE],
        );
        const steps: unknown[] = [step, step1];
        return steps;
    }

    getExportCustomCsvSummaryInteractiveTutorialSteps(){
        const step = this.interactiveTutorialService.generateStep(
            '#summary',
            'bottom',
            $localize`:@@interactive tutorial/export/custom csv summary/description:This is the last step where you can see a summary of your choices.`,
            $localize`:@@interactive tutorial/export/custom csv summary/description title:Summary`,
            [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT],
        );
        const step1 = this.interactiveTutorialService.generateStep(
            '#exportBtn',
            'bottom',
            $localize`:@@interactive tutorial/export/custom csv summary/export btn:To export the file, click the Export Readings button.`,
            $localize`:@@interactive tutorial/export/custom csv summary/export btn title:Generate csv file`,
            [TutorialButtonLabel.BACK, TutorialButtonLabel.COMPLETE],
        );
        const steps: unknown[] = [step, step1];
        return steps;
    }
}
