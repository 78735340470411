import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ApplicationUnitsDataStoreService } from '@shared/services/dataStore/applicationUnitsDataStore.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ViewChild } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MeasurementDataModel } from '@shared/models/appModels/measurementDataModel.model';
import { DataModelCreateVariableComponent } from './create-variable/dataModel-variable-create.component';
import { DataModelEditVariableComponent } from './edit-variable/dataModel-variable-edit.component';
import { typesLocalisation } from '@shared/types/localisation';
import { environment } from '@env/environment';
import { InteractiveTutorialService } from '@shared/services/interactiveTutorialService.service';
import { SettingsInteractiveTutorialService } from '../settings-interactive-tutorial.service';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({

    // tslint:disable-next-line:component-selector
    selector: 'sv-settings-dataModel',
    templateUrl: 'settings-dataModel.component.html',
    styleUrls: ['settings-dataModel.component.sass']
})

export class SettingsDataModelComponent implements OnInit, AfterViewInit, OnDestroy {
    data: MeasurementDataModel[] = [];
    readonly dataModelTxt = typesLocalisation.DataModelTexts[environment.language].texts;
    displayedColumns: string[] = ['Name', 'ParsedName', 'Description', 'Unit', 'Type', 'IsBilled', 'IsCumulative', 'IsPrimary', 'More'];
    dataSource;
    @ViewChild('paginator', { static: true }) paginator: MatPaginator;
    readonly menuIcon: string = 'menu';
    readonly editIcon: string = 'mode_edit';
    readonly itemsPage = typesLocalisation.ItemsPage[environment.language].texts;

    showTutorialSubscription: Subscription;

    constructor(private appUnitsDataService: ApplicationUnitsDataStoreService,
        private dialog: MatDialog,
        private interactiveTutorialService: InteractiveTutorialService,
        private settingsInteractiveTutorialService: SettingsInteractiveTutorialService,
        private route: ActivatedRoute) { }

    ngOnInit() {
        this.paginator._intl.itemsPerPageLabel = this.itemsPage.itemsPage;
        this.paginator._intl.nextPageLabel = this.itemsPage.nextPage;
        this.paginator._intl.previousPageLabel = this.itemsPage.previousPage;
        this.paginator._intl.lastPageLabel = this.itemsPage.lastPage;
        this.paginator._intl.firstPageLabel = this.itemsPage.firstPage;
        this.getMeasurementDefinitionData();
    }

    ngAfterViewInit(){
        this.showTutorialSubscription = this.interactiveTutorialService.showTutorialSubject.pipe(debounceTime(1500)).subscribe(isActive => {
            if (this.interactiveTutorialService.getActiveComponent(this.route.routeConfig.path) && isActive) {
            this.startInteractiveTutorial();
            }
          });
    }

    ngOnDestroy(){
        if(this.showTutorialSubscription){
            this.showTutorialSubscription.unsubscribe();
        }
    }

    startInteractiveTutorial(){
        const steps = this.settingsInteractiveTutorialService.getSettingsDataModelInteractiveTutorialSteps();
        this.interactiveTutorialService.startInteractiveTutorial(steps);
    }

    getMeasurementDefinitionData() {
        this.appUnitsDataService.getMeasurementDefinitions()
            .subscribe(
                data => {
                    data = data.filter(element => !element.isMetadata);
                    data.sort((a, b) => {
                        if (a.id > b.id) {
                            return 1;
                        }
                        if (b.id > a.id) {
                            return -1;
                        }
                        return 0;
                    });
                    this.data = data;
                    this.dataSource = new MatTableDataSource<MeasurementDataModel>(this.data);
                    this.dataSource.paginator = this.paginator;
                },
                (e) => console.log(e),
                () => console.log('Fetching Measurement Definition Complete!')
            );
    }
    onEdit(element: MeasurementDataModel) {
        let dialogRef: MatDialogRef<DataModelEditVariableComponent, MeasurementDataModel>;
        dialogRef = this.dialog.open(DataModelEditVariableComponent, {
            panelClass: 'dialog-overlay',
            data: {
                measurementDefData: element
            }
        });
        dialogRef.afterClosed().subscribe(
            (data) => {
                if (typeof (data) !== 'undefined') {
                    const measurementData = new MeasurementDataModel(data);
                    this.appUnitsDataService.putMeasurementDefinitionById(measurementData.id, measurementData).subscribe();
                }
            }
        );
    }

    onAddNewVariable() {
        let dialogRef: MatDialogRef<DataModelCreateVariableComponent, MeasurementDataModel>;
        dialogRef = this.dialog.open(DataModelCreateVariableComponent, {
            panelClass: 'dialog-overlay',
            data: {
                measurementDefData: this.data
            },
            restoreFocus: false,
        });
        dialogRef.afterClosed().subscribe(
            (data) => {
                if (typeof (data) !== 'undefined') {
                    const measurementData = new MeasurementDataModel(data);
                    this.appUnitsDataService.postMeasurementDefinition(measurementData).subscribe(
                        savedData => {
                            this.getMeasurementDefinitionData();
                        }
                    );
                }
            }
        );
    }
}
