import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';

import { environment } from '@env/environment';
import { IMeterData } from '@shared/models/appModels/meterData.model';
import { ChartService } from '../chart.service';
import { GraphDataSet, lineGraphConfig } from '@shared/types/graphData.interface';
import { MeterData } from '@shared/models/appModels/meterData.model';
import { Moment } from 'moment';



@Component({
  // tslint:disable-next-line:component-selector
  selector: 'sv-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.sass']
})
export class ChartComponent implements OnInit, OnChanges {

  @Input() options: {} = {};
  @Input() colors: lineGraphConfig[] = [];     /* Style configuration, if none provided, component will generate default SV colors */
  @Input() legend: boolean = true;
  @Input() chartName: string = 'unnamed chart';
  @Input() legendLabels: string[] = [];       /* Custom legend labels. If none provided, component will generate them from data inputs (form ID) */
  private _meterDataInput: MeterData[] = [];
  @Input()
  get meterDataInput(): MeterData[] {
    return this._meterDataInput;
  }
  set meterDataInput(data: MeterData[]) {
    this._meterDataInput = data;
    this.initGraph();
  }
  @Input() key: string = 'energy';

  /* All original chart.js inputs defined by documentation */
  @Output() onChartClick: EventEmitter<KeyboardEvent> = new EventEmitter();
  @Output() onChartHover: EventEmitter<KeyboardEvent> = new EventEmitter();

  readonly debugMode: boolean = environment.debug;


  dataToGraph: GraphDataSet[] = [];
  dataIds: number[] = [];
  orderedMeterData: IMeterData[][] = [];
  lineChartColors: lineGraphConfig[] = [];
  xAxisLabels: string[] = [];

  constructor(private chartService: ChartService) { }

  ngOnInit() {
    this.initGraph();
  }

  initGraph() {
    if (this.debugMode) {console.log(`${this.chartName} invoked`); }
    /* Prepare data to display */
    this.dataIds = this.chartService.getIds(this.meterDataInput);
    this.orderedMeterData = this.chartService.orderData(this.meterDataInput, this.dataIds);
    this.dataToGraph = this.chartService.packDataSetsdataIn(this.orderedMeterData, this.key);

    /* External component provided custom legend labels */
    if (this.legendLabels.length > 0 && this.legendLabels.length >= this.dataToGraph.length) {
      this.dataToGraph.forEach((gd: GraphDataSet, idx: number) => {
        this.dataToGraph[idx].label = this.legendLabels[idx];
      });
    }

    /* External component provided custom line styles */
    if (this.colors.length === 0) {
      this.lineChartColors = this.chartService.generateStyles(this.dataToGraph);
    } else {
      this.lineChartColors = this.colors;
    }

    this.xAxisLabels = this.chartService.xAxisLabels(this.orderedMeterData);
  }

  ngOnChanges() {
  }

  public chartClicked(e: KeyboardEvent): void {
    this.onChartClick.emit(e);
  }

  public chartHovered(e: KeyboardEvent): void {
    this.onChartHover.emit(e);
  }

}
