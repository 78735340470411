import { Injectable, OnDestroy } from "@angular/core";

import { Observable } from "rxjs/Observable";
import { Subscription } from "rxjs/Subscription";

import {
  BaseDataStore,
  GenericHttpParams,
  UtilityTypeEnum,
} from "@shared/types";
import { CommonAppDataService } from "@shared/services/commonAppData.service";
import { ApplicationUnits } from "@shared/models/applicationUnits.model";
import { UnitService } from "@shared/services/http/unit.service";
import { ParametersService } from "@shared/services/http/parameters.service";
import { ApplicationUnitsHttpParamG } from "@shared/types/http/applicationUnitsConfig";
import { MediaTypeParameter } from "@shared/models/appModels/MediaTypeParameter.mode";
import { MediaTypeUnit } from "@shared/models/appModels/MediaTypeUnit.model";
import { MeasurementDataModel } from "@shared/models/appModels/measurementDataModel.model";
import { UnitHttpParamG } from "@shared/types/http/unitHttpConfig";
import { map, flatMap, toArray, tap, filter } from "rxjs/operators";
import { from, zip } from "rxjs";

@Injectable()
export class ApplicationUnitsDataStoreService
  extends BaseDataStore<
  GenericHttpParams<ApplicationUnits>,
  ApplicationUnitsHttpParamG
  >
  implements OnDestroy {
  private utilityTypeChange$: Subscription;

  /* Composed application units model: cumulatives + units */
  private currentApplicationUnits: ApplicationUnits = undefined;

  private currentUnit: MeasurementDataModel[] = undefined;

  private measurementsParameters: MediaTypeParameter[] = undefined;

  private cumulativeParameters: MediaTypeParameter[] = undefined;

  private nonCumulativeParameters: MediaTypeParameter[] = undefined;

  private meterPointParameters: MediaTypeParameter[] = undefined;

  constructor(
    private unitService: UnitService,
    private parameterService: ParametersService,
    cs: CommonAppDataService
  ) {
    super(cs);

    this.getApplicationUnits(this.cs.getCurrentMediaType()).subscribe(
      (newUnits: ApplicationUnits) => {
        this.currentApplicationUnits = newUnits;
      },
      (err) => {
        console.error(err);
      },
      () => { }
    );
  }

  getApplicationUnits(
    mediaType: UtilityTypeEnum,
    allowEmpty: boolean = false
  ): Observable<ApplicationUnits> {
    if (typeof this.currentApplicationUnits !== "undefined") {
      return Observable.of(this.currentApplicationUnits);
    }
    const params = this.getEmptyParams();
    params.queryParams.svUtilityType = UtilityTypeEnum[mediaType];
    params.config.strict = !allowEmpty;
    return zip(this.getMediaTypeUnits(), this.getMeasurementCumulatives()).pipe(
      map(([model, cumulative]) => {
        var uniqueCumulativeValues = new Array<MediaTypeParameter>();
        cumulative.forEach(element => {
          if (uniqueCumulativeValues.find(name => name.name === element.name) === undefined) {
            uniqueCumulativeValues.push(element)
          }
        });
        const retVal = ApplicationUnits.GenerateFromApi(
          [],
          uniqueCumulativeValues
        );
        this.currentApplicationUnits = retVal;
        return retVal;
      })
    );
  }

  getMediaTypeUnits(): Observable<MeasurementDataModel[]> {
    if (typeof this.currentUnit !== "undefined") {
      return Observable.of(this.currentUnit);
    }
    const params = this.getEmptyParams();
    params.queryParams.svUtilityType =
      UtilityTypeEnum[this.cs.getCurrentMediaType()];
    params.config.strict = false;
    return this.unitService
      .getMeasurementDataModel(params)
      .pipe(tap((definitions) => (this.currentUnit = definitions)));
  }

  getMeasurementDefinitions(): Observable<MeasurementDataModel[]> {
    const params = this.getUnitHttpParamGEmptyParams();
    params.queryParams.svUtilityType =
      UtilityTypeEnum[this.cs.getCurrentMediaType()];
    params.config.strict = false;
    return this.unitService.getMeasurementDataModel(params);
  }

  // tslint:disable-next-line: max-line-length
  postMeasurementDefinition(
    measurementDefinition: MeasurementDataModel,
    allowEmpty: boolean = true,
    options?: { emitEvent: boolean }
  ): Observable<MeasurementDataModel> {
    const params = this.getUnitHttpParamGEmptyParams();
    params.body = measurementDefinition;
    params.queryParams.svUtilityType =
      UtilityTypeEnum[this.cs.getCurrentMediaType()];
    params.config.strict = !allowEmpty;
    return this.unitService.postMeasurementDataModel(params);
  }

  getMeasurementParameters(): Observable<MediaTypeParameter[]> {
    if (typeof this.measurementsParameters !== "undefined") {
      return Observable.of(this.measurementsParameters);
    }
    return this.getMeasurementDefinitions().pipe(
      flatMap((definitions) => from(definitions)),
      map(
        (model) =>
          new MediaTypeParameter({
            name: model.name,
            cumulative: model?.isCumulative || false,
            description: model?.description || "",
            type: model?.type,
          })
      ),
      toArray(),
      tap((mtp) => (this.measurementsParameters = mtp))
    );
  }

  getMeasurementCumulatives(): Observable<MediaTypeParameter[]> {
    if (typeof this.cumulativeParameters !== "undefined") {
      return Observable.of(this.cumulativeParameters);
    }

    return this.getMediaTypeUnits().pipe(
      flatMap((definitions) => from(definitions)),
      filter(
        (model) => !model?.isMetadata && model.isCumulative
      ),
      map(
        (model) =>
          new MediaTypeParameter({
            name: model.name,
            unit: model?.unit,
            description: model.description,
            type: model?.type,
            cumulative: true,
          })
      ),
      toArray(),
      tap((cp) => (this.cumulativeParameters = cp))
    );
  }

  getMeasurementNonCumulatives(): Observable<MediaTypeParameter[]> {
    if (typeof this.nonCumulativeParameters === "undefined") {
      return this.getMeasurementParameters()
        .map<MediaTypeParameter[], MediaTypeParameter[]>(
          (mtp: MediaTypeParameter[]) => {
            return mtp.filter(
              (p) =>
                p.cumulative === false || typeof p.cumulative === "undefined"
            );
          }
        )
        .do((ncp: MediaTypeParameter[]) => {
          this.nonCumulativeParameters = ncp;
        });
    } else {
      return Observable.of(this.nonCumulativeParameters);
    }
  }

  getOperationalDataModel(): Observable<MediaTypeParameter[]> {
    if (typeof this.meterPointParameters !== "undefined") {
      return Observable.of(this.meterPointParameters);
    }
    const params = this.getEmptyParams();
    params.queryParams.svUtilityType =
      UtilityTypeEnum[this.cs.getCurrentMediaType()];
    params.config.strict = false;
    return this.parameterService
      .getOperationalDataModel(params)
      .pipe(tap((mpp) => (this.meterPointParameters = mpp)));
  }

  putMeasurementDefinitionById(
    id: number,
    body: MeasurementDataModel,
    allowEmpty: boolean = false
  ): Observable<MeasurementDataModel> {
    const params = this.getUnitHttpParamGEmptyParams();
    params.body = body;
    params.path.id = "" + id;
    return this.unitService.putMeasurementDataModelById(params);
  }

  protected getEmptyParams(): ApplicationUnitsHttpParamG {
    const r: ApplicationUnitsHttpParamG = {
      body: {},
      config: {},
      headers: {},
      path: {},
      queryParams: {},
    };
    return r;
  }

  protected getUnitHttpParamGEmptyParams(): UnitHttpParamG {
    const r: UnitHttpParamG = {
      body: {},
      config: {},
      headers: {},
      path: {},
      queryParams: {},
    };
    return r;
  }

  ngOnDestroy(): void {
    try {
      this.utilityTypeChange$.unsubscribe();
    } catch (e) { }
  }
}
