import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { PositionDataStoreService } from "@shared/services/dataStore/positionDataStore.service";
import { Schedule, Position } from "@shared/models";
import {
  EventType,
  EventStatus,
  ValidationResultEnum,
  VeeStatusEnum,
} from "@shared/types";

import { Router } from "@angular/router";
import { of, forkJoin, Subject, Observable, Subscription } from "rxjs";
import {
  map,
  concatMap,
  startWith,
  delay,
  filter,
  shareReplay,
  distinctUntilChanged,
  debounceTime,
} from "rxjs/operators";
import { InteractiveTutorialService, NextComponentName } from "@shared/services/interactiveTutorialService.service";
import { TimelineInteractiveTutorialService } from "app/components/timeline/timelineInteractiveTutorialService.service";

interface IValidationStatistics {
  validCount?: number;
  invalidCount?: number;
  manualCorrectionCount?: number;
  autoCorrectionCount?: number;
  totalCount?: number;
}

interface ILoading {
  loading?: boolean;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: "sv-data-validation-widget",
  templateUrl: "data-validation-widget.component.html",
  styleUrls: ["data-validation-widget.component.sass"],
})
export class DataValidationWidgetComponent implements OnInit, OnDestroy {
  private currentSchedule$: Subject<Schedule> = new Subject();

  ongoingValidationPosition$: Observable<Position>;
  lastValidatedPosition$: Observable<Position>;
  ongoingValidationData$: Observable<IValidationStatistics & ILoading>;
  lastCompletedValidationData$: Observable<IValidationStatistics & ILoading>;
  lastCompletedValidationChartData$: Observable<
    [{ data: [number, number, number] }]
  >;

  chartLabels: string[] = [
    $localize`:@@validation/widget/auto correction count label:Autocorrection`,
    $localize`:@@validation/widget/manual correction count label:Manual correction`,
    $localize`:@@validation/widget/valid count label:Valid`,
  ];
  canStartInteractiveTut$: Subscription

  constructor(
    private router: Router,
    private positionService: PositionDataStoreService,
    private interactiveTutorialServ: InteractiveTutorialService,
    private timelineInteractiveTutorialServ: TimelineInteractiveTutorialService
  ) {
    this.ongoingValidationPosition$ = this.currentSchedule$.pipe(
      map(this.findOngoingValidation, this),
      shareReplay()
    );

    this.lastValidatedPosition$ = this.currentSchedule$.pipe(
      map(this.findRecentCompletedValidation, this),
      shareReplay()
    );

    this.ongoingValidationData$ = this.wrapValidationService(
      this.ongoingValidationPosition$,
      (position) =>
        forkJoin([
          this.fetchOngoingValidationStats(position),
          this.fetchCompletedValidationStats(position),
        ]).pipe(map(([ongoing, completed]) => ({ ...ongoing, ...completed })))
    );

    this.lastCompletedValidationData$ = this.wrapValidationService(
      this.lastValidatedPosition$,
      (position) => this.fetchCompletedValidationStats(position)
    );

    this.lastCompletedValidationChartData$ = this.lastCompletedValidationData$.pipe(
      map((validationData) => [
        {
          data: [
            validationData.autoCorrectionCount,
            validationData.manualCorrectionCount,
            validationData.validCount,
          ],
        },
      ])
    );
  }

  @Input() set schedule(schedule: Schedule) {
    this.currentSchedule$.next(schedule);
  }

  ngOnInit(){
    this.canStartInteractiveTut$ = this.interactiveTutorialServ.canGoToNextStepSubject.pipe(debounceTime(100)).subscribe(val => {
      if(val === NextComponentName.TIMELINE_DATA_VALIDATION_WIDGET){
        this.startInteractiveTutorial();
      }
    })
  }

  private startInteractiveTutorial(){
    const steps = this.timelineInteractiveTutorialServ.getDataValidationTimelineWidgetTutorialSteps();
    this.interactiveTutorialServ.startInteractiveTutorial(steps);
  }

  ngOnDestroy(){
    if(this.canStartInteractiveTut$){
      this.canStartInteractiveTut$.unsubscribe();
    }
  }

  private findOngoingValidation(schedule: Schedule): Position {
    let result: Position = undefined;
    if (!schedule) {
      return result;
    }
    for (let p of schedule.getPositions()) {
      if (
        p.events[EventType.VALIDATION].status === EventStatus.IN_PROGRESS &&
        (result === undefined ||
          p.events[EventType.VALIDATION].date <
            result.events[EventType.VALIDATION].date)
      ) {
        result = p;
      }
    }

    return result;
  }

  private findRecentCompletedValidation(schedule: Schedule): Position {
    let result: Position = undefined;
    if (!schedule) {
      return result;
    }
    for (let p of schedule.getPositions()) {
      if (
        ![EventStatus.DONE, EventStatus.CLOSED].includes(
          p.events[EventType.VALIDATION].status
        )
      ) {
        continue;
      }
      if (
        result === undefined ||
        p.events[EventType.VALIDATION].date <
          result.events[EventType.VALIDATION].date
      ) {
        result = p;
      }
    }
    return result;
  }

  private fetchOngoingValidationStats(
    position?: Position
  ): Observable<IValidationStatistics> {
    if (!position) {
      return of({} as IValidationStatistics);
    }
    return forkJoin([
      this.positionService.getPositionMeasurementDataCount(
        position.idPosition,
        {
          validationResult: ValidationResultEnum[ValidationResultEnum.INVALID],
        }
      ),
      this.positionService.getPositionMeasurementDataCount(position.idPosition),
    ]).pipe(
      map(([invalid, valid]) => ({ validCount: valid, invalidCount: invalid }))
    );
  }

  private fetchCompletedValidationStats(
    position?: Position
  ): Observable<IValidationStatistics> {
    if (!position) {
      return of({} as IValidationStatistics);
    }

    return forkJoin([
      this.positionService.getPositionMeasurementDataCount(
        position.idPosition,
        {
          validationResult: ValidationResultEnum[ValidationResultEnum.VALID],
        }
      ),
      this.positionService.getPositionMeasurementDataCount(
        position.idPosition,
        {
          validationResult:
            ValidationResultEnum[ValidationResultEnum.MANUALCORRECTION],
        }
      ),
      this.positionService.getPositionMeasurementDataCount(
        position.idPosition,
        { estimation: VeeStatusEnum[VeeStatusEnum.DONE] }
      ),
      this.positionService.getPositionMeasurementDataCount(position.idPosition),
    ]).pipe(
      map(
        ([
          validCount,
          manualCorrectionCount,
          autoCorrectionCount,
          totalCount,
        ]) =>
          ({
            validCount: validCount,
            manualCorrectionCount: manualCorrectionCount,
            autoCorrectionCount: autoCorrectionCount,
            totalCount: totalCount,
          } as IValidationStatistics)
      )
    );
  }

  private wrapValidationService(
    position$: Observable<Position>,
    serviceFn: (position: Position) => Observable<IValidationStatistics>
  ): Observable<IValidationStatistics & ILoading> {
    return position$.pipe(
      filter((position) => position !== undefined),
      distinctUntilChanged(),
      concatMap((position) =>
        serviceFn(position).pipe(
          delay(500),
          startWith({ loading: true } as ILoading)
        )
      )
    );
  }

  validationProgress(validCount: number, invalidCount: number): number {
    return Math.ceil((100 * (validCount - invalidCount)) / validCount);
  }

  navigateToValidation(position: Position): void {
    if (!position) return;

    this.router.navigate(["/data-validation"], {
      queryParams: { position: position.idPosition },
      queryParamsHandling: "merge",
    });
  }
}
