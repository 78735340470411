import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import 'rxjs/add/observable/fromPromise';

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers = req.headers;
    if (headers.get("Accept")) {
      return next.handle(req);
    } else {
      return next.handle(this.setHeader(req));
    }
  }

  private setHeader(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
      headers: req.headers.append('Accept', 'application/json ')
    });
  }
}
