// Angular modules import
import { Injectable } from "@angular/core";

// Data imports
import {
  httpConfig,
  IHttpConfig,
  IApiEndpointConfig,
} from "@shared/types/http/httpType";
import { EndpointsEnum } from "@shared/types/http/endpointEnum";

// RxJS imports
import { Observable } from "rxjs/Observable";
import { Subject } from "rxjs/Subject";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { UtilityTypeEnum } from "@shared/types/utilityType";
import { environment } from "@env/environment";

import { utilityTypeLocalStorageKey } from "@shared/types/applicationTypes";
import * as moment from "moment";

export interface AppSubjects {
  general: Subject<any>;
  sidebar: Subject<any>;
  activity: Subject<any>;
  login: Subject<any>;
  utilityType: Subject<UtilityTypeEnum>;
}

export type SubjectQuery = keyof AppSubjects;

@Injectable()
export class CommonAppDataService {
  private httpConfig: IHttpConfig = httpConfig;

  private subjects: AppSubjects = {
    general: new Subject<any>(),
    sidebar: new Subject<any>(),
    activity: new Subject<any>(),
    login: new Subject<any>(),
    utilityType: undefined,
  };

  constructor() {
    this.subjects.utilityType = this.initMediaTypeSubject();
  }

  private initMediaTypeSubject(): BehaviorSubject<UtilityTypeEnum> {
    if (localStorage.getItem(utilityTypeLocalStorageKey)) {
      return new BehaviorSubject<UtilityTypeEnum>(
        UtilityTypeEnum[localStorage.getItem(utilityTypeLocalStorageKey)]
      );
    } else {
      localStorage.setItem(
        utilityTypeLocalStorageKey,
        UtilityTypeEnum[environment.defaultMediaType]
      );
      return new BehaviorSubject<UtilityTypeEnum>(environment.defaultMediaType);
    }
  }

  getSubject<T = any>(subjectType: SubjectQuery): Subject<T> {
    if (this.subjects.hasOwnProperty(subjectType)) {
      return this.subjects[subjectType] as Subject<T>;
    } else {
      throw new Error(`Incorrect subject name passed: ${subjectType}`);
    }
  }

  getObservable<T = any>(observableType: SubjectQuery): Observable<T> {
    if (this.subjects.hasOwnProperty(observableType)) {
      return this.subjects[observableType].asObservable();
    } else {
      throw new Error(`Incorrect subject name passed: ${observableType}`);
    }
  }

  sendMessageToSubject<T = any>(subjectType: SubjectQuery, payload: T) {
    if (this.subjects.hasOwnProperty(subjectType)) {
      (this.subjects[subjectType] as Subject<T>).next(payload);
    } else {
      throw new Error(`Incorrect subject name passed: ${subjectType}`);
    }
  }

  getCurrentMediaType(): UtilityTypeEnum {
    try {
      const subjectUtil =
        UtilityTypeEnum[
          (this.getSubject<UtilityTypeEnum>(
            "utilityType"
          ) as BehaviorSubject<UtilityTypeEnum>).getValue()
        ];
      const localStorageUtil = localStorage.getItem(utilityTypeLocalStorageKey);
      if (UtilityTypeEnum[subjectUtil] !== localStorageUtil) {
        throw new Error(`Mismatched type of utilityType: subject stores ${UtilityTypeEnum[subjectUtil]}, localStorage: ${localStorageUtil}
        \nUtilityType will be aligned to subject value`);
      }
    } catch (e) {
      localStorage.setItem(
        utilityTypeLocalStorageKey,
        UtilityTypeEnum[
          (this.getSubject(
            "utilityType"
          ) as BehaviorSubject<UtilityTypeEnum>).getValue()
        ]
      );
    }
    return (this.getSubject(
      "utilityType"
    ) as BehaviorSubject<UtilityTypeEnum>).getValue();
  }

  getUtilityTypeObservable(): Observable<UtilityTypeEnum> {
    return this.subjects["utilityType"].asObservable();
  }

  getToday(dayPrecision: boolean = true): moment.Moment {
    let today = moment.utc();
    if (dayPrecision) {
      today = today.startOf("day");
    }
    return today;
  }

  getApiBasePath(): string {
    return this.httpConfig.httpBasePath;
  }

  getApiEndpointConfig(path: EndpointsEnum): IApiEndpointConfig {
    try {
      return this.httpConfig.endpoints[path];
    } catch (e) {
      throw new Error(`Missing API configuration for ${EndpointsEnum[path]}`);
    }
  }
}
