import { TableDataSource } from '@shared/models/tableDataSource.model';
import { Position } from '@shared/models/appModels/position.model';

export class TabbedPosition extends Position {
  detailRow: boolean;
  rowId: number;
}

export class ScheduleDetailsDataSource extends TableDataSource<Position, Position> {

  private setParams(detailRow: boolean, schedule: Position, baseElement: Position) {
    const element: TabbedPosition = new TabbedPosition(schedule);
    element.detailRow = detailRow;
    element.rowId = baseElement.idSchedule;
    return element;
  }

  protected renderData(data: Position[]): TabbedPosition[] {
    const rows: TabbedPosition[] = [];
    data.forEach((baseElement, idx) => {
      const topElement: TabbedPosition = this.setParams(false, baseElement, baseElement);
      const detailElement: TabbedPosition = this.setParams(true, topElement, baseElement);
      rows.push(topElement, detailElement);
    });
    return rows;
  }

}
