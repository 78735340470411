import { FileMeterData } from '@shared/models';
import { TableDataSource } from '@shared/models/tableDataSource.model';

export class FileMeterDataTabbed extends FileMeterData {
}

export class FileMeterDataPreviewDataSource extends TableDataSource<FileMeterData, FileMeterDataTabbed> {

  protected renderData(data: FileMeterData[]): FileMeterDataTabbed[] {
    const ret: FileMeterDataTabbed[] = [];
    data.forEach(d => {
      const cpy: FileMeterDataTabbed = new FileMeterDataTabbed(d.getInterface(false));
      cpy.name = d.name;
      cpy.creationDate = d.creationDate;
      cpy.lastModifiedDate = d.lastModifiedDate;
      ret.push(cpy);
    });
    return ret;
  }
}
