import { Injectable } from '@angular/core';

import { BaseDataStore, GenericHttpParams, ApiDataShift, IFileUpload, FileTemperatureDataQueryParameters } from '@shared/types';

import { Subject } from 'rxjs/Subject';

import { Observable } from 'rxjs/Observable';

import { CommonAppDataService } from '../commonAppData.service';
import { FileTemperatureData } from '@shared/models/appModels/fileTemperatureData';
import { FileTemperatureDataHttpParamG } from '@shared/types/http/fileTemperaturesDataHttpConfig';
import { FileTemperatureDataService } from '../http/fileTemperatureData.service';
import { TemperatureData } from '@shared/models/appModels/temperatureData.model';

@Injectable()
export class FileTemperatureDataDataStoreService extends BaseDataStore<GenericHttpParams<FileTemperatureData>, FileTemperatureDataHttpParamG> {

    private temperaturesDataSubject$: Subject<FileTemperatureData> = new Subject<FileTemperatureData>();
    private readonly temperaturesDataObservable$: Observable<FileTemperatureData> = this.temperaturesDataSubject$.asObservable();

    constructor(
        private fileTemperatureDataService: FileTemperatureDataService,
        cs: CommonAppDataService
    ) {
        super(cs);
    }

    getChangeObservable(): Observable<FileTemperatureData> {
        return this.temperaturesDataObservable$;
    }

    getSubject(): Subject<FileTemperatureData> {
        return this.temperaturesDataSubject$;
    }

    getTemperatureDataList(nums: ApiDataShift, allowEmpty: boolean = true): Observable<TemperatureData[]> {
        const params = this.getEmptyParams();
        params.queryParams.limit = '' + nums.limit;
        params.queryParams.offset = '' + nums.offset;
        params.config.strict = !allowEmpty;
        return this.fileTemperatureDataService.getTemperatureDataList(params);
    }

    addFileTemperatureData(item: any, options: { emitEvent: boolean } = { emitEvent: true }): Observable<FileTemperatureData> {
        const params = this.getEmptyParams();
        params.body = item;
        return this.fileTemperatureDataService.postFileTemperatureData(params)
            .do((updated: FileTemperatureData) => {
                if (options.emitEvent) {
                    this.temperaturesDataSubject$.next(updated);
                }
            });
    }

    getTemperatureDataListCount(filters?: { from?: number, to?: number }): Observable<number> {
        const params = this.getEmptyParams();
        if (filters.from != undefined) {
            params.queryParams.from = filters.from + '';
        }
        if (filters.to != undefined) {
            params.queryParams.to = filters.to + '';
        }
        return this.fileTemperatureDataService.getTemperatureDataListCount(params);
    }

    protected getEmptyParams(): FileTemperatureDataHttpParamG {
        const r: FileTemperatureDataHttpParamG = { body: {} as IFileUpload, headers: {}, path: {}, queryParams: {}, config: {} };
        return r;
    }

}
