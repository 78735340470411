import { GenericHttpParams, IApiEndpointConfig } from '@shared/types/http/httpType';
import { SensorData } from '@shared/models/appModels/sensorData.model';

export const SensorDataApiConf: IApiEndpointConfig = {
    rootApiPath: 'sensordata',
    paths: [
        {/* 1 */
            url: '',
            swaggerId: 'postSensorDataState',
            pathParams: [],
            responseIsArray: false,
        },
        {/* 2 */
            url: '',
            swaggerId: 'getSensorDataState',
            pathParams: [],
            responseIsArray: false,
        },
    ]
};

export interface SensorDataQueryParams {
    from?: string;
    active?: string;
    to?: string;
    interval? : string
}

export interface SensorDataHttpParamG extends GenericHttpParams<SensorData> {
    queryParams?: SensorDataQueryParams;
    body?: SensorData;
    path?: {
    };
}
