import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'sv-custom-billing-file',
    templateUrl: 'generate-custom-billing-file.component.html',
    styleUrls: ['generate-custom-billing-file.component.sass']
})

export class CustomBillingFileComponent {

    @Output() firstStepCompleted = new EventEmitter<boolean>();
    @Output() displayHeadersInCsvFile = new EventEmitter<boolean>();
    @Output() selectedColumnsInCsvFile = new EventEmitter<string[]>();
    @Input() stepper: MatStepper;

    allBillingColumns: string[] = ['Customer', 'MeterID', 'Date', 'valueEnergy', 'valueEnergyUsage'];
    addColumnsPlaceholder: string = $localize`:@@export/add columns:Add columns...`;
    columnsRequired: string = $localize`:@@export/columns required:Columns are required.`;
    wrongColumn: string = $localize`:@@export/wrong column:There is no such column or it is already in the pick list.`;
    initComponent: boolean = true;
    selectedBillingColumns: string[] = [];
    displayHeadersInCsv: boolean = false;

    constructor() {}


    onNextStep() {
        this.firstStepCompleted.emit(true);
        this.selectedColumnsInCsvFile.emit(this.selectedBillingColumns);
        this.displayHeadersInCsvFile.emit(this.displayHeadersInCsv);
        this.stepper.next();
    }

    selectedDataEvent(selectedColumns) {
        this.selectedBillingColumns = selectedColumns;
        if (selectedColumns.length !== 0) {
            this.firstStepCompleted.emit(true);
          } else {
            this.firstStepCompleted.emit(false);
          }
    }

}
