// Angular modules
import { CommonModule } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from "@angular/material-moment-adapter";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import {
  DateAdapter,
  MatDateFormats,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from "@angular/material/core";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSelectModule } from "@angular/material/select";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AuthInterceptor } from "@shared/services/auth/authInterceptor";
import { KeycloakService } from "@shared/services/auth/keycloak.service";
// Services with global visibility
import { CommonAppDataService } from "@shared/services/commonAppData.service";
import { AnalysisAlgorithmDataStoreService } from "@shared/services/dataStore/analysisAlgorithmsDataStore.service";
import { AnalysisStatusDataStoreService } from "@shared/services/dataStore/analysisProcessDataStore.service";
import { ApplicationUnitsDataStoreService } from "@shared/services/dataStore/applicationUnitsDataStore.service";
import { CustomerDataStoreService } from "@shared/services/dataStore/customerDataStore.service";
import { FileMeterDataStoreService } from "@shared/services/dataStore/fileMeterDataDataStore.service";
import { FileOperationalDataStoreService } from "@shared/services/dataStore/fileOperationalDataStore.service";
import { FileTemperatureDataDataStoreService } from "@shared/services/dataStore/fileTemperaturesDataDataStore.service";
import { MeasurementDataStoreService } from "@shared/services/dataStore/measurementDataDataStore.service";
import { MeasurementDefinitionMappingDataStoreService } from "@shared/services/dataStore/measurementDefinitionMappingDataStore.service";
import { MeterDataStoreService } from "@shared/services/dataStore/meterDataStore.service";
import { MeterPointDataStoreService } from "@shared/services/dataStore/meterPointDataStore.service";
import { PositionDataStoreService } from "@shared/services/dataStore/positionDataStore.service";
import { ScheduleDataStoreService } from "@shared/services/dataStore/scheduleDataStore.service";
import { SensorDataDataStoreService } from "@shared/services/dataStore/sensorDataDataStore.service";
import { TimeGroupDataStoreService } from "@shared/services/dataStore/timeGroupDataStore.service";
import { VeeStatusDataStoreService } from "@shared/services/dataStore/veeProcessDataStore.service";
import { VeeRulesDataStoreService } from "@shared/services/dataStore/veeRulesDataStore";
import { AnalysisAlgorithmService } from "@shared/services/http/analysisAlgorithm.service";
import { AnalysisStatusService } from "@shared/services/http/analysisStatus.service";
import { CustomerService } from "@shared/services/http/customer.service";
import { FileMeterDataService } from "@shared/services/http/fileMeterData.service";
import { FileOperationalDataService } from "@shared/services/http/fileOperationalData.service";
import { FileTemperatureDataService } from "@shared/services/http/fileTemperatureData.service";
import { MeasurementDataService } from "@shared/services/http/measurementData.service";
import { MeasurementDefinitionMappingService } from "@shared/services/http/measurementDefinitionMapping.service";
import { MeterDataHTTPService } from "@shared/services/http/meterData.service";
import { MeterPointService } from "@shared/services/http/meterPoint.service";
import { ParametersService } from "@shared/services/http/parameters.service";
import { PositionHTTPService } from "@shared/services/http/position.service";
import { ScheduleService } from "@shared/services/http/schedule.service";
import { SensorDataService } from "@shared/services/http/sensorData.service";
import { TimeGroupService } from "@shared/services/http/timeGroup.service";
import { UnitService } from "@shared/services/http/unit.service";
import { VeeRuleService } from "@shared/services/http/veeRule.service";
import { VeeStatusService } from "@shared/services/http/veeStatus.service";
import { HeadersInterceptor } from "@shared/services/interceptors/headersInterceptor";
import { LocaleService } from "@shared/services/localeService.service";
// Routing
import { AppRoutingModule } from "./components/app/app-routing.module";
// Components
import { AppComponent } from "./components/app/app.component";
import { CommonComponentsModule } from "./components/common-component/commonComponents.module";
import { DataAnalysisModule } from "./components/data-analysis/data-analysis.module";
import { DataExplorationModule } from "./components/data-exploration/data-exploration.module";
import { DataValidationModule } from "./components/data-validation/data-validation.module";
import { ExportModule } from "./components/export/export.module";
import { GrafanaModule } from "./components/grafana-dashboards/grafana.module";
import { ChartService } from "./components/graphics/chart.service";
// App modules
import { GraphicsModule } from "./components/graphics/graphics.module";
import { HomeComponent } from "./components/home/home.component";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { ScheduleModule } from "./components/schedule/schedule.module";
import { SettingsModule } from "./components/settings/settings.module";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { TimelineModule } from "./components/timeline/timeline.module";
import { UploadModule } from "./components/upload/upload.module";
import { CommonPipeModule } from "./utils/commonPipe.module";
import { SV_DATETIME_FORMAT, SV_DATE_FORMAT } from "./utils/date-formats";



const _SV_DATE_FORMAT: string = "L";

const _SV_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: _SV_DATE_FORMAT,
  },
  display: {
    dateInput: "L",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "L",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

const _SV_DATETIME_FORMAT: string = "L LT";

const _SV_DATETIME_FORMATS: MatDateFormats = {
  parse: {
    dateInput: _SV_DATETIME_FORMAT,
  },
  display: {
    dateInput: "L LT",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "L LT",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SidebarComponent,
    HomeComponent,
    NotFoundComponent,
  ],

  imports: [
    CommonModule,
    CommonPipeModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    DataValidationModule,
    DataAnalysisModule,
    ScheduleModule,
    TimelineModule,
    GraphicsModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    DataExplorationModule,
    ExportModule,
    SettingsModule,
    UploadModule,
    MatSelectModule,
    MatButtonModule,
    MatPaginatorModule,
    MatMenuModule,
    MatCheckboxModule,
    CommonComponentsModule,
    GrafanaModule,
  ],

  providers: [
    CommonAppDataService,
    /* Uncomment when app needs some pre-init actions */
    // { provide: APP_INITIALIZER, useFactory: unitsInitFetch, multi: true, deps: [CommonAppDataService] },
    KeycloakService,
    ScheduleDataStoreService,
    PositionDataStoreService,
    ScheduleService,
    PositionHTTPService,
    MeterPointDataStoreService,
    MeterPointService,
    MeterDataHTTPService,
    MeterDataStoreService,
    ChartService,
    CustomerService,
    CustomerDataStoreService,
    MeasurementDataStoreService,
    MeasurementDataService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: SV_DATE_FORMAT, useValue: _SV_DATE_FORMAT },
    { provide: SV_DATETIME_FORMAT, useValue: _SV_DATETIME_FORMAT },
    { provide: MAT_DATE_FORMATS, useValue: _SV_DATE_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    FileOperationalDataStoreService,
    FileOperationalDataService,
    FileMeterDataStoreService,
    FileMeterDataService,
    VeeRulesDataStoreService,
    VeeRuleService,
    VeeStatusDataStoreService,
    VeeStatusService,
    TimeGroupService,
    TimeGroupDataStoreService,
    UnitService,
    ApplicationUnitsDataStoreService,
    ParametersService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HeadersInterceptor, multi: true },
    AnalysisAlgorithmDataStoreService,
    AnalysisAlgorithmService,
    AnalysisStatusDataStoreService,
    AnalysisStatusService,
    SensorDataService,
    SensorDataDataStoreService,
    FileTemperatureDataService,
    FileTemperatureDataDataStoreService,
    MeasurementDefinitionMappingDataStoreService,
    MeasurementDefinitionMappingService,
    {
      provide: LOCALE_ID,
      useFactory: (localeService: LocaleService) => localeService.getLanguage(),
      deps: [LocaleService],
    },
  ],

  // Bootstrap main application component
  bootstrap: [AppComponent],
})
export class AppModule {}
