import { FileExtensionEnum, IGetInterfaceType } from '@shared/types';
import { UploadFile, UploadProgress } from '@ngx-uploader';

export interface IFileTemperatureData {
    name: string;
    quantity: number;
    size: number;
}

export class FileTemperatureData implements IFileTemperatureData, UploadFile, IGetInterfaceType<IFileTemperatureData, FileTemperatureData>  {
    quantity: number;
    name: string;
    size: number;
    id: string;
    fileIndex: number;
    lastModifiedDate: Date;
    type: string;
    form: FormData;
    progress: UploadProgress;


    constructor(iFile: IFileTemperatureData) {
        if (!iFile) { throw new TypeError('MeasurementData initialization: missing input interface as constructor argument!'); }

        this.quantity = (typeof (iFile.quantity) !== 'undefined') ? iFile.quantity : undefined;
        this.name = (typeof (iFile.name) !== 'undefined') ? iFile.name : undefined;
        this.size = (typeof (iFile.size) !== 'undefined') ? iFile.size : undefined;
        this.id = '' + 1;
        this.fileIndex = 1;
        this.lastModifiedDate = undefined;
        this.type = FileExtensionEnum[0];
        this.form = undefined;
        this.progress = undefined;
    }

    getInterface(webInterface: boolean = true): IFileTemperatureData {
        const emptyValue = (webInterface) ? null : undefined;
        return {
            quantity: this.quantity,
            name: this.name,
            size: (typeof (this.size) !== 'undefined') ? this.size : emptyValue,
        };
    }
}
