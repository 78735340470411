import { Injectable } from "@angular/core";
import { InteractiveTutorialService, NextComponentName, TutorialButtonLabel } from "@shared/services/interactiveTutorialService.service";

@Injectable({ providedIn: "root" })
export class DataValidationInteractiveTutorialService {
  constructor(private interactiveTutServ: InteractiveTutorialService) {}

  getDataValidationPageInteractiveTutorialSteps(
    uploadBtnVisibility: boolean,
    seeResultBtnVisibility: boolean,
    validationBtnVisibility: boolean
  ) {
    let steps: unknown[];

    const step = this.interactiveTutServ.generateStep(
      "#newRuleBtn",
      "left",
      $localize`:@@interactive tutorial/data validation/main page/new rule btn:By clicking onto this button you will be taken to create new validation rule modal.`,
      $localize`:@@interactive tutorial/data validation/main page/new rule btn title:Create new rule`,
      [TutorialButtonLabel.NEXT]
    );

    const step1 = this.interactiveTutServ.generateStep(
      "#validationRules",
      "bottom",
      $localize`:@@interactive tutorial/data validation/main page/validation rules:Here you can preview your active validation rules(Active rules will be used in validation process).`,
      $localize`:@@interactive tutorial/data validation/main page/validation rules title:Validation rules`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
    );

    const step2 = this.interactiveTutServ.generateStep(
      "#estimationRules",
      "bottom",
      $localize`:@@interactive tutorial/data validation/main page/estimation rules:Here you can preview your active estimation rules(Active rules will be used in validation process).`,
      $localize`:@@interactive tutorial/data validation/main page/estimation rules title:Estimation rules`,
      [TutorialButtonLabel.BACK, (validationBtnVisibility || uploadBtnVisibility) ? TutorialButtonLabel.NEXT : TutorialButtonLabel.COMPLETE]
    );

    const step3 = this.interactiveTutServ.generateStep(
      "#validationBtn",
      "left",
      $localize`:@@interactive tutorial/data validation/main page/validation btn:Pleace click this button in order to start validation process.`,
      $localize`:@@interactive tutorial/data validation/main page/validation btn title:Start validation`,
      [TutorialButtonLabel.BACK, seeResultBtnVisibility ? TutorialButtonLabel.NEXT : TutorialButtonLabel.COMPLETE],
      () => {
        if (validationBtnVisibility) {
          return true;
        } else {
          return false;
        }
      }
    );

    const step4 = this.interactiveTutServ.generateStep(
      "#seeResultsBtn",
      "left",
      $localize`:@@interactive tutorial/data validation/main page/see validation results:After validation is done you can preview results by clicking this button.`,
      $localize`:@@interactive tutorial/data validation/main page/see validation results title:Preview results`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.COMPLETE],
      () => {
        if (seeResultBtnVisibility) {
          return true;
        } else {
          return false;
        }
      },
      false,
      {
        complete: () => {
          this.interactiveTutServ.canGoToNextStepSubject.next(
            NextComponentName.DATA_VALIDATION_SUMMARY
          );
        },
      }
    );

    const stepUpload = this.interactiveTutServ.generateStep(
      "#uploadBtn",
      "left",
      $localize`:@@interactive tutorial/data validation/main page/upload btn:Firstly please upload data for this position in order to carry out validation.`,
      $localize`:@@interactive tutorial/data validation/main page/upload btn title:Upload data for this position`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.COMPLETE],
    );
    if (uploadBtnVisibility) {
      steps = [step, step1, step2, stepUpload];
    } else {
      steps = [step, step1, step2, step3, step4];
    }
    return steps;
  }

  getDataValidationSummaryInteractiveTutorialSteps() {
    const step = this.interactiveTutServ.generateStep(
      "#metersFilter",
      "top",
      $localize`:@@interactive tutorial/data validation/summary page/meter filter:In this input you can filter results by Meter ID.`,
      $localize`:@@interactive tutorial/data validation/summary page/meter filter title:Meters Filter`,
      [TutorialButtonLabel.NEXT],
      undefined,
      true
    );

    const step0 = this.interactiveTutServ.generateStep(
      "#locationFilter",
      "top",
      $localize`:@@interactive tutorial/data analysis/summary/location:In this input you can filter results by Meter point Location.`,
      $localize`:@@interactive tutorial/data analysis/summary/location title:Location Filter`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT],
      undefined,
      true
    );

    const step1 = this.interactiveTutServ.generateStep(
      "#validationResultFilters",
      "top",
      $localize`:@@interactive tutorial/data validation/summary page/validation result filter:By clicking onto this buttons you can filter results by validation result flag.`,
      $localize`:@@interactive tutorial/data validation/summary page/validation result filter title:Filter results by validation result flag`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
    );

    const step2 = this.interactiveTutServ.generateStep(
      "#freezeBtn",
      "top",
      $localize`:@@interactive tutorial/data validation/summary page/freeze btn:In order to perform data export and data analysis, you will have to freeze the current position. To do this you will have to eliminate all incorrect readings.`,
      $localize`:@@interactive tutorial/data validation/summary page/freeze btn title:Freeze position`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
    );

    const step3 = this.interactiveTutServ.generateStep(
      "#goAnalysisBtn",
      "top",
      $localize`:@@interactive tutorial/data validation/summary page/analysis btn:Once the position is frozen, you will be able to proceed to data analysis.`,
      $localize`:@@interactive tutorial/data validation/summary page/analysis btn title:Go to data analysis`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
    );

    const step4 = this.interactiveTutServ.generateStep(
      "#goExportBtn",
      "top",
      $localize`:@@interactive tutorial/data validation/summary page/export btn:Once the position is frozen, you will be able to proceed to data export.`,
      $localize`:@@interactive tutorial/data validation/summary page/export btn:Go to data export`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
    );
    const step5 = this.interactiveTutServ.generateStep(
      "#metersTable",
      "top",
      $localize`:@@interactive tutorial/data validation/summary page/meters table:This table contains a collection of meter points. After clicking on a specific meter, you will see a set of readings taken by it and a graph of its consumption in a specific time period.`,
      $localize`:@@interactive tutorial/data validation/summary page/meters table title:Meter points table`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.COMPLETE]
    );
    const steps: any[] = [step, step0, step1, step2, step3, step4, step5];
    return steps;
  }

  getDataValidationCreateRuleInteractiveTutorialSteps() {
    const step = this.interactiveTutServ.generateStep(
      "#createRuleContainer",
      "right",
      $localize`:@@interactive tutorial/data validation/create rule/create rule container:Here you define name, description and recommendation texts for your own rule. This values are required.`,
      $localize`:@@interactive tutorial/data validation/create rule/create rule container title:First step of creating own rule`,
      [TutorialButtonLabel.NEXT]
    );

    const step1 = this.interactiveTutServ.generateStep(
      "#nextStepBtn",
      "right",
      $localize`:@@interactive tutorial/data validation/create rule/next step btn:When you enter the required values, you can proceed to the next step.`,
      $localize`:@@interactive tutorial/data validation/create rule/next step btn title:Proceed to next step`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.COMPLETE]
    );
    const steps: any[] = [step, step1];
    return steps;
  }

  getDataValidationCreateNextStepRuleInteractiveTutorialSteps() {
    const step = this.interactiveTutServ.generateStep(
      "#valuesAccordion",
      "bottom",
      $localize`:@@interactive tutorial/data validation/create rule next step/values accordion:By clicking onto these panels you can see available variables and operators. This values will be used to build your implementation of validation rule.`,
      $localize`:@@interactive tutorial/data validation/create rule next step/values accordion title:Available variables and operators`,
      [TutorialButtonLabel.NEXT]
    );

    const step1 = this.interactiveTutServ.generateStep(
      "#implementationInput",
      "bottom",
      $localize`:@@interactive tutorial/data validation/create rule next step/implementation:Here you define your implementation of rule.`,
      $localize`:@@interactive tutorial/data validation/create rule next step/implementation title:Rule implementation`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
    );

    const step2 = this.interactiveTutServ.generateStep(
      "#validateBtn",
      "bottom",
      $localize`:@@interactive tutorial/data validation/create rule next step/validation btn:Click this button to check if your rule has been implemented correctly.`,
      $localize`:@@interactive tutorial/data validation/create rule next step/validation btn title:Validate rule`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
    );

    const step3 = this.interactiveTutServ.generateStep(
      "#saveBtn",
      "bottom",
      $localize`:@@interactive tutorial/data validation/create rule next step/save btn:If your rule has been built correctly, you can finish the rule creation process by clicking this button.`,
      $localize`:@@interactive tutorial/data validation/create rule next step/save btn title:Save rule`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.COMPLETE]
    );

    const steps: any[] = [step, step1, step2, step3];
    return steps;
  }

  getDataValidationDetailsInteractiveTutorialSteps(
    plottable: boolean,
    idMeterPoint: number,
  ) {

    const step = this.interactiveTutServ.generateStep(
      `#readingsPreview${idMeterPoint}`,
      "bottom",
      $localize`:@@interactive tutorial/data validation/summary page/readings preview:Here you can preview the readings, edit them and view their details.`,
      $localize`:@@interactive tutorial/data validation/summary page/readings preview title:Readings Table`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT],
      undefined,
      true
    );

    const step1 = this.interactiveTutServ.generateStep(
      `#plot${idMeterPoint}`,
      "bottom",
      $localize`:@@interactive tutorial/data validation/details/plot:The graph shows the history of usages.`,
      $localize`:@@interactive tutorial/data validation/details/plot:Usages history plot`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.COMPLETE],
      () => {
        if (plottable) {
          return true;
        } else {
          return false;
        }
      },
      true
    );

    const step2 = this.interactiveTutServ.generateStep(
      `#plot${idMeterPoint}`,
      "bottom",
      $localize`:@@interactive tutorial/data validation/details/plot no data:The graph should show the history of usages, unfortunately the indicated reading does not have the appropriate amount of data.`,
      $localize`:@@interactive tutorial/data validation/details/plot no data title:Usages history plot`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.COMPLETE],
      () => {
        if (plottable) {
          return false;
        } else {
          return true;
        }
      },
      true
    );

    const steps: any[] = [
      step,
      step1,
      step2,
    ];
    return steps;
  }
}
