import { Component, OnInit, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'sv-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.sass']
})

export class ProgressComponent implements OnInit {
  @Input() labelBox: boolean = false;
  @Input() label: string = '';
  @Input() completeness: number = 0;
  @Input() unit: string = '';
  constructor() {}

  ngOnInit() {}
}
