// Angular import
import { Component, OnInit, Inject, OnDestroy, AfterViewInit, ChangeDetectorRef } from "@angular/core";
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
  FormArray
} from "@angular/forms";

// Enviroments
import { environment as env } from "@env/environment";

// Services
import { CommonAppDataService } from "@shared/services/commonAppData.service";
import { ScheduleSupportService } from "../service/scheduleSupport.service";

// Models
import { Schedule, IPosition, ISchedule, Position } from "@shared/models";
import { EventType, UtilityTypeEnum } from "@shared/types";

// External libraries
import * as moment from "moment";
import { IPositionEvent } from "@shared/models/appModels/positionEvent.model";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { RestEnumMpapper } from "@shared/models/RestSupport";
import { VeeStatusDataStoreService } from "@shared/services/dataStore/veeProcessDataStore.service";
import {
  VeeProcessStageEnum,
  VeeProcessStatusEnum,
} from "@shared/types/modelTypes/veeProcessTypes";
import { PositionDataStoreService } from "@shared/services/dataStore/positionDataStore.service";
import { Subject } from 'rxjs';
import { MeterPointByPositionIdCount } from '../create/schedule-create.component';

export interface AutomaticSchedulerForPositionHelperInterface {
  start_time: number;
  end_time: number;
  aggregation: string;
  positionId: number;
  utility_type: string | UtilityTypeEnum;
  orderInSchedule: number;
}
import { InteractiveTutorialService } from "@shared/services/interactiveTutorialService.service";
import { ScheduleInteractiveTutorialService } from "../scheduleInteractiveTutorialService.service";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "sv-schedule-edit",
  templateUrl: "schedule-edit.component.html",
  styleUrls: ["schedule-edit.component.sass"],
})
export class ScheduleEditComponent implements OnInit, OnDestroy, AfterViewInit {
  /* Input schedule element to init control values */
  inputSchedule: Schedule;

  /* Main form */
  scheduleForm: FormGroup;
  /* Controls of form */
  changeName: FormControl;
  /* Positions array */
  schedulePositionsFormArray: FormArray;
  /* Date and time controlling */
  private today: moment.Moment;
  private isFrozen = new Array<boolean>();
  private isValidated = new Array<boolean>();

  currentDate = moment(Date.now()).format('YYYY-MM-DD');
  beginDate: string;
  endDate: string;
  /* Errors */
  endDateIsNotAfterStartDate = false;
  startDateIsNotBeforeEndDate = false;
  activeSchedulerConfigError = false;
  blockSaveBtn = false;

  activeSchedulerPositionsValidationList = [];
  watchChangesInActiveSchedulerValidationListSubject: Subject<Array<any>> = new Subject();
  utilityType: UtilityTypeEnum;
  allActiveMeterPoints: number;
  selectedActiveMeterPoints: MeterPointByPositionIdCount[] = new Array<MeterPointByPositionIdCount>();

  readonly automaticScheduler = env.automaticScheduler === "true";
  startTutFunc;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { schedule: Schedule },
    public dialogRef: MatDialogRef<ScheduleEditComponent>,
    private fb: FormBuilder,
    private comServ: CommonAppDataService,
    private scheduleSupportService: ScheduleSupportService,
    private positionDataStoreService: PositionDataStoreService,
    private veeStatus: VeeStatusDataStoreService,
    private cdr: ChangeDetectorRef,
    private interactiveTutorialService: InteractiveTutorialService,
    private schedulesInteractiveTutorialService: ScheduleInteractiveTutorialService
  ) {
    /* Initailly construct form controls */
    this.changeName = new FormControl();
    this.schedulePositionsFormArray = new FormArray([]);

    this.scheduleForm = new FormGroup({});

    this.inputSchedule = this.data.schedule;

    this.today = this.comServ.getToday();
  }

  ngOnInit() {
    this.utilityType = this.comServ.getCurrentMediaType();
    this.watchChangesInActiveSchedulerValidationListSubject.subscribe(arr => {
      let validElemsCounter = 0;
      arr.forEach(element => {
        if (!element.isValid) {
          this.blockSaveBtn = true;
        } else {
          validElemsCounter += 1;
        }
      });
      if (validElemsCounter === arr.length) {
        this.blockSaveBtn = false;
      }
    });
    /* Init display values of controls - rewrite them from input schedule */
    this.initControlsValues();
    /* Build-up form group */
    this.scheduleForm = this.fb.group({
      name: this.changeName,
      positions: this.schedulePositionsFormArray,
    });
  }

  ngOnDestroy() {
    if (this.watchChangesInActiveSchedulerValidationListSubject) {
      this.watchChangesInActiveSchedulerValidationListSubject.unsubscribe();
    }
    clearTimeout(this.startTutFunc);
  }

  ngAfterViewInit() {
    this.startTutFunc = setTimeout(() => {
      this.startInteractiveTutorial();
    }, 1500);
    this.positionDataStoreService.getPositionMeterPointsCount(-99999.0, false, { active: true }).subscribe(v => {
      this.allActiveMeterPoints = v;
      this.cdr.markForCheck();
    });
    this.cdr.detectChanges();
  }
  private startInteractiveTutorial() {
    const isFrozen = !this.isFrozen[0];
    const steps = this.schedulesInteractiveTutorialService.getScheduleEditModalInteractiveTutorialSteps(isFrozen, this.inputSchedule.positions.length);
    this.interactiveTutorialService.startInteractiveTutorial(steps);
  }

  private checkUtilityType(input: Schedule): UtilityTypeEnum {
    const mapper: RestEnumMpapper<typeof UtilityTypeEnum> = new RestEnumMpapper<
      typeof UtilityTypeEnum
    >();
    if (
      this.comServ.getCurrentMediaType() ===
      mapper.getEnumAsNumber(UtilityTypeEnum, input.utilityType)
    ) {
      return mapper.getEnumAsNumber(UtilityTypeEnum, input.utilityType);
    } else {
      throw new Error(
        "Schedule inder edition and global utility type does not match!"
      );
    }
  }

  createIPosition(value: any, index: number): IPosition {
    if (value.events instanceof FormArray) {
      return {
        name: this.getNameForPosition(index),
        idPosition: value.id.value,
        orderInSchedule: index + 1,
        idSchedule: this.inputSchedule.idSchedule,
        events: value.events.controls.map(
          (eventForm): IPositionEvent => {
            return {
              date: +eventForm.controls.date.value,
              status: eventForm.controls.status.value,
              typeOf: eventForm.controls.typeOf.value,
            };
          }
        ),
        activeScheduler: value.activeScheduler.value,
      };
    } else {
      return {
        name: this.getNameForPosition(index),
        idPosition: value.id,
        orderInSchedule: index + 1,
        idSchedule: this.inputSchedule.idSchedule,
        events: value.events.map(
          (eventForm): IPositionEvent => {
            return {
              date: +eventForm.date,
              status: eventForm.status,
              typeOf: eventForm.typeOf,
            };
          }
        ),
        activeScheduler: value.activeScheduler,
      };
    }
  }

  updateSchedule(e) {
    const formValues = this.scheduleForm.value;
    const iEditedSchedule: ISchedule = {
      name: formValues.name,
      closed: this.inputSchedule.closed,
      idSchedule: this.inputSchedule.idSchedule,
      scheduleNumber: this.inputSchedule.scheduleNumber,
      utilityType: this.checkUtilityType(this.inputSchedule),
    };

    let SchObj: Schedule;
    try {
      SchObj = new Schedule(iEditedSchedule);
    } catch {
      throw new Error("Unable to create new schedule from Schedule interface");
    }

    const positions = this.generatePositionsCopy();

    const automaticSchedulerForPositions: AutomaticSchedulerForPositionHelperInterface[] = this.generateAutomaticImportForPositionConfig();

    positions.forEach((p) => SchObj.addPosition(p));
    const dataAboutScheduleAndPositions: any[] = [];
    dataAboutScheduleAndPositions.push(SchObj);
    dataAboutScheduleAndPositions.push(automaticSchedulerForPositions);
    this.dialogRef.close(dataAboutScheduleAndPositions);
  }

  generatePositionsCopy(): Position[] {
    const iPositionsCopy: IPosition[] = this.schedulePositionsFormArray.controls.map(
      (position: FormGroup, index: number) => this.createIPosition(position.controls, index)
    );

    const positions: Position[] = [];
    try {
      iPositionsCopy.forEach((p) => positions.push(new Position(p)));
    } catch {
      throw new Error("Unable to create new position from position interface");
    }
    return positions;
  }

  generateAutomaticImportForPositionConfig(): AutomaticSchedulerForPositionHelperInterface[] {
    const automaticSchedulerForPositions: AutomaticSchedulerForPositionHelperInterface[] = [];
    const positions = this.generatePositionsCopy();
    const formValues = this.scheduleForm.value;

    for (let i = 0; i < formValues.positions.length; i++) {
      if (formValues.positions[i].activeScheduler) {
        const pos = formValues.positions[i];
        const hourDateFrom = '0:00';
        const hourDateTo = '23:59';
        const dateFrom = this.getTimestamp(moment(pos.dateFrom + ' ' + hourDateFrom, 'YYYY-MM-DD HH:mm'));
        const dateTo = this.getTimestamp(moment(pos.dateTo + ' ' + hourDateTo, 'YYYY-MM-DD HH:mm'));
        automaticSchedulerForPositions.push({
          start_time: +dateFrom, end_time: +dateTo,
          aggregation: pos.aggregation, positionId: pos.id, utility_type: this.utilityType, orderInSchedule: positions[i].orderInSchedule
        });
      }
    }
    return automaticSchedulerForPositions;
  }

  initControlsValues() {
    this.changeName.setValue(this.inputSchedule.name);
    this.changeName.setValidators(Validators.required);

    /* Nested copy of events */
    this.inputSchedule.positions.forEach((position: Position) => {
      const eventsArray = new FormArray([]);
      position.events.forEach((event, idxEv: number) => {
        eventsArray.push(
          this.scheduleSupportService.createEventControlGroupFromTemplate(
            event,
            position.orderInSchedule
          )
        );
      });
      const newFormGroup: FormGroup = this.fb.group({
        name: new FormControl(position.name),
        id: new FormControl(position.idPosition),
        index: new FormControl(position.orderInSchedule),
        activeScheduler: new FormControl(position.activeScheduler),
        events: eventsArray,
        dateFrom: new FormControl(this.currentDate, this.isStartDateBeforeEndDate().bind(this)),
        dateTo: new FormControl(this.currentDate, this.isEndDateAfterStartDate().bind(this)),
        aggregation: new FormControl('1d', Validators.required)
      });
      this.schedulePositionsFormArray.push(newFormGroup);
    });

    const tmpFreeze = new Map();
    const tmpValidated = new Map();
    const idPositions = Array<number>();
    if (this.inputSchedule.positions) {
      this.inputSchedule.positions.forEach((element) => {
        if (this.selectedActiveMeterPoints.find(m => m.index === element.orderInSchedule) === undefined) {
          this.selectedActiveMeterPoints.push({ index: element.orderInSchedule, count: undefined })
        }
        this.activeSchedulerPositionsValidationList.push({ position: element, isValid: element.activeScheduler ? false : true });
        this.veeStatus
          .getVeeStateForPosition(element.idPosition, true)
          .forEach((v) => {
            idPositions.push(element.idPosition);
            tmpFreeze.set(
              element.idPosition,
              v.stage === VeeProcessStageEnum.FREEZE &&
              v.status === VeeProcessStatusEnum.DONE
            );
            tmpValidated.set(
              element.idPosition,
              v.stage === VeeProcessStageEnum.VALIDATION &&
              v.status === (VeeProcessStatusEnum.DONE || VeeProcessStatusEnum.IN_PROGRESS || VeeProcessStatusEnum.FAILED)
            );
            this.isFrozen = new Array<boolean>();
            this.isValidated = new Array<boolean>();
            idPositions.sort((a, b) => a - b); // This looks suspicious - why do we sort it for every vee status?
            idPositions.forEach((element) => {
              this.isFrozen.push(tmpFreeze.get(element));
              this.isValidated.push(tmpValidated.get(element));
            });
          })
          .catch((err) => { });
      });
    }
    this.watchChangesInActiveSchedulerValidationListSubject.next(this.activeSchedulerPositionsValidationList);
  }

  getNameForPosition(positionIndex: number): string {
    return $localize`:schedule position name|Position label@@schedules/schedule position:Schedule position #${positionIndex + 1
      }:POSITION_INDEX:`;
  }

  getNameForEvent(event: FormGroup, positionIndex: number) {
    const eventTypeName = this.scheduleSupportService.getEventTypeName(
      event.controls.typeOf.value
    );
    return $localize`:position event caption|Position event caption@@schedules/position event caption:${eventTypeName}:EVENT_TYPE_NAME: #${positionIndex + 1
      }:POSITION_INDEX:`;
  }

  offPositionsSchedulers(event: any, index: number) {
    this.checkSchedulerPositionValidation(index, event);
    this.activeSchedulerConfigError = false;
    const newPosition: IPosition = this.createIPosition(event.value, index);
    if (newPosition.idPosition !== -1) {
      this.positionDataStoreService
        .updatePosition(new Position(newPosition))
        .subscribe();
    }
  }

  checkSchedulerPositionValidation(orderInSchedule: number, offScheduler: any = null) {
    this.activeSchedulerPositionsValidationList.forEach(element => {
      if (element.position.orderInSchedule === orderInSchedule + 1) {
        if (offScheduler) {
          element.isValid = offScheduler.value.activeScheduler;
          if (!offScheduler.value.activeScheduler) {
            this.endDateIsNotAfterStartDate = false;
            this.startDateIsNotBeforeEndDate = false;
            this.activeSchedulerConfigError = false;
            this.blockSaveBtn = false;
            this.scheduleForm.markAsPristine();
            this.scheduleForm.markAsUntouched();
          }
        } else {
          element.isValid = true;
        }
      }
    });
    this.watchChangesInActiveSchedulerValidationListSubject.next(this.activeSchedulerPositionsValidationList);
  }

  cancelEditing(): void {
    this.dialogRef.close();
  }

  addPosition(): void {
    this.isFrozen.push(false);
    this.today = this.comServ.getToday();
    this.schedulePositionsFormArray.push(this.createPositionControlGroup());
    const positions = this.generatePositionsCopy();
    positions.forEach(element => {
      const elem = this.activeSchedulerPositionsValidationList.find(el => {
        return el.position.orderInSchedule === element.orderInSchedule;
      });
      if (this.selectedActiveMeterPoints.find(m => m.index === element.orderInSchedule) === undefined) {
        this.selectedActiveMeterPoints.push({ index: element.orderInSchedule, count: undefined })
      }
      if (typeof elem === 'undefined') {
        this.activeSchedulerPositionsValidationList.push({ position: element, isValid: element.activeScheduler ? false : true });
      }
    });
    this.watchChangesInActiveSchedulerValidationListSubject.next(this.activeSchedulerPositionsValidationList);
  }

  createPositionControlGroup(): FormGroup {
    const baseDate = this.today;
    const events = [
      EventType.UPLOAD,
      EventType.VALIDATION,
      EventType.EXPORT,
      EventType.ANALYSIS,
    ];

    return this.fb.group({
      id: new FormControl(-1),
      activeScheduler: new FormControl(false),
      dateFrom: new FormControl(this.currentDate, this.isStartDateBeforeEndDate().bind(this)),
      dateTo: new FormControl(this.currentDate, this.isEndDateAfterStartDate().bind(this)),
      aggregation: new FormControl('1d', Validators.required),
      events: new FormArray(
        events.map((eventType) =>
          this.scheduleSupportService.createNewEventControlGroup(
            baseDate,
            eventType,
            {
              offsetDates: true,
            }
          )
        )
      ),
    });
  }

  removePosition(event: Event, positionIndex: number) {
    /* Prevent multiclick */
    let tmp = new Array<boolean>();
    for (let i = 0; i < this.isFrozen.length; i++) {
      // WTF is this?
      if (i !== positionIndex) {
        tmp.push(this.isFrozen[i]);
      }
    }
    this.isFrozen = tmp;
    this.schedulePositionsFormArray.removeAt(positionIndex);
    event.stopPropagation();
    const positions = this.generatePositionsCopy();
    var removeElement;
    this.selectedActiveMeterPoints.forEach(element => {
      if (element.index === positionIndex) {
        removeElement = element
      } else if (positionIndex < element.index) {
        element.index = element.index - 1;
      }
    });
    this.selectedActiveMeterPoints.splice(this.selectedActiveMeterPoints.indexOf(removeElement), 1)
    let elemToDelete;
    this.activeSchedulerPositionsValidationList.forEach(elem => {
      const element = positions.find(el => {
        return el.orderInSchedule === elem.position.orderInSchedule;
      });
      if (typeof element === 'undefined') {
        elemToDelete = elem;
      }
    });
    const index = this.activeSchedulerPositionsValidationList.indexOf(elemToDelete);
    this.activeSchedulerPositionsValidationList.splice(index, 1);
    this.watchChangesInActiveSchedulerValidationListSubject.next(this.activeSchedulerPositionsValidationList);
  }

  isEndDateAfterStartDate(format = 'YYYY-MM-DD'): any {
    return (control: FormControl): { [key: string]: any } => {
      const val = moment(control.value, format, true);
      this.endDate = control.value;
      const currentDate = moment(this.currentDate, format, true);
      this.endDateIsNotAfterStartDate = false;
      // if (val.isSameOrAfter(currentDate, 'day')) {
      if (val.isSameOrAfter(this.beginDate, 'day')) {
        this.endDateIsNotAfterStartDate = false;
        return null;
      } else {
        this.endDateIsNotAfterStartDate = true;
        return { 'endDateIsNotAfterStartDate': true };
      }
    };
  }

  isStartDateBeforeEndDate(format = 'YYYY-MM-DD'): any {
    return (control: FormControl): { [key: string]: any } => {
      this.beginDate = control.value;
      const val = moment(control.value, format, true);
      this.startDateIsNotBeforeEndDate = false;
      const currentDate = moment(this.endDate, format, true);
      if (val.isSameOrBefore(currentDate, 'day')) {
        this.startDateIsNotBeforeEndDate = false;
        return null;
      } else {
        this.startDateIsNotBeforeEndDate = true;
        return { 'startDateIsNotBeforeEndDate': true };
      }
    };
  }

  validateData(index: number) {
    this.scheduleForm.updateValueAndValidity();
    const automaticSchedulerForPositions: AutomaticSchedulerForPositionHelperInterface[] = this.generateAutomaticImportForPositionConfig();
    const config = automaticSchedulerForPositions.find(element => element.orderInSchedule === index + 1);
    this.positionDataStoreService.getPositionMeasurementDataCount(-99999.0, {
      from: config.start_time,
      to: config.end_time
    }).subscribe(count => {
      if (count === 0) {
        this.selectedActiveMeterPoints.find(m => m.index === index + 1).count = 0;
        this.activeSchedulerConfigError = true;
      } else {
        this.activeSchedulerConfigError = false;
        this.checkSchedulerPositionValidation(index);
        this.positionDataStoreService.getPositionMeterPointsCount(-99999.0, false, { from: config.start_time, to: config.end_time, active: true }).subscribe(v => {
          this.selectedActiveMeterPoints.find(m => m.index === index + 1).count = v;
          this.cdr.markForCheck();
        });
      }
      this.cdr.markForCheck();
    });
  }

  openExpansionPanel(posIndex: number) {
    this.beginDate = this.scheduleForm.controls.positions.value[posIndex].dateFrom;
    this.endDate = this.scheduleForm.controls.positions.value[posIndex].dateTo;
    this.activeSchedulerConfigError = false;
  }
  getTimestamp(date: any) {
    var timezoneOffset = new Date(date).getTimezoneOffset() * 60 * 1000;
    return timezoneOffset > 0 ? new Date(date).getTime() + timezoneOffset : new Date(date).getTime() - timezoneOffset;
  }

  getMeterPointByPostitionIdCount(index: number) {
    if (this.selectedActiveMeterPoints.find(m => m.index === index + 1) !== undefined) {
      return this.selectedActiveMeterPoints.find(m => m.index === index + 1).count;
    }
    return undefined
  }
}
