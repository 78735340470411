
import { INameMapper } from '@shared/types/applicationTypes';
import { environment as env } from '@env/environment';
import { typesLocalisation as loc } from '@shared/types/localisation';

export enum MeasurementOriginEnum  {
  'FROM_FILE' = 0,
  'MANUALLY_ADDED' = 1,
  'EDITED' = 2,
  'DEFAULT' = 3,
}

export enum VeeStatusEnum {
  'REQUIRED' = 0,
  'NOT_REQUIRED' = 1,
  'DONE' = 2,
  'FAILED' = 3,
  'UNDEFINED' = 4,
}

export const measurementOriginNamesMap: INameMapper[] =
 [
  {id: MeasurementOriginEnum.FROM_FILE,
    name: loc.measurementOriginNamesMap[env.language].measurementOrigin.FROM_FILE, classType: 'from-file'},
  {id: MeasurementOriginEnum.MANUALLY_ADDED,
    name: loc.measurementOriginNamesMap[env.language].measurementOrigin.MANUALLY_ADDED, classType: 'manually-added'},
  {id: MeasurementOriginEnum.EDITED,
    name: loc.measurementOriginNamesMap[env.language].measurementOrigin.EDITED, classType: 'edited'}
 ];

 export const estimationStatusNamesMap: INameMapper[] = [
   {id: VeeStatusEnum.REQUIRED,
    name: loc.estimationStatusNamesMap[env.language].estimationStatus.REQUIRED, classType: 'estimation-required'},
   {id: VeeStatusEnum.NOT_REQUIRED,
    name: loc.estimationStatusNamesMap[env.language].estimationStatus.NOT_REQUIRED, classType: 'estimation-not-required'},
   {id: VeeStatusEnum.DONE,
    name: loc.estimationStatusNamesMap[env.language].estimationStatus.DONE, classType: 'estimation-done'},
   {id: VeeStatusEnum.FAILED,
    name: loc.estimationStatusNamesMap[env.language].estimationStatus.FAILED, classType: 'estimation-failed'},
   {id: VeeStatusEnum.UNDEFINED,
    name: loc.estimationStatusNamesMap[env.language].estimationStatus.UNDEFINED, classType: 'estimation-undefined'},
 ];

 export const validationStatusNamesMap: INameMapper[] = [
  {id: VeeStatusEnum.REQUIRED,
    name: loc.validationStatusNamesMap[env.language].validationStatus.REQUIRED, classType: 'validation-required'},
   {id: VeeStatusEnum.NOT_REQUIRED,
    name: loc.validationStatusNamesMap[env.language].validationStatus.NOT_REQUIRED, classType: 'validation-not-required'},
   {id: VeeStatusEnum.DONE,
    name: loc.validationStatusNamesMap[env.language].validationStatus.DONE, classType: 'validation-done'},
   {id: VeeStatusEnum.FAILED,
    name: loc.validationStatusNamesMap[env.language].validationStatus.FAILED, classType: 'validation-failed'},
   {id: VeeStatusEnum.UNDEFINED,
    name: loc.validationStatusNamesMap[env.language].validationStatus.UNDEFINED, classType: 'validation-undefined'},
 ];
