import { IMeterPoint } from '@shared/models/appModels/meterPoint.model';
import { MeasurementTypeEnum, MeterNodeTypeEnum } from '@shared/types/modelTypes/meterPointTypes';
import { UtilityTypeEnum } from '@shared/types/utilityType';

export const MockMeterPoint_Plain: IMeterPoint[] = [
  {
    idMeterPoint: 1,
    idCustomer: 1,
    serialNumber: 'SVR00000000001',
    meterPlacement: '42-200 Warsaw sadads 6',
    isMeterBilled: true,
    idTimeGroup: 1,
    measurementType: MeasurementTypeEnum.MANUAL,
    meterPointType: MeterNodeTypeEnum.METER,
    utilityType: UtilityTypeEnum.ELECTRICITY,
    active: true,
    idMasterMeterPoint: 5,

  },
  {
    idMeterPoint: 2,
    idCustomer: 2,
    serialNumber: 'SVR00000000002',
    isMeterBilled: true,
    idTimeGroup: 1,
    measurementType: MeasurementTypeEnum.MANUAL,
    meterPointType: MeterNodeTypeEnum.METER,
    meterPlacement: '42-200 Warsaw sadads 6',
    utilityType: UtilityTypeEnum.ELECTRICITY,
    active: true,
  },
  {
    idMeterPoint: 3,
    idCustomer: 3,
    serialNumber: 'SVR00000000003',
    isMeterBilled: true,
    idTimeGroup: 1,
    measurementType: MeasurementTypeEnum.MANUAL,
    meterPointType: MeterNodeTypeEnum.METER,
    meterPlacement: '42-200 Warsaw sadads 6',
    utilityType: UtilityTypeEnum.ELECTRICITY,
    active: true,
  },
  {
    idMeterPoint: 4,
    idCustomer: 4,
    serialNumber: 'SVR00000000004',
    isMeterBilled: true,
    idTimeGroup: 1,
    measurementType: MeasurementTypeEnum.TELEMETRY,
    meterPointType: MeterNodeTypeEnum.METER,
    meterPlacement: '42-200 Warsaw sadads 6',
    utilityType: UtilityTypeEnum.ELECTRICITY,
    active: true,
  },
  {
    idMeterPoint: 5,
    idCustomer: 5,
    serialNumber: 'SVR00000000005',
    isMeterBilled: true,
    idTimeGroup: 1,
    measurementType: MeasurementTypeEnum.MANUAL,
    meterPointType: MeterNodeTypeEnum.METER,
    meterPlacement: '42-200 Warsaw sadads 6',
    utilityType: UtilityTypeEnum.ELECTRICITY,
    active: true,
  },
  {
    idMeterPoint: 6,
    idCustomer: 6,
    serialNumber: 'SVR50000000006',
    isMeterBilled: true,
    idTimeGroup: 1,
    measurementType: MeasurementTypeEnum.MANUAL,
    meterPointType: MeterNodeTypeEnum.METER,
    meterPlacement: '42-200 Warsaw sadads 6',
    utilityType: UtilityTypeEnum.ELECTRICITY,
    active: true,
  },
  {
    idMeterPoint: 7,
    idCustomer: 7,
    serialNumber: 'SVR50000000007',
    isMeterBilled: true,
    idTimeGroup: 1,
    measurementType: MeasurementTypeEnum.MANUAL,
    meterPointType: MeterNodeTypeEnum.METER,
    meterPlacement: '42-200 Warsaw sadads 6',
    utilityType: UtilityTypeEnum.ELECTRICITY,
    active: true,
  },
  {
    idMeterPoint: 8,
    idCustomer: 8,
    serialNumber: 'SVR70000000008',
    isMeterBilled: true,
    idTimeGroup: 1,
    measurementType: MeasurementTypeEnum.MANUAL,
    meterPointType: MeterNodeTypeEnum.METER,
    meterPlacement: '42-200 Warsaw sadads 6',
    utilityType: UtilityTypeEnum.ELECTRICITY,
    active: true,
  },
  {
    idMeterPoint: 9,
    idCustomer: 9,
    serialNumber: 'SVR70000000009',
    isMeterBilled: true,
    idTimeGroup: 1,
    measurementType: MeasurementTypeEnum.MANUAL,
    meterPointType: MeterNodeTypeEnum.METER,
    meterPlacement: '42-200 Warsaw sadads 6',
    utilityType: UtilityTypeEnum.ELECTRICITY,
    active: true,
  },
  {
    idMeterPoint: 10,
    idCustomer: 10,
    serialNumber: 'SVR70000000010',
    isMeterBilled: true,
    idTimeGroup: 1,
    measurementType: MeasurementTypeEnum.MANUAL,
    meterPointType: MeterNodeTypeEnum.METER,
    meterPlacement: '42-200 Warsaw sadads 6',
    utilityType: UtilityTypeEnum.ELECTRICITY,
    active: true,
  },
  {
    idMeterPoint: 11,
    idCustomer: 1,
    serialNumber: 'SVR1856421937171',
    isMeterBilled: true,
    idTimeGroup: 1,
    measurementType: MeasurementTypeEnum.MANUAL,
    meterPointType: MeterNodeTypeEnum.METER,
    meterPlacement: '42-200 Warsaw sadads 6',
    utilityType: UtilityTypeEnum.ELECTRICITY,
    active: true,
  }
];

