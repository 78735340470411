/* Angular modules */
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GraphicsModule } from "../graphics/graphics.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// Material design controls
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatButtonModule } from "@angular/material/button";
import { MatAutocompleteModule } from "@angular/material/autocomplete";

/* Services available to timeline component only */
import { TimelineSupportService } from "./timelineSupport.service";
import { TimelineComponent } from './timeline.component';
import { TimelineCardComponent } from './timeline-card/timeline-card.component';
import { ScheduleModule } from '../schedule/schedule.module';
import { CommonPipeModule } from 'app/utils/commonPipe.module';

@NgModule({
  imports: [
    CommonModule,
    CommonPipeModule,
    GraphicsModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatNativeDateModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    ScheduleModule,
  ],
  declarations: [TimelineComponent, TimelineCardComponent],
  exports: [TimelineComponent, TimelineCardComponent],
  providers: [TimelineSupportService],
})
export class TimelineModule {}
