import { INameMapper } from '@shared/types/applicationTypes';
import { environment as env } from '@env/environment';
import { typesLocalisation as loc } from '@shared/types/localisation';

export enum VeeRuleSeverityEnum {
  'INFO' = 0,
  'WARNING' = 1,
  'ERROR' = 2,
}

export enum VeeRuleTypeEnum {
  'ESTIMATION' = 0,
  'VALIDATION' = 1,
}

export const VeeRuleSeverityNameMapping: INameMapper[] = [
  {id: VeeRuleSeverityEnum.INFO, name: loc.VeeRuleSeverityNameMapping[env.language].VeeRuleSeverityEnum.INFO, classType: 'rule-info'},
  {id: VeeRuleSeverityEnum.WARNING, name: loc.VeeRuleSeverityNameMapping[env.language].VeeRuleSeverityEnum.WARNING, classType: 'rule-warning'},
  {id: VeeRuleSeverityEnum.ERROR, name: loc.VeeRuleSeverityNameMapping[env.language].VeeRuleSeverityEnum.ERROR, classType: 'rule-erorr'},
];

export const VeeRuleTypeNameMapping: INameMapper[] = [
  {id: VeeRuleTypeEnum.ESTIMATION, name: loc.VeeRuleTypeNameMapping[env.language].VeeRuleTypeName.ESTIMATION, classType: 'estimation-rule'},
  {id: VeeRuleTypeEnum.VALIDATION, name: loc.VeeRuleTypeNameMapping[env.language].VeeRuleTypeName.VALIDATION, classType: 'validation-rule'},
];
