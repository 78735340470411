<div class="container">
    <h1>{{settingsApplication}}</h1>
    <mat-accordion class="expansion-box">
        <mat-expansion-panel [expanded]="panelsState.panelOpenState">
            <mat-expansion-panel-header>
                <i class="material-icons notranslate">{{buildIcom}}</i> {{settings}}
            </mat-expansion-panel-header>

            <div [formGroup]="settingsForm" novalidate>
                <mat-tab-group class="algorithms-tab-group">
                    <mat-tab label="{{downloadData}}">
                        <div class="row">
                            <div class="col">
                                <mat-slide-toggle id="enableDownloadingData" class="mat-slide" formControlName="activeDownloadingData"
                                    (change)="changeStartDownloadData($event)">
                                    {{downloadStart}}
                                </mat-slide-toggle>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <mat-form-field id="startDate" class="form-group">
                                    <mat-label>{{startDate}}</mat-label>
                                    <input matInput [matDatepicker]="picker"
                                        formControlName="beginDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field id="interval">
                                    <mat-label>{{intervalLabel}}</mat-label>
                                    <mat-select formControlName="interval">
                                        <mat-option [value]="24">{{day}}</mat-option>
                                        <mat-option [value]="1">{{hour}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>