import { IApiEndpointConfig, GenericHttpParams } from '@shared/types/http/httpType';
import { VeeStatus } from '@shared/models/appModels/VeeStatus.model';

export const veeStatusApiConf: IApiEndpointConfig = {
  rootApiPath: 'process/',
  paths: [
    {/* 1 */
      swaggerId: 'getProcessState',
      url: 'position/{idPosition}',
      pathParams: [
        { name: 'idPosition', pattern: '{idPosition}' }
      ],
      responseIsArray: false,
    },
    {/* 2 */
      swaggerId: 'postProcessState',
      url: 'position/{idPosition}',
      pathParams: [
        { name: 'idPosition', pattern: '{idPosition}' }
      ],
      responseIsArray: false,
    },
    {/* 3 */
      swaggerId: 'deleteProcessState',
      url: 'position/{idPosition}',
      pathParams: [
        { name: 'idPosition', pattern: '{idPosition}' }
      ],
      responseIsArray: false,
    },
    {/* 4 */
      swaggerId: 'postValidationProcessState',
      url: 'validations/measurements',
      pathParams: [],
      responseIsArray: false,
    },
  ],
};

export interface VeeStatusHttpParamG extends GenericHttpParams<VeeStatus> {
  path?: {
    idPosition?: string;
    svUtilityType?: string;
  };
}

