<div class="dialog-form">
	<h3 class="dialog-title">{{name}}</h3>

	<div *ngIf="canRenderValidationRuleSection || canRenderEstimationRuleSection">

		<div class="row">
			<div class="col date-text">
				<div *ngIf="validationFlag === ValidationResultEnum.INVALID">
					<ng-container *ngIf="this.data.veeRule">
					<p><b i18n="@@data validation/detail/validation rule desc/label">Validation Rule:</b> {{ dataValidationTranslationsHelper.loadValidationDescriptionTranslation(this.data.meterData?.idValidationRule)|| this.data.veeRule?.description}}</p>
					<p><b i18n="@@data validation/detail/validation rule desc/recommend">Recommendation: </b>
						{{ dataValidationTranslationsHelper.loadValidationRecommendationTranslation(this.data.meterData?.idValidationRule) ? dataValidationTranslationsHelper.loadValidationRecommendationTranslation(this.data.meterData?.idValidationRule) : this.data.veeRule?.recommendation}}
					</ng-container>
				</div>
				<div *ngIf="validationFlag === ValidationResultEnum.AUTOCORRECTION || estimationFlag === VeeStatusEnum.DONE">
					<ng-container *ngIf="this.data.veeRule">
					<p><b i18n="@@data validation/detail/estimation rule desc/label">Estimation rule:
					</b> {{ dataValidationTranslationsHelper.loadEstimationDescriptionTranslation(this.data.meterData?.idEstimationRule) }}
				</p>
					<p><b i18n="@@data validation/detail/estimation rule desc/recommend">Recommendation: </b>
						{{ dataValidationTranslationsHelper.loadEstimationRecommendationTranslation(this.data.meterData?.idEstimationRule) ?  dataValidationTranslationsHelper.loadEstimationRecommendationTranslation(this.data.meterData?.idEstimationRule) : this.data.veeRule?.recommendation}}
					</p>
					</ng-container>
				</div>
		
			</div>
		</div>
	</div>

	<h4 class="caption">{{labels.measurement}}</h4>

	<div class="row">
		<div class="col date-text">
			<span class="label">{{meterIdLabel}}</span>
			<span class="data">{{serialMeter}}</span>
		</div>

		<div class="col date-text">
			<span class="label">{{labels.date}}</span>
			<span class="data">{{date | svDateTime}}</span>
		</div>
	</div>

	<div class="row">
		<div class="col date-text">
			<span class="label">{{labels.status}}</span>
			<span class="data">{{selectedStatusFlagControl?.name}}</span>
		</div>
	</div>

	<div class="row">
		<div class="col date-text" *ngFor="let unit of appUnits?.units; index as i">
			<span class="label">{{unit?.description}}</span>
			<span class="data">{{ (getElementValue(unit?.name) | number:'1.0-2') || labels.NA }}
				{{ unitHelper.getElementUnit(unit?.name, measurementsDefinitions) }}
			</span>
		</div>
	</div>

	<div *ngIf="optionalValues?.length > 0">
		<h4 class="caption caption-btn">{{labels.optionalValues}}</h4>

		<div class="row">
			<div class="col date-text" *ngFor="let optVal of optionalValues">
				<span class="label">{{optVal.name}}</span>
				<span *ngIf="getTypeParam(optVal.value); else elseBlock"
					class="data">{{optVal.value | number:'1.0-2' }}{{optVal?.unit}}</span>
				<ng-template #elseBlock>
					<span class="data">{{optVal.value}}{{optVal?.unit}}</span>
				</ng-template>
			</div>
		</div>
	</div>
</div>