<div class="mainContainer">
    <div class="minorContainer" id="valuesAccordion">
        <div [@.disabled]=" disableAnimation">
            <mat-accordion>
                <mat-expansion-panel [expanded]="false">
                    <mat-expansion-panel-header>
                        <mat-panel-title i18n="@@data validatation/new rule modal/texts/available variables">
                            Available Variables
                        </mat-panel-title>
                        <mat-panel-description i18n="@@data validatation/new rule modal/texts/variable description">
                            Variable Description
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <mat-list *ngFor="let element of availableVariables; let i=index">
                        <mat-list-item>
                            <span class="moveLeft">{{ element.name }}</span>
                            <span class="moveRight">{{ element.description }}</span>
                            <mat-divider></mat-divider>
                        </mat-list-item>
                    </mat-list>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <div [@.disabled]="disableAnimation">
            <mat-accordion>
                <mat-expansion-panel [expanded]="false">
                    <mat-expansion-panel-header>
                        <mat-panel-title i18n="@@data validatation/new rule modal/texts/available operators">
                            Available Operators
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-list *ngFor="let element of operatorsForDisplay; let i=index">
                        <mat-list-item>
                            {{ element }}
                            <mat-divider></mat-divider>
                        </mat-list-item>
                    </mat-list>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
    <div class="marginTop">
        <form [formGroup]="ruleForm" (ngSubmit)="onSubmit()" novalidate class="marginTop">
            <mat-form-field class="wideTextArea" id="implementationInput">
                <textarea cdkAutosizeMinRows="4" matInput placeholder="Implementation"
                    i18n-placeholder="@@data validatation/new rule modal/texts/implementation"
                    formControlName='implementation' [matAutocomplete]="auto" (keyup)="getCaretPos($event)"
                    (input)="getCaretPos($event)" (focus)="getCaretPos($event)"
                    (click)="getCaretPos($event)"></textarea>
            </mat-form-field>
            <mat-error *ngIf="ruleForm.get('implementation').touched && ruleForm.get('implementation').errors?.required"
                i18n="@@data validatation/new rule modal/errors/required">
                Implementation is required.
            </mat-error>
            <mat-error
                *ngIf="ruleForm.get('implementation').touched && ruleForm.get('implementation').errors?.forbiddenCharAtBeginning"
                i18n="@@data validatation/new rule modal/errors/forbidden char begin">
                Forbidden character at the beggining of implementation.
            </mat-error>
            <mat-error
                *ngIf="ruleForm.get('implementation').touched && ruleForm.get('implementation').errors?.lackOfClosingBracket"
                i18n="@@data validatation/new rule modal/errors/closing bracket">
                Lack of closing bracket.
            </mat-error>
            <mat-error *ngIf="validateResp!='VALID' && validateResp!=''">
                {{ handleErrorInfo(validateResp) }}
                <button class="btn" (click)="onHandleError()"
                    i18n="@@data validatation/new rule modal/texts/okay">Okay</button>
            </mat-error>
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let option of availableVariables"
                    [value]="ruleForm.get('implementation').value.substring(0,currentCaretPos) + option.name + ruleForm.get('implementation').value.substring(currentCaretPos)">
                    {{option.name}}</mat-option>
            </mat-autocomplete>
            <button id="validateBtn" [disabled]="ruleForm.get('implementation').invalid" type="button" mat-raised-button
                color="accent" class="btn btn-create-new marginRight" (click)="onValidate()"
                i18n="@@data validatation/new rule modal/texts/validate">Validate</button>
            <button id="saveBtn"
                [disabled]="submitBtnDisabled || ruleForm.touched || ruleForm.get('implementation').invalid"
                type="submit" mat-raised-button color="accent" class="btn btn-create-new"
                i18n="@@data validatation/new rule modal/texts/submit">Submit</button>
        </form>
    </div>
</div>