import { AbstractControl, ValidatorFn, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/first';
import { MeterPointDataStoreService } from '@shared/services/dataStore/meterPointDataStore.service';

export class MeterValidator {

  static checkSerialNumber(meterDataStoreService: MeterPointDataStoreService): ValidatorFn {
    return (control: AbstractControl) => {
      return new Observable(observer => {
        control.valueChanges.flatMap(value => meterDataStoreService.getMeterPointExistenceBySerial(control.value))
        .subscribe(res => {
          if (!res) {
            observer.next({asyncInvalid: true});
          } else {
            observer.next(null);
          }
        });
      }).first();
    };
  }

  static checkExistSerialNumber(meterDataStoreService: MeterPointDataStoreService): ValidatorFn {
    return (control: AbstractControl) => {
      return new Observable(observer => {
        control.valueChanges.flatMap(value => meterDataStoreService.getMeterPointExistenceBySerial(control.value))
        .subscribe(res => {
          if (!res) {
            observer.next(null);
          } else {
            observer.next({asyncInvalid: true});
          }
        });
      }).first();
    };
  }

  static checkMeterPointExistance(meterDataStoreService: MeterPointDataStoreService): AsyncValidatorFn {

    return (control: AbstractControl) => {
      if (control.dirty) {
        let val: string;
        try {
          val = '' + control.value;
        } catch (e) {
          throw new Error('Unable to parse control value for validation');
        }
        return meterDataStoreService.getMeterPointExistenceBySerial(val, {limit: 50, offset: 0}, true)
        .switchMap<boolean, ValidationErrors | null>((existing: boolean) => {
          if (existing) {
            return Observable.of(null);
          } else {
            return Observable.of({nonExistingMeterPointSerial: true});
          }
        });
      } else {
        return Observable.of(null);
      }
    };
  }

  static checkSerialNumberAvilibility(meterDataStoreService: MeterPointDataStoreService): AsyncValidatorFn {

    return (control: AbstractControl) => {
      let val: string;
      try {
        val = '' + control.value;
      } catch (e) {
        throw new Error('Unable to parse control value for validation');
      }
      return meterDataStoreService.getMeterPointExistenceBySerial(val, {limit: 50, offset: 0}, true)
      .switchMap<boolean, ValidationErrors | null>((existing: boolean) => {
        if (existing) {
          return Observable.of({duplicateSerialNumber: true});
        } else {
          return Observable.of(null);
        }
      });
    };
  }

}
