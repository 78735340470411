<div class="custom-container">
  <div id="selectVariables">
  <h3 i18n="@@export/custom billings modal/choose data model variables">Choose data model variables:</h3>
  <br />
  <sv-input-with-autocomplete-and-chips *ngIf="canGenerateComponent"
    (selectedDataEventEmitter)="selectedDataEvent($event)" [wrongElement]="wrongVariable"
    [elementRequired]="variablesRequired" [allData]="variablesForComponent"
    [inputPlaceholder]="addVariablesPlaceholder"
    (typingEventEmitter)="onTypingEvent($event)"
    [fetchingData] = "fetchingData"></sv-input-with-autocomplete-and-chips>
  <br />
  </div>
  <div class="custom-btn">
    <div matTooltip="You have to add some values ​​to be able to move on." i18n-matTooltip="@@export/custom billing modal/add values tooltip" [matTooltipDisabled]="!(variables.length===0)" matTooltipPosition="below"
		matTooltipShowDelay="500" class="tooltip-div">
    <button id="summaryBtn" mat-raised-button color="accent" class="btn btn-action" (click)="onGenerate()"
      [disabled]="variables.length===0" i18n="@@export/custom billings modal/summary">
      Summary
    </button>
    </div>
  </div>
</div>