<h1>{{dataModelTxt.MAPPER}}</h1>
<ng-container *ngIf="colNames.length !== 0">
<div [@.disabled]="disableAnimation" class="mapper-container">
    <form formArray="mappingForm">
    <mat-accordion>
            <mat-expansion-panel (opened)="openPrimaryModelExpansionPanel($event)" (closed)="closePrimaryModelExpansionPanel($event)" id="primaryModel" [expanded]="false" [disabled]="measurementMapperService.isExpansionPanelDisbaled">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                            {{dataModelTxt.PRIMARY_MODEL}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-list>
                    <h4>{{dataModelTxt.MANDATORY_VARIABLES}}</h4>
                        <ng-container *ngFor="let element of measDefs | async; let i=index">
                        <ng-container *ngIf="element.isPrimary || element.isMetadata">
                        <mat-list-item class="list-item-style" id="list-item-container{{i}}">
                            <span id="SpanElement{{i}}"  class="element-on-the-left" existingMappingsDirective>{{ element.name }}</span>
                            <span class="element-on-the-center"> {{dataModelTxt.MAPPED_TO}} </span>
                            <mat-form-field>
                                <input type="text" matInput [formControl]="myControl" name="{{element.name}}" id="myControl{{i}}" [matAutocomplete]="auto"
                                     #input>
                                     <mat-icon id="primaryIcon{{i}}" matSuffix *ngIf="measurementMapperService.exsistingMappingsTooltips[i]" matTooltip="{{dataModelTxt.EXISTING_MAPPING}}" class="info-btn material-icons notranslate">{{infoIcon}}</mat-icon>
                            </mat-form-field>
                            <button mat-raised-button (click)="onDelete(i)" color="accent" id="myDeleteMappingBtn{{i}}" class="btn" color="primary" style="margin-left:2.5vw">{{dataModelTxt.DELETE_MAPPING}}</button>
                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option *ngFor="let option of filteredOptions | async" [value]="option"
                                    class="small-font">
                                    {{option}}
                                </mat-option>
                                <mat-option *ngIf="showBtn" class="small-font" matTooltip="{{dataModelTxt.LACK_OF_COLUMN_IN_FILE}}">
                                    <span>{{dataModelTxt.LACK_OF_COLUMN_IN_FILE}}</span>
                                </mat-option>
                            </mat-autocomplete>
                            <mat-divider></mat-divider>
                        </mat-list-item>
                    </ng-container>
                    </ng-container>
                    </mat-list>
                </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion>
            <mat-expansion-panel id="optionalParameters" [expanded]="false" [disabled]="measurementMapperService.isExpansionPanelDisbaled">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                            {{dataModelTxt.OPTIONAL_PARAMETERS}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-list>
                    <h4>{{dataModelTxt.OPTIONAL_VARIABLES}}</h4>
                    <ng-container *ngFor="let element of measDefs | async; let i=index">
                    <ng-container *ngIf="!(element.isPrimary || element.isMetadata)">
                    <mat-list-item class="list-item-style" id="list-item-container{{i}}">
                        
                        <span id="SpanElement{{i}}"  class="element-on-the-left" existingMappingsDirective>{{ element.name }}</span>
                        <span class="element-on-the-center"> {{dataModelTxt.MAPPED_TO}} </span>
                        <mat-form-field >
                            
                            <input type="text" matInput [formControl]="myControl" name="{{element.name}}" id="myControl{{i}}" [matAutocomplete]="auto"
                                 #input>
                            
                                 <mat-icon matSuffix *ngIf="measurementMapperService.exsistingMappingsTooltips[i]" matTooltip="{{dataModelTxt.EXISTING_MAPPING}}">{{infoIcon}}</mat-icon>
                               
                        </mat-form-field>

                        <button mat-raised-button (click)="onDelete(i)" color="accent" id="myDeleteMappingBtn{{i}}" class="btn" color="primary" style="margin-left:1vw">{{dataModelTxt.DELETE_MAPPING}}</button>
                        
                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option"
                                class="small-font">
                                {{option}}
                            </mat-option>
                            <mat-option *ngIf="showBtn" class="small-font" matTooltip="{{dataModelTxt.LACK_OF_COLUMN_IN_FILE}}">
                                <span>{{dataModelTxt.LACK_OF_COLUMN_IN_FILE}}</span>
                            </mat-option>
                        </mat-autocomplete>
                        <mat-divider></mat-divider>
                    </mat-list-item>
                </ng-container>
                </ng-container>
                    </mat-list>
                </mat-expansion-panel>
    </mat-accordion>
</form>
    <div class="btn-right">
            <mat-error class="element-on-the-center" *ngIf="mappingForm.invalid">
                    <div *ngIf="mappingError">{{dataModelTxt.MAPPING_ERROR}}</div>
                    <div *ngIf="mappingErrorNull">{{dataModelTxt.PRIMARY_VARIABLES_MUST_BE_MAPPED}}</div>
                    <div *ngIf="mappingErrorCheckExistenceOfVariable">{{dataModelTxt.NOT_EXISTING_COLUMN}}</div>
            </mat-error>
            <button id="validateBtn" [disabled]="measurementMapperService.isExpansionPanelDisbaled" mat-raised-button color="accent" (click)="onValidate()" class="btn btn-create-new element-on-the-center">{{dataModelTxt.VALIDATE}}</button>
            <button id="mapBtn" [disabled]="disableMapButton || mappingForm.dirty" mat-raised-button color="accent" (click)="onSubmit(form)" class="btn btn-create-new">{{dataModelTxt.MAP}}</button>
        </div>
</div>
</ng-container>
