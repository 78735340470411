import { Component, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';

/* External libraries */
import "rxjs/add/operator/switchMap";

import { Subscription } from "rxjs/Subscription";

/* Application services */
import { ScheduleDataStoreService } from "@shared/services/dataStore/scheduleDataStore.service";

// Env
import { environment } from '@env/environment';
import { VeeStatusDataStoreService } from '@shared/services/dataStore/veeProcessDataStore.service';
import { DataExploarationLocalisation as eLoc } from '../data-exploration-localisation';
import { MeterPointDataSource } from './dataSource/meterPointDataSource';
import { ActivatedRoute } from '@angular/router';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { typesLocalisation } from '@shared/types/localisation';
import { VeeStatus } from '@shared/models/appModels/VeeStatus.model';
import { VeeProcessStageEnum } from '@shared/types/modelTypes/veeProcessTypes';
import { AnalysisAlgorithm } from '@shared/models/appModels/analysisAlgorithm.model';
import { AnalysisAlgorithmDataStoreService } from '@shared/services/dataStore/analysisAlgorithmsDataStore.service';
import { AnalysisAlgorithmTypeEnum } from '@shared/types/modelTypes/analysisAlgorithmTypes';
import { InteractiveTutorialService } from '@shared/services/interactiveTutorialService.service';
import { DataExplorationInteractiveTutorialService } from '../data-exploration-interactive-tutorial.service';
import { debounceTime } from 'rxjs/operators';


@Component({
  selector: "sv-data-exploration-page",
  templateUrl: "data-exploration-page.component.html",
  styleUrls: ["data-exploration-page.component.sass"],
})
export class DataExplorationPageComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly debugMode: boolean = environment.debug;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  private scheduleId: number;
  private positionId: number;
  explorationButtonDisabled: boolean = true;
  explorationButtonVisable: boolean = false;
  showNodesPreview: boolean = false;
  filterForm: FormGroup;
  changeFilterForm: FormGroup;
  dataSourceExploration: MeterPointDataSource;
  diagramId: number = undefined;
  readonly itemsPage = typesLocalisation.ItemsPage[environment.language].texts;
  actualSelect: {
    idSchedule?: number;
    idPosition?: number;
  };
  panelsState: boolean = true;
  actual_position: number;
  queryParams$: Subscription;
  scheduleSelect$: Subscription;
  positionsSelect$: Subscription;
  readonly tableHeaders =
    eLoc[environment.language].texts.asset.assetDataPreview.tableHeaders;
  readonly header = eLoc[environment.language].texts.page.dataExploration;
  readonly basicDashbords =
    eLoc[environment.language].texts.page.basicDashboards;
  readonly generateResults =
    eLoc[environment.language].texts.page.generateResults;
  readonly exploration = eLoc[environment.language].texts.page.exploration;
  readonly algorithmsMdIcon: string = "equalizer";
  listExplorationAlgorithm: Array<AnalysisAlgorithm>;
  freeze: boolean;
  title = new Map();
  maps = [["Flow and return temperature", $localize`:Dashboard title in Grafana|Dashboard title in Grafana@@exploration/flow and return temperature:Flow and return temperature`],
  ["Consumption for the meter", $localize`:Dashboard title in Grafana|Dashboard label in Grafana@@exploration/consumption for the meter:Consumption for the meter`],
  ["Node preview",$localize`:Dashboard title in Grafana|Dashboard label in Grafana@@exploration/node preview:Node preview`]]
  showTutorialSubscription: Subscription;

  constructor(
    public scheduleDataStoreService: ScheduleDataStoreService,
    private fb: FormBuilder,
    public veeStatusService: VeeStatusDataStoreService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private analysisAlgorithmService: AnalysisAlgorithmDataStoreService,
    private interactiveTutorialService: InteractiveTutorialService,
    private dataExplorationInteractiveTutorialService: DataExplorationInteractiveTutorialService
  ) {
    this.filterForm = this.fb.group({
      scheduleSelect: new FormControl(),
      positionSelect: new FormControl(),
    });
    this.actualSelect = { idPosition: undefined, idSchedule: undefined };
    this.listExplorationAlgorithm = new Array<AnalysisAlgorithm>();
  }

  ngOnInit() {
    this.maps.forEach(elements => {
      this.title.set(elements[0], elements[1]);
    });
    this.route.queryParams.subscribe(params => {
      const positionId = +params.position || -1;
      const scheduleId = +params.schedule || -1;
      if (positionId !== -1) {
        this.actual_position = +params.position;
        this.cdr.markForCheck();
      }
      if (scheduleId !== -1) {
        this.scheduleId = scheduleId;
      }
    });

    this.analysisAlgorithmService.getAnalysisAlgorithmsTypeExploration().subscribe(algorithms => {
      this.listExplorationAlgorithm = algorithms;
      this.listExplorationAlgorithm.sort((a, b) => a.idAnalysisAlgorithm - b.idAnalysisAlgorithm);
      var elem = this.listExplorationAlgorithm.find(algorithm => algorithm.enabled);
      if (elem) {
        this.diagramId = elem.idAnalysisAlgorithm;
      }
      this.cdr.markForCheck();
    });
  }

  ngAfterViewInit() {
    this.showTutorialSubscription = this.interactiveTutorialService.showTutorialSubject.pipe(debounceTime(1500)).subscribe(isActive => {
      if (this.interactiveTutorialService.getActiveComponent(this.route.routeConfig.path) && isActive) {
        this.startInteractiveTutorial();
      }
    });
  }

  startInteractiveTutorial() {
    const steps = this.dataExplorationInteractiveTutorialService.getDataExplorationMainPageInteractiveTutorialSteps();
    this.interactiveTutorialService.startInteractiveTutorial(steps);
  }

  ngOnDestroy() {
    if (this.showTutorialSubscription) {
      this.showTutorialSubscription.unsubscribe();
    }
  }

  handleSchedulePositionSelection(e: { idSchedule?: number, idPosition?: number }) {
    this.actualSelect.idSchedule = e.idSchedule;
    this.actualSelect.idPosition = e.idPosition;
    if (e.idPosition && e.idSchedule) {
      this.positionId = e.idPosition;
      this.scheduleId = e.idSchedule;
      this.actualSelect.idSchedule = e.idSchedule;
      this.actual_position = e.idPosition;
      this.explorationButtonVisable = false;
      this.showNodesPreview = false;
      this.veeStatusService
        .getVeeStateForPosition(this.actual_position, true)
        .subscribe((vs: VeeStatus | {}) => {
          if (
            Object.keys(vs).length !== 0 &&
            (vs as VeeStatus).stage === VeeProcessStageEnum.FREEZE
          ) {
            this.explorationButtonDisabled = false;
            this.freeze = true;
          } else {
            this.explorationButtonDisabled = true;
            this.freeze = false;
          }
          this.cdr.markForCheck();
        });
    }
  }

  onRuleClick($event: MatCheckboxChange, rule: AnalysisAlgorithm) {
    this.explorationButtonVisable = false;
    this.showNodesPreview = false;
    if ($event.checked === false) {
      this.analysisAlgorithmService
        .toggleAnalysisAlgorithm(
          rule.idAnalysisAlgorithm,
          false,
          AnalysisAlgorithmTypeEnum.EXPLORATION,
          { emitEvent: true }
        )
        .subscribe();
      this.explorationButtonDisabled = true;
    } else {
      this.diagramId = rule.idAnalysisAlgorithm;
      this.analysisAlgorithmService
        .getAnalysisAlgorithmsTypeExploration()
        .map((v) => {
          v.forEach((element) => {
            this.analysisAlgorithmService
              .toggleAnalysisAlgorithm(
                element.idAnalysisAlgorithm,
                false,
                AnalysisAlgorithmTypeEnum.EXPLORATION,
                { emitEvent: true }
              )
              .subscribe();
          });
        })
        .subscribe((l) => {
          this.analysisAlgorithmService
            .toggleAnalysisAlgorithm(
              rule.idAnalysisAlgorithm,
              $event.checked,
              AnalysisAlgorithmTypeEnum.EXPLORATION,
              { emitEvent: true }
            )
            .subscribe();
        });
      if (this.freeze) {
        this.explorationButtonDisabled = false;
      }
    }
    this.listExplorationAlgorithm.forEach((e) => (e.enabled = false));
    this.listExplorationAlgorithm.find(
      (e) => e.idAnalysisAlgorithm == rule.idAnalysisAlgorithm
    ).enabled = $event.checked;
    this.listExplorationAlgorithm.sort(
      (a, b) => a.idAnalysisAlgorithm - b.idAnalysisAlgorithm
    );
  }

  showResults() {
    const nodesPreview = this.listExplorationAlgorithm.find(
      (algorithm) => algorithm.name === "Node preview"
    );
    if (nodesPreview.enabled) {
      this.showNodesPreview = true;
    } else {
      this.explorationButtonVisable = true;
    }
  }

  getTranslatedAlgorithmName(name: string) {
    return this.title.get(name);
  }
}
