import { Directive, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { UploadClass } from './UploadClass.class';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[ngFileSelect]'
})
export class NgFileSelectDirective extends UploadClass implements OnInit, OnDestroy {

  constructor(public elementRef: ElementRef) {
    super(elementRef);
  }

  ngOnInit() {
    super.ngOnInit();
    this.el.addEventListener('change', this.fileListener, false);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.el) {
      this.el.removeEventListener('change', this.fileListener, false);
      this._sub.forEach(sub => sub.unsubscribe());
    }
  }

  fileListener = () => {
    if (this.el.files) {
      this.upload.handleFiles(this.el.files)
      .then(() => {
        this.el.value = null;
      });
    }
  }
}
