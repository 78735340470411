
import { UtilityTypeEnum } from '@shared/types/utilityType';
import { IPrintableNameMapper } from '@shared/types/applicationTypes';

export function getUtilityTypesNames(): IPrintableNameMapper[] {
  const utilityTypeNames: IPrintableNameMapper[] = [];
  for (const utilityType of Object.values(UtilityTypeEnum)) {
    if (typeof utilityType === 'string') {
      utilityTypeNames.push(getUtilityTypeName(UtilityTypeEnum[utilityType]));
    }
  }
  return utilityTypeNames;
}

export function getUtilityTypeName(choice: UtilityTypeEnum): IPrintableNameMapper {
  switch (choice) {
    case UtilityTypeEnum.HEAT:
      return {
        id: UtilityTypeEnum.HEAT,
        name: $localize`:@@utility type names/heat:Heat`,
        classType: 'heat-media',
        icon: 'wb_sunny',
        isPrintable: true,
      };
    case UtilityTypeEnum.WATER:
      return {
        id: UtilityTypeEnum.WATER,
        name: $localize`:@@utility type names/water:Water`,
        classType: 'water-media',
        icon: 'local_drink',
        isPrintable: true,
      };
    case UtilityTypeEnum.ELECTRICITY:
      return {
        id: UtilityTypeEnum.ELECTRICITY,
        name: $localize`:@@utility type names/electricity:Electricity`,
        classType: 'electricity-media',
        icon: 'power',
        isPrintable: true,
      };
    case UtilityTypeEnum.OTHER:
      return {
        id: UtilityTypeEnum.OTHER,
        name: $localize`:@@utility type names/other:Other media type`,
        classType: 'other-media',
        icon: 'bubble_chart',
        isPrintable: false,
      };
  }
 }

