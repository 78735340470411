import { DataSource } from '@angular/cdk/collections';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import 'rxjs/add/observable/merge';

import { MatPaginator } from '@angular/material/paginator';

import { PositionDataStoreService } from '@shared/services/dataStore/positionDataStore.service';
import { MeterPoint } from '@shared/models/appModels/meterPoint.model';
import { typesLocalisation } from '@shared/types/localisation';
import { environment } from '@env/environment';

export class ReadlistDataSource extends DataSource<MeterPoint> {
  private filterChange$ = new BehaviorSubject('all   all');
  renderedData: MeterPoint[] = [];
  filteredData: MeterPoint[] = [];
  allData: MeterPoint[] = [];
  paginatorLength = 0;
  readonly debugMode: boolean = environment.debug;
  readonly itemsPage = typesLocalisation.ItemsPage[environment.language].texts;
  get filter(): string { return this.filterChange$.value; }
  set filter(filter: string) { this.filterChange$.next(filter); }

  constructor(
    private positionDataStore: PositionDataStoreService,
    private id: number,
    private paginator: MatPaginator) {
    super();
    this.filterChange$.subscribe(() => this.paginator.pageIndex = 0);
  }
  connect(): Observable<MeterPoint[]> {
    let meterPointsLength;
    return this.positionDataStore.getPositionMeterPointsCount(this.id).concatMap<number, MeterPoint[]>((num: number) => {
       meterPointsLength = num;
        const displayDataChanges = [
    this.positionDataStore.getPositionMeterPoints(this.id, meterPointsLength, 0 ).map(v => {
        this.paginatorLength = v.length;
        this.filteredData = v;
      }),
      this.paginator._intl.itemsPerPageLabel = this.itemsPage.itemsPage,
      this.paginator._intl.nextPageLabel = this.itemsPage.nextPage,
      this.paginator._intl.previousPageLabel = this.itemsPage.previousPage,
      this.paginator.page,
      this.filterChange$
    ];
      return Observable.merge(...displayDataChanges).map(() => {
      let dataTmp: MeterPoint[] = [];
      const tmpFilter = this.filter.split('   ');
      if (tmpFilter[0] === 'all' || tmpFilter[0] === '') {
        if (tmpFilter[1] === 'all' || tmpFilter[1] === '') {
          dataTmp = [...this.filteredData];
          this.paginatorLength = dataTmp.length;
        } else {
          dataTmp = this.filteredData.filter(a => a.meterPlacement && a.meterPlacement.toLocaleLowerCase().includes(tmpFilter[1].toLocaleLowerCase()));
          this.paginatorLength = dataTmp.length;
        }

      } else {
        if (tmpFilter[1] === 'all' || tmpFilter[1] === '') {
          dataTmp = this.filteredData.filter(a => a.serialNumber.toLocaleUpperCase().startsWith(tmpFilter[0].toLocaleUpperCase()));
          this.paginatorLength = dataTmp.length;
        } else {
          dataTmp = this.filteredData.filter(a => a.serialNumber.toLocaleUpperCase().startsWith(tmpFilter[0].toLocaleUpperCase()) &&
            a.meterPlacement && a.meterPlacement.toLocaleLowerCase().includes(tmpFilter[1].toLocaleLowerCase()));
          this.paginatorLength = dataTmp.length;
        }
      }
      this.allData = dataTmp;
      const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
      this.renderedData = dataTmp.splice(startIndex, this.paginator.pageSize);
      return this.renderedData;
    });
    }

      );



  }
  disconnect() { }
}





