<div class="container">
        <h1>{{ dataModelTxt.DATA_MODEL }}</h1>
        <br>
    <div class="container-btn">

        <button id="addNewVariableBtn" mat-raised-button color="accent" (click)="onAddNewVariable()" class="btn btn-create-new btn-right">
            {{ dataModelTxt.NEW_VARIABLE }}
        </button>

    </div>
    <div class="mat-elevation-z8">
        <mat-table id="mainTable" #table [dataSource]="dataSource">

            <!-- Name Column -->
            <ng-container matColumnDef="Name">
                <mat-header-cell mat-header-cell *matHeaderCellDef> {{dataModelTxt.NAME}} </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
            </ng-container>

            <!-- ParsedName Column -->
            <ng-container matColumnDef="ParsedName">
                <mat-header-cell mat-header-cell *matHeaderCellDef> {{dataModelTxt.PARSED_NAME}} </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let element"> {{element.parsedName}} </mat-cell>
            </ng-container>

            <!-- Description Column -->
            <ng-container matColumnDef="Description">
                <mat-header-cell mat-header-cell *matHeaderCellDef> {{dataModelTxt.DESCRIPTION}} </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let element"> {{element.description}} </mat-cell>
            </ng-container>

            <!-- Unit Column -->
            <ng-container matColumnDef="Unit">
                <mat-header-cell mat-header-cell *matHeaderCellDef> {{dataModelTxt.UNIT}} </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let element"> {{element.unit}} </mat-cell>
            </ng-container>

            <!-- Type Column -->
            <ng-container matColumnDef="Type">
                <mat-header-cell mat-header-cell *matHeaderCellDef> {{dataModelTxt.TYPE}} </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let element"> {{element.type}} </mat-cell>
            </ng-container>

            <!-- IsBilled Column -->
            <ng-container matColumnDef="IsBilled">
                <mat-header-cell mat-header-cell *matHeaderCellDef> {{dataModelTxt.IS_BILLED}} </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let element"> {{element.isBilled}} </mat-cell>
            </ng-container>

            <!-- IsCumulative Column -->
            <ng-container matColumnDef="IsCumulative">
                <mat-header-cell mat-header-cell *matHeaderCellDef> {{dataModelTxt.IS_CUMULATIVE}} </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let element"> {{element.isCumulative}} </mat-cell>
            </ng-container>

            <!-- IsPrimary Column -->
            <ng-container matColumnDef="IsPrimary">
                <mat-header-cell mat-header-cell *matHeaderCellDef> {{dataModelTxt.IS_PRIMARY}} </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let element"> {{element.isPrimary}} </mat-cell>
            </ng-container>

            <!-- More Column -->
            <ng-container matColumnDef="More">
                <mat-header-cell mat-header-cell *matHeaderCellDef>{{dataModelTxt.MORE}} </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let element">
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="icon-button with a menu">
                        <mat-icon class="material-icons notranslate">{{menuIcon}}</mat-icon>
                        <mat-menu #menu="matMenu" class="menu-box">
                            <button (click)="onEdit(element)" mat-menu-item>
                                    <i class="material-icons notranslate">{{editIcon}}</i>
                                <span class="txt-in-menu">{{dataModelTxt.EDIT}}</span>
                            </button>
                        </mat-menu>
                    </button>
                </mat-cell>
            </ng-container>

            <mat-header-row mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <mat-paginator #paginator [pageSizeOptions]="[5, 10, 15]" showFirstLastButtons class="notranslate"></mat-paginator>
    </div>
</div>