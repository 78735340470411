import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef,
  AfterViewInit,
} from "@angular/core";
/* External libraries */
import { Observable, Subscription, zip } from "rxjs";
import { concatMap, debounceTime } from "rxjs/operators";

/* Application services */
import { ApplicationUnitsDataStoreService } from "@shared/services/dataStore/applicationUnitsDataStore.service";
import { CommonAppDataService } from "@shared/services/commonAppData.service";
import { CustomerDataStoreService } from "@shared/services/dataStore/customerDataStore.service";
import { FileMeterDataStoreService } from "@shared/services/dataStore/fileMeterDataDataStore.service";
import { MeterPointDataStoreService } from "@shared/services/dataStore/meterPointDataStore.service";
import { PositionDataStoreService } from "@shared/services/dataStore/positionDataStore.service";
import { VeeStatusDataStoreService } from "@shared/services/dataStore/veeProcessDataStore.service";

/* Application datatypes */
import { Schedule } from "@shared/models/appModels/schedule.model";
import { Position } from "@shared/models/appModels/position.model";
import { MeterPoint } from "@shared/models/appModels/meterPoint.model";

// Env
import { environment } from "@env/environment";
import { UtilityTypeEnum } from "@shared/types";
import { VeeStatus } from "@shared/models/appModels/VeeStatus.model";
import { VeeProcessStageEnum } from "@shared/types/modelTypes/veeProcessTypes";
import { FormControl, FormGroup, FormBuilder } from "@angular/forms";
import * as moment from "moment";
import { MeasurementDataModel } from '@shared/models/appModels/measurementDataModel.model';
import { MatStepper } from '@angular/material/stepper';
import { InteractiveTutorialService } from "@shared/services/interactiveTutorialService.service";
import { ActivatedRoute } from "@angular/router";
import { ExportInteractiveTutorialService } from "./export-interactive-tutorial.service";


@Component({
  // tslint:disable-next-line:component-selector
  selector: "sv-export",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "export.component.html",
  styleUrls: ["export.component.sass"],
})
export class ExportComponent
  implements OnInit, OnDestroy, AfterViewInit {
  mediaSubject$: Subscription;
  showTutorialSubscription: Subscription;

  readonly debugMode: boolean = environment.debug;

  @ViewChild(MatStepper, { static: true }) steppper: MatStepper;

  schedules: Schedule[];
  positions: Position[];
  selectDate: FormGroup;
  from: FormControl;
  to: FormControl;

  exportButtonVisible: boolean;
  dataAvailable: boolean;

  readonly energy;

  selectedPosition = 0;

  exportDisabled: boolean = true;
  dataOnPositionAvailable: boolean = false;
  validScheduleAndPositionSelection: boolean = false;
  utilityType: UtilityTypeEnum;
  selectedMeterPointsTab: MeterPoint[];
  selectedVariablesTab: MeasurementDataModel[];
  firstStepCompletedBoolean: boolean = false;
  secondStepCompletedBoolean: boolean = false;
  selectedExportOption: string = 'GenerateCsvFile';
  displayHeadersInCsv: boolean = false;
  selectedColumnsToCustomBilling: string[] = [];
  constructor(
    public fileMeterDataDataStoreService: FileMeterDataStoreService,
    public positionDataStoreService: PositionDataStoreService,
    public meterPointDataStoreService: MeterPointDataStoreService,
    public customerDataStoreService: CustomerDataStoreService,
    public veeStatusService: VeeStatusDataStoreService,
    public cs: CommonAppDataService,
    private unitsService: ApplicationUnitsDataStoreService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private interactiveTutorialService: InteractiveTutorialService,
    private exportInteractiveTutorialService: ExportInteractiveTutorialService
  ) {
    this.exportButtonVisible = false;
    this.dataAvailable = false;
    if (this.cs.getCurrentMediaType() !== UtilityTypeEnum.WATER) {
      //FIXME
      this.energy = $localize`:@@export/export table header energy:Energy`;
    } else {
      this.energy = $localize`:@@export/export table header volume:Volume`;
    }
    this.from = new FormControl();
    this.to = new FormControl();

    this.selectDate = this.fb.group({
      from: this.from,
      to: this.to,
    });
  }

  onMediaTypeChange(): Observable<UtilityTypeEnum> {
    return this.cs.getObservable("utilityType");
  }

  ngOnInit() {
    this.utilityType = this.cs.getCurrentMediaType();
    this.veeStatusService
      .getVeeStateForPosition(this.selectedPosition, true)
      .subscribe((vs: VeeStatus | {}) => {
        if (
          Object.keys(vs).length !== 0 &&
          (vs as VeeStatus).stage === VeeProcessStageEnum.FREEZE
        ) {
          this.setStartAndEndDate();
        }
      });
    this.mediaSubject$ = this.onMediaTypeChange().subscribe(
      (mediaType: UtilityTypeEnum) => {
        this.unitsService
          .getApplicationUnits(this.cs.getCurrentMediaType())
          .subscribe((units) => {
          });
      }
    );

  }

  ngAfterViewInit(): void {
    this.showTutorialSubscription = this.interactiveTutorialService.showTutorialSubject.pipe(debounceTime(1500)).subscribe(isActive => {
      if (this.interactiveTutorialService.getActiveComponent(this.route.routeConfig.path) && isActive) {
        if (this.exportButtonVisible) {
          this.startInteractiveTutorial();
        }
      }
    });
  }

  startInteractiveTutorial() {
    const steps = this.exportInteractiveTutorialService.getExportMainComponentInteractiveTutorialSteps(this.dataOnPositionAvailable, this.selectedExportOption);
    this.interactiveTutorialService.startInteractiveTutorial(steps);
  }

  handleSchedulePositionSelection(e: {
    idSchedule?: number;
    idPosition?: number;
  }) {
    if (e.idPosition && e.idSchedule) {
      this.dataOnPositionAvailable = false;
      this.selectedPosition = e.idPosition;
      this.validScheduleAndPositionSelection = true;
      this.veeStatusService
        .getVeeStateForPosition(this.selectedPosition, true)
        .subscribe((vs: VeeStatus | {}) => {
          if (
            Object.keys(vs).length !== 0 &&
            (vs as VeeStatus).stage === VeeProcessStageEnum.FREEZE
          ) {
            this.setStartAndEndDate();
            this.exportButtonVisible = true;
            this.exportDisabled = false;
          } else {
            this.exportButtonVisible = true;
            this.exportDisabled = true;
          }
          this.cdr.markForCheck();
        });
      this.positionDataStoreService.getPositionMeasurementDataCount(this.selectedPosition).subscribe( count => {
        if (count > 0) {
          this.dataOnPositionAvailable = true;
          this.cdr.detectChanges();
        }
      });
    }
  }


  setStartAndEndDate() {
    zip(
      this.positionDataStoreService.getPositionMeterData(
        this.selectedPosition,
        { limit: 1, offset: 0 }
      ),
      this.positionDataStoreService
        .getPositionMeterDataCount(this.selectedPosition)
        .pipe(
          concatMap((meterDataCount) =>
            this.positionDataStoreService.getPositionMeterData(
              this.selectedPosition,
              {
                limit: 1,
                offset:
                  meterDataCount > 0 ? meterDataCount - 1 : meterDataCount,
              }
            )
          )
        )
    ).subscribe(([first, end]) => {
      if (first.length !== 0 && end.length !== 0) {
        this.selectDate.setValue({
          from: moment.utc(first[0].timestamp),
          to: moment.utc(end[0].timestamp),
        });
      }
    });
  }

  selectedMeterPoints(meterPoints: MeterPoint[]) {
    this.selectedMeterPointsTab = meterPoints;
  }

  selectedVariables(variables: MeasurementDataModel[]) {
    this.selectedVariablesTab = variables;
  }

  firstStepCompleted(completed: boolean) {
    this.firstStepCompletedBoolean = completed;
  }

  secondStepCompleted(completed: boolean) {
    this.secondStepCompletedBoolean = completed;
  }


  selectedExportChange(event) {
    this.firstStepCompletedBoolean = false;
    this.secondStepCompletedBoolean = false;
  }

  displayHeadersInCsvFile(ifDisplay) {
    this.displayHeadersInCsv = ifDisplay;
  }

  selectedColumnsInCsvFile(selectedColumns) {
    this.selectedColumnsToCustomBilling = selectedColumns;
  }

  onDownloadBillingFile() {
    this.fileMeterDataDataStoreService
      .getBillingFile(
        this.selectedPosition,
        +moment.utc(this.from.value),
        +moment.utc(this.to.value)
      )
      .subscribe(saveAs);
  }

  ngOnDestroy(): void {
    try {
      this.mediaSubject$.unsubscribe();
    } catch (e) {}
    finally {
      if (this.showTutorialSubscription){
        this.showTutorialSubscription.unsubscribe();
    }
    }
  }
}
