<h1>{{dataModelTxt.CREATE_NEW_VARIABLE_FORM}}</h1>
<form [formGroup]="newVariableForm" (ngSubmit)="onSubmit()" novalidate>
    <div id="container" class="container-form">
        <div class="row">
            <mat-form-field class="col">
                <input matInput placeholder="{{dataModelTxt.NAME}}" formControlName="name" name="name">
                <mat-error *ngIf="newVariableForm.get('name').touched && newVariableForm.get('name').errors?.required">
                    {{dataModelTxt.NAME_IS_REQUIRED}}
                </mat-error>
                <mat-error *ngIf="newVariableForm.get('name').touched && newVariableForm.get('name').errors?.occupiedName">
                    {{dataModelTxt.NAME_IS_OCCUPIED}}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field class="col">
                <input matInput placeholder="{{dataModelTxt.PARSED_NAME}}" formControlName="name_parsed" name="name_parsed">
                <mat-error
                    *ngIf="newVariableForm.get('name_parsed').touched && newVariableForm.get('name_parsed').errors?.required">
                    {{dataModelTxt.PARSED_NAME_IS_REQUIRED}}
                </mat-error>
                <mat-error *ngIf="newVariableForm.get('name_parsed').touched && newVariableForm.get('name_parsed').errors?.occupiedParsedName">
                    {{dataModelTxt.PARSED_NAME_IS_OCCUPIED}}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field class="col">
                <input matInput placeholder="{{dataModelTxt.DESCRIPTION}}" formControlName="description" name="description">
                <mat-error
                    *ngIf="newVariableForm.get('description').touched && newVariableForm.get('description').errors?.required">
                    {{dataModelTxt.DESCRIPTION_IS_REQUIRED}}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field class="col">
                <input matInput placeholder="{{dataModelTxt.UNIT}}" formControlName="unit" name="unit">
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field class="col">
                <mat-select matSelect placeholder="{{dataModelTxt.TYPE}}" formControlName="type">
                    <mat-option value="STRING">STRING</mat-option>
                    <mat-option value="DOUBLE">DOUBLE</mat-option>
                    <mat-option value="INTEGER">INTEGER</mat-option>
                    <mat-option value="BOOLEAN">BOOLEAN</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <!--<div class="row">
            <mat-form-field class="col">
                <label>IsPrimary</label>
                <mat-checkbox matSelect formControlName="is_primary">Yes</mat-checkbox>
            </mat-form-field>
        </div>-->
        <div class="row">
            <div  id="isPrimary">
            <label style="margin-right: 10px;padding:0 10px;">{{dataModelTxt.IS_PRIMARY}}</label>
            <mat-checkbox matSelect formControlName="is_primary">
                {{dataModelTxt.YES}}
            </mat-checkbox>
            </div>
        </div>
        <div class="form-btn right-btn">
            <button id="saveBtn" [disabled]="newVariableForm.invalid" mat-raised-button color="accent" class="btn btn-action" type="submit">{{dataModelTxt.SUBMIT}}</button>
        </div>
    </div>
</form>