import { GenericHttpParams, IApiEndpointConfig } from '@shared/types/http/httpType';
import { TimeGroup } from '@models/appModels/timeGroup.model';

export const TimeGroupApiConfig: IApiEndpointConfig = {
  rootApiPath: 'timegroups/',
  paths: [
    { /* 1 */
      url: '',
      swaggerId: 'getTimeGroupsList',
      pathParams: [],
      responseIsArray: true,
    },
    { /* 2 */
      url: '',
      swaggerId: 'postTimeGroups',
      pathParams: [],
      responseIsArray: true,
    },
    { /* 3 */
      url: 'id/{idTimeGroup}',
      swaggerId: 'getTimeGroupsById',
      pathParams: [
        {name: 'idTimeGroup', pattern: '{idTimeGroup}'},
      ],
      responseIsArray: false,
    },
    { /* 4 */
      url: 'id/{idTimeGroup}',
      swaggerId: 'putTimeGroupsById',
      pathParams: [
        {name: 'idTimeGroup', pattern: '{idTimeGroup}'},
      ],
      responseIsArray: false,
    },
    { /* 5 */
      url: 'id/{idTimeGroup}',
      swaggerId: 'deleteTimeGroupsById',
      pathParams: [
        {name: 'idTimeGroup', pattern: '{idTimeGroup}'},
      ],
      responseIsArray: false,
    },
  ]
};

export interface TimeGroupHttpParamG extends GenericHttpParams<TimeGroup> {
  path: {
    idTimeGroup?: string
  };
  queryParams: {
    limit?: string;
    offset?: string;
    name?: string;
  };

}
