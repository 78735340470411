// Angular import
import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';

// External libraries
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/retry';
import 'rxjs/add/operator/merge';
import 'rxjs/add/operator/concat';

// Enviroments
import { environment } from '@env/environment';

// Services
import { ScheduleDataStoreService } from '@shared/services/dataStore/scheduleDataStore.service';

/* Models */
import { Schedule } from '@shared/models/appModels/schedule.model';
import { Position } from '@shared/models/appModels/position.model';
/* Types */
import { NestedTableHost } from '@shared/types/applicationTypes';
import { ScheduleDialogsService } from './service/schedule-dialog.service';
import { MatPaginator } from '@angular/material/paginator';
import { ScheduleDataSource, TabbedSchedule } from './datasource/scheduleDataSource';

/* Localisation */
import { ActivatedRoute } from '@angular/router';
import { VeeStatusDataStoreService } from '@shared/services/dataStore/veeProcessDataStore.service';
import { VeeProcessStageEnum, VeeProcessStatusEnum } from '@shared/types/modelTypes/veeProcessTypes';
/* RxJs */
import { UtilityTypeEnum, PositionTransitions } from '@shared/types';
import { AutomaticImportConfigForSchedulePosition } from '@shared/models/appModels/automaticImportConfigurationForSchedulePosition.model';
import { DataImportDataStoreService } from '@shared/services/dataStore/dataImportDataStore.service';
import { PositionDataStoreService } from '@shared/services/dataStore/positionDataStore.service';
import { concatMap, share, startWith, map, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import { InteractiveTutorialService } from '@shared/services/interactiveTutorialService.service';
import { ScheduleInteractiveTutorialService } from './scheduleInteractiveTutorialService.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'sv-schedule',
  templateUrl: 'schedule.component.html',
  styleUrls: ['schedule.component.sass'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ScheduleComponent implements OnInit, AfterViewInit, OnDestroy, NestedTableHost<Schedule> {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  expandedElement: Schedule;
  dataSource: ScheduleDataSource;
  displayedColumnsFiles = ['name', 'status', 'actions'];

  /* Filter form controls */
  filterForm: FormGroup;
  criteriaAutoComplete: FormControl;
  searchPeriodFrom: FormControl;
  searchPeriodTo: FormControl;

  angPipeTimeFormat: string;
  isFrozen = false;
  schedules: Schedule[] = [];
  filteredOptions: Observable<Schedule[]>;
  allSchedulesFilterValue: string = $localize`:all filter option|All schedules dropdown option@@schedules/all filter option:All schedules`;

  /* Debug */
  debugMode: boolean = environment.debug;

  /* FIXME: Implement auto-opening */
  autoopenId: any;

  dataFetched: boolean = false;
  scheduleCount$: Observable<number> = Observable.of(0).pipe(share());
  showTutorialSubscription: Subscription;

  constructor(private fb: FormBuilder,
    private scheduleDataServ: ScheduleDataStoreService,
    private schDialog: ScheduleDialogsService,
    private route: ActivatedRoute,
    private veeStatus: VeeStatusDataStoreService,
    private dataImportDataStore: DataImportDataStoreService,
    private positionDataStoreService: PositionDataStoreService,
    private interactiveTutorialService: InteractiveTutorialService,
    private scheduleInteractiveTutorialService: ScheduleInteractiveTutorialService) {
    this.expandedElement = null;
    this.dataSource = new ScheduleDataSource();
  }

  ngOnInit() {
    this.initForms();
    this.showTutorialSubscription = this.interactiveTutorialService.showTutorialSubject.pipe(debounceTime(1500)).subscribe(isActive => {
      if (this.interactiveTutorialService.getActiveComponent(this.route.routeConfig.path) && isActive) {
        this.startInteractiveTutorial();
        }
    });
  }

  ngAfterViewInit(): void {
    this.paginator._intl.itemsPerPageLabel = $localize`:items per page label|A label used in a widget responsible
     for splitting records in a table into multiple pages@@paging/items per page:Items per page`;
    this.paginator._intl.nextPageLabel = $localize`:next page tooltip|A tooltip for a paging widget button@@paging/next page:Next page`;
    this.paginator._intl.previousPageLabel = $localize`:previous page tooltip|A tooltip for a paging widget button@@paging/previous page:Previous page`;
    this.paginator._intl.lastPageLabel = $localize`:last page tooltip|A tooltip for a paging widget button@@paging/last page:Last page`;
    this.paginator._intl.firstPageLabel = $localize`:first page tooltip|A tooltip for a paging widget button@@paging/first page:First page`;
    if (this.route.snapshot.queryParams['schedule']) {
      this.autoopenId = +this.route.snapshot.queryParams['schedule'];
    }
    const limit = this.paginator.pageSize;
    const offset = this.paginator.pageIndex * this.paginator.pageSize;
    /* FIXME: All schedules are downloaded to handle the input field with an autocomplete,
     this need to be changed to download only a couple of schedules based on input value
     but we need endpoint on backend to implement this */
    this.loadData().subscribe(sched => {
      this.schedules = sched;
    });
    this.loadData(limit, offset)
      .subscribe((schedules: Schedule[]) => {
        this.dataSource.setNewData(schedules);
        this.dataFetched = true;
        if (this.autoopenId) {
          const idx = schedules.findIndex((sch: Schedule) => sch.idSchedule === this.autoopenId);
          if (idx !== -1) {
            this.expandedElement = schedules[idx];
          }
        }
      },
        (err: any) => {
          if (this.debugMode) { console.log(err); }
        },
        () => {
          if (this.debugMode) {
            console.log(`Init data fetch Finished`);
          }
        });

    this.onPaginatorEvents(this.paginator)
      .subscribe((pagedData: Schedule[]) => {
        this.dataSource.setNewData(pagedData);
      });

    this.onExternalDataChangeEvent([this.scheduleDataServ.getChangeObservable()])
      .subscribe((pagedData: Schedule[]) => {
        if (this.debugMode) { console.log('New data triggered by external change'); }
        this.dataSource.setNewData(pagedData);
      });

    this.onFilterEvents()
      .subscribe((pagedData: Schedule[]) => {
        if (typeof (pagedData[0]) !== 'undefined') {
          this.dataSource.setNewData(pagedData);
        }
      });
  }

  private startInteractiveTutorial() {
    const steps = this.scheduleInteractiveTutorialService.getScheduleMainComponentInteractiveTutorialSteps();
    this.interactiveTutorialService.startInteractiveTutorial(steps);
  }

  private initForms() {
    /* Setup search form controls */
    this.criteriaAutoComplete = new FormControl(this.allSchedulesFilterValue);
    this.filterForm = this.fb.group({
      criteriaAutoComplete: this.criteriaAutoComplete
    });
    this.filteredOptions = this.criteriaAutoComplete.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value)),
      );
  }

  private _filter(value: string): Schedule[] {
    const filterValue = value.toLowerCase();
    return this.schedules.filter(schedule => schedule.name.toLowerCase().includes(filterValue));
  }

  onShowEditScreen(scheduleTemplate: Schedule) {
    const scheduleCopy = new Schedule(scheduleTemplate.getInterface());
    this.scheduleDataServ.getSchedulePositions(scheduleCopy.idSchedule, 0, 50, true)
      .pipe(concatMap<Position[], Observable<Schedule>>((pArr: Position[]) => {
        pArr.forEach((p: Position) => {
          scheduleCopy.addPosition(new Position(p));
        });
        return this.schDialog.invokeEditScheduleModal(scheduleCopy);
      }))
      .subscribe((data) => {
        if (typeof (data) !== 'undefined') {
          this.scheduleDataServ.updateSchedule(data[0])
            .subscribe(sch => {
              if (data[1].length > 0) {
                data[1].forEach(element => {
                  if (element.positionId === -1) {
                    sch.positions.forEach(pos => {
                      if (pos.orderInSchedule === element.orderInSchedule) {
                        element.positionId = pos.idPosition;
                      }
                    });
                  }
                });
                const dataForSent: AutomaticImportConfigForSchedulePosition[] = [];
                data[1].forEach(element => {
                  console.log(element)
                  dataForSent.push(new AutomaticImportConfigForSchedulePosition({
                    start_time: element.start_time,
                    end_time: element.end_time, aggregation: element.aggregation, positionId: element.positionId.toString(),
                    utility_type: UtilityTypeEnum[element.utility_type]
                  }));
                });
                this.dataImportDataStore.postAutomaticImportConfigurationForSchedulePosition(dataForSent).subscribe();
              }
            });
        } else {
          /* Action was cancelled */
        }
      });
  }

  onCreateNewSchedule() {
    this.schDialog.invokeCreateScheduleModal()
      .subscribe((data) => {
        if (typeof (data) !== 'undefined') {
          this.scheduleDataServ.addSchedule(data[0]).pipe(concatMap<Schedule, Observable<Schedule[]>>(saved => {
            if (data[1].length > 0) {
              data[1].forEach(element => {
                if (element.positionId === -1) {
                  saved.positions.forEach(pos => {
                    if (pos.orderInSchedule - 1 === element.orderInSchedule) {
                      element.positionId = pos.idPosition;
                    }
                  });
                }
              });
              const dataForSent: AutomaticImportConfigForSchedulePosition[] = [];
              data[1].forEach(element => {
                console.log(element)
                dataForSent.push(new AutomaticImportConfigForSchedulePosition({
                  start_time: element.start_time,
                  end_time: element.end_time, aggregation: element.aggregation, positionId: element.positionId.toString(),
                  utility_type: UtilityTypeEnum[element.utility_type]
                }));
              });
              this.dataImportDataStore.postAutomaticImportConfigurationForSchedulePosition(dataForSent).subscribe();
            }
            return this.loadData();
          }))
            .subscribe(schedules => {
              this.schedules = schedules;
            });
        }
      });
  }

  hasFrozenPosition(schedule: Schedule) {
    this.isFrozen = false;
    this.scheduleDataServ.getSchedulePositions(schedule.idSchedule).subscribe(element => {
      if (element) {
        element.forEach(e => {
          this.veeStatus.getVeeStateForPosition(e.idPosition).subscribe(v => {
            if (v.stage === VeeProcessStageEnum.FREEZE &&
              v.status === VeeProcessStatusEnum.DONE) {
              this.isFrozen = true;
            }
          }, error => {
            console.info('Not frozen position');
          });

        });
      }
    }, error => {
      console.info('Schedule not have positions');
    }
    );
  }

  onDeleteSchedule(schedule: Schedule) {
    /* FIXME: confirmation dialog needed */
    this.scheduleDataServ.deleteScheduleById(schedule.idSchedule, false, { emitEvent: true })
      .subscribe();
  }

  loadData(limit?: number, offset?: number, otherParams?: { [name: string]: any; }): Observable<Schedule[]> {
    if (otherParams) {
      throw new Error('Params filtering not implemented');
    }
    if (!limit && !offset) {
      return this.scheduleDataServ.getAllSchedulesNoPositions();
    }
    this.scheduleCount$ = this.scheduleDataServ.getSchedulesCount().pipe(share());
    return this.scheduleDataServ.getAllSchedulesNoPositions({ limit: limit, offset: offset });
  }

  onPaginatorEvents(paginator: MatPaginator): Observable<Schedule[]> {
    return paginator.page
      .pipe(concatMap(pEvent => {
        const limit = this.paginator.pageSize;
        const offset = this.paginator.pageIndex * this.paginator.pageSize;
        return this.scheduleDataServ.getAllSchedulesNoPositions({ limit: limit, offset: offset });
      }));
  }

  onExternalDataChangeEvent(changeInductors: Observable<any>[]): Observable<Schedule[]> {
    return Observable.merge(...changeInductors)
      .pipe(concatMap<any, Observable<Schedule[]>>((v: any) => {
        this.paginator.pageIndex = 0;
        const limit = this.paginator.pageSize;
        const offset = this.paginator.pageIndex * limit;
        return this.loadData(limit, offset);
      }));
  }
  onFilterEvents(): Observable<Schedule[]> {
    return this.filterForm.valueChanges
      .pipe(concatMap<any, Observable<Schedule[]>>((controlVal) => {
        this.paginator.pageIndex = 0;
        const limit = this.paginator.pageSize;
        const offset = this.paginator.pageIndex * limit;
        const filterParams = <any>{};
        if (controlVal.criteriaAutoComplete !== this.allSchedulesFilterValue) {
          filterParams.name = controlVal.criteriaAutoComplete;
          return of(new Array(this.schedules.find(element => element.name === filterParams.name)));
        }
        // tslint:disable-next-line: max-line-length
        return this.scheduleDataServ.getAllSchedulesNoPositions({ limit: limit, offset: offset }, true, filterParams);
      }));

  }

  isThisRowExpanded(row: Schedule): boolean {
    if ((this.expandedElement !== null && typeof (this.expandedElement) !== 'undefined') && row) {
      return this.expandedElement.idSchedule === row.idSchedule;
    } else { /* No expanded element */
      return false;
    }
  }

  handleMainRowClick(rowElement: TabbedSchedule): Schedule {
    if (this.expandedElement !== null
      && this.expandedElement.idSchedule === rowElement.idSchedule) {
      /* Same element clicked twice, hide expansion */
      this.expandedElement = null;
    } else {
      /* New element clicked, expand it */
      this.expandedElement = rowElement;
    }
    return this.expandedElement;
  }

  rowCanBeExpanded(index: number, row: TabbedSchedule): boolean {
    return row.detailRow || false;
  }

  isThisDetailElement(row: TabbedSchedule) {
    return (this.expandedElement !== null
      && this.expandedElement.idSchedule === +row.idSchedule);
  }

  detailsVisible(detail: TabbedSchedule) {
    if (this.expandedElement !== null && typeof (this.expandedElement) !== 'undefined') {
      return detail.idSchedule === this.expandedElement.idSchedule;
    } else {
      return false;
    }
  }

  isLoading(): boolean {
    return !this.dataFetched;
  }

  ngOnDestroy() {
    if (this.showTutorialSubscription) {
      this.showTutorialSubscription.unsubscribe();
    }
  }

}
