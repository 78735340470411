import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';

import { RestTypeMapper } from '@shared/models/RestSupport/restTypeMapper.model';

import { CommonAppDataService } from '@shared/services/commonAppData.service';
import { EndpointsEnum } from '@shared/types/http/endpointEnum';
import { UtilityTypeEnum } from '@shared/types/utilityType';
/* MOCKS */


import { unit_mock } from '@shared/mock/units.mock';
import { MediaTypeUnit, IMediaTypeUnit } from '@shared/models/appModels/MediaTypeUnit.model';
import { UnitHttpParamG } from '@shared/types/http/unitHttpConfig';
import { RequestService } from './requestService.class';
import { MeasurementDataModel, IMeasurementDataModel } from '@shared/models/appModels/measurementDataModel.model';

@Injectable()
export class UnitService extends RequestService {

  unitsMock: IMediaTypeUnit[][] = [];
  measurementDefinitionMock: IMeasurementDataModel[][] = [];
  unitsMapper: RestTypeMapper<IMediaTypeUnit, MediaTypeUnit>;
  measurementDefinitionMapper: RestTypeMapper<IMeasurementDataModel, MeasurementDataModel>;

  constructor(
    http: HttpClient,
    cs: CommonAppDataService) {
    super(http, cs, EndpointsEnum.DATA_MODEL_TYPE);
    if (this.MOCK_SERVICE) {
      this.unitsMock = unit_mock;
    } else {
      this.unitsMock = undefined;
    }
    /* Initialize type MAPPERS */
    this.unitsMapper = new RestTypeMapper<IMediaTypeUnit, MediaTypeUnit>();
    this.measurementDefinitionMapper = new RestTypeMapper<IMeasurementDataModel, MeasurementDataModel>();
  }

  getMeasurementDataModel(params: UnitHttpParamG): Observable<MeasurementDataModel[]> {
    const thisApiID: string = 'getMeasurementDataModel';
    if (this.MOCK_SERVICE) {
      const mediaIdx = UtilityTypeEnum[params.queryParams.svUtilityType.toUpperCase()];
      const ret = new MeasurementDataModel(this.measurementDefinitionMock[mediaIdx][0]);
      return Observable.of([ret]);
    } else {
      return this.getRequest(params, this.measurementDefinitionMapper, thisApiID, MeasurementDataModel);
    }
  }

  postMeasurementDataModel(params: UnitHttpParamG): Observable<MeasurementDataModel> {
    const thisApiID = 'postMeasurementDataModel';
    if (this.MOCK_SERVICE) {
      return Observable.of(new MeasurementDataModel({
        utilityType: params.body.utilityType,
        name: params.body.name,
        parsedName: params.body.parsedName,
        type: params.body.type,
        description: params.body.description,
        unit: params.body.unit,
        isBilled: params.body.isBilled,
        isCumulative: params.body.isCumulative,
        isPrimary: params.body.isPrimary,
        isMetadata: params.body.isMetadata
      }));
    } else {
      const bodyToSend: IMeasurementDataModel = this.measurementDefinitionMapper.mapObjectToInterface(params.body);
      return this.postRequest(bodyToSend, params, this.measurementDefinitionMapper, thisApiID, MeasurementDataModel);
    }
  }

  putMeasurementDataModelById(params: UnitHttpParamG): Observable<MeasurementDataModel> {
    const thisApiID: string = 'putMeasurementDataModelById';
    if (this.MOCK_SERVICE) {
      throw new Error('Missing mock implementation');
    } else {
      const bodyToSend: IMeasurementDataModel = this.measurementDefinitionMapper.mapObjectToInterface(params.body);
      return this.putRequest(bodyToSend, params, this.measurementDefinitionMapper, thisApiID, MeasurementDataModel);
    }
  }

}
