import { INameMapper, IPrintableNameMapper } from '@shared/types/applicationTypes';
import { environment as env } from '@env/environment';
import { typesLocalisation as loc } from '@shared/types/localisation';

export enum AnalysisProcessStageEnum {
  'DETECTION_OF_ANOMALIES' = 0,
  'FORECASTING' = 1,
  'UNDEFINED' = 3
  // 'DEFAULT'
}

export enum AnalysisProcessStatusEnum {
  'IN_PROGRESS' = 0,
  'DONE' = 1,
  'FAILED' = 2
}

export enum AnalysisProcessFailureEnum {
  'DATA_MISSING' = 0,
  'OTHER' = 1
}

export const AnalysisProcessNameMapper: IPrintableNameMapper[] = [
  {
    id: AnalysisProcessStageEnum.DETECTION_OF_ANOMALIES,
    name: loc.AnalysisProcessNameMapper[env.language].stageName.DETECTION_OF_ANOMALIES,
    classType: 'analysis-detection',
    isPrintable: true,
  },
  {
    id: AnalysisProcessStageEnum.FORECASTING,
    name: loc.AnalysisProcessNameMapper[env.language].stageName.FORECASTING,
    classType: 'analysis-forecasting',
    isPrintable: true,
  },
  {
    id: AnalysisProcessStageEnum.DETECTION_OF_ANOMALIES,
    name: loc.AnalysisProcessNameMapper[env.language].stageName.DEFAULT,
    classType: 'analysis-default',
    isPrintable: false,
  },
  {
    id: AnalysisProcessStageEnum.UNDEFINED,
    name: loc.AnalysisProcessNameMapper[env.language].stageName.DEFAULT,
    classType: 'analysis-default',
    isPrintable: false,
  },

];

export const AnalysisProcessStatusMapper: INameMapper[] = [
  { id: AnalysisProcessStatusEnum.DONE, name: loc.AnalysisProcessStatusMapper[env.language].statusName.IN_PROGRESS, classType: 'analysis-detection' },
  { id: AnalysisProcessStatusEnum.DONE, name: loc.AnalysisProcessStatusMapper[env.language].statusName.DONE, classType: 'analysis-forecasting' },
  { id: AnalysisProcessStatusEnum.FAILED, name: loc.AnalysisProcessStatusMapper[env.language].statusName.FAILED, classType: 'analysis-detection' },
];

export const AnalysisProcessFailureMapper: INameMapper[] = [
  { id: AnalysisProcessFailureEnum.DATA_MISSING, name: loc.AnalysisProcessFailureMapper[env.language].AnalysisFailureText.DATA_MISSING, classType: 'analysis-fail-data-missing' },
  { id: AnalysisProcessFailureEnum.OTHER, name: loc.AnalysisProcessFailureMapper[env.language].AnalysisFailureText.OTHER, classType: 'analysis-fail-other' }
];


