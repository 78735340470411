import { ISchedule } from '@shared/models/appModels/schedule.model';
import { UtilityTypeEnum } from '@shared/types/utilityType';

export const MockSchedule: ISchedule[] = [
  {
    idSchedule: 1,
    name: 'Example Closed schedule ID:1',
    scheduleNumber: 1,
    beginDate: new Date('2016-11-01').getTime(),
    endDate: new Date('2016-12-01').getTime(),
    closed: true,
    utilityType: UtilityTypeEnum.HEAT
  },
  {
    idSchedule: 2,
    name: 'Example Open & Active schedule ID:2',
    scheduleNumber: 2,
    beginDate: new Date('2017-12-01').getTime(),
    endDate: new Date('2018-02-01').getTime(),
    closed: false,
    utilityType: UtilityTypeEnum.HEAT
  },
  {
    idSchedule: 3,
    name: 'Example future schedule ID:3',
    scheduleNumber: 3,
    beginDate: new Date('2018-05-01').getTime(),
    endDate: new Date('2018-06-01').getTime(),
    closed: false,
    utilityType: UtilityTypeEnum.HEAT
  },
  {
    idSchedule: 4,
    name: 'Example schedule 4 - empty & active',
    scheduleNumber: 4,
    beginDate: new Date('2017-11-01').getTime(),
    endDate: new Date('2018-01-01').getTime(),
    closed: false,
    utilityType: UtilityTypeEnum.HEAT
  }

];
