import { enableProdMode } from "@angular/core";

import { AppModule } from "app/app.module";
import { environment } from "@env/environment";
import { KeycloakService } from "@shared/services/auth/keycloak.service";
import { loadSvTranslations } from "@shared/services/localeService.service";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

if (environment.production) {
  enableProdMode();
}

loadSvTranslations()
  .then(() => {
    if (environment.authConfig.authRequired) {
      KeycloakService.init()
        .then(() => {
          console.log("Keycloak initialized");
          bootstrapFn();
        })
        .catch((e) => {
          console.warn(e);
          console.warn(
            "Keycloak failed to initialize \nRe-attempt in 5 seconds..."
          );
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        });
    } else {
      bootstrapFn();
    }
  })
  .catch((e) => console.log(e));

function bootstrapFn() {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.log(err));
}
