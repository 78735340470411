import { TableDataSource } from '@shared/models/tableDataSource.model';
import { Schedule } from '@shared/models/appModels/schedule.model';

export class TabbedSchedule extends Schedule {
  detailRow: boolean;
  rowId: number;
}

export class ScheduleDataSource extends TableDataSource<Schedule, TabbedSchedule> {

  private setParams(detailRow: boolean, schedule: Schedule, baseElement: Schedule) {
    const element: TabbedSchedule = new TabbedSchedule(schedule);
    element.detailRow = detailRow;
    element.rowId = baseElement.idSchedule;
    return element;
  }

  protected renderData(data: Schedule[]): TabbedSchedule[] {
    const rows: TabbedSchedule[] = [];
    data.forEach((baseElement, idx) => {
      const topElement: TabbedSchedule = this.setParams(false, baseElement, baseElement);
      const detailElement: TabbedSchedule = this.setParams(true, topElement, baseElement);
      rows.push(topElement, detailElement);
    });
    return rows;
  }
}
