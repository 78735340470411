import { Injectable } from "@angular/core";
import { InteractiveTutorialService, TutorialButtonLabel } from "@shared/services/interactiveTutorialService.service";

@Injectable({ providedIn: "root" })
export class ScheduleInteractiveTutorialService {
  constructor(private interactiveTutServ: InteractiveTutorialService) {}

  getScheduleMainComponentInteractiveTutorialSteps() {
    const step = this.interactiveTutServ.generateStep(
      "#filterByMeter",
      "right",
      $localize`:@@interactive tutorial/schedule/main component/filter by meter:Here you can filter schedules by their names.`,
      $localize`:@@interactive tutorial/schedule/main component/filter by meter title:Filter by names`,
      [TutorialButtonLabel.NEXT]
    );

    const step1 = this.interactiveTutServ.generateStep(
      "#schedulesTab",
      "top",
      $localize`:@@interactive tutorial/schedule/main component/schedules tab:Here you can preview existing schedules and also edit or delete them.`,
      $localize`:@@interactive tutorial/schedule/main component/schedules tab title:Schedules table`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
    );

    const step2 = this.interactiveTutServ.generateStep(
      "#addNewSchedule",
      "left",
      $localize`:@@interactive tutorial/schedule/main component/add new schedule btn:Pleace click this button in order to create new schedule with position.`,
      $localize`:@@interactive tutorial/schedule/main component/add new schedule btn title:Schedule creation`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.COMPLETE]
    );

    const steps: any[] = [step, step1, step2];
    return steps;
  }

  getScheduleCreateModalInteractiveTutorialSteps() {
    const step = this.interactiveTutServ.generateStep(
      "#scheduleName",
      "top",
      $localize`:@@interactive tutorial/schedule/creation modal/schedule name:In this input you can define name of your schedule.`,
      $localize`:@@interactive tutorial/schedule/creation modal/schedule name title:Schedule name`,
      [TutorialButtonLabel.NEXT]
    );

    const step1 = this.interactiveTutServ.generateStep(
      "#addPosition",
      "right",
      $localize`:@@interactive tutorial/schedule/creation modal/add position btn:By clicking onto this button you can add new position to your schedule.`,
      $localize`:@@interactive tutorial/schedule/creation modal/add position btn title:Adding new position`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
    );

    const step2 = this.interactiveTutServ.generateStep(
      "#deletePosition",
      "right",
      $localize`:@@interactive tutorial/schedule/creation modal/delete position btn:By clicking onto this button you can delete position.`,
      $localize`:@@interactive tutorial/schedule/creation modal/delete position btn title:Deleting position`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
    );

    const step3 = this.interactiveTutServ.generateStep(
      "#positionConfig",
      "right",
      $localize`:@@interactive tutorial/schedule/creation modal/position config:Here you can configure your position.`,
      $localize`:@@interactive tutorial/schedule/creation modal/position config title:Position configuration`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
    );

    const step4 = this.interactiveTutServ.generateStep(
      "#saveBtn",
      "right",
      $localize`:@@interactive tutorial/schedule/creation modal/save btn:When you will be done with creating schedule, please click save button.`,
      $localize`:@@interactive tutorial/schedule/creation modal/save btn title:Save Schedule`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.COMPLETE]
    );

    const steps: any[] = [step, step1, step2, step3, step4];
    return steps;
  }

  getScheduleEditModalInteractiveTutorialSteps(
    isNotFrozen: boolean,
    positions: number
  ) {
    const step = this.interactiveTutServ.generateStep(
      "#scheduleName",
      "top",
      $localize`:@@interactive tutorial/schedule/edit modal/schedule name:In this input you can change name of your schedule.`,
      $localize`:@@interactive tutorial/schedule/edit modal/schedule name title:Schedules name`,
      [TutorialButtonLabel.NEXT]
    );

    const step1 = this.interactiveTutServ.generateStep(
      "#addPosition",
      "right",
      $localize`:@@interactive tutorial/schedule/edit modal/add position btn:By clicking onto this button you can add new position to your schedule.`,
      $localize`:@@interactive tutorial/schedule/edit modal/add position btn title:Adding new position`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
    );

    const step2 = this.interactiveTutServ.generateStep(
      "#deletePosition",
      "right",
      $localize`:@@interactive tutorial/schedule/edit modal/delete position btn:By clicking onto this button you can delete existing position(if this position is not frozen).`,
      $localize`:@@interactive tutorial/schedule/edit modal/delete position btn title:Deleting position`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT],
      () => {
        if (isNotFrozen && positions > 0) {
          return true;
        } else {
          return false;
        }
      }
    );

    const step3 = this.interactiveTutServ.generateStep(
      "#positionConfig",
      "right",
      $localize`:@@interactive tutorial/schedule/edit modal/position config:Here you can configure your position.`,
      $localize`:@@interactive tutorial/schedule/edit modal/position config title:Position configuration`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT],
      () => {
        if (positions > 0) {
          return true;
        } else {
          return false;
        }
      }
    );

    const step4 = this.interactiveTutServ.generateStep(
      "#saveBtn",
      "right",
      $localize`:@@interactive tutorial/schedule/edit modal/save btn:When you will be done with editing schedule, please click save button.`,
      $localize`:@@interactive tutorial/schedule/edit modal/save btn title:Save Schedule`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.COMPLETE],
      undefined,
      true
    );
    const steps: any[] = [step, step1, step2, step3, step4];
    return steps;
  }
}
