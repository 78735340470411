import { ICustomer } from '@shared/models/appModels/customer.model';
import { CustomerSegmentType } from '@shared/types/modelTypes/customerTypes';

export const MockCustomers: ICustomer[] = [
  {
    idCustomer: 1,
    idFileOperationalData: 1,
    denotation: 'a12',
    segmentType: CustomerSegmentType[CustomerSegmentType.ENTERPRISE],
    active: true,
  },
  {
    idCustomer: 2,
    idFileOperationalData: 1,
    denotation: 'a13',
    segmentType: CustomerSegmentType[CustomerSegmentType.SINGLE_FAMILY_HOUSING],
    active: true,
  },
  {
    idCustomer: 3,
    idFileOperationalData: 1,
    denotation: 'a14',
    segmentType: CustomerSegmentType[CustomerSegmentType.ENTERPRISE],
    active: true
  },
  {
    idCustomer: 4,
    idFileOperationalData: 1,
    denotation: 'a15',
    segmentType: CustomerSegmentType[CustomerSegmentType.ENTERPRISE],
    active: true
  },
  {
    idCustomer: 5,
    idFileOperationalData: 1,
    denotation: 'c16',
    segmentType: CustomerSegmentType[CustomerSegmentType.ENTERPRISE],
    active: true
  },
  {
    idCustomer: 6,
    idFileOperationalData: 1,
    denotation: 'c10',
    segmentType: CustomerSegmentType[CustomerSegmentType.ENTERPRISE],
    active: true
  },
  {
    idCustomer: 7,
    idFileOperationalData: 1,
    denotation: 'a11',
    segmentType: CustomerSegmentType[CustomerSegmentType.ENTERPRISE],
    active: true
  },
  {
    idCustomer: 8,
    idFileOperationalData: 1,
    denotation: 'a12',
    segmentType: CustomerSegmentType[CustomerSegmentType.ENTERPRISE],
    active: true
  },
  {
    idCustomer: 9,
    idFileOperationalData: 1,
    denotation: 'c20',
    segmentType: CustomerSegmentType[CustomerSegmentType.ENTERPRISE],
    active: true
  },
  {
    idCustomer: 10,
    idFileOperationalData: 1,
    denotation: 'c21',
    segmentType: CustomerSegmentType[CustomerSegmentType.ENTERPRISE],
    active: true
  },
  {
    idCustomer: 11,
    idFileOperationalData: 1,
    denotation: 'c21',
    segmentType: CustomerSegmentType[CustomerSegmentType.ENTERPRISE],
    active: true
  }
];
