// Angular modules import
import { Injectable } from '@angular/core';

// RxJS imports
/* Rx classes */
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
/* Static class extensions */
import 'rxjs/add/operator/combineAll';
import 'rxjs/add/operator/reduce';
import 'rxjs/add/operator/last';
import 'rxjs/add/operator/defaultIfEmpty';
import 'rxjs/add/operator/toArray';
import 'rxjs/add/observable/zip';

/* Datatypes */
import { Schedule } from '@shared/models/appModels/schedule.model';
import { Position } from '@shared/models/appModels/position.model';

/* Services */
import { PositionDataStoreService  } from '@shared/services/dataStore/positionDataStore.service';
import { ScheduleService  } from '@shared/services/http/schedule.service';

import { GenericHttpParams, ScheduleHttpParamG, BaseDataStore, UtilityTypeEnum, ScheduleQueryParams, ApiDataShift } from '@shared/types';
import { CommonAppDataService } from '@shared/services/commonAppData.service';

@Injectable()
export class ScheduleDataStoreService extends BaseDataStore<GenericHttpParams<Schedule>, ScheduleHttpParamG> {

  private scheduleDataSubject$: Subject<Schedule> = new Subject<Schedule>();
  private scheduleDataObservable$: Observable<Schedule> = this.scheduleDataSubject$.asObservable();

  constructor(
    private scheduleService: ScheduleService,
    private positionDataStoreService: PositionDataStoreService,
    cs: CommonAppDataService) {
      super(cs);
  }

  getChangeObservable(): Observable<Schedule> {
    return this.scheduleDataObservable$;
  }

  getAllSchedules(
    offset: number = 0,
    limit: number = this.defaultLimit,
    allowEmpty: boolean = true): Observable<Schedule[]> {

    const params = this.getEmptyParams();
    params.queryParams.offset = '' + offset;
    params.queryParams.limit =  '' + limit;
    params.queryParams.svUtilityType = UtilityTypeEnum[this.cs.getCurrentMediaType()];
    params.config.strict = !allowEmpty;

    return this.getSchedulesList(params);
  }

  getAllSchedulesNoPositions(
    nums: ApiDataShift = {limit: this.defaultLimit, offset: this.defaultOffset},
    allowEmpty: boolean = true,
    filterParams?: ScheduleQueryParams): Observable<Schedule[]> {
      const params = this.getEmptyParams();
      params.queryParams.offset = '' + nums.offset;
      params.queryParams.limit =  '' + nums.limit;
      params.queryParams.svUtilityType = UtilityTypeEnum[this.cs.getCurrentMediaType()];
      params.config.strict = !allowEmpty;
      if (filterParams) {
        if (filterParams.past) {
          params.queryParams.past = filterParams.past;
        }
        if (filterParams.future) {
          params.queryParams.future = filterParams.future;
        }
        if (filterParams.active) {
          params.queryParams.active = filterParams.active;
        }
        if (filterParams.past) {
          params.queryParams.past = filterParams.past;
        }
        if (filterParams.name) {
          params.queryParams.name = filterParams.name;
        }
      }

    return this.scheduleService.getSchedulesList(params);
  }

  getAllSchedulesNoPositionsByTime(
    limit: number = this.defaultLimit,
    offset: number = 0,
    allowEmpty: boolean = true,
    from?: number,
    to?: number): Observable<Schedule[]> {
      const params = this.getEmptyParams();
      params.queryParams.offset = '' + offset;
      params.queryParams.limit =  '' + limit;
      params.queryParams.svUtilityType = UtilityTypeEnum[this.cs.getCurrentMediaType()];
      params.config.strict = !allowEmpty;
      if (from) {
        params.queryParams.from = '' + from;
      }
      if (to) {
        params.queryParams.to = '' + to;
      }
      return this.scheduleService.getSchedulesList(params);
    }

  getSchedulesCount(): Observable<number> {
    const params = this.getEmptyParams();
    params.queryParams.svUtilityType = UtilityTypeEnum[this.cs.getCurrentMediaType()];
    return this.scheduleService.getSchedulesCount(params);
  }

  getOpenSchedules(
    offset: number = 0,
    limit: number = this.defaultLimit,
    allowEmpty: boolean = true): Observable<Schedule[]> {

    const params = this.getEmptyParams();
    params.queryParams.offset = '' + offset;
    params.queryParams.limit = '' + limit;
    params.config.strict = !allowEmpty;
    params.queryParams.open = '' + true;
    params.queryParams.svUtilityType = UtilityTypeEnum[this.cs.getCurrentMediaType()];

    return this.getSchedulesList(params);
  }

  private getSchedulesList(params: ScheduleHttpParamG): Observable<Schedule[]> {

    let querySchedules: Schedule[] = [];
    return this.scheduleService.getSchedulesList(params)
    .concatMap<Schedule[], number>((schArray: Schedule[]) => { /* For each schedule emit it's ID */
      querySchedules = schArray.slice(0);
      const ids: number[] = schArray.map((sch: Schedule): number =>  sch.idSchedule);
      return Observable.from(ids);
    })
    .concatMap<number, Position[]>((idIn: number) => { /* Map each ID to positions array */
      return this.getSchedulePositions(idIn);
    })
    .concatMap<Position[], Schedule>((pIn: Position[], idx: number) => { /* Connect each position array with schedule */
      querySchedules[idx].positions = pIn.slice(0);
      return Observable.of(querySchedules[idx]);
    })
    .toArray();
  }

  getNextScheduleNumber(): Observable<number> {
    const params = this.getEmptyParams();
    params.queryParams.svUtilityType = UtilityTypeEnum[this.cs.getCurrentMediaType()];
    return this.scheduleService.getSchedulesCount(params)
    .map<number, number>((max: number) => {
      return max + 1;
    });
  }

  getScheduleById(idSchedule: number, allowEmpty: boolean = false): Observable<Schedule> {

    const params: ScheduleHttpParamG = this.getEmptyParams();
    params.path.idSchedule = '' + idSchedule;
    params.config.strict = !allowEmpty;
    params.queryParams.svUtilityType = UtilityTypeEnum[this.cs.getCurrentMediaType()];
    return this.scheduleService.getSchedulesById(params);
  }


  updateSchedule(updatedSchedule: Schedule, options: {emitEvent: boolean} = {emitEvent: true}): Observable<Schedule> {

    const params = this.getEmptyParams();
    params.body = updatedSchedule;
    params.path.idSchedule = '' + updatedSchedule.idSchedule;
    params.queryParams.svUtilityType = UtilityTypeEnum[this.cs.getCurrentMediaType()];

    return Observable.of(updatedSchedule)
    /* Concat map to enclose values all original values */
    .concatMap<Schedule, Schedule>((uP: Schedule) => {

      /* We must assume, each existing position was updated */
      const updatedPositionsIds: number[] = uP.getPositions()
      .map(p => p.getId())
      .filter(id => id !== -1);

      /* Save bare schedule */
      return this.scheduleService.putSchedulesById(params)
      /* Delete deleted positions & re-emit complete new schedule */
      .concatMap<Schedule, Schedule>((savedSchedule) => {
        return this.getSchedulePositions(savedSchedule.getId(), 0, 50, true)
        .concatMap<Position[], Schedule>((currentPositions: Position[]) => {
          const deletedPositions: number[] = [];
          currentPositions.forEach((upId) => {
            const idx = updatedPositionsIds.findIndex(p => p === upId.getId());
            if (idx === -1) {
              deletedPositions.push(upId.getId());
            }
          });
          return Observable.from(deletedPositions)
          .concatMap<number, Position>(id => {
            return this.positionDataStoreService.deletePosition(id);
          })
          .toArray()
          .map((pArr: Position[]) => {
            return uP;
          });
        });
      })
      /* Update existing and add new positions */
      .concatMap<Schedule, Schedule>((wholeSchedule) => {
        return Observable.from(wholeSchedule.getPositions())
        .concatMap<Position, Position>((pos) => {
          if (pos.idPosition === -1) {
            return this.positionDataStoreService.addPosition(pos);
          } else {
            return this.positionDataStoreService.updatePosition(pos, {emitEvent: options.emitEvent});
          }
        })
        .toArray()
        .concatMap<Position[], Schedule>(parr => {
          return this.getSingleScheduleWithPositinos(uP.getId(), 0, 50, true);
        });
      });
    })
    .concatMap<Schedule, Schedule>((sch: Schedule) => { /* Emit editted schedule */
      if (options.emitEvent) {
        this.scheduleDataSubject$.next(sch); /* Emit change notification */
      }
      return Observable.of(sch);
    });
  }

  /* FIXME: delete is  handled in cascade style via DB. Separate delete calls are not needed */
  deleteScheduleById(deleteScheduleId: number, allowEmpty: boolean = false, options: {emitEvent: boolean} = {emitEvent: true}): Observable<Schedule> {

    const params = this.getEmptyParams();
    params.path.idSchedule = '' + deleteScheduleId;
    params.queryParams.svUtilityType = UtilityTypeEnum[this.cs.getCurrentMediaType()];

    return this.scheduleService.deleteSchedulesById(params)
    .do((deletedSchedule: Schedule) => {
      if (options.emitEvent) {
        this.scheduleDataSubject$.next(deletedSchedule);
      }
    });
  }

  addSchedule(newSchedule: Schedule, options: {emitEvent: boolean} = {emitEvent: true}): Observable<Schedule> {
    /* After mapping to interface type, schedule will lose it's positions */
    /* We have to store positions in closure */
    let schResponse: Schedule;
    const positionsCopy: Position[] = newSchedule.positions.slice(0);

    const params = this.getEmptyParams();
    params.body = newSchedule;
    params.queryParams.svUtilityType = UtilityTypeEnum[this.cs.getCurrentMediaType()];

    return this.scheduleService.postSchedules(params)
    .concatMap<Schedule, Position>((sch: Schedule) => { /* Add schedule ID it each of positions & emit event for each position */
      schResponse = sch;
      positionsCopy.forEach((pos: Position) => {
        pos.idSchedule = sch.idSchedule;
      });
      return Observable.from(positionsCopy);
    })
    .concatMap<Position, Position>((pos: Position) => { /* Store each of position */
      return this.positionDataStoreService.addPosition(pos, {emitEvent: options.emitEvent});
    })
    .toArray()
    .concatMap<Position[], Schedule>((pArr: Position[]) => { /* Emit new schedule */
      // schResponse.positions = pArr.slice(0);
      pArr.forEach((p: Position) => {
        schResponse.addPosition(p);
      });
      if (options.emitEvent) {
        this.scheduleDataSubject$.next(schResponse);
      }
      return Observable.of(schResponse);
    });
  }

  getSchedulePositions(
    idSchedule: number,
    offset: number = 0,
    limit: number = this.defaultLimit,
    allowEmpty: boolean = true): Observable<Position[]> {

    const params = this.getEmptyParams();
    params.path.idSchedule = '' + idSchedule;
    params.queryParams.limit = '' + limit;
    params.queryParams.offset = '' + offset;
    params.config.strict = !allowEmpty;
    params.queryParams.svUtilityType =  UtilityTypeEnum[this.cs.getCurrentMediaType()];
    return this.scheduleService.getSchedulesPositions(params);
  }

  getSingleScheduleWithPositinos(
    idSchedule: number,
    offset: number = 0,
    limit: number = this.defaultLimit,
    allowEmpty: boolean = true): Observable<Schedule> {
      return this.getScheduleById(idSchedule, false)
      .concatMap<Schedule, Schedule>((bareSch: Schedule) => {
        return this.getSchedulePositions(bareSch.getId(), offset, limit, allowEmpty)
        .concatMap<Position[], Schedule>((pArray: Position[]) => {
          pArray.forEach((p: Position) => {
            bareSch.addPosition(p);
          });
          return Observable.of(bareSch);
        });
      });
  }

  protected getEmptyParams(): ScheduleHttpParamG {
    const r: ScheduleHttpParamG = {body: {} as Schedule, headers: {}, path: {}, queryParams: {}, config: {}};
    return r;
  }

}
