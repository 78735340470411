import { IGetInterfaceType } from '@shared/types/applicationTypes';

export interface IValidateImplementation {
    implementation: string;
    utility_type: string;
}

export class ValidateImplementation implements IValidateImplementation, IGetInterfaceType<IValidateImplementation, ValidateImplementation> {

    implementation: string;
    utility_type: string;

    constructor(iValidateImplementation: IValidateImplementation) {
        this.implementation = (typeof (iValidateImplementation.implementation) !== 'undefined') ? iValidateImplementation.implementation : undefined;
        this.utility_type = (typeof (iValidateImplementation.utility_type) !== 'undefined') ? iValidateImplementation.utility_type : undefined;

    }

    getInterface(webInterface: boolean): IValidateImplementation {
        return {
            implementation: this.implementation,
            utility_type: this.utility_type,
        };
    }

}
