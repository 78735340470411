import { Component } from '@angular/core';

@Component({

    // tslint:disable-next-line:component-selector
    selector: 'sv-settings-algorithms',
    templateUrl: 'settings-algorithms.component.html',
    styleUrls: ['settings-algorithms.component.scss']
})
export class SettingsAlgorithmsComponent {

}
