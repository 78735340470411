import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { EventEmitter, Output, Input } from '@angular/core';

/* External libraries */
import 'rxjs/add/operator/switchMap';

import { Subscription } from 'rxjs/Subscription';

/* Application services */
import { ScheduleDataStoreService } from '@shared/services/dataStore/scheduleDataStore.service';
import { PositionDataStoreService } from '@shared/services/dataStore/positionDataStore.service';
import { AnalysisAlgorithmDataStoreService } from '@shared/services/dataStore/analysisAlgorithmsDataStore.service';
import { SchedulePositionSelectorLocalisation as loc } from '../../common-component/select-shcedule-position/select-page.localisation';
import { AnalysisStatusDataStoreService } from '@shared/services/dataStore/analysisProcessDataStore.service';

/* Application datatypes */
import { Position } from '@shared/models/appModels/position.model';
import { Schedule } from '@shared/models/appModels/schedule.model';
import { IAnalysisAlgorithm, AnalysisAlgorithm } from '@shared/models/appModels/analysisAlgorithm.model';
import { AnalysisProcessStageEnum, AnalysisProcessStatusEnum } from '@shared/types/modelTypes/analysisProcessTypes';
// Env
import { environment } from '@env/environment';

import { AbstractControl } from '@angular/forms';
import { AnalysisAlgorithmTypeEnum } from '@shared/types/modelTypes/analysisAlgorithmTypes';
import { AnalysisLocalisation as aLoc } from '../data-analysis-localisation';
import { AnalysisStatus } from '@shared/models/appModels/analysisStatus.model';
import { VeeStatus } from '@shared/models/appModels/VeeStatus.model';
import { VeeProcessStageEnum } from '@shared/types/modelTypes/veeProcessTypes';
import { VeeStatusDataStoreService } from '@shared/services/dataStore/veeProcessDataStore.service';
import * as moment from 'moment';
import { PositionTransitions } from '@shared/types';
import { InteractiveTutorialService } from '@shared/services/interactiveTutorialService.service';
import { DataAnalaysisInteractiveTutorialService } from './data-analysis-interactive-tutorial-service.service';
import { debounceTime } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

interface AnalysisAlgorithmsPanels {
  panelOpenStateDetectionOfAnomalies: boolean;
  panelOpenStateForecasting: boolean;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'sv-data-analysis',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'data-analysis-page.component.html',
  styleUrls: ['data-analysis-page.component.sass']
})
export class DataAnalysisPageComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() selectionEvent: EventEmitter<{ idSchedule?: number, idPosition?: number }>;
  @Output() creationEvent: EventEmitter<any>;

  @Input() createNewAllowed: boolean;
  readonly debugMode: boolean = environment.debug;
  readonly algorithmsMdIcon: string = 'donut_small';
  readonly pageLabel = aLoc[environment.language].texts.page;

  AnalysisAlgorithmTypeEnum: typeof AnalysisAlgorithmTypeEnum = AnalysisAlgorithmTypeEnum;

  panelsState: AnalysisAlgorithmsPanels = { panelOpenStateDetectionOfAnomalies: true, panelOpenStateForecasting: false };

  toggleAnalysis: boolean;
  panelOpenState: boolean;
  panelShowResult: boolean;
  analysisDisabled: boolean = true;
  analysisD: boolean;
  @Output() actualAnalysisAlgorithm: IAnalysisAlgorithm;
  @Output() listAlgorithmsDetectionOfAnomalies: IAnalysisAlgorithm[] = [];
  @Output() listAlgorithmsForecasting: IAnalysisAlgorithm[] = [];
  @Output() listEnabledAlgorithm: IAnalysisAlgorithm[] = [];
  analysis: any;
  @Output() analysisStatus: AnalysisStatus;

  filterForm: FormGroup;
  startDate: moment.Moment;
  endDate: moment.Moment;
  showTutorialSubscription: Subscription;

  schedules: Schedule[];
  positions: Position[];

  navigationContext: {
    schedule?: number,
    position?: number,
  };
  actualSelect: {
    idSchedule?: number,
    idPosition?: number
  };
  actual_position = 0;
  queryParams$: Subscription;
  scheduleSelect$: Subscription;
  positionsSelect$: Subscription;
  externaChangeSchedule$: Subscription;
  externaChangePosition$: Subscription;
  @Output() title = new Map();
  maps = [["sigma3", $localize`:Dashboard title in Grafana|Dashboard title in Grafana@@analysis/summary/3sigma:Rule 3 Sigma`],
  ["mad", $localize`:Dashboard title in Grafana|Dashboard title in Grafana@@analysis/summary/mad:Median absolute deviation`],
  ["quartile", $localize`:Dashboard title in Grafana|Dashboard title in Grafana@@analysis/summary/quartile:Quartile Deviation`],
  ["periodic profile", $localize`:Dashboard title in Grafana|Dashboard title in Grafana@@analysis/summary/periodic profile:Periodic profile`]];


  constructor(
    private positionDataStoreService: PositionDataStoreService,
    public scheduleDataStoreService: ScheduleDataStoreService,
    private fb: FormBuilder,
    public veeStatusService: VeeStatusDataStoreService,
    private analysisAlgorithmsService: AnalysisAlgorithmDataStoreService,
    private analysisProcessService: AnalysisStatusDataStoreService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private interactiveTutorialService: InteractiveTutorialService,
    private dataAnalysisInteractiveTutorialService: DataAnalaysisInteractiveTutorialService
  ) {
    this.filterForm = this.fb.group({
      'scheduleSelect': new FormControl(),
      'positionSelect': new FormControl()
    });
    this.selectionEvent = new EventEmitter<{ idSchedule?: number, idPosition?: number }>();
    this.creationEvent = new EventEmitter<any>();
    this.navigationContext = { position: undefined, schedule: undefined };
    this.actualSelect = { idPosition: undefined, idSchedule: undefined };
    this.panelOpenState = true;
    this.panelShowResult = false;
    this.analysis = new EventEmitter<{
      aggregation?: string, startDate?: number, endDate?: number,
      meter: string, address: string
    }>();
  }


  ngOnInit() {
    this.maps.forEach(elements => {
      this.title.set(elements[0], elements[1]);
      this.title.set(elements[1], elements[0]);
    });
    this.getAnalysisAlgorithms();
    this.toggleAnalysis = false;
    this.cdr.detectChanges();
  }
  private getAnalysisAlgorithms() {
    this.listEnabledAlgorithm = [];
    this.analysisAlgorithmsService.getAnalysisAlgorithmsTypeDetectionOfAnomalies()
      .map<AnalysisAlgorithm[], AnalysisAlgorithm[]>((rules) => {
        return rules.sort((a, b) => a.idAnalysisAlgorithm - b.idAnalysisAlgorithm);
      })
      .concatMap<AnalysisAlgorithm[], AnalysisAlgorithm[]>((analysisAlgorithms: AnalysisAlgorithm[]) => {
        this.listAlgorithmsDetectionOfAnomalies = analysisAlgorithms.slice(0);
        return this.analysisAlgorithmsService.getAnalysisAlgorithmsTypeDetectionOfAnomalies();
      })
      .map<AnalysisAlgorithm[], AnalysisAlgorithm[]>((rules) => {
        return rules.sort((a, b) => a.idAnalysisAlgorithm - b.idAnalysisAlgorithm);
      })
      .subscribe((r: AnalysisAlgorithm[]) => {
        this.listAlgorithmsDetectionOfAnomalies = r.slice(0);
        this.filterAlgorithm(r, this.listAlgorithmsDetectionOfAnomalies);
      },
        (e) => {
          console.error('Unable to fetch rules');
          console.error(e);
          this.listAlgorithmsDetectionOfAnomalies = (this.listAlgorithmsDetectionOfAnomalies.length === 0) ? [] : this.listAlgorithmsDetectionOfAnomalies;
        },
        () => {
          if (this.debugMode) { console.log('DetectionOfAnomalies algorithms fetch complete'); }
        });

    this.analysisAlgorithmsService.getAnalysisAlgorithmsTypeForecasting()
      .map<AnalysisAlgorithm[], AnalysisAlgorithm[]>((rules) => {
        return rules.sort((a, b) => a.idAnalysisAlgorithm - b.idAnalysisAlgorithm);
      })
      .concatMap<AnalysisAlgorithm[], AnalysisAlgorithm[]>((analysisAlgorithms: AnalysisAlgorithm[]) => {
        this.listAlgorithmsForecasting = analysisAlgorithms.slice(0);
        return this.analysisAlgorithmsService.getAnalysisAlgorithmsTypeForecasting();
      })
      .map<AnalysisAlgorithm[], AnalysisAlgorithm[]>((rules) => {
        return rules.sort((a, b) => a.idAnalysisAlgorithm - b.idAnalysisAlgorithm);
      })
      .subscribe((r: AnalysisAlgorithm[]) => {
        this.listAlgorithmsForecasting = r.slice(0);
        this.filterAlgorithm(r, this.listAlgorithmsForecasting);
      },
        (e) => {
          console.error('Unable to fetch rules');
          console.error(e);
          this.listAlgorithmsForecasting = (this.listAlgorithmsForecasting.length === 0) ? [] : this.listAlgorithmsForecasting;
        },
        () => {
          if (this.debugMode) { console.log('Forecasting algorithms fetch complete'); }
        });
  }

  ngAfterViewInit(){
    this.showTutorialSubscription = this.interactiveTutorialService.showTutorialSubject.pipe(debounceTime(1500)).subscribe(isActive => {
      if (this.interactiveTutorialService.getActiveComponent(this.route.routeConfig.path) && isActive) {
      this.startInteractiveTutorial();
      }
    });
  }

  startInteractiveTutorial(){
    const steps = this.dataAnalysisInteractiveTutorialService.getDataAnalysisPageInteractiveTutorialSteps(!this.toggleAnalysis);
    this.interactiveTutorialService.startInteractiveTutorial(steps);
  }

  filterAlgorithm(r: AnalysisAlgorithm[], listAlgorithm: any) {
    if (listAlgorithm && listAlgorithm.find(f => f.enabled === true)) {
      this.actualAnalysisAlgorithm = r.slice(0)[0];
      this.listEnabledAlgorithm = listAlgorithm.filter(algorithm => algorithm.enabled === true);
      this.cdr.markForCheck();
    }
  }

  /* Common controls getters */
  get scheduleSelect(): AbstractControl {
    return this.filterForm.get('scheduleSelect');
  }

  get positionSelect(): AbstractControl {
    return this.filterForm.get('positionSelect');
  }

  getControlCreationText(control: 'schedule' | 'position') {
    switch (control) {
      case 'schedule': {
        return loc[environment.language].texts.control.creationText.SCHEDULE;
      }
      case 'position': {
        return loc[environment.language].texts.control.creationText.POSITION;
      }
    }
  }

  getControlPlaceholderText(control: 'schedule' | 'position') {
    switch (control) {
      case 'schedule': {
        if (this.schedules && this.schedules.length === 0) {
          return loc[environment.language].texts.control.emptyText.SCHEDULE;
        } else {
          return loc[environment.language].texts.control.placeholder.SCHEDULE;
        }
      }
      case 'position': {
        return loc[environment.language].texts.control.placeholder.POSITION;
      }
      default: {
        throw new Error('Incorrect parameter passed as control identifier');
      }
    }
  }

  ngOnDestroy(): void {
    try { /* Any of subscriptions may be null if some subscription throw an erorr */
      if (this.queryParams$) {
        this.queryParams$.unsubscribe();
      }
    } catch (e) {
      console.error(e);
    } finally {
      if (this.scheduleSelect$) {
        this.scheduleSelect$.unsubscribe();
      }
      if (this.positionsSelect$) {
        this.positionsSelect$.unsubscribe();
      }
      if(this.showTutorialSubscription){
        this.showTutorialSubscription.unsubscribe();
      }
    }
  }

  onStartAnalysisClicked(): void {
    let algorithmType: string;
    this.actualAnalysisAlgorithm && this.actualAnalysisAlgorithm.algorithmType === 0 ? algorithmType = 'DETECTION_OF_ANOMALIES' : algorithmType = 'FORECASTING';
    if (this.analysisStatus !== undefined) {
      this.analysisProcessService.clearAnalysisStateForPosition(this.actual_position, algorithmType).subscribe(v => {
        this.startAnalysis(algorithmType);
      });
    } else {
      this.startAnalysis(algorithmType);
    }
  }

  startAnalysis(algorithmType: string) {
    this.analysisProcessService.setAnalysisStateForPosition(this.actual_position,
      algorithmType,
      AnalysisProcessStageEnum[algorithmType],
      this.analysis.aggregation,
      this.analysis.startDate,
      this.analysis.endDate,
      this.analysis.meter,
      this.analysis.address,
      algorithmType).subscribe(as => {
        this.positionDataStoreService.getPositionById(this.actual_position).subscribe(p => {
          const pos = Position.positionStateMachine(p, PositionTransitions.ANALYSIS_START);
          this.positionDataStoreService.updatePosition(pos, { emitEvent: false }).subscribe(position => {
            this.toggleAnalysis = true;
            this.panelOpenState = false;
            this.panelShowResult = false;
            this.cdr.markForCheck();
          });
        });
      });
  }

  onNewAnalysisClicked(): void {
    this.toggleAnalysis = false;
    this.panelOpenState = true;
    this.panelShowResult = false;
  }

  onAnalysisEvent($event: any) {
    this.analysisD = false;
    this.analysis = $event;
  }

  onShowResultClicked($event): void {
    if ($event) {
      if (this.panelShowResult) {
        this.panelShowResult = false;
      } else {
        this.panelShowResult = true;
      }
      this.cdr.markForCheck();
    }
  }

  onRuleClick($event: MatCheckboxChange, rule: AnalysisAlgorithm, type: AnalysisAlgorithmTypeEnum) {
    this.panelShowResult = false;
    if (!this.actualAnalysisAlgorithm && $event.checked === true) {
      this.actualAnalysisAlgorithm = rule;
    }
    if (this.actualAnalysisAlgorithm.algorithmType !== type) {
      this.actualAnalysisAlgorithm = rule;
      if (type === AnalysisAlgorithmTypeEnum.FORECASTING) {
        this.analysisAlgorithmsService.getAnalysisAlgorithmsTypeDetectionOfAnomalies().map(v => {
          v.forEach(element => {
            this.analysisAlgorithmsService.toggleAnalysisAlgorithm(
              element.idAnalysisAlgorithm,
              false,
              AnalysisAlgorithmTypeEnum.FORECASTING,
              { emitEvent: true })
              .subscribe();
          });
        }).subscribe(l => {
          this.setAlgorithmState($event, rule, type);
        });
      } else {
        this.analysisAlgorithmsService.getAnalysisAlgorithmsTypeForecasting().map(v => {
          v.forEach(element => {
            this.analysisAlgorithmsService.toggleAnalysisAlgorithm(
              element.idAnalysisAlgorithm,
              false,
              AnalysisAlgorithmTypeEnum.DETECTION_OF_ANOMALIES,
              { emitEvent: true })
              .subscribe();
          });
        }).subscribe(l => {
          this.setAlgorithmState($event, rule, type);
        });
      }
    } else {
      this.analysisAlgorithmsService.toggleAnalysisAlgorithm(rule.idAnalysisAlgorithm, $event.checked, type, { emitEvent: true })
        .subscribe(z => {
          this.getAnalysisAlgorithms();
          if ((this.listAlgorithmsDetectionOfAnomalies.length === 1 || this.listAlgorithmsForecasting.length === 1) && $event.checked === false) {
            this.cdr.markForCheck();
          }
        });
    }
  }

  setAlgorithmState($event: MatCheckboxChange, rule: AnalysisAlgorithm, type: AnalysisAlgorithmTypeEnum) {
    this.analysisAlgorithmsService.toggleAnalysisAlgorithm(rule.idAnalysisAlgorithm, $event.checked, type, { emitEvent: true }).subscribe(z => {
      this.getAnalysisAlgorithms();
      this.setAnalysisState();
    });
  }

  setAnalysisState() {
    this.analysisProcessService.getAnalysisStateForPosition(this.actual_position,
      (this.actualAnalysisAlgorithm && this.actualAnalysisAlgorithm.algorithmType) ?
        AnalysisAlgorithmTypeEnum[this.actualAnalysisAlgorithm.algorithmType] :
        AnalysisAlgorithmTypeEnum[AnalysisAlgorithmTypeEnum.DETECTION_OF_ANOMALIES], true)
      .subscribe((vs: AnalysisStatus | {}) => {
        if (Object.keys(vs).length !== 0) {
          this.analysisStatus = (vs as AnalysisStatus);
          this.analysisD = ((vs as AnalysisStatus).status === AnalysisProcessStatusEnum.DONE) ? true : false;
          this.toggleAnalysis = true;
        } else {
          this.toggleAnalysis = false;
          this.analysisStatus = undefined;
        }
        this.cdr.markForCheck();
      });
  }

  handleSchedulePositionSelection(e: { idSchedule?: number, idPosition?: number }) {
    this.actualSelect.idSchedule = e.idSchedule;
    this.actualSelect.idPosition = e.idPosition;
    if (!this.actualSelect.idPosition) {
      this.analysisDisabled = true;
      this.panelShowResult = false;
    }
    if (e.idPosition && e.idSchedule && e.idPosition !== this.actual_position) {
      this.actualSelect.idSchedule = e.idSchedule;
      this.actual_position = e.idPosition;
      this.veeStatusService.getVeeStateForPosition(this.actual_position, true)
        .subscribe((vs: VeeStatus | {}) => {
          if (Object.keys(vs).length !== 0 && (vs as VeeStatus).stage === VeeProcessStageEnum.FREEZE) {
            this.analysisDisabled = false;
            this.setAnalysisState();
          } else {
            this.analysisDisabled = true;
          }
          this.cdr.markForCheck();
        });
    }
  }
  getTranslatedAlgorithmName(name: string) {
    return this.title.get(name);
  }
}
