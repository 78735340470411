import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import 'rxjs/add/observable/merge';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import { typesLocalisation } from '@shared/types/localisation';
import { environment } from '@env/environment';
import { TableDataSource } from '@shared/models/tableDataSource.model';
import { ParameterPrimityveType } from '@shared/types';
import { PositionDataStoreService } from '@shared/services/dataStore/positionDataStore.service';
import { Forecasting } from '@shared/models/appModels/forecasting.model';

export class ForecastingDetailsDataSource extends TableDataSource<Forecasting, Forecasting> {
  readonly debugMode: boolean = environment.debug;
  private filterChange$ = new BehaviorSubject('1');
  renderedData: Forecasting[] = [];
  filteredData: Forecasting[] = [];
  paginatorLength = 0;
  readonly itemsPage = typesLocalisation.ItemsPage[environment.language].texts;
  get filter(): string { return this.filterChange$.value; }
  set filter(filter: string) { this.filterChange$.next(filter); }
  protected renderData(data: Forecasting[]): Forecasting[] {
    return this.sortData(data);
  }
  constructor(
    private positionDataStoreService: PositionDataStoreService,
    private id: number,
    private idMeterPoint: number,
    private paginator: MatPaginator,
    private sort: MatSort
  ) {
    super();
    this.filterChange$.subscribe(() => this.paginator.pageIndex = 0);
  }
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this.positionDataStoreService.getPositionForecasting(this.id).map(v => {
        this.paginatorLength = v.length;
        v.sort((a, b) => a.period - b.period);
        this.filteredData = v;
      }),
      this.paginator._intl.itemsPerPageLabel = this.itemsPage.itemsPage,
      this.paginator._intl.nextPageLabel = this.itemsPage.nextPage,
      this.paginator._intl.previousPageLabel = this.itemsPage.previousPage,
      this.paginator.page,
      this.filterChange$
    ];

    return Observable.merge(...displayDataChanges).map(() => {
      let dataTmp: Forecasting[] = [];
      dataTmp = this.filteredData.filter(v => v.idMeterPoint === this.idMeterPoint);
      dataTmp = dataTmp.filter(v => v.analysedPositionId === this.id);
      this.paginatorLength = dataTmp.length;
      const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
      this.renderedData = dataTmp.splice(startIndex, this.paginator.pageSize);
      return this.renderedData;
    });
  }
  disconnect() { }
  private sortData(data: Forecasting[]): Forecasting[] {
    if (!this.sort.active || this.sort.direction === '') { return data; }

    return data.sort((a, b) => {
      let propertyA: ParameterPrimityveType = '';
      let propertyB: ParameterPrimityveType = '';

      switch (this.sort.active) {
        case 'date': [propertyA, propertyB] = [a.timestamp, b.timestamp]; break;
        case 'value': [propertyA, propertyB] = [a.energy, b.energy]; break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this.sort.direction === 'asc' ? 1 : -1);
    });
  }
}
