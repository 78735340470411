import { INameMapper } from '@shared/types/applicationTypes';
import { environment as env } from '@env/environment';
import { typesLocalisation as loc } from '@shared/types/localisation';

export enum CustomerSegmentType {
  'ENTERPRISE' = 0,
  'SERVICE_COMPANY' = 1,
  'PUBLIC_ADMINISTRATION' = 2,
  'HEALTHCARE' = 3,
  'EDUCATION' = 4,
  'MULTI_FAMILY_HOUSING' = 5,
  'SINGLE_FAMILY_HOUSING' = 6,
  'DEFAULT' = 7,
}

export const CustomerSegmentNames: INameMapper[] = [
   {id: CustomerSegmentType.ENTERPRISE, name: loc.CustomerSegmentNames[env.language].segmentType.ENTERPRISE, classType: 'enterprise'},
   {id: CustomerSegmentType.SERVICE_COMPANY, name: loc.CustomerSegmentNames[env.language].segmentType.SERVICE_COMPANY, classType: 'service-companies'},
   {id: CustomerSegmentType.PUBLIC_ADMINISTRATION, name: loc.CustomerSegmentNames[env.language].segmentType.PUBLIC_ADMINISTRATION, classType: 'public-administration'},
   {id: CustomerSegmentType.HEALTHCARE, name: loc.CustomerSegmentNames[env.language].segmentType.HEALTHCARE, classType: 'healthcare'},
   {id: CustomerSegmentType.EDUCATION, name: loc.CustomerSegmentNames[env.language].segmentType.EDUCATION, classType: 'education'},
   {id: CustomerSegmentType.MULTI_FAMILY_HOUSING, name: loc.CustomerSegmentNames[env.language].segmentType.MULTI_FAMILY_HOUSING, classType: 'multi-family-housing'},
   {id: CustomerSegmentType.SINGLE_FAMILY_HOUSING, name: loc.CustomerSegmentNames[env.language].segmentType.SINGLE_FAMILY_HOUSING, classType: 'single-family-housing'},
   {id: CustomerSegmentType.DEFAULT, name: loc.CustomerSegmentNames[env.language].segmentType.DEFAULT, classType: 'default-segment'},
 ];
