// Angular modules import
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// RjJS imports
import { Observable } from 'rxjs/Observable';
import { environment } from '@env/environment';

import { IMeterPoint, MeterPoint } from '@shared/models/appModels/meterPoint.model';
import { IMeterData, MeterData } from '@shared/models/appModels/meterData.model';
import { IMeasurementData, MeasurementData } from '@shared/models/appModels/measurementData.model';
import { Customer, ICustomer } from '@shared/models/appModels/customer.model';

/* Datasource */
import { MockMeterPoint_Plain } from '@shared/mock/meterPoint.mock';


import { RestTypeMapper } from '@shared/models/RestSupport/restTypeMapper.model';
import { BooleanResult } from '@shared/types/http/httpType';
import { EndpointsEnum } from '@shared/types/http/endpointEnum';
import { MeterPointHttpParamG } from '@shared/types/http/meterPointHttpConfig';
import { CommonAppDataService } from '@shared/services/commonAppData.service';
import { CustomerDataStoreService } from '@shared/services/dataStore/customerDataStore.service';
import { RequestService } from './requestService.class';


@Injectable()
export class MeterPointService extends RequestService {

  readonly debugMode: boolean = environment.debug;
  /* Data Rest <-> Object Mappers */
  private meterPointMapper: RestTypeMapper<IMeterPoint, MeterPoint>;
  private customerMappre: RestTypeMapper<ICustomer, Customer>;
  private meterDataMappre: RestTypeMapper<IMeterData, MeterData>;
  private mesurementDataMappre: RestTypeMapper<IMeasurementData, MeasurementData>;

  mockMeterPoints: IMeterPoint[];

  constructor(http: HttpClient, cs: CommonAppDataService, private customerService: CustomerDataStoreService) {
    super(http, cs, EndpointsEnum.METER_POINT);
    if (this.MOCK_SERVICE) {
      this.mockMeterPoints = MockMeterPoint_Plain;
    } else {
      /* Unpin mocking services */
      this.customerService = undefined;
    }
    /* Initialize mappers */
    this.meterPointMapper = new RestTypeMapper<IMeterPoint, MeterPoint>();
    this.customerMappre = new RestTypeMapper<ICustomer, Customer>();
    this.meterDataMappre = new RestTypeMapper<IMeterData, MeterData>();
    this.mesurementDataMappre = new RestTypeMapper<IMeasurementData, MeasurementData>();

  }

  getMeterPointsList(params: MeterPointHttpParamG): Observable<MeterPoint[]> {
    const thisApiID = 'getMeterPointsList';
    if (this.MOCK_SERVICE) {
      /* Filtering logic */
      let resultsArrayed = [];
      if (params.queryParams.id) {
        resultsArrayed = this.mockMeterPoints.filter(meter => meter.idMeterPoint === +params.queryParams.id);
      } else if (params.queryParams.active) {
        resultsArrayed = this.mockMeterPoints.filter(meter => meter.active === (params.queryParams.active === 'true'));
      } else if (params.queryParams.name) {
        const regex = new RegExp(params.queryParams.name + '.*');
        resultsArrayed = this.mockMeterPoints.filter(meter => regex.test(meter.serialNumber));
      } else /* No filter parammeters provided */ {
        resultsArrayed = this.mockMeterPoints;
      }
      const flattern: IMeterPoint[] = [].concat.apply([], resultsArrayed);
      return Observable.of(new Object())
        .mapTo(flattern.slice(+params.queryParams.offset, +params.queryParams.offset + (+params.queryParams.limit)))
        .map<IMeterPoint[], MeterPoint[]>((listData: IMeterPoint[]): MeterPoint[] =>
          listData.map((x: IMeterPoint) => new MeterPoint(x)))
        .delay(this.MOCK_TIME)
        .catch((error: any) => Observable.throw(error || new Error('Server error')));
    } else {
      return this.getRequest(params, this.meterPointMapper, thisApiID, MeterPoint);
    }
  }

  postMeterPoints(params: MeterPointHttpParamG): Observable<MeterPoint> {
    const thisApiID = 'postMeterPoints';
    if (this.MOCK_SERVICE) {
      const ibody = params.body.getInterface();
      const maxId = Math.max(...this.mockMeterPoints.map(meterPoint => meterPoint.idMeterPoint));
      ibody.idMeterPoint = maxId + 1;
      this.mockMeterPoints.forEach((el: IMeterPoint, idx: number) => {
        if (el.idMeterPoint === ibody.idMeterPoint) {
          throw new Error(`chedule with ID ${el.idMeterPoint} is already existing`);
        }
      });
      this.mockMeterPoints.push(ibody);
      const obs: Observable<MeterPoint> = Observable.of(params.body);
      return obs;
    } else {
      const bodyToPost: IMeterPoint = this.meterPointMapper.mapObjectToInterface(params.body);
      return this.postRequest(bodyToPost, params, this.meterPointMapper, thisApiID, MeterPoint);
    }
  }

  getMeterPointsById(params: MeterPointHttpParamG = { queryParams: {}, config: { strict: true } }): Observable<MeterPoint> {
    const thisApiID: string = 'getMeterPointsById';
    if (this.MOCK_SERVICE) {
      const id = +params.path.idMeterPoint;
      return Observable.of(new Object())
        .mapTo(this.mockMeterPoints)
        .map<IMeterPoint[], IMeterPoint>((listMeter: IMeterPoint[]): IMeterPoint => listMeter.find((x: IMeterPoint) => x.idMeterPoint === id))
        .map<IMeterPoint, MeterPoint>((item: IMeterPoint): MeterPoint => new MeterPoint(item))
        .catch((error: any) => Observable.throw(error || new Error('Server error')));
    } else {
      return this.getRequest(params, this.meterPointMapper, thisApiID, MeterPoint);
    }
  }

  putMeterPointsById(params: MeterPointHttpParamG): Observable<MeterPoint> {
    const thisApiID: string = 'putMeterPointsById';
    if (this.MOCK_SERVICE) {
      throw new Error('Missing mock implementation');
    } else {
      const bodyToSend = this.meterPointMapper.mapObjectToInterface(params.body);
      return this.putRequest(bodyToSend, params, this.meterPointMapper, thisApiID, MeterPoint);
    }
  }

  deleteMeterPointsById(params: MeterPointHttpParamG): Observable<MeterPoint> {
    const thisApiID = 'deleteMeterPointsById';
    if (this.MOCK_SERVICE) {
      throw new Error('Missing mock implementation');
    } else {
      return this.deleteRequest(params, this.meterPointMapper, thisApiID, MeterPoint);
    }
  }

  getMeterPointCustomer(params: MeterPointHttpParamG): Observable<Customer> {
    const thisApiID = 'getMeterpointCustomer';
    if (this.MOCK_SERVICE) {
      return this.getMeterPointsById(params)
        .concatMap<MeterPoint, Customer>((mp: MeterPoint) => {
          return this.customerService.getCustomerById(mp.idCustomer);
        });
    } else {
      return this.getRequest(params, this.customerMappre, thisApiID, Customer);
    }
  }

  getMeterPointMeterData(params: MeterPointHttpParamG): Observable<MeterData[]> {
    const thisApiID = 'getMeterpointMeterdata';
    if (this.MOCK_SERVICE) {
      throw new Error('Missing mock implementation');
    } else {
      return this.getRequest(params, this.meterDataMappre, thisApiID, MeterData);
    }
  }

  getMeterpointMesurementdata(params: MeterPointHttpParamG): Observable<MeasurementData[]> {
    const thisApiID = 'getMeterpointMesurementdata';
    if (this.MOCK_SERVICE) {
      throw new Error('Missing mock implementation');
    } else {
      return this.getRequest(params, this.mesurementDataMappre, thisApiID, MeasurementData);
    }
  }

  putMeterPointActivation(params: MeterPointHttpParamG): Observable<MeterPoint> {
    const thisApiID = 'putMeterPointActivation';
    if (this.MOCK_SERVICE) {
      const id: number = +params.path.idMeterPoint;
      const findIdx = this.mockMeterPoints.findIndex(meter => meter.idMeterPoint === id);
      if (findIdx !== -1) {
        const body: BooleanResult = params.body;
        this.mockMeterPoints[findIdx].active = body.value;
        return Observable.of(new MeterPoint(this.mockMeterPoints[findIdx]));
      } else {
        return Observable.throw('No such index');
      }
    } else {
      const bodyToSend = params.body as BooleanResult;
      return this.putRequest(bodyToSend, params, this.meterPointMapper, thisApiID, MeterPoint);
    }
  }

  putMeterPointEnabled(params: MeterPointHttpParamG): Observable<MeterPoint> {
    const thisApiID = 'putMeterPointEnabled';
    if (this.MOCK_SERVICE) {
      const id: number = +params.path.idMeterPoint;
      const findIdx = this.mockMeterPoints.findIndex(meter => meter.idMeterPoint === id);
      if (findIdx !== -1) {
        const body: BooleanResult = params.body;
        this.mockMeterPoints[findIdx].enabled = body.value;
        return Observable.of(new MeterPoint(this.mockMeterPoints[findIdx]));
      } else {
        return Observable.throw('No such index');
      }
    } else {
      const bodyToSend = params.body as BooleanResult;
      return this.putRequest(bodyToSend, params, this.meterPointMapper, thisApiID, MeterPoint);
    }
  }

  getMeterPointsListCount(params: MeterPointHttpParamG): Observable<number> {
    const thisApiID = 'getMeterPointsListCount';
    if (this.MOCK_SERVICE) {
      let resultsArrayed = [];
      if (params.queryParams.id) {
        resultsArrayed = this.mockMeterPoints.filter(meter => meter.idMeterPoint === +params.queryParams.id);
      } else if (params.queryParams.active) {
        resultsArrayed = this.mockMeterPoints.filter(meter => meter.active === (params.queryParams.active.toLowerCase() == 'true'));
      } else if (params.queryParams.name) {
        const regex = new RegExp(params.queryParams.name + '.*');
        resultsArrayed = this.mockMeterPoints.filter(meter => regex.test(meter.serialNumber));
      } else /* No filter parammeters provided */ {
        resultsArrayed = this.mockMeterPoints;
      }
      const flattern: IMeterPoint[] = [].concat.apply([], resultsArrayed);
      return Observable.of(flattern.length);
    } else {
      return this.getCountRequest(params, thisApiID);
    }
  }

  /* INFO: This service is existing due to mocking strategy */
  getMeterBySerialNumber(params: MeterPointHttpParamG): Observable<MeterPoint> {
    if (this.MOCK_SERVICE) {
      const serialNo: string = params.queryParams.name[0];
      return Observable.of(new Object())
        .mapTo(this.mockMeterPoints)
        .map<IMeterPoint[], IMeterPoint>((listMeter: IMeterPoint[]): IMeterPoint => listMeter.find((x: IMeterPoint) => x.serialNumber === serialNo))
        .map<IMeterPoint, MeterPoint>((item: IMeterPoint): MeterPoint => new MeterPoint(item))
        .catch((error: any) => Observable.throw(error || new Error('Server error')));
    } else {
      return this.getMeterPointsList(params)
        .map((meterPointsArray: MeterPoint[]) => {
          return meterPointsArray[0];
        });
    }
  }
}
