<mat-table #tableFiles [dataSource]="dataSource">

    <ng-container matColumnDef="positionName">
      <mat-header-cell *matHeaderCellDef i18n="positions table header|A header in the table for displaying schedule's positions@@schedules/positions table header name">Position Name</mat-header-cell>
			<mat-cell *matCellDef="let position">
        {{ position.getName() }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="positionDetails">
			<mat-cell *matCellDef="let detailedPosition" class="detail-data">
        <sv-event-details class="event-details" *ngIf="detailsVisible(detailedPosition)" [inputPosition]="detailedPosition"></sv-event-details>
			</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumnsDetails"></mat-header-row>
		<mat-row *matRowDef="let row; columns: displayedColumnsDetails;"
							matRipple
							class="element-row"
							[class.expanded]="isThisRowExpanded(row)"
							(click)="handleMainRowClick(row)"></mat-row>

    <mat-row *matRowDef="let detailRow; columns: ['positionDetails']; when: rowCanBeExpanded"
							[@detailExpand]="isThisRowExpanded(detailRow) ? 'expanded' : 'collapsed' ">
		</mat-row>
  </mat-table>

  <div class="no-result" *ngIf="!dataAvailable()">
		<mat-spinner [strokeWidth]="3" [diameter]="20" class="notranslate"></mat-spinner>
  </div>

