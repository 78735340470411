import { EventEmitter, Injector } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";

import { Observable } from "rxjs/Observable";
import { Subject } from "rxjs/Subject";
import { Subscription } from "rxjs/Subscription";
import { mergeMap } from "rxjs/operators/mergeMap";
import {
  UploadFile,
  UploadOutput,
  UploadInput,
  UploadStatus,
} from "./interfaces";

export function humanizeBytes(bytes: number): string {
  if (bytes === 0) {
    return "0 Byte";
  }
  const k = 1024;

  const sizes: string[] = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
  const i: number = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
}

export class NgUploaderService {
  queue: UploadFile[];
  serviceEvents: EventEmitter<UploadOutput>;
  filesListEvents: EventEmitter<any>;
  uploadScheduler: Subject<{ file: UploadFile; event: UploadInput }>;
  subs: { id: string; sub: Subscription }[];
  contentTypes: string[];
  totalFileSizeExceeded: any = { status: false };
  maxUploads: number;

  constructor(
    concurrency: number = Number.POSITIVE_INFINITY,
    contentTypes: string[] = ["*"]
  ) {
    this.queue = [];
    this.serviceEvents = new EventEmitter<any>();
    this.filesListEvents = new EventEmitter<any>();
    this.uploadScheduler = new Subject<{
      file: UploadFile;
      event: UploadInput;
    }>();
    this.subs = [];
    this.contentTypes = contentTypes;
    this.maxUploads = 1;

    this.uploadScheduler
      .pipe(mergeMap((upload) => this.startUpload(upload), concurrency))
      .subscribe(
        (uploadOutput) => {
          this.serviceEvents.emit(uploadOutput);
        },
        (e) => {
          this.serviceEvents.emit({
            type: "rejected",
            file: (e as UploadOutput).file || undefined,
            statusText: (e as UploadOutput).statusText || undefined,
          } as UploadOutput);
        },
        () => {
          console.log("Done");
        }
      );
  }

  handleFiles(incomingFiles: FileList): Promise<any> {
    return new Promise((resolve) => {
      this.filesListEvents.emit({
        incomingFiles,
        totalFileSizeExceeded: this.totalFileSizeExceeded,
      });
      if (!this.totalFileSizeExceeded.status) {
        const allowedIncomingFiles: File[] = [].reduce.call(
          incomingFiles,
          (acc: File[], checkFile: File, i: number) => {
            const futureQueueLength = acc.length + this.queue.length + 1;
            if (
              this.isContentTypeAllowed(checkFile.type) &&
              this.maxUploads === futureQueueLength
            ) {
              acc = acc.concat(checkFile);
            } else {
              const rejectedFile: UploadFile = this.makeUploadFile(
                checkFile,
                i
              );
              if (this.maxUploads !== futureQueueLength) {
                this.serviceEvents.emit({
                  type: "rejected",
                  file: rejectedFile,
                  statusText: "tooManyFiles",
                });
              } else {
                this.serviceEvents.emit({
                  type: "rejected",
                  file: rejectedFile,
                });
              }
            }
            return acc;
          },
          []
        );
        this.queue.push(
          ...[].map.call(allowedIncomingFiles, (file: File, i: number) => {
            const uploadFile: UploadFile = this.makeUploadFile(file, i);
            this.serviceEvents.emit({ type: "addedToQueue", file: uploadFile });
            return uploadFile;
          })
        );
        this.serviceEvents.emit({ type: "allAddedToQueue" });
      } else {
        this.totalFileSizeExceeded.status = false;
      }
      resolve();
    });
  }

  initInputEvents(input: EventEmitter<UploadInput>): Subscription {
    return input.subscribe((event: UploadInput) => {
      switch (event.type) {
        case "uploadFile":
          const uploadFileIndex = this.queue.findIndex(
            (file) => file === event.file
          );
          if (uploadFileIndex !== -1 && event.file) {
            this.uploadScheduler.next({
              file: this.queue[uploadFileIndex],
              event: event,
            });
          }
          break;
        case "uploadAll":
          const files = this.queue.filter(
            (file) => file.progress.status === UploadStatus.Queue
          );
          files.forEach((file) => {
            this.uploadScheduler.next({ file: file, event: event });
          });
          break;
        case "cancel":
          const id = event.id || null;
          if (!id) {
            return;
          }

          const index = this.subs.findIndex((sub) => sub.id === id);
          if (index !== -1 && this.subs[index].sub) {
            this.subs[index].sub.unsubscribe();

            const fileIndex = this.queue.findIndex((file) => file.id === id);
            if (fileIndex !== -1) {
              this.queue[fileIndex].progress.status = UploadStatus.Cancelled;
              this.serviceEvents.emit({
                type: "cancelled",
                file: this.queue[fileIndex],
              });
            }
          }
          break;
        case "cancelAll":
          this.subs.forEach((sub) => {
            if (sub.sub) {
              sub.sub.unsubscribe();
            }

            const file = this.queue.find(
              (uploadFile) => uploadFile.id === sub.id
            );
            if (file) {
              file.progress.status = UploadStatus.Cancelled;
              this.serviceEvents.emit({ type: "cancelled", file: file });
            }
          });
          break;
        case "remove":
          if (!event.id) {
            return;
          }

          const i = this.queue.findIndex((file) => file.id === event.id);
          if (i !== -1) {
            const file = this.queue[i];
            this.queue.splice(i, 1);
            this.serviceEvents.emit({ type: "removed", file: file });
          }
          break;
        case "removeAll":
          if (this.queue.length) {
            this.queue = [];
            this.serviceEvents.emit({ type: "removedAll" });
          }
          break;
      }
    });
  }

  startUpload(upload: {
    file: UploadFile;
    event: UploadInput;
    uploadError?: string;
  }): Observable<UploadOutput> {
    return new Observable((observer) => {
      const sub = this.uploadFile(upload.file, upload.event).subscribe(
        (output) => {
          observer.next(output);
        },
        (err) => {
          observer.error(err);
        },
        () => {
          observer.next({ type: "done", file: upload.file });
        }
      );

      this.subs.push({ id: upload.file.id, sub: sub });
    });
  }

  uploadFile(file: UploadFile, event: UploadInput): Observable<UploadOutput> {
    if (event.uploadExecutor) {
      return this.uploadFileViaExecutor(file, event);
    }
  }

  setContentTypes(contentTypes: string[]): void {
    if (typeof contentTypes !== "undefined" && contentTypes instanceof Array) {
      if (contentTypes.find((type: string) => type === "*") !== undefined) {
        this.contentTypes = ["*"];
      } else {
        this.contentTypes = contentTypes;
      }
      return;
    }
    this.contentTypes = ["*"];
  }

  allContentTypesAllowed(): boolean {
    return this.contentTypes.find((type: string) => type === "*") !== undefined;
  }

  isContentTypeAllowed(mimetype: string): boolean {
    if (this.allContentTypesAllowed()) {
      return true;
    }
    return (
      this.contentTypes.find((type: string) => type === mimetype) !== undefined
    );
  }

  makeUploadFile(file: File, index: number): UploadFile {
    return {
      fileIndex: index,
      id: null,
      name: file.name,
      size: file.size,
      type: file.type,
      form: new FormData(),
      progress: {
        status: UploadStatus.Queue,
        data: {
          percentage: 0,
          speed: 0,
          speedHuman: `${humanizeBytes(0)}/s`,
          startTime: null,
          endTime: null,
        },
      },
      lastModifiedDate: file["lastModifiedDate"], /// Fix me,
      sub: undefined,
      nativeFile: file,
    };
  }

  private uploadFileViaExecutor(
    file: UploadFile,
    event: UploadInput
  ): Observable<UploadOutput> {
    return new Observable<UploadOutput>((observer) => {
      return event.uploadExecutor(file).subscribe(
        (x) => {
          const time: number = new Date().getTime();
          let progressStartTime: number =
            (file.progress.data && file.progress.data.startTime) || time;
          switch (UploadStatus[x.type]) {
            case "Queue":
              file.progress = {
                status: UploadStatus.Uploading,
                data: {
                  percentage: 0,
                  speed: 0,
                  speedHuman: `${humanizeBytes(0)}/s`,
                  startTime: new Date().getTime(),
                  endTime: null,
                },
              };
              observer.next({ type: "addedToQueue", file: file });
              break;
            case "Uploading":
              const percentage = Math.round((100 * x.loaded) / x.total);
              let speed = 0;
              const diff = new Date().getTime() + 1 - time;
              speed = Math.round((x.loaded / diff) * 1000);
              progressStartTime =
                (file.progress.data && file.progress.data.startTime) ||
                new Date().getTime();

              file.progress = {
                status: UploadStatus.Uploading,
                data: {
                  percentage: percentage,
                  speed: speed,
                  speedHuman: `${humanizeBytes(speed)}/s`,
                  startTime: progressStartTime,
                  endTime: null,
                },
              };
              observer.next({ type: "uploading", file: file });
              break;
            case "Done":
              const speedAverage = Math.round(
                (file.size / (new Date().getTime() - progressStartTime)) * 1000
              );
              file.progress = {
                status: UploadStatus.Done,
                data: {
                  percentage: 100,
                  speed: speedAverage,
                  speedHuman: `${humanizeBytes(speedAverage)}/s`,
                  startTime: progressStartTime,
                  endTime: new Date().getTime(),
                },
              };
              observer.next({ type: "done", file: file });
              break;
            case "Canceled":
              observer.next({ type: "cancelled", file: file });
              break;
            default:
              return `Unhandled event: ${x.type}`;
          }
        },
        (e: any) => {
          observer.next({
            type: "rejected",
            file: file,
            statusText: (e as HttpErrorResponse)?.statusText || e,
            statusCode: (e as HttpErrorResponse)?.status,
          } as UploadOutput);
        },
        () => observer.complete()
      );
    });
  }
}
