import { IApiEndpointConfig, GenericHttpParams } from '@shared/types/http/httpType';
import { MeterData } from '@shared/models/appModels/meterData.model';

export const MeterDataApiConf: IApiEndpointConfig = {
  rootApiPath: 'meterdata/',
  paths: [
    {/* 1 */
      swaggerId: 'getMeterDataList',
      url: '',
      pathParams: [],
      responseIsArray: true},
    {/* 2 */
      swaggerId: 'postMeterData',
      url: 'id/{idMeterData}',
      pathParams: [{name: 'idMeterData', pattern: '{idMeterData}'}],
      responseIsArray: false},
    {/* 3 */
      swaggerId: 'putMeterDataById',
      url: 'id/{idMeterData}',
      pathParams: [{name: 'idMeterData', pattern: '{idMeterData}'}],
      responseIsArray: false},
    {/* 4 */
      swaggerId: 'deleteMeterDataById',
      url: 'id/{idMeterData}',
      pathParams: [{name: 'idMeterData', pattern: '{idMeterData}'}],
      responseIsArray: false},
    {/* 5 */
      swaggerId: 'getMeterdataListCount',
      url: 'count',
      pathParams: [],
      responseIsArray: false
    }
  ]
};

export interface MeterDataHttpParamG extends GenericHttpParams<MeterData> {
  queryParams?: {
    /* Required */
    limit?:    string;
    offset?:   string;
    /* Optional */
    id?: string[];
    from?: string;
    to?: string;
    meterId?: string;
    svUtilityType?: string;
    sort?: string;
  };
  path?: {
    idPosition?: string;
    idMeterData?:    string;
  };
}
