/* Angular modules import */
import { Injectable } from '@angular/core';


/* RjJS imports */
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

/* Models */
import { FileMeterData, IFileMeterData } from '@shared/models/appModels/fileMeasurementData.model';

/* Datatypes */
import { GenericHttpParams } from '@shared/types/http/httpType';
import { FileMeterDataHttpParamG, FileMeterDataQueryParameters } from '@shared/types/http/fileMeterDataHttpConfig';
import { BaseDataStore, ApiDataShift } from '@shared/types/applicationTypes';

/* Http services */
import { FileMeterDataService } from '@shared/services/http/fileMeterData.service';
import { CommonAppDataService } from '@shared/services/commonAppData.service';
import { UtilityTypeEnum } from '@shared/types/utilityType';


@Injectable()
export class FileMeterDataStoreService extends BaseDataStore<GenericHttpParams<FileMeterData>, FileMeterDataHttpParamG> {

  private fileMeterDataSubject$: Subject<FileMeterData> = new Subject<FileMeterData>();
  private readonly fileMeterDataObservable$: Observable<FileMeterData> = this.fileMeterDataSubject$.asObservable();

  constructor(private fileMeterDataService: FileMeterDataService, cs: CommonAppDataService) {
    super(cs);
  }

  getChangeObservable(): Observable<FileMeterData> {
    return this.fileMeterDataObservable$;
  }

  getSubject(): Subject<FileMeterData> {
    return this.fileMeterDataSubject$;
  }

  getFileMeterDataList(nums: ApiDataShift, allowEmpty: boolean = true, filters?: FileMeterDataQueryParameters): Observable<FileMeterData[]> {

    const params = this.getEmptyParams();
    params.queryParams.limit = nums.limit;
    params.queryParams.offset = nums.offset;
    params.queryParams.svUtilityType = UtilityTypeEnum[this.cs.getCurrentMediaType()];

    if (filters) {
      if (filters.sort) {
        params.queryParams.sort = '' + filters.sort;
      }
      if (filters.idPosition) {
        params.queryParams.idPosition = filters.idPosition;
      }
    }

    return this.getFileMeterList(params);
  }

  getFileMeterDataForPosition(
    idPosition: number,
    limit: number = 50,
    offset: number = 0,
    allowEmpty: boolean = true): Observable<FileMeterData[]> {
    const params = this.getEmptyParams();
    params.queryParams.limit = limit;
    params.queryParams.offset = offset;
    params.config.strict = !allowEmpty;
    params.queryParams.idPosition = ['' + idPosition];
    params.queryParams.svUtilityType = UtilityTypeEnum[this.cs.getCurrentMediaType()];
    return this.getFileMeterList(params);
  }

  addFileMeterData(item: any,
    svIdPosition: number,
    options: { emitEvent: boolean } = { emitEvent: true }): Observable<FileMeterData> {
    const params = this.getEmptyParams();
    params.body = item;
    params.headers.svIdPosition = svIdPosition + '';

    return this.fileMeterDataService.postFileMeterData(params)
      .do((added: FileMeterData) => {
        if (options.emitEvent) {
          this.fileMeterDataSubject$.next(added);
        }
      });
  }

  getFileMeterDataById(id: number, allowEmpty: boolean = false): Observable<FileMeterData> {
    const params = this.getEmptyParams();
    params.path.idFileMeterData = '' + id;
    params.config.strict = !allowEmpty;

    return this.fileMeterDataService.getFileMeterDataById(params);
  }

  deleteFileMeterDataById(id: number, allowEmpty: boolean = false, options: { emitEvent: boolean } = { emitEvent: true }): Observable<FileMeterData> {
    const params = this.getEmptyParams();
    params.path.idFileMeterData = '' + id;
    params.config.strict = !allowEmpty;

    return this.fileMeterDataService.deleteFileMeterDataById(params)
      .do((removed: FileMeterData) => {
        if (options.emitEvent) {
          this.fileMeterDataSubject$.next(removed);
        }
      });
  }

  getFileMeterDataListCount(
    filters?: { idFileMeterData?: string[]; idPosition?: string[] },
    allowEmpty: boolean = false): Observable<number> {
    const params = this.getEmptyParams();
    params.queryParams.svUtilityType = UtilityTypeEnum[this.cs.getCurrentMediaType()];
    if (filters) {
      if (filters.idFileMeterData) {
        params.queryParams.idFileMeterData = filters.idFileMeterData || undefined;
      }
      if (filters.idPosition) {
        params.queryParams.idPosition = filters.idPosition || undefined;
      }

    }
    return this.fileMeterDataService.getFileMeterdataListCount(params);
  }

  getBillingFile(idPosition: number, startTime: number, endTime: number): Observable<File> {
    const params = this.getEmptyParams();
    params.queryParams.idPosition = ['' + idPosition];
    params.queryParams.from = startTime + '';
    params.queryParams.to = endTime + '';

    return this.fileMeterDataService.getBillingFile(params);
  }

  private getFileMeterList(params): Observable<FileMeterData[]> {
    return this.fileMeterDataService.getFileMeterDataList(params);
  }

  protected getEmptyParams(): FileMeterDataHttpParamG {
    const r: FileMeterDataHttpParamG = { body: {} as FileMeterData, headers: {}, path: {}, queryParams: {}, config: {} };
    return r;
  }
}
