import { INameMapper } from '@shared/types/applicationTypes';


export interface KeyCloakTokenResult {
  success: boolean;
  token: string;
}

export interface KeyCloakConstructorParams {
  url: string;
  realm: string;
  clientId: string;
  'ssl-required': 'external';
  'public-client': boolean;
  resource: string;
  'auth-server-url': string;
  'confidential-port': number;
  logoutUrl: string;
  redirect_uri: string;
}

export enum RolesEnum  {
  'ANALYST' = 0,
  'VIEWER' = 1,
  'ADMIN' = 2,
  'UNKNOWN' = 3
}

export function getRoleName(role: RolesEnum): INameMapper {
  switch (role) {
    case RolesEnum.ANALYST:
      return { id: RolesEnum.ANALYST, name: $localize`:@@role names/roles/analyst:Analyst`, classType: 'user-role-analyst' };
    case RolesEnum.VIEWER:
      return { id: RolesEnum.VIEWER, name: $localize`:@@role names/roles/viewer:Viewer`, classType: 'user-role-viewer' };
    case RolesEnum.ADMIN:
      return { id: RolesEnum.ADMIN, name: $localize`:@@role names/roles/admin:Administrator`, classType: 'user-role-admin' };
    case RolesEnum.UNKNOWN:
      return { id: RolesEnum.UNKNOWN, name: $localize`:@@role names/roles/unknown:Guest`, classType: 'user-role-unknown' };
  }
  return undefined;
}
