import { ElementRef, OnInit, OnDestroy, Input, Output, EventEmitter, Directive } from '@angular/core';
import { UploaderOptions, UploadOutput, UploadInput } from '../classes/interfaces';
import { Subscription } from 'rxjs/Subscription';
import { NgUploaderService } from '../classes/ngx-uploader.class';


@Directive()
export class UploadClass implements OnInit, OnDestroy {

  protected upload: NgUploaderService;
  protected el: HTMLInputElement;

  @Input() uploadInput: EventEmitter<UploadInput>;
  @Input() options: UploaderOptions;
  @Output() uploadOutput: EventEmitter<UploadOutput>;
  @Output() filesList: EventEmitter<any>;

  protected _sub: Subscription[];

  constructor( public elementRef: ElementRef) {
    this.el = elementRef.nativeElement;
    this.uploadOutput = new EventEmitter<UploadOutput>();
    this.filesList = new EventEmitter<any>();
  }

  ngOnInit(): void {
    const concurrency = this.options && this.options.concurrency || Number.POSITIVE_INFINITY;
    const allowedContentTypes = this.options && this.options.allowedContentTypes || ['*'];
    this.upload = new NgUploaderService(concurrency, allowedContentTypes);
    this.el = this.elementRef.nativeElement;
    this._sub = [];

    this._sub.push(
      this.upload.serviceEvents.subscribe((event: UploadOutput) => {
        this.uploadOutput.emit(event);
      },
      (e) => { throw new Error(e); },
      () => { console.warn('Completed'); }
    )
    );

    this._sub.push(
      this.upload.filesListEvents.subscribe((event: any) => {
        this.filesList.emit(event);
      },
      (e) => { throw new Error(e); },
      () => { console.warn('Completed'); })
    );

    if (this.uploadInput instanceof EventEmitter) {
      this._sub.push(this.upload.initInputEvents(this.uploadInput));
    }
  }

  ngOnDestroy(): void {
    this._sub.forEach(sub => sub.unsubscribe());
  }

  clearRefs() {
    this.el.value = '';
  }

  getElementRef(): HTMLInputElement {
    return this.el;
  }
}
