import { Component, HostListener, Input, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: "sv-grafana-dashboard",
  templateUrl: "grafana-dashboard.component.html",
  styleUrls: ["grafana-dashboard.component.sass"],
})
export class GrafanaDashboardComponent implements OnInit {
  private url: string;

  @Input() set iframeUrl(value: string) {
    this.url = value;
    this.setSecureUrl();
  }

  @Input() width: number;
  @Input() height: number;
  fullSizeMode: boolean = false;
  orginalWidth: number;
  orginalHeight: number;
  secureURL: SafeResourceUrl;

  constructor(public sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.orginalWidth = this.width;
    this.orginalHeight = this.height;
    this.setSecureUrl();
  }

  enableFullscreenMode() {
    this.fullSizeMode = !this.fullSizeMode;
    this.resize();
  }

  @HostListener("document:keydown.escape", ["$event"])
  onKeydownHandler() {
    if (this.fullSizeMode === true) {
      this.fullSizeMode = false;
    }
    this.resize();
  }

  private resize() {
    if (this.fullSizeMode) {
      this.width = screen.width;
      this.height = screen.height;
    } else {
      this.width = this.orginalWidth;
      this.height = this.orginalHeight;
    }
    this.setSecureUrl();
  }

  private setSecureUrl() {
    this.secureURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }
}
