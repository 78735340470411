import { Component, OnInit, Output, EventEmitter, ElementRef, ViewChild, ChangeDetectionStrategy, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';

/* External libraries */
import 'rxjs/add/operator/switchMap';
import { Observable, Subscription, of, throwError, Subject } from 'rxjs';
import { distinct, map, concatMap, tap, takeWhile, switchMap, takeUntil } from 'rxjs/operators';
/* Application services */
import { PositionDataStoreService } from '@shared/services/dataStore/positionDataStore.service';
import { PositionTransitions, EventType, EventStatus } from '@shared/types';
/* Application datatypes */
import { Position } from '@shared/models/appModels/position.model';
import { RestEnumMpapper } from '@shared/models/RestSupport';
import { AnalysisProcessErorr } from '@shared/models/RestSupport/AnalysisProcessError.model';
import { AnalysisAlgorithmTypeEnum } from '@shared/types/modelTypes/analysisAlgorithmTypes';
// Env
import { environment } from '@env/environment';
import { MeterPoint } from '@shared/models/appModels/meterPoint.model';
import { AnalysisStatusDataStoreService } from '@shared/services/dataStore/analysisProcessDataStore.service';
import { AnalysisLocalisation as loc } from '../../data-analysis-localisation';
import {
  AnalysisProcessStageEnum, AnalysisProcessStatusEnum, AnalysisProcessNameMapper,
  AnalysisProcessStatusMapper, AnalysisProcessFailureEnum, AnalysisProcessFailureMapper
} from '@shared/types/modelTypes/analysisProcessTypes';
import { AnalysisStatus } from '@shared/models/appModels/analysisStatus.model';
import { IPolledData } from '@shared/types/applicationTypes';
import { IAnalysisAlgorithm } from '@shared/models/appModels/analysisAlgorithm.model';
import { typesLocalisation } from '@shared/types/localisation';
import { ChangeDetectorRef } from '@angular/core';
import { veeProcessNames } from '@shared/types/modelTypes/veeProcessTypes';

interface UpdateList {
  listUpdate: boolean;
  requestUpdate: boolean;
}

interface ButtonState {
  text: string;
  isVisible: boolean;
  isDisabled: boolean;
}

enum ButtonsStateEnum {
  'EMPTY' = 0,
  'COMPLETE',
  'PENDING',
  'NO_DATA',
  'FAILED',
  'ERROR',
  'FREEZE'
}

@Component({

  // tslint:disable-next-line:component-selector
  selector: 'sv-data-analysis-process',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'data-analysis-process.component.html',
  styleUrls: ['data-analysis-process.component.sass'],
})
export class DataAnalysisProcessComponent implements OnInit, OnDestroy {
  private static statusMapper: RestEnumMpapper<typeof AnalysisProcessStageEnum> = new RestEnumMpapper<typeof AnalysisProcessStageEnum>();

  @Input() scheduleId: number;
  @Input() positionId: number;
  @Input() analysisEvent: any;
  @Output() buttonNewAnalysisClick = new EventEmitter();
  @Output() buttonShowResult = new EventEmitter();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('filter') filter: ElementRef;
  private longPollSubs$: Subscription;
  readonly debugMode: boolean = environment.debug;
  readonly seeDetailsMdIcon: string = 'expand_more';
  readonly hideDetailsMdIcon: string = 'expand_less';
  readonly buttonLabel = loc[environment.language].texts.summary.buttonLabel;
  readonly results = loc[environment.language].texts.summary.showResults;
  readonly dataMissing = typesLocalisation.AnalysisProcessFailureMapper[environment.language].AnalysisFailureText.DATA_MISSING;
  @Input() listAlgorithmsDetectionOfAnomalies: IAnalysisAlgorithm[] = [];
  @Input() listAlgorithmsForecasting: IAnalysisAlgorithm[] = [];
  @Input() listEnabledAlgorithm: IAnalysisAlgorithm[];
  @Input() actualAnalysisAlgorithm: IAnalysisAlgorithm;
  //// Table
  expandedElement;
  actualShowElement: number;
  showProcessProgression: boolean = false;

  AnalysisProcessStageEnum: typeof AnalysisProcessStageEnum = AnalysisProcessStageEnum;
  AnalysisProcessStatusEnum: typeof AnalysisProcessStatusEnum = AnalysisProcessStatusEnum;
  AnalysisAlgorithmTypeEnum: typeof AnalysisAlgorithmTypeEnum = AnalysisAlgorithmTypeEnum;
  analysisProcessSteps: { stage: string, status: string, statusEnum: AnalysisProcessStatusEnum }[] = [];

  actual_position = 0;
  lastQueryState: AnalysisStatus;
  toggleAlgorithm: boolean = false;
  lastPolledState: IPolledData<AnalysisStatus>;
  analysisButtonState: ButtonState;
  uploadButton: ButtonState;
  analysisDone: boolean;
  analysisProgress: boolean;
  firstAnalysis: boolean;
  analysisD: boolean;
  analysisFailed: boolean;
  analysisStatus: AnalysisStatus;
  private readonly destroy$ = new Subject();

  isExpansionDetailRow = (index, row) => row.hasOwnProperty('detailRow');
  isExpandedElement = (row: MeterPoint) => (this.actualShowElement === row.idMeterPoint && this.expandedElement !== null);


  constructor(
    private analysisProcessService: AnalysisStatusDataStoreService,
    private router: Router,
    private positionDataStoreService: PositionDataStoreService,
    private cdr: ChangeDetectorRef,

  ) {
    this.lastQueryState = undefined;
    this.analysisButtonState = { isDisabled: false, isVisible: true, text: loc[environment.language].texts.asset.assetDataPreview.buttonLabel };
    this.uploadButton = { isDisabled: true, isVisible: false, text: loc[environment.language].texts.asset.assetDataPreview.upload };
  }

  ngOnInit() {
    this.analysisProcessService.getAnalysisStateForPosition(this.positionId, AnalysisAlgorithmTypeEnum[this.actualAnalysisAlgorithm.algorithmType], true).subscribe(v => {
      this.analysisStatus = v;
      this.analysisD = this.analysisStatus.status === AnalysisProcessStatusEnum.DONE ? true : false;
      this.analysisFailed = this.analysisStatus.status === AnalysisProcessStatusEnum.FAILED ? true : false;
      if (this.analysisD || this.analysisFailed) {
        this.firstAnalysis = this.analysisFailed;
        this.analysisDone = this.analysisD;
        this.analysisProgress = false;
        this.analysisProcessSteps = [];
        if (this.actualAnalysisAlgorithm) {
          this.actualAnalysisAlgorithm.algorithmType === 0 ? this.analysisProcessSteps.push({
            stage: AnalysisProcessNameMapper[0].name,
            status: this.handleStatus(this.analysisStatus, AnalysisProcessStageEnum.DETECTION_OF_ANOMALIES),
            statusEnum: this.handleStatusEnum(this.analysisStatus, AnalysisProcessStageEnum.DETECTION_OF_ANOMALIES)
          }) : this.analysisProcessSteps.push({
            stage: AnalysisProcessNameMapper[1].name,
            status: this.handleStatus(this.analysisStatus, AnalysisProcessStageEnum.FORECASTING),
            statusEnum: this.handleStatusEnum(this.analysisStatus, AnalysisProcessStageEnum.FORECASTING)
          });
          this.showProcessProgression = true;
          this.cdr.markForCheck();
        }
      } else if (this.analysisStatus.status === AnalysisProcessStatusEnum.IN_PROGRESS) {
        this.onAnalysisAction();
      }
    });
    this.cdr.detectChanges();
  }

  newAnalysis(): void {
    this.firstAnalysis = true;
    this.analysisDone = false;
    this.analysisProgress = false;
    this.buttonNewAnalysisClick.emit();
    this.cdr.markForCheck();
  }

  showSummary(): void {
    this.buttonShowResult.emit(true);
  }
  onAnalysisAction(setAnalysis: boolean = false): void {
    this.firstAnalysis = false;
    this.analysisProgress = true;
    this.analysisDone = false;
    this.toggleAlgorithm = false;
    var algorithmType: string;
    this.actualAnalysisAlgorithm && this.actualAnalysisAlgorithm.algorithmType === 0 ? algorithmType = 'DETECTION_OF_ANOMALIES' : algorithmType = 'FORECASTING';
    if (setAnalysis || this.analysisStatus === undefined) {
      this.analysisProcessService.getAnalysisStateForPosition(this.positionId, algorithmType, true)
        .concatMap<AnalysisStatus, Position>(status => {
          this.initAnalysisProcessStatusProgression(status);
          return this.getInitialTriggerObservable(status)
            .do((p: Position) => {
              if (setAnalysis) {
                this.analysisStatus = status;
                this.analysisEvent.aggregation = status.aggregation;
                this.analysisEvent.startDate = status.startDate;
                this.analysisEvent.endDate = status.endDate;
                this.analysisEvent.meter = status.meterId;
                this.analysisEvent.address = status.address;
                this.showProcessProgression = true;
                this.setAnalysisButtonText(p);
                this.setButtonsState(ButtonsStateEnum.PENDING);
              } else {
                this.setAnalysisButtonText(p);
                this.setButtonsState(ButtonsStateEnum.PENDING);
                this.lastQueryState = undefined;
                this.analysisProcessSteps = [];
                this.showProcessProgression = true;
                this.analysisStatus = undefined;
              }
            });
        })
        .concatMap<Position, AnalysisStatus>((p: Position) => {
          if (setAnalysis) {
            return this.analysisProcessService.setAnalysisStateForPosition(this.positionId,
              algorithmType,
              AnalysisProcessStageEnum[algorithmType],
              this.analysisEvent.aggregation,
              this.analysisEvent.startDate,
              this.analysisEvent.endDate,
              this.analysisEvent.meter,
              this.analysisEvent.address,
              algorithmType);
          }
          return Observable.of(status);

        })
        /* After - start polling state and wait for completeness */
        .concatMap<AnalysisStatus, IPolledData<AnalysisStatus>>((stepResponse: AnalysisStatus) => {
          this.cdr.markForCheck();
          return this.startPollingSequence();
        })
        .subscribe((updatedPos: IPolledData<AnalysisStatus>) => {
          this.lastPolledState = updatedPos;
        },
          (err) => {
            this.setAnalysisFailed();
          },
          () => {
            console.log('Analysis Process Complete');
            this.firstAnalysis = false;
            this.analysisProgress = false;
            this.analysisDone = true;
            this.buildProgressionList(this.lastQueryState);
            this.positionDataStoreService.getPositionById(this.positionId)
              .concatMap<Position, Position>((p: Position) => {
                return this.setPositionAnalysisEvent(p, PositionTransitions.ANALYSIS_FINISH);
              })
              .subscribe(p => {
                this.setAnalysisButtonText(p, AnalysisStatus.generateAnalysisDoneState(this.actualAnalysisAlgorithm.algorithmType));
                this.setButtonsState(ButtonsStateEnum.COMPLETE);
                this.buttonShowResult.emit(false);
              });
          });
    } else if (this.analysisStatus.status === AnalysisProcessStatusEnum.IN_PROGRESS) {
      this.analysisButtonState.text = loc[environment.language].texts.asset.assetDataPreview.ongoing;
      this.setButtonsState(ButtonsStateEnum.PENDING);
      this.hendlePendingAnalysis(this.analysisStatus);
      this.cdr.markForCheck();
    }
    else {
      this.initAnalysisProcessStatusProgression(this.analysisStatus);
    }
  }

  private getInitialTriggerObservable(analysisStatus: AnalysisStatus): Observable<Position> {
    let retObs: Observable<Position>;
    !this.isProcessEmpty(analysisStatus) ?
      retObs = this.clearAnalysisData().concatMap<AnalysisStatus, Position>((as: AnalysisStatus) => {
        return this.positionDataStoreService.getPositionById(this.positionId)
          .concatMap<Position, Position>((p: Position) => {
            return this.setPositionAnalysisEvent(p, PositionTransitions.ANALYSIS_START);
          });
      })
      : retObs = this.positionDataStoreService.getPositionById(this.positionId).concatMap<Position, Position>((p: Position) => {
        return this.setPositionAnalysisEvent(p, PositionTransitions.ANALYSIS_START);
      });
    return retObs;
  }

  private setAnalysisButtonText(position: Position, analysisStatus?: AnalysisStatus) {
    switch (position.events[EventType.ANALYSIS].status) {
      case EventStatus.TODO: {
        this.analysisButtonState.text = loc[environment.language].texts.asset.assetDataPreview.buttonLabel;
        break;
      }
      case EventStatus.IN_PROGRESS: {
        // tslint:disable-next-line: max-line-length
        (analysisStatus && analysisStatus.stage === AnalysisProcessStageEnum.DETECTION_OF_ANOMALIES && analysisStatus.status === AnalysisProcessStatusEnum.DONE) ?
          // tslint:disable-next-line: max-line-length
          this.analysisButtonState.text = loc[environment.language].texts.asset.assetDataPreview.restart : this.analysisButtonState.text = loc[environment.language].texts.asset.assetDataPreview.ongoing;
        break;
      }
      case EventStatus.DONE: {
        this.analysisButtonState.text = loc[environment.language].texts.asset.assetDataPreview.restart;
        break;
      }
      default: {
        this.analysisButtonState.text = loc[environment.language].texts.asset.assetDataPreview.buttonLabel;
        break;
      }
    }
  }

  private clearAnalysisData(): Observable<AnalysisStatus> {
    let algorithmType: string;
    this.actualAnalysisAlgorithm && this.actualAnalysisAlgorithm.algorithmType === 0 ? algorithmType = 'DETECTION_OF_ANOMALIES' : algorithmType = 'FORECASTING';
    return this.analysisProcessService.clearAnalysisStateForPosition(this.positionId, algorithmType);
  }

  private setPositionAnalysisEvent(p: Position, state: PositionTransitions): Observable<Position> {
    const pos = Position.positionStateMachine(p, state);
    return this.positionDataStoreService.updatePosition(pos, { emitEvent: false });
  }

  setAnalysisFailed() {
    this.positionDataStoreService.getPositionById(this.positionId)
      .concatMap((p: Position) => {
        return this.setPositionAnalysisEvent(p, PositionTransitions.ANALYSIS_FAILED);
      })
      .subscribe((p: Position) => {
        this.setAnalysisButtonText(p);
        this.setButtonsState(ButtonsStateEnum.FAILED);
        this.buttonShowResult.emit(false);
      });
  }

  private hendlePendingAnalysis(status: AnalysisStatus) {
    this.buildProgressionList(status);
    this.showProcessProgression = true;

    /* Analysis process is pending for this position, request polling changes */
    this.longPollSubs$ = this.startPollingSequence()
      .subscribe((polledState) => {
        console.log(polledState);
      },
        (err) => {
          this.setAnalysisFailed();
        },
        () => {
          console.log('Analysis process Complete');
          this.firstAnalysis = false;
          this.analysisProgress = false;
          this.analysisDone = true;
          this.buildProgressionList(this.lastQueryState);
          this.positionDataStoreService.getPositionById(this.positionId)
            .concatMap<Position, Position>((p: Position) => {
              return this.setPositionAnalysisEvent(p, PositionTransitions.ANALYSIS_FINISH);
            })
            .subscribe(p => {
              this.setAnalysisButtonText(p);
              this.setButtonsState(ButtonsStateEnum.COMPLETE);
              this.buttonShowResult.emit(false);
            });
        });
  }

  private startPollingSequence(): Observable<IPolledData<AnalysisStatus>> {
    let updates: UpdateList = { listUpdate: false, requestUpdate: false };
    let done: boolean;
    let algorithmType: string;
    this.actualAnalysisAlgorithm && this.actualAnalysisAlgorithm.algorithmType === 0 ? algorithmType = 'DETECTION_OF_ANOMALIES' : algorithmType = 'FORECASTING';
    return this.analysisProcessService.longPollingState(this.positionId, algorithmType, 1000,
      {
        sequence: false,
        aggregation: this.analysisEvent.aggregation,
        startDate: this.analysisEvent.startDate,
        endDate: this.analysisEvent.endDate,
        meterId: this.analysisEvent.meter,
        address: this.analysisEvent.address
      }).pipe(
        distinct(), concatMap<IPolledData<AnalysisStatus>, any>((state: IPolledData<AnalysisStatus>) => {
          if (this.positionId !== state.positionId) {
            return throwError(new Error('oops!'))
          }
          return of(state);
        }),
        takeUntil(this.destroy$),
        tap((distinctState: IPolledData<AnalysisStatus>) => {
          updates = this.setLastQueryState(distinctState.data);
          this.buildProgressionList(distinctState.data);
          this.cdr.markForCheck();
          if (this.isProcessFailed(distinctState.data) === true) {
            /* TODO: get real erorr reason */
            throw new AnalysisProcessErorr(
              `Analysis process failed due to reason: ${AnalysisProcessFailureMapper[AnalysisProcessFailureEnum.DATA_MISSING].name}`,
              AnalysisProcessFailureEnum.DATA_MISSING);
          }
        }),
        takeWhile((state: IPolledData<AnalysisStatus>) => {
          done = this.isWholeProcessComplete(state.data);
          return !done
        }),
        concatMap<IPolledData<AnalysisStatus>, Observable<IPolledData<AnalysisStatus>>>((state: IPolledData<AnalysisStatus>) => {
          return Observable.of(state);
        }));
  }

  private isWholeProcessComplete(status: AnalysisStatus): boolean {
    return status.status === AnalysisProcessStatusEnum.DONE;
  }

  private isProcessFailed(status: AnalysisStatus): boolean {
    return status.status === AnalysisProcessStatusEnum.FAILED;
  }

  private buildProgressionList(status: AnalysisStatus) {
    this.analysisProcessSteps = [];
    AnalysisProcessNameMapper
      .filter(proc => proc.isPrintable).forEach((p, idx) => {
        if (idx <= status.stage) {
          if (this.actualAnalysisAlgorithm.algorithmType === idx) {
            this.analysisProcessSteps.push({
              stage: AnalysisProcessNameMapper[idx].name,
              status: this.handleStatus(status, AnalysisProcessNameMapper[idx].id),
              statusEnum: this.handleStatusEnum(status, AnalysisProcessNameMapper[idx].id)
            });
          }
        }
      });
    this.cdr.markForCheck();
  }

  private handleStatus(currentAnalysisStatus: AnalysisStatus, checkedState: AnalysisProcessStageEnum): string {
    if (currentAnalysisStatus.stage === checkedState) {
      /* Fill only last state with status recieved from backend */
      return AnalysisProcessStatusMapper[currentAnalysisStatus.status].name;
    } else {
      /* All provious steps are considered done */
      return AnalysisProcessStatusMapper[AnalysisProcessStatusEnum.DONE].name;
    }
  }

  private handleStatusEnum(currentAnalysisStatus: AnalysisStatus, checkedState: AnalysisProcessStageEnum): AnalysisProcessStatusEnum {
    if (currentAnalysisStatus.stage === checkedState) {
      /* Fill only last state with status recieved from backend */
      return currentAnalysisStatus.status as AnalysisProcessStatusEnum;
    } else {
      /* All provious steps are considered done */
      return AnalysisProcessStatusEnum.DONE;
    }
  }

  private isProcessEmpty(status: AnalysisStatus): boolean {
    return Object.keys(status).length === 0;
  }

  private setLastQueryState(state: AnalysisStatus): UpdateList {
    let listUpdateRequired: boolean = false;
    let requestUpdateRequired: boolean = false;
    if (typeof (this.lastQueryState) === 'undefined' || this.lastQueryState !== state) {
      this.lastQueryState = state;
      if (this.lastQueryState.status === AnalysisProcessStatusEnum.DONE) {
        requestUpdateRequired = true;
      }
      this.buttonShowResult.emit(false);
      listUpdateRequired = true;
    } else {
      /* State did not changed since last poll */
    }
    return { listUpdate: listUpdateRequired, requestUpdate: requestUpdateRequired };
  }
  setButtonsState(state: ButtonsStateEnum) {
    switch (state) {
      case ButtonsStateEnum.EMPTY:
      case ButtonsStateEnum.FAILED:
      case ButtonsStateEnum.COMPLETE: {
        this.analysisButtonState.isVisible = true;
        this.analysisButtonState.isDisabled = false;
        break;
      }
      case ButtonsStateEnum.PENDING:
      case ButtonsStateEnum.ERROR: {
        this.analysisButtonState.isVisible = true;
        this.analysisButtonState.isDisabled = true;

        break;
      }
      case ButtonsStateEnum.NO_DATA: {
        this.analysisButtonState.isVisible = false;
        this.analysisButtonState.isDisabled = false;
        this.uploadButton.isVisible = false;

        break;
      }
    }
  }

  onUpload() {
    this.positionDataStoreService.getPositionById(this.positionId, false)
      .subscribe((pos: Position) => {
        this.router.navigate(['/data-analysis'], { queryParams: { schedule: pos.idSchedule, position: pos.idPosition } });
      });
  }

  private initAnalysisProcessStatusProgression(status: AnalysisStatus) {
    this.positionDataStoreService.getPositionById(this.positionId)
      .do(position => {
        this.uploadButton.isVisible = false;
        this.uploadButton.isDisabled = true;
        this.setAnalysisButtonText(position);
      })
      .subscribe((p: Position) => {
        if (!this.isProcessEmpty(status) && this.isWholeProcessComplete(status)) {
          this.setButtonsState(ButtonsStateEnum.COMPLETE);
          this.setAnalysisButtonText(p, AnalysisStatus.generateAnalysisDoneState(this.actualAnalysisAlgorithm.algorithmType));
          this.buildProgressionList(status);
          this.showProcessProgression = true;
          this.uploadButton.isVisible = true;
          this.uploadButton.isDisabled = false;
        } else if (!this.isProcessEmpty(status) && this.analysisFailed) {
          this.setButtonsState(ButtonsStateEnum.FAILED);
          this.buildProgressionList(status);
        } else if (!this.isProcessEmpty(status)) {
          this.setButtonsState(ButtonsStateEnum.PENDING);
          this.setAnalysisButtonText(p);
          this.hendlePendingAnalysis(status);
        }
        this.cdr.markForCheck();
      },
        (err) => {
          // this.setButtonsState(ButtonsStateEnum.E)
        });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    try {
      this.longPollSubs$.unsubscribe();
    } catch (e) {

    }
  }
}
