import { RestEnumMpapper } from '@shared/models/RestSupport';
import { UtilityTypeEnum } from '@shared/types/utilityType';
import { IGetInterfaceType } from '@shared/types/applicationTypes';
import {
  VeeProcessStageEnum, VeeProcessStatusEnum
} from '@shared/types/modelTypes/veeProcessTypes';

export interface IVeeStatus {
  stage?: VeeProcessStageEnum | string;
  status?: VeeProcessStatusEnum | string;
  mediaType?: UtilityTypeEnum | string;
}

export interface IVeeRequest {
  requestedStage?: VeeProcessStageEnum | string;
  requestedStatus?: VeeProcessStatusEnum | string;
}

export class VeeStatus implements IVeeStatus, IVeeRequest, IGetInterfaceType<IVeeStatus | IVeeRequest, VeeStatus> {

  static VeeProcessStageMapper: RestEnumMpapper<typeof VeeProcessStageEnum> = new RestEnumMpapper<typeof VeeProcessStageEnum>();
  static MediaTypeMapper: RestEnumMpapper<typeof UtilityTypeEnum> = new RestEnumMpapper<typeof UtilityTypeEnum>();
  static VeeStatusMapper: RestEnumMpapper<typeof VeeProcessStatusEnum> = new RestEnumMpapper<typeof VeeProcessStatusEnum>();


  requestedStage?: VeeProcessStageEnum | string;
  stage?: VeeProcessStageEnum | string;
  status?: VeeProcessStatusEnum | string;
  requestedStatus?: VeeProcessStatusEnum | string;
  mediaType?: UtilityTypeEnum | string;

  static generateValidationDoneState(): VeeStatus {
    return new VeeStatus({ stage: VeeProcessStageEnum.VALIDATION, status: VeeProcessStatusEnum.DONE });
  }

  constructor(iVeeProcess: (IVeeRequest | IVeeStatus)) {

    this.requestedStage = (typeof ((<IVeeRequest>iVeeProcess).requestedStage) !== 'undefined') ?
      this.assignStage((<IVeeRequest>iVeeProcess).requestedStage) : undefined;

    this.requestedStatus = (typeof ((<IVeeRequest>iVeeProcess).requestedStatus) !== 'undefined') ?
      this.assignStatus((<IVeeRequest>iVeeProcess).requestedStatus) : undefined;

    this.stage = (typeof ((<IVeeStatus>iVeeProcess).stage) !== 'undefined') ?
      this.assignStage((<IVeeStatus>iVeeProcess).stage) : undefined;

    this.status = (typeof ((<IVeeStatus>iVeeProcess).status) !== 'undefined') ?
      this.assignStatus((<IVeeStatus>iVeeProcess).status) : undefined;

    this.mediaType = (typeof ((<IVeeStatus>iVeeProcess).mediaType) !== 'undefined') ?
      this.assgnMediaType((<IVeeStatus>iVeeProcess).mediaType) : undefined;
  }

  assignStage(eIn: VeeProcessStageEnum | string): VeeProcessStageEnum {
    try {
      return VeeStatus.VeeProcessStageMapper.getEnumAsNumber(VeeProcessStageEnum, eIn);
    } catch (e) {
      return undefined;
    }
  }

  assignStatus(eIn: VeeProcessStatusEnum | string): VeeProcessStatusEnum {
    try {
      return VeeStatus.VeeStatusMapper.getEnumAsNumber(VeeProcessStatusEnum, eIn);
    } catch (e) {
      return undefined;
    }
  }

  assgnMediaType(eIn: UtilityTypeEnum | string): UtilityTypeEnum {
    try {
      return VeeStatus.MediaTypeMapper.getEnumAsNumber(UtilityTypeEnum, eIn);
    } catch (e) {
      return undefined;
    }
  }


  getInterface(webInterface: boolean): IVeeStatus | IVeeRequest {
    const ret: (IVeeStatus | IVeeRequest) = {};

    /* This is the ugliest piece of code i have ever wrote in my life. */
    if (typeof ((<IVeeRequest>this).requestedStage) !== 'undefined') {
      (<IVeeStatus>ret).stage = VeeStatus.VeeProcessStageMapper.getEnumAsString(VeeProcessStageEnum, this.requestedStage);
    }
    if (typeof ((<IVeeRequest>this).requestedStatus) !== 'undefined') {
      (<IVeeStatus>ret).status = VeeStatus.VeeStatusMapper.getEnumAsString(VeeProcessStatusEnum, this.requestedStatus);
    }
    if (typeof ((<IVeeStatus>this).stage) !== 'undefined') {
      (<IVeeStatus>ret).stage = VeeStatus.VeeProcessStageMapper.getEnumAsString(VeeProcessStageEnum, this.stage);
    }
    if (typeof ((<IVeeStatus>this).status) !== 'undefined') {
      (<IVeeStatus>ret).stage = VeeStatus.VeeStatusMapper.getEnumAsString(VeeProcessStatusEnum, this.status);
    }
    if (typeof ((<IVeeStatus>this).mediaType) !== 'undefined') {
      (<IVeeStatus>ret).mediaType = VeeStatus.MediaTypeMapper.getEnumAsString(UtilityTypeEnum, this.mediaType);
    }

    return ret;
  }

}
