import { TableDataSource } from '@shared/models/tableDataSource.model';

import { FileOperationalData } from '@shared/models/appModels/fileOperationalData';


export class FileOperationalTableElement extends FileOperationalData {
  detailRow: boolean;
  rowId: number;
}

export class FileOperationalDataSource extends TableDataSource<FileOperationalData, FileOperationalTableElement> {

  protected renderData(data: FileOperationalData[]) {
    const rows: FileOperationalTableElement[] = [];
    data.forEach((baseElement: FileOperationalData) => {

      const mainElement = new FileOperationalTableElement(baseElement.getInterface());

      mainElement.detailRow = false;
      mainElement.rowId = baseElement.idFileOperationalData;
      const detailedElement: FileOperationalTableElement = Object.assign({}, mainElement);
      detailedElement.detailRow = true;
      detailedElement.rowId = baseElement.idFileOperationalData;
      /* Duplicate elements to display details */
      rows.push(mainElement, detailedElement);
    });
    return rows;
  }
}
