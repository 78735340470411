<div class="sidebar" id="sidebar" [class.expand]="isExpanded">
	<ul class="nav-left">
		<li
			*ngFor="let menuItem of linksConfig"
			routerLinkActive="active-link"
      (click)="hideSidebar()">
			<!-- preserveQueryParams -->
			<div *ngIf="menuItem.url !== undefined"
				[routerLink]="getLink(menuItem)" [queryParams]="getNavigationContext(menuItem)"
				[matTooltip]="menuItem.text"
				matTooltipPosition="right"
				[matTooltipDisabled]="isExpanded">
				<i class="material-icons md-24 notranslate">{{ menuItem.mdIcon }}</i>
				<span #mainSpan>{{ menuItem.text }}
					<i *ngIf="menuItem.sublist.length > 0" class="material-icons md-24 notranslate" >arrow_drop_down</i>
				</span>
			</div>
			<div *ngIf="menuItem.url === undefined">
				<i class="material-icons md-24 notranslate">{{ menuItem.mdIcon }}</i>
				<span #mainSpan>{{ menuItem.text }}
					<i *ngIf="menuItem.sublist.length > 0" class="material-icons md-24 notranslate" >arrow_drop_down</i>
				</span>
			</div>

			<ul *ngIf="menuItem.sublist.length > 0">
				<li *ngFor="let subMenu of menuItem.sublist">
            <div *ngIf="subMenu.url !== undefined"
				routerLinkActive="active-link"
              (click)="hideSidebar()" [routerLink]="getLink(subMenu)" [queryParams]="getNavigationContext(subMenu)">
              <i class="material-icons md-24 notranslate">{{ subMenu.mdIcon }}</i>
              <span #subSpan>{{ subMenu.text }}</span>
            </div>
            <div *ngIf="subMenu.url === undefined" routerLinkActive="active-link"
            (click)="hideSidebar()">
            <i class="material-icons md-24 notranslate">{{ subMenu.mdIcon }}</i>
            <span #subSpan>{{ subMenu.text }}</span>
          </div>
				</li>
			</ul>
		</li>
	</ul>
</div>
