<header>

	<div (click)="toggleSidebar()" class="btn-sidebar">
		<i class="material-icons notranslate">menu</i>
	</div>

	<div class="sv" id="sv-app-header">
		<img (click)="setActivity(); toggleSidebar(false)" routerLink="/home"
			src="{{ logo ? 'assets/img/smartx.png' : 'assets/img/logo-cp.png' }}"
			alt="ConnectPoint logo" class="logo">
	</div>

	<div class="navbar-menu">
		<mat-checkbox i18n="@@interactive tutorial/navbar/show tutorial" ngDefaultControl (change)="showTut($event)" [(ngModel)]="showTutorial" class="interactive-tut">
			Display hints</mat-checkbox>
		<button mat-button color="primary" [matMenuTriggerFor]="menu"
		matTooltip="Medium" i18n-matTooltip="@@top navbar/medium type tooltip">
			<i class="material-icons notranslate">{{ mediaIcon }}</i>{{ mediaText }}
		</button>
		<mat-menu #menu="matMenu" class="menu-box">
			<button mat-menu-item (click)="onMediaSelectClick(mediatype.id)" [disabled]=!support[mediatype.id]
			*ngFor="let mediatype of utilityTypesNames">
				<i class="material-icons notranslate">{{ mediatype.icon }}</i>
				<span>{{ mediatype.name }}</span>
			</button>
		</mat-menu>

		<ng-template [ngIf]="isLoggedIn()">
			<div class="user" (click)="onLoginClick()">
				<i class="material-icons notranslate">person</i>
				
				<div class="name">
					<span matTooltip="User" i18n-matTooltip="@@top navbar/user name tooltip">{{ getUserName() }}</span>
					<span *ngIf="getRole() !== undefined" class="role mat-small"
						matTooltip="Role" i18n-matTooltip="@@top navbar/role name tooltip">{{ getRoleName() }}</span>
				</div>
			</div>

			<button (click)="logout()" mat-icon-button color="primary" class="btn">
				<i class="material-icons notranslate" matTooltip="Logout" i18n-matTooltip="@@top navbar/logout button tooltip">power_settings_new</i>
			</button>
		</ng-template>
	</div>
</header>
