
import { UploadFile, UploadProgress} from '@ngx-uploader';

import { IGetInterfaceType } from '@shared/types/applicationTypes';
import { RestEnumMpapper } from '@shared/models/RestSupport/restEnumMapper.model';
import { FileExtensionEnum, FileExtensionNameMapping } from '@shared/types/applicationTypes';


export interface IFileOperationalData {
  idFileOperationalData: number;
  name: string;
  quantity: number;
  creationDate: number;
  file: FileExtensionEnum | string;
  size: number;
}

export class FileOperationalData implements IFileOperationalData, UploadFile, IGetInterfaceType<IFileOperationalData, FileOperationalData>  {
  /* Enum mapper */
  private static fileExtEnumMapper: RestEnumMpapper<typeof FileExtensionEnum> = new RestEnumMpapper<typeof FileExtensionEnum>();
  /* Main REST api */
  idFileOperationalData: number;
  quantity: number;
  creationDate: number;
  file: FileExtensionEnum;
  /* Shared */
  name: string;
  size: number;
  /* Ng-uploader extension */
  id: string;
  fileIndex: number;
  lastModifiedDate: Date;
  type: string;
  form: FormData;
  progress: UploadProgress;


  constructor(iFile: IFileOperationalData) {
    if (!iFile) { throw new TypeError('MeasurementData initialization: missing input interface as constructor argument!'); }

    this.idFileOperationalData = (typeof(iFile.idFileOperationalData) !== 'undefined') ? iFile.idFileOperationalData : undefined;
    this.quantity = (typeof(iFile.quantity) !== 'undefined') ? iFile.quantity : undefined;
    this.creationDate = (typeof(iFile.creationDate) !== 'undefined') ? iFile.creationDate : undefined;
    this.file = (typeof(iFile.file) !== 'undefined') ?
    FileOperationalData.fileExtEnumMapper.getEnumAsNumber(FileExtensionEnum, iFile.file) : undefined;
    this.name = (typeof(iFile.name) !== 'undefined') ? iFile.name : undefined;
    this.size = (typeof(iFile.size) !== 'undefined') ? iFile.size : undefined;

    /* Ng-uploader specific types created form REST interface */
    this.id = '' + this.idFileOperationalData;
    this.fileIndex = this.idFileOperationalData;
    this.lastModifiedDate = new Date(this.creationDate);
    this.type = FileOperationalData.fileExtEnumMapper.getEnumAsString(FileExtensionEnum, this.file);
    this.form = undefined;
    this.progress = undefined;
  }

  getFileExtension(): string {
    return FileExtensionNameMapping[this.file].name;
  }

  getInterface(webInterface: boolean = true): IFileOperationalData {
    const emptyValue = (webInterface) ? null : undefined;
    return {
      idFileOperationalData: this.idFileOperationalData,
      quantity: this.quantity,
      creationDate: this.creationDate,
      file: FileOperationalData.fileExtEnumMapper.getEnumAsNumber(FileExtensionEnum, this.file),
      name: this.name,
      size: (typeof(this.size) !== 'undefined') ? this.size : emptyValue,
    };
  }
}
