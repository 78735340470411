export const UploadLocalisation = [
  {
    lang: 'ENG',
    texts: {
      common: {
        uploadPrompt: { part1: 'Drag a file here or', part2: 'browse', part3: 'to upload.' },
        dropArea: 'Drop a file here',
        succes: 'File uploaded successfully.',
        error: 'Error uploading the file.',
        tooManyFiles: 'Only a single file can be sent at once',
        reload: 'Reload the data.',
        emptyDate: 'Date field is empty',
        emptyTemperature: 'The field with the temperature is empty',
        wrongMeterId: 'There is no given meter id.',
        Unknown: 'Unknown failure.',
        oversize: 'The file is too large.',
        badExtension: 'Incorrect file extension.',
        server: 'Server rejected the file.',
        uploadProgress: 'Upload Progress',
        upload: 'Upload',
        chhoseFileToUpload: 'Choose file to upload',
      },
      measurement: {
        /* Main component */
        componentTitle: 'Import measurement data',
        modalAddMeasurementTitle: 'Add measurement',
        modalEditMeasurementTitle: 'Edit measurement',
        modalViewMeasurementTitle: 'See measurement details',
        numberOfImportedMeasurements: 'Number of imported measurements:',
        numberOfMeasurementsWithoutReadingValue: 'Number of measurements without a reading value on the primary variable:',
        uploadButton: { DONE: 'Reupload', ELSE: 'Upload' },
        addNewButton: 'Add new',
        addNewButtonTooltip: 'Vee process is pending, unable to add new data',
        editButtonTooltip: 'Vee process is pending, unable to edit',
        tableFiller: { MissingMeasurement: 'N/A' },
        filterLabels: { MANUAL: 'manual', FROM_FILE: 'from file', EDITED: 'edited' },
        tableHeaders: { NAME: 'Name', SCHEDULE: 'Schedule', POSITION: 'Position', UPLOAD_DATE: 'Upload data', ACTIONS: 'Actions' },
        uploadCountersLabels: { TOTAL: 'imported data', INVALID: 'invalid', FILES: 'imported files' },
        detailButtonsLabels: { RELOAD: 'Reload data', PREVIEW: 'Preview data', VALIDATION: 'Go to validation', FILE: 'Show files' },
        reuploadNotSupported: 'Reuploading is not supported in this version',
        noData: 'No data found',
        measurements: 'Measurements',
        measurement: 'Measurement',
        listFile: 'File list',
        filtres: 'Filters:',
        date: 'Date',
        dateCreate: 'Date of creation',
        modificationDate: 'Date of modification',
        status: 'Status',
        actions: 'Actions',
        delete: 'Delete',
        edit: 'Edit',
        view: 'View',
        checking: 'Checking...',
        serialMeter: 'SerialMeter is',
        required: 'required',
        enter: 'Please, enter',
        operationalData: 'operational data',
        chooseData: 'Choose a date',
        optionalValues: 'Optional values',
        optionalValue: 'Optional value',
        name: 'Name',
        value: 'Value',
        cancel: 'Cancel',
        save: 'Save',
        NA: 'N/A',
        noUnit: 'No unit',
        energy: 'Energy',
        water: 'Volume',
        all: 'Show all',
        importType: 'Import type',
        user: 'User',
        automatic: 'Automatic',
        activeScheduler: 'Automatic Scheduler'
      },
      asset: {
        componentTitle: 'Import asset data',
        /* NEW */
        assetFilesPreview: {
          tableHeaders: { NAME: 'Name', UPLOAD_DATE: 'Upload date', ACTIONS: 'Actions' },
          noData: 'No data found',
          numerator: 'imported data'
        },
        assetDataPreview: {
          header: 'Asset data: Meter Points',
          tableHeaders: {
            METER_ID: 'Meter ID',
            LOCATION: 'Location',
            MES_TYPE: 'Measurement type',
            CUSTOMER: 'Customer',
            SEGMENT: 'Segment',
            ACTION: 'Action',
          },
          actions: {
            EDIT: 'Edit',
            DEACTIVATE: 'Deactivate meter',
            ACTIVATE: 'Activate meter',
            ASSIGN_CUSTOMER: 'Assign customer',
            UNASSIGN_CUSTOMER: 'Unassign customer'
          },
          noData: 'No data found',
          addNewButton: 'Add new',
          filtersLabel: 'Filters',
          id: 'Id',
          denotation: 'Denotation',
          assign: 'Assign customer for meter',
          meter: 'Meter',
          meterIs: 'Meter ID is',
          meterMustBe: 'Meter ID must be',
          unique: 'unique',
          meterType: 'Meter node type',
          address: 'Address',
          timeGroup: 'Time group',
          billed: 'is meter billed?',
          avtive: 'is meter active?',
          maxRead: 'Max read',
          must: 'must',
          number: 'be a number',
          master: 'Master Meter ID',
          invalid: 'Master Meter ID must refer to existing meter point',
          addCustomer: 'Add customer?',
          existing: 'Existing',
          customerId: 'Customer ID',
          customer: 'customer',
          meterpoint: 'meterpoint',
          both: 'both',
          disable: 'Disable...',
          noCustomer: 'no customer'
        }
      },
      temperatures: {
        header: 'Import temperature data',
        timestamp: 'Date',
        temperature: 'Temperature [°C]'
      }
    },
  },
  {
    lang: 'GER',
    texts: {
      common: {
        uploadPrompt: { part1: 'Datei hierhin ziehen oder', part2: 'einen Pfad auswählen', part3: 'zum Hochladen.' },
        dropArea: 'Datei hierhin ziehen',
        succes: 'Import der Datei erfolgreich.',
        error: 'Beim Import der Datei ist ein Fehler aufgetreten.',
        tooManyFiles: 'Es kann nur eine einzige Datei gleichzeitig gesendet werden',
        reload: 'Daten erneut importieren.',
        emptyDate: 'Datumsfeld ist leer',
        emptyTemperature: 'Das Feld mit der Temperatur ist leer',
        wrongMeterId: 'Es gibt keine angegebene Zähler ID.',
        Unknown: 'Unbekannter Fehler.',
        oversize: 'Datei überdimensioniert',
        badExtension: 'Falsche Dateiendung.',
        server: 'Der Server hat die Datei abgelehnt.',
        uploadProgress: 'Fortschritt hochladen',
        upload: 'Hochladen',
        chhoseFileToUpload: 'Datei zum Hochladen auswählen',
      },
      measurement: {
        /* Main component */
        componentTitle: 'Importiere Messdaten ',
        modalAddMeasurementTitle: 'Messwert hinzufügen',
        modalEditMeasurementTitle: 'Messwert bearbeiten',
        modalViewMeasurementTitle: 'Messwertdetails ansehen',
        numberOfImportedMeasurements: 'Anzahl der importierten Messungen:',
        numberOfMeasurementsWithoutReadingValue: 'Anzahl der Messungen ohne Lesewert für die Primärvariable:',
        uploadButton: { DONE: 'Erneut importieren', ELSE: 'Importieren' },
        addNewButton: 'Hinzufügen',
        addNewButtonTooltip: 'Der Vee-Prozess ist ausstehend, es können keine neuen Daten hinzugefügt werden',
        editButtonTooltip: 'Der Vee-Prozess ist ausstehend und kann nicht bearbeitet werden',
        tableFiller: { MissingMeasurement: 'N/A' },
        filterLabels: { MANUAL: 'manuell', FROM_FILE: 'aus Datei', EDITED: 'bearbeitet' },
        tableHeaders: { NAME: 'Name', SCHEDULE: 'Zeitplan', POSITION: 'Position', UPLOAD_DATE: 'Daten hochladen', ACTIONS: 'Aktionen' },
        uploadCountersLabels: { TOTAL: 'importierte Daten', INVALID: 'ungültig', FILES: 'importierte Dateien' },
        detailButtonsLabels: { RELOAD: 'Daten neu laden', PREVIEW: 'Datenvorschau', VALIDATION: 'Gehe zu Validierung', FILE: 'Dateien anzeigen' },
        reuploadNotSupported: 'Erneutes Importieren wird in dieser Version nicht unterstützt',
        noData: 'Keine Daten gefunden',
        measurements: 'Messwerte',
        measurement: 'Messwert',
        listFile: 'Dateiliste',
        filtres: 'Filter:',
        date: 'Datum',
        dateCreate: 'Erstellungsdatum',
        modificationDate: 'Änderungsdatum',
        status: 'Status',
        actions: 'Aktionen',
        delete: 'Löschen',
        edit: 'Bearbeiten',
        view: 'Sehen',
        checking: 'Überprüfung...',
        serialMeter: 'Zähler ID ist',
        required: 'erforderlich',
        enter: 'Bitte, eintreten',
        operationalData: 'Assetdaten',
        chooseData: 'Wählen Sie ein Datum',
        optionalValues: 'Optionale Werte',
        optionalValue: 'Optionaler Wert',
        name: 'Name',
        value: 'Wert',
        cancel: 'Abbrechen',
        save: 'Speichern',
        NA: 'Keine Daten',
        noUnit: 'Keine Einheit',
        energy: 'Energie',
        water: 'Volumen',
        all: 'Zeige alle',
        importType: 'Importtyp',
        user: 'Nutzer',
        automatic: 'Automatisch',
        activeScheduler: 'Automatischer Scheduler'
      },
      asset: {
        componentTitle: 'Import von Assetdaten',
        /* NEW */
        assetFilesPreview: {
          tableHeaders: { NAME: 'Name', UPLOAD_DATE: 'Datum des Hochladens', ACTIONS: 'Aktionen' },
          noData: 'Keine Daten gefunden',
          numerator: 'importiert daten'
        },
        assetDataPreview: {
          header: 'Assetdaten: Messgerät',
          tableHeaders: {
            METER_ID: 'Zähler ID',
            LOCATION: 'Adresse',
            MES_TYPE: 'Messart',
            CUSTOMER: 'Kunde',
            SEGMENT: 'Segment',
            ACTION: 'Aktionen',
          },
          actions: {
            EDIT: 'Bearbeiten',
            DEACTIVATE: 'Messgerät deaktivieren',
            ACTIVATE: 'Aktivieren Sie das Messgerät',
            ASSIGN_CUSTOMER: 'Kunden zuordnen',
            UNASSIGN_CUSTOMER: 'Den Kunden abmelden'
          },
          noData: 'Keine Daten gefunden',
          addNewButton: 'Neue hinzufügen',
          filtersLabel: 'Filter',
          id: 'ID',
          denotation: 'Bezeichnung',
          assign: 'Kunde dem Zähler zuordnen',
          meter: 'Zähler',
          meterIs: 'Zähler ID ist',
          meterMustBe: 'Zähler ID muss sein',
          unique: 'einzigartig',
          meterType: 'Zählertyp',
          address: 'Adresse',
          timeGroup: 'Zeit Gruppe',
          billed: 'Wird der Zähler in Rechnung gestellt?',
          avtive: 'Ist der Zähler aktiv?',
          maxRead: 'Max gelesen',
          must: 'muss',
          number: 'eine Nummer sein',
          master: 'Master Zähler ID',
          invalid: 'Master Zähler ID muss sich auf den vorhandenen Zähler ID beziehen',
          addCustomer: 'Kunde hinzufügen?',
          existing: 'Vorhanden',
          customerId: 'Kunde ID',
          customer: 'Kunde',
          meterpoint: 'Zähler ID',
          both: 'beide',
          disable: 'Deaktivieren...',
          noCustomer: 'kein Kunde'
        }
      },
      temperatures: {
        header: 'Temperaturdaten importieren',
        timestamp: 'Datum',
        temperature: 'Temperatur [°C]'
      }
    },
  },
  {
    lang: 'PL',
    texts: {
      common: {
        uploadPrompt: { part1: 'Przeciągnij i upuść plik tutaj lub', part2: 'przeglądaj', part3: 'aby załadować dane.' },
        dropArea: 'Przeciągnij i upuść plik tutaj',
        succes: 'Importowanie danych zakończone sukcesem.',
        error: 'Importowanie danych zakończone błędem.',
        tooManyFiles: 'Jednocześnie można wysłać tylko jeden plik',
        reload: 'Zaimportuj ponownie.',
        emptyDate: 'Pole daty jest puste',
        emptyTemperature: 'Pole z temperaturą jest puste',
        wrongMeterId: 'Nie znaleziono w bazie licznika.',
        Unknown: 'Nieznana przyczyna błędu.',
        oversize: 'Za duży rozmiar pliku.',
        badExtension: 'Nieprawidłowe rozszerzenie pliku.',
        server: 'Serwer odrzucił plik.',
        uploadProgress: 'Postępy przesyłania',
        upload: 'Prześlij',
        chhoseFileToUpload: 'Wybierz plik do przesłania',
      },
      measurement: {
        /* Main component */
        componentTitle: 'Import danych odczytowych',
        modalAddMeasurementTitle: 'Dodaj odczyt',
        modalEditMeasurementTitle: 'Edytuj odczyt',
        modalViewMeasurementTitle: 'Detale odczytu',
        numberOfImportedMeasurements: 'Liczba zaimportowanych pomiarów:',
        numberOfMeasurementsWithoutReadingValue: 'Liczba pomiarów bez wartości odczytowej na zmiennej prymarnej:',
        uploadButton: { DONE: 'Ponów importowanie', ELSE: 'Importuj' },
        addNewButton: 'Dodaj nowy',
        addNewButtonTooltip: 'Proces walidacji danych w trakcie, brak możliwości dodania nowych danych odczytowych',
        editButtonTooltip: 'Proces walidacji danych w trakcie, brak możliwości edycji danych odczytowych',
        tableFiller: { MissingMeasurement: 'B/D' },
        filterLabels: { MANUAL: 'Manualny', FROM_FILE: 'Z pliku', EDITED: 'Edytowany' },
        tableHeaders: { NAME: 'Nazwa', SCHEDULE: 'Harmonogram', POSITION: 'Pozycja', UPLOAD_DATE: 'Data importu', ACTIONS: 'Akcje' },
        uploadCountersLabels: { TOTAL: 'Zaimportowano', INVALID: 'Błędne', FILES: 'Zaimportowane pliki' },
        detailButtonsLabels: { RELOAD: 'Ponów importowanie', PREVIEW: 'Podgląd danych', VALIDATION: 'Przejdź do walidacji', FILE: 'Podgląd plików' },
        reuploadNotSupported: 'Ponowny import danych nie jest wspierany w tej wersji',
        noData: 'Brak danych',
        measurements: 'Odczyty',
        measurement: 'Odczyt',
        listFile: 'Lista plików',
        filtres: 'Filtry:',
        date: 'Data',
        dateCreate: 'Data utworzenia',
        modificationDate: 'Data modyfikacji',
        status: 'Status',
        actions: 'Akcje',
        delete: 'Usuń',
        edit: 'Edytuj',
        view: 'Podgląd',
        checking: 'Sprawdzam...',
        serialMeter: 'ID Licznika',
        required: 'wymagany',
        enter: 'Prosze, wprowadź',
        operationalData: 'dane assetowe',
        chooseData: 'Wybierz datę',
        optionalValues: 'Wartości opcjonalne',
        optionalValue: 'Wartość opcjonalna',
        name: 'Nazwa',
        value: 'Wartość',
        cancel: 'Anuluj',
        save: 'Zapisz',
        NA: 'Brak danych',
        noUnit: 'Brak jednostki',
        energy: 'Energia',
        water: 'Objętość',
        all: 'Pokaż wszystkie',
        importType: 'Typ importu',
        user: 'Użytkownik',
        automatic: 'Automatyczny',
        activeScheduler: 'Automatyczny Harmonogram'
      },
      asset: {
        componentTitle: 'Import danych assetowych',
        /* NEW */
        assetFilesPreview: {
          tableHeaders: { NAME: 'Nazwa', UPLOAD_DATE: 'Data importu', ACTIONS: 'Akcje' },
          noData: 'Brak danych',
          numerator: 'Zaimportowano'
        },
        assetDataPreview: {
          header: 'Dane assetowe: ID Licznika',
          tableHeaders: {
            METER_ID: 'ID Licznika',
            LOCATION: 'Lokalizacja',
            MES_TYPE: 'Typ odczytu',
            CUSTOMER: 'Klient',
            SEGMENT: 'Segment',
            ACTION: 'Akcje',
          },
          actions: {
            EDIT: 'Edytuj',
            DEACTIVATE: 'Deaktywuj licznik',
            ACTIVATE: 'Aktywuj licznik',
            ASSIGN_CUSTOMER: 'Przypisz klienta',
            UNASSIGN_CUSTOMER: 'Usuń klienta'
          },
          noData: 'Brak danych',
          addNewButton: 'Dodaj nowy',
          filtersLabel: 'Filtry',
          id: 'ID',
          denotation: 'Oznaczenie',
          assign: 'Przypisz klienta do licznika',
          meter: 'Licznik',
          meterIs: 'ID Licznika',
          meterMustBe: 'ID Licznika musi być',
          unique: 'unikalny',
          meterType: 'Typ węzła',
          address: 'Adres',
          timeGroup: 'Grupa odczytowa',
          billed: 'Czy bilingowany?',
          avtive: 'Czy aktywny?',
          maxRead: 'Maksymalny odczyt',
          must: 'musi',
          number: 'być liczbą całkowitą',
          master: 'Licznik nadrzędny',
          invalid: 'Licznik nadrzędny musi odnosic się do istniejącego ID Licznika',
          addCustomer: 'Dodać kienta?',
          existing: 'istniejący',
          customerId: 'ID Klienta',
          customer: 'klient',
          meterpoint: 'ID Licznika',
          both: 'razem',
          disable: 'Zablokuj...',
          noCustomer: 'Brak klienta'
        }
      },
      temperatures: {
        header: 'Import danych temperaturowych',
        timestamp: 'Data',
        temperature: 'Temperatura [°C]'
      }
    },
  },
];


