import { Component, OnInit, Inject, AfterViewInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApplicationUnitsDataStoreService } from '@shared/services/dataStore/applicationUnitsDataStore.service';
import { CommonAppDataService } from '@shared/services/commonAppData.service';
import { UtilityTypeEnum } from '@shared/types';
import { MeasurementDataModel } from '@shared/models/appModels/measurementDataModel.model';
import { Observable } from 'rxjs/Observable';
import { FormControl, NgForm, FormBuilder, FormArray, } from '@angular/forms';
import { startWith, map, tap, debounceTime, switchMap } from 'rxjs/operators';
import { typesLocalisation } from '@shared/types/localisation';
import { environment } from '@env/environment';
import { MeasurementDefinitionMappingDataStoreService } from '@shared/services/dataStore/measurementDefinitionMappingDataStore.service';
import { MeasurementMapperService } from './measurementMapper.service';
import { Subscription } from 'rxjs/Subscription';
import { InteractiveTutorialService } from '@shared/services/interactiveTutorialService.service';
import { MeasurementsUploadInterativeTutorialService } from '../measurementsUploadInteractiveTutorialService.service';
import { of, Subject } from 'rxjs';

@Component({
    selector: 'sv-measurements-mapper',
    templateUrl: './measurements-mapper.component.html',
    styleUrls: ['./measurements-mapper.component.sass'],
})

export class MeasurementMapperComponent implements OnInit, AfterViewInit, OnDestroy {

    colNames: string[] = [];
    colNamesFiltered: string[] = [];
    existingMappings = [];
    measDefs: Observable<MeasurementDataModel[]>;
    utilityType: UtilityTypeEnum;
    measurementsDefinitions: MeasurementDataModel[];
    primaryMeasurementsDefinitions: MeasurementDataModel[];
    myControl = new FormControl();
    filteredOptions: Observable<string[]>;
    filteredOptionsSubscription: Subscription;
    disableMappingButtonsArray: HTMLButtonElement[] = [];
    showBtn = false;
    disableMapButton = true;
    disableAnimation = true;
    mappingForm: FormArray;
    mappingError = false;
    mappingErrorNull = false;
    mappingErrorCheckExistenceOfVariable = false;
    addIcon = 'add';
    infoIcon = 'info';
    existingMappingsToolsipsSubscription: Subscription;
    mappingFormSubscription: Subscription;
    readonly dataModelTxt = typesLocalisation.MeasurementDefinitionMapper[environment.language].texts;
    readonly noMappingTxt = 'NO_MAPPING';

    tabOpened: Subject<boolean> = new Subject();
    tabOpenedSubscription: Subscription;
    interactiveTutFunc;


    constructor(@Inject(MAT_DIALOG_DATA) private data: { csvFileTxt: string },
        private appUnitsDataService: ApplicationUnitsDataStoreService,
        private cs: CommonAppDataService,
        private dialog: MatDialog,
        private fb: FormBuilder,
        private snackBar: MatSnackBar,
        private cdr: ChangeDetectorRef,
        public measurementMapperService: MeasurementMapperService,
        private measurmentDefinitionMappingDataStoreService: MeasurementDefinitionMappingDataStoreService,
        private dialogRef: MatDialogRef<MeasurementMapperComponent>,
        private interactiveTutorialService: InteractiveTutorialService,
        private measUplInterTutServ: MeasurementsUploadInterativeTutorialService) { }

    ngOnInit(): void {
        this.utilityType = this.cs.getCurrentMediaType();
        this.measDefs = this.appUnitsDataService.getMeasurementDefinitions().pipe(tap(
            (data) => {
                data.sort((a, b) => {
                    if (a.id > b.id) {
                        return 1;
                    }
                    if (b.id > a.id) {
                        return -1;
                    }
                    return 0;
                });
            }));
        this.readCsvFile(this.data.csvFileTxt);
        if (this.colNames.length !== 0) {
            this.mappingForm = this.fb.array([]);
            this.existingMappingsToolsipsSubscription = this.measurementMapperService.exsistingMappingsTooltipsSubject.subscribe((data) => {
                this.myControl.updateValueAndValidity();
            });
        }
        this.tabOpenedSubscription = this.tabOpened.pipe(debounceTime(1500),switchMap(v =>of(v))).subscribe(v => {
            if(v) {
            this.startInteractiveTutorialPart2();
            }
        });
    }

    startInteractiveTutorial(){
        const steps = this.measUplInterTutServ.getMeasurementsUploadMapperInteractiveTutorialSteps();
        this.interactiveTutorialService.startInteractiveTutorial(steps);
    }

    startInteractiveTutorialPart2(){
        const steps = this.measUplInterTutServ.getMeasurementUploadMapperPrimaryModelTabInteractiveTutorialSteps();
        this.interactiveTutorialService.startInteractiveTutorial(steps);
    }

    ngOnDestroy() {
        this.measurementMapperService.columnNamesFromFile = [];
        this.measurementMapperService.existingMappings = [];
        this.measurementMapperService.exsistingMappingsTooltips = [];
        this.measurementMapperService.isExpansionPanelDisbaled = true;
        if (this.existingMappingsToolsipsSubscription) {
            this.existingMappingsToolsipsSubscription.unsubscribe();
        }
        if (this.mappingFormSubscription) {
            this.mappingFormSubscription.unsubscribe();
        }
        if (this.filteredOptionsSubscription) {
            this.filteredOptionsSubscription.unsubscribe()
        }
        this.measurementMapperService.mappingFormSubject.next(false);
        if(this.tabOpenedSubscription){
            this.tabOpenedSubscription.unsubscribe();
        }
        clearTimeout(this.interactiveTutFunc);
    }

    getMeasurementDefinitionData(): Observable<MeasurementDataModel[]> {
        this.utilityType = this.cs.getCurrentMediaType();
        return this.appUnitsDataService.getMeasurementDefinitions()
            .pipe(tap(
                (data) => {
                    data.sort((ElemA, ElemB) => {
                        if (ElemA.id > ElemB.id) {
                            return 1;
                        }
                        if (ElemB.id > ElemA.id) {
                            return -1;
                        }
                        return 0;
                    });
                    this.measurementsDefinitions = data;
                    this.measurementMapperService.columnNamesFromFile = this.colNames;
                    for (let i = 0; i < this.measurementsDefinitions.length; i++) {
                        if (this.measurementsDefinitions[i].parsedName !== '' && this.measurementsDefinitions[i].parsedName !== undefined &&
                            this.measurementsDefinitions[i].parsedName !== null) {
                            this.existingMappings.push(this.measurementsDefinitions[i]);
                        }
                    }
                    this.measurementMapperService.existingMappings = this.existingMappings;
                    this.primaryMeasurementsDefinitions = this.measurementsDefinitions.filter(elem => elem.isPrimary || elem.isMetadata);
                    for (let k = 0; k < this.existingMappings.length; k++) {
                        this.measurementMapperService.exsistingMappingsTooltips.push(false);
                    }
                    this.mappingFormSubscription = this.measurementMapperService.mappingFormSubject.subscribe((mappingFormExist) => {
                        if (mappingFormExist) {
                            this.checkIfVariablesFromFileAreInOurModel();
                            this.disableDeleteMappingButtons();
                            this.filteredOptions = this.myControl.valueChanges
                                .pipe(
                                    startWith(''),
                                    map(value => this._filter(value)),
                                    tap(val => {
                                        this.mappingForm.markAsDirty();
                                        if (val !== undefined) {
                                            if (val.length < 1) {
                                                this.showBtn = true;
                                            } else {
                                                this.showBtn = false;
                                            }
                                        } else {
                                            this.showBtn = true;
                                        }
                                    })
                                );
                                this.filteredOptionsSubscription = this.filteredOptions.subscribe((filter) => {});
                        }
                    });
                }),
            );
    }

    ngAfterViewInit(): void {
        if (this.colNames.length === 0) {
            setTimeout(() => {
                const snackBarRef = this.snackBar.open(this.dataModelTxt.WRONG_FILE_STRUCTURE);
                snackBarRef._dismissAfter(3000);
                this.dialogRef.close();
            }, 0);
        }
        this.getMeasurementDefinitionData().subscribe((data) => {
            this.cdr.detectChanges();
            this.appUnitsDataService.getMeasurementDefinitions().subscribe((data) => {
                for (let i = 0; i < data.length; i++) {
                    this.mappingForm.push(new FormControl(<HTMLInputElement>document.getElementById(`myControl${i}`)));
                    this.mappingForm.get(`${i}`).setValidators([this.checkIfVariableFromInputExistInOurModel.bind(this),
                    this.checkIfPrimaryValueIsNullOrEmpty.bind(this), this.chceckIfMappingNameIsOccupied.bind(this)]);
                    this.disableMappingButtonsArray.push(<HTMLButtonElement>document.getElementById(`myDeleteMappingBtn${i}`));
                }
                this.measurementMapperService.mappingFormSubject.next(true);
                this.interactiveTutFunc = setTimeout(() => this.startInteractiveTutorial(),1500);
            });
            setTimeout(() => this.disableAnimation = false);
        },
        (e) => console.log(e),
        () => console.log('Fetching Measurement Definition Complete!'));
    }

    openPrimaryModelExpansionPanel(e){
        this.tabOpened.next(true);
    }

    closePrimaryModelExpansionPanel(e){
        this.tabOpened.next(false);
    }

    private _filter(value: string): string[] {
        if (value === null) {
            value = '';
        }
        if (typeof (value) === 'string') {
            const filterValue = value.toLowerCase();
            this.colNamesFiltered = Object.assign([], this.colNames);
            for (let i = 0; i < this.colNames.length; i++) {
                for (let j = 0; j < this.colNamesFiltered.length; j++) {
                    if (this.mappingForm.get(`${i}`) !== null) {
                        if (this.mappingForm.get(`${i}`).value !== null) {
                            if (this.mappingForm.get(`${i}`).value.value === this.colNamesFiltered[j]) {
                                this.colNamesFiltered.splice(j, 1);
                            }
                        }
                    }
                }
            }
            return this.colNamesFiltered.filter(val => val.toLowerCase().includes(filterValue));
        } else {
            return this.colNames;
        }
    }

    readCsvFile(csvData: string) {
        let columnsNames: string[];
        let csvElements: string[];
        csvData = csvData.replace(/ /g, '');
        csvElements = csvData.split(/\r\n|\n/);
        columnsNames = this.readColumnsNames(csvElements);
        this.colNames = columnsNames;
    }

    readColumnsNames(csvData: string[]) {
        const headers = (csvData[0]).split(';');
        let columnsNamesArray = [];
        for (let j = 0; j < headers.length; j++) {
            headers[j] = headers[j].replace(/ /g, '');
            columnsNamesArray.push(headers[j].toLocaleUpperCase());
        }
        return columnsNamesArray;
    }

    checkIfVariablesFromFileAreInOurModel() {

        if (this.mappingForm) {
            for (let i = 0; i < this.mappingForm.length; i++) {
                if (this.mappingForm.get(`${i}`).value !== null) {
                const name = this.mappingForm.get(`${i}`).value.name;
                const measDef = this.measurementsDefinitions.find(elem => elem.name === name);
                this.mappingForm.get(`${i}`).value.value = measDef.parsedName;
                }
            }
        }
    }

    createMappingMap() {
        let measurementMapping: MeasurementDataModel[] = [];
        measurementMapping = this.measurementsDefinitions;
         for (let i = 0; i < this.mappingForm.length; i++) {
            if (this.mappingForm.get(`${i}`).value.value === this.noMappingTxt) {
                const currentMapping = measurementMapping.find((elem) => elem.name === this.mappingForm.get(`${i}`).value.name);
                measurementMapping[measurementMapping.indexOf(currentMapping)].parsedName = '';
            } else {
                const currentMapping = measurementMapping.find((elem) => elem.name === this.mappingForm.get(`${i}`).value.name);
                measurementMapping[measurementMapping.indexOf(currentMapping)].parsedName = this.mappingForm.get(`${i}`).value.value;
            }
        }
        return measurementMapping;
    }

    onValidate() {
        this.mappingError = false;
        this.mappingErrorNull = false;
        this.mappingErrorCheckExistenceOfVariable = false;
        for (let i = 0; i < this.mappingForm.length; i++) {
            this.mappingForm.get(`${i}`).updateValueAndValidity();
        }
        for (let j = 0; j < this.mappingForm.length; j++) {
            const inputValue = this.mappingForm.get(`${j}`).value.value;
            const inputName = this.mappingForm.get(`${j}`).value.name;
            const measDef = this.measurementsDefinitions.find((el) => el.name === inputName);
            if ( (measDef.parsedName !== inputValue) && (inputValue !== '') && (measDef.parsedName !== '') ) {
                const snackBarRef = this.snackBar.open(this.dataModelTxt.CHANGED_EXISTING_MAPPINGS);
                snackBarRef._dismissAfter(4000);
                break;
            }
        }
        if (this.mappingForm.invalid) {
            this.disableMapButton = true;
            this.mappingForm.markAsDirty();
        } else {
            this.mappingError = false;
            this.mappingErrorNull = false;
            this.mappingErrorCheckExistenceOfVariable = false;
            this.disableMapButton = false;
            this.mappingForm.markAsPristine();
        }
    }

    onSubmit(form: NgForm) {
        const mappingDefinition = this.createMappingMap();
        this.measurmentDefinitionMappingDataStoreService.putMeasurementDataModel(mappingDefinition).subscribe(data => {
            this.dialog.closeAll();
        },
            error => {
                console.log('Błąd', error);
                this.dialog.closeAll();
            });
    }

    onDelete(index: number) {
        const valToDelete = this.measurementsDefinitions.find((el) => el.name === this.mappingForm.get(`${index}`).value.name);
        if (typeof valToDelete !== 'undefined') {
            valToDelete.parsedName = '';
        }
        const elementsToDelete: MeasurementDataModel[] = [];
        elementsToDelete.push(valToDelete);
        this.measurmentDefinitionMappingDataStoreService.putMeasurementDataModel(elementsToDelete)
            .subscribe(resp => {
                this.mappingForm.get(`${index}`).value.value = this.noMappingTxt;
                this.mappingForm.get(`${index}`).value.disabled = true;
                this.decideIfWeCanDeleteMapping(<FormControl>this.mappingForm.get(`${index}`), this.disableMappingButtonsArray[index]);
            });
    }

    chceckIfMappingNameIsOccupied(element: FormControl): { [s: string]: boolean } {
        for (let i = 0; i < this.mappingForm.length; i++) {

            const elem = this.mappingForm.get(`${i}`);
            if (elem !== null) {
                if ((element.value.id !== elem.value.id) && (element.value.value !== '' && elem.value.value !== '')
                    && (element.value.value !== this.noMappingTxt && elem.value.value !== this.noMappingTxt)) {
                    if ((element.value.value === elem.value.value)) {
                        this.mappingError = true;
                        return { mappingError: true };
                    }
                }
            }
        }
    }

    decideIfWeCanDeleteMapping(element: FormControl, button: HTMLButtonElement) {
        if (element.value !== null) {
            const measDef = this.primaryMeasurementsDefinitions.find((elem) => element.value.name === elem.name);
            if (typeof (measDef) !== 'undefined') {
                if (element.value.name === measDef.name && (measDef.isPrimary || measDef.isMetadata)) {
                    button.disabled = true;
                }
            } else if ( element.value.value === '' || element.value.value === this.noMappingTxt) {
                button.disabled = true;
            }
        }
    }

    disableDeleteMappingButtons() {
        for (let i = 0; i < this.mappingForm.length; i++) {
            if (this.mappingForm.get(`${i}`).value !== null) {
            this.decideIfWeCanDeleteMapping(<FormControl>this.mappingForm.get(`${i}`), this.disableMappingButtonsArray[i]);
            }
        }
    }

    checkIfPrimaryValueIsNullOrEmpty(element: FormControl): { [s: string]: boolean } {
        if (element !== null) {
            const measDef = this.primaryMeasurementsDefinitions.find((elem) => element.value.name === elem.name);
            if (typeof (measDef) !== 'undefined') {
                if (element.value.name === measDef.name && (element.value.value === null ||
                    element.value.value === '' || element.value.value === this.noMappingTxt)) {
                    this.mappingErrorNull = true;
                    return { valueIsNullOrEmpty: true };
                }
            }
        }
    }

    checkIfVariableFromInputExistInOurModel(element: FormControl): { [s: string]: boolean } {
        let elementFound = this.colNames.find(el => {
            return el === element.value.value;
        });
        if (typeof (elementFound) === 'undefined') {
            let measDef;
            measDef = this.measurementsDefinitions.find(el => {
                return el.parsedName === element.value.value;
            });
            if (typeof (measDef) !== 'undefined') {
                elementFound = measDef.parsedName;
            }
        }
        if (elementFound === undefined && element.value.value !== this.noMappingTxt && element.value.value !== '') {
            this.mappingErrorCheckExistenceOfVariable = true;
            return { lackOfVariableInOurModel: true };
        }
    }
}
