<h2>{{labels.measurements}}</h2>

<div class="card table-filter">
	<div class="card-header" [formGroup]="filterFormGroup">
		<div>
			<b>{{labels.filtres}}</b>
			<mat-form-field id="filter" class="form-group">
				<input matInput type="text" placeholder="{{meterIdLabel}}" formControlName="meter">
			</mat-form-field>
			<mat-radio-group id="uploadMethod" #radioSelect formControlName="options">
				<mat-radio-button *ngFor="let val of radioValues" [value]="val.value">{{val.label}}</mat-radio-button>
			</mat-radio-group>
		</div>
		<div [matTooltipDisabled]="!addingDisabled" [matTooltip]="addNewButtonTooltip" matTooltipPosition="below"
			matTooltipShowDelay="500">
			<button id="addMeasurement" mat-raised-button color="primary" class="btn" (click)="addElement()" [disabled]="addingDisabled">
				{{ addNewButtonTxt }}
			</button>
		</div>
	</div>

	<mat-table id="measurementsTable" #table [dataSource]="dataSource">

		<ng-container matColumnDef="name">
			<mat-header-cell *matHeaderCellDef>{{meterIdLabel}}</mat-header-cell>
			<mat-cell *matCellDef="let element">
				{{element?._serial}}
				<span *ngIf="element.getMeasurementOrigin().id !== MeasurementOriginEnum.FROM_FILE" class="badge"
					[ngClass]="element.getMeasurementOrigin().classType">{{element.getMeasurementOrigin().name}}</span>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="timestamp">
			<mat-header-cell *matHeaderCellDef>{{labels.date}}</mat-header-cell>
			<mat-cell *matCellDef="let element">{{ element.timestamp | svDateTime }}</mat-cell>
		</ng-container>

		<ng-container matColumnDef="selectedStatusFlag">
			<mat-header-cell *matHeaderCellDef>{{labels.status}}</mat-header-cell>
			<mat-cell *matCellDef="let element">
				<span class="badge"
					[ngClass]="element.getStatusFlag().classType">{{element.getStatusFlag().name}}</span>
			</mat-cell>
		</ng-container>

		<ng-container *ngFor="let column of columnsHandler.getDynamicColumns(); let i = index"
			[matColumnDef]="column.columnDef">
			<mat-header-cell *matHeaderCellDef>{{ column.header }}</mat-header-cell>

			<mat-cell *matCellDef="let element">

				<div *ngIf="column.type === 'basic'">
					{{ (element.getMeasurementDataSingle(column.valueKey) | number:'1.0-2') || missingValue}}
				</div>

				<div *ngIf="column.type === 'span'">
					<span class="flag"
						[ngClass]="column.classType(element)">{{column.generateCellContent(element)}}</span>
				</div>

				<div *ngIf="column.type === 'date'">
					<span
						[ngClass]="column.classType(element)">{{column.generateCellContent(element) | svDateTime }}</span>
				</div>

				<div *ngIf="column.type === 'form'">
					<mat-form-field (click)="$event.stopPropagation();">
						<input matInput name="value" id="value" value="{{column.generateCellContent(element)}}">
						<button matSuffix mat-button color="primary" class="btn">Save</button>
					</mat-form-field>
				</div>

			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="action">
			<mat-header-cell *matHeaderCellDef class="text-right">{{labels.actions}}</mat-header-cell>
			<mat-cell *matCellDef="let element" class="text-right">
				<button mat-icon-button [matMenuTriggerFor]="menu">
					<i class="material-icons notranslate">{{menuMdIcon}}</i>
				</button>

				<mat-menu #menu="matMenu" class="menu-box">
					<button mat-menu-item (click)="viewElement(element)">
						<i class="material-icons notranslate">{{viewMdIcon}}</i>
						<span>{{labels.view}}</span>
					</button>
					<div [matTooltipDisabled]="!addingDisabled" [matTooltip]="editButtonTooltip"
						matTooltipPosition="below" matTooltipShowDelay="500">
						<button mat-menu-item [disabled]="addingDisabled" (click)="editElement(element)">
							<i class="material-icons notranslate">{{configureMdIcon}}</i>
							<span>{{labels.edit}}</span>
						</button>
					</div>

					<button *ngIf="element.getMeasurementOrigin().id !== MeasurementOriginEnum.FROM_FILE" mat-menu-item
						(click)="deleteElement(element)">
						<i class="material-icons notranslate">{{deleteMdIcon}}</i>
						<span>{{labels.delete}}</span>
					</button>
				</mat-menu>
			</mat-cell>
		</ng-container>

		<mat-header-row *matHeaderRowDef="columnsHandler.getAllColumnDef()"></mat-header-row>
		<mat-row *matRowDef="let row; columns: columnsHandler.getAllColumnDef();"></mat-row>

	</mat-table>

	<div class="no-result" *ngIf="(getDataLength() === 0 && dataFetched)">
		{{labels.noData}}
	</div>

	<div class="no-result" *ngIf="!dataFetched">
		<mat-spinner [strokeWidth]="3" [diameter]="20" class="notranslate"></mat-spinner>
	</div>

	<mat-paginator #paginator [length]="getDataLength()" [pageIndex]="0" [pageSize]="10"
		[pageSizeOptions]="[5, 10, 20]" showFirstLastButtons class="notranslate">
	</mat-paginator>
</div>