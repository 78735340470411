<div class="custom-container">
  <h3 i18n="@@export/choose columns">Choose columns:</h3>
  <br />
  <sv-input-with-autocomplete-and-chips *ngIf="initComponent" [wrongElement]="wrongColumn"
    (selectedDataEventEmitter)="selectedDataEvent($event)" [inputPlaceholder]="addColumnsPlaceholder"
    [allData]="allBillingColumns" [elementRequired]="columnsRequired"></sv-input-with-autocomplete-and-chips>
  <br />
  <mat-checkbox [(ngModel)]="displayHeadersInCsv" i18n="@@export/include headers in csv">Include headers in csv file</mat-checkbox>
  <div class="custom-btn">
    <button [disabled]="selectedBillingColumns.length===0" (click)="onNextStep()" mat-raised-button color="accent"
      class="btn btn-action" i18n="@@export/custom billings modal/next step">
      Next step
    </button>
  </div>
</div>