import { BaseOptionalParamsMapper } from '@shared/models/baseOptionalParams.mode';
import { IGetInterfaceType } from '@shared/types';

export class IForecasting {
    idMeterPoint: number;
    analysedPositionId: number;
    timestamp: number;
    energy?: number;
    serialNumber?: string;
    profileType?: string;
    period?: number;
    intervalMin?: number;
    intervalMax?: number;

}

export class Forecasting extends BaseOptionalParamsMapper implements IForecasting, IGetInterfaceType<IForecasting, Forecasting>  {
    idMeterPoint: number;
    analysedPositionId: number;
    timestamp: number;
    energy?: number;
    serialNumber?: string;
    profileType?: string;
    period?: number;
    intervalMin?: number;
    intervalMax?: number;


    constructor(iForecasting: IForecasting) {
        super();
        if (!iForecasting) { throw new TypeError('Analysis initialization: params is undefined !'); }
        this.idMeterPoint = (typeof (iForecasting.idMeterPoint) !== 'undefined') ? iForecasting.idMeterPoint : undefined;
        this.analysedPositionId = (typeof (iForecasting.analysedPositionId) !== 'undefined') ? iForecasting.analysedPositionId : undefined;
        this.timestamp = (typeof (iForecasting.timestamp) !== 'undefined') ? iForecasting.timestamp : undefined;
        this.energy = (typeof (iForecasting.energy) !== 'undefined') ? iForecasting.energy : undefined;
        this.serialNumber = (typeof (iForecasting.serialNumber) !== 'undefined') ? iForecasting.serialNumber : undefined;
        this.profileType = (typeof (iForecasting.profileType) !== 'undefined') ? iForecasting.profileType : undefined;
        this.period = (typeof (iForecasting.period) !== 'undefined') ? iForecasting.period : undefined;
        this.intervalMin = (typeof (iForecasting.intervalMin) !== 'undefined') ? iForecasting.intervalMin : undefined;
        this.intervalMax = (typeof (iForecasting.intervalMax) !== 'undefined') ? iForecasting.intervalMax : undefined;
    }

    getInterface(webInterface: boolean = true): IForecasting {
        return {
            idMeterPoint: this.idMeterPoint,
            analysedPositionId: this.analysedPositionId,
            timestamp: this.timestamp,
            energy: this.energy,
            serialNumber: this.serialNumber,
            profileType: this.profileType,
            period: this.period,
            intervalMin: this.intervalMin,
            intervalMax: this.intervalMax
        };
    }
}
