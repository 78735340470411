import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { MeasurementDataModel } from '@shared/models/appModels/measurementDataModel.model';
import { BehaviorSubject } from 'rxjs';

@Injectable()

export class MeasurementMapperService {
    columnNamesFromFile: string[] = [];
    existingMappings: MeasurementDataModel[] = [];
    exsistingMappingsTooltips: boolean[] = [];
    exsistingMappingsTooltipsSubject = new Subject();
    mappingFormSubject = new BehaviorSubject(false);
    isExpansionPanelDisbaled = true;
}
