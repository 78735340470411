
import { INameMapper } from '@shared/types/applicationTypes';
import { environment as env } from '@env/environment';
import { typesLocalisation as loc } from '@shared/types/localisation';

export enum PositionTransitions {
  'UPLOAD_FINISH'     = 0,
  'VALIDATION_START'  = 1,
  'VALIDATION_FINISH' = 3,
  'VALIDATION_FAILED' = 4,
  'VALIDATION_FREEZED' = 5,
  'EXPORT_DONE' = 6,
  'ANALYSIS_START'  = 7,
  'ANALYSIS_FINISH' = 8,
  'ANALYSIS_FAILED' = 9,
}

export const PositionProcessTransition: INameMapper[] = [
  {
    id: PositionTransitions.UPLOAD_FINISH,
    name: loc.PositionProcessTransition[env.language].PositionTransitions.UPLOAD_FINISH,
    classType: '',
    description: '',
    icon: 'string'
  },
  {
    id: PositionTransitions.VALIDATION_START,
    name: loc.PositionProcessTransition[env.language].PositionTransitions.VALIDATION_START,
    classType: '',
    description: '',
    icon: 'string'
  },
  {
    id: PositionTransitions.VALIDATION_FINISH,
    name: loc.PositionProcessTransition[env.language].PositionTransitions.VALIDATION_FINISH,
    classType: '',
    description: '',
    icon: 'string'
  },
  {
    id: PositionTransitions.VALIDATION_FAILED,
    name: loc.PositionProcessTransition[env.language].PositionTransitions.VALIDATION_FAILED,
    classType: '',
    description: '',
    icon: 'string'
  },
  {
    id: PositionTransitions.VALIDATION_FREEZED,
    name: loc.PositionProcessTransition[env.language].PositionTransitions.VALIDATION_FREEZED,
    classType: '',
    description: '',
    icon: 'string'
  },
  {
    id: PositionTransitions.ANALYSIS_START,
    name: loc.PositionProcessTransition[env.language].PositionTransitions.VALIDATION_START,
    classType: '',
    description: '',
    icon: 'string'
  },
  {
    id: PositionTransitions.ANALYSIS_FINISH,
    name: loc.PositionProcessTransition[env.language].PositionTransitions.VALIDATION_FINISH,
    classType: '',
    description: '',
    icon: 'string'
  },
  {
    id: PositionTransitions.ANALYSIS_FAILED,
    name: loc.PositionProcessTransition[env.language].PositionTransitions.VALIDATION_FAILED,
    classType: '',
    description: '',
    icon: 'string'
  },
];
