import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  EventEmitter,
  Output,
  ChangeDetectorRef,
} from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MeterPoint } from "@shared/models";
import { MatChipInputEvent } from "@angular/material/chips";
import {
  MatAutocompleteSelectedEvent,
  MatAutocomplete,
  MatAutocompleteTrigger,
} from "@angular/material/autocomplete";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

export interface ObjectWrapper {
  valueForDisplay: string;
  otherValues: any;
}

@Component({
  // tslint:disable-next-line: component-selector
  selector: "sv-input-with-autocomplete-and-chips",
  templateUrl: "input-with-autoComp-and-chips.component.html",
  styleUrls: ["input-with-autoComp-and-chips.component.sass"],
})
export class InputWithAutoCompAndChipsComponent implements OnInit {
  selectedDataForm: FormGroup;
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  inputCtrl = new FormControl(null, [Validators.required]);
  dataFromInput: ObjectWrapper[] = [];
  @Input() inputPlaceholder: string;
  @Input() wrongElement: string;
  @Input() elementRequired: string;
  @Input() fetchingData: boolean;

  @ViewChild("Input") dataInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto") matAutocomplete: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
  @ViewChild("chipList") chipList;
  @Output() selectedDataEventEmitter = new EventEmitter<MeterPoint[]>();
  @Output() typingEventEmitter = new EventEmitter<string>();
  // @Input value with getter and setter defined to perform additional actions when value is changed
  private _allData: ObjectWrapper[];
  @Input() set allData(value: ObjectWrapper[]) {
    this._allData = value;
    this.allDataCopy = this._allData.slice(0);
    this.inputCtrl.updateValueAndValidity();
  }
  get allData(): ObjectWrapper[] {
    return this._allData;
  }
  private _disabledInput: boolean;
  @Input() set disabledInput(value: boolean) {
    this._disabledInput = value;
    if (this.disabledInput) {
      this.allDataCopy = this._allData?.slice(0);
      this.additionalActionsOnDisablingInput();
      this.selectedDataForm?.clearValidators();
      this.selectedDataForm?.updateValueAndValidity();
      this.selectedDataForm?.controls["selectedData"].disable();
    } else {
      this.additionalActionsOnDisablingInput();
      this.inputCtrl.setValue(null);
      this.selectedDataForm?.controls["selectedData"].enable();
    }
  }

  get disabledInput(): boolean {
    return this._disabledInput;
  }
  allDataCopy: any;
  notValidValue: boolean = false;

  constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.selectedDataForm = this.fb.group({
      selectedData: this.inputCtrl,
    });
    this.allDataCopy = this._allData.slice(0);
    this.inputCtrl.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((val) => {
        // Send typed value to parent component
        this.typingEventEmitter.emit(val);
      });
  }

  additionalActionsOnDisablingInput() {
    this.dataFromInput = [];
    if (this.dataInput) {
      this.dataInput.nativeElement.value = "";
    }
    if (this._allData !== undefined) {
      this.pickData();
    }
  }

  pickData() {
    let data: any[] = [];
    this.allData.forEach((el) => {
      const index = this.dataFromInput.findIndex(
        (e) => e.valueForDisplay === el.valueForDisplay
      );
      if (index >= 0) {
        this.dataFromInput[index].otherValues = el.otherValues;
      }
    });
    data = this.dataFromInput.map((el) => el.otherValues);
    // Sending chosen data to parent component
    this.selectedDataEventEmitter.emit(data);
  }

  add(event: MatChipInputEvent): void {
    // Add value to pick list
    const input = event.input;
    const value = event.value;
    this.chipList.errorState = false;
    if ((value || "").trim()) {
      this.decideIfWeCanAddValueToPickList(value);
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }

    this.inputCtrl.setValue(null);
    this.autocomplete.closePanel();
  }

  clearInvalidState() {
    setTimeout(() => {
      this.chipList.errorState = false;
      this.notValidValue = false;
      this.selectedDataForm.updateValueAndValidity();
      this.cdr.detectChanges();
    }, 5000);
  }

  decideIfWeCanAddValueToPickList(value: string) {
    // If value is already in pick list or it doesnt exist dont add it to pick list
    if (
      this.allData.some((obj) => obj.valueForDisplay === value) &&
      !this.dataFromInput.some((obj) => obj.valueForDisplay === value)
    ) {
      this.dataFromInput.push({
        valueForDisplay: value.trim(),
        otherValues: null,
      });
      const index = this.allDataCopy.findIndex((el) => el === value);
      if (index !== -1) {
        this.allDataCopy.splice(index, 1);
      }
      this.pickData();
    } else {
      this.chipList.errorState = true;
      this.notValidValue = true;
      this.clearInvalidState();
    }
  }

  remove(element: ObjectWrapper): void {
    // Remove value from pick list
    const index = this.dataFromInput
      .map((e) => e.valueForDisplay)
      .indexOf(element.valueForDisplay);

    if (index >= 0) {
      this.dataFromInput.splice(index, 1);
      const data = this.allData.find(
        (obj) => obj.valueForDisplay === element.valueForDisplay
      );
      this.allDataCopy.push(data);
    }

    let error = false;

    for (let i = 0; i < this.dataFromInput.length; i++) {
      if (
        !this.allData.find((obj) => {
          return obj.valueForDisplay === this.dataFromInput[i].valueForDisplay;
        })
      ) {
        this.chipList.errorState = true;
        this.notValidValue = true;
        error = true;
        this.clearInvalidState();
      }
    }
    this.pickData();
    if (!error) {
      this.chipList.errorState = false;
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.chipList.errorState = false;
    this.decideIfWeCanAddValueToPickList(event.option.viewValue);
    this.dataInput.nativeElement.value = "";
    this.inputCtrl.setValue(null);
  }
}
