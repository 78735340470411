import { Injectable } from '@angular/core';

/* Application types & classes */
import { GraphDataSet, lineGraphConfig } from '@shared/types/graphData.interface';
import { IMeterData } from '@shared/models/appModels/meterData.model';
import { IAnalysis } from '@shared/models/appModels/analysis.model';
import { DatePipe } from 'app/utils/date.pipe';

@Injectable()
export class ChartService {

  private colArray: { colorName: string, redComponent: number, greenComponent: number, blueComponent: number }[] = [
    { colorName: 'svBlue', redComponent: 0, greenComponent: 199, blueComponent: 159 },
    { colorName: 'svOrange', redComponent: 252, greenComponent: 151, blueComponent: 31 },
    { colorName: 'svRed', redComponent: 255, greenComponent: 0, blueComponent: 0 },
    { colorName: 'svGreen', redComponent: 0, greenComponent: 255, blueComponent: 0 },
    { colorName: 'svBlue', redComponent: 0, greenComponent: 199, blueComponent: 159 },
  ];
  constructor(private datePipe: DatePipe) {

  }

  packDataSetsdataIn(dataIn: IMeterData[][], key: string = 'energy'): GraphDataSet[] {
    const graphSets: Array<GraphDataSet> = new Array<GraphDataSet>(dataIn.length);

    dataIn.forEach((data: IMeterData[], index: number) => {

      /* Concat data array to line array */
      const lined: number[] = [];
      data.forEach((singleValue: IMeterData, ) => {
        lined.push(+singleValue.usageValues[key]);
      });
      if (!graphSets[index]) {
        graphSets[index] = { data: [], label: '' };
      }
      graphSets[index].data = lined;
      graphSets[index].label = data[0].idMeterPoint.toString();
    });
    return graphSets.slice(0);
  }

  packDataAnalysisSetsdataIn(dataIn: IAnalysis[][], key: string = 'energy'): GraphDataSet[] {
    const graphSets: Array<GraphDataSet> = new Array<GraphDataSet>(dataIn.length);

    dataIn.forEach((data: IAnalysis[], index: number) => {

      /* Concat data array to line array */
      const lined: number[] = [];
      data.forEach((singleValue: IAnalysis, ) => {
        lined.push(+singleValue.energy);
      });
      if (!graphSets[index]) {
        graphSets[index] = { data: [], label: '' };
      }
      graphSets[index].data = lined;
      graphSets[index].label = data[0].idMeterPoint.toString();
    });
    return graphSets.slice(0);
  }
  xAxisLabels(dataIn: IMeterData[][]):  string[] {
    const dataLenghts: number[] = [];
    dataIn.forEach(singleData => {
      dataLenghts.push(singleData.length);
    });
    const maxVal = Math.max(...dataLenghts);
    const idx = dataLenghts.findIndex(val => val === maxVal);

    const labels: string[] = [];
    dataIn[idx].forEach((dataEntry: IMeterData) => {
      if (typeof dataEntry.timestamp === 'number') {
        const isoFormatted = new Date(+dataEntry.timestamp).toUTCString();
        labels.push(isoFormatted);
      } else if (typeof dataEntry.timestamp === 'object') {
        labels.push(this.datePipe.transform(dataEntry.timestamp));
      }
    });

    return labels;
  }

  /* Method to extract unique IDs from MeterData array */
  getIds(dataArray: IMeterData[]): number[] {
    const ids: number[] = [];
    dataArray.forEach((data: IMeterData) => {
      const findResult = ids.findIndex((value: number): boolean => {
        return value === data.idMeterPoint;
      });
      if (findResult === -1) {
        ids.push(data.idMeterPoint);
      }
    });
    return ids.slice(0);
  }

  orderData(dataArray: IMeterData[], ids: number[]): IMeterData[][] {
    const res: IMeterData[][] = new Array(ids.length);
    dataArray.forEach((data: IMeterData, idx: number) => {
      const insertIdx: number = ids.findIndex((val: number): boolean => {
        return val === data.idMeterPoint;
      });
      if (!res[insertIdx]) {
        res[insertIdx] = [];
      }
      res[insertIdx].push(data);
    });
    return res.slice(0);
  }

  generateStyles(graphSets: GraphDataSet[]): lineGraphConfig[] {
    const styleArray: lineGraphConfig[] = [];
    graphSets.forEach((singleSet, idx) => {
      // const r = this.getRandomInt(0, 255);
      // const g = this.getRandomInt(0, 255);
      // const b = this.getRandomInt(0, 255);
      const id = singleSet.label;

      /* Pick single deinfed color form color array */
      const colIdx = idx % this.colArray.length;

      styleArray.push({
        backgroundColor: this.getColorString(this.colArray[colIdx].colorName, 0.5), // `rgba(${r},${g},${b},0.5)`,
        borderColor: this.getColorString(this.colArray[colIdx].colorName, 1), // `rgba(${r},${g},${b},1)`,
        pointBackgroundColor: this.getColorString(this.colArray[colIdx].colorName, 0.5), // `rgba(${r},${g},${b},0.5)`,
        showLine: true,
        fill: false,
        label: id,
        cubicInterpolationMode: 'monotone',
        lineTension: 1,
        pointRadius: 2,
        spanGaps: false,
      });
    });
    return styleArray;
  }

  getColorString(colName: string, alpha: number): string {
    const colIdx = this.colArray.findIndex((val): boolean => {
      return val.colorName === colName;
    });
    let selColor;
    if (colIdx !== -1) {
      selColor = this.colArray[colIdx];
    } else {
      selColor = undefined;
    }

    if (selColor !== undefined) {
      return `rgba(${selColor.redComponent},${selColor.greenComponent},${selColor.blueComponent},${alpha})`;
    } else {
      return `rgbs(${this.getRandomInt(0, 255)},${this.getRandomInt(0, 255)},${this.getRandomInt(0, 255)},0.5)`;
    }
  }

  getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

}
