
import { GenericHttpParams, IApiEndpointConfig } from '@shared/types/http/httpType';
import { FileMeterData } from '@shared/models/appModels/fileMeasurementData.model';

export const fileMeterDataApiConf: IApiEndpointConfig = {
  rootApiPath: 'filemeterdata/',
  paths: [
    {/* 1 */
      url: '',
      swaggerId: 'getFilemeterdataList',
      pathParams: [],
      responseIsArray: true,
    },
    {/* 2 */
      url: '',
      swaggerId: 'postFilemeterdataList',
      pathParams: [],
      responseIsArray: false,
    },
    {/* 3 */
      url: 'id/{idFileOperationalData}',
      swaggerId: 'getFilemeterdataById',
      pathParams: [
        { name: 'idFileMeterData', pattern: '{idFileMeterData}' },
      ],
      responseIsArray: false,
    },
    {/* 4 */
      url: 'id/{idFileOperationalData}',
      swaggerId: 'deleteFilemeterdataById',
      pathParams: [
        { name: 'idFileMeterData', pattern: '{idFileMeterData}' },
      ],
      responseIsArray: false,
    },
    {
      url: 'count',
      swaggerId: 'getFilemeterdataListCount',
      pathParams: [],
      responseIsArray: false
    },
    {
      url: 'billing',
      swaggerId: 'getBillingFile',
      pathParams: [],
      responseIsArray: false
    }
  ]
};

export interface FileMeterDataQueryParameters {
  /* Required */
  [key: string]: string | string[] | number;
  limit?: number;
  offset?: number;
  /* Optional */
  idFileMeterData?: string[];
  idPosition?:      string[];
  svUtilityType?: string;
  sort?: string;
  from?: string;
  to?: string;

}

export interface FileMeterDataHttpParamG extends GenericHttpParams<FileMeterData> {
  queryParams: FileMeterDataQueryParameters;
  reportProgress?: boolean;
  path?: {
    idFileMeterData?: string;
  };
  headers?: {
    svIdPosition?: string;
  };
}
