import 'rxjs/add/observable/merge';
import { TableDataSource } from '@shared/models/tableDataSource.model';
import { MeterPoint, IMeterPoint } from '@shared/models/appModels/meterPoint.model';


export class MeterPointDataTabbed extends MeterPoint {
  constructor(iIn: IMeterPoint) {
    super(iIn);
    this.rowId = iIn.idMeterPoint;
  }

  detailRow: boolean;
  rowId: number;
}

export class MeterPointDataSource extends TableDataSource<MeterPoint, MeterPointDataTabbed> {

  private setParams(detailRow: boolean, meterPoint: MeterPoint, baseMeterPoint: MeterPoint) {
    const element: MeterPointDataTabbed = new MeterPointDataTabbed(meterPoint);
    element.detailRow = detailRow;
    element.rowId = baseMeterPoint.idMeterPoint;
    element.idMeterPoint = baseMeterPoint.idMeterPoint;
    element.serialNumber = baseMeterPoint.serialNumber;
    element.meterPlacement = baseMeterPoint.meterPlacement;
    element.measurementType = baseMeterPoint.measurementType;
    element.customer = baseMeterPoint.customer;
    return element;
  }

  protected renderData(data: MeterPoint[]): MeterPointDataTabbed[] {
    const rows = [];
    data.forEach((baseMeterPoint, idx) => {
      const topElement: MeterPointDataTabbed = this.setParams(false, baseMeterPoint, baseMeterPoint);
      const detailElement: MeterPointDataTabbed = this.setParams(true, topElement, baseMeterPoint);
      rows.push(topElement, detailElement);
    });
    return rows;
  }
}
