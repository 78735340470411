import { IApiEndpointConfig, GenericHttpParams } from '@shared/types/http/httpType';
import { MediaTypeUnit } from '@shared/models/appModels/MediaTypeUnit.model';

export const UnitTypeApiConf: IApiEndpointConfig = {
  rootApiPath: 'model/',
  paths: [
    {/* 1 */
      swaggerId: 'getMeasurementDataModel',
      url: 'measurementdata',
      pathParams: [],
      responseIsArray: true
    },
    {/* 2 */
      swaggerId: 'postMeasurementDataModel',
      url: 'measurementdata',
      pathParams: [],
      responseIsArray: false
    },
    {/* 3 */
      swaggerId: 'putMeasurementDataModelById',
      url: 'measurementdata/{id}',
      pathParams: [{
        name: 'id' , pattern: '{id}'
      }],
      responseIsArray: false
    },
      {/* 4 */
        swaggerId: 'putMeasurementDataModel',
        url: 'measurementdata',
        pathParams: [],
        responseIsArray: false},
  ]
};

export interface IUnitQueryParams {
  svUtilityType?: string;
}

export interface UnitHttpParamG extends GenericHttpParams<MediaTypeUnit> {
  queryParams?: IUnitQueryParams;
  path?: {
    id?: string;
  };
}
