
import { environment } from '@env/environment';

/* Single API configs */
import { ScheduleApiConf } from '@shared/types/http/scheduleHttpConfig';
import { PositionApiConf } from '@shared/types/http/positionHttpConfig';
import { fileOperationalDataApiConf } from '@shared/types/http/fileOperatinalDataHttpConfig';
import { fileMeterDataApiConf } from '@shared/types/http/fileMeterDataHttpConfig';
import { customerApiConf } from '@shared/types/http/customersHttpConfig';
import { MeterPointApiConf } from '@shared/types/http/meterPointHttpConfig';
import { MeasurementDataApiConf } from '@shared/types/http/measurementDataHttpConfig';
import { veeStatusApiConf } from '@shared/types/http/veeProcessHttpConfig';
import { veeRuleApiConf } from '@shared/types/http/veeRuleHttpConfig';
import { analysisAlgorithmApiConf } from '@shared/types/http/analysisAlgorithmHttpConfig';
import { MeterDataApiConf } from '@shared/types/http/meterDataHttpConfig';
import { UnitTypeApiConf } from '@shared/types/http/unitHttpConfig';
import { TimeGroupApiConfig } from '@shared/types/http/timeGroupHttpConfig';
import { ParametersApiConf } from '@shared/types/http/parameterHttpConfig';
import { analysisStatusApiConf } from '@shared/types/http/analysisProcessHttpConfig';
import { SensorDataApiConf } from '@shared/types/http/sensorDataHttpConfig';
import { fileTemperatureDataApiConf } from '@shared/types/http/fileTemperaturesDataHttpConfig';
import { automaticImportConfigurationForSchedulePositionApiConf } from './automaticImportConfigForSchedulePositionHttpConfig';

export interface IPathConfig {
  url: string;
  swaggerId: string;
  pathParams: { name: string, pattern: string }[];
  responseIsArray: boolean;
}

export interface IFileUpload {
  upfile: any;
}

/* Generic params parameter configuration for extensions */
export interface GenericHttpParams<T> {
  queryParams?: any;
  headers?: any;
  body?: T | any; /* Anny added due to file uploads path */
  path?: { [name: string]: string };
  config?: { strict?: boolean, mock?: any };
}

/* Wrapper for plain numeric REST response */
export interface IntegerResult {
  value: number;
}

/* Wrapper for plain boolean REST repsonses */
export interface BooleanResult {
  value: boolean;
}

export interface AnalysisResult {
  value: string;
}

export interface HttpError {
  errorClass: string;
  text: string;
}

export interface IApiEndpointConfig {
  rootApiPath: string;
  paths: IPathConfig[];
}

export interface IHttpConfig {
  httpBasePath: string;
  defaultLimit: number;
  endpoints: IApiEndpointConfig[];
}

export const httpConfig: IHttpConfig = {
  httpBasePath: environment.backendPath,
  defaultLimit: environment.defaultReqLimit,
  endpoints: [
    ScheduleApiConf,
    PositionApiConf,
    fileOperationalDataApiConf,
    fileMeterDataApiConf,
    customerApiConf,
    MeterPointApiConf,
    MeasurementDataApiConf,
    MeterDataApiConf,
    veeStatusApiConf,
    veeRuleApiConf,
    UnitTypeApiConf,
    TimeGroupApiConfig,
    ParametersApiConf,
    analysisAlgorithmApiConf,
    analysisStatusApiConf,
    SensorDataApiConf,
    fileTemperatureDataApiConf,
    automaticImportConfigurationForSchedulePositionApiConf,
  ]
};

