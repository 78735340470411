
import { IApiEndpointConfig, GenericHttpParams } from '@shared/types/http/httpType';
import { Customer } from '@shared/models/appModels/customer.model';

export const customerApiConf: IApiEndpointConfig = {
  rootApiPath: 'customers/',
  paths: [
    {/* 1 */
      swaggerId: 'getCustomersList',
      url: '',
      pathParams: [],
      responseIsArray: true,
    },
    {/* 2 */
      swaggerId: 'postCustomers',
      url: '',
      pathParams: [],
      responseIsArray: false,
    },
    {/* 3 */
      swaggerId: 'getCustomersById',
      url: 'id/{idCustomer}',
      pathParams: [
        { name: 'idCustomer', pattern: '{idCustomer}'}
      ],
      responseIsArray: false,
    },
    {/* 4 */
      swaggerId: 'putCustomersById',
      url: 'id/{idCustomer}',
      pathParams: [
        { name: 'idCustomer', pattern: '{idCustomer}'}
      ],
      responseIsArray: false,
    },
    {/* 5 */
      swaggerId: 'deleteCustomersById',
      url: 'id/{idCustomer}',
      pathParams: [
        { name: 'idCustomer', pattern: '{idCustomer}'}
      ],
      responseIsArray: false,
    },
    {/* 6 */
      swaggerId: 'putCustomerActivation',
      url: 'id/{idCustomer}/active',
      pathParams: [
        { name: 'idCustomer', pattern: '{idCustomer}'}
      ],
      responseIsArray: false
    },
    {/* 7 */
      swaggerId: 'getCustomerMeterPoints',
      url: 'id/{idCustomer}/meterpoints',
      pathParams: [
        {name: 'idCustomer', pattern: '{idCustomer}'}
      ],
      responseIsArray: true,
    },
    {/* 8 */
      swaggerId: 'getCustomersListCount',
      url: 'count',
      pathParams: [
        {name: 'idCustomer', pattern: '{idCustomer}'}
      ],
      responseIsArray: false,
    }
  ]
};

export interface CustomerHttpParamG extends GenericHttpParams<Customer> {
  queryParams?: {
    limit?: string;
    offset?: string;
    id?: string[];
    name?: string;
    segment?: string[];
    active?: string;
  };
  path?: {
    idCustomer?: string
  };
}
