import { Component, OnInit, ViewChild, Input, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

import { MeasurementDataPreviewDataSource } from './dataSource/readingsPreviewSource';
import { MeterPointDataStoreService } from '@shared/services/dataStore/meterPointDataStore.service';
import { PositionDataStoreService } from '@shared/services/dataStore/positionDataStore.service';
import { CommonAppDataService } from '@shared/services/commonAppData.service';

import { MeasurementsPreviewDialogsService } from './service/measurements-preview-dialogs.service';
import { MeterPoint } from '@shared/models';
import { UtilityTypeEnum, MeasurementDataHttpParamG } from '@shared/types';
import { MeasurementData } from '@shared/models/appModels/measurementData.model';
import { MeasurementOriginEnum } from '@shared/types';

import { DynamicColumnsHandler } from '@shared/models/viewModels/tableDynamicColumns';
import { TableHost, SortType } from '@shared/types/applicationTypes';
import { Observable } from 'rxjs/Observable';
import { environment } from '@env/environment';
import { measurementOriginNamesMap } from '@shared/types/modelTypes/measurementType';
import { MeasurementDataStoreService } from '@shared/services/dataStore/measurementDataDataStore.service';
import { ApplicationUnits } from '@shared/models/applicationUnits.model';
import { ApplicationUnitsDataStoreService } from '@shared/services/dataStore/applicationUnitsDataStore.service';

import { UploadLocalisation as loc } from '../../upload.localisation';
import { VeeStatusDataStoreService } from '@shared/services/dataStore/veeProcessDataStore.service';
import { typesLocalisation } from '@shared/types/localisation';
// RxJs 6
import { map, switchMap, debounceTime, concatMap, tap } from 'rxjs/operators';
import { of, merge, Subscription } from 'rxjs';
import { MeasurementsUploadInterativeTutorialService } from '../measurementsUploadInteractiveTutorialService.service';
import { InteractiveTutorialService } from '@shared/services/interactiveTutorialService.service';
import { VeeRule } from '@shared/models/appModels/VeeRule.model';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'sv-measurements-preview',
  templateUrl: './measurements-preview.component.html',
  styleUrls: ['./../../data-preview.sass'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReadingsPreviewComponent implements OnInit, AfterViewInit, OnDestroy, TableHost<MeasurementData> {

  addNewButtonTxt = loc[environment.language].texts.measurement.addNewButton;
  loadDataSubscription: Subscription;
  filterFormSubscription: Subscription;
  loadDataAfterViewInitSubscription: Subscription;
  paginatorEventsSubscription: Subscription;
  externalDataChangeSubscription: Subscription;
  filterEventsSubscription: Subscription;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @Input() set idPosition(pos: number) {
    /* Set main property value */
    this._idPos = pos;
    /* Re-trigger data fetch for each new data passed as parameter */
    if (this.dataFetched) {
      this.dataFetched = false;
      this.loadDataSubscription = this.loadData()
        .subscribe((data: MeasurementData[]) => {
          this.dataSource.setNewData(data);
          this.cd.markForCheck();
        },
        (err: any) => { if (this.debugMode) { console.error(err); } },
        () => {
          if (this.debugMode) { console.log(`non-ini data fetch Finished`); }
          this.dataFetched = true;
          this.cd.markForCheck()
        },
      );
    }
    this.veeStatusService.getVeeStateForPosition(this.idPosition, true)
      .subscribe(vs => {
        if (Object.keys(vs).length === 0) {
          this.addingDisabled = false;
        } else {
          this.addingDisabled = true;
        }
      });
  }
  @Input() set idFile(file: number) {
    this._idFile = file;
  }

  get idPosition(): number {
    return this._idPos;
  }
  get idFile(): number {
    return this._idFile;
  }
  /* Icons */
  readonly menuMdIcon: string = 'menu';
  readonly configureMdIcon: string = 'mode_edit';
  readonly viewMdIcon: string = 'visibility';
  readonly deleteMdIcon: string = 'close';
  /* Names */
  readonly missingValue = loc[environment.language].texts.measurement.tableFiller.MissingMeasurement;
  readonly addNewButtonTooltip = loc[environment.language].texts.measurement.addNewButtonTooltip;
  readonly editButtonTooltip = loc[environment.language].texts.measurement.editButtonTooltip;
  readonly labels = loc[environment.language].texts.measurement;
  readonly meterIdLabel = loc[environment.language].texts.asset.assetDataPreview.tableHeaders.METER_ID;
  readonly all = loc[environment.language].texts.measurement.all;

  public filterFormGroup: FormGroup;
  public columnsHandler: DynamicColumnsHandler<MeterPoint>;

  dataSource: MeasurementDataPreviewDataSource;

  /* Loading and data availability control */
  dataLength: number = 0;
  dataLenghtPaginator: number = 0;
  dataFetched: boolean = false;
  dataLoading: boolean;
  idNumberFilter: number = undefined;

  readonly debugMode: boolean = environment.debug;
  readonly itemsPage = typesLocalisation.ItemsPage[environment.language].texts;
  /* Local copies of Enumerators to use on template */
  MeasurementOriginEnum: typeof MeasurementOriginEnum = MeasurementOriginEnum;
  measurementOriginNamesMap: typeof measurementOriginNamesMap = measurementOriginNamesMap;

  private _idPos: number;
  private _idFile: number;

  radioValues: { value: string, label: string }[] = [
    { value: 'SHOW_ALL', label: this.all },
    { value: MeasurementOriginEnum[MeasurementOriginEnum.MANUALLY_ADDED], label: loc[environment.language].texts.measurement.filterLabels.MANUAL },
    { value: MeasurementOriginEnum[MeasurementOriginEnum.FROM_FILE], label: loc[environment.language].texts.measurement.filterLabels.FROM_FILE },
    { value: MeasurementOriginEnum[MeasurementOriginEnum.EDITED], label: loc[environment.language].texts.measurement.filterLabels.EDITED },
  ];

  addingDisabled: boolean = false;
  appUnits: ApplicationUnits;
  origin: string;
  sort: SortType = 'DESC';
  meterPointsIds: number[] = [];

  interactiveTutFunc;

  constructor(
    private dialogsService: MeasurementsPreviewDialogsService,
    private cs: CommonAppDataService,
    private positionDataStore: PositionDataStoreService,
    private meterPointService: MeterPointDataStoreService,
    private measurementsService: MeasurementDataStoreService,
    private veeStatusService: VeeStatusDataStoreService,
    private unitsService: ApplicationUnitsDataStoreService,
    public cd: ChangeDetectorRef,
    private interactiveTutServ: InteractiveTutorialService,
    private measUplIntTutServ: MeasurementsUploadInterativeTutorialService) {

    this.filterFormGroup = new FormGroup({
      meter: new FormControl(''),
      options: new FormControl('SHOW_ALL')
    });

    this.columnsHandler = new DynamicColumnsHandler<MeterPoint>(['name', 'timestamp', 'selectedStatusFlag'], [], ['action']);
    this.dataFetched = false;
    this.dataSource = new MeasurementDataPreviewDataSource();
  }

  ngOnInit() {

    this.interactiveTutFunc = setTimeout(()=>this.startInteractiveTutorial(), 1500);

    const mediaType = this.cs.getCurrentMediaType();
    this.columnsHandler.removeAllDynamicDef();
    if (this.cs.getCurrentMediaType() !== 1) {
      this.unitsService.getApplicationUnits(mediaType)
        .subscribe(units => {
          this.appUnits = units;
          this.appUnits.units.forEach(appUnit => {
            this.columnsHandler.addDynamicColumn({
              sort: false,
              columnDef: appUnit.columnDef,
              header: this.labels.energy,
              type: 'basic',
              group: 1,
              valueKey: appUnit.name,
              classType: (row: MeterPoint) => `__UNSUPPORTED__`,
              generateCellContent: (row: MeterPoint) => `__UNSUPPORTED__`
            });
          });
        });
    } else {
      this.unitsService.getApplicationUnits(mediaType)
        .subscribe(units => {
          this.appUnits = units;
          this.appUnits.units.forEach(appUnit => {
            this.columnsHandler.addDynamicColumn({
              sort: false,
              columnDef: appUnit.columnDef,
              header: this.labels.water,
              type: 'basic',
              group: 1,
              valueKey: appUnit.name,
              classType: (row: MeterPoint) => `__UNSUPPORTED__`,
              generateCellContent: (row: MeterPoint) => `__UNSUPPORTED__`
            });
          });
        });
    }

    this.filterFormSubscription = this.filterFormGroup.get('meter').valueChanges.pipe(debounceTime(500), switchMap(v => {
      const limit = this.paginator.pageSize;
      const offset = 0;
      if (v !== '') {
        return this.meterPointService.getMeterPointBySerialMatch(v);
      } else {
        this.idNumberFilter = undefined;
        return this.loadData(limit, offset, { origin: this.origin });
      }
    }), tap((p: MeterPoint | MeasurementData[]) => {
      if (p instanceof MeterPoint) {
        if (p.idMeterPoint) {
          this.idNumberFilter = p.idMeterPoint;
        } else {
          this.idNumberFilter = -1;
        }
      } else if (!(p instanceof Array)) {
        this.idNumberFilter = -1;
      } else {
        this.idNumberFilter = undefined;
      }
    })).subscribe((p: MeterPoint | MeasurementData[]) => {
      const limit = this.paginator.pageSize;
      const offset = 0;
      const obj = { origin: this.origin };
      this.filterMeterIDLoadData(limit, offset, obj);
    });
  }

  startInteractiveTutorial() {
    const steps = this.measUplIntTutServ.getMeasurementUploadMeasurementPreviewInteractiveTutorialSteps();
    this.interactiveTutServ.startInteractiveTutorial(steps);
  }

  filterMeterIDLoadData(limit: number, offset: number, obj: { origin: string }){
    this.loadData(limit, offset, obj)
      .subscribe((data: MeasurementData[]) => {
        this.dataSource.setNewData(data);
        this.paginator.firstPage();
      },
        (err: any) => { if (this.debugMode) { console.log(err); } },
        () => {
          if (this.debugMode) { console.log(`Init data fetch Finished`); }
          this.dataFetched = true;
        },
      );
  }

  ngAfterViewInit(): void {
    this.paginator._intl.itemsPerPageLabel = this.itemsPage.itemsPage;
    this.paginator._intl.nextPageLabel = this.itemsPage.nextPage;
    this.paginator._intl.previousPageLabel = this.itemsPage.previousPage;
    this.paginator._intl.lastPageLabel = this.itemsPage.lastPage;
    this.paginator._intl.firstPageLabel = this.itemsPage.firstPage;
    const limit = this.paginator.pageSize;
    const offset = this.paginator.pageIndex * this.paginator.pageSize;

    /* initial data load */
    this.loadDataAfterViewInitSubscription = this.loadData(limit, offset)
      .subscribe((data: MeasurementData[]) => {
        this.dataSource.setNewData(data);
      },
      (err: any) => { if (this.debugMode) { console.log(err); } },
      () => {
        if (this.debugMode) { console.log(`Init data fetch Finished`); }
        this.dataFetched = true;
        this.cd.markForCheck();
      },
    );

    this.filterEventsSubscription = this.onFilterEvents()
      .subscribe((pagedData: MeasurementData[]) => {
        this.dataSource.setNewData(pagedData);
      },
      (err) => {console.log('Error: ', err)},
      () => {this.dataFetched = true;
        this.cd.markForCheck();
      });

    this.paginatorEventsSubscription = this.onPaginatorEvents(this.paginator)
      .subscribe((pagedData: MeasurementData[]) => {
        this.dataSource.setNewData(pagedData);
        this.cd.markForCheck();
      },
      (err) => {console.log('Error', err)},
      () => {this.dataFetched = true;
        this.cd.markForCheck();
      });

    this.externalDataChangeSubscription = this.onExternalDataChangeEvent([this.measurementsService.getChangeObservable()])
      .subscribe((pagedData: MeasurementData[]) => {
        this.dataSource.setNewData(pagedData);
      },
      (err) => {console.log('Error', err)},
      () => {this.dataFetched = true;
        this.cd.markForCheck();
    });
    this.cd.detectChanges();
  }

  getDataLength(): number {
    return this.dataLength;
  }

  isLoading(): boolean {
    return (!this.dataFetched);
  }

  get filters(): { [key: string]: AbstractControl } {
    return this.filterFormGroup.controls;
  }

  /* FIXME: Referende to meterpoint in measData model is bad but convinient. */
  loadData(limit: number = 10, offset: number = 0, otherParams?: { origin: string; }): Observable<MeasurementData[]> {

    let dataObservable: Observable<MeasurementData[]>;
    let countObservable: Observable<number>;
    if (!otherParams || otherParams.origin === this.radioValues[0].value) {
      if (!this.idNumberFilter) {
        dataObservable = this.positionDataStore.getPositionMeasurementData(this.idPosition, { limit: limit, offset: offset }, true, { sort: this.sort });
        countObservable = this.positionDataStore.getPositionMeasurementDataCount(this.idPosition);
      } else {
        // tslint:disable-next-line: max-line-length
        dataObservable = this.positionDataStore.getPositionMeasurementData(this.idPosition, { limit: limit, offset: offset }, true, { idMeterPoint: this.idNumberFilter, sort: this.sort });
        countObservable = this.positionDataStore.getPositionMeasurementDataCount(this.idPosition, { idMeterPoint: this.idNumberFilter });
      }

    } else {
      if (!this.idNumberFilter) {
        // tslint:disable-next-line: max-line-length
        dataObservable = this.positionDataStore.getPositionMeasurementData(this.idPosition, { limit: limit, offset: offset }, true, { origin: otherParams.origin, sort: this.sort });
        countObservable = this.positionDataStore.getPositionMeasurementDataCount(this.idPosition, { origin: otherParams.origin });
      } else {
        // tslint:disable-next-line: max-line-length
        dataObservable = this.positionDataStore.getPositionMeasurementData(this.idPosition, { limit: limit, offset: offset }, true, { origin: otherParams.origin, idMeterPoint: this.idNumberFilter, sort: this.sort });
        // tslint:disable-next-line: max-line-length
        countObservable = this.positionDataStore.getPositionMeasurementDataCount(this.idPosition, { origin: otherParams.origin, idMeterPoint: this.idNumberFilter });
      }
    }

    return dataObservable.pipe(concatMap<MeasurementData[], Observable<MeasurementData[]>>((mdArray: MeasurementData[]) => {
      let mesDClosure: MeasurementData[] = [];
      return of(mdArray).pipe(
        concatMap<MeasurementData[], Observable<MeasurementData[]>>((mesD: MeasurementData[]) => {
          return countObservable.pipe(
            map((count: number) => {
              this.dataLength = count;
              return mesD;
            }));
        }),
        concatMap<MeasurementData[], Observable<MeterPoint[]>>((md: MeasurementData[]) => {
          mesDClosure = md.slice(0);
          this.meterPointsIds = [];
          if (mesDClosure.length > 0) {
            mesDClosure.forEach(measurementData => {
              if (this.meterPointsIds.indexOf(measurementData.idMeterPoint) === -1) {
                this.meterPointsIds.push(measurementData.idMeterPoint);
              }
            });
            return this.meterPointService.getMeterPointsListAll({ limit: this.paginator.pageSize, offset: 0 }, true, this.meterPointsIds);
          } else {
            this.cd.markForCheck();
            return of([]);
          }
        }),
        concatMap<MeterPoint[], Observable<MeasurementData[]>>((mpArr: MeterPoint[]) => {
          const matched: MeasurementData[] = mesDClosure.map((md: MeasurementData) => {
            const matchedMp: MeterPoint = mpArr.find(mp => mp.idMeterPoint === md.idMeterPoint);
            if (typeof (matchedMp) !== 'undefined') {
              md.meterPointRef = matchedMp;
              this.cd.markForCheck();
              return md;
            } else {
              throw new Error(`Measurement ${md.idMeasurementData} indicate relation with meterPoint ID ${md.idMeterPoint}\n
            MeterPoint with this ID was not found in MeterPoint collection taking part in position ${this.idPosition}\n
            Data of this position are not integral or corrupted`);
            }
          });
          this.cd.markForCheck();
          return of(matched);
        }));
    }));
  }

  onPaginatorEvents(paginator: MatPaginator): Observable<MeasurementData[]> {
    /* Subscribe for changes that re-render table view */
    return paginator.page.pipe(
      concatMap<PageEvent, Observable<MeasurementData[]>>((pageEvent: PageEvent) => {
        const limit = pageEvent.pageSize;
        const offset = pageEvent.pageIndex * pageEvent.pageSize;
        return this.loadData(limit, offset);
      }));
  }

  onExternalDataChangeEvent(changeInductors: Observable<any>[]): Observable<MeasurementData[]> {
    return merge(...changeInductors).pipe(
      concatMap<any, Observable<MeasurementData[]>>(change => {
        return this.loadData();
      }));
  }

  onFilterEvents(): Observable<MeasurementData[]> {
    return this.filterFormGroup.get('options').valueChanges.pipe(
      concatMap<any, Observable<MeasurementData[]>>(controlValue => {
        this.paginator.pageIndex = 0;
        const limit = this.paginator.pageSize;
        const offset = this.paginator.pageIndex * this.paginator.pageSize;
        this.origin = controlValue;
        //return this.loadData(limit, offset, { origin: controlValue });
        return this.loadData(limit, offset, { origin: this.origin });
      }));
  }

  onClearFilters(): Observable<MeasurementData[]> {
    throw new Error('Method not implemented.');
  }

  onMediaTypeChange(): Observable<UtilityTypeEnum> {
    return this.cs.getObservable('utilityType');
  }

  addElement(): void {
    this.dialogsService
      .openCreateAndEditModal({ mesData: undefined, position: this.idPosition }, loc[environment.language].texts.measurement.modalAddMeasurementTitle)
      .subscribe((res: MeasurementData) => {
        if (typeof (res) !== 'undefined') {
          this.measurementsService.addCMeasurementData(res)
            .subscribe(addedData => {
            });
        } else {
          /* Modal was canceled */
        }
      });
  }

  viewElement(element: MeasurementData): void {
    this.dialogsService
      .openViewModal([element, undefined], loc[environment.language].texts.measurement.modalViewMeasurementTitle)
      .subscribe((res: MeasurementData) => {
      });
  }

  editElement(element: MeasurementData): void {
    this.dialogsService
      .openCreateAndEditModal({ mesData: element, position: this.idPosition }, loc[environment.language].texts.measurement.modalEditMeasurementTitle)
      .subscribe((res: MeasurementData) => {
        if (typeof (res) !== 'undefined') {
          this.measurementsService.updateMeasurementData(new Array<MeasurementData>(res))
            .subscribe(updatedData => {
            });
        } else {
          /* Modal was canceled */
        }
      });
  }

  deleteElement(element: MeasurementData): void {
    this.measurementsService.deleteMeasurementData(element.idMeasurementData);
  }

  ngOnDestroy(): void {
    if (this.loadDataSubscription) {
      this.loadDataSubscription.unsubscribe();
    }
    if (this.filterFormSubscription) {
      this.filterFormSubscription.unsubscribe();
    }
    if (this.loadDataAfterViewInitSubscription) {
      this.loadDataAfterViewInitSubscription.unsubscribe();
    }
    if (this.paginatorEventsSubscription) {
      this.paginatorEventsSubscription.unsubscribe();
    }
    if (this.externalDataChangeSubscription) {
      this.externalDataChangeSubscription.unsubscribe();
    }
    if (this.filterEventsSubscription) {
      this.filterEventsSubscription.unsubscribe();
    }
    this.cd.markForCheck();
    clearTimeout(this.interactiveTutFunc);
  }

}
