import * as moment from "moment";

import { Schedule } from "@shared/models/appModels/schedule.model";
import { Position } from "@shared/models/appModels/position.model";
import { PositionEvent } from "@shared/models/appModels/positionEvent.model";
import { EventType, EventStatus } from "@shared/types/modelTypes/eventType";

export enum CardType {
  EVENT = 0,
  TODAY = 1,
}

/* Navigation according to event type & state. NOT TRANSLATABLE */
const naviParts: string[][] = [
  /* upload */ /* validation */ /* Export */ /* Analysis */ /* All events */
  /* "undefined" */ ["", "", "", "", ""],
  /* "todo" */ [
    "/upload/readings",
    "/data-validation",
    "/export",
    "/data-analysis",
    "/home",
  ],
  /* "doing" */ [
    "/upload/readings",
    "/data-validation",
    "/export",
    "/data-analysis",
    "/home",
  ],
  /* "done", */ [
    "/upload/readings",
    "/data-validation",
    "/export",
    "/data-analysis",
    "/home",
  ],
  /* "closed", */ [
    "/upload/readings",
    "/data-validation",
    "/export",
    "/data-analysis",
    "/home",
  ],
  /* "locked", */ [
    "/upload/readings",
    "/data-validation",
    "/export",
    "/data-analysis",
    "/home",
  ],
];

export class TimelineEntry {
  static typeIconMap = {
    /*Event type*/ /*MD-icon name*/ alert: "warning",
    today: "date_range",
  };

  /* Main title of card i.e. copy of event name */
  title: string;
  /* Names of related schedule & position */
  scheduleName: string;
  positionName: string;

  dataSuccess: string;
  dataError: string;

  dataSuccess2: string;
  dataError2: string;

  dataSuccessValue: number;
  dataErrorValue: number;

  dataSuccessValue2: number;
  dataErrorValue2: number;

  /* Due date copied form event */
  dueDate: moment.Moment;
  /* Is action associated with button available for this entry and it's text */
  action: boolean;
  actionText: string;
  /* Status and type of entry */
  status: EventStatus;
  type: EventType;
  /* Icon reference */
  icoRef: string;
  /* To distinguish between action and status */
  cardType: CardType;

  /* Navigation */
  navigation: string;
  scheduleRef: number;
  positionRef: number;

  /* Prompt */
  promptText: string;

  /* Generate TimelineEntry basing on the schedule and position event information */
  public static generate(
    schIn: Schedule,
    posIn: Position,
    event: PositionEvent
  ): TimelineEntry {
    const timelineEvent = new TimelineEntry();
    timelineEvent.dueDate = moment.utc(event.date);
    timelineEvent.icoRef = TimelineEntry.typeIconMap.alert;
    timelineEvent.cardType = CardType.EVENT;
    timelineEvent.status = event.status;
    timelineEvent.type = event.typeOf;
    timelineEvent.action = true;
    this.setTimelineEntryTitleDataSuccessAndDataError(timelineEvent, posIn);
    this.setTimelineEventActionTextAndPromptText(timelineEvent);
    timelineEvent.navigation =
      naviParts[timelineEvent.status][timelineEvent.type];

    timelineEvent.scheduleRef = schIn.idSchedule;
    timelineEvent.positionRef = posIn.idPosition;

    return timelineEvent;
  }

  private static setTimelineEntryTitleDataSuccessAndDataError(
    timelineEvent: TimelineEntry,
    posIn: Position
  ) {
    switch (timelineEvent.type) {
      case EventType.UPLOAD:
        timelineEvent.title = $localize`:@@timeline/card/upload header:Import #${posIn.orderInSchedule}:POSITION_INDEX:`;
        timelineEvent.dataSuccess = $localize`:@@timeline/card/upload/imported measurements label:Imported`;
        timelineEvent.dataError = $localize`:@@timeline/card/upload/invalid measurements label:Invalid`;
        break;
      case EventType.VALIDATION:
        timelineEvent.title = $localize`:@@timeline/card/validation header:Validation #${posIn.orderInSchedule}:POSITION_INDEX:`;
        timelineEvent.dataSuccess = $localize`:@@timeline/card/validation/validated measurements label:Validated`;
        timelineEvent.dataError = $localize`:@@timeline/card/validation/invalid measurements label:Invalid`;
        break;
      case EventType.EXPORT:
        timelineEvent.title = $localize`:@@timeline/card/export header:Export #${posIn.orderInSchedule}:POSITION_INDEX:`;
        timelineEvent.dataSuccess = $localize`:@@timeline/card/export/meters count label:Meters to export`;
        timelineEvent.dataError = $localize`:@@timeline/card/export/exported meters count label:Exported meters`;
        break;
      case EventType.ANALYSIS:
        timelineEvent.title = $localize`:@@timeline/card/analysis header:Analysis #${posIn.orderInSchedule}:POSITION_INDEX:`;
        timelineEvent.dataSuccess = $localize`:@@timeline/card/analysis/analysed meters count label:Meters analysed`;
        timelineEvent.dataError = $localize`:@@timeline/card/analysis/anomalies count label:Anomalies`;
        timelineEvent.dataError2 = $localize`:@@timeline/card/analysis/forecasts count label:Forecasts`;
        timelineEvent.dataSuccess2 = $localize`:@@timeline/card/analysis/meters count label:Meters`;
        break;
    }
  }

  private static setDoneStatusActionText(timelineEvent: TimelineEntry) {
    switch (timelineEvent.type) {
      case EventType.UPLOAD:
        timelineEvent.actionText = $localize`:@@timeline/card/import/retry button label:Reupload`;
        break;
      case EventType.VALIDATION:
        timelineEvent.actionText = $localize`:@@timeline/card/validation/retry button label:Restart validation`;
        break;
      case EventType.EXPORT:
        timelineEvent.actionText = $localize`:@@timeline/card/export/retry button label:Restart export`;
        break;
      case EventType.ANALYSIS:
        timelineEvent.actionText = $localize`:@@timeline/card/analysis/retry button label:Restart analysis`;
        break;
    }
  }

  private static handleUndefinedStatus(timelineEvent: TimelineEntry) {
    timelineEvent.actionText = "";
    timelineEvent.promptText = "";
  }

  private static setTodoStatusActionText(timelineEvent: TimelineEntry) {
    switch (timelineEvent.type) {
      case EventType.UPLOAD:
        timelineEvent.actionText = $localize`:@@timeline/card/import/start button label:Start upload`;
        timelineEvent.promptText = $localize`:@@timeline/card/import/start process hint label:Pending import`;
        break;
      case EventType.VALIDATION:
        timelineEvent.actionText = $localize`:@@timeline/card/validation/start button label:Start validation`;
        timelineEvent.promptText = $localize`:@@timeline/card/validation/start process hint label:Pending validation`;
        break;
      case EventType.EXPORT:
        timelineEvent.actionText = $localize`:@@timeline/card/export/start button label:Start export`;
        timelineEvent.promptText = $localize`:@@timeline/card/export/start process hint label:Pending export`;
        break;
      case EventType.ANALYSIS:
        timelineEvent.actionText = $localize`:@@timeline/card/analysis/start button label:Start analysis`;
        timelineEvent.promptText = $localize`:@@timeline/card/analysis/start process hint label:Pending analysis`;
        break;
    }
  }

  private static setInProgressStatusActionText(timelineEvent: TimelineEntry) {
    switch (timelineEvent.type) {
      case EventType.UPLOAD:
        timelineEvent.actionText = $localize`:@@timeline/card/import/continue button label:Continue import`;
        break;
      case EventType.VALIDATION:
        timelineEvent.actionText = $localize`:@@timeline/card/validation/continue button label:Continue validation`;
        break;
      case EventType.EXPORT:
        timelineEvent.actionText = $localize`:@@timeline/card/export/continue button label:Continue export`;
        break;
      case EventType.ANALYSIS:
        timelineEvent.actionText = $localize`:@@timeline/card/analysis/continue button label:Continue analysis`;
        break;
    }
  }

  private static setClosedStatusActionText(timelineEvent: TimelineEntry) {
    switch (timelineEvent.type) {
      case EventType.UPLOAD:
      case EventType.VALIDATION:
      case EventType.EXPORT:
      case EventType.ANALYSIS:
        timelineEvent.actionText = $localize`:@@timeline/card/show results button label:Show results`;
        break;
    }
  }

  private static setLockedStatusActionText(timelineEvent: TimelineEntry) {
    switch (timelineEvent.type) {
      case EventType.UPLOAD:
        timelineEvent.actionText = $localize`:@@timeline/card/import/locked button label:Start import`;
        break;
      case EventType.VALIDATION:
        timelineEvent.actionText = $localize`:@@timeline/card/validation/locked button label:Start validation`;
        timelineEvent.promptText = $localize`:@@timeline/card/validation/locked hint label:Import required`;
        break;
      case EventType.EXPORT:
        timelineEvent.actionText = $localize`:@@timeline/card/export/locked button label:Start export`;
        timelineEvent.promptText = $localize`:@@timeline/card/export/locked hint label:Validation required`;
        break;
      case EventType.ANALYSIS:
        timelineEvent.actionText = $localize`:@@timeline/card/analysis/locked button label:Start analysis`;
        timelineEvent.promptText = $localize`:@@timeline/card/analysis/locked hint label:Validation required`;
        break;
    }
  }

  private static setTimelineEventActionTextAndPromptText(
    timelineEvent: TimelineEntry
  ) {
    switch (timelineEvent.status) {
      case EventStatus.DONE: {
        this.setDoneStatusActionText(timelineEvent);
        break;
      }
      case EventStatus.UNDEFINED: {
        this.handleUndefinedStatus(timelineEvent);
        break;
      }
      case EventStatus.TODO: {
        this.setTodoStatusActionText(timelineEvent);
        break;
      }
      case EventStatus.IN_PROGRESS: {
        this.setInProgressStatusActionText(timelineEvent);
        break;
      }
      case EventStatus.CLOSED: {
        this.setClosedStatusActionText(timelineEvent);
        break;
      }
      case EventStatus.LOCKED: {
        this.setLockedStatusActionText(timelineEvent);
        break;
      }
    }
  }

  isDelayed(dateAgainst?: moment.Moment): boolean {
    if (dateAgainst) {
      return (
        this.dueDate.isBefore(dateAgainst) &&
        this.status !== EventStatus.DONE &&
        this.status !== EventStatus.CLOSED
      );
    }

    return (
      this.dueDate.isBefore(moment.utc(), "day") &&
      this.status !== EventStatus.DONE &&
      this.status !== EventStatus.CLOSED
    );
  }

  daysLeft(date: moment.Moment) {
    if (this.dueDate) {
      return this.dueDate.diff(date, "days");
    }
  }
}
