import { TableDataSource } from '@shared/models/tableDataSource.model';
import { Position } from '@shared/models';

export class PositionTabbed extends Position {
  detailRow: boolean;
  rowId: number;
}

export class PositionDataDataSource extends TableDataSource<Position, PositionTabbed> {

  private setParams(detailRow: boolean, schedule: Position, baseElement: Position) {
    const element: PositionTabbed = new PositionTabbed(schedule);
    element.detailRow = detailRow;
    element.getName = baseElement.getName;
    element.idSchedule = baseElement.idSchedule;
    element.rowId = baseElement.idPosition;
    element.idPosition = baseElement.idPosition;
    element.activeScheduler = baseElement.activeScheduler;
    return element;
  }

  protected renderData(data: Position[]): PositionTabbed[] {
    const rows: PositionTabbed[] = [];
    data.forEach((baseElement, idx) => {
      const topElement: PositionTabbed = this.setParams(false, baseElement, baseElement);
      const detailElement: PositionTabbed = this.setParams(true, topElement, baseElement);
      rows.push(topElement, detailElement);
    });
    return rows;
  }
}
