<div [formGroup]="filterForm">
	<mat-form-field class="form-group mat-form-field-select-page">
		<mat-select [placeholder]="getControlPlaceholderText('schedule')" formControlName="scheduleSelect">
			<mat-option *ngFor="let sch of schedules; let i=index" [value]="sch.getId()">{{ sch.getScheduleName() }}
			</mat-option>
			<mat-option *ngIf="createNewAllowed" value="create">{{getControlCreationText('schedule')}}</mat-option>
		</mat-select>
	</mat-form-field>

	<mat-form-field class="form-group">
		<mat-select [placeholder]="getControlPlaceholderText('position')" formControlName="positionSelect">
			<mat-option *ngFor="let pos of positions; let i=index" [value]="pos.getId()">{{ pos.getName() }}
			</mat-option>
			<mat-option *ngIf="createNewAllowed" value="create">{{getControlCreationText('position')}}</mat-option>
		</mat-select>
	</mat-form-field>
</div>