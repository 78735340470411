// Angular imports
import {
  Component,
  OnInit,
  OnDestroy,
  Inject,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  AfterViewInit,
} from "@angular/core";
import { FormGroup, FormArray, FormControl, FormBuilder, Validators } from "@angular/forms";

// External libraries
import { Subscription } from "rxjs/Subscription";
import * as moment from "moment";

// Enviroments
import { environment } from "@env/environment";

// Services
import { ScheduleDataStoreService } from "@shared/services/dataStore/scheduleDataStore.service";
import { CommonAppDataService } from "@shared/services/commonAppData.service";
import { ScheduleSupportService } from "../service/scheduleSupport.service";

// Models
import {
  Schedule,
  ISchedule,
  IPosition,
  PositionEvent,
  IPositionEvent,
  Position,
} from "@shared/models";
import { EventType, EventStatus, UtilityTypeEnum } from "@shared/types";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PositionDataStoreService } from "@shared/services/dataStore/positionDataStore.service";
import { Subject } from 'rxjs';
import { AutomaticSchedulerForPositionHelperInterface } from '../edit/schedule-edit.component';

export interface MeterPointByPositionIdCount {
  index: number;
  count: number;
}
import { InteractiveTutorialService } from '@shared/services/interactiveTutorialService.service';
import { ScheduleInteractiveTutorialService } from "../scheduleInteractiveTutorialService.service";

@Component({
  selector: "sv-create-schedule",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./schedule-create.component.html",
  styleUrls: ["./schedule-create.component.sass"],
})
export class ScheduleCreateComponent
  implements OnInit, OnDestroy, AfterViewInit {
  /* Form */
  /* Main form group */
  scheduleForm: FormGroup;
  /* Form controls */
  scheduleNameControl: FormControl;
  /* Array of positions */
  schedulePositionsFormArray: FormArray;

  private readonly debugMode: boolean = environment.debug;
  readonly automaticScheduler = environment.automaticScheduler === "true";

  nextScheduleNumber: number;
  private today: moment.Moment;
  private maxId$: Subscription;
  currentDate = moment(Date.now()).format('YYYY-MM-DD');
  beginDate: string;
  endDate: string;
  /* Errors */
  endDateIsNotAfterStartDate = false;
  startDateIsNotBeforeEndDate = false;
  activeSchedulerConfigError = false;
  blockSaveBtn = false;

  activeSchedulerPositionsValidationList = [];
  watchChangesInActiveSchedulerValidationListSubject: Subject<Array<any>> = new Subject();
  utilityType: UtilityTypeEnum;
  allActiveMeterPoints: number;
  selectedActiveMeterPoints: MeterPointByPositionIdCount[] = new Array<MeterPointByPositionIdCount>();
  startTutFunc;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { schedule: Schedule },
    public dialogRef: MatDialogRef<ScheduleCreateComponent>,
    private fb: FormBuilder,
    private scheduleSupportService: ScheduleSupportService,
    private schDataStoreService: ScheduleDataStoreService,
    private positionDataStoreService: PositionDataStoreService,
    private cdr: ChangeDetectorRef,
    private comServ: CommonAppDataService,
    private interactiveTutorialService: InteractiveTutorialService,
    private schedulesInteractiveTutorialService: ScheduleInteractiveTutorialService
  ) {
    /* Init start date as common control innitializer */
    this.today = this.comServ.getToday();

    // TODO: extract init controls object as interface
    this.setupFormControls();
    this.scheduleForm = this.fb.group({
      scheduleNameControl: this.scheduleNameControl,
      positions: this.schedulePositionsFormArray,
    });
  }

  ngOnInit() {
    this.utilityType = this.comServ.getCurrentMediaType();
    this.watchChangesInActiveSchedulerValidationListSubject.subscribe(arr => {
      let validElemsCounter = 0;
      arr.forEach(element => {
        if (!element.isValid) {
          this.blockSaveBtn = true;
        } else {
          validElemsCounter += 1;
        }
      });
      if (validElemsCounter === arr.length) {
        this.blockSaveBtn = false;
      }
    });
    if (this.data.schedule) {
      this.populateControlsFromTemplate();
    }
    /* Request last occupied schedule ID as control innitializer */
    this.maxId$ = this.schDataStoreService.getNextScheduleNumber().subscribe(
      (val: number) => {
        this.nextScheduleNumber = val;
      },
      (error: any) => {
        console.warn(error);
      },
      () => {
        if (this.debugMode) {
          console.log(`Schedule ID observable finalized`);
        }
      }
    );
    //!scheduleForm.valid || activeSchedulerConfigError || blockSaveBtn
    this.cdr.detectChanges();
  }

  ngAfterViewInit(): void {
    this.addPosition();
    this.positionDataStoreService.getPositionMeterPointsCount(-99999.0, false, { active: true }).subscribe(v => {
      this.allActiveMeterPoints = v;
      this.cdr.markForCheck();
    });
    this.startTutFunc = setTimeout(() => {
        this.startInteractiveTutorial();
      }, 1000);
  }

  private startInteractiveTutorial() {
    const steps = this.schedulesInteractiveTutorialService.getScheduleCreateModalInteractiveTutorialSteps();
    this.interactiveTutorialService.startInteractiveTutorial(steps);
  }

  private setupFormControls() {
    this.scheduleNameControl = new FormControl(
      $localize`:default schedule name|The default name for a schedule@@schedules/default schedule name:Schedule`
    );
    /* Init as empty control array  */
    this.schedulePositionsFormArray = new FormArray([]);
  }

  populateControlsFromTemplate() {
    /* Nested copy of events */
    for (let position of this.data.schedule.positions) {
      this.activeSchedulerPositionsValidationList.push({ position: position, isValid: position.activeScheduler ? false : true });
      const tempArray = new FormArray([]);
      position.events.forEach((event: PositionEvent, idxEv: number) => {
        const temp: any = +position.events[idxEv].date;
        tempArray.push(
          this.scheduleSupportService.createNewEventControlGroup(
            moment.utc(temp),
            position.events[idxEv].typeOf,
            { offsetDates: false }
          )
        );
      });
      const newFormGroup: FormGroup = this.fb.group({
        name: new FormControl(position.name + position.orderInSchedule),
        events: tempArray,
        dateFrom: new FormControl(this.currentDate, this.isStartDateBeforeEndDate().bind(this)),
        dateTo: new FormControl(this.currentDate, this.isEndDateAfterStartDate().bind(this)),
        aggregation: new FormControl('1d', Validators.required),
      });
      this.schedulePositionsFormArray.push(newFormGroup);
    }
    this.watchChangesInActiveSchedulerValidationListSubject.next(this.activeSchedulerPositionsValidationList);
  }

  createIPosition(value: any, index: number): IPosition {
    if (value.events instanceof FormArray) {
      return {
        name: this.getNameForPosition(index),
        idPosition: -1,
        orderInSchedule: index,
        idSchedule: -1,
        events: value.events.controls.map(
          (eventForm): IPositionEvent => {
            return {
              date: +eventForm.controls.date.value,
              status: eventForm.controls.status.value,
              typeOf: eventForm.controls.typeOf.value,
            };
          }
        ),
        activeScheduler: value.activeScheduler.value,
      };
    } else {
      return {
        name: this.getNameForPosition(index),
        idPosition: -1,
        orderInSchedule: index,
        idSchedule: -1,
        events: value.events.map(
          (eventForm): IPositionEvent => {
            return {
              date: +eventForm.date,
              status: eventForm.status,
              typeOf: eventForm.typeOf,
            };
          }
        ),
        activeScheduler: value.activeScheduler,
      };
    }
  }


  checkSchedulerPositionValidation(orderInSchedule: number, offScheduler: any = null) {
    this.activeSchedulerPositionsValidationList.forEach(element => {
      if (element.position.orderInSchedule === orderInSchedule) {
        if (offScheduler) {
          element.isValid = offScheduler.value.activeScheduler;
          if (!offScheduler.value.activeScheduler) {
            this.endDateIsNotAfterStartDate = false;
            this.startDateIsNotBeforeEndDate = false;
            this.activeSchedulerConfigError = false;
            this.blockSaveBtn = false;
            this.scheduleForm.markAsPristine();
            this.scheduleForm.markAsUntouched();
          }
        } else {
          element.isValid = true;
        }
      }
    });
    this.watchChangesInActiveSchedulerValidationListSubject.next(this.activeSchedulerPositionsValidationList);
  }

  addPosition() {
    this.schedulePositionsFormArray.push(this.createPositionControlGroup());
    const positions = this.generatePositionsCopy();
    positions.forEach(element => {
      if (this.selectedActiveMeterPoints.find(m => m.index === element.orderInSchedule) === undefined) {
        this.selectedActiveMeterPoints.push({ index: element.orderInSchedule, count: undefined })
      }
      const elem = this.activeSchedulerPositionsValidationList.find(el => {
        return el.position.orderInSchedule === element.orderInSchedule;
      });
      if (typeof elem === 'undefined') {
        this.activeSchedulerPositionsValidationList.push({ position: element, isValid: element.activeScheduler ? false : true });
      }
    });
    this.watchChangesInActiveSchedulerValidationListSubject.next(this.activeSchedulerPositionsValidationList);
  }

  removePosition(event: Event, positionIndex: number) {
    /* Prevent multiclick */
    event.stopPropagation();
    this.schedulePositionsFormArray.removeAt(positionIndex);
    const positions = this.generatePositionsCopy();
    var removeElement;
    this.selectedActiveMeterPoints.forEach(element => {
      if (element.index === positionIndex) {
        removeElement = element
      } else if (positionIndex < element.index) {
        element.index = element.index - 1;
      }
    });
    this.selectedActiveMeterPoints.splice(this.selectedActiveMeterPoints.indexOf(removeElement), 1)
    let elemToDelete;
    this.activeSchedulerPositionsValidationList.forEach(elem => {
      const element = positions.find(el => {
        return el.orderInSchedule === elem.position.orderInSchedule;
      });
      if (typeof element === 'undefined') {
        elemToDelete = elem;
      }
    });
    const index = this.activeSchedulerPositionsValidationList.indexOf(elemToDelete);
    this.activeSchedulerPositionsValidationList.splice(index, 1);
    this.watchChangesInActiveSchedulerValidationListSubject.next(this.activeSchedulerPositionsValidationList);
  }

  offPositionsSchedulers(event: any, index: number) {
    this.checkSchedulerPositionValidation(index, event);
    this.activeSchedulerConfigError = false;
    const newPosition: IPosition = this.createIPosition(event.value, index);
    if (newPosition.idPosition !== -1) {
      this.positionDataStoreService
        .updatePosition(new Position(newPosition))
        .subscribe();
    }
  }

  createPositionControlGroup(): FormGroup {
    const baseDate = this.today;
    const events = [
      EventType.UPLOAD,
      EventType.VALIDATION,
      EventType.EXPORT,
      EventType.ANALYSIS,
    ];

    return this.fb.group({
      activeScheduler: new FormControl(false),
      dateFrom: new FormControl(this.currentDate, this.isStartDateBeforeEndDate().bind(this)),
      dateTo: new FormControl(this.currentDate, this.isEndDateAfterStartDate().bind(this)),
      aggregation: new FormControl('1d', Validators.required),
      events: new FormArray(
        events.map((eventType) =>
          this.scheduleSupportService.createNewEventControlGroup(
            baseDate,
            eventType,
            { offsetDates: true }
          )
        )
      ),
    });
  }

  getNameForPosition(positionIndex: number): string {
    return $localize`:schedule position name|Position name label@@schedules/schedule position:Schedule position #${positionIndex + 1
      }:POSITION_INDEX:`;
  }

  getNameForEvent(event: FormGroup, positionIndex: number) {
    const eventTypeName = this.scheduleSupportService.getEventTypeName(
      event.controls.typeOf.value
    );
    return $localize`:position event caption|Position event caption@@schedules/position event caption:${eventTypeName}:EVENT_TYPE_NAME: #${positionIndex + 1
      }:POSITION_INDEX:`;
  }

  ngOnDestroy() {
    try {
      this.maxId$.unsubscribe();
    } catch (e) {
      console.error("Unsubscribe erorr", e);
    }
    if (this.debugMode) {
      console.log("Schedule create component destroyed");
    }
    clearTimeout(this.startTutFunc);
  }

  onSubmitNewSchedule() {
    const formModel = this.scheduleForm.value;
    const schIfc: ISchedule = {} as ISchedule;

    schIfc.name =
      formModel.scheduleNameControl + " #" + this.nextScheduleNumber;

    // FIXME: this is added only due to type completeness
    schIfc.idSchedule = -1;
    schIfc.closed = false;
    schIfc.scheduleNumber = this.nextScheduleNumber;
    schIfc.utilityType = this.comServ.getCurrentMediaType();

    /* Map each form entry to position interface */
    const deepCopyPositions: IPosition[] = formModel.positions.map(
      (positionForm, index: number) => {
        const copiedPosition: IPosition = {} as IPosition;
        /* ID's are unknown at creation time */
        copiedPosition.idPosition = -1;
        copiedPosition.idSchedule = -1;

        copiedPosition.name = this.getNameForPosition(index);
        copiedPosition.orderInSchedule = index + 1;
        copiedPosition.activeScheduler = positionForm.activeScheduler;
        /* Init events as empty array */
        copiedPosition.events = [];
        positionForm.events.forEach((evForm, idx) => {
          /* Assign initial states for position events */
          copiedPosition.events[idx] = {} as IPositionEvent;
          if (idx === EventType.UPLOAD) {
            copiedPosition.events[idx].status = EventStatus.TODO;
          } else {
            copiedPosition.events[idx].status = EventStatus.LOCKED;
          }
          copiedPosition.events[idx].date = +evForm.date;
          // copiedPositinon.events = positionForm.events.slice(0);
          copiedPosition.events[idx].typeOf = evForm.typeOf as EventType;
        });
        return copiedPosition;
      }
    );

    const newSch = new Schedule(schIfc, deepCopyPositions);

    const automaticSchedulerForPositions: AutomaticSchedulerForPositionHelperInterface[] = this.generateAutomaticImportForPositionConfig();
    const dataAboutScheduleAndPositions: any[] = [];
    dataAboutScheduleAndPositions.push(newSch);
    dataAboutScheduleAndPositions.push(automaticSchedulerForPositions);

    this.dialogRef.close(dataAboutScheduleAndPositions);
  }

  isEndDateAfterStartDate(format = 'YYYY-MM-DD'): any {
    return (control: FormControl): { [key: string]: any } => {
      const val = moment(control.value, format, true);
      this.endDate = control.value;
      const currentDate = moment(this.currentDate, format, true);
      this.endDateIsNotAfterStartDate = false;
      if (val.isSameOrAfter(this.beginDate, 'day')) {
        this.endDateIsNotAfterStartDate = false;
        return null;
      } else {
        this.endDateIsNotAfterStartDate = true;
        return { 'endDateIsNotAfterStartDate': true };
      }
    };
  }

  isStartDateBeforeEndDate(format = 'YYYY-MM-DD'): any {
    return (control: FormControl): { [key: string]: any } => {
      this.beginDate = control.value;
      const val = moment(control.value, format, true);
      this.startDateIsNotBeforeEndDate = false;
      const currentDate = moment(this.endDate, format, true);
      if (val.isSameOrBefore(this.endDate, 'day')) {
        this.startDateIsNotBeforeEndDate = false;
        return null;
      } else {
        this.startDateIsNotBeforeEndDate = true;
        return { 'startDateIsNotBeforeEndDate': true };
      }
    };
  }

  generatePositionsCopy(): Position[] {
    const iPositionsCopy: IPosition[] = this.schedulePositionsFormArray.controls.map(
      (position: FormGroup, index: number) => this.createIPosition(position.controls, index)
    );

    const positions: Position[] = [];
    try {
      iPositionsCopy.forEach((p) => positions.push(new Position(p)));
    } catch {
      throw new Error("Unable to create new position from position interface");
    }
    return positions;
  }

  generateAutomaticImportForPositionConfig(): AutomaticSchedulerForPositionHelperInterface[] {
    const automaticSchedulerForPositions: AutomaticSchedulerForPositionHelperInterface[] = [];
    const positions = this.generatePositionsCopy();
    const formValues = this.scheduleForm.value;

    for (let i = 0; i < formValues.positions.length; i++) {
      if (formValues.positions[i].activeScheduler) {
        const pos = formValues.positions[i];
        const hourDateFrom = '0:00';
        const hourDateTo = '23:59';
        const dateFrom = this.getTimestamp(moment(pos.dateFrom + ' ' + hourDateFrom, 'YYYY-MM-DD HH:mm'));
        const dateTo = this.getTimestamp(moment(pos.dateTo + ' ' + hourDateTo, 'YYYY-MM-DD HH:mm'));
        automaticSchedulerForPositions.push({
          start_time: +dateFrom, end_time: +dateTo,
          aggregation: pos.aggregation, positionId: positions[i].idPosition, utility_type: this.utilityType, orderInSchedule: positions[i].orderInSchedule
        });
      }
    }
    return automaticSchedulerForPositions;
  }

  validateData(index: number) {
    this.scheduleForm.updateValueAndValidity();
    const automaticSchedulerForPositions: AutomaticSchedulerForPositionHelperInterface[] = this.generateAutomaticImportForPositionConfig();
    const config = automaticSchedulerForPositions.find(element => element.orderInSchedule === index);
    this.positionDataStoreService.getPositionMeasurementDataCount(-99999.0, {
      from: config.start_time,
      to: config.end_time
    }).subscribe(count => {
      if (count === 0) {
        this.activeSchedulerConfigError = true;
        this.selectedActiveMeterPoints.find(m => m.index === index).count = 0;
        this.cdr.markForCheck();
      } else {
        this.activeSchedulerConfigError = false;
        this.checkSchedulerPositionValidation(index);
        this.positionDataStoreService.getPositionMeterPointsCount(-99999.0, false, { from: config.start_time, to: config.end_time, active: true }).subscribe(v => {
          this.selectedActiveMeterPoints.find(m => m.index === index).count = v;
          this.cdr.markForCheck();
        });
      }
    });
  }

  openExpansionPanel(posIndex: number) {
    this.beginDate = this.scheduleForm.controls.positions.value[posIndex].dateFrom;
    this.endDate = this.scheduleForm.controls.positions.value[posIndex].dateTo;
    this.activeSchedulerConfigError = false;
  }
  getTimestamp(date: any) {
    var timezoneOffset = new Date(date).getTimezoneOffset() * 60 * 1000;
    return timezoneOffset > 0 ? new Date(date).getTime() + timezoneOffset : new Date(date).getTime() - timezoneOffset;
  }

  getMeterPointByPostitionIdCount(index: number) {
    return this.selectedActiveMeterPoints.find(m => m.index === index).count;
  }
}
