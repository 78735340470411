<div class="card alert alert-info">
	<div class="row all-validated">
		<!-- mock: data -->
		<span class="col" i18n="@@data validation/summary/counter labels/total">
			<span *ngIf="counters.TOTAL.pending || !loadAllInvalids">
				<mat-spinner [strokeWidth]="3" [diameter]="20"></mat-spinner>
			</span>
			<b *ngIf="!counters.TOTAL.pending && loadAllInvalids" class="notranslate">{{counters.TOTAL.value}}</b>
			 Validated data
		</span>
		<span class="col" i18n="@@data validation/summary/counter labels/invalid">
			<span *ngIf="counters[ValidationResultEnum[ValidationResultEnum.INVALID]].pending || !loadAllInvalids">
				<mat-spinner [strokeWidth]="3" [diameter]="20" class="notranslate"></mat-spinner>
			</span>
			<b
				*ngIf="!counters[ValidationResultEnum[ValidationResultEnum.INVALID]].pending && loadAllInvalids" class="notranslate">{{counters[ValidationResultEnum[ValidationResultEnum.INVALID]].value}}</b>
				 Invalid
		</span>
		<span class="col" i18n="@@data validation/summary/counter labels/auto correction">
			<span
				*ngIf="counters[ValidationResultEnum[ValidationResultEnum.AUTOCORRECTION]].pending || !loadAllInvalids">
				<mat-spinner [strokeWidth]="3" [diameter]="20" class="notranslate"></mat-spinner>
			</span>
			<b
				*ngIf="!counters[ValidationResultEnum[ValidationResultEnum.AUTOCORRECTION]].pending && loadAllInvalids" class="notranslate">{{counters[ValidationResultEnum[ValidationResultEnum.AUTOCORRECTION]].value}}</b>
				Auto correction
		</span>
		<span class="col" i18n="@@data validation/summary/counter labels/manual correction">
			<span
				*ngIf="counters[ValidationResultEnum[ValidationResultEnum.MANUALCORRECTION]].pending || !loadAllInvalids">
				<mat-spinner [strokeWidth]="3" [diameter]="20" class="notranslate"></mat-spinner>
			</span>
			<b
				*ngIf="!counters[ValidationResultEnum[ValidationResultEnum.MANUALCORRECTION]].pending && loadAllInvalids" class="notranslate">{{counters[ValidationResultEnum[ValidationResultEnum.MANUALCORRECTION]].value}}</b>
				Manual correction
		</span>
	</div>
</div>

<div class="card table-filter">
	<div class="card-header">
		<div [formGroup]="changeFilterForm">
			<b i18n="@@data validation/summary/filter label">Filters:</b>
			<mat-form-field id="metersFilter" class="form-group">
				<input matInput type="text" placeholder="Meter ID" i18n-placeholder="@@data validation/summary/table headers/meter" formControlName="meter">
			</mat-form-field>
			<mat-form-field id="locationFilter" class="form-group">
				<input matInput type="text" i18n-placeholder ="@@interactive tutorial/data analysis/location title" placeholder="Location" formControlName="location">
			</mat-form-field>
			<mat-radio-group id="validationResultFilters" #radioSelect formControlName="options">
				<!-- mock: data -->
				<mat-radio-button value="SHOW_ALL" (click)="disableRuleChoice()"
					i18n="@@validation/filter/show all option name">All</mat-radio-button>
				<mat-radio-button [value]="ValidationResultEnum[ValidationResultEnum.VALID]"
					(click)="disableRuleChoice()">
					{{validationFlagNames[ValidationResultEnum.VALID].name}}</mat-radio-button>
				<mat-radio-button [value]="ValidationResultEnum[ValidationResultEnum.INVALID]">
					{{validationFlagNames[ValidationResultEnum.INVALID].name}}</mat-radio-button>
				<mat-radio-button [value]="ValidationResultEnum[ValidationResultEnum.AUTOCORRECTION]"
					(click)="disableRuleChoice()">
					{{validationFlagNames[ValidationResultEnum.AUTOCORRECTION].name}}</mat-radio-button>
				<mat-radio-button [value]="ValidationResultEnum[ValidationResultEnum.MANUALCORRECTION]"
					(click)="disableRuleChoice()">
					{{validationFlagNames[ValidationResultEnum.MANUALCORRECTION].name}}</mat-radio-button>
			</mat-radio-group>
			<div>
				<ng-container *ngIf="this.changeFilterForm.get('options').value === 'INVALID'">
					<mat-form-field class="form-group" style="width: 450px">
						<mat-select [placeholder]="currentRadioBtnValue">
							<mat-radio-group formControlName="checkedRule" [value]="currentRadioBtnValue">
								<ng-container *ngFor="let role of rulesArray; let j = index">
									<mat-option>
										<mat-radio-button
											[value]="j===0 ? allRules : getRadioBtnDisplayName(existsValidators[j])"
											(change)="onRuleRadioBtnChange(j)">
											{{ j===0 ? allRules : getRadioBtnDisplayName(existsValidators[j]) }}
										</mat-radio-button>
									</mat-option>
								</ng-container>
							</mat-radio-group>
						</mat-select>
					</mat-form-field>
					<button class="menuBtn" mat-icon-button [matMenuTriggerFor]="menu"
						(click)="$event.stopPropagation()">
						<i class="material-icons notranslate">{{menuMdIcon}}</i>
					</button>
					<mat-menu #menu="matMenu" class="menu-box">
						<button mat-menu-item (click)="onSelectUnSelect()"
							[disabled]="(this.changeFilterForm.get('options').value !== 'INVALID') || !loadAllInvalids|| lackOfInvalids || this.checkedInvalids.length === 0">
							<i class="material-icons notranslate">{{configureMdIcon}}</i>
							<span i18n="@@data validation/summary/button labels/select">Select/Unselect all</span>
						</button>

						<button mat-menu-item (click)="onCollectiveManualCorrection($event)"
							[disabled]="ifCollectiveCorrectionBtnDisabled()">
							<i class="material-icons notranslate">{{checkIcon}}</i>
							<span i18n="@@data validation/summary/button labels/correction">Approve all / Selected</span>
						</button>
					</mat-menu>
				</ng-container>
			</div>

		</div>

		<div class="smaller-screens-btns-validation-summary">
			<!-- <button *ngIf="btnApprove"
								mat-raised-button color="accent" class="btn">
					{{approveAll}}
				</button> -->

			<button id="freezeBtn" [disabled]="freezeDisabled" (click)="onFreezData()" mat-raised-button color="primary" class="btn" i18n="@@data validation/summary/button labels/freeze">
				Freeze validation data
			</button>

			<button id="goAnalysisBtn" [disabled]="goToDisabled" (click)="onGoToAnalysis()" mat-raised-button color="primary" class="btn" i18n="@@data validation/summary/button labels/go analysis">
				Go to data analysis
			</button>

			<button id="goExportBtn" [disabled]="goToDisabled" (click)="onGoToExport()" mat-raised-button color="accent" class="btn" i18n="@@data validation/summary/button labels/go export">
				Go to data export
			</button>

			<!-- <button
				[disabled]="(this.changeFilterForm.get('options').value !== 'INVALID') || !loadAllInvalids|| lackOfInvalids || this.checkedInvalids.length === 0"
				(click)="onSelectUnSelect()" mat-raised-button color="primary" class="btn">

				{{buttonLabels.SELECT}}
			</button>

			<button
				[disabled]="(this.changeFilterForm.get('options').value !== 'INVALID') || !loadAllInvalids || lackOfInvalids || this.checkedInvalids.length === 0"
				(click)="onCollectiveManualCorrection()" mat-raised-button color="primary" class="btn">

				{{buttonLabels.CORRECTION}}
			</button> -->

		</div>

	</div>

	<mat-table id="metersTable" [dataSource]="dataSourceNew" class="mat-elevation-z8">
		<ng-container matColumnDef="meterId">
			<mat-header-cell *matHeaderCellDef i18n="@@data validation/summary/meter id"> MeterId and Location </mat-header-cell>
			<mat-cell *matCellDef="let element">
				<mat-accordion class="test">
					<mat-expansion-panel hideToggle #expansionPanel>
						<mat-expansion-panel-header (click)="expandPanel(expansionPanel, $event, element)">
							<mat-panel-title>
								{{element.serialNumber}}
							<br/>
								{{ element?.meterPlacement }}
							</mat-panel-title>
						</mat-expansion-panel-header>
						<div *ngIf="canIRender(element)">
							<sv-detail (measurementInputClickedValue)="onMeasurementInputClickedValue($event)"
								(responseCheckboxesValues)="onResponseCheckboxesValues($event)"
								(refreshCounters)="onRefreshCounters($event)"
								(detailCheckedInvalidsReadings)="onCheckedInvalidsReadings($event)"
								(noDataInDetailsTable)="onNoDataInSomeOfDetailsTable($event)"
								(allInvalidReadingsUnchecked)="onAllInvalidReadingsUnchecked($event)" [detail]="element"
								[filterObj]="filterObj" [currentCheckboxesState]="currentCheckBoxState"
								[appUnits]="applicationUnits" [checkedInvalid]="checkedInvalids"
								[positionFrozen]="positionFrozenObj"
								[askForCheckboxesValues]="askForCheckboxesValuesBehSubject"></sv-detail>
						</div>
					</mat-expansion-panel>
				</mat-accordion>
			</mat-cell>
		</ng-container>

		<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
		<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
	</mat-table>

	<div class="no-result" *ngIf="(dataLength === 0 && dataFetched)" i18n="@@data validation/summary/no data found">
		No data found.
	</div>

	<div class="no-result" *ngIf="dataLoading || !loadAllInvalids">
		<mat-spinner [strokeWidth]="3" [diameter]="20" class="notranslate"></mat-spinner>
	</div>

	<mat-paginator #paginator [length]="dataLength" [pageIndex]="0" [pageSize]="10" [pageSizeOptions]="[5, 10, 20]"
		showFirstLastButtons class="notranslate">
	</mat-paginator>
</div>