import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { KeycloakService } from '@shared/services/auth/keycloak.service';
import { KeyCloakTokenResult } from '@shared/types/authTypes';
import 'rxjs/add/observable/fromPromise';
import { environment } from '@env/environment';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private DEBUG_MODE: boolean;

  constructor(private keycloakService: KeycloakService) {
    this.DEBUG_MODE = environment.authDebug;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.DEBUG_MODE) { console.log('Authentication HTTP Interceptor called'); }
    if (!environment.authConfig.authRequired) {
      return next.handle(req);
    }
    const token$: Observable<KeyCloakTokenResult> = Observable.fromPromise(this.keycloakService.getToken());
    return token$
      .concatMap(tokenResult => {
        if (tokenResult.success) {
          if (this.DEBUG_MODE) { console.log('API with token', tokenResult); }
          return next.handle(this.setAuthHeader(req, tokenResult.token));
        }
        if (this.DEBUG_MODE) { console.warn('Unable to refresh Auth token'); }
        this.keycloakService.clearToken();
        return next.handle(req);
      });
  }

  private setAuthHeader(req: HttpRequest<any>, authToken: string): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        'Authorization': `Bearer ${authToken}`
      }
    });
  }

}



