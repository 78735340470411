import {
  Component,
  Input,
  ViewChild,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
  OnDestroy,
  Output,
  EventEmitter,
} from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTabChangeEvent } from "@angular/material/tabs";

import { MeterData } from "@shared/models/appModels/meterData.model";

import {
  ValidationResultEnum,
  TableHost,
  VeeStatusEnum,
  MeterDataOriginEnum,
  MeasurementOriginEnum,
  UtilityTypeEnum,
  getValidationResult,
} from "@shared/types";

import { PositionDataStoreService } from "@shared/services/dataStore/positionDataStore.service";
import { CommonAppDataService } from "@shared/services/commonAppData.service";
import { MeasurementDataTabbed } from "../../dataSource/meterDataSource";
import { environment } from "@env/environment";
import { VeeRulesDataStoreService } from "@shared/services/dataStore/veeRulesDataStore";
import { VeeRule, IVeeRule } from "@shared/models/appModels/VeeRule.model";
import { ApplicationUnits } from "@shared/models/applicationUnits.model";
import { ApplicationUnitsDataStoreService } from "@shared/services/dataStore/applicationUnitsDataStore.service";
import { MeasurementData, MeterPoint } from "@shared/models";
import { VeeStatusDataStoreService } from "@shared/services/dataStore/veeProcessDataStore.service";
import { VeeStatus } from "@shared/models/appModels/VeeStatus.model";
import { VeeProcessStageEnum } from "@shared/types/modelTypes/veeProcessTypes";
import { MeasurementsPreviewDialogsService } from "../../../../upload/measurements/measurements-preview/service/measurements-preview-dialogs.service";
import { typesLocalisation } from "@shared/types/localisation";
import { Observable, Subscription } from "rxjs";
import { concatMap, map, mergeMap, finalize } from "rxjs/operators";
import { of } from "rxjs";
import { MatTableDataSource } from "@angular/material/table";
import { MeasurementDataStoreService } from "@shared/services/dataStore/measurementDataDataStore.service";
import { _countGroupLabelsBeforeOption } from "@angular/material/core";
import {
  ValidateMeasurement,
  IValidateMeasurement,
} from "@shared/models/appModels/validateMeasurement.mode";
import { VeeRuleTypeEnum } from "@shared/types/modelTypes/veeRuleTypes";
import { DecimalPipe } from "@angular/common";
import { DataValidationInteractiveTutorialService } from "app/components/data-validation/data-validation-interactive-tutorial-steps";
import { InteractiveTutorialService } from "@shared/services/interactiveTutorialService.service";
import { DataValidationTranslationsHelper } from "../../service/data-validation-translations-helper.service";
import { MatInput } from "@angular/material/input";
import { LocaleService } from "@shared/services/localeService.service";
import { UploadLocalisation as loc } from "app/components/upload/upload.localisation";

export interface CheckMeasurement {
  check: boolean;
  measurementData: MeasurementData;
}

export enum ValidationDetailColumn {
  ENERGY = "energy",
  VOLUME = "volume",
  VALIDATION_STATUS = "validation status",
  USAGE = "usage",
  ACTIONS = "actions",
  DATE = "date",
  SELECT = "select",
}

enum FilterValue {
  SHOWALL = "SHOWALL",
  VALID = "VALID",
  INVALID = "INVALID",
  AUTOCORRECTION = "AUTOCORRECTION",
  MANUALCORRECTION = "MANUALCORRECTION",
}

@Component({
  selector: "sv-detail",
  templateUrl: "detail.component.html",
  styleUrls: ["detail.component.sass"],
})
export class DetailComponent
  implements OnInit, AfterViewInit, TableHost<any>, OnDestroy {
  utilityTypeSubs$: Subscription;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns: string[] = [
    ValidationDetailColumn.DATE,
    ValidationDetailColumn.ENERGY,
    ValidationDetailColumn.VALIDATION_STATUS,
    ValidationDetailColumn.USAGE,
    ValidationDetailColumn.ACTIONS,
    ValidationDetailColumn.SELECT,
  ];
  dataSource: MatTableDataSource<MeasurementData> = new MatTableDataSource();
  measurementData: MeasurementData[] = [];
  allDataCount: number = 0;
  selectAll;
  correctedValue: string = "";
  noReading: string = $localize`:@@data validation/detail/no reading:No reading`;

  /* Chart style configuration */
  chartData: MeterData[] = [];
  lineChartColors: any[];
  plotLegend: boolean = false;
  pointStyle1: string[] = [];
  pointRadius1: number[] = [];
  pointBackgroundColor1: string[] = [];
  pointBorderColor1: string[] = [];
  graphDataAvailable: boolean = false;
  chartOptions = {};

  private minimalLength = 4;
  private plotPoints = 10;

  actialDisplayKey: string;
  units: ApplicationUnits;

  DEBUG_MODE: boolean;

  ValidationResultEnum: typeof ValidationResultEnum = ValidationResultEnum;
  MeterDataOriginEnum: typeof MeterDataOriginEnum = MeterDataOriginEnum;
  VeeStatusEnum: typeof VeeStatusEnum = VeeStatusEnum;

  plottable: boolean = false;

  dataFetched: boolean = false;
  dataLoading: boolean = true;
  dataLength: number = 0;
  checkedInvalidsThisMeterPoint: Array<any> = new Array();
  listRulesValidation: IVeeRule[] = [];
  selectedFilter;
  filterValues = [
    FilterValue.SHOWALL,
    FilterValue.VALID,
    FilterValue.INVALID,
    FilterValue.AUTOCORRECTION,
    FilterValue.MANUALCORRECTION,
  ];
  decimalPipeFormat = "1.0-3";
  @Output() noDataInDetailsTable = new EventEmitter<boolean>();
  @Output() allInvalidReadingsUnchecked = new EventEmitter<boolean>();
  @Output() detailCheckedInvalidsReadings = new EventEmitter<
    CheckMeasurement[]
  >();
  @Output() refreshCounters = new EventEmitter<boolean>();
  @Output() measurementInputClickedValue = new EventEmitter<{
    key: string;
    value: number;
  }>();
  @Output() responseCheckboxesValues = new EventEmitter<CheckMeasurement[]>();
  askForCheckboxes$: Subscription;
  properApiCall$: Subscription;
  private _appUnits;
  @Input()
  get appUnits(): any {
    return this._appUnits;
  }
  set appUnits(appUnits: any) {
    this._appUnits = appUnits;
    this.displayedColumns[1] = this._appUnits.units[0].name;
  }
  private _detail: MeasurementDataTabbed;
  @Input()
  get detail(): MeasurementDataTabbed {
    return this._detail;
  }
  set detail(detail: MeasurementDataTabbed) {
    this._detail = detail;
  }
  private _filterObj;
  @Input()
  get filterObj(): any {
    return this._filterObj;
  }
  set filterObj(obj: any) {
    if (JSON.stringify(this._filterObj) !== JSON.stringify(obj)) {
      this._filterObj = obj;
      this.dataLoading = true;
      this.changeValidationResultFilter(obj);
    }
  }

  private _currentCheckboxesState;
  @Input()
  get currentCheckboxesState(): any {
    return this._currentCheckboxesState;
  }
  set currentCheckboxesState(currChbxSt: any) {
    this._currentCheckboxesState = currChbxSt;
    // Current Checkboxes state from invalid filter sent from data-validation-summary component
    this.selectAll = currChbxSt;
    if (currChbxSt) {
      this.checkedInvalidsThisMeterPoint.forEach((elem) => {
        elem.check = true;
      });
      this.allInvalidReadingsUnchecked.emit(false);
    } else {
      this.checkedInvalidsThisMeterPoint.forEach((elem) => {
        elem.check = false;
      });
      this.allInvalidReadingsUnchecked.emit(true);
    }
  }
  private _checkedInvalid;
  @Input()
  get checkedInvalid(): any {
    return this._checkedInvalid;
  }
  set checkedInvalid(checkedInv: any) {
    this._checkedInvalid = checkedInv;
    const checked = checkedInv.find((elem) => {
      return elem.check === true;
    });
    if (typeof checked === "undefined") {
      this.allInvalidReadingsUnchecked.emit(true);
    } else {
      this.allInvalidReadingsUnchecked.emit(false);
    }
    // Checked Invalids filter sent from data-validation-summary component
    this.checkedInvalidsThisMeterPoint = checkedInv.filter((elem) => {
      if (
        this.detail.idMeterPoint === elem.measurementData.idMeterPoint &&
        this.detail.idPosition === elem.measurementData.idPosition
      ) {
        return elem;
      }
    });
  }
  private _positionFrozen;
  @Input()
  get positionFrozen(): any {
    return this._positionFrozen;
  }
  set positionFrozen(posFrozObj: any) {
    if (!posFrozObj.firstValue) {
      this._positionFrozen = posFrozObj.frozen;
    }
  }
  private _askForCheckboxesValues: Observable<boolean>;
  @Input()
  get askForCheckboxesValues(): Observable<boolean> {
    return this._askForCheckboxesValues;
  }
  set askForCheckboxesValues(askForCheckBoxVal: Observable<boolean>) {
    this._askForCheckboxesValues = askForCheckBoxVal;
    this.askForCheckboxes$ = this._askForCheckboxesValues.subscribe((val) => {
      if (val) {
        this.responseCheckboxesValues.emit(this.checkedInvalidsThisMeterPoint);
      }
    });
  }

  public get ValidationDetailColumn() {
    return ValidationDetailColumn;
  }

  /* Labeling texts */
  readonly itemsPage = typesLocalisation.ItemsPage[environment.language].texts;
  veeStatus: VeeStatus;
  tutorialFunc;

  constructor(
    private positionDataStoreService: PositionDataStoreService,
    private communicationService: CommonAppDataService,
    private veeRules: VeeRulesDataStoreService,
    private unitsService: ApplicationUnitsDataStoreService,
    private veeStatusService: VeeStatusDataStoreService,
    private mesDialog: MeasurementsPreviewDialogsService,
    private dataValidationTranslationsHelper: DataValidationTranslationsHelper,
    private cdr: ChangeDetectorRef,
    private dialogsService: MeasurementsPreviewDialogsService,
    private measurementService: MeasurementDataStoreService,
    private veeRuleService: VeeRulesDataStoreService,
    private cs: CommonAppDataService,
    private veeProcessService: VeeStatusDataStoreService,
    private decimalPipe: DecimalPipe,
    private dataValidationInterTutServ: DataValidationInteractiveTutorialService,
    private interTutServ: InteractiveTutorialService,
    private localeService: LocaleService
  ) {
    this.DEBUG_MODE = environment.debug;
    this.unitsService
      .getApplicationUnits(this.communicationService.getCurrentMediaType())
      .subscribe((units: ApplicationUnits) => {
        this.units = units;
        this.actialDisplayKey = this.units.units[0].name;
      });
  }
  onPaginatorEvents(paginator: MatPaginator): Observable<any[]> {
    return of(['Method not implemented']);
  }
  onExternalDataChangeEvent(
    changeInductors: Observable<any>[]
  ): Observable<any[]> {
    throw new Error("Method not implemented.");
  }
  /* No filters section */
  onFilterEvents(): Observable<any[]> {
    throw new Error("Method not implemented.");
  }
  viewElement(element: MeasurementData): void {
    if (element?.idValidationRule) {
      this.getValidationRuleForEntry(element.idValidationRule).subscribe(veeRule => {
        this.dialogsService
          .openViewModal([element, veeRule], "See measurement details")
          .subscribe((res: MeasurementData) => { });
      });
    } else {
      this.dialogsService
        .openViewModal([element, undefined], "See measurement details")
        .subscribe((res: MeasurementData) => { });
    }
  }

  iterateThroughObjectProperties(obj) {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      }
    }
  }

  changeValidationResultFilter(obj: any) {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        if (typeof obj[prop] === "boolean") {
          if (obj[prop]) {
            this.selectedFilter = this.filterValues.find(
              (el) => el === prop.toUpperCase()
            );
            // Another option than INVALID selected, so delete select column
            const index = this.displayedColumns.indexOf(
              ValidationDetailColumn.SELECT
            );
            if (index !== -1) {
              this.displayedColumns.splice(index, 1);
            }
          }
        } else {
          if (obj[prop].check) {
            if (
              !this.displayedColumns.includes(ValidationDetailColumn.SELECT)
            ) {
              this.displayedColumns.push(ValidationDetailColumn.SELECT);
            }
            this.selectedFilter = this.filterValues.find(
              (el) => el === prop.toUpperCase()
            );
          } else {
            // Another option than INVALID selected, so delete select column
            const index = this.displayedColumns.indexOf(
              ValidationDetailColumn.SELECT
            );
            if (index !== -1) {
              this.displayedColumns.splice(index, 1);
            }
          }
        }
      }
    }
    this.properApiCall$ = this.makeProperApiCall(
      this.selectedFilter
    ).subscribe((data) => {
      if (this.selectedFilter === FilterValue.INVALID) {
        if (this.filterObj.invalid.rules.length > 0) {
          if (
            data.length === 0 &&
            this.checkedInvalidsThisMeterPoint.length !== 0
          ) {
            //this.selectAll = false;
            // this.checkedInvalidsThisMeterPoint.forEach((elem) => {
            //   elem.check = false;
            // });
            // this.detailCheckedInvalidsReadings.emit(
            //   this.checkedInvalidsThisMeterPoint
            // );
          } else {
            //this.selectAll = true;
            // this.checkedInvalidsThisMeterPoint.forEach((elem) => {
            //   elem.check = true;
            // });
          }
        }
      }
      if (data.length === 0) {
        this.noDataInDetailsTable.emit(true);
      }
      this.loadPlotFunc();
    });
  }

  makeProperApiCall(filterValue: string): Observable<MeasurementData[]> {
    const limit = this.paginator.pageSize;
    const offset = this.paginator.pageIndex * this.paginator.pageSize;
    if (filterValue === FilterValue.SHOWALL) {
      return this.loadData(limit, offset);
    } else if (filterValue === FilterValue.INVALID) {
      if (this._filterObj.invalid.rules.length > 0) {
        return this.loadData(limit, offset, {
          flagValue: filterValue,
          rule: this._filterObj.invalid.rules,
        });
      } else {
        return this.loadData(limit, offset, { flagValue: filterValue });
      }
    } else {
      return this.loadData(limit, offset, { flagValue: filterValue });
    }
  }
  ngOnInit(): void {
    this.chartOptions = {
      scales: {
        xAxes: [
          {
            type: "time",
            time: {
              displayFormats: {
                day: "DD.MM",
              },
              unit: "day",
            },
          },
        ],
      },
    };
    this.veeRuleService
      .getVeeRules()
      .pipe(
        map<VeeRule[], VeeRule[]>((rules) => {
          return rules.sort((a, b) => a.priority - b.priority);
        })
      )
      .subscribe(
        (veeRules: VeeRule[]) => {
          const list = veeRules
            .slice(0)
            .filter(
              (v) =>
                v.media_type ===
                UtilityTypeEnum[this.cs.getCurrentMediaType()] ||
                v.media_type === UtilityTypeEnum[UtilityTypeEnum.OTHER]
            );
          this.listRulesValidation = list.filter(
            (v) => v.rule_type === VeeRuleTypeEnum[VeeRuleTypeEnum.VALIDATION]
          );
        },
        (e) => {
          console.error("Unable to fetch rules");
          console.error(e);
          this.listRulesValidation =
            this.listRulesValidation.length === 0
              ? []
              : this.listRulesValidation;
        },
        () => {
          this.tutorialFunc = setTimeout(() => {
            this.startInteractiveTutorial();
          }, 1500);
        }
      );
    this.currentCheckboxesState = this.currentCheckboxesState;
  }

  private startInteractiveTutorial() {
    const steps = this.dataValidationInterTutServ.getDataValidationDetailsInteractiveTutorialSteps(
      this.plottable,
      this.detail.idMeterPoint
    );
    this.interTutServ.startInteractiveTutorial(steps);
  }

  loadPlotFunc() {
    this.loadPlot().subscribe(
      (data: MeterData[]) => {
        this.graphStylization(data);
      },
      (err: any) => {
        console.error(err);
        throw new Error(err);
      },
      () => {
        this.setupGraphStyles();
        this.graphDataAvailable = true;
        this.paginator.firstPage();
        this.cdr.detectChanges();
      }
    );
  }

  prepareEnergyOrVolumeValueFromInput(correctedValue: string): string {
    const lang = this.localeService.getLanguage();
    let value;
    switch (lang) {
      case "pl-PL":
        value = correctedValue.replace(/,(?=[^,]*$)/, '.');
        break;
      case "de-DE":
        value = correctedValue.replace(/\./g, '').replace(/,(?=[^,]*$)/, '.');
        break;
      default:
        value = correctedValue.replace(/,/g, '');
        break;
    }
    return value;
  }

  onSaveValue(element, control: MatInput) {
    let oldValue;
    const correctedValue = control.value.split(/\s/g).join('');
    const value = this.prepareEnergyOrVolumeValueFromInput(correctedValue);
    const elem = element;
    if (elem.measurementValues?.energy) {
      oldValue = elem.measurementValues.energy;
      elem.measurementValues.energy = Number(value).toString();
    } else if (elem.measurementValues?.volume) {
      oldValue = elem.measurementValues.volume;
      elem.measurementValues.volume = Number(value).toString();
    }
    elem.measurementOrigin = MeasurementOriginEnum.EDITED;
    elem.validationResult = ValidationResultEnum.MANUALCORRECTION;
    let measurementDataArray: MeasurementData[] = new Array<MeasurementData>();
    measurementDataArray.push(elem);
    /* FIXME: use optimistic update instead of whole set refresh */
    if (elem.measurementValues?.energy !== 'NaN') {
      this.measurementService
        .updateMeasurementData(measurementDataArray, false, { emitEvent: true })
        .pipe(
          concatMap<MeasurementData[], Observable<boolean>>((m) => {
            let ids = [];
            const list = this.listRulesValidation.filter((r) => r.enabled);
            list.forEach((element) => {
              ids.push(element.id);
            });
            this.refreshCounters.emit(true);
            const validateMeasurement: IValidateMeasurement = {
              position_id: this.detail["idPosition"],
              meter_point_id: this.detail.idMeterPoint.toString(),
              validation_rule_ids: ids
            };
            this.changeValidationResultFilter(this.filterObj);
            return this.veeProcessService
              .postValidationStateForPosition(
                new ValidateMeasurement(validateMeasurement)
              )
              .pipe(
                concatMap<ValidateMeasurement, Observable<boolean>>((v) => {
                  return this.positionDataStoreService
                    .getPositionMeasurementDataCount(this.detail["idPosition"], {
                      validationResult: ValidationResultEnum.INVALID,
                    })
                    .pipe(map((cnt) => cnt === 0));
                })
              );
          })
        ) /*allValid: boolean */
        .subscribe((allValid: boolean) => {
          this.loadPlotFunc();
        });
    } else {
      if (elem.measurementValues?.energy) {
        elem.measurementValues.energy = oldValue;
      } else if (elem.measurementValues?.volume) {
        elem.measurementValues.volume = oldValue;
      }
      control.value = this.getEnergyOrVolumeValue(elem);
      this.cdr.detectChanges();
    }
  }

  onMeasureInputClick(event, element, control: MatInput) {
    this.correctedValue = event.target.value.split(/\s/g).join("");
    const value = this.prepareEnergyOrVolumeValueFromInput(this.correctedValue);
    this.measurementInputClickedValue.emit({
      key: element.idMeasurementData,
      value: +value,
    });
  }

  onInputClick(event, element, control: MatInput) {
    event.stopPropagation();
    const correctedValue = control.value.split(/\s/g).join("");
    const value = this.prepareEnergyOrVolumeValueFromInput(correctedValue);
    control.value = value
      ? value
      : "";
  }

  getEnergyOrVolumeValue(element) {
    if (element.measurementValues?.energy) {
      return this.decimalPipe.transform(
        +element.measurementValues?.energy,
        this.decimalPipeFormat
      );
    } else if (element.measurementValues?.volume) {
      return this.decimalPipe.transform(
        +element.measurementValues?.volume,
        this.decimalPipeFormat
      );
    } else {
      return this.noReading;
    }
  }

  getUsageValue(element) {
    if (element.meterDataRef?.usageValues?.energy) {
      return this.decimalPipe.transform(
        element.meterDataRef?.usageValues?.energy,
        this.decimalPipeFormat
      );
    } else if (element.meterDataRef?.usageValues?.volume) {
      return this.decimalPipe.transform(
        element.meterDataRef?.usageValues?.volume,
        this.decimalPipeFormat
      );
    } else {
      return this.noReading;
    }
  }

  loadPlot() {
    return this.veeStatusService
      .getVeeStateForPosition(this.detail["idPosition"], true)
      .pipe(
        map((vs) => {
          this.veeStatus = vs;
          return vs;
        }),
        concatMap<VeeStatus, Observable<number>>((vs) => {
          return this.positionDataStoreService.getPositionMeasurementDataCount(
            this.detail["idPosition"],
            { idMeterPoint: this.detail.idMeterPoint }
          );
        }),
        concatMap<number, Observable<MeterData[]>>((count: number) => {
          return this.loadPlotData(count);
        })
      );
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    if (this.askForCheckboxes$) {
      this.askForCheckboxes$.unsubscribe();
    }
    if (this.properApiCall$) {
      this.properApiCall$.unsubscribe();
    }
    clearTimeout(this.tutorialFunc);
  }

  getColumnName(column: string) {
    if (this.displayedColumns.includes(column.toLowerCase())) {
      switch (column) {
        case ValidationDetailColumn.ENERGY: {
          return $localize`:@@data validation/detail/energy column: Energy`;
        }
        case ValidationDetailColumn.VOLUME: {
          return $localize`:@@export/export table header volume: Volume`;
        }
        case ValidationDetailColumn.VALIDATION_STATUS: {
          return $localize`:@@data validation/detail/validation status column: Validation Status`;
        }
        case ValidationDetailColumn.USAGE: {
          return $localize`:@@data validation/detail/usage column: Usage`;
        }
        case ValidationDetailColumn.ACTIONS: {
          return $localize`:@@data validation/detail/actions column: Actions`;
        }
        case ValidationDetailColumn.DATE: {
          return $localize`:@@data validation/detail/date column: Date`;
        }
      }
    }
  }

  getValidationStatusLabel(status: string) {
    switch (status) {
      case ValidationResultEnum[ValidationResultEnum.VALID]: {
        return $localize`:@@data validation/validation summary/valid: Valid`;
      }
      case ValidationResultEnum[ValidationResultEnum.INVALID]: {
        return $localize`:@@data validation/validation summary/invalid: Invalid`;
      }
      case ValidationResultEnum[ValidationResultEnum.AUTOCORRECTION]: {
        return $localize`:@@data validation/validation summary/auto correction: Auto Correction`;
      }
      case ValidationResultEnum[ValidationResultEnum.MANUALCORRECTION]: {
        return $localize`:@@data validation/validation summary/manual correction: Manual Correction`;
      }
      case ValidationResultEnum[ValidationResultEnum.UNDEFINED]: {
        return $localize`:@@data validation/validation summary/undefined: Undefined`;
      }
    }
  }

  getValidationStatus(status: number): string {
    return ValidationResultEnum[status];
  }

  getValidationStatusFlag(status: number) {
    if (typeof status !== "undefined") {
      return getValidationResult(status).classType;
    }
  }

  getDataLength() {
    return this.dataSource.data.length;
  }

  changeChecboxValue(element: MeasurementData) {
    this.checkedInvalidsThisMeterPoint.forEach((elem) => {
      if (
        elem.measurementData.idMeasurementData === element.idMeasurementData
      ) {
        elem.check = !elem.check;
      }
    });
    this.detailCheckedInvalidsReadings.emit(this.checkedInvalidsThisMeterPoint);
  }

  getDataSourceDataLength() {
    return this.dataSource.data.length;
  }

  graphStylization(data: MeterData[]) {
    if (this.veeStatus.stage === VeeProcessStageEnum.FREEZE) {
      this.chartData = data
        .slice(0)
        .sort((a: MeterData, b: MeterData) => b.timestamp - a.timestamp);
    } else {
      this.chartData = data.slice(0);
    }
    if (this.chartData.length >= this.minimalLength) {
      this.plottable = true;
      this.chartData.reverse();
      this.styleUpPlot(this.chartData);
    } else {
      this.plottable = false;
    }
  }

  loadData(
    limit?: number,
    offset?: number,
    otherParams?: { flagValue: string; rule?: number[] }
  ): Observable<MeasurementData[]> {
    let countObservable;
    if (typeof otherParams !== "undefined") {
      countObservable = this.positionDataStoreService.getPositionMeasurementDataCount(
        this.detail["idPosition"],
        {
          idMeterPoint: this.detail.idMeterPoint,
          validationResult: otherParams.flagValue,
        }
      );
    } else {
      countObservable = this.positionDataStoreService.getPositionMeasurementDataCount(
        this.detail["idPosition"],
        { idMeterPoint: this.detail.idMeterPoint }
      );
    }
    return countObservable.pipe(
      mergeMap<number, Observable<MeasurementData[]>>((count) => {
        this.allDataCount = count;
        if (typeof otherParams !== "undefined") {
          return this.positionDataStoreService.getPositionMeasurementData(
            this.detail["idPosition"],
            { limit: count, offset: offset ? offset : 0},
            true,
            {
              idMeterPoint: this.detail.idMeterPoint,
              validationResult: otherParams.flagValue,
            }
          );
        } else {
          return this.positionDataStoreService.getPositionMeasurementData(
            this.detail["idPosition"],
            { limit: count, offset: offset ? offset : 0},
            true,
            { idMeterPoint: this.detail.idMeterPoint }
          );
        }
      }),
      concatMap<MeasurementData[], Observable<MeterData[]>>((data) => {
        if (typeof otherParams !== "undefined" && otherParams?.rule) {
          const measurementDataArray = data.filter(
            (k) => otherParams.rule.indexOf(k.idValidationRule) !== -1
            );
          this.measurementData = measurementDataArray;
          this.dataSource.data = this.measurementData;
          this.paginator.length = this.dataSource.data.length;
          this.dataSource.paginator = this.paginator;
        } else {
          this.measurementData = data;
          this.dataSource.data = this.measurementData;
          this.paginator.length = this.dataSource.data.length;
          this.dataSource.paginator = this.paginator;
        }
        return this.positionDataStoreService.getPositionMeterDataForSingleMeterPoint(
          this.detail["idPosition"],
          this.detail.idMeterPoint,
          { limit: this.allDataCount, offset: offset ? offset : 0},
          {
            sort: 'DESC'
          }
        );
      }),
      concatMap<MeterData[], Observable<MeasurementData[]>>((resp) => {
        for (let i = 0; i < resp.length; i++){
          for (let j = 0; j < this.measurementData.length; j++) {
            if (this.measurementData[j].idMeasurementData === resp[i].idMeterData) {
              this.measurementData[j]["meterDataRef"] = resp[i];
              this.measurementData[j]["meterPointRef"] = <MeterPoint>(
                (<unknown>this.detail)
              );
          }
        }
      }
      return of(this.measurementData);
    }), finalize(() => this.dataLoading = false)
    );
  }

  setEvent(limit?: number, offset?: number): Observable<any[]> {
    if (limit === undefined || offset === undefined) {
      limit = this.paginator.pageSize;
      offset = this.paginator.pageIndex * limit;
    }
    return this.loadData(limit, offset);
  }

  private loadPlotData(dataAmount: number): Observable<MeterData[]> {
    /* FIXME: data shall be fetched not only for single position, but whole history - no API support */
    return this.positionDataStoreService
      .getPositionMeterDataForSingleMeterPoint(
        this.detail["idPosition"],
        this.detail.idMeterPoint,
        { limit: dataAmount, offset: 0 },
        { sort: "DESC" }
      )
      .pipe(
        concatMap<MeterData[], Observable<MeterData[]>>(
          (mdPlot: MeterData[]) => {
            /* FIXME: data shall be fetched not only for single position, but whole history - no API support */
            return this.positionDataStoreService
              .getPositionMeasurementsForSingleMeterPoint(
                this.detail["idPosition"],
                this.detail.idMeterPoint,
                { limit: dataAmount, offset: 0 },
                { sort: "DESC" },
                false
              )
              .pipe(
                map((measuremnets: MeasurementData[]) => {
                  if (measuremnets.length === mdPlot.length) {
                    mdPlot = mdPlot.map((md: MeterData, idx) => {
                      md.measurmeentDataRef = measuremnets[idx];
                      return md;
                    });
                  } else {
                    throw new Error(
                      "Too few measurement points to draw a graph"
                    );
                  }
                  return mdPlot;
                })
              );
          }
        )
      );
  }

  private styleUpPlot(plotData: MeterData[]) {
    for (let i = 0; i < plotData.length; ++i) {
      if (
        plotData[i].measurmeentDataRef.validationResult ===
        ValidationResultEnum.INVALID
      ) {
        this.pointStyle1[i] = "rect";
        this.pointRadius1[i] = 4;
        this.pointBackgroundColor1[i] = "rgba(255, 0, 0)";
        this.pointBorderColor1[i] = "rgba(255, 0, 0)";
      } else if (
        plotData[i].measurmeentDataRef.validationResult ===
        ValidationResultEnum.AUTOCORRECTION
      ) {
        this.pointStyle1[i] = "rect";
        this.pointRadius1[i] = 4;
        this.pointBackgroundColor1[i] = "rgba(240, 104, 40)";
        this.pointBorderColor1[i] = "rgba(240, 104, 40)";
      } else if (
        plotData[i].measurmeentDataRef.validationResult ===
        ValidationResultEnum.MANUALCORRECTION
      ) {
        this.pointStyle1[i] = "rect";
        this.pointRadius1[i] = 4;
        this.pointBackgroundColor1[i] = "rgba(187, 187, 187)";
        this.pointBorderColor1[i] = "rgba(187, 187, 187)";
      } else {
        this.pointStyle1[i] = "circle";
        this.pointRadius1[i] = 2;
        this.pointBackgroundColor1[i] = "rgba(0, 199, 159)";
        this.pointBorderColor1[i] = "rgba(0, 199, 159)";
      }
      if (plotData[i].timestamp === this.detail.timestamp) {
        this.pointRadius1[i] = 5;
      }
      if (plotData[i].usageValues[this.actialDisplayKey] === null) {
        plotData[i].usageValues[this.actialDisplayKey] = 0;
      }
    }
  }

  private setupGraphStyles() {
    this.lineChartColors = [
      {
        pointStyle: this.pointStyle1,
        pointRadius: this.pointRadius1,
        pointHoverRadius: 6,
        pointBackgroundColor: this.pointBackgroundColor1,
        pointBorderColor: this.pointBorderColor1,
        borderColor: "rgba(0, 199, 159)",
        backgroundColor: "transparent",
      },
    ];
  }

  ngAfterViewInit(): void {
    this.paginator._intl.itemsPerPageLabel = $localize`:items per page label|A label used in a widget responsible
    for splitting records in a table into multiple pages@@paging/items per page:Items per page`;
    this.paginator._intl.nextPageLabel = $localize`:next page tooltip|A tooltip for a paging widget button@@paging/next page:Next page`;
    this.paginator._intl.previousPageLabel = $localize`:previous page tooltip|A tooltip for a paging widget button@@paging/previous page:Previous page`;
  }

  onClearFilters(): Observable<any[]> {
    throw new Error("Method not implemented.");
  }

  getDataSourceLength(): number {
    return this.dataLength;
  }

  isLoading(): boolean {
    return false;
  }

  onTabUnitChange($event: MatTabChangeEvent) {
    this.actialDisplayKey = this.units.units[$event.index].name;
    this.graphDataAvailable = false;
    setTimeout(() => {
      this.graphDataAvailable = true;
    }, 20);
  }

  isDataAvailable(): boolean {
    if (this.graphDataAvailable) {
      return true;
    }
  }

  getValidationRuleForEntry(idValidationRule: number): Observable<VeeRule> {
    return this.veeRules.getVeeRuleById(idValidationRule);
  }

  getEstimationRuleForEntry(): Observable<VeeRule> {
    return this.veeRules.getVeeRuleById(this.detail.idEstimationRule);
  }

  selectUnselectAll() {
    this.selectAll = !this.selectAll;
    if (this.selectAll) {
      this.checkedInvalidsThisMeterPoint.forEach((elem) => {
        elem.check = true;
      });
      this.detailCheckedInvalidsReadings.emit(
        this.checkedInvalidsThisMeterPoint
      );
    } else {
      this.checkedInvalidsThisMeterPoint.forEach((elem) => {
        elem.check = false;
      });
      this.detailCheckedInvalidsReadings.emit(
        this.checkedInvalidsThisMeterPoint
      );
    }
  }

  isThisChecked(element: MeasurementData): boolean {
    const obj = this.checkedInvalidsThisMeterPoint.find(
      (elem) =>
        element.idMeasurementData === elem.measurementData.idMeasurementData
    );
    if (typeof obj !== "undefined") {
      return obj.check;
    } else {
      return true;
    }
  }
}
