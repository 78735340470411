import { Component, OnInit, Inject, AfterViewInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonAppDataService } from '@shared/services/commonAppData.service';
import { UtilityTypeEnum } from '@shared/types';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IMeasurementDataModel, MeasurementDataModel } from '@shared/models/appModels/measurementDataModel.model';
import { environment } from '@env/environment';
import { typesLocalisation } from '@shared/types/localisation';
import { InteractiveTutorialService } from '@shared/services/interactiveTutorialService.service';
import { SettingsInteractiveTutorialService } from '../../settings-interactive-tutorial.service';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'dataModel-variable-create',
    templateUrl: 'dataModel-variable-create.component.html',
    styleUrls: ['dataModel-variable-create.component.sass']
})

export class DataModelCreateVariableComponent implements OnInit, AfterViewInit, OnDestroy {

    newVariableForm: FormGroup;
    occupiedNames: string[] = [];
    occupiedParsedNames: string[] = [];
    private utilityType: UtilityTypeEnum;
    readonly dataModelTxt = typesLocalisation.DataModelTexts[environment.language].texts;
    showTutFunc;

    constructor( private fb: FormBuilder,
        private cs: CommonAppDataService,
        @Inject(MAT_DIALOG_DATA) private data1: { measurementDefData: MeasurementDataModel[] },
        public dialogRef: MatDialogRef<DataModelCreateVariableComponent>, 
        private interactiveTutorialService: InteractiveTutorialService,
        private settingsInteractiveTutorialService: SettingsInteractiveTutorialService) {}

    ngOnInit() {
        this.newVariableForm = this.fb.group({
            'name': new FormControl(null, [Validators.required, this.occupiedName.bind(this)]),
            'name_parsed': new FormControl(null, [Validators.required, this.occupiedParsedName.bind(this)]),
            'description': new FormControl(null, [Validators.required]),
            'unit': new FormControl(null),
            'type': new FormControl('DOUBLE'),
            'is_primary': new FormControl(false),
          });
          this.utilityType = this.cs.getCurrentMediaType();
          for (const element of this.data1.measurementDefData) {
            this.occupiedNames.push(element.name);
            this.occupiedParsedNames.push(element.parsedName);
          }
    }

    startInteractiveTutorial(){
        const steps = this.settingsInteractiveTutorialService.getSettingsDataModelNewVariableModalInteractiveTutorialSteps();
        this.interactiveTutorialService.startInteractiveTutorial(steps);
    }

    ngAfterViewInit(){
        this.showTutFunc = setTimeout(()=>this.startInteractiveTutorial(),1500);
    }

    ngOnDestroy(): void {
        clearTimeout(this.showTutFunc);
    }

    onSubmit() {
        const obj: IMeasurementDataModel = {
            utilityType: UtilityTypeEnum[this.utilityType],
            name: this.newVariableForm.get('name').value,
            parsedName: this.newVariableForm.get('name_parsed').value,
            type: this.newVariableForm.get('type').value,
            description: this.newVariableForm.get('description').value,
            unit: this.newVariableForm.get('unit').value,
            isBilled: false,
            isCumulative: false,
            isPrimary: this.newVariableForm.get('is_primary').value,
            isMetadata: false
        };
        this.dialogRef.close(obj);
    }

    occupiedName(control: FormControl): { [s: string]: boolean } {
        if (this.occupiedNames.indexOf(control.value) !== -1) {
            return { occupiedName: true };
        }
    }

    occupiedParsedName(control: FormControl): { [s: string]: boolean } {
        if (this.occupiedParsedNames.indexOf(control.value) !== -1) {
            return { occupiedParsedName: true };
        }
    }
}
