<div class="card">
	<mat-table #table [dataSource]="dataSource">

		<!-- FIX ASYNC -->
		<ng-container matColumnDef="schedule">
			<mat-header-cell *matHeaderCellDef>{{tableHeaders.SCHEDULE}}</mat-header-cell>
			<mat-cell *matCellDef="let element">{{getNameSchedule(element)}}</mat-cell>
		</ng-container>
		<ng-container matColumnDef="position">
			<mat-header-cell *matHeaderCellDef>{{tableHeaders.POSITION}}</mat-header-cell>
			<mat-cell *matCellDef="let element">{{element.name}}</mat-cell>
		</ng-container>

		<ng-container *ngIf="automaticScheduler" matColumnDef="import">
			<mat-header-cell *matHeaderCellDef>{{importType}}</mat-header-cell>
			<mat-cell *matCellDef="let element">
				<div *ngIf="element.activeScheduler">
					{{automatic}}
				</div>
				<div *ngIf="!element.activeScheduler">
					{{user}}
				</div>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="action">
			<mat-header-cell *matHeaderCellDef class="text-right">{{tableHeaders.ACTIONS}}</mat-header-cell>
			<mat-cell *matCellDef="let row" class="text-right">
				<i class="material-icons notranslate">{{ isThisDetailElement(row) ? hideDetailsMdIcon : seeDetailsMdIcon }}</i>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="expandedDetail">
			<mat-cell *matCellDef="let element" class="detail-data">
				<div id="positionInfo{{element.rowId}}" class="data-text-box smaller-screens-btns-measurement-preview">
					<span class="data-text-inline"><b class="notranslate">{{allCount}}</b>
						{{uploadCountersLabels.TOTAL}}</span>
					<span class="data-text-inline"><b class="notranslate">{{anomalyCount}}</b>
						{{uploadCountersLabels.INVALID}} </span>
					<span class="data-text-inline"><b>{{getCountFile(element)}}</b> {{uploadCountersLabels.FILES}}
					</span>
				</div>

				<div class="smaller-screens-btns-measurement-preview" [formGroup]="newFormGroup">
					<!-- <mat-slide-toggle id="automaticSched{{element.rowId}}" *ngIf="freezePosition && automaticScheduler" formControlName="activeScheduler"
						(change)="offPositionsSchedulers(element,$event)">
						{{activeScheduler}}
					</mat-slide-toggle> -->

					<button id="reloadBtn{{element.rowId}}" (click)="onReloadFile(element)" mat-raised-button color="primary" class="btn" disabled=true>
						{{detailButtonsLabels.RELOAD}} </button>

					<button id="previewDataBtn{{element.rowId}}" (click)="onShowPreview(element)" mat-raised-button color="primary"
						class="btn">{{detailButtonsLabels.PREVIEW}} </button>

					<button id="previewFileBtn{{element.rowId}}" (click)="onShowFiles(element)" mat-raised-button color="primary" class="btn">
						{{detailButtonsLabels.FILE}} </button>

					<button id="startValidationBtn{{element.rowId}}" (click)="onStartValidation(element)" mat-raised-button color="accent" class="btn">
						{{detailButtonsLabels.VALIDATION}} </button>
				</div>

			</mat-cell>
		</ng-container>

		<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
		<mat-row *matRowDef="let row; columns: displayedColumns" matRipple class="element-row"
			[class.expanded]="isThisRowExpanded(row)" (click)="handleMainRowClick(row)" (click)="isActiv(row)">
		</mat-row>
		<mat-row *matRowDef="let row; columns: ['expandedDetail']; when: rowCanBeExpanded"
			[@detailExpand]="isThisRowExpanded(row) ? 'expanded' : 'collapsed' ">
		</mat-row>
	</mat-table>

	<div class="no-result" *ngIf="!isLoading() && getDataSourceLength() === 0">
		{{noData}}
	</div>

	<div class="no-result" *ngIf="isLoading()">
		<mat-spinner [strokeWidth]="3" [diameter]="20" class="notranslate"></mat-spinner>
	</div>

	<mat-paginator #paginator [length]="getDataSourceLength()" [pageIndex]="0" [pageSize]="10"
		[pageSizeOptions]="[5, 10, 20]" showFirstLastButtons class="notranslate">
	</mat-paginator>
</div>