import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartsModule } from 'ng2-charts';
import { MatSliderModule } from '@angular/material/slider';


import { ChartComponent } from './chart/chart.component';
import { SliderComponent } from './slider/slider.component';
import { ProgressComponent } from './progress/progress.component';
import { PieComponent } from './pie/pie.component';
import { FormsModule } from '@angular/forms';
import { DatePipe } from 'app/utils/date.pipe';

@NgModule({
  imports: [
    CommonModule,
    ChartsModule,
    FormsModule,
    MatSliderModule,
  ],
  declarations: [
    ChartComponent,
    SliderComponent,
    ProgressComponent,
    PieComponent,
  ],
  exports: [
    ChartComponent,
    SliderComponent,
    ProgressComponent,
    PieComponent,
  ],
  providers: [
    DatePipe,
  ]
})
export class GraphicsModule { }
