// Angular modules import
import { Injectable } from '@angular/core';

// RjJS imports
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/mergeMap';

/* Datatypes */
import { Position } from '@shared/models/appModels/position.model';
import { MeterData } from '@shared/models/appModels/meterData.model';
import { MeterPoint } from '@shared/models/appModels/meterPoint.model';
import { MeasurementData } from '@shared/models/appModels/measurementData.model';
import { Customer } from '@shared/models/appModels/customer.model';

/* Services */
import { PositionHTTPService } from '@shared/services/http/position.service';
import { MeterPointDataStoreService } from '@shared/services/dataStore/meterPointDataStore.service';

import { environment } from '@env/environment';
import { PositionHttpParamG, UtilityTypeEnum, ValidationResultEnum, MeasurementOriginEnum, VeeStatusEnum, PositionHttpParams } from '@shared/types';
import { GenericHttpParams } from '@shared/types/http/httpType';
// tslint:disable-next-line: max-line-length
import { BaseDataStore, SortType, ApiDataShift, SubDataShift, MeterDataFilters, MeasurementsDataFilters, AnalysisFilters } from '@shared/types/applicationTypes';
import { CommonAppDataService } from '@shared/services/commonAppData.service';
import { RestEnumMpapper } from '@shared/models/RestSupport';
import { Analysis } from '@shared/models/appModels/analysis.model';
import { Forecasting } from '@shared/models/appModels/forecasting.model';

@Injectable()
export class PositionDataStoreService extends BaseDataStore<GenericHttpParams<Position>, PositionHttpParamG>  {

  debugMode: boolean = environment.debug;

  private positionDataSubject$: Subject<Position> = new Subject<Position>();
  private positionDataObservable$: Observable<Position> = this.positionDataSubject$.asObservable();

  constructor(private positionService: PositionHTTPService, private meterPointService: MeterPointDataStoreService, cs: CommonAppDataService) {
    super(cs);
  }

  getChangeObservable(): Observable<Position> {
    return this.positionDataObservable$;
  }

  getAllPositionsList(schedulerActive?: boolean, nums: ApiDataShift = { limit: 10, offset: 0 },
    allowEmpty: boolean = true, isFile: boolean = false): Observable<Position[]> {
    const params: PositionHttpParamG = this.setParams(true, false, false, { nums: nums });
    params.queryParams.svUtilityType = UtilityTypeEnum[this.cs.getCurrentMediaType()];
    params.queryParams.schedulerActive = '' + schedulerActive;
    params.queryParams.isFile = '' + isFile;
    params.config.strict = !allowEmpty;

    return this.getPositionsList(params);
  }

  getPositionById(id: number, allowEmpty: boolean = true): Observable<Position> {
    const params: PositionHttpParamG = this.setParams(false, false, true, { idPos: id });
    params.config.strict = !allowEmpty;
    return this.positionService.getPositionsById(params);
  }

  positionDataSubject(options: { emitEvent: boolean }, updated: Position) {
    if (options.emitEvent) {
      this.positionDataSubject$.next(updated);
    }
  }

  addPosition(item: Position, options: { emitEvent: boolean } = { emitEvent: true }): Observable<Position> {

    const params: PositionHttpParamG = this.getEmptyParams();
    params.body = item;
    params.queryParams.svUtilityType = UtilityTypeEnum[this.cs.getCurrentMediaType()];
    return this.positionService.postPositions(params)
      .do((added: Position) => {
        this.positionDataSubject(options, added);
        return Observable.of(added);
      });
  }

  updatePosition(item: Position, options: { emitEvent: boolean } = { emitEvent: true }): Observable<Position> {
    const params: PositionHttpParamG = this.setParams(false, false, true, { idPos: item.idPosition });
    params.body = item;
    return this.positionService.putPositionsById(params)
      .do((updated: Position) => {
        this.positionDataSubject(options, updated);
        return Observable.of(updated);
      });
  }

  deletePosition(id: number, options: { emitEvent: boolean } = { emitEvent: true }): Observable<Position> {
    const params: PositionHttpParamG = this.setParams(false, false, true, { idPos: id });
    return this.positionService.deletePositionsById(params)
      .do((pos: Position) => {
        this.positionDataSubject(options, pos);
      });
  }

  getPositionMeterData(
    idPosition: number,
    nums: ApiDataShift = { limit: 10, offset: 0 },
    allowEmpty: boolean = true,
    filters?: MeterDataFilters): Observable<MeterData[]> {
    const params: PositionHttpParamG = this.setParams(true, true, true, { nums: nums, filters: filters, idPos: idPosition });
    params.config.strict = !allowEmpty;
    return this.positionService.getPositionsMeterdataById(params);
  }

  private setAnalysisParams(idPosition: number,
    filters?: AnalysisFilters): PositionHttpParams {
    const params: PositionHttpParams = this.getEmptyParams();
    params.path.idPosition = '' + idPosition;
    if (filters) {
      if (filters.idMeterPoint) {
        params.queryParams.idMeterPoint = '' + filters.idMeterPoint;
      }
      if (filters.algorithm) {
        params.queryParams.algorithm = '' + filters.algorithm;
      }
      if (filters.anomaly !== undefined) {
        params.queryParams.anomaly = '' + filters.anomaly;
      }
    }
    return params;
  }

  getPositionAnalysis(
    idPosition: number,
    filters?: AnalysisFilters): Observable<Analysis[]> {
    const params: PositionHttpParams = this.setAnalysisParams(idPosition, filters);
    return this.positionService.getPositionsAnalysisById(params);
  }

  getPositionAnalysisCount(
    idPosition: number,
    filters?: AnalysisFilters): Observable<number> {
    const params: PositionHttpParams = this.setAnalysisParams(idPosition, filters);
    return this.positionService.getPositionsAnalysisByIdCount(params);
  }

  getPositionForecasting(
    idPosition: number,
    filters?: AnalysisFilters): Observable<Forecasting[]> {
    const params: PositionHttpParams = this.setAnalysisParams(idPosition, filters);
    return this.positionService.getPositionsForecastingById(params);
  }

  getPositionForecastingCount(
    idPosition: number,
    filters?: AnalysisFilters): Observable<number> {
    const params: PositionHttpParams = this.setAnalysisParams(idPosition, filters);
    return this.positionService.getPositionsForecastingByIdCount(params);
  }

  getPositionMeterDataCount(
    idPosition: number,
    filters?: MeterDataFilters): Observable<number> {
    const params: PositionHttpParamG = this.setParams(false, true, true, { filters: filters, idPos: idPosition });
    return this.positionService.getPositionsMeterdataByIdCount(params);
  }

  getPositionMeasurementData(
    idPosition: number,
    nums: ApiDataShift = { limit: 10, offset: 0 },
    allowEmpty: boolean = true,
    filters?: MeasurementsDataFilters
  ): Observable<MeasurementData[]> {
    const params = this.setParams(true, true, true, { nums: nums, idPos: idPosition, filters: filters });
    params.config.strict = !allowEmpty;
    return this.positionService.getPositionsMeasurementsDataById(params);
  }

  getPositionMeasurementDataCount(
    idPosition: number,
    filters?: MeasurementsDataFilters): Observable<number> {
    const params: PositionHttpParamG = this.setParams(false, true, true, { idPos: idPosition, filters: filters });
    return this.positionService.getPositionsMeasurementsDataByIdCount(params);
  }

  getPositionMeterPoints(id: number, limit: number = 50, offset: number = 0, allowEmpty: boolean = false, filters?: { from?: number, to?: number, active?: boolean, validationResult?: ValidationResultEnum }): Observable<MeterPoint[]> {
    const params: PositionHttpParamG = this.setParams(true, true, true, { idPos: id, nums: { limit: limit, offset: offset }, filters: filters });
    params.config.strict = !allowEmpty;
    return this.positionService.getPositionsMeterpointsById(params);
  }

  getPositionMeterPointsByName(id: number, name: string, limit: number = 50, offset: number = 0, allowEmpty: boolean = false): Observable<MeterPoint[]> {
    const params: PositionHttpParamG = this.setParams(true, false, true, { idPos: id, nums: { limit: limit, offset: offset } });
    params.queryParams.name = name;
    params.config.strict = !allowEmpty;
    return this.positionService.getPositionsMeterpointsById(params);
  }

  getPositionMeterPointsCount(id: number, allowEmpty: boolean = false, filters?: { from?: number, to?: number, active?: boolean, validationResult?: ValidationResultEnum, idVeeRule?: number }): Observable<number> {
    const params: PositionHttpParamG = this.setParams(false, true, true, { idPos: id, filters: filters });
    params.config.strict = !allowEmpty;
    return this.positionService.getPositionsMeterpointsByIdCount(params);
  }

  getPositionMeasurementsForSingleMeterPoint(
    idPos: number,
    idMeterPoint: number,
    nums: ApiDataShift = { limit: 10, offset: 0 },
    filters?: MeasurementsDataFilters,
    allowEmpty: boolean = true): Observable<MeasurementData[]> {
    const params = this.setParams(true, true, true, { nums: nums, idPos: idPos, idMeterPoint: idMeterPoint, filters: filters });
    params.config.strict = !allowEmpty;
    return this.positionService.getPositionsMeasurementsDataById(params);
  }

  getPositionMeterDataForSingleMeterPoint(
    idPos: number,
    idMeterPoint: number,
    nums: ApiDataShift = { limit: 10, offset: 0 },
    filters?: { from?: number, to?: number, sort?: SortType }): Observable<MeterData[]> {
    const params = this.setParams(true, true, true, { nums: nums, idPos: idPos, idMeterPoint: idMeterPoint, filters: filters });
    return this.positionService.getPositionsMeterdataById(params);
  }

  /* Combinatory API starts here */
  getPositionMetersAndMeterData(
    idPosition: number,
    nums: ApiDataShift = { limit: 10, offset: 0 },
    allowEmpty: boolean = false,
    fetchCustomer: boolean = true,
    meterDataLimits: SubDataShift = { limit: 10 }): Observable<MeterPoint[]> {
    return this.getPositionMeterPoints(idPosition, nums.limit, nums.offset, allowEmpty)
      .concatMap<MeterPoint[], MeterPoint[]>((meterPointArray: MeterPoint[]) => {
        return Observable.from(meterPointArray.map(meter => meter.idMeterPoint))
          .concatMap<number, Customer | undefined>((idMeterPoint: number) => {
            return this.getCustomer(fetchCustomer, idMeterPoint);
          })
          .concatMap<Customer | undefined, MeterData[]>((customer: Customer, index: number) => {
            if (typeof (customer) !== 'undefined') {
              meterPointArray[index].customer = customer;
            }
            // tslint:disable-next-line: max-line-length
            return this.getPositionMeterData(idPosition, { limit: meterDataLimits.limit, offset: 0 }, true, { idMeterPoint: meterPointArray[index].idMeterPoint });
          })
          .concatMap<MeterData[], MeterPoint>((meterData: MeterData[], index: number) => {
            meterPointArray[index].meterData = meterData.slice(0);
            return Observable.of(meterPointArray[index]);
          })
          .toArray();
      });
  }

  getPositionMetersAndMeasurementData(
    idPosiition: number,
    nums: ApiDataShift = { limit: 10, offset: 0 },
    allowEmpty: boolean = false,
    fetchCustomer: boolean = true,
    measurementDataLimits: SubDataShift = { limit: 10 }): Observable<MeterPoint[]> {

    return this.getPositionMeterPoints(idPosiition, nums.limit, nums.offset, allowEmpty)
      .concatMap<MeterPoint[], MeterPoint[]>((mpArray: MeterPoint[]) => {
        return Observable.from(mpArray.map(meter => meter.idMeterPoint))
          .concatMap<number, (Customer | undefined)>((idMeterPoint: number, index: number) => {
            return this.getCustomer(fetchCustomer, idMeterPoint);
          })
          .concatMap<(Customer | undefined), MeasurementData[]>((customer: Customer, index: number) => {
            if (typeof (customer) !== 'undefined') {
              mpArray[index].customer = customer;
            }
            return this.meterPointService.getMeterPointMeasurementData(mpArray[index].idMeterPoint, measurementDataLimits.limit, 0, true);
          })
          .concatMap<MeasurementData[], MeterPoint>((mesData: MeasurementData[], index: number) => {
            mpArray[index].measurementsData = mesData.slice(0);
            return Observable.of(mpArray[index]);
          })
          .toArray();
      });
  }

  getCustomer(fetchCustomer: any, idMeterPoint: number) {
    return fetchCustomer ? this.meterPointService.getMeterPointCustomer(idMeterPoint, true) : Observable.of(undefined);
  }

  private getPositionsList(params: PositionHttpParamG): Observable<Position[]> {
    return this.positionService.getPositionsList(params);
  }

  getPositionsCount(isFile: boolean = false): Observable<number> {
    const params = this.getEmptyParams();
    params.queryParams.svUtilityType = UtilityTypeEnum[this.cs.getCurrentMediaType()];
    params.queryParams.isFile = '' + isFile;
    return this.positionService.getPositionsCount(params);
  }

  private setSortingOrderParam(sort: SortType): string {
    const sVal = sort.toUpperCase() as SortType;
    if (sVal === 'ASC' || sVal === 'DESC') {
      return sVal;
    } else {
      throw new Error('Incorrect sort params');
    }
  }

  getEmptyParams(): PositionHttpParamG {
    const r: PositionHttpParamG = { queryParams: {}, path: {}, headers: {}, body: {} as Position, config: {} };
    return r;
  }

  private setMediaTypeAndPositioIdAndIdMeterPointParams(params: any, idPos: number,
    idMeterPoint?: number): PositionHttpParamG {
    params.path.idPosition = '' + idPos;
    if (idMeterPoint) {
      params.queryParams.idMeterPoint = '' + idMeterPoint;
    }
    params.queryParams.svUtilityType = UtilityTypeEnum[this.cs.getCurrentMediaType()];
    return params;
  }

  private setLimitAndOffsetParams(params: PositionHttpParamG, nums: ApiDataShift,): PositionHttpParamG {
    params.queryParams.limit = '' + nums.limit;
    params.queryParams.offset = '' + nums.offset;
    return params;
  }

  private setParams(setNums: boolean, setFilters: boolean, setMediaTypeAndPositioIdAndIdMeterPointParams: boolean, setParams: {
    nums?: ApiDataShift, idPos?: number,
    idMeterPoint?: number, filters?: any
  }): PositionHttpParamG {
    let params = this.getEmptyParams();
    if (setNums) {
      params = this.setLimitAndOffsetParams(params, setParams.nums);
    }
    if (setFilters) {
      params = this.setFiltersParams(params, setParams.filters);
    }
    if (setMediaTypeAndPositioIdAndIdMeterPointParams) {
      if (setParams.idMeterPoint) {
        params = this.setMediaTypeAndPositioIdAndIdMeterPointParams(params, setParams.idPos, setParams.idMeterPoint);
      } else {
        params = this.setMediaTypeAndPositioIdAndIdMeterPointParams(params, setParams.idPos);
      }
    }
    return params;
  }

  private setFiltersParams(params: PositionHttpParamG,
    filters?: any): PositionHttpParamG {
    if (filters) {
      if (filters.sort) {
        params.queryParams.sort = this.setSortingOrderParam(filters.sort);
      }
      if (filters.from) {
        params.queryParams.from = '' + filters.from;
      }
      if (filters.to) {
        params.queryParams.to = '' + filters.to;
      }
      if (filters.idMeterPoint) {
        params.queryParams.idMeterPoint = '' + filters.idMeterPoint;
      }
      if (filters.active) {
        params.queryParams.active = '' + filters.active;
      }
      if (typeof (filters.validationResult) !== 'undefined') {
        const map = new RestEnumMpapper<typeof ValidationResultEnum>();
        params.queryParams.validationResult = map.getEnumAsString(ValidationResultEnum, filters.validationResult);
      }
      if (filters.origin) {
        const mapper = new RestEnumMpapper<typeof MeasurementOriginEnum>();
        params.queryParams.origin = mapper.getEnumAsString(MeasurementOriginEnum, filters.origin);
      }
      if (typeof (filters.estimation) !== 'undefined') {
        const map = new RestEnumMpapper<typeof VeeStatusEnum>();
        params.queryParams.estimation = map.getEnumAsString(VeeStatusEnum, filters.estimation);
      }
      if (typeof (filters.validation) !== 'undefined') {
        const map = new RestEnumMpapper<typeof VeeStatusEnum>();
        params.queryParams.validation = map.getEnumAsString(VeeStatusEnum, filters.validation);
      }
      if (filters.idEstimationRule) {
        params.queryParams.idEstimationRule = '' + filters.idEstimationRule;
      }
      if (filters.idVeeRule) {
        params.queryParams.idVeeRule = '' + filters.idVeeRule;
      }
    }
    return params;
  }

}
