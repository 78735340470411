import { IAnalysisAlgorithm } from '@shared/models/appModels/analysisAlgorithm.model';
import { AnalysisAlgorithmSeverityEnum, AnalysisAlgorithmTypeEnum, AnalysisStatusEnum } from '@shared/types/modelTypes/analysisAlgorithmTypes';


export const analysisAlgorithmMock: IAnalysisAlgorithm[] = [
  {
    idAnalysisAlgorithm: 1,
    name: '3 sigma algorithm for heat',
    description: '3 sigma algorithm for heat',
    algorithmType: AnalysisAlgorithmTypeEnum.DETECTION_OF_ANOMALIES,
    recommendation: '3 sigma algorithm for heat',
    switchable: true,
    enabled: true,
    analysis: AnalysisStatusEnum.UNDEFINED,
    nameFile: 'SmartVee_Heat3Sigma.py',
  }
];
