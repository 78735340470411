import { IFileTemperatureData } from '@shared/models/appModels/fileTemperatureData';

export const MockFileTemperatureData: IFileTemperatureData[] = [

  {
    name: 'First uploaded file',
    quantity: 50,
    size: 1024,
  },
  {
    name: 'Second uploaded file',
    quantity: 125,
    size: 5012,
  },
  {
    name: 'Third uploaded file',
    quantity: 20,
    size: 4039,
  }
];
