import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { MeasurementData } from "@shared/models";
import { CommonAppDataService } from "@shared/services/commonAppData.service";
import { INameMapper, ArrayedParameters, ValidationResultEnum, VeeStatusEnum } from "@shared/types";
import { ApplicationUnits } from "@shared/models/applicationUnits.model";
import { ApplicationUnitsDataStoreService } from "@shared/services/dataStore/applicationUnitsDataStore.service";
import { MediaTypeParameter } from "@shared/models/appModels/MediaTypeParameter.mode";
import { environment } from "@env/environment";
import { UploadLocalisation as loc } from "../../../../upload.localisation";
import { concatMap } from "rxjs/operators";
import { Observable } from "rxjs";
import { MeasurementDataModel } from '@shared/models/appModels/measurementDataModel.model';
import { UnitHelper } from '../../service/unitsHelper.service';
import { DataValidationTranslationsHelper } from "app/components/data-validation/page/service/data-validation-translations-helper.service";
import { VeeRule } from "@shared/models/appModels/VeeRule.model";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "sv-readings-preview-view-dialog",
  templateUrl: "readings-preview-view-dialog.component.html",
  styleUrls: ["readings-preview-view-dialog.component.sass"],
})
export class ReadingsPreviewViewDialogComponent implements OnInit {

  public name: string;
  private element: MeasurementData;
  appUnits: ApplicationUnits;
  measurementsDefinitions: MeasurementDataModel[];
  private appParameters: MediaTypeParameter[];
  readonly labels = loc[environment.language].texts.measurement;
  readonly meterIdLabel =
    loc[environment.language].texts.asset.assetDataPreview.tableHeaders
      .METER_ID;
  ValidationResultEnum: typeof ValidationResultEnum = ValidationResultEnum;
  VeeStatusEnum: typeof VeeStatusEnum = VeeStatusEnum;
  validationFlag;
  estimationFlag;
  canRenderValidationRuleSection: boolean = false;
  canRenderEstimationRuleSection: boolean = false;
  noRecommendation = $localize`:@@data validation/detail/validation rule desc/no recommend:No recommendation.`;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { meterData: MeasurementData, veeRule: VeeRule },
    public dialogRef: MatDialogRef<ReadingsPreviewViewDialogComponent>,
    private communicationService: CommonAppDataService,
    private unitsService: ApplicationUnitsDataStoreService,
    public unitHelper: UnitHelper,
    public dataValidationTranslationsHelper: DataValidationTranslationsHelper,
  ) {
  }

  ngOnInit() {
    this.unitsService.getMeasurementDefinitions().subscribe(measDefs => {
      this.measurementsDefinitions = measDefs;
    });
    this.estimationFlag = this.data.meterData?.estimation;
    this.validationFlag = this.data.meterData?.validationResult;
    this.canRenderValidationRuleSection = this.data.meterData?.idValidationRule ? true : false;
    this.canRenderEstimationRuleSection = this.data.meterData?.idEstimationRule ? true : false;
    if (typeof this.data.meterData !== "undefined") {
      this.element = this.data.meterData;
      this.unitsService
        .getApplicationUnits(this.communicationService.getCurrentMediaType())
        .pipe(
          concatMap<ApplicationUnits, Observable<MediaTypeParameter[]>>(
            (appUnits) => {
              this.appUnits = appUnits;
              return this.unitsService.getMeasurementNonCumulatives();
            }
          )
        )
        .subscribe((parameters) => {
          this.appParameters = parameters;
        });
    }
  }

  get serialMeter(): string {
    try {
      return this.element.meterPointRef.serialNumber;
    } catch (e) { }
  }

  get date(): number {
    try {
      return this.element.timestamp;
    } catch (e) { }
  }

  get selectedStatusFlagControl(): INameMapper {
    try {
      return this.element.getStatusFlag();
    } catch (e) { }
  }

  get values(): ArrayedParameters[] {
    try {
      return this.element.convertParamsToArray(
        this.element.getParamsByKeyList(
          this.appUnits.units.map((p) => p.name),
          this.element.measurementValues
        )
      );
    } catch (e) {
      return undefined;
    }
  }

  getElementValue(key: string): number | undefined {
    if (this.element.isParamPresent(key, this.element.measurementValues)) {
      return +this.element.getParamByKey(key, this.element.measurementValues)[
        key
      ];
    } else {
      return undefined;
    }
  }


  get unit(): string[] {
    try {
      return this.appUnits.units.map((u) => u.unit);
    } catch (e) { }
  }

  get optionalValues(): ArrayedParameters[] {
    try {
      const params = this.element
        .convertParamsToArray(this.element.measurementValues, this.measurementsDefinitions)
        .filter((param) => {
          const match = this.appUnits.units.find(
            (unit) => unit.name === param.name
          );
          if (typeof match === "undefined") {
            return true;
          } else {
            return false;
          }
        });

      params.forEach((p) => {
        const idx = this.appParameters.findIndex(
          (apParam) => apParam.name === p.name
        );
        if (idx !== -1) {
          p.name = this.appParameters[idx].description;
        }
      });
      return params;
    } catch {
      return undefined;
    }
  }

  getTypeParam(x: any){
    return !Number.isNaN(Number(x))
  }
}
