import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { environment } from '@env/environment';
import { SettingsLocalisation } from '../settings-localisation';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { CommonAppDataService } from '@shared/services/commonAppData.service';
import * as moment from 'moment';
import { SensorDataDataStoreService } from '@shared/services/dataStore/sensorDataDataStore.service';
import { SensorData, ISensorData } from '@shared/models/appModels/sensorData.model';
import { InteractiveTutorialService } from '@shared/services/interactiveTutorialService.service';
import { SettingsInteractiveTutorialService } from '../settings-interactive-tutorial.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: "sv-settings-application",
  templateUrl: "settings-application.component.html",
  styleUrls: ["settings-application.component.scss"],
})
export class SettingsApplicationComponent implements OnInit, AfterViewInit, OnDestroy {
  settingsForm: FormGroup;
  changeStartDate: FormControl;
  readonly startDate: string =
    SettingsLocalisation[environment.language].texts.startDate;
  readonly wrongFormatText: string =
    SettingsLocalisation[environment.language].texts.wrongFormatText;
  readonly downloadData: string =
    SettingsLocalisation[environment.language].texts.downloadData;
  readonly settingsApplication: string =
    SettingsLocalisation[environment.language].texts.settingsApplication;
  readonly settings: string =
    SettingsLocalisation[environment.language].texts.settings;
  readonly downloadStart: string =
    SettingsLocalisation[environment.language].texts.downloadStart;
  readonly day: string = SettingsLocalisation[environment.language].texts.day;
  readonly hour: string = SettingsLocalisation[environment.language].texts.hour;
  readonly intervalLabel: string =
    SettingsLocalisation[environment.language].texts.interval;
  readonly buildIcom: string = "build";
  panelsState = { panelOpenState: true };
  statusDownloadData: FormControl;
  interval: FormControl;
  showTutorialSubscription: Subscription;

    constructor(private fb: FormBuilder,
        private comServ: CommonAppDataService,
        private sensorData: SensorDataDataStoreService,
        private interactiveTutorialService: InteractiveTutorialService,
        private settingsInteractiveTutorialService: SettingsInteractiveTutorialService,
        private route: ActivatedRoute) {
        this.settingsForm = new FormGroup({});
        this.changeStartDate = new FormControl();
        this.statusDownloadData = new FormControl();
        this.interval = new FormControl();
        this.getStartData();
        this.statusDownloadData.setValue(environment.defaultStatusDownloadData);
    }

    ngOnInit() {
      this.settingsForm = this.fb.group({
        beginDate: this.changeStartDate,
        activeDownloadingData: this.statusDownloadData,
        interval: this.interval,
      });
      this.settingsForm
        .get("activeDownloadingData")
        .valueChanges.subscribe((val) => {
          if (val) {
            this.settingsForm.get("beginDate").disable();
            this.settingsForm.get("interval").disable();
          } else {
            this.settingsForm.get("beginDate").enable();
            this.settingsForm.get("interval").enable();
          }
        });
      this.getStartData();
  
    }

    ngAfterViewInit(){
        this.showTutorialSubscription = this.interactiveTutorialService.showTutorialSubject.pipe(debounceTime(1500)).subscribe(isActive => {
            if (this.interactiveTutorialService.getActiveComponent(this.route.routeConfig.path) && isActive) {
              this.startInteractiveTutorial();
              }
          });
    }

    startInteractiveTutorial(){
        const steps = this.settingsInteractiveTutorialService.getSettingsApplicationInteractiveTutorialSteps();
        this.interactiveTutorialService.startInteractiveTutorial(steps);
    }

    ngOnDestroy(){
        if(this.showTutorialSubscription){
            this.showTutorialSubscription.unsubscribe();
        }
    }

  getStartData() {
    this.sensorData.getSensorDataState().subscribe(
      (sensor) => {
        this.changeStartDate.setValue(moment(sensor.beginDate));
        this.statusDownloadData.setValue(sensor.active);
        this.interval.setValue(sensor.interval);
      },
      (err) => {
        this.changeStartDate.setValue(moment());
        this.statusDownloadData.setValue(environment.defaultStatusDownloadData);
        this.interval.setValue(24);
      }
    );
  }

  changeStartDownloadData(event: MatSlideToggleChange) {
    environment.defaultStatusDownloadData = event.checked;
    this.statusDownloadData.setValue(event.checked);
    const date = +(this.changeStartDate.value ?? this.comServ.getToday());
    const sensorData: ISensorData = {
      id: 1,
      active: event.checked,
      beginDate: date,
      endDate: date,
      interval: this.interval.value,
    };

    this.sensorData
      .postSensorDataState(new SensorData(sensorData))
      .subscribe((_) => this.getStartData());
  }
}
