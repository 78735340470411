export const SettingsLocalisation = [
  {
    lang: 'ENG',
    texts: {
      startDate: 'Start date',
      wrongFormatText: 'Incorrect date format',
      downloadStart: 'Start downloading data',
      downloadData: 'Download data',
      settingsApplication: 'Application settings',
      settings: 'Settings',
      day: "Day",
      hour: "Hour",
      interval: "Interval"
    }
  },
  {
    lang: 'GER',
    texts: {
      startDate: 'Anfangsdatum',
      wrongFormatText: 'Falsches Datumsformat',
      downloadStart: 'Daten herunterladen starten',
      downloadData: 'Daten herunterladen',
      settingsApplication: 'Einstellungen Anwendung',
      settings: 'Einstellungen',
      day: "Tag",
      hour: "Stunde",
      interval: "Intervall"
    }
  },
  {
    lang: 'PL',
    texts: {
      startDate: 'Data startu',
      wrongFormatText: 'Nieprawidłowy format daty',
      downloadStart: 'Rozpocznij pobieranie danych',
      downloadData: 'Pobierz dane',
      settingsApplication: 'Ustawienia aplikacji',
      settings: 'Ustawienia',
      day: "Dzień",
      hour: "Godzina",
      interval: "Odstęp czasowy"
    },
  }
];
