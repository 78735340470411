// Angular modules import
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Application services
import { CommonAppDataService } from '@shared/services/commonAppData.service';

// RjJS imports
import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import 'rxjs/add/operator/pluck';
import 'rxjs/add/operator/max';
import 'rxjs/add/operator/do';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/concatMap';

import { ISensorData, SensorData } from '@shared/models/appModels/sensorData.model';
import { RestTypeMapper } from '@shared/models/RestSupport/restTypeMapper.model';
import { SensorDataHttpParamG } from '@shared/types/http/sensorDataHttpConfig';
import { EndpointsEnum } from '@shared/types/http/endpointEnum';
/* Datasource */
import { MockSensorData } from '@shared/mock/sensorData.mock';

import { environment } from '@env/environment';
import { RequestService } from './requestService.class';

@Injectable()
export class SensorDataService extends RequestService {

  /* Database simulation */
  sensorDatas: ISensorData[];


  private sensorDataMapper: RestTypeMapper<ISensorData, SensorData> = new RestTypeMapper<ISensorData, SensorData>();

  /* Debug data */
  debugMode: boolean = environment.debug;

  constructor(http: HttpClient, cs: CommonAppDataService) {
    super(http, cs, EndpointsEnum.SENSOR_DATA);
    if (this.MOCK_SERVICE) {
      this.sensorDatas = MockSensorData.slice(0);
    } else {
      this.sensorDatas = undefined;
    }
    /* Initialize type MAPPERS */
    this.sensorDataMapper = new RestTypeMapper<ISensorData, SensorData>();
  }

  getSensorDataState(params: SensorDataHttpParamG): Observable<SensorData> {

    const thisApiID = 'getSensorDataState';

    if (this.MOCK_SERVICE) {
      return Observable.create((observer: Observer<SensorData>) => {
        observer.next(this.sensorDataMapper.mapInterfaceToObject(this.sensorDatas, SensorData) as SensorData);
        observer.complete();
      })
        .delay(this.MOCK_TIME);
    } else {
      return this.getRequest(params, this.sensorDataMapper, thisApiID, SensorData);
    }
  }

  postSensorDataState(params: SensorDataHttpParamG): Observable<SensorData> {

    const thisApiID = 'postSensorDataState';

    if (this.MOCK_SERVICE) {
      return Observable.create((observer: Observer<SensorData>) => {
        /* Map data to interface type */
        const iSensorData: ISensorData = this.sensorDataMapper.mapObjectToInterface(params.body);
        const response: SensorData = this.sensorDataMapper.mapInterfaceToObject(iSensorData, SensorData) as SensorData;
        observer.next(response);
        observer.complete();
      });
    } else {
      const bodyToSend: ISensorData = this.sensorDataMapper.mapObjectToInterface(params.body);
      return this.postRequest(bodyToSend, params, this.sensorDataMapper, thisApiID, SensorData);
    }
  }

}
