import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { CommonUploadComponent } from '../CommonUpload.class';
import { FileTemperatureData } from '@shared/models/appModels/fileTemperatureData';
import { FileTemperatureDataDataStoreService } from '@shared/services/dataStore/fileTemperaturesDataDataStore.service';
import { CommonAppDataService } from '@shared/services/commonAppData.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { TableHost } from '@shared/types';
import { typesLocalisation } from '@shared/types/localisation';
import { UploadLocalisation } from '../upload.localisation';
import { environment } from '@env/environment';
import { TemperatureData } from '@shared/models/appModels/temperatureData.model';
import { TemperatureDataSource } from './dataSource/TemperatureDataSource';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { concatMap, map } from 'rxjs/operators';
import { merge } from 'rxjs';
import { UploadInput } from '@ngx-uploader';
import { InteractiveTutorialService } from '@shared/services/interactiveTutorialService.service';
import { TemperaturesUploadInteractiveTutorialService } from './temperaturesUploadInteractiveTutorialService.service';
import { debounceTime } from 'rxjs/operators';


@Component({
    selector: 'sv-temperatures',
    templateUrl: './temperatures.component.html',
    styleUrls: ['../upload.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class TemperaturesComponent extends CommonUploadComponent<FileTemperatureData> implements OnInit, AfterViewInit, OnDestroy, TableHost<TemperatureData>  {
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    readonly itemsPage = typesLocalisation.ItemsPage[environment.language].texts;
    readonly temperaturesLabel = UploadLocalisation[environment.language].texts.temperatures;
    readonly noData = UploadLocalisation[environment.language].texts.asset.assetDataPreview.noData;

    displayedColumns = ['timestamp', 'temperature'];
    dataSource: TemperatureDataSource;
    dataLength: number;
    dataLoading: boolean;
    dataFetched: boolean;
    countFileSubs: Subscription;
    loadDataSubscription: Subscription;
    onPaginatorEventsSubscription: Subscription;
    onExternalDataChangeEventsSubscription: Subscription;
    showTutorialSubscription: Subscription;

    constructor(private fileTemperaturesDataService: FileTemperatureDataDataStoreService,
        cs: CommonAppDataService,
        private router: Router,
        cdr: ChangeDetectorRef,
        private route: ActivatedRoute,
        private interactiveTutorialService: InteractiveTutorialService,
        private temperaturesUploadInterTutServ: TemperaturesUploadInteractiveTutorialService) {
        super(cs, false, cdr);
        this.uploadExecutor = (body) => this.fileTemperaturesDataService.addFileTemperatureData(body, { emitEvent: true });
        this.countHandler = () => this.fileTemperaturesDataService.getTemperatureDataListCount({});

        this.countFileSubs = this.countFileSubject.subscribe((filesInQueue) => {
            // this is situation when error occurred during send process and user tries to drag another file omitting clicking reload button
            if (filesInQueue === 1 && this.uploadResults.error) {
                this.reuploadFileSelect();
            }
        });
    }

    ngOnInit() {
        this.dataSource = new TemperatureDataSource();
        this.dataLength = 0;
        this.showTutorialSubscription = this.interactiveTutorialService.showTutorialSubject.pipe(debounceTime(1500)).subscribe(isActive => {
            if (this.interactiveTutorialService.getActiveComponent(this.route.routeConfig.path) && isActive) {
            this.startInteractiveTutorial();
            }
          });
    }

    startInteractiveTutorial(){
        const steps = this.temperaturesUploadInterTutServ.getTemperaturesUploadInteractiveTutorialSteps();
        this.interactiveTutorialService.startInteractiveTutorial(steps);
    }

    btnDisabled() {
        return this.displayableUpload.length === 0;
    }

    startUpload(): void {
        const event: UploadInput = {
            type: 'uploadAll',
            uploadExecutor: (file) => this.fileTemperaturesDataService.addFileTemperatureData(
                file,
                { emitEvent: true }),
        };
        this.uploadResults.pending = true;
        this.uploadInput.emit(event);
    }

    setDataSource(pagedData: TemperatureData[]) {
        if (this.debugMode) { console.warn(`New Data triggered by paginator event`); }
        this.dataSource.setNewData(pagedData);
    }

    ngAfterViewInit(): void {
        this.paginator._intl.itemsPerPageLabel = this.itemsPage.itemsPage;
        this.paginator._intl.nextPageLabel = this.itemsPage.nextPage;
        this.paginator._intl.previousPageLabel = this.itemsPage.previousPage;
        this.paginator._intl.lastPageLabel = this.itemsPage.lastPage;
        this.paginator._intl.firstPageLabel = this.itemsPage.firstPage;
        const limit = this.paginator.pageSize;
        const offset = this.paginator.pageIndex * this.paginator.pageSize;

        this.loadDataSubscription = this.loadData(limit, offset)
            .subscribe((temperatureData: TemperatureData[]) => {
                this.dataSource.setNewData(temperatureData);
                this.dataFetched = true;
                this.cdr.markForCheck();
            },
                (err: any) => { if (this.debugMode) { console.log(err); } }
            );

        this.onPaginatorEventsSubscription = this.onPaginatorEvents(this.paginator)
            .subscribe((pagedData: TemperatureData[]) => {
                this.setDataSource(pagedData);
            },
                (err: any) => { if (this.debugMode) { console.log(err); } },
            );

        this.onExternalDataChangeEventsSubscription = this.onExternalDataChangeEvent([this.fileTemperaturesDataService.getChangeObservable()])
            .subscribe((pagedData: TemperatureData[]) => {
                this.dataSource.setNewData(pagedData);
            });

        this.cdr.detectChanges();
    }

    ngOnDestroy(): void {
        if (this.countFileSubs) {
            this.countFileSubs.unsubscribe();
        }
        if (this.loadDataSubscription) {
            this.loadDataSubscription.unsubscribe();
        }
        if (this.onExternalDataChangeEventsSubscription) {
            this.onExternalDataChangeEventsSubscription.unsubscribe();
        }
        if (this.onPaginatorEventsSubscription) {
            this.onPaginatorEventsSubscription.unsubscribe();
        }
    }

    loadData(limit: number, offset: number): Observable<TemperatureData[]> {
        let dataFetchObservable: Observable<TemperatureData[]>;
        let countObservable: Observable<number>;
        dataFetchObservable = this.fileTemperaturesDataService.getTemperatureDataList({ limit: limit, offset: offset }, true);
        countObservable = this.fileTemperaturesDataService.getTemperatureDataListCount({});
        return dataFetchObservable.pipe(
            concatMap<TemperatureData[], Observable<TemperatureData[]>>((temp: TemperatureData[]) => {
                return countObservable.pipe(
                    map<number, TemperatureData[]>((length: number) => {
                        this.dataLength = length;
                        return temp;
                    }));
            }));
    }

    onPaginatorEvents(paginator: MatPaginator): Observable<TemperatureData[]> {
        return paginator.page.pipe(
            concatMap<PageEvent, Observable<TemperatureData[]>>((pageEvent: PageEvent) => {
                const limit = pageEvent.pageSize;
                const offset = pageEvent.pageIndex * pageEvent.pageSize;
                return this.loadData(limit, offset);
            }));
    }

    onExternalDataChangeEvent(changers: Observable<any>[]): Observable<TemperatureData[]> {
        return merge(...changers).pipe(
            concatMap<any, Observable<TemperatureData[]>>((v: any) => {
                return this.loadData(this.paginator.pageSize, this.paginator.pageIndex * this.paginator.pageSize);
            }));
    }

    onFilterEvents(): Observable<TemperatureData[]> {
        this.paginator.pageIndex = 0;
        return this.loadData(this.paginator.pageSize, 0);
    }

    onClearFilters(): Observable<TemperatureData[]> {
        return this.loadData(this.paginator.pageSize, this.paginator.pageIndex * this.paginator.pageSize);
    }

    isLoading(): boolean {
        return !this.dataFetched;
    }

    getDataSourceLength(): number {
        return this.dataLength;
    }

    clearData() {
        this.uploadResults.error = false;
        this.uploadResults.errorCause = '';
        this.uploadResults.pending = false;
        this.uploadResults.success = false;
        this.cdr.detectChanges();
    }

    reuploadFileSelect() {
        try {
            this.clearData();
        } catch (e) {
            console.error(e);
        }
    }
}
