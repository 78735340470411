import { IFileOperationalData } from '@shared/models/appModels/fileOperationalData';
import { FileExtensionEnum } from '@shared/types/applicationTypes';


export const MockFileOperationalData: IFileOperationalData[] = [

  {
    idFileOperationalData: 1,
    creationDate: new Date('2017-07-29').getTime(),
    name: 'First uploaded file',
    file: FileExtensionEnum[FileExtensionEnum.CSV],
    quantity: 50,
    size: 1024,
  },
  {
    idFileOperationalData: 2,
    creationDate: new Date('2017-09-15').getTime(),
    name: 'Second uploaded file',
    file: FileExtensionEnum[FileExtensionEnum.CSV],
    quantity: 125,
    size: 5012,
  },
  {
    idFileOperationalData: 3,
    creationDate: new Date('2017-12-20').getTime(),
    name: 'Third uploaded file',
    file: FileExtensionEnum[FileExtensionEnum.CSV],
    quantity: 20,
    size: 4039,
  }
];
