import { Component, OnInit, Input } from "@angular/core";

import { Router } from "@angular/router";
import { Schedule, Position } from "@shared/models";
import { EventType, EventStatus } from "@shared/types";
import { AnalysisAlgorithmDataStoreService } from "@shared/services/dataStore/analysisAlgorithmsDataStore.service";
import { AnalysisAlgorithmTypeEnum } from "@shared/types/modelTypes/analysisAlgorithmTypes";
import { AnalysisAlgorithm } from "@shared/models/appModels/analysisAlgorithm.model";
import { catchError, debounceTime, tap } from "rxjs/operators";
import { Observable, forkJoin, of, Subscription } from "rxjs";
import { InteractiveTutorialService, NextComponentName } from "@shared/services/interactiveTutorialService.service";
import { TimelineInteractiveTutorialService } from "app/components/timeline/timelineInteractiveTutorialService.service";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "sv-data-exploration-widget",
  templateUrl: "data-exploration-widget.component.html",
  styleUrls: ["data-exploration-widget.component.sass"],
})
export class DataExplorationWidgetComponent implements OnInit {
  private selectedSchedule: Schedule;
  title = new Map();
  algorithms$: Observable<AnalysisAlgorithm[]>;
  maps = [["Flow and return temperature", $localize`:Dashboard title in Grafana|Dashboard title in Grafana@@exploration/flow and return temperature:Flow and return temperature`],
  ["Consumption for the meter", $localize`:Dashboard title in Grafana|Dashboard label in Grafana@@exploration/consumption for the meter:Consumption for the meter`],
  ["Node preview",$localize`:Dashboard title in Grafana|Dashboard label in Grafana@@exploration/node preview:Node preview`]]

  canStartInteractiveTut$: Subscription;
  ngOnInit() {
    this.maps.forEach(elements => {
      this.title.set(elements[0], elements[1]);
    });
    this.algorithms$ = this.algorithmService
      .getAnalysisAlgorithmsTypeExploration()
      .pipe(
        tap((algorithms) => {
          algorithms.sort(
            (a, b) => a.idAnalysisAlgorithm - b.idAnalysisAlgorithm
          );
        }),
        catchError((_) => of([]))
      );
      this.canStartInteractiveTut$ = this.interactiveTutorialServ.canGoToNextStepSubject.pipe(debounceTime(100)).subscribe(val => {
        if(val === NextComponentName.TIMELINE_DATA_EXPLORATION_WIDGET){
          this.startInteractiveTutorial();
        }
      })
  }

  constructor(
    private router: Router,
    private algorithmService: AnalysisAlgorithmDataStoreService,
    private interactiveTutorialServ: InteractiveTutorialService,
    private timelineInteractiveTutorialServ: TimelineInteractiveTutorialService
  ) {}

  @Input() set schedule(schedule: Schedule) {
    this.selectedSchedule = schedule;
  }


  private startInteractiveTutorial(){
    const steps = this.timelineInteractiveTutorialServ.getDataExplorationTimelineWidgetTutorialSteps();
    this.interactiveTutorialServ.startInteractiveTutorial(steps);
  }

  ngOnDestroy(){
    if(this.canStartInteractiveTut$){
      this.canStartInteractiveTut$.unsubscribe();
    }
  }

  private findLastValidatedPosition(): Position {
    let result: Position = undefined;
    for (let p of this.selectedSchedule?.getPositions() || []) {
      if (
        ![EventStatus.DONE, EventStatus.CLOSED].includes(
          p.events[EventType.VALIDATION].status
        )
      ) {
        continue;
      }
      if (
        result === undefined ||
        p.events[EventType.VALIDATION].date <
          result.events[EventType.VALIDATION].date
      ) {
        result = p;
      }
    }
    return result;
  }

  navigateToExploration() {
    const position = this.findLastValidatedPosition();
    this.router.navigate(["/data-exploration"], {
      queryParams:
        position !== undefined ? { position: position.idPosition } : {},
      queryParamsHandling: "merge",
    });
  }

  navigateToAlgorithm(
    algorithms: AnalysisAlgorithm[],
    selectedAlgorithmId: number
  ) {
    forkJoin(
      algorithms.map((algorithm) =>
        this.algorithmService.toggleAnalysisAlgorithm(
          algorithm.idAnalysisAlgorithm,
          algorithm.idAnalysisAlgorithm === selectedAlgorithmId,
          AnalysisAlgorithmTypeEnum.EXPLORATION,
          { emitEvent: true }
        )
      )
    )
      .toPromise()
      .then(() => this.navigateToExploration());
  }
  getTranslatedAlgorithmName(name: string) {
    return this.title.get(name);
  }
}
