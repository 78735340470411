import { Position } from '@shared/models';
import { GenericHttpParams, IApiEndpointConfig } from '@shared/types/http/httpType';

export const PositionApiConf: IApiEndpointConfig = {
  rootApiPath: "positions/",
  paths: [
    {
      /* 1 */ url: "",
      swaggerId: "getPositionsList",
      pathParams: [],
      responseIsArray: true,
    },
    {
      /* 2 */ url: "",
      swaggerId: "postPositions",
      pathParams: [],
      responseIsArray: false,
    },
    {
      /* 3 */ url: "id/{idPosition}",
      swaggerId: "getPositionsById",
      pathParams: [{ name: "idPosition", pattern: "{idPosition}" }],
      responseIsArray: false,
    },
    {
      /* 4 */ url: "id/{idPosition}",
      swaggerId: "deletePositionsById",
      pathParams: [{ name: "idPosition", pattern: "{idPosition}" }],
      responseIsArray: false,
    },
    {
      /* 5 */ url: "id/{idPosition}",
      swaggerId: "putPositionsById",
      pathParams: [{ name: "idPosition", pattern: "{idPosition}" }],
      responseIsArray: false,
    },
    {
      /* 6 */ url: "id/{idPosition}/meterdata",
      swaggerId: "getPositionsMeterdataById",
      pathParams: [{ name: "idPosition", pattern: "{idPosition}" }],
      responseIsArray: true,
    },
    {
      /* 7 */ url: "id/{idPosition}/meterdata/count",
      swaggerId: "getPositionsMeterdataByIdCount",
      pathParams: [{ name: "idPosition", pattern: "{idPosition}" }],
      responseIsArray: false,
    },
    {
      /* 8 */ url: "id/{idPosition}/measurementdata",
      swaggerId: "getPositionsMeasurementsDataById",
      pathParams: [{ name: "idPosition", pattern: "{idPosition}" }],
      responseIsArray: true,
    },
    {
      /* 9 */ url: "id/{idPosition}/measurementdata/count",
      swaggerId: "getPositionsMeasurementsDataByIdCount",
      pathParams: [{ name: "idPosition", pattern: "{idPosition}" }],
      responseIsArray: false,
    },
    {
      /* 10 */ url: "id/{idPosition}/meterpoints",
      swaggerId: "getPositionsMeterpointsById",
      pathParams: [{ name: "idPosition", pattern: "{idPosition}" }],
      responseIsArray: true,
    },
    {
      /* 11 */ url: "id/{idPosition}/meterpoints/count",
      swaggerId: "getPositionsMeterpointsByIdCount",
      pathParams: [{ name: "idPosition", pattern: "{idPosition}" }],
      responseIsArray: false,
    },
    {
      /* 12 */ url: "id/{idPosition}/analysis",
      swaggerId: "getPositionsAnalysisById",
      pathParams: [{ name: "idPosition", pattern: "{idPosition}" }],
      responseIsArray: true,
    },
    {
      /* 13 */ url: "id/{idPosition}/analysis/count",
      swaggerId: "getPositionsAnalysisByIdCount",
      pathParams: [{ name: "idPosition", pattern: "{idPosition}" }],
      responseIsArray: false,
    },
    {
      /* 14 */ url: "id/{idPosition}/forecasting",
      swaggerId: "getPositionsForecastingById",
      pathParams: [{ name: "idPosition", pattern: "{idPosition}" }],
      responseIsArray: true,
    },
    {
      /* 15 */ url: "id/{idPosition}/forecasting/count",
      swaggerId: "getPositionsForecastingByIdCount",
      pathParams: [{ name: "idPosition", pattern: "{idPosition}" }],
      responseIsArray: false,
    },
    {
      /* 17 */ url: "count",
      swaggerId: "getPositionsCount",
      pathParams: [],
      responseIsArray: false,
    },
  ],
};

export interface PositionHttpParamG extends GenericHttpParams<Position> {
  queryParams: {
    /* Required */
    limit?: string;
    offset?: string;
    /* Optional */
    id?: string[];
    name?: string;
    schedules?: string[];
    group?: string;
    idMeterPoint?: string;
    validationResult?: string;
    origin?: string;
    mesurementType?: string;
    from?: string;
    idEstimationRule?: string;
    idVeeRule?: string;
    estimation?: string;
    validation?: string;
    to?: string;
    sort?: string;
    svUtilityType?: string;
    schedulerActive?: string;
    isFile?: string;
    active?: string;
  };
  path?: {
    idPosition?: string;
  };
}

export interface PositionHttpParams extends GenericHttpParams<Position> {
  queryParams: {
    idMeterPoint?: string;
    analysedPositionId?: string;
    algorithm?: string;
    anomaly?: string;
  };
  path?: {
    idPosition?: string;
  };
}
