import { IFileMeterData } from '@shared/models/appModels/fileMeasurementData.model';
import { FileExtensionEnum } from '@shared/types/applicationTypes';

export const MockFileMeterData: IFileMeterData[] = [
  {
    idFileMeterData: 1,
    idPosition: 2,
    name: 'Example meter data file no.1',
    quantity: 120,
    anomalies: 22,
    creationDate: new Date('2017-09-28').getTime(),
    file: FileExtensionEnum[FileExtensionEnum.CSV],
    size: 2048
  },
  {
    idFileMeterData: 2,
    idPosition: 3,
    name: 'Example meter data file no.2',
    quantity: 120,
    anomalies: 22,
    creationDate: new Date('2017-12-28').getTime(),
    file: FileExtensionEnum[FileExtensionEnum.CSV],
    size: 2048
  }
];
