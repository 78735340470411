import { Component, OnInit, ViewChild, Input, AfterViewInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

import { FileMeterDataPreviewDataSource } from './dataSource/readingsPreviewSource';
import { CommonAppDataService } from '@shared/services/commonAppData.service';

import { FileMeterData } from '@shared/models';
import { UtilityTypeEnum } from '@shared/types';
import { MeasurementOriginEnum } from '@shared/types';

import { TableHost } from '@shared/types/applicationTypes';
import { Observable } from 'rxjs/Observable';
import { environment } from '@env/environment';
import { measurementOriginNamesMap } from '@shared/types/modelTypes/measurementType';
import { MeasurementDataStoreService } from '@shared/services/dataStore/measurementDataDataStore.service';
import { ApplicationUnits } from '@shared/models/applicationUnits.model';

import { UploadLocalisation as loc } from '../../upload.localisation';
import { VeeStatusDataStoreService } from '@shared/services/dataStore/veeProcessDataStore.service';
import { typesLocalisation } from '@shared/types/localisation';
import { FileMeterDataStoreService } from '@shared/services/dataStore/fileMeterDataDataStore.service';
import { Subscription } from 'rxjs';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'sv-measurements-file-preview',
  templateUrl: './measurements-preview.component.html',
  styleUrls: ['./../../data-preview.sass'],
})
export class ReadingsFilePreviewComponent implements OnInit, AfterViewInit, OnDestroy, TableHost<FileMeterData> {

  addNewButtonTxt = loc[environment.language].texts.measurement.addNewButton;
  loadDataSubscription: Subscription;
  loadDataAfterViewInitSubscription: Subscription;
  filterDataEventsSubscription: Subscription;
  paginatorEventsSubscription: Subscription;
  externalDataChangeEventsSubscription: Subscription;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @Input() set idPosition(pos: number) {
    /* Set main property value */
    this._idPos = pos;
    /* Re-trigger data fetch for each new data passed as parameter */
    if (this.dataFetched) {
      this.dataFetched = false;
      this.loadDataSubscription = this.loadData()
        .subscribe((data: FileMeterData[]) => {
          this.dataSource.setNewData(data);
        },
          (err: any) => { if (this.debugMode) { console.error(err); } },
          () => {
            if (this.debugMode) { console.log(`non-ini data fetch Finished`); }
            this.dataFetched = true;
          },
        );
    }
    this.veeStatusService.getVeeStateForPosition(this.idPosition, true)
      .subscribe(vs => {
        if (Object.keys(vs).length === 0) {
          this.addingDisabled = false;
        } else {
          this.addingDisabled = true;
        }
      });
  }
  get idPosition(): number {
    return this._idPos;
  }

  readonly labels = loc[environment.language].texts.measurement;
  readonly noData = loc[environment.language].texts.measurement.noData;

  public filterFormGroup: FormGroup;

  dataSource: FileMeterDataPreviewDataSource;

  /* Loading and data availability control */
  dataLength: number = 0;
  dataFetched: boolean = false;
  dataLoading: boolean;
  @Input() fileList: Array<FileMeterData> = [];

  readonly debugMode: boolean = environment.debug;
  readonly itemsPage = typesLocalisation.ItemsPage[environment.language].texts;
  /* Local copies of Enumerators to use on template */
  MeasurementOriginEnum: typeof MeasurementOriginEnum = MeasurementOriginEnum;
  measurementOriginNamesMap: typeof measurementOriginNamesMap = measurementOriginNamesMap;

  private _idPos: number;
  displayedColumns: string[] = ['name', 'timestamp'];

  addingDisabled: boolean = false;
  appUnits: ApplicationUnits;
  origin: string;

  constructor(
    private cs: CommonAppDataService,
    private measurementsService: MeasurementDataStoreService,
    private cdr: ChangeDetectorRef,
    private fileMeterDataStoreService: FileMeterDataStoreService,
    private veeStatusService: VeeStatusDataStoreService) {
    this.dataFetched = false;
    this.dataSource = new FileMeterDataPreviewDataSource();
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.paginator._intl.itemsPerPageLabel = this.itemsPage.itemsPage;
    this.paginator._intl.nextPageLabel = this.itemsPage.nextPage;
    this.paginator._intl.previousPageLabel = this.itemsPage.previousPage;
    this.paginator._intl.lastPageLabel = this.itemsPage.lastPage;
    this.paginator._intl.firstPageLabel = this.itemsPage.firstPage;
    const limit = this.paginator.pageSize;
    const offset = this.paginator.pageIndex * this.paginator.pageSize;


    /* initial data load */
    this.loadDataAfterViewInitSubscription = this.loadData(limit, offset)
      .subscribe((data: FileMeterData[]) => {
        this.dataSource.setNewData(data);
      },
        (err: any) => { if (this.debugMode) { console.log(err); } },
        () => {
          if (this.debugMode) { console.log(`Init data fetch Finished measurementPreview`); }
          this.dataFetched = true;
        },
      );

    this.filterDataEventsSubscription = this.onFilterEvents()
      .subscribe((pagedData: FileMeterData[]) => {
        this.dataSource.setNewData(pagedData);
      });

    this.paginatorEventsSubscription = this.onPaginatorEvents(this.paginator)
      .subscribe((pagedData: FileMeterData[]) => {
        this.dataSource.setNewData(pagedData);
      });

    this.externalDataChangeEventsSubscription = this.onExternalDataChangeEvent([this.measurementsService.getChangeObservable()])
      .subscribe((pagedData: FileMeterData[]) => {
        this.dataSource.setNewData(pagedData);
      });
    this.cdr.detectChanges();
  }

  getDataLength(): number {
    if (this.dataSource) {
      return this.dataLength;
    }
  }

  isLoading(): boolean {
    return (!this.dataFetched);
  }

  get filters(): { [key: string]: AbstractControl } {
    return this.filterFormGroup.controls;
  }

  /* FIXME: Referende to meterpoint in measData model is bad but convinient. */
  loadData(limit: number = 10, offset: number = 0, otherParams?: { origin: string; }): Observable<FileMeterData[]> {
    let dataObservable: Observable<FileMeterData[]>;
    return this.fileMeterDataStoreService.getFileMeterDataListCount({ idPosition: [this.idPosition + ''] })
      .flatMap<number, FileMeterData[]>((num: number) => {
        Observable.of(num);
        this.dataLength = num;
        return this.fileMeterDataStoreService.getFileMeterDataForPosition(this.idPosition, limit, offset)
          .flatMap<FileMeterData[], FileMeterData[]>((file: FileMeterData[]) => {
            dataObservable = Observable.of(file);
            this.cdr.markForCheck();
            return dataObservable;
          });
      });
  }

  onPaginatorEvents(paginator: MatPaginator): Observable<FileMeterData[]> {
    /* Subscribe for changes that re-render table view */
    return paginator.page
      .concatMap<PageEvent, FileMeterData[]>((pageEvent: PageEvent) => {
        const limit = pageEvent.pageSize;
        const offset = pageEvent.pageIndex * pageEvent.pageSize;
        return this.loadData(limit, offset);
      });
  }

  onExternalDataChangeEvent(changeInductors: Observable<any>[]): Observable<FileMeterData[]> {
    return Observable.merge(...changeInductors)
      .concatMap<any, FileMeterData[]>(change => {
        return this.loadData();
      });
  }

  onFilterEvents(): Observable<FileMeterData[]> {
    this.paginator.pageIndex = 0;
    const limit = this.paginator.pageSize;
    const offset = this.paginator.pageIndex * this.paginator.pageSize;
    return this.loadData(limit, offset);
  }

  onClearFilters(): Observable<FileMeterData[]> {
    throw new Error('Method not implemented.');
  }

  onMediaTypeChange(): Observable<UtilityTypeEnum> {
    return this.cs.getObservable('utilityType');
  }


  ngOnDestroy(): void {
    if (this.loadDataSubscription) {
      this.loadDataSubscription.unsubscribe();
    }
    if (this.loadDataAfterViewInitSubscription) {
      this.loadDataAfterViewInitSubscription.unsubscribe();
    }
    if (this.paginatorEventsSubscription) {
      this.paginatorEventsSubscription.unsubscribe();
    }
    if (this.externalDataChangeEventsSubscription) {
      this.externalDataChangeEventsSubscription.unsubscribe();
    }
    if (this.filterDataEventsSubscription) {
      this.filterDataEventsSubscription.unsubscribe();
    }
  }

}
