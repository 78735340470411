import { IGetInterfaceType, ParameterType, UtilityTypeEnum } from '@shared/types';
import { BaseModel } from '@shared/models/baseModel';


export interface IMeasurementDataModel {
    id?: number;
    utilityType: UtilityTypeEnum | string;
    name: string;
    parsedName: string;
    type: ParameterType;
    description: string;
    unit?: string;
    isBilled: boolean;
    isCumulative: boolean;
    isPrimary: boolean;
    isMetadata?: boolean;
}

export class MeasurementDataModel extends BaseModel implements IMeasurementDataModel, IGetInterfaceType<IMeasurementDataModel, MeasurementDataModel> {
    id?: number;
    utilityType: UtilityTypeEnum | string;
    name: string;
    parsedName: string;
    type: ParameterType;
    description: string;
    unit?: string;
    isBilled: boolean;
    isCumulative: boolean;
    isPrimary: boolean;
    isMetadata?: boolean;

    constructor(Iin: IMeasurementDataModel) {
        super();
        if (!Iin) { throw new Error('Unable to construct MediaTypeParameter from API returned value'); }
        this.id = this.assignSimpleType<number>(Iin.id);
        this.utilityType = this.assignSimpleType<UtilityTypeEnum | string>(Iin.utilityType);
        this.name = this.assignSimpleType<string>(Iin.name);
        this.parsedName = this.assignSimpleType<string>(Iin.parsedName);
        this.type = this.assignSimpleType<ParameterType>(Iin.type);
        this.description = this.assignSimpleType<string>(Iin.description);
        this.unit = this.assignSimpleType<string>(Iin.unit);
        this.isCumulative = this.assignSimpleType<boolean>(Iin.isCumulative);
        this.isBilled = this.assignSimpleType<boolean>(Iin.isBilled);
        this.isPrimary = this.assignSimpleType<boolean>(Iin.isPrimary);
        this.isMetadata = this.assignSimpleType<boolean>(Iin.isMetadata);
    }


    getInterface(webInterface: boolean): IMeasurementDataModel {
        const emptyValue = (webInterface) ? null : undefined;
        return {
            id: this.id || emptyValue,
            utilityType: this.utilityType || emptyValue,
            name: this.name || emptyValue,
            parsedName: this.parsedName || emptyValue,
            type: this.type || emptyValue,
            description: this.description || emptyValue,
            unit: this.unit || emptyValue,
            isBilled: this.isBilled,
            isCumulative: this.isCumulative,
            isPrimary: this.isPrimary,
            isMetadata: this.isMetadata
        };
    }

}
