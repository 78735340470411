import { IGetInterfaceType, ParameterType } from '@shared/types';
import { BaseModel } from '@shared/models/baseModel';


export interface IMediaTypeParameter {
  name: string;
  type: ParameterType;
  description: string;
  unit?: string;
  cumulative: boolean;
}

export interface FlaternParameters {
  [key: string]: MediaTypeParameter;
}

export class MediaTypeParameter extends BaseModel implements IMediaTypeParameter, IGetInterfaceType<IMediaTypeParameter, MediaTypeParameter> {
  name: string;
  type: ParameterType;
  description: string;
  unit?: string;
  cumulative: boolean;

  constructor(Iin: IMediaTypeParameter) {
    super();
    if (!Iin) { throw new Error('Unable to construct MediaTypeParameter from API returned value'); }
    this.name = this.assignSimpleType<string>(Iin.name);
    this.type = this.assignSimpleType<ParameterType>(Iin.type);
    this.description = this.assignSimpleType<string>(Iin.description);
    this.unit = this.assignSimpleType<string>(Iin.unit);
    this.cumulative = this.assignSimpleType<boolean>(Iin.cumulative);
  }

  static flatternParameterArray(arrIn: MediaTypeParameter[]): FlaternParameters {
    return arrIn.reduce<FlaternParameters>((prev: FlaternParameters, curr: MediaTypeParameter, idx: number) => {
      return { ...prev, [curr.name]: curr };
    }, {} as FlaternParameters);
  }

  getInterface(webInterface: boolean): IMediaTypeParameter {
    const emptyValue = (webInterface) ? null : undefined;
    return {
      name: this.name || emptyValue,
      cumulative: this.cumulative || emptyValue,
      description: this.description || emptyValue,
      type: this.type || emptyValue,
    };
  }

}
