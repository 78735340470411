import { Inject, Pipe, PipeTransform } from "@angular/core";
import { DateAdapter } from "@angular/material/core";
import * as moment from "moment";
import { SV_DATETIME_FORMAT, SV_DATE_FORMAT } from "./date-formats";

@Pipe({ name: "svDate" })
export class DatePipe implements PipeTransform {
  constructor(
    private dateAdapter: DateAdapter<moment.Moment>,
    @Inject(SV_DATE_FORMAT) private dateFormat: string
  ) {}

  transform(value: string | number | Date | moment.Moment): string {
    if (!moment.isMoment(value)) {
      value = moment.utc(value);
    }
    return this.dateAdapter.format(value, this.dateFormat);
  }
}

@Pipe({ name: "svDateTime" })
export class DateTimePipe implements PipeTransform {
  constructor(
    private dateAdapter: DateAdapter<moment.Moment>,
    @Inject(SV_DATETIME_FORMAT) private dateFormat: string
  ) {}

  transform(value: string | number | Date | moment.Moment): string {
    if (!moment.isMoment(value)) {
      value = moment.utc(value);
    }
    return this.dateAdapter.format(value, this.dateFormat);
  }
}
