
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { MeterPoint } from '@shared/models';
import { environment } from '@env/environment';
import { UploadLocalisation as loc} from '../../../../upload.localisation';

@Component({
  selector: 'sv-disable-operational-dialog',
  templateUrl: './disable-operational.component.html',
  styleUrls: ['./disable-operational.sass']
})
export class DisableCustomerComponent implements OnInit {

  fg: FormGroup;

  public name: string;
  public actionButton: string;
  readonly assetLabels = loc[environment.language].texts.asset.assetDataPreview;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: MeterPoint,
    public dialogRef: MatDialogRef<DisableCustomerComponent>) {

   }

  ngOnInit() {
    this.fg = new FormGroup({
      disableSelector: new FormControl()
    });
  }

  onDisable() {
    this.dialogRef.close(this.fg.get('disableSelector').value);
  }

}
