import { Component, OnInit, ViewChild, ElementRef, ChangeDetectionStrategy, Output, EventEmitter, AfterViewInit, ChangeDetectorRef, OnDestroy } from '@angular/core';

import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatTable } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { PositionDataDataSource, PositionTabbed as PositionMeterDataTabbed } from './dataSource/positionDataSource';
import { ScheduleDataStoreService } from '@shared/services/dataStore/scheduleDataStore.service';
import { PositionDataStoreService } from '@shared/services/dataStore/positionDataStore.service';
import { FileMeterDataStoreService } from '@shared/services/dataStore/fileMeterDataDataStore.service';

import { environment } from '@env/environment';
import { Schedule, Position } from '@shared/models';
import { NestedTableHost, TableHost, LoadableTable } from '@shared/types';
import { INavigationContext } from '@shared/models/navigationCtx.model';

import { UploadLocalisation as loc } from '../../upload.localisation';
import { typesLocalisation } from '@shared/types/localisation';
import { CommonAppDataService } from '@shared/services/commonAppData.service';
import { MeasurementDataStoreService } from '@shared/services/dataStore/measurementDataDataStore.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { VeeStatusDataStoreService } from '@shared/services/dataStore/veeProcessDataStore.service';
import { VeeProcessStageEnum } from '@shared/types/modelTypes/veeProcessTypes';
// rxjs 6
import { Observable, Subscription, Subject } from 'rxjs';
import { concatMap, toArray, map, takeUntil, switchMap, debounceTime } from 'rxjs/operators';
import { from, of, merge } from 'rxjs';
import { MeasurementsUploadInterativeTutorialService } from '../measurementsUploadInteractiveTutorialService.service';
import { InteractiveTutorialService } from '@shared/services/interactiveTutorialService.service';

interface PositionPlus {
  position: Position;
  countFile: number;
  nameSchedule: string;
}

@Component({

  // tslint:disable-next-line:component-selector
  selector: 'sv-measurements-files-preview',
  templateUrl: './measurements-files-preview.component.html',
  styleUrls: ['./../../files-preview.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class MeasurementsFilesPreviewComponent implements OnInit, AfterViewInit, OnDestroy, NestedTableHost<PositionMeterDataTabbed>, TableHost<Position>, LoadableTable {

  @Output() changePreview: EventEmitter<INavigationContext>;
  @Output() changePreviewFile: EventEmitter<INavigationContext>;
  @Output() reloadFile: EventEmitter<number>;
  @Output() validationNavigate: EventEmitter<INavigationContext>;
  @ViewChild('table', { static: true }) dataTable: MatTable<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('filter') filter: ElementRef;

  readonly seeDetailsMdIcon: string = 'expand_more';
  readonly hideDetailsMdIcon: string = 'expand_less';
  readonly debugMode = environment.debug;

  readonly tableHeaders = loc[environment.language].texts.measurement.tableHeaders;
  readonly uploadCountersLabels = loc[environment.language].texts.measurement.uploadCountersLabels;
  readonly detailButtonsLabels = loc[environment.language].texts.measurement.detailButtonsLabels;
  readonly noData = loc[environment.language].texts.measurement.noData;
  readonly user = loc[environment.language].texts.measurement.user;
  readonly automatic = loc[environment.language].texts.measurement.automatic;
  readonly importType = loc[environment.language].texts.measurement.importType;
  readonly activeScheduler = loc[environment.language].texts.measurement.activeScheduler;
  readonly automaticScheduler = (environment.automaticScheduler === 'true');


  emitNavigationContext: INavigationContext;

  displayedColumns = this.automaticScheduler ? ['schedule', 'position', 'import', 'action'] : ['schedule', 'position', 'action'];

  dataSource: PositionDataDataSource;

  dataFetched: boolean = false;
  dataLength: number = 0;
  dataLoading: boolean;
  expandedElement: PositionMeterDataTabbed;
  actualShowElement: number;
  anomalyCount: number = 0;
  allCount: number = 0;
  actualPosition: Position;
  freezePosition: boolean;

  newFormGroup: FormGroup;
  poisitionPlus: PositionPlus[];
  loadDataSubscription: Subscription;
  onDestroy$: Subject<void> = new Subject<void>();
  paginatorEventsSubscription: Subscription;

  tabClicked: Subject<number> = new Subject();
  tabClickedSubsciprion: Subscription;

  readonly itemsPage = typesLocalisation.ItemsPage[environment.language].texts;
  constructor(
    private fileMeterDataStoreService: FileMeterDataStoreService,
    private scheduleDataStore: ScheduleDataStoreService,
    private positionDataStore: PositionDataStoreService,
    private communicationService: CommonAppDataService,
    private measurementService: MeasurementDataStoreService,
    private positionService: PositionDataStoreService,
    private fileMeterDataService: FileMeterDataStoreService,
    private veeStatus: VeeStatusDataStoreService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private interactiveTutorialService: InteractiveTutorialService,
    private measuUplIntServ: MeasurementsUploadInterativeTutorialService
  ) {
    this.expandedElement = null;
    this.changePreview = new EventEmitter<INavigationContext>();
    this.changePreviewFile = new EventEmitter<INavigationContext>();
    this.reloadFile = new EventEmitter<number>();
    this.validationNavigate = new EventEmitter<any>();
    this.dataSource = new PositionDataDataSource();
    this.newFormGroup = this.fb.group({
      activeScheduler: [false],
    });
    this.poisitionPlus = [];
  }

  ngOnDestroy(): void {
    if (this.changePreview) {
      this.changePreview.unsubscribe();
    }
    if (this.changePreviewFile) {
      this.changePreviewFile.unsubscribe();
    }
    if (this.reloadFile) {
      this.reloadFile.unsubscribe();
    }
    if (this.validationNavigate) {
      this.validationNavigate.unsubscribe();
    }
    if (this.loadDataSubscription) {
      this.loadDataSubscription.unsubscribe();
    }
    if (this.paginatorEventsSubscription) {
      this.paginatorEventsSubscription.unsubscribe();
    }
    this.onDestroy$.next();
  }

  ngOnInit() {
    this.paginator._intl.itemsPerPageLabel = this.itemsPage.itemsPage;
    this.paginator._intl.nextPageLabel = this.itemsPage.nextPage;
    this.paginator._intl.previousPageLabel = this.itemsPage.previousPage;
    this.paginator._intl.lastPageLabel = this.itemsPage.lastPage;
    this.paginator._intl.firstPageLabel = this.itemsPage.firstPage;
    this.loadDataSubscription = this.loadData()
      .subscribe((position: Position[]) => {
        this.dataSource.setNewData(position);
        this.paginator.length = this.dataLength;
        this.dataFetched = true;
      },
        (err: any) => {
          if (this.debugMode) { console.log(err); }
        },
        () => {
          if (this.debugMode) { console.log(`Init data fetch Finished`); }
        },
      );
    this.tabClickedSubsciprion = this.tabClicked.pipe(debounceTime(1500), switchMap(v => of(v))).subscribe(val => {
      if (val > -1) {
        this.startInteractiveTutorial(val);
      }
    });
  }

  startInteractiveTutorial(rowId: number) {
    const steps = this.measuUplIntServ.getMeasurementUploadFilesPreviewInteractiveTutorialSteps(rowId, this.freezePosition, this.automaticScheduler);
    this.interactiveTutorialService.startInteractiveTutorial(steps);
  }

  ngAfterViewInit(): void {

    this.paginatorEventsSubscription = this.onPaginatorEvents(this.paginator)
      .subscribe((pagedData: Position[]) => {
        this.dataSource.setNewData(pagedData);
      },
        (err: any) => { if (this.debugMode) { console.log(err); } },
        () => { if (this.debugMode) { console.log(`New Data triggered by paginator event`); } },
      );

    this.onExternalDataChangeEvent([
      this.fileMeterDataStoreService.getChangeObservable()
    ])
      .subscribe((pagedData: Position[]) => {
        if (this.debugMode) { console.log(`New Data triggered by external data change event`); }
        this.dataSource.setNewData(pagedData);
      },
        (err) => { if (this.debugMode) { console.log(err); } },
        () => { if (this.debugMode) { console.log(`Finished`); } }
      );
    this.cdr.detectChanges();
  }
  isActiv(position: PositionMeterDataTabbed) {
    this.freezePosition = false;
    if (this.expandedElement && this.expandedElement.rowId === position.rowId) {
      this.positionService.getPositionMeasurementDataCount
        (position.idPosition).subscribe(num => {
          this.allCount = num;
          this.fileMeterDataService.getFileMeterDataList({ limit: null, offset: 0 }, false, { idPosition: [position.idPosition.toString()] }).subscribe(file => {
            let anomaly = 0;
            file.forEach(t =>
              anomaly = anomaly + t.anomalies
            );
            this.anomalyCount = anomaly;
            this.tabClicked.next(position.rowId);
            this.cdr.markForCheck();
          });
        });
      this.veeStatus.getVeeStateForPosition(position.idPosition, true).subscribe(v => {
        this.freezePosition = !(v.stage && v.stage === VeeProcessStageEnum.FREEZE);
      });
      this.newFormGroup.get('activeScheduler').setValue(position.activeScheduler);
      this.cdr.markForCheck();
    }
    this.tabClicked.next(-1);
    return true;
  }

  loadData(limit: number = 10, offset: number = 0): Observable<Position[]> {
    this.poisitionPlus = [];
    this.positionDataStore.getAllPositionsList(null, { limit: null, offset: 0 }, true, false).subscribe((list) => {
      this.dataLength = list.length;
    });
    return this.positionDataStore.getAllPositionsList(null, { limit: limit, offset: offset }, true, false).pipe(
      concatMap<Position[], Observable<Position[]>>((filesList: Position[]): Observable<Position[]> => {

        return from(filesList).pipe(
          concatMap<Position, Observable<Schedule>>((file: Position) => {
            this.poisitionPlus.push({ position: file, countFile: 0, nameSchedule: '' });
            return this.scheduleDataStore.getScheduleById(file.idSchedule);
          }),
          concatMap<Schedule, Observable<number>>((sch: Schedule, idx: number) => {
            filesList[idx].idSchedule = sch.idSchedule;
            this.poisitionPlus[idx].nameSchedule = sch.name;
            const id = ['' + filesList[idx].idPosition];
            return this.fileMeterDataStoreService.getFileMeterDataListCount({ idPosition: id });
          }),
          concatMap<number, Observable<Position>>((num: number, idx: number) => {
            this.poisitionPlus[idx].countFile = num;
            return of(filesList[idx]);
          }),
          toArray(),
          map((p: Position[]) => {
            this.cdr.markForCheck();
            return filesList;
          }));
      }));
  }

  onPaginatorEvents(paginator: MatPaginator): Observable<Position[]> {
    return paginator.page.pipe(
      concatMap<PageEvent, Observable<Position[]>>((pageEvent: PageEvent) => {
        const limit = pageEvent.pageSize;
        const offset = pageEvent.pageIndex * pageEvent.pageSize;
        if (this.debugMode) { console.log(limit, offset); }
        this.cdr.markForCheck();
        return this.loadData(limit, offset);
      }));
  }

  onExternalDataChangeEvent(changeIndustores: Observable<any>[]): Observable<Position[]> {
    /* All change emitters related to operational data */
    return merge(...changeIndustores).pipe(
      takeUntil(this.onDestroy$),
      concatMap<any, Observable<Position[]>>((v: any) => {
        const limit = this.paginator.pageSize;
        const offset = this.paginator.pageIndex * limit;
        this.paginator.pageIndex = 0;
        this.cdr.markForCheck();
        return this.loadData(limit, offset);
      }));
  }

  /* NESTED TABLE CONTROL */
  isThisRowExpanded(row: PositionMeterDataTabbed): boolean {
    return this.expandedElement !== null ? this.expandedElement.rowId === row.rowId : false;
  }

  handleMainRowClick(row: PositionMeterDataTabbed, actual?: PositionMeterDataTabbed): PositionMeterDataTabbed {
    if (this.expandedElement !== null
      && this.expandedElement.rowId === row.rowId) {
      /* Same element clicked twice, hide expansion */
      this.expandedElement = null;
    } else {
      /* New element clicked, expand it */
      this.expandedElement = row;
    }
    return this.expandedElement;
  }

  rowCanBeExpanded(index: number, row: PositionMeterDataTabbed): boolean {
    return (row.detailRow === true);
  }

  getCountFile(position: Position): number {
    return this.poisitionPlus.find(f => f.position.idPosition === position.idPosition) ?
      this.poisitionPlus.find(f => f.position.idPosition === position.idPosition).countFile :
      0;
  }

  getNameSchedule(position: Position) {
    return this.poisitionPlus.find(f => f.position.idPosition === position.idPosition) ?
      this.poisitionPlus.find(f => f.position.idPosition === position.idPosition).nameSchedule :
      '';
  }

  isThisDetailElement(row: PositionMeterDataTabbed): boolean {
    return (this.expandedElement !== null
      && this.expandedElement.rowId === +row.rowId);
  }

  onFilterEvents(): Observable<Position[]> {
    this.cdr.markForCheck();
    return this.loadData();
  }

  onClearFilters(): Observable<Position[]> {
    this.cdr.markForCheck();
    return this.loadData();
  }

  getDataSourceLength(): number {
    return this.dataLength;
  }

  isLoading(): boolean {
    return !this.dataFetched;
  }

  onShowPreview(file: Position): void {
    if (this.debugMode) { console.log(file); }
    this.changePreview.emit({
      schedule: file.idSchedule,
      position: file.idPosition
    });
  }

  onShowFiles(file: Position): void {
    if (this.debugMode) { console.log(file); }
    this.changePreviewFile.emit({
      schedule: file.idSchedule,
      position: file.idPosition
    });
  }

  onReloadFile(file: Position) {
    if (this.debugMode) { console.log(file); }
    this.reloadFile.emit(file.idPosition);
  }

  onStartValidation(file: Position) {
    if (this.debugMode) { console.log(file); }
    this.validationNavigate.emit({
      schedule: file.idSchedule,
      position: file.idPosition
    });
  }

  offPositionsSchedulers(idPosition: Position, checked: MatSlideToggleChange) {
    const newPosition = new Position(idPosition);
    newPosition.activeScheduler = checked.checked;
    this.positionDataStore.getAllPositionsList(true, ({ limit: 1, offset: 0 })).subscribe(v => {
      if (v.length !== 0) {
        v[0].activeScheduler = false;
        this.positionDataStore.updatePosition(v[0]).subscribe(m => {
          this.updatePosition(newPosition);
        });
      } else {
        this.updatePosition(newPosition);
      }
    });
  }

  updatePosition(newPosition: Position) {
    this.positionDataStore.updatePosition(newPosition).subscribe(p => {
      const limit = this.paginator.pageSize;
      const offset = this.paginator.pageIndex * this.paginator.pageSize;
      this.loadData(limit, offset).subscribe((position: Position[]) => {
        this.dataSource.setNewData(position);
        this.cdr.markForCheck();
      });
    });
  }
}
