import { Component, OnInit, Inject, OnDestroy, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { CommonAppDataService } from '@shared/services/commonAppData.service';
import { UtilityTypeEnum } from '@shared/types';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MeasurementDataModel } from '@shared/models/appModels/measurementDataModel.model';
import { Subscription } from 'rxjs/Subscription';
import { typesLocalisation } from '@shared/types/localisation';
import { environment } from '@env/environment';
import { SettingsInteractiveTutorialService } from '../../settings-interactive-tutorial.service';
import { InteractiveTutorialService } from '@shared/services/interactiveTutorialService.service';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'dataModel-variable-edit',
    templateUrl: 'dataModel-variable-edit.component.html',
    styleUrls: ['dataModel-variable-edit.component.sass']
})

export class DataModelEditVariableComponent implements OnInit, OnDestroy, AfterViewInit {

    editVariableForm: FormGroup;
    utilityType: UtilityTypeEnum;
    desc: string;
    unit: string | null;
    isPrim: boolean;
    isPrimInForm: boolean;
    disableSubmitBtn: boolean = true;
    formValueChangesSubscripion: Subscription;
    readonly dataModelTxt = typesLocalisation.DataModelTexts[environment.language].texts;
    showTutFunc;

    constructor(private fb: FormBuilder, private cs: CommonAppDataService,
        @Inject(MAT_DIALOG_DATA) private selectedElement: { measurementDefData: MeasurementDataModel },
        public dialogRef: MatDialogRef<DataModelEditVariableComponent>,
        private interactiveTutorialService: InteractiveTutorialService,
        private settingsInteractiveTutorialService: SettingsInteractiveTutorialService) {
            this.desc = this.selectedElement.measurementDefData.description;
            this.isPrim = this.selectedElement.measurementDefData.isPrimary;
            this.isPrimInForm = this.selectedElement.measurementDefData.isPrimary;
            this.unit = (typeof(this.selectedElement.measurementDefData.unit) !== 'undefined') ? this.selectedElement.measurementDefData.unit : '';
        }

    ngOnInit() {

        this.editVariableForm = this.fb.group({
            'description': new FormControl(this.desc, [Validators.required]),
            'unit': new FormControl(this.unit),
            'is_primary': new FormControl(this.isPrim),
        });
        this.utilityType = this.cs.getCurrentMediaType();

        this.formValueChangesSubscripion = this.editVariableForm.valueChanges.subscribe(
            value => this.checkChangesInForm(value)
        );
    }

    onSubmitChanges() {
        this.selectedElement.measurementDefData.description = this.editVariableForm.get('description').value;
        this.selectedElement.measurementDefData.unit = this.editVariableForm.get('unit').value;
        this.selectedElement.measurementDefData.isPrimary = this.editVariableForm.get('is_primary').value;
        this.dialogRef.close(this.selectedElement.measurementDefData);
    }

    checkChangesInForm(value) {
        if (this.unit === value.unit && this.desc === value.description && this.isPrim === value.is_primary) {
            this.disableSubmitBtn = true;
        } else {
            this.disableSubmitBtn = false;
        }
    }

    startInteractiveTutorial(){
        const steps = this.settingsInteractiveTutorialService.getSettingsDataModelEditVariableModalInteractiveTutorialSteps();
        this.interactiveTutorialService.startInteractiveTutorial(steps);
    }

    ngAfterViewInit(){
        this.showTutFunc = setTimeout(()=> this.startInteractiveTutorial(), 1500);
    }

    ngOnDestroy() {
        this.formValueChangesSubscripion.unsubscribe();
        clearTimeout(this.showTutFunc);
    }
}
