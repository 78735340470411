<div id="dataExplorationWidgetContainer">
<div class="card-header">
	<h3 i18n="@@exploration/exploration panel title">Data Exploration</h3>
	<span class="header-element">
		<i class="material-icons notranslate">equalizer</i>
	</span>
</div>

<div class="card-body">
	<div class="content">
		<div *ngIf="algorithms$ | async as algorithms; else loading">
			<ul class="card-body list-group">
				<table>
					<tr *ngFor="let item of algorithms" [matTooltip]="getTranslatedAlgorithmName(item.description)" matTooltipPosition="below"
						matTooltipShowDelay="500">
						<td>
							{{getTranslatedAlgorithmName(item.name)}}
						</td>
						<td style="text-align:right">
							<i class="material-icons notranslate pointer" (click)="navigateToAlgorithm(algorithms, item.idAnalysisAlgorithm)">arrow_right</i>
						</td>
					</tr>
				</table>
			</ul>
			<div *ngIf="algorithms.length > 0" class="text-center">
				<a (click)="navigateToExploration()" mat-raised-button color="primary" class="btn" i18n="@@widget/process details button label">Details</a>
			</div>
		</div>
		<ng-template #loading>
			<div class="content">
				<mat-spinner style="margin: 0 auto" diameter="14"></mat-spinner>
			</div>
		</ng-template>
</div>
</div>