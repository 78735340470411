// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
import { IEnvironment } from '@env/environmentIfc';
import { EndpointsEnum } from '@shared/types/http/endpointEnum';
import { UtilityTypeEnum } from '@shared/types/utilityType';
import { LanguagesEnum } from '@shared/types/localisation';

export const environment: IEnvironment = {
  language: LanguagesEnum.GER, // IGNORED, will be replaced with value from gitlab pipeline
  production: false,
  debug: false,
  authDebug: false,
  randomizeMock: true,
  backendPath: 'MY_ADDR',
  heatSupport: 'HEAT_SUPPORT',
  waterSupport: 'WATER_SUPPORT',
  electricitySupport: 'ELECTRICITY_SUPPORT',
  automaticScheduler: 'AUTOMATIC_SCHEDULER',
  defaultReqLimit: 50,
  // tslint:disable-next-line: max-line-length
  defaultMediaType: Boolean(JSON.parse('HEAT_SUPPORT')) ? UtilityTypeEnum.HEAT : (Boolean(JSON.parse('ELECTRICITY_SUPPORT')) ? UtilityTypeEnum.ELECTRICITY : UtilityTypeEnum.WATER),
  defaultStatusDownloadData: false,
  veeStatusTimeout: 30000,
  servcesConfiguration: [
    { mockEnabled: false, time: 2000, verbose: true, endpointE: EndpointsEnum.SCHEDULES },
    { mockEnabled: false, time: 2000, verbose: true, endpointE: EndpointsEnum.POSITIONS },
    { mockEnabled: false, time: 2000, verbose: true, endpointE: EndpointsEnum.FILE_OPERATIONAL_DATA },
    { mockEnabled: false, time: 2000, verbose: true, endpointE: EndpointsEnum.FILE_METER_DATA },
    { mockEnabled: false, time: 100, verbose: true, endpointE: EndpointsEnum.CUSTOMER },
    { mockEnabled: false, time: 100, verbose: true, endpointE: EndpointsEnum.METER_POINT },
    { mockEnabled: false, time: 100, verbose: true, endpointE: EndpointsEnum.MEASUREMENT_DATA },
    { mockEnabled: false, time: 100, verbose: true, endpointE: EndpointsEnum.METER_DATA },
    { mockEnabled: false, time: 2000, verbose: true, endpointE: EndpointsEnum.VEE_STATUS },
    { mockEnabled: false, time: 100, verbose: true, endpointE: EndpointsEnum.VEE_RULE },
    { mockEnabled: false, time: 100, verbose: true, endpointE: EndpointsEnum.DATA_MODEL_TYPE },
    { mockEnabled: false, time: 100, verbose: true, endpointE: EndpointsEnum.TIME_GROUPS },
    { mockEnabled: false, time: 100, verbose: true, endpointE: EndpointsEnum.PARAMETERS },
    { mockEnabled: false, time: 100, verbose: true, endpointE: EndpointsEnum.ANALYSIS_ALGORITHM },
    { mockEnabled: false, time: 100, verbose: true, endpointE: EndpointsEnum.ANALYSIS_STATUS },
    { mockEnabled: false, time: 100, verbose: true, endpointE: EndpointsEnum.SENSOR_DATA },
    { mockEnabled: false, time: 100, verbose: false, endpointE: EndpointsEnum.FILE_TEMPERATUTE_DATA },
    { mockEnabled: false, time: 100, verbose: false, endpointE: EndpointsEnum.DATA_IMPORT },
  ],
  keyCloakConstructParams: {
    url: 'KEYCLOAK_URL', /* Authorization server URL */
    realm: 'KEYCLOAK_REALM',
    clientId: 'KEYCLOAK_CLIENT',
    'ssl-required': 'external',
    'public-client': true,
    'auth-server-url': 'KEYCLOAK_URL', /* Authorization server URL */
    'confidential-port': 0,
    resource: 'KEYCLOAK_CLIENT',
    logoutUrl: 'MY_ADDR:8081/home', /* Redirection URI after logout() */
    redirect_uri: 'MY_ADDR:8081/home' /* Redirection URI after failed KC operation */
  },
  keyCloakInitParams: {
    onLoad: 'login-required',
    promiseType: 'native'
  },
  authConfig: {
    authRequired: true, /* Disable/enable login via KC external serwer - WILL BE REPLACED FROM AUTH_REQUIRED on deploy! */
    tokenValidUntilTime: 60,
    tokenAutoRefresh: true,
  },
  sourceMap: false,
  analysisStatusTimeout: 30000,
};
