<form [formGroup]="ruleForm" (ngSubmit)="onSubmitNewRule($event, ruleForm)" novalidate>
    <div id="createRuleContainer" class="container-form">
        <div class="row">
            <mat-form-field class="col">
                <input matInput placeholder="Name" i18n-placeholder="@@data validatation/new rule modal/texts/name" formControlName="name" name="name">
                <mat-error *ngIf="ruleForm.get('name').touched && ruleForm.get('name').errors?.required" i18n="@@data validatation/new rule modal/errors/name required">
                    Please enter a name.
                </mat-error>
                <mat-error *ngIf="ruleForm.get('name').touched && ruleForm.get('name').errors?.nameIsOccupied" i18n="@@data validatation/new rule modal/errors/name is occupied">
                    This name is occupied!
                </mat-error>
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field class="col">
                <input matInput placeholder="Description" i18n-placeholder="@@data validatation/new rule modal/texts/description" formControlName="description" name="description">
                <mat-error *ngIf="ruleForm.get('description').invalid && ruleForm.get('description').touched" i18n="@@data validatation/new rule modal/errors/description required">
                    Please enter a description.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field class="col">
                <input matInput placeholder="Recommendation" i18n-placeholder="@@data validatation/new rule modal/texts/recommendation" formControlName="recommendation" name="recommendation">
                <mat-error *ngIf="ruleForm.get('recommendation').invalid && ruleForm.get('recommendation').touched" i18n="@@data validatation/new rule modal/errors/recommendation required">
                    Please enter a recommendation.
                </mat-error>
            </mat-form-field>
        </div>

        <mat-error *ngIf="ruleForm.invalid && ruleForm.touched" i18n="@@data validatation/new rule modal/errors/invalid data first step">
            Please enter a valid data.
        </mat-error>
        <div class="form-btn right-btn">
            <button id="nextStepBtn" [disabled]='ruleForm.invalid || nextStepBtnDisbaled' mat-raised-button color="accent" class="btn btn-action" i18n="@@data validatation/new rule modal/texts/next step">Next Step</button>
        </div>
    </div>
</form>