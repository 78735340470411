import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonAppDataService } from '../commonAppData.service';
import { EndpointsEnum } from '@shared/types';
import { Observable, of } from 'rxjs';
import { AutomaticImportConfigurationHttpParamG } from '@shared/types/http/automaticImportConfigForSchedulePositionHttpConfig';
import { RestTypeMapper } from '@shared/models/RestSupport';
import { IAutomaticImportConfigForSchedulePosition, AutomaticImportConfigForSchedulePosition } from '@shared/models/appModels/automaticImportConfigurationForSchedulePosition.model';
import { RequestService } from './requestService.class';

@Injectable({providedIn: 'root'})
export class DataImportService extends RequestService {


     /* Data Rest <-> Object Mappers */
    private autoamticImportMapper: RestTypeMapper<IAutomaticImportConfigForSchedulePosition, AutomaticImportConfigForSchedulePosition>;

    constructor(http: HttpClient, cs: CommonAppDataService) {
        super(http, cs, EndpointsEnum.DATA_IMPORT);
        this.autoamticImportMapper = new RestTypeMapper<IAutomaticImportConfigForSchedulePosition, AutomaticImportConfigForSchedulePosition>();
    }

    postAutomaticImportConfigurationForSchedulePosition(params: AutomaticImportConfigurationHttpParamG)
    : Observable<AutomaticImportConfigForSchedulePosition[]> {
        const thisApiID = 'data_import';
        if (this.MOCK_SERVICE) {
            return of(params.body);
        } else {
            return this.postRequest(params.body, params, this.autoamticImportMapper, thisApiID, AutomaticImportConfigForSchedulePosition);
        }
    }
}
