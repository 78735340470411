import { MeterPoint } from '@shared/models';
import { TableDataSource } from '@shared/models/tableDataSource.model';
import { CommonAppDataService } from '@shared/services/commonAppData.service';


export class OperationalDataSource extends TableDataSource<MeterPoint, MeterPoint> {
  constructor(private communicationService: CommonAppDataService) { super(); }

  protected renderData(data: MeterPoint[]): MeterPoint[] {
    return data.filter(m => m.utilityType === this.communicationService.getCurrentMediaType());
  }
}

