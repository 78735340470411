import { Injectable } from "@angular/core";
import { InteractiveTutorialService, NextComponentName, TutorialButtonLabel } from "@shared/services/interactiveTutorialService.service";
import { AnalysisAlgorithmTypeEnum } from "@shared/types/modelTypes/analysisAlgorithmTypes";

@Injectable({ providedIn: "root" })
export class DataAnalaysisInteractiveTutorialService {
  constructor(private interactiveTutorialService: InteractiveTutorialService) {}

  getDataAnalysisPageInteractiveTutorialSteps(showReadlist: boolean) {
    const step = this.interactiveTutorialService.generateStep(
      "#svSelect",
      "bottom",
      $localize`:@@interactive tutorial/data analysis/schedule and position selector:With these two fields you can specify the current schedule and position.`,
      $localize`:@@interactive tutorial/data analysis/schedule and position selector title:Selection of the schedule and position`,
      [TutorialButtonLabel.NEXT]
    );
    const step1 = this.interactiveTutorialService.generateStep(
      "#svAlgorithmsTypeSelect",
      "bottom",
      $localize`:@@interactive tutorial/data analysis/algorithm type selector:Here you can select analysis algorithm type.`,
      $localize`:@@interactive tutorial/data analysis/algorithm type selector title:Analysis algorithm type selector`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
    );
    const step2 = this.interactiveTutorialService.generateStep(
      "#svAlgortihmSelect",
      "bottom",
      $localize`:@@interactive tutorial/data analysis/algorithms selector:Here you select the algorithms that will be used to perform the analysis process.`,
      $localize`:@@interactive tutorial/data analysis/algorithms selector title:Algorithms selector`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.COMPLETE],
      undefined,
      undefined,
      {
        complete: () => {
          if (showReadlist) {
            this.interactiveTutorialService.canGoToNextStepSubject.next(
              NextComponentName.DATA_ANALYSIS_READLIST
            );
          }
        },
      }
    );
    const steps: unknown[] = [step, step1, step2];
    return steps;
  }

  getDataAnalysisReadlistInteractiveTutorialService() {
    const step = this.interactiveTutorialService.generateStep(
      "#metersFilter",
      "top",
      $localize`:@@interactive tutorial/asset upload/data preview/meter filter:This field allows you to filter the data in the table below by meter ID.`,
      $localize`:@@interactive tutorial/asset upload/data preview/meter filter title:Filter by meter ID`,
      [TutorialButtonLabel.NEXT]
    );
    const step1 = this.interactiveTutorialService.generateStep(
      "#aggregationSelector",
      "top",
      $localize`:@@interactive tutorial/data analysis/aggregation filter:This field allows you to specify the type of data aggregation.`,
      $localize`:@@interactive tutorial/data analysis/aggregation filter title:Aggregation type`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
    );
    const step2 = this.interactiveTutorialService.generateStep(
      "#startDate",
      "top",
      $localize`:@@interactive tutorial/data analysis/start date:This field allows you to specify a start date for the data that will be used in the analysis process.`,
      $localize`:@@interactive tutorial/data analysis/start date title:Start date`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
    );
    const step3 = this.interactiveTutorialService.generateStep(
      "#endDate",
      "top",
      $localize`:@@interactive tutorial/data analysis/end date:This field allows you to specify an end date for the data that will be used in the analysis process.`,
      $localize`:@@interactive tutorial/data analysis/end date title:End date`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
    );
    const step4 = this.interactiveTutorialService.generateStep(
      "#location",
      "top",
      $localize`:@@interactive tutorial/data analysis/location:This field allows you to specify the location of the meter points that will be used in the analysis process.`,
      $localize`:@@interactive tutorial/data analysis/location title:Location`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
    );
    const step5 = this.interactiveTutorialService.generateStep(
      "#selectAll",
      "top",
      $localize`:@@interactive tutorial/data analysis/select all:By clicking this button, you can automatically select/unselect all available meter points.`,
      $localize`:@@interactive tutorial/data analysis/select all title:Select/Unselect all`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
    );
    const step6 = this.interactiveTutorialService.generateStep(
      "#startAnalysis",
      "top",
      $localize`:@@interactive tutorial/data analysis/start analysis:By clicking this button, we can start the analysis process.`,
      $localize`:@@interactive tutorial/data analysis/start analysis title:Start analysis`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
    );
    const step7 = this.interactiveTutorialService.generateStep(
      "#metersTable",
      "top",
      $localize`:@@interactive tutorial/data analysis/meters table:This table lists the available meter points.`,
      $localize`:@@interactive tutorial/data analysis/meters table title:Meter points table`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.COMPLETE]
    );
    const steps: unknown[] = [
      step,
      step1,
      step2,
      step3,
      step4,
      step5,
      step6,
      step7,
    ];
    return steps;
  }

  getDataAnalysisSummaryInteractiveTutorialSteps(
    actualAlgorithm: string | AnalysisAlgorithmTypeEnum
  ) {
    const step = this.interactiveTutorialService.generateStep(
      "#summary",
      "top",
      $localize`:@@interactive tutorial/data analysis/summary/summary:Here we can see the filters used in the analysis process.`,
      $localize`:@@interactive tutorial/data analysis/summary/summary title:Filters applied`,
      [TutorialButtonLabel.NEXT]
    );
    const step1 = this.interactiveTutorialService.generateStep(
      "#meterFilter",
      "top",
      $localize`:@@interactive tutorial/asset upload/data preview/meter filter:This field allows you to filter the data in the table below by meter ID.`,
      $localize`:@@interactive tutorial/asset upload/data preview/meter filter title:Filter by meter ID`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
    );
    const step2 = this.interactiveTutorialService.generateStep(
      "#locationFilter",
      "top",
      $localize`:@@interactive tutorial/data analysis/summary/location:This field allows you to filter the data in the table below by the location of the counters.`,
      $localize`:@@interactive tutorial/data analysis/summary/location title:Location`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
    );
    const step3 = this.interactiveTutorialService.generateStep(
      "#deviation",
      "top",
      $localize`:@@interactive tutorial/data analysis/summary/deviation:This slider allows you to change deviation of analysis algorithm.`,
      $localize`:@@interactive tutorial/data analysis/summary/deviation title:Deviation`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT],
      () => {
        if (actualAlgorithm === 0) {
          return true;
        } else {
          return false;
        }
      }
    );
    const step4 = this.interactiveTutorialService.generateStep(
      "#mainTable",
      "top",
      $localize`:@@interactive tutorial/data analysis/summary/results:This table shows analysis results.By clicking on specific accordion tab you will see chart and some details about data.`,
      $localize`:@@interactive tutorial/data analysis/summary/results title:Analysis results`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.COMPLETE]
    );
    const steps: unknown[] = [step, step1, step2, step3, step4];
    return steps;
  }

  getDataAnalysisSummaryDetailsInteractiveTutorialSteps(
    actualAlgorithm: string | AnalysisAlgorithmTypeEnum
  ) {
    const step = this.interactiveTutorialService.generateStep(
      "#chart",
      "top",
      $localize`:@@interactive tutorial/data analysis/summary/detail/chart:Here you can see a graph that represents the results of your analysis.`,
      $localize`:@@interactive tutorial/data analysis/summary/detail/chart title:Graph of the analysis results`,
      [TutorialButtonLabel.NEXT],
      undefined,
      true
    );
    const step1 = this.interactiveTutorialService.generateStep(
      "#anomalyDetection",
      "top",
      $localize`:@@interactive tutorial/data analysis/summary/detail/analysis results:Here you can see a data details.`,
      $localize`:@@interactive tutorial/data analysis/summary/detail/analysis results title:Graph of the analysis results`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.COMPLETE],
      () => {
        if (actualAlgorithm === 0) {
          return true;
        } else {
          return false;
        }
      }
    );
    const step2 = this.interactiveTutorialService.generateStep(
      "#forecasting",
      "top",
      $localize`:@@interactive tutorial/data analysis/summary/detail/analysis results:Here you can see a data details.`,
      $localize`:@@interactive tutorial/data analysis/summary/detail/analysis results title:Graph of the analysis results`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.COMPLETE],
      () => {
        if (actualAlgorithm === 1) {
          return true;
        } else {
          return false;
        }
      }
    );
    const steps: unknown[] = [step, step1, step2];
    return steps;
  }
}
