import { Injectable } from "@angular/core";

import {
  TimelineEntry,
  Schedule,
  Position,
  PositionEvent,
  CardType,
} from "@shared/models";
import { EventType, ValidationResultEnum, EventStatus } from "@shared/types";

import { PositionDataStoreService } from "@shared/services/dataStore/positionDataStore.service";
import { AnalysisStatusDataStoreService } from "@shared/services/dataStore/analysisProcessDataStore.service";
import { FileMeterDataStoreService } from "@services/dataStore/fileMeterDataDataStore.service";

import { Observable, forkJoin } from "rxjs";
import { tap, map } from "rxjs/operators";
import * as moment from "moment";

@Injectable()
export class TimelineSupportService {
  constructor(
    private positionService: PositionDataStoreService,
    private analysisStatusService: AnalysisStatusDataStoreService,
    private fileMeterDataService: FileMeterDataStoreService
  ) {}

  convertPositionsToTimelineEvents(
    schedule: Schedule
  ): Observable<TimelineEntry[]> {
    const entries: Observable<TimelineEntry>[] = [];
    for (const position of schedule.positions) {
      for (const event of position.events) {
        const entry = TimelineEntry.generate(schedule, position, event);
        entries.push(this.postGenerateEdits(entry, position, event));
      }
    }

    return forkJoin(entries);
  }

  private postGenerateEdits(
    entry: TimelineEntry,
    position: Position,
    event: PositionEvent
  ): Observable<TimelineEntry> {
    if (position.idPosition === undefined) {
      return Observable.of(entry);
    }
    switch (event.typeOf) {
      case EventType.UPLOAD: {
        return this.fetchImportDynamicData(entry, position);
      }
      case EventType.VALIDATION: {
        return this.fetchValidationDynamicData(entry, position);
      }
      case EventType.EXPORT: {
        return this.fetchExportDynamicData(entry, position, event);
      }
      case EventType.ANALYSIS: {
        return this.fetchAnalysisDynamicData(entry, position, event);
      }
      default: {
        return Observable.of(entry);
      }
    }
  }

  private fetchImportDynamicData(
    entry: TimelineEntry,
    position: Position
  ): Observable<TimelineEntry> {
    return forkJoin([
      this.positionService.getPositionMeasurementDataCount(position.idPosition),
      this.fileMeterDataService.getFileMeterDataList(
        { limit: null, offset: 0 },
        false,
        { idPosition: [position.idPosition.toString()] }
      ),
    ]).pipe(
      tap(([num, files]) => {
        let anomaly = 0;
        files.forEach((t) => (anomaly = anomaly + t.anomalies));
        entry.dataSuccessValue = num;
        entry.dataErrorValue = anomaly;
      }),
      map((_) => entry)
    );
  }

  private fetchValidationDynamicData(
    entry: TimelineEntry,
    position: Position
  ): Observable<TimelineEntry> {
    return forkJoin([
      this.positionService.getPositionMeasurementDataCount(position.idPosition),
      this.positionService.getPositionMeasurementDataCount(
        position.idPosition,
        { validationResult: ValidationResultEnum.INVALID }
      ),
    ]).pipe(
      tap(([cnt, val]) => {
        entry.dataSuccessValue = cnt;
        entry.dataErrorValue = val;
      }),
      map((_) => entry)
    );
  }

  private fetchExportDynamicData(
    entry: TimelineEntry,
    position: Position,
    event: PositionEvent
  ): Observable<TimelineEntry> {
    return this.positionService
      .getPositionMeterPointsCount(position.idPosition)
      .pipe(
        tap((count) => {
          if (event.status === EventStatus.DONE) {
            entry.dataSuccessValue = count;
            entry.dataErrorValue = 0;
          } else {
            entry.dataSuccessValue = 0;
            entry.dataErrorValue = count;
          }
        }),
        map((_) => entry)
      );
  }

  private fetchAnalysisDynamicData(
    entry: TimelineEntry,
    position: Position,
    event: PositionEvent
  ): Observable<TimelineEntry> {
    entry.dataSuccessValue = 0;

    const meterPointCount$ = this.positionService.getPositionMeterPointsCount(
      position.idPosition
    );
    const analysisState$ = this.analysisStatusService.getAnalysisStateForPosition(
      position.idPosition,
      "DETECTION_OF_ANOMALIES",
      true
    );
    const analysisCount$ = this.positionService.getPositionAnalysisCount(
      position.idPosition,
      { anomaly: true }
    );
    const forecastingCount$ = this.positionService.getPositionForecastingCount(
      position.idPosition,
      { anomaly: false }
    );

    let result: Observable<any>;
    if (event.status === EventStatus.DONE) {
      result = forkJoin([
        analysisState$,
        analysisCount$,
        meterPointCount$,
        forecastingCount$,
      ]).pipe(
        tap(([state, analysisCount, meterPointCount, forecastingCount]) => {
          if ([undefined, "", "all"].includes(state.meterId)) {
            entry.dataSuccessValue = meterPointCount;
          } else {
            entry.dataSuccessValue = state.meterId.split(",").length;
          }
          entry.dataErrorValue = analysisCount;
          entry.dataSuccessValue2 = meterPointCount;
          entry.dataErrorValue2 = forecastingCount;
        })
      );
    } else {
      result = analysisCount$.pipe(
        tap((count) => (entry.dataErrorValue = count))
      );
    }

    return result.pipe(map((_) => entry));
  }

  stackTimelineEvents(tleArr: TimelineEntry[]): TimelineEntry[][] {
    /* Results - stacked eventd grouped by date */
    const stackedEntries: TimelineEntry[][] = [];
    /* intermediate indexer object */
    const stackedIdx: {
      date: moment.Moment;
      indexes: number[];
      type: EventType;
    }[] = [];

    const dates = tleArr.map((tle: TimelineEntry) => tle.dueDate);
    const types = tleArr.map((tle: TimelineEntry) => tle.type);

    /* Keep track of 'today' position */
    const todayIdx = tleArr.findIndex((el: TimelineEntry) => {
      return el.cardType === CardType.TODAY;
    });

    /* Build up indexer */
    dates.forEach((date, mainIndex) => {
      const findResult = stackedIdx.findIndex((indexer) => {
        return (
          indexer.date.isSame(dates[mainIndex]) &&
          indexer.type === types[mainIndex]
        );
      });

      /* this date was not already registered in indexer OR is today */
      if (findResult === -1 || findResult === todayIdx) {
        stackedIdx.push({
          date: date,
          indexes: [mainIndex],
          type: tleArr[mainIndex].type,
        });
        /* this date with same type was already registered - add occurence index */
      } else {
        stackedIdx[findResult].indexes.push(mainIndex);
      }
    });

    stackedIdx.forEach((stackedIndexer, mainIdx) => {
      stackedEntries[mainIdx] = [];
      stackedIndexer.indexes.forEach((element) => {
        stackedEntries[mainIdx].push(tleArr[element]);
      });
    });

    const lgt = stackedEntries.length;
    stackedEntries.forEach((el: TimelineEntry[], idx: number) => {
      if (idx + 1 < lgt) {
        if (
          el[0].type > stackedEntries[idx + 1][0].type &&
          el[0].dueDate.isSame(stackedEntries[idx + 1][0].dueDate)
        ) {
          const tmp = el;
          stackedEntries[idx] = stackedEntries[idx + 1];
          stackedEntries[idx + 1] = tmp;
        }
      }
    });

    return stackedEntries.slice(0);
  }
}
