import { RestEnumMpapper } from '@shared/models/RestSupport';
import { AnalysisProcessStageEnum, AnalysisProcessStatusEnum } from '@shared/types/modelTypes/analysisProcessTypes';
import { AnalysisAlgorithmSeverityEnum, AnalysisAlgorithmTypeEnum, AnalysisStatusEnum } from '@shared/types/modelTypes/analysisAlgorithmTypes';
import { IGetInterfaceType } from '@shared/types/applicationTypes';
import { UtilityTypeEnum } from '@shared/types/utilityType';

export interface IAnalysisAlgorithm {
  idAnalysisAlgorithm: number;
  name: string;
  description: string;
  algorithmType: AnalysisAlgorithmTypeEnum | string;
  switchable: boolean;
  enabled: boolean;
  analysis: AnalysisStatusEnum | string;
  nameFile: string;
  recommendation?: string;
  svUtilityType?: UtilityTypeEnum | string;
}

export class AnalysisAlgorithm implements IAnalysisAlgorithm, IGetInterfaceType<IAnalysisAlgorithm, AnalysisAlgorithm> {

  /* Mappers */
  static MediaTypeMapper: RestEnumMpapper<typeof UtilityTypeEnum> = new RestEnumMpapper<typeof UtilityTypeEnum>();
  static ruleSeverityMapper: RestEnumMpapper<typeof AnalysisAlgorithmSeverityEnum> = new RestEnumMpapper<typeof AnalysisAlgorithmSeverityEnum>();
  static ruleTypeMapper: RestEnumMpapper<typeof AnalysisAlgorithmTypeEnum> = new RestEnumMpapper<typeof AnalysisAlgorithmTypeEnum>();
  static statusTypeMapper: RestEnumMpapper<typeof AnalysisStatusEnum> = new RestEnumMpapper<typeof AnalysisStatusEnum>();

  idAnalysisAlgorithm: number;
  name: string;
  description: string;
  algorithmType: AnalysisAlgorithmTypeEnum | string;
  switchable: boolean;
  enabled: boolean;
  analysis: AnalysisStatusEnum | string;
  nameFile: string;
  recommendation?: string;
  requestedStage?: AnalysisProcessStageEnum | string;
  stage?: AnalysisProcessStageEnum | string;
  status?: AnalysisProcessStatusEnum | string;
  svUtilityType?: UtilityTypeEnum | string;

  constructor(iAnalysisAlgorithm: IAnalysisAlgorithm) {
    if (!iAnalysisAlgorithm) {
      throw new Error('No interface object provided to constructor');
    }
    this.idAnalysisAlgorithm = (typeof (iAnalysisAlgorithm.idAnalysisAlgorithm) !== 'undefined') ? iAnalysisAlgorithm.idAnalysisAlgorithm : undefined;
    this.name = (typeof (iAnalysisAlgorithm.name) !== 'undefined') ? iAnalysisAlgorithm.name : undefined;
    this.description = (typeof (iAnalysisAlgorithm.description) !== 'undefined') ? iAnalysisAlgorithm.description : undefined;

    this.algorithmType = (typeof (iAnalysisAlgorithm.algorithmType) !== 'undefined') ?
      AnalysisAlgorithm.ruleTypeMapper.getEnumAsNumber(AnalysisAlgorithmTypeEnum, iAnalysisAlgorithm.algorithmType) : undefined;

    this.switchable = (typeof (iAnalysisAlgorithm.switchable) !== 'undefined') ? iAnalysisAlgorithm.switchable : undefined;
    this.enabled = (typeof (iAnalysisAlgorithm.enabled) !== 'undefined') ? iAnalysisAlgorithm.enabled : undefined;
    // tslint:disable-next-line: max-line-length
    this.analysis = (typeof (iAnalysisAlgorithm.analysis) !== 'undefined') ? AnalysisAlgorithm.statusTypeMapper.getEnumAsNumber(AnalysisStatusEnum, iAnalysisAlgorithm.analysis) : undefined;
    this.nameFile = (typeof (iAnalysisAlgorithm.nameFile) !== 'undefined') ? iAnalysisAlgorithm.nameFile : undefined;
    this.recommendation = (typeof (iAnalysisAlgorithm.recommendation) !== 'undefined') ? iAnalysisAlgorithm.recommendation : undefined;
    this.svUtilityType = (typeof (iAnalysisAlgorithm.svUtilityType) !== 'undefined') ?
      this.assgnMediaType((typeof (iAnalysisAlgorithm.svUtilityType))) : undefined;
  }

  assgnMediaType(eIn: UtilityTypeEnum | string): UtilityTypeEnum {
    try {
      return AnalysisAlgorithm.MediaTypeMapper.getEnumAsNumber(UtilityTypeEnum, eIn);
    } catch (e) {
      return undefined;
    }
  }


  getInterface(webInterface: boolean): IAnalysisAlgorithm {
    const emptyValue = (webInterface) ? null : undefined;
    return {
      idAnalysisAlgorithm: this.idAnalysisAlgorithm || emptyValue,
      name: this.name || emptyValue,
      description: this.description || emptyValue,
      algorithmType: AnalysisAlgorithm.ruleTypeMapper.getEnumAsString(AnalysisAlgorithmTypeEnum, this.algorithmType) || emptyValue,
      switchable: this.switchable || emptyValue,
      enabled: this.enabled || emptyValue,
      analysis: AnalysisAlgorithm.statusTypeMapper.getEnumAsString(AnalysisStatusEnum, this.analysis) || emptyValue,
      nameFile: this.nameFile || emptyValue,
      svUtilityType: AnalysisAlgorithm.MediaTypeMapper.getEnumAsString(UtilityTypeEnum, this.svUtilityType) || emptyValue
    };
  }

}
