<form [formGroup]="addAssetForm" (ngSubmit)="onFormSubmit($event)" class="dialog-form">
	<h3 class="dialog-title">{{name}}</h3>

	<h4 class="caption">{{assetLabels.meter}}</h4>
<div id="firstSection">
	<div class="row">
		<mat-form-field class="col">
			<input matInput formControlName="serialMeter" placeholder="Meter ID">
      <mat-error *ngIf="addAssetForm.get('serialMeter').invalid">
        <ng-container *ngIf="addAssetForm.get('serialMeter').hasError('required')">{{assetLabels.meterIs}} <strong>{{measurementsLabels.required}}</strong></ng-container>
        <ng-container *ngIf="addAssetForm.get('serialMeter').hasError('duplicateSerialNumber')">{{assetLabels.meterMustBe}} <strong>{{assetLabels.unique}}</strong></ng-container>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="col">
			<input matInput formControlName="address" placeholder="{{assetLabels.address}}" >
		</mat-form-field>

	</div>

	<div class="row">
		<mat-form-field class="col">
			<mat-select formControlName="selectedMeasurementTypeControl" placeholder="{{assetLabels.tableHeaders.MES_TYPE}}">
				<mat-option *ngFor="let measurement of selectedMeasurements" [value]="measurement.id">
					{{ measurement.name }}
				</mat-option>
			</mat-select>
		</mat-form-field>

		<mat-form-field class="col">
			<mat-select formControlName="selectedMeterNodeTypeControl" placeholder="{{assetLabels.meterType}}">
				<mat-option *ngFor="let node of selectedMeterNode" [value]="node.id">
					{{ node.name }}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>

	<div class="row">
		<mat-form-field class="col">
      <input matInput formControlName="timeGroup" placeholder="{{assetLabels.timeGroup}}">
      <mat-error *ngIf="addAssetForm.get('timeGroup').invalid">
        <ng-container *ngIf="addAssetForm.get('timeGroup').hasError('pattern')">{{assetLabels.timeGroup}} <strong>must</strong> be a number</ng-container>
      </mat-error>
		</mat-form-field>

		<div class="col">
			<mat-slide-toggle formControlName="isMeterBilled">{{assetLabels.billed}}</mat-slide-toggle>
			<mat-slide-toggle formControlName="isMeterActive">{{assetLabels.avtive}}</mat-slide-toggle>
		</div>
  </div>

  <div formGroupName="config">
		<div class="row">
			<mat-form-field class="col">
        <input matInput formControlName="maxRead" placeholder="{{assetLabels.maxRead}}" value="{{ element?.meterConfigMaxRead || '' }}">
        <mat-error *ngIf="addAssetForm.get('config').get('maxRead').invalid">
          <ng-container>{{assetLabels.maxRead}} <strong>{{assetLabels.must}}</strong> {{assetLabels.number}}</ng-container>
        </mat-error>
			</mat-form-field>
		</div>
	</div>

  <div class="row">
      <mat-form-field class="col">
        <input matInput formControlName="masterSerialMeter" placeholder="{{assetLabels.master}}">
        <mat-error *ngIf="addAssetForm.get('masterSerialMeter').invalid">
          <ng-container *ngIf="addAssetForm.get('masterSerialMeter').invalid">{{assetLabels.invalid}}</ng-container>
        </mat-error>
      </mat-form-field>
  </div>
</div>
    <h4 class="caption">{{assetLabels.tableHeaders.CUSTOMER}} <span *ngIf="mergeCustomer.existing" class="badge active">{{assetLabels.existing}}</span></h4>
	<mat-slide-toggle id="addCustomer" [formControl]="addCustomer">{{assetLabels.addCustomer}}</mat-slide-toggle>
    <form *ngIf="addCustomer.value === true" [formGroup]="customerData">
      <div class="row">
        <mat-form-field class="col">
          <input matInput formControlName="customerDenotation" placeholder="{{assetLabels.customerId}}" required>
        </mat-form-field>

        <mat-form-field class="col">
          <mat-select formControlName="customerSegment" placeholder="{{assetLabels.tableHeaders.SEGMENT}}">
            <mat-option *ngFor="let segment of selectSegments" [value]="segment.id">
              {{ segment.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>

 	<h4 class="caption caption-btn">
		{{measurementsLabels.optionalValues}}

		<button id="addOptionalValue" type="button"
						(click)="addOptionalValueField()"
						mat-icon-button color="primary" class="btn-new-position">
			<i class="material-icons notranslate">{{addMdIcon}}</i>
		</button>
	</h4>

	<mat-accordion formArrayName="optionalValues">
		<mat-expansion-panel id="optionalValue" [expanded]="isLast" *ngFor="let element of optionalValues.controls; let idx = index; last as isLast;" [formGroupName]="idx" hideToggle="true">
			<mat-expansion-panel-header class="header-btn">

				<mat-panel-title>{{measurementsLabels.optionalValue}}</mat-panel-title>

				<mat-panel-description>
					<button type="button"
									id="deleteOptionalValue"
									(click)="deleteUserField(idx)"
									mat-icon-button color="primary">
						<i class="material-icons notranslate">{{removeMdIcon}}</i>
					</button>
				</mat-panel-description>
			</mat-expansion-panel-header>

			<div class="row">
				<mat-form-field class="col">
					<input matInput formControlName="key" placeholder="{{measurementsLabels.name}}">
				</mat-form-field>

				<mat-form-field class="col">
					<input matInput formControlName="value" placeholder="{{measurementsLabels.value}}">
				</mat-form-field>
			</div>
		</mat-expansion-panel>
	</mat-accordion>

	<div class="form-btn">
		<button type="button" mat-button color="primary" class="btn" (click)="dialogRef.close(undefined)">{{measurementsLabels.cancel}}</button>
    <button id="save" type="submit" mat-raised-button color="accent" class="btn" [disabled]="addAssetForm.invalid || addAssetForm.status === 'PENDING'">{{measurementsLabels.save}}</button>
	</div>
</form>
