<mat-table #tableFiles [dataSource]="dataSource">

    <ng-container matColumnDef="eventName">
      <mat-header-cell *matHeaderCellDef i18n="event table header|A header in the table for displaying schedule position events@@schedules/position event table header name">Event name</mat-header-cell>
			<mat-cell *matCellDef="let element">
        {{ getNameForEvent(element) }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="eventDetails">
      <mat-header-cell *matHeaderCellDef i18n="event table header|A header in the table for displaying schedule position events@@schedules/position event table header due date">Due to</mat-header-cell>
			<mat-cell *matCellDef="let element">
          {{element.date | svDate}}
			</mat-cell>
    </ng-container>

    <ng-container matColumnDef="eventState">
      <mat-header-cell *matHeaderCellDef i18n="event table header|A header in the table for displaying schedule position events@@schedules/position event table header status">Status</mat-header-cell>
			<mat-cell *matCellDef="let element">
          {{ getNameForEventStatus(element) }}
			</mat-cell>
    </ng-container>


  <mat-header-row *matHeaderRowDef="displayedColumnsDetails"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumnsDetails;">

  </mat-row>
</mat-table>