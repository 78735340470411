import {
  Component,
  Input,
  HostBinding,
  OnInit
} from "@angular/core";
import { Router } from "@angular/router";

import { TimelineEntry, CardType } from "@shared/models";
import { EventStatus, EventType } from "@shared/types";
import * as moment from "moment";

@Component({
  selector: "sv-timeline-card",
  templateUrl: "timeline-card.component.html",
  styleUrls: ["timeline-card.component.sass"],
})
export class TimelineCardComponent implements OnInit {

  /* Input entries to display as card */
  @Input() entryArray: TimelineEntry[];
  @Input() facing: string;

  /* @Asia - class replaced with 'delayed' */
  // @HostBinding('class.timeline-card__delayed') delayed: boolean
  @HostBinding("class.timeline-card__facing-left") isFacedLeft: boolean;
  @HostBinding("class.timeline-card__facing-right") isFacedRight: boolean;

  @HostBinding("class.stacked") stackedClassSet: boolean;

  @HostBinding("class.today") todayClassSet: boolean;

  @HostBinding("class.todo") todoClassSet: boolean;
  @HostBinding("class.doing") doingClassSet: boolean;
  @HostBinding("class.done") doneClassSet: boolean;
  @HostBinding("class.disabled") disabledClassSet: boolean;
  @HostBinding("class.delayed") delayedClassSet: boolean;

  EventType: typeof EventType = EventType;

  event: TimelineEntry;

  constructor(private router: Router) {}

  ngOnInit() {
    this.setFacingCssClases();
    this.stackedClassSet = this.setStackedCssClass();
    this.todayClassSet = this.isToday();

    this.todoClassSet = this.isInState(EventStatus.TODO);
    this.doingClassSet = this.isInState(EventStatus.IN_PROGRESS);
    this.doneClassSet = this.isInState(EventStatus.DONE);
    this.disabledClassSet = this.isInState(EventStatus.LOCKED);
    this.delayedClassSet = this.isDelayed();
  }

  /* Return delayed state if any of positions is delayed */

  isInState(stateToCheck: EventStatus): boolean {
    const findIdx = this.entryArray.findIndex(
      (entry: TimelineEntry): boolean => {
        return entry.status === stateToCheck;
      }
    );
    return findIdx !== -1;
  }

  isDelayed(): boolean {
    if (this.entryArray && this.entryArray[0].cardType !== CardType.TODAY) {
      const idx = this.entryArray.findIndex((entry: TimelineEntry): boolean => {
        return entry.isDelayed();
      });
      return idx !== -1;
    } else {
      return false;
    }
  }

  setFacingCssClases() {
    if (this.facing === "left") {
      this.isFacedLeft = true;
      this.isFacedRight = false;
    }
    if (this.facing === "right") {
      this.isFacedLeft = false;
      this.isFacedRight = true;
    }
  }

  setStackedCssClass(): boolean {
    return this.entryArray.length > 1;
  }

  actionButtonClicked(entry: TimelineEntry): void {
    this.router.navigate([entry.navigation], {
      queryParams: {
        schedule: entry.scheduleRef,
        position: entry.positionRef,
      },
    });
  }

  /* Check if any of cards is "today" markup */
  isToday(): boolean {
    const findIdx = this.entryArray.findIndex((el) => {
      return el.cardType === CardType.TODAY;
    });
    return findIdx !== -1;
  }

  getTodayIndex(): number {
    const findIdx = this.entryArray.findIndex((el) => {
      return el.cardType === CardType.TODAY;
    });
    return findIdx;
  }

  getButonDisabled(entry: TimelineEntry) {
    return (
      entry.status === EventStatus.LOCKED ||
      entry.status === EventStatus.UNDEFINED
    );
  }

  getDataError(): number {
    let sum: number = 0;
    this.entryArray.forEach((te) => {
      if (te.dataErrorValue !== undefined) {
        sum += te.dataErrorValue;
      }
    });
    return sum;
  }

  getDataSuccess(): number {
    let sum: number = 0;
    this.entryArray.forEach((te) => {
      if (te.dataSuccessValue !== undefined) {
        sum += te.dataSuccessValue;
      }
    });
    return sum;
  }

  getSmallestDueDate(): moment.Moment {
    if (this.entryArray.length === 0) {
      return this.entryArray[0].dueDate; // this makes no sense!
    } else {
      return this.entryArray.sort((a, b) => {
        return a.dueDate.diff(b.dueDate);
      })[0].dueDate;
    }
  }
}
