export enum HttpErrorEnum {
  'VEE_WRONG_START_DATA' = 0,
  'VEE_EMPTY_FLOW_OR_ENERGY',
  'VEE_EMPTY_OPERATIONAL_TIME',
  'PARSE_WRONG_DATE',
  'PARSE_DATE_NOT_EXIST',
  'PARSE_WRONG_METERID',
  'VEE_EMPTY_VEE_RULE',
  'VEE_WRONG_SEQUENCE_OF_STAGES',
  'VEE_EMPTY_FLAG',
  'UPLOAD_MULTIPLE_FILES',
  'PARSE_EXTENSION',
  'PARSE_SIZE',
  'UPLOAD_CROSS_CHECK',
  'PARSE_EMPTY_UPLOAD',
  'PARSE_INVALID_DATE_FORMAT',
  'AUTH_FAILURE',
  'SERVER_FAILURE',
  'INCORRECT_PARAM',
  'UNKNOWN_ERROR',
  'PARSE_TEMPERATURE_NOT_EXIST',
}
