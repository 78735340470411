import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

import { ScheduleCreateComponent } from '../create/schedule-create.component';
import { ScheduleEditComponent } from '../edit/schedule-edit.component';
import { Schedule } from '@shared/models/appModels/schedule.model';

@Injectable()
export class ScheduleDialogsService {

  constructor(private dialog: MatDialog) { }

  public invokeCreateScheduleModal(data?: Schedule): Observable<any> {

        let dialogRef: MatDialogRef<ScheduleCreateComponent, Schedule>;
        dialogRef = this.dialog.open(ScheduleCreateComponent, {
                    panelClass: 'dialog-overlay',
                    data: {
                      schedule: data
                    },
                    restoreFocus: false,
                  });
        return dialogRef.afterClosed();
  }

  public invokeEditScheduleModal(data?: Schedule): Observable<Schedule> {

    let dialogRef: MatDialogRef<ScheduleEditComponent, Schedule>;
    dialogRef = this.dialog.open(ScheduleEditComponent, {
                panelClass: 'dialog-overlay',
                data: {
                  schedule: data
                }
              });
    return dialogRef.afterClosed();
  }
}
