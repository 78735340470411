<div class="progress-line">
	<div *ngIf="labelBox" class="progress-text">
		<span>{{ label }}</span>
		<span>{{ completeness }}{{ unit }}</span>
	</div>

	<div class="progress" [class.empty-text]="labelBox">
		<div class="progress-bar" [style.width]="completeness+'%'">
			<span *ngIf="!labelBox">{{ completeness }}{{ unit }}</span>
		</div>
	</div>
</div>
