
import { IApiEndpointConfig, GenericHttpParams } from '@shared/types/http/httpType';
import { MeterPoint } from '@shared/models/appModels/meterPoint.model';

export const MeterPointApiConf: IApiEndpointConfig = {
  rootApiPath: 'meterpoints/',
  paths: [
    {/* 1 */
      swaggerId: 'getMeterPointsList',
      url: '',
      pathParams: [],
      responseIsArray: true,
    },
    {/* 2 */
      swaggerId: 'postMeterPoints',
      url: '',
      pathParams: [],
      responseIsArray: false,
    },
    {/* 3 */
      swaggerId: 'getMeterPointsById',
      url: 'id/{idMeterPoint}',
      pathParams: [
        { name: 'idMeterPoint', pattern: '{idMeterPoint}' }
      ],
      responseIsArray: false,
    },
    {/* 4 */
      swaggerId: 'putMeterPointsById',
      url: 'id/{idMeterPoint}',
      pathParams: [
        { name: 'idMeterPoint', pattern: '{idMeterPoint}' }
      ],
      responseIsArray: false,
    },
    {/* 5 */
      swaggerId: 'deleteMeterPointsById',
      url: 'id/{idMeterPoint}',
      pathParams: [
        { name: 'idMeterPoint', pattern: '{idMeterPoint}' }
      ],
      responseIsArray: false,
    },
    {/* 6 */
      swaggerId: 'getMeterpointCustomer',
      url: 'id/{idMeterPoint}/customer',
      pathParams: [
        { name: 'idMeterPoint', pattern: '{idMeterPoint}' }
      ],
      responseIsArray: false,
    },
    {/* 7 */
      swaggerId: 'getMeterpointMeterdata',
      url: 'id/{idMeterPoint}/meterdata',
      pathParams: [
        { name: 'idMeterPoint', pattern: '{idMeterPoint}' }
      ],
      responseIsArray: true,
    },
    {/* 8 */
      swaggerId: 'getMeterpointMesurementdata',
      url: 'id/{idMeterPoint}/measurementdata',
      pathParams: [
        { name: 'idMeterPoint', pattern: '{idMeterPoint}' }
      ],
      responseIsArray: true,
    },
    {/* 9 */
      swaggerId: 'putMeterPointActivation',
      url: 'id/{idMeterPoint}/active',
      pathParams: [
        { name: 'idMeterPoint', pattern: '{idMeterPoint}' }
      ],
      responseIsArray: true,
    },
    {/* 10 */
      swaggerId: 'putMeterPointEnabled',
      url: 'id/{idMeterPoint}/enabled',
      pathParams: [
        { name: 'idMeterPoint', pattern: '{idMeterPoint}' }
      ],
      responseIsArray: true,
    },
    {
      swaggerId: 'getMeterPointsListCount',
      url: 'count',
      pathParams: [],
      responseIsArray: false,
    }
  ]
};

export interface MeterPointQueryParams {
  [name: string]: string | string[];
  limit?: string;
  offset?: string;
  id?: string;
  name?: string;
  active?: string;
}

export interface MeterPointPathParams {
  [name: string]: string;
  idMeterPoint?: string;
}

export interface MeterPointHttpParamG extends GenericHttpParams<MeterPoint> {
  queryParams?: MeterPointQueryParams;
  path?: MeterPointPathParams;
}
