import { Injectable } from '@angular/core';
import { BaseDataStore, GenericHttpParams, ApiDataShift } from '@shared/types';
import { TimeGroup } from '@shared/models/appModels/timeGroup.model';
import { TimeGroupHttpParamG } from '@shared/types/http/timeGroupHttpConfig';
import { CommonAppDataService } from '@shared/services/commonAppData.service';
import { TimeGroupService } from '@shared/services/http/timeGroup.service';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class TimeGroupDataStoreService extends BaseDataStore<GenericHttpParams<TimeGroup>, TimeGroupHttpParamG>  {

  constructor(private timeGroupHttpService: TimeGroupService, cs: CommonAppDataService) {
    super(cs);
  }

  getTimeGroupsList(nums: ApiDataShift = {limit: 10, offset: 0}, allowEmpty: boolean = true ): Observable<TimeGroup[]> {
    const params: TimeGroupHttpParamG = this.getEmptyParams();
    params.queryParams.offset = '' + nums.offset;
    params.queryParams.limit = '' + nums.limit;
    params.config.strict = !allowEmpty;
    return this.timeGroupHttpService.getTimeGroupsList(params);
  }

  getTimeGroupsByName(
    nums: ApiDataShift = {limit: 10, offset: 0},
    name: string,
    allowEmpty: boolean = true ): Observable<TimeGroup[]> {

      const params: TimeGroupHttpParamG = this.getEmptyParams();
      params.queryParams.offset = '' + nums.offset;
      params.queryParams.limit = '' + nums.limit;
      params.queryParams.name = name;
      params.config.strict = !allowEmpty;
      return this.timeGroupHttpService.getTimeGroupsList(params);
  }

  getSingleTimeGroupsByName(
    name: string,
    allowEmpty: boolean = true ): Observable<TimeGroup> {

      const params: TimeGroupHttpParamG = this.getEmptyParams();
      params.queryParams.offset = '' + 0;
      params.queryParams.limit = '' + 999; /* FIXME: Due to unsupported name query */
      params.queryParams.name = name;
      params.config.strict = !allowEmpty;

      return this.timeGroupHttpService.getTimeGroupsList(params)
      .concatMap<TimeGroup[], TimeGroup>((stgArr: TimeGroup[]) => {
        return Observable.from(stgArr);
      })
      .filter(stg => stg.timeGroupName === name)
      .defaultIfEmpty({} as TimeGroup);
  }

  addTimeGroup(body: TimeGroup): Observable<TimeGroup> {
    const params: TimeGroupHttpParamG = this.getEmptyParams();
    params.body = body;
    return this.timeGroupHttpService.postTimeGroups(params);
  }

  getTimeGroupById(id: number, allowEmpty: boolean = false): Observable<TimeGroup> {
    const params: TimeGroupHttpParamG = this.getEmptyParams();
    params.path.idTimeGroup = '' + id;
    params.config.strict = !allowEmpty;
    return this.timeGroupHttpService.getTimeGroupsById(params);
  }

  editTimeGroup(body: TimeGroup, id: number, allowEmpty: boolean = false): Observable<TimeGroup> {
    const params: TimeGroupHttpParamG = this.getEmptyParams();
    params.path.idTimeGroup = '' + id;
    params.config.strict = !allowEmpty;
    params.body = body;
    return this.timeGroupHttpService.putTimeGroupsById(params);
  }

  deleteTimeGroup(id: number, allowEmpty: boolean = false): Observable<TimeGroup> {
    const params: TimeGroupHttpParamG = this.getEmptyParams();
    params.path.idTimeGroup = '' + id;
    params.config.strict = !allowEmpty;
    return this.timeGroupHttpService.deleteTimeGroupsById(params);
  }


  protected getEmptyParams(): TimeGroupHttpParamG {
    const r: TimeGroupHttpParamG = {queryParams: {}, path: {}, headers: {}, body: {} as TimeGroup, config: {}};
    return r;
  }

}
