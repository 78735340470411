import { GenericHttpParams, IApiEndpointConfig } from '@shared/types/http/httpType';
import { Schedule } from '@shared/models/appModels/schedule.model';
import { SortType } from '@shared/types/applicationTypes';

export const ScheduleApiConf: IApiEndpointConfig = {
  rootApiPath: 'schedules/',
  paths: [
    {/* 1 */
      url: '',
      swaggerId: 'getSchedulesList',
      pathParams: [],
      responseIsArray: true,
    },
    {/* 2 */
      url: '',
      swaggerId: 'postSchedules',
      pathParams: [],
      responseIsArray: false,
    },
    {/* 3 */
      url: 'id/{idSchedule}/',
      swaggerId: 'getSchedulesById',
      pathParams: [
        {name: 'idSchedule', pattern: '{idSchedule}'},
      ],
      responseIsArray: false,
    },
    {/* 4 */
      url: 'id/{idSchedule}/',
      swaggerId: 'deleteSchedulesById',
      pathParams: [
        {name: 'idSchedule', pattern: '{idSchedule}'},
      ],
      responseIsArray: false,
    },
    {/* 5 */
      url: 'id/{idSchedule}/',
      swaggerId: 'putSchedulesById',
      pathParams: [
        {name: 'idSchedule', pattern: '{idSchedule}'},
      ],
      responseIsArray: false,
    },
    {/* 6 */
      url: 'count/',
      swaggerId: 'getSchedulesCount',
      pathParams: [],
      responseIsArray: false,
    },
    {/* 7 */
      url: 'id/{idSchedule}/positions',
      swaggerId: 'getSchedulesPositions',
      pathParams: [
        {name: 'idSchedule', pattern: '{idSchedule}'}
      ],
      responseIsArray: true,
    }
   ]
};

export interface ScheduleQueryParams {
    /* Required */
    limit?:    string;
    offset?:   string;
    /* Optional */
    sort?:    SortType;
    from?:    string;
    to?:      string;
    name?:    string;
    active?:  string;
    open?:    string;
    delayed?: string;
    future?:  string;
    past?:    string;
    svUtilityType?: string;
}

export interface ScheduleHttpParamG extends GenericHttpParams<Schedule> {
  queryParams?: ScheduleQueryParams;
  path?: {
    idSchedule?:    string
    name?:  string
  };
  body?: Schedule;
}
