<div [ngClass]="{ fullSize: fullSizeMode, 'explor-chart': !fullSizeMode }">
  <iframe
    #myIframe
    [src]="secureURL"
    [width]="width ? width : '90%'"
    [height]="height ? height : '90%'"
    frameborder="0"
    scrolling="no"
    webkitallowfullscreen
    mozallowfullscreen
    allowfullscreen
    [ngClass]="{ fullSize: fullSizeMode }"
  ></iframe>
  <button
    class="enableFullScreenBtn"
    mat-icon-button
    (click)="enableFullscreenMode()"
  >
    <i class="material-icons notranslate">fullscreen</i>
  </button>
</div>

<button
  mat-flat-button
  (click)="enableFullscreenMode()"
  [ngClass]="{
    'button-disable-fullscreen': fullSizeMode,
    'button-disable-fullscreen-none': !fullSizeMode
  }"
>
  <i class="material-icons notranslate">fullscreen_exit</i>
</button>
