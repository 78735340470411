<sv-timeline (currentSchedule)="schedule = $event"></sv-timeline>

<div class="container widget-box">
	<div class="row">
		<div class="col">
			<sv-data-validation-widget [schedule]="schedule" class="card"></sv-data-validation-widget>
		</div>

		<div class="col">
			<sv-data-analysis-widget [schedule]="schedule" class="card"></sv-data-analysis-widget>
		</div>

		<div class="col">
			<sv-data-exploration-widget [schedule]="schedule" class="card"></sv-data-exploration-widget>
		</div>
	</div>
</div>