import { DataSource } from '@angular/cdk/collections';
import { CollectionViewer } from '@angular/cdk/collections';

import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

export abstract class TableDataSource<Simple, Tabbed extends Simple> extends DataSource<Simple> {

  protected dataSubject: Subject<Simple[]>;
  protected data: Simple[];

  constructor() {
    super();
    this.dataSubject = new Subject<Simple[]>();
  }

  connect(collectionViewer: CollectionViewer): Observable<Simple[]> {
    return this.dataSubject;
  }

  disconnect(collectionViewer: CollectionViewer): void { }

  /* read and transform new table data */
  setNewData(data: Simple[]) {
    this.data = data;
    this.dataSubject.next(this.renderData(data));
  }

  getData(): Simple[] {
    return this.data;
  }

  protected abstract renderData(data: Simple[]): Tabbed[];
}
