import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { environment } from '@env/environment';

import { RestTypeMapper } from '@shared/models/RestSupport';
import { IAnalysisRequest, IAnalysisStatus } from '@shared/models/appModels/analysisStatus.model';
import { AnalysisStatus } from '@shared/models/appModels/analysisStatus.model';

import { AnalysisStatusHttpParamG } from '@shared/types/http/analysisProcessHttpConfig';
import { EndpointsEnum } from '@shared/types/http/endpointEnum';
import { AnalysisProcessStageEnum, AnalysisProcessStatusEnum } from '@shared/types/modelTypes/analysisProcessTypes';

import { CommonAppDataService } from '@shared/services/commonAppData.service';
import { RequestService } from './requestService.class';



@Injectable()
export class AnalysisStatusService extends RequestService {


  private analysisStatusMapper: RestTypeMapper<(IAnalysisRequest & IAnalysisStatus), AnalysisStatus>;

  private mockSequenceStage: AnalysisProcessStageEnum;
  private mockSequenceStatus: AnalysisProcessStatusEnum;

  /* Debug data */
  debugMode: boolean = environment.debug;

  constructor(
    http: HttpClient,
    cs: CommonAppDataService) {
    super(http, cs, EndpointsEnum.ANALYSIS_STATUS);
    if (this.MOCK_SERVICE) {
      this.mockSequenceStage = AnalysisProcessStageEnum.DETECTION_OF_ANOMALIES;
      this.mockSequenceStatus = AnalysisProcessStatusEnum.IN_PROGRESS;
    }
    /* Initialize type MAPPERS */
    this.analysisStatusMapper = new RestTypeMapper<(IAnalysisRequest | IAnalysisStatus), AnalysisStatus>();
  }
  getMock(params: AnalysisStatusHttpParamG): Observable<AnalysisStatus> {
    const prototype: IAnalysisStatus = {
      stage: this.mockSequenceStage, status: this.mockSequenceStatus,
      aggregation: (params.body as AnalysisStatus).aggregation,
      startDate: (params.body as AnalysisStatus).startDate,
      endDate: (params.body as AnalysisStatus).endDate,
      meterId: (params.body as AnalysisStatus).meterId,
      address: (params.body as AnalysisStatus).address,
      algorithmType: (params.body as AnalysisStatus).algorithmType,
      svUtilityType: (params.body as AnalysisStatus).svUtilityType,
    };
    return Observable.of(new AnalysisStatus(prototype));
  }
  getAnalysisProcessState(params: AnalysisStatusHttpParamG): Observable<AnalysisStatus> {
    const thisApiID = 'getAnalysisProcessState';
    return this.MOCK_SERVICE ? this.getMock(params) : this.getRequest(params, this.analysisStatusMapper, thisApiID, AnalysisStatus);
  }

  postAnalysisProcessState(params: AnalysisStatusHttpParamG): Observable<AnalysisStatus> {
    const thisApiID = 'postAnalysisProcessState';
    if (this.MOCK_SERVICE) {
      return this.getMock(params);
    } else {
      const bodyToSend = this.analysisStatusMapper.mapObjectToInterface(params.body) as IAnalysisRequest;
      return this.postRequest(bodyToSend, params, this.analysisStatusMapper, thisApiID, AnalysisStatus);
    }
  }

  deleteAnalysisProcessState(params: AnalysisStatusHttpParamG): Observable<AnalysisStatus> {
    const thisApiID = 'deleteAnalysisProcessState';
    return this.MOCK_SERVICE ? this.getMock(params) : this.deleteRequest(params, this.analysisStatusMapper, thisApiID, AnalysisStatus);
  }

}
