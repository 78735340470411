import { Injectable } from "@angular/core";
import { InteractiveTutorialService, TutorialButtonLabel } from "@shared/services/interactiveTutorialService.service";

@Injectable({ providedIn: "root" })
export class TemperaturesUploadInteractiveTutorialService {
  constructor(private interactiveTutorialServ: InteractiveTutorialService) {}

  getTemperaturesUploadInteractiveTutorialSteps() {
    const step = this.interactiveTutorialServ.generateStep(
      "#svUpload",
      "bottom",
      $localize`:@@interactive tutorial/asset upload/main view/upload:Start by selecting the appropriate csv file`,
      $localize`:@@interactive tutorial/measurements upload/main component/svUpload title:Select file`,
      [TutorialButtonLabel.NEXT]
    );

    const step1 = this.interactiveTutorialServ.generateStep(
      "#svUploadBtn",
      "bottom-end",
      $localize`:@@interactive tutorial/measurements upload/main component/svUploadBtn:Finalize your upload by clicking on this button.`,
      $localize`:@@interactive tutorial/measurements upload/main component/svUploadBtn title:Finalize upload`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
    );

    const step2 = this.interactiveTutorialServ.generateStep(
      "#svTemperaturesTable",
      "top",
      $localize`:@@interactive tutorial/asset upload/data preview/temperatures table:The table contains a collection of the temperature readings you imported.`,
      $localize`:@@interactive tutorial/asset upload/data preview/temperatures table title:Temperature readings table`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.COMPLETE]
    );

    const steps: unknown[] = [step, step1, step2];
    return steps;
  }
}
