<div ngFileDrop #fileDrop class="drop-container" (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput"
    [ngClass]="{ 'is-drop-over': dragOver }">

    <div class="import-overlay">
    </div>

    <h1>{{temperaturesLabel.header}}</h1>

    <div class="upload-info-container">
        <div id="svUpload" class="text-import column text-highlight">
            {{uploadPrompt.part1}}
            <label class="btn-upload">
                {{uploadPrompt.part2}}
                <input #fileSelect ngFileSelect type="file" (uploadOutput)="onUploadOutput($event)"
                    [uploadInput]="uploadInput">
            </label>
            {{uploadPrompt.part3}}
        </div>
        <div class="column">
            <div class="card">
                <div class="card-body overflow-hide">
                    <h1>{{uploadProg}}</h1>

                    <div *ngIf="displayableUpload.length > 0" class="card progress-card">
                        <ng-container>
                            <div class="upload-progress-container" *ngFor="let f of displayableUpload; let i = index;">
                                <div class="elements-space-between">
                                    <span class="truncated-name" [matTooltip]="f.name"
                                        matTooltipClass="tooltip-not-truncated"
                                        matTooltipPosition="left">{{f.name}}</span>
                                    <span>{{ '  '+ humanizeBytes(f.size)}}</span>
                                </div>
                                <mat-progress-bar mode="determinate" [value]="f.progress?.data?.percentage">
                                </mat-progress-bar>
                                <div class="elements-space-between">
                                    <span>{{f.progress?.data?.percentage + '%' + ' done'}}</span>
                                    <span>{{ f.progress?.data?.speedHuman }}</span>
                                </div>
                            </div>
                        </ng-container>
                    </div>

                    <div *ngIf="displayResults()" class="card alert" [ngClass]="getResultClass()">
                        <span *ngIf="uploadResults.success">{{success}}</span>

                        <span *ngIf="uploadResults.error">
                            {{error}}: {{uploadResults?.errorCause}}

                            <label class="link btn-upload">
                                <a (click)="reuploadFileSelect()">{{reload}}</a>
                            </label>
                        </span>
                    </div>
                    <div class="button-on-bottom-container">
                    <div class="tooltip-div" [matTooltip]="chooseFileToUpload" [matTooltipDisabled]="!btnDisabled()">
                        <!-- div added because hints do not work on the disabled button -->
                        <button id="svUploadBtn" type="button" (click)="startUpload()" [disabled]="btnDisabled()" mat-raised-button
                            color="accent" class="btn">
                            {{upload}}
                        </button>
                    </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="card">

        <mat-table id="svTemperaturesTable" #tableFiles [dataSource]="dataSource" matSort>
            <!-- id Column -->
            <ng-container matColumnDef="timestamp">
                <mat-header-cell *matHeaderCellDef>{{temperaturesLabel.timestamp}}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.timestamp | svDate }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="temperature">
                <mat-header-cell *matHeaderCellDef>{{temperaturesLabel.temperature}}</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{(element.avgOutTemp | number)}} </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>

        <div class="no-result" *ngIf="!isLoading() && getDataSourceLength() === 0">
            {{noData}}
        </div>

        <div class="no-result" *ngIf="isLoading()">
            <mat-spinner [strokeWidth]="3" [diameter]="20" class="notranslate"></mat-spinner>
        </div>

        <mat-paginator #paginator [length]="dataLength" [pageIndex]="0" [pageSize]="10" [pageSizeOptions]="[5, 10, 20]"
            showFirstLastButtons class="notranslate">
        </mat-paginator>
    </div>
</div>