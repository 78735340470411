import { RestEnumMpapper } from '@shared/models/RestSupport';
import { VeeProcessStageEnum, VeeProcessStatusEnum } from '@shared/types/modelTypes/veeProcessTypes';
import { VeeRuleSeverityEnum, VeeRuleTypeEnum } from '@shared/types/modelTypes/veeRuleTypes';
import { UtilityTypeEnum } from '@shared/types/utilityType';
import { IGetInterfaceType } from '@shared/types/applicationTypes';

export interface IVeeRule {
  id: number;
  name: string;
  description: string;
  rule_type: VeeRuleTypeEnum | string;
  priority: number;
  severity: VeeRuleSeverityEnum | string;
  switchable: boolean;
  enabled: boolean;
  media_type?: UtilityTypeEnum | string;
  recommendation?: string;
  implementation?: string;
}

export class VeeRule implements IVeeRule, IGetInterfaceType<IVeeRule, VeeRule> {

  /* Mappers */
  static ruleSeverityMapper: RestEnumMpapper<typeof VeeRuleSeverityEnum> = new RestEnumMpapper<typeof VeeRuleSeverityEnum>();
  static mediaTypeMapper: RestEnumMpapper<typeof UtilityTypeEnum> = new RestEnumMpapper<typeof UtilityTypeEnum>();
  static ruleTypeMapper: RestEnumMpapper<typeof VeeRuleTypeEnum> = new RestEnumMpapper<typeof VeeRuleTypeEnum>();

  id: number;
  name: string;
  description: string;
  rule_type: VeeRuleTypeEnum | string;
  priority: number;
  severity: VeeRuleSeverityEnum | string;
  switchable: boolean;
  enabled: boolean;
  media_type?: UtilityTypeEnum | string;
  recommendation?: string;
  implementation?: string;

  requestedStage?: VeeProcessStageEnum | string;
  stage?: VeeProcessStageEnum | string;
  status?: VeeProcessStatusEnum | string;

  constructor(iVeeRule: IVeeRule) {
    if (!iVeeRule) {
      throw new Error('No interface object provided to constructor');
    }
    this.id = (typeof (iVeeRule.id) !== 'undefined') ? iVeeRule.id : undefined;
    this.name = (typeof (iVeeRule.name) !== 'undefined') ? iVeeRule.name : undefined;
    this.description = (typeof (iVeeRule.description) !== 'undefined') ? iVeeRule.description : undefined;

    this.rule_type = (typeof (iVeeRule.rule_type) !== 'undefined') ? iVeeRule.rule_type : undefined;

    this.priority = (typeof (iVeeRule.priority) !== 'undefined') ? iVeeRule.priority : undefined;

    this.severity = (typeof (iVeeRule.severity) !== 'undefined') ? iVeeRule.severity : undefined;

    this.switchable = (typeof (iVeeRule.switchable) !== 'undefined') ? iVeeRule.switchable : undefined;
    this.enabled = (typeof (iVeeRule.enabled) !== 'undefined') ? iVeeRule.enabled : undefined;

    this.media_type = (typeof (iVeeRule.media_type) !== 'undefined') ? iVeeRule.media_type : undefined;

    this.recommendation = (typeof (iVeeRule.recommendation) !== 'undefined') ? iVeeRule.recommendation : undefined;

    this.implementation = (typeof (iVeeRule.implementation) !== 'undefined') ? iVeeRule.implementation : undefined;
  }

  getInterface(webInterface: boolean): IVeeRule {
    const emptyValue = (webInterface) ? null : undefined;
    return {
      id: this.id,
      name: this.name || emptyValue,
      description: this.description || emptyValue,
      rule_type: this.rule_type,
      priority: this.priority || emptyValue,
      severity: this.severity,
      switchable: this.switchable,
      enabled: this.enabled,
      media_type: this.media_type,
      recommendation: this.recommendation || emptyValue,
      implementation: this.implementation || emptyValue,
    };
  }

}
