import { GenericHttpParams, IApiEndpointConfig } from '@shared/types/http/httpType';
import { AutomaticImportConfigForSchedulePosition } from '@shared/models/appModels/automaticImportConfigurationForSchedulePosition.model';

export const automaticImportConfigurationForSchedulePositionApiConf: IApiEndpointConfig = {
    rootApiPath: 'data_import',
    paths: [
        {
            url: '',
            swaggerId: 'data_import',
            pathParams: [],
            responseIsArray: false
        }
    ]
};

export interface AutomaticImportConfigurationHttpParamG extends GenericHttpParams<AutomaticImportConfigForSchedulePosition> {
    path?: {
      svUtilityType?: string;
    };
}
