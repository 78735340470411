import { TableDataSource } from '@shared/models/tableDataSource.model';
import { Position } from '@shared/models/appModels/position.model';
import { PositionEvent } from '@shared/models/appModels/positionEvent.model';

export class TabbedPosition extends Position {
  detailRow: boolean;
  rowId: number;
}

export class PositionEventsDetailsDataSource extends TableDataSource<PositionEvent, PositionEvent> {

  protected renderData(data: PositionEvent[]): PositionEvent[] {
    return data;
  }

}
