import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

import { ClientDataPreviewDialogComponent } from '../dialog/client-data-preview-dialog/client-data-preview-dialog.component';
import { DisableCustomerComponent } from '../dialog/disable-operational-dialog/disable-operational.component';
import { MeterPoint } from '@shared/models/appModels/meterPoint.model';
import { AssignCustomerComponent } from '../dialog/assign-customer/assign-customer.component';
import { Customer } from '@shared/models/appModels/customer.model';

@Injectable()
export class AssetDataDialogService {

  constructor(private dialog: MatDialog) {

  }

  public invokeEditDialog(meterPoint: MeterPoint, name = 'Add new'): Observable<any> {

    let dialogRef: MatDialogRef<ClientDataPreviewDialogComponent, MeterPoint>;
    dialogRef = this.dialog.open(ClientDataPreviewDialogComponent, {
      panelClass: 'dialog-overlay',
      data: {
        meterPoint: meterPoint
      }
    });
    dialogRef.componentInstance.name = name;
    return dialogRef.afterClosed();
  }

  public invokeActivationDialog(meterPoint: MeterPoint, name = 'Disable elements'): Observable<string> {
    let dialogRef: MatDialogRef<DisableCustomerComponent, string>;
    dialogRef = this.dialog.open(DisableCustomerComponent, {
      panelClass: 'dialog-overlay',
      data: {
        meterPoint: meterPoint
      }
    });
    dialogRef.componentInstance.name = name;
    dialogRef.componentInstance.actionButton = name;
    return dialogRef.afterClosed();
  }

  public invokeCustomerAssignmentDialog(meterPoint: MeterPoint): Observable<Customer> {
    let dialogRef: MatDialogRef<AssignCustomerComponent, Customer>;
    dialogRef = this.dialog.open(AssignCustomerComponent, {
      panelClass: 'dialog-overlay',
      data: {
        meterPoint: meterPoint
      }
    });
    dialogRef.componentInstance.serialNumber = meterPoint.serialNumber;
    return dialogRef.afterClosed();
  }
}
