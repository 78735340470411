import { GenericHttpParams, IApiEndpointConfig } from '@shared/types/http/httpType';
import { FileTemperatureData } from '@shared/models/appModels/fileTemperatureData';

export const fileTemperatureDataApiConf: IApiEndpointConfig = {
    rootApiPath: 'filetemperaturedata/',
    paths: [
        {
            url: '',
            swaggerId: 'getTemperatureDataList',
            pathParams: [],
            responseIsArray: true,
        },
        {
            url: '',
            swaggerId: 'postFileTemperatureData',
            pathParams: [],
            responseIsArray: false,
        },
        {
            url: 'count',
            swaggerId: 'getTemperatureDataListCount',
            pathParams: [],
            responseIsArray: false,
        }
    ]
};

export interface FileTemperatureDataQueryParameters {
    limit?: string;
    offset?: string;
    from?: string;
    to?: string;
}

export interface FileTemperatureDataHttpParamG extends GenericHttpParams<FileTemperatureData> {
    queryParams: FileTemperatureDataQueryParameters;
    reportProgress?: boolean;
    path?: {};
    headers?: {};
}
