import * as moment from 'moment';

import { TimelineEntry, CardType } from '@shared/models/timelineEntry.model';
import { Schedule } from '@shared/models/appModels/schedule.model';
import { Position } from '@shared/models/appModels/position.model';
import { PositionEvent } from '@shared/models/appModels/positionEvent.model';
import { EventStatus } from '@shared/types/modelTypes/eventType';

export class TodayTimelineEntry extends TimelineEntry {
  /* Only for today */
  completeness?: number = 0;
  unit?: string = '%';
  delayedTasks?: number;
  daysRemaining?: number;

  public static generateToday(schedule: Schedule): TodayTimelineEntry {
    const generatedToday: TodayTimelineEntry = new TodayTimelineEntry();

    generatedToday.dueDate = moment.utc().endOf("day");

    generatedToday.title = $localize`:@@timeline/card/today header:Today`;
    generatedToday.dataSuccess = $localize`:@@timeline/card/days remaining label:Days remaining`;
    generatedToday.dataError = $localize`:@@timeline/card/delayed tasks label:Delayed tasks`;
    generatedToday.unit = '%';
    let total = 0, done = 0, todo = 0;
    schedule.positions.forEach((pos: Position) => {
      pos.events.forEach((ev: PositionEvent) => {
        total++;
        if (ev.status !== EventStatus.DONE && ev.status !== EventStatus.CLOSED) {
          todo ++;
        } else {
          done ++;
        }
      });
    });
    generatedToday.completeness = Math.ceil((done / total) * 100);

    let delayed = 0;
    schedule.positions.forEach((pos: Position) => {
      pos.events.forEach((ev: PositionEvent) => {
        const mEventDate = moment(new Date(ev.date));
        const lmDueDate = moment(generatedToday.dueDate);
        if (!((mEventDate.add(1, 'day')).isAfter(moment(lmDueDate)))) {
          if (ev.status !== EventStatus.DONE && ev.status !== EventStatus.CLOSED) {
          delayed++;
        }
      }
      });
    });
    generatedToday.delayedTasks = delayed;

    const mDueDate = moment(generatedToday.dueDate);

    let firstEventDate;
    schedule.positions.forEach((pos: Position) => {
      pos.events.forEach((ev: PositionEvent) => {
        if (typeof(firstEventDate) === 'undefined') {
          firstEventDate = ev.date;
        } else {
          if (ev.date < firstEventDate && ev.status !== EventStatus.DONE && ev.status !== EventStatus.CLOSED) {
            firstEventDate = ev.date;
          }
        }
      });
    });
    const mLastTime = moment(new Date(firstEventDate));
    const distance = Math.ceil(moment.duration(mLastTime.diff(mDueDate)).asDays());
    generatedToday.daysRemaining = (distance > 0) ? distance : 0;


    generatedToday.icoRef = TimelineEntry.typeIconMap.today;
    generatedToday.cardType = CardType.TODAY;
    return generatedToday;
  }
}
