import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { environment } from '@env/environment';

import { RestTypeMapper } from '@shared/models/RestSupport';
import { IVeeRequest, IVeeStatus } from '@shared/models/appModels/VeeStatus.model';
import { VeeStatus } from '@shared/models/appModels/VeeStatus.model';

import { VeeStatusHttpParamG } from '@shared/types/http/veeProcessHttpConfig';
import { EndpointsEnum } from '@shared/types/http/endpointEnum';
import { VeeProcessStageEnum, VeeProcessStatusEnum } from '@shared/types/modelTypes/veeProcessTypes';

import { CommonAppDataService } from '@shared/services/commonAppData.service';
import { RequestService } from './requestService.class';
import { ValidateMeasurement, IValidateMeasurement } from '@shared/models/appModels/validateMeasurement.mode';



@Injectable()
export class VeeStatusService extends RequestService {

  private veeStatusMapper: RestTypeMapper<(IVeeRequest & IVeeStatus), VeeStatus>;
  private validationMeasurementMapper: RestTypeMapper<(IValidateMeasurement), ValidateMeasurement>;

  private mockSequenceStage: VeeProcessStageEnum;
  private mockSequenceStatus: VeeProcessStatusEnum;

  /* Debug data */
  debugMode: boolean = environment.debug;

  constructor(
    http: HttpClient,
    cs: CommonAppDataService) {
    super(http, cs, EndpointsEnum.VEE_STATUS);
    if (this.MOCK_SERVICE) {
      this.mockSequenceStage = VeeProcessStageEnum.PREPROCESS;
      this.mockSequenceStatus = VeeProcessStatusEnum.IN_PROGRESS;
    }
    this.veeStatusMapper = new RestTypeMapper<(IVeeRequest | IVeeStatus), VeeStatus>();
    this.validationMeasurementMapper = new RestTypeMapper<(IValidateMeasurement), ValidateMeasurement>();
  }

  getProcessState(params: VeeStatusHttpParamG): Observable<VeeStatus> {
    const thisApiID = 'getProcessState';
    if (this.MOCK_SERVICE) {
      const prototype: IVeeStatus = { stage: this.mockSequenceStage, status: this.mockSequenceStatus };
      return Observable.of(new VeeStatus(prototype));
    } else {
      return this.getRequest(params, this.veeStatusMapper, thisApiID, VeeStatus);
    }
  }

  postProcessState(params: VeeStatusHttpParamG): Observable<VeeStatus> {
    const thisApiID = 'postProcessState';
    if (this.MOCK_SERVICE) {
      const prototype: IVeeStatus = { stage: (params.body as VeeStatus).stage, status: (params.body as VeeStatus).status };
      const vs: VeeStatus = new VeeStatus(prototype);
      this.mockSequenceStage = vs.stage as VeeProcessStageEnum;
      this.mockSequenceStatus = vs.status as VeeProcessStatusEnum;
      return Observable.of(vs);
    } else {
      const bodyToSend = this.veeStatusMapper.mapObjectToInterface(params.body) as IVeeRequest;
      return this.postRequest(bodyToSend, params, this.veeStatusMapper, thisApiID, VeeStatus);
    }
  }

  deleteProcessState(params: VeeStatusHttpParamG): Observable<VeeStatus> {
    const thisApiID = 'deleteProcessState';
    if (this.MOCK_SERVICE) {
      /* FIXME: Const mock is a bad mock */
      const prototype: IVeeStatus = { stage: VeeProcessStageEnum.PREPROCESS, status: VeeProcessStatusEnum.DONE };
      const vs: VeeStatus = new VeeStatus(prototype);
      return Observable.of(vs);
    } else {
      return this.deleteRequest(params, this.veeStatusMapper, thisApiID, VeeStatus);
    }
  }

  postValidationProcessState(params: VeeStatusHttpParamG): Observable<ValidateMeasurement> {
    const thisApiID = 'postValidationProcessState';
    if (this.MOCK_SERVICE) {
      // tslint:disable-next-line: max-line-length
      const prototype: IValidateMeasurement = { 
        position_id: (params.body as ValidateMeasurement).position_id, 
        meter_point_id: (params.body as ValidateMeasurement).meter_point_id,
        validation_rule_ids: (params.body as ValidateMeasurement).validation_rule_ids };
      const vs: ValidateMeasurement = new ValidateMeasurement(prototype);
      return Observable.of(vs);
    } else {
      const bodyToSend = this.validationMeasurementMapper.mapObjectToInterface(params.body);
      return this.postRequest(bodyToSend, params, this.validationMeasurementMapper, thisApiID, ValidateMeasurement);
    }
  }

}
