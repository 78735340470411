// Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { GraphicsModule } from '../graphics/graphics.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { DndModule } from 'ng2-dnd';
import { BrowserModule } from '@angular/platform-browser';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';

// This Module's Components
import { DataValidationPageComponent } from './page/data-validation-page.component';

import { DataValidationWidgetComponent } from './widget/data-validation-widget.component';
import { DataValidationSummaryComponent } from './page/summary/data-validation-summary.component';
import { DataValidationCreateComponent } from './page/create/data-validation-create.component';
import { DetailComponent } from './page/summary/detail/detail.component';
import { CommonComponentsModule } from '../common-component/commonComponents.module';
import { CommonPipeModule } from '../../utils/commonPipe.module';
import { ReadingsPreviewViewDialogComponent } from '../upload/measurements/measurements-preview/dialog/readings-preview-view-dialog/readings-preview-view-dialog.component';
import { UploadModule } from '../upload/upload.module';
import { MeasurementsPreviewDialogsService } from '../upload/measurements/measurements-preview/service/measurements-preview-dialogs.service';
import { DataValidationDialogsService } from './page/service/data-validation-dialog.service';
import { DataValidationCreateNextStepComponent } from './page/createNextStep/data-validation-create-next-step.component';



@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    GraphicsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatSliderModule,
    MatTableModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatRadioModule,
    MatMenuModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatRippleModule,
    MatPaginatorModule,
    MatListModule,
    MatSortModule,
    MatProgressSpinnerModule,
    DndModule,
    BrowserModule,
    DndModule.forRoot(),
    CommonComponentsModule,
    MatTooltipModule,
    MatTabsModule,
    CommonPipeModule,
    MatFormFieldModule,
    UploadModule,
    MatDialogModule,
    MatAutocompleteModule,
  ],
  declarations: [
    DataValidationPageComponent,
    DataValidationWidgetComponent,
    DataValidationSummaryComponent,
    DetailComponent,
    DataValidationCreateComponent,
    DataValidationCreateNextStepComponent,
  ],
  exports: [
    DataValidationPageComponent,
    DataValidationWidgetComponent,
  ],
  entryComponents: [
    ReadingsPreviewViewDialogComponent,
    DataValidationCreateComponent,
    DataValidationCreateNextStepComponent,
  ],
  providers: [MeasurementsPreviewDialogsService, DataValidationDialogsService, DecimalPipe]
})
export class DataValidationModule {

}
