import { INameMapper } from '@shared/types/applicationTypes';
import { environment as env } from '@env/environment';
import { typesLocalisation as loc } from '@shared/types/localisation';

export enum EventType {
  UPLOAD = 0,
  VALIDATION = 1,
  EXPORT = 2,
  ANALYSIS = 3,
}

export enum EventStatus {
  UNDEFINED = 0,
  TODO = 1,
  IN_PROGRESS = 2,
  DONE = 3,
  CLOSED = 4,
  LOCKED = 5
}

// TODO: to app configuration
export const DateOffsets: number[] = [
  1, 5, 10, 10, 0
];

