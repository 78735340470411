import { INameMapper } from '@shared/types/applicationTypes';
import { environment as env } from '@env/environment';
import { typesLocalisation as loc } from '@shared/types/localisation';

export enum AnalysisAlgorithmSeverityEnum {
  'INFO' = 0,
  'WARNING' = 1,
  'ERROR' = 2,
}

export enum AnalysisAlgorithmTypeEnum {
  'DETECTION_OF_ANOMALIES' = 0,
  'FORECASTING' = 1,
  'EXPLORATION' = 2
}
export enum AnalysisStatusEnum {
  'IN_PROGRESS' = 0,
  'DONE' = 1,
  'FAILED' = 2,
  'UNDEFINED' = 3,
}

export const AnalysisAlgorithmSeverityNameMapping: INameMapper[] = [
  { id: AnalysisAlgorithmSeverityEnum.INFO, name: loc.AnalysisAlgorithmSeverityNameMapping[env.language].AnalysisAlgorithmSeverityEnum.INFO, classType: 'rule-info' },
  { id: AnalysisAlgorithmSeverityEnum.WARNING, name: loc.AnalysisAlgorithmSeverityNameMapping[env.language].AnalysisAlgorithmSeverityEnum.WARNING, classType: 'rule-warning' },
  { id: AnalysisAlgorithmSeverityEnum.ERROR, name: loc.AnalysisAlgorithmSeverityNameMapping[env.language].AnalysisAlgorithmSeverityEnum.ERROR, classType: 'rule-erorr' },
];

export const AnalysisAlgorithmTypeNameMapping: INameMapper[] = [
  // tslint:disable-next-line: max-line-length
  { id: AnalysisAlgorithmTypeEnum.DETECTION_OF_ANOMALIES, name: loc.AnalysisAlgorithmTypeNameMapping[env.language].AnalysisAlgorithmTypeName.DETECTION_OF_ANOMALIES, classType: 'detection-rule' },
  { id: AnalysisAlgorithmTypeEnum.FORECASTING, name: loc.AnalysisAlgorithmTypeNameMapping[env.language].AnalysisAlgorithmTypeName.FORECASTING, classType: 'forecasting-rule' },
  { id: AnalysisAlgorithmTypeEnum.EXPLORATION, name: loc.AnalysisAlgorithmTypeNameMapping[env.language].AnalysisAlgorithmTypeName.EXPLORATION, classType: 'exploration-rule' },
];

export const analysisStatusNamesMap: INameMapper[] = [
  {
    id: AnalysisStatusEnum.IN_PROGRESS,
    name: loc.analysisStatusNamesMap[env.language].analysisStatus.IN_PROGRESS, classType: 'analysis-doing'
  },
  {
    id: AnalysisStatusEnum.DONE,
    name: loc.analysisStatusNamesMap[env.language].analysisStatus.DONE, classType: 'analysis-done'
  },
  {
    id: AnalysisStatusEnum.FAILED,
    name: loc.analysisStatusNamesMap[env.language].analysisStatus.FAILED, classType: 'analysis-failed'
  },
  {
    id: AnalysisStatusEnum.UNDEFINED,
    name: loc.analysisStatusNamesMap[env.language].analysisStatus.UNDEFINED, classType: 'analysis-undefined'
  },
];
