<mat-tab-group class="details-algorithms-tab-group" (selectedTabChange)="onTabClick($event)">
	<div *ngFor="let item of listAlgorithmsDetectionOfAnomalies; let i = index">

		<mat-tab *ngIf="item.enabled && isDataInAlgorithms(item)" label="{{getTranslatedAlgorithmName(item.name)}}">
		</mat-tab>
	</div>
	<div *ngFor="let item of listAlgorithmsForecasting; let i = index">

		<mat-tab *ngIf="item.enabled && isDataInAlgorithms(item)" label="{{getTranslatedAlgorithmName(item.name)}}">
		</mat-tab>
	</div>
</mat-tab-group>
<div class="row">
	<div id="chart" class="col col-chart">
	<ng-container *ngIf="actualAnalysisAlgorithm.algorithmType===0">
		<sv-grafana-dashboard *ngIf="isData()" [iframeUrl]="getURL()" [width]="600" [height]="350"></sv-grafana-dashboard>
	</ng-container>
	<ng-container *ngIf="actualAnalysisAlgorithm.algorithmType===1">
		<sv-grafana-dashboard [iframeUrl]="getForecastingURL()" [width]="600" [height]="350"></sv-grafana-dashboard>
	</ng-container>
	</div>
	<div id="anomalyDetection" *ngIf="actualAnalysisAlgorithm.algorithmType===0" class="col col-table">
		<h4>
			<span>{{details}}</span>
		</h4>

		<mat-table #table [dataSource]="dataSourceDetails">
			<ng-container matColumnDef="date">
				<mat-header-cell *matHeaderCellDef> {{headers.DATE}} </mat-header-cell>
				<div *ngIf="analysisEvent.aggregation === 'h'">
					<mat-cell *matCellDef="let element"> {{element.timestamp | svDateTime}}
					</mat-cell>
				</div>
				<div *ngIf="analysisEvent.aggregation === 'd' || analysisEvent.aggregation === 'm'">
					<mat-cell *matCellDef="let element"> {{element.timestamp | svDate}}
					</mat-cell>
				</div>
			</ng-container>

			<ng-container matColumnDef="temp">
				<mat-header-cell *matHeaderCellDef mat-sort-header> {{headers.AVG_TEMP}} </mat-header-cell>
				<mat-cell *matCellDef="let element">{{element.avgOutTemp | number:'1.0-2'}}</mat-cell>
			</ng-container>

			<ng-container matColumnDef="energy">
				<mat-header-cell *matHeaderCellDef mat-sort-header> {{energy}} </mat-header-cell>
				<mat-cell *matCellDef="let element">{{element.energy | number:'1.0-2'}}</mat-cell>
			</ng-container>
			<ng-container matColumnDef="percentage">
				<mat-header-cell *matHeaderCellDef mat-sort-header> {{headers.PERCENTAGE}} </mat-header-cell>
				<mat-cell *matCellDef="let element">{{element.measurementDiff | number:'1.0-2'}}</mat-cell>
			</ng-container>


			<mat-header-row *matHeaderRowDef="displayedColumnsDetails"></mat-header-row>
			<mat-row *matRowDef="let row; columns: displayedColumnsDetails;"></mat-row>
		</mat-table>
	</div>

	<div id="forecasting" *ngIf="actualAnalysisAlgorithm.algorithmType===1" class="col col-table">
		<h4>
			<span>{{details}}</span>
			<!-- <a mat-raised-button color="primary" class="btn">send message</a> -->
		</h4>

		<mat-table #table [dataSource]="forecastingDataSourceDetails">

			<ng-container matColumnDef="energy">
				<mat-header-cell *matHeaderCellDef mat-sort-header> {{energy}} </mat-header-cell>
				<mat-cell *matCellDef="let element">{{element.energy | number:'1.0-2'}}</mat-cell>
			</ng-container>
			<ng-container matColumnDef="period">
				<mat-header-cell *matHeaderCellDef mat-sort-header> {{headers.PERIOD}} </mat-header-cell>
				<mat-cell *matCellDef="let element">{{element.period}}</mat-cell>
			</ng-container>


			<mat-header-row *matHeaderRowDef="forecastingDisplayedColumnsDetails"></mat-header-row>
			<mat-row *matRowDef="let row; columns: forecastingDisplayedColumnsDetails;"></mat-row>
		</mat-table>
	</div>

	<div class="no-result" [style.display]="!isLoading() && getDataSourceLength() === 0 ? '' : 'none'">
		{{noData}}
	</div>

	<div class="no-result" [style.display]="isLoading() ? '' : 'none'">
		<mat-spinner [strokeWidth]="3" [diameter]="20" class="notranslate"></mat-spinner>
	</div>
	<mat-paginator #paginator [length]="getDataSourceLength()" [pageIndex]="0" [pageSize]="5"
		[pageSizeOptions]="[5, 10, 20]" showFirstLastButtons class="notranslate">
	</mat-paginator>
</div>