import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';

import { BaseDataStore, GenericHttpParams, BooleanResult, AnalysisResult, UtilityTypeEnum } from '@shared/types';
import { AnalysisAlgorithm } from '@shared/models/appModels/analysisAlgorithm.model';
import { AnalysisAlgorithmHttpParamG } from '@shared/types/http/analysisAlgorithmHttpConfig';
import { AnalysisAlgorithmService } from '@shared/services/http/analysisAlgorithm.service';
import { CommonAppDataService } from '@shared/services/commonAppData.service';
import { AnalysisAlgorithmTypeEnum } from '@shared/types/modelTypes/analysisAlgorithmTypes';
import { RestEnumMpapper } from '@shared/models/RestSupport';


@Injectable()
export class AnalysisAlgorithmDataStoreService extends BaseDataStore<GenericHttpParams<AnalysisAlgorithm>, AnalysisAlgorithmHttpParamG> {

    static RuleTypeMapper: RestEnumMpapper<typeof AnalysisAlgorithmTypeEnum> = new RestEnumMpapper<typeof AnalysisAlgorithmTypeEnum>();

    private analysisAlgorithmSubject$: Subject<AnalysisAlgorithm> = new Subject<AnalysisAlgorithm>();
    private analysisAlgorithmObservable$: Observable<AnalysisAlgorithm> = this.analysisAlgorithmSubject$.asObservable();

    constructor(
        private analysisAlgorithmHttpService: AnalysisAlgorithmService, cs: CommonAppDataService) {
        super(cs);
    }

    getChangeObservable(): Observable<AnalysisAlgorithm> {
        return this.analysisAlgorithmObservable$;
    }

    getAnalysisAlgorithmsTypeDetectionOfAnomalies(allowEmpty: boolean = true): Observable<AnalysisAlgorithm[]> {
        return this.getAnalysisAlgorithmList(allowEmpty, AnalysisAlgorithmTypeEnum.DETECTION_OF_ANOMALIES);
    }

    getAnalysisAlgorithmsTypeForecasting(allowEmpty: boolean = true): Observable<AnalysisAlgorithm[]> {
        return this.getAnalysisAlgorithmList(allowEmpty, AnalysisAlgorithmTypeEnum.FORECASTING);
    }

    private getAnalysisAlgorithmList(allowEmpty: boolean, type: AnalysisAlgorithmTypeEnum) {
        const params = this.getEmptyParams();
        params.queryParams.svAnalysisAlgorithmType = AnalysisAlgorithmDataStoreService.RuleTypeMapper.getEnumAsString(AnalysisAlgorithmTypeEnum, type);
        params.queryParams.svUtilityType = UtilityTypeEnum[this.cs.getCurrentMediaType()];
        params.config.strict = !allowEmpty;
        return this.analysisAlgorithmHttpService.getAnalysisAlgorithmsList(params);
    }

    getAnalysisAlgorithmsTypeExploration(allowEmpty: boolean = true): Observable<AnalysisAlgorithm[]> {
        return this.getAnalysisAlgorithmList(allowEmpty, AnalysisAlgorithmTypeEnum.EXPLORATION);
    }

    getAnalysisAlgorithmById(id: number): Observable<AnalysisAlgorithm> {
        const params = this.getEmptyParams();
        params.path.idAnalysisAlgorithm = '' + id;
        return this.analysisAlgorithmHttpService.getAnalysisAlgorithmById(params);
    }

    // tslint:disable-next-line: max-line-length
    toggleAnalysisAlgorithm(id: number, state: boolean, ruleType: AnalysisAlgorithmTypeEnum, options: { emitEvent: boolean } = { emitEvent: true }): Observable<AnalysisAlgorithm> {
        const params = this.getEmptyParams();
        params.path.idAnalysisAlgorithm = '' + id;
        params.body = { value: state } as BooleanResult;
        // tslint:disable-next-line: max-line-length
        params.queryParams.svAnalysisAlgorithmType = new RestEnumMpapper<typeof AnalysisAlgorithmTypeEnum>().getEnumAsString(AnalysisAlgorithmTypeEnum, ruleType);

        return this.analysisAlgorithmHttpService.postAlgorithmsEnabled(params)
            .do(r => {
                this.analysisAlgorithmSubject(options, r);
            });
    }

    private analysisAlgorithmSubject(options: { emitEvent: boolean }, r: AnalysisAlgorithm) {
        if (options.emitEvent) {
            this.analysisAlgorithmSubject$.next(r);
        }
    }

    // tslint:disable-next-line: max-line-length
    statusAnalysisAlgorithm(id: number, state: string, ruleType: AnalysisAlgorithmTypeEnum, options: { emitEvent: boolean } = { emitEvent: true }): Observable<AnalysisAlgorithm> {
        const params = this.getEmptyParams();
        params.path.idAnalysisAlgorithm = '' + id;
        params.body = { value: state } as AnalysisResult;
        // tslint:disable-next-line: max-line-length
        params.queryParams.svAnalysisAlgorithmType = new RestEnumMpapper<typeof AnalysisAlgorithmTypeEnum>().getEnumAsString(AnalysisAlgorithmTypeEnum, ruleType);

        return this.analysisAlgorithmHttpService.postAlgorithmsAnalysis(params)
            .do(r => {
                this.analysisAlgorithmSubject(options, r);
            });
    }

    protected getEmptyParams(): AnalysisAlgorithmHttpParamG {
        const r: AnalysisAlgorithmHttpParamG = { body: {} as AnalysisAlgorithm, config: {}, headers: {}, path: {}, queryParams: {} };
        return r;

    }
}
