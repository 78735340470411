<h2>{{header}}</h2>

<div class="card table-filter">
	<div class="card-header input-filter">
		<div>
			<b>{{filtersLabel}}:</b>

			<mat-form-field id="svMeterFilter">
				<input matInput [formControl]="filterbyMeterPoint" placeholder={{tableHeaders.METER_ID}}>
			</mat-form-field>
		</div>

		<button id="svAddNewElement" (click)="addElement()" mat-raised-button color="primary" class="btn">{{addNewButton}}</button>
	</div>

	<mat-table id="svMetersTable" #table [dataSource]="dataSource">
		<!-- id Column -->
		<ng-container matColumnDef="id">
			<mat-header-cell *matHeaderCellDef>{{tableHeaders.METER_ID}}</mat-header-cell>
			<mat-cell *matCellDef="let element">{{element.serialNumber}}</mat-cell>
		</ng-container>

		<ng-container matColumnDef="denotation">
			<mat-header-cell *matHeaderCellDef>{{tableHeaders.CUSTOMER}}</mat-header-cell>
			<mat-cell *matCellDef="let element">
				{{ (element.hasCosutomer()) ? element.customer.denotation : noCustomerString.customer}} </mat-cell>
		</ng-container>

		<!-- Serial Meter Column -->
		<ng-container matColumnDef="serialMeter">
			<mat-header-cell *matHeaderCellDef>{{tableHeaders.METER_ID}}</mat-header-cell>
			<mat-cell *matCellDef="let element"> {{ element.serialNumber}} </mat-cell>
		</ng-container>

		<ng-container matColumnDef="address">
			<mat-header-cell *matHeaderCellDef> {{tableHeaders.LOCATION}} </mat-header-cell>
			<mat-cell *matCellDef="let element"> {{ element.meterPlacement}} </mat-cell>
		</ng-container>

		<ng-container matColumnDef="segment">
			<mat-header-cell *matHeaderCellDef> {{tableHeaders.SEGMENT}} </mat-header-cell>
			<mat-cell *matCellDef="let element">
				{{ (element.hasCosutomer()) ? element.customer.getCustomerSegmentAsString() : noCustomerString.segment}}
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="measurementType">
			<mat-header-cell *matHeaderCellDef> {{tableHeaders.MES_TYPE}} </mat-header-cell>
			<mat-cell *matCellDef="let element"> {{ element.getMesurementTypeaAsString() }} </mat-cell>
		</ng-container>

		<ng-container matColumnDef="action">
			<mat-header-cell *matHeaderCellDef class="text-right">{{tableHeaders.ACTION}}</mat-header-cell>
			<mat-cell *matCellDef="let element" class="text-right">
				<button mat-icon-button [matMenuTriggerFor]="menu">
					<i class="material-icons notranslate">{{menuMdIcon}}</i>
				</button>

				<mat-menu #menu="matMenu" class="menu-box">
					<button mat-menu-item (click)="editElement(element)">
						<i class="material-icons notranslate">{{configureMdIcon}}</i>
						<span>{{actions.EDIT}}</span>
					</button>

					<button *ngIf="element.active !== false" mat-menu-item (click)="onDeactivateMeter(element)">
						<i class="material-icons notranslate">{{deleteMdIcon}}</i>
						<span>{{actions.DEACTIVATE}}</span>
					</button>

					<button *ngIf="element.active === false" mat-menu-item (click)="onActivateMeter(element)">
						<i class="material-icons notranslate">{{checkMdIcon}}</i>
						<span>{{actions.ACTIVATE}}</span>
					</button>

					<button *ngIf="!element.customer" mat-menu-item (click)="onAssignCustomer(element)">
						<i class="material-icons notranslate">{{checkMdIcon}}</i>
						<span>{{actions.ASSIGN_CUSTOMER}}</span>
					</button>

					<button *ngIf="element.customer" mat-menu-item (click)="onUnassignCustomer(element)">
						<i class="material-icons notranslate">{{deleteMdIcon}}</i>
						<span>{{actions.UNASSIGN_CUSTOMER}}</span>
					</button>

				</mat-menu>
			</mat-cell>
		</ng-container>

		<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
		<mat-row *matRowDef="let row; columns: displayedColumns;" [class.inactive]="!isRowMeterPointActive(row)">
		</mat-row>
	</mat-table>

	<div class="no-result" *ngIf="!isLoading() && getDataSourceLength() === 0">
		{{noData}}
	</div>

	<div class="no-result" *ngIf="isLoading()">
		<mat-spinner [strokeWidth]="3" [diameter]="20" class="notranslate"></mat-spinner>
	</div>

	<mat-paginator #paginator
		[length]="dataLength"
		[pageIndex]="0"
		[pageSize]="10"
		[pageSizeOptions]="[5, 10, 20]"
		showFirstLastButtons
		class="notranslate">
	</mat-paginator>
</div>
