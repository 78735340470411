import * as moment from 'moment';
/* Not from index.js to avoid circular dependency webpack detection warning */
import { IPosition, Position } from '@shared/models/appModels/position.model';
import { IGetInterfaceType} from '@shared/types/applicationTypes';
import { SchedulePrefixSeparator, UtilityTypeEnum } from '@shared/types';
import { RestEnumMpapper } from '@shared/models/RestSupport/restEnumMapper.model';

export interface ISchedule {

  idSchedule: number;    /* Database ID */
  name: string;
  scheduleNumber: number; /* Human-friendly ID */
  beginDate?: number;
  endDate?: number;
  closed: boolean;
  utilityType: UtilityTypeEnum | string;
}

export class Schedule implements ISchedule, IGetInterfaceType<ISchedule, Schedule> {

  /* Enum mappers */
  static UtilityTypeMapper: RestEnumMpapper<typeof UtilityTypeEnum> = new RestEnumMpapper<typeof UtilityTypeEnum>();

  /* Interface implementation */
  idSchedule: number;
  name: string;
  scheduleNumber: number;
  beginDate?: number;
  endDate: number;
  closed: boolean;
  utilityType: UtilityTypeEnum | string;

  /* Class extensions */
  positions?: Array<Position>;

  constructor(iSch: ISchedule, iPos?: IPosition[]) {

    /* Construct object basing on interface object input */
    this.idSchedule = (iSch && typeof(iSch.idSchedule) !== 'undefined') ? iSch.idSchedule : undefined;
    this.name = (iSch && typeof(iSch.name) !== 'undefined') ? iSch.name : undefined;
    this.scheduleNumber = (iSch && typeof(iSch.scheduleNumber) !== 'undefined') ? iSch.scheduleNumber : undefined;
    this.beginDate = (iSch && typeof(iSch.beginDate) !== 'undefined' ) ? iSch.beginDate : undefined;
    this.endDate = (iSch && typeof(iSch.endDate) !== 'undefined') ? iSch.endDate : undefined;
    this.closed = (iSch && typeof(iSch.closed) !== 'undefined' ) ? iSch.closed : undefined;
    this.utilityType = (typeof(iSch.utilityType) !== 'undefined') ? this.assignMediaType(iSch.utilityType) : undefined;

    this.positions = [];
    if (iPos && iPos.length > 0) {
      iPos.forEach(posIfc => { this.positions.push(new Position(posIfc)); });
    } else {
      /* Leave empty */
    }
  }

  private assignMediaType(eIn: UtilityTypeEnum | string): UtilityTypeEnum {
    try {
      return Schedule.UtilityTypeMapper.getEnumAsNumber(UtilityTypeEnum, eIn);
    } catch (e) {
      console.warn(e);
      return UtilityTypeEnum.OTHER;
    }
  }

  public getName(options?: {addPostfix: boolean}): string {
    return this.name;
  }

  public getScheduleName(options?: {addPrefix: boolean}): string {
    if (options && options.addPrefix) {
      return `${this.name} ${SchedulePrefixSeparator}${this.scheduleNumber}`;
    } else {
      return `${this.name}`;
    }
  }

  public getId(): number {
    return this.idSchedule;
  }

  public getPositionName(idx: number, options?: {addPrefix: boolean}): string {
    if (this.positions && this.positions[idx]) {
        return this.positions[idx].getName(options);
    } else {
      return undefined;
    }
  }

  public isScheduleClosed(): boolean {
    return this.closed;
  }

  public isScheduleFuture(): boolean {
    const today = moment.utc();
    return moment(this.beginDate).isAfter(today, 'day') && moment(this.endDate).isAfter(today, 'day');
  }

  public hasPositions(): boolean {
    return this.positions && this.positions.length > 0;
  }

  public isPast(): boolean {
    return moment(this.endDate).isBefore(moment.utc());
  }

  public isFuture(): boolean {
    return moment(this.beginDate).isAfter(moment.utc());
  }

  public getPositions(): Position[] {
    if (this.positions) {
      return this.positions.slice(0);
    } else {
      return [];
    }
  }

  public addPosition(p: Position) {
    try {
      this.positions.push(p);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  getInterface(webInterface: boolean = true): ISchedule {
    return {
      name: this.name,
      beginDate: this.beginDate,
      endDate: this.endDate,
      idSchedule: this.idSchedule,
      closed: this.closed,
      scheduleNumber: this.scheduleNumber,
      utilityType: Schedule.UtilityTypeMapper.getEnumAsString(UtilityTypeEnum, this.utilityType)
    };
  }


}
