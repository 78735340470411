import { Injectable } from "@angular/core";
import { LanguagesEnum } from "@shared/types/localisation";
import {
  getTranslations,
  loadTranslations,
  ParsedTranslationBundle,
} from "@locl/core";
import { environment } from "@env/environment";

// Angular locales
import { registerLocaleData } from "@angular/common";
import localePl from "@angular/common/locales/pl";
import localeDe from "@angular/common/locales/de";
import localeEn from "@angular/common/locales/en";

import * as moment from "moment";

let language: string;

export function loadSvTranslations(): Promise<ParsedTranslationBundle> {
  const lang = environment.language;
  let path = undefined;

  switch (lang) {
    case LanguagesEnum.PL:
      language = "pl-PL";
      path = "/assets/i18n/pl-translations.json";
      break;

    case LanguagesEnum.GER:
      language = "de-DE";
      path = "/assets/i18n/de-translations.json";
      break;

    default:
      language = "en-EN";
      break;
  }

  if (path === undefined) {
    loadTranslations();
    return Promise.resolve(null);
  }

  return getTranslations(path);
}

@Injectable({ providedIn: "root" })
export class LocaleService {
  constructor() {
    this.setupLocale();
  }

  public getLanguage(): string {
    return language;
  }

  private setupLocale(): void {
    let localeData = null;

    switch (language) {
      case "pl-PL":
        localeData = localePl;
        break;
      case "de-DE":
        localeData = localeDe;
        break;
      default:
        localeData = localeEn;
        break;
    }

    registerLocaleData(localeData);
    moment.locale(language.substring(0, 2));
  }
}
