// Angular modules import
import { Injectable } from '@angular/core';

// RjJS imports
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/combineLatest';
import { Subject } from 'rxjs/Subject';

/* Datatypes */
import { MeterPoint } from '@shared/models/appModels/meterPoint.model';
import { MeterData } from '@shared/models/appModels/meterData.model';
import { MeasurementData } from '@shared/models/appModels/measurementData.model';
import { Customer } from '@shared/models/appModels/customer.model';

/* Services */
import { MeterPointService } from '@shared/services/http/meterPoint.service';

import { environment } from '@env/environment';
import { GenericHttpParams, BooleanResult } from '@shared/types';
import { BaseDataStore, ApiDataShift } from '@shared/types/applicationTypes';
import { MeterPointHttpParamG, MeterPointQueryParams } from '@shared/types/http/meterPointHttpConfig';
import { CommonAppDataService } from '@shared/services/commonAppData.service';

@Injectable()
export class MeterPointDataStoreService extends BaseDataStore<GenericHttpParams<MeterPoint>, MeterPointHttpParamG> {

  readonly debugMode: boolean = environment.debug;

  /* Cache tables */
  meters: MeterPoint[] = [];

  private meterPointSubject$: Subject<MeterPoint> = new Subject<MeterPoint>();
  private meterDataObservable$: Observable<MeterPoint> = this.meterPointSubject$.asObservable();

  constructor(private meterPointHTTPService: MeterPointService,
    cs: CommonAppDataService
  ) {
    super(cs);
  }

  getChangeObservable(): Observable<MeterPoint> {
    return this.meterDataObservable$;
  }

  private setParams(nums: ApiDataShift, allowEmpty: boolean): MeterPointHttpParamG {
    const params = this.getEmptyParams();
    params.queryParams.limit = '' + nums.limit;
    params.queryParams.offset = '' + nums.offset;
    params.config.strict = !allowEmpty;
    return params;
  }

  getMeterPointsListAll(
    nums: ApiDataShift = { limit: 10, offset: 0 },
    allowEmpty: boolean = true,
    ids?: number[]): Observable<MeterPoint[]> {
    const params = this.setParams(nums, allowEmpty);
    if (ids && ids.length !== 0) {
      params.queryParams.id = '' + ids;
    }
    return this.meterPointHTTPService.getMeterPointsList(params);
  }

  getMeterPointsListBySerial(
    names: string,
    nums: ApiDataShift = { limit: 10, offset: 0 },
    allowEmpty: boolean = true): Observable<MeterPoint[]> {
    const params = this.setParams(nums, allowEmpty);
    params.queryParams.name = names;
    return this.meterPointHTTPService.getMeterPointsList(params);
  }

  getMeterPointById(idMeterPoint: number, allowEmpty: boolean = false): Observable<MeterPoint> {
    const params = this.getEmptyParams();
    params.path.idMeterPoint = '' + idMeterPoint;
    params.config.strict = !allowEmpty;

    return this.meterPointHTTPService.getMeterPointsById(params);
  }

  getMeterPointBySerialMatch(
    name: string,
    nums: ApiDataShift = { limit: 50, offset: 0 },
    allowEmpty: boolean = false): Observable<MeterPoint> {
    const params = this.setParams(nums, allowEmpty);
    params.queryParams.name = name;

    return this.getMeterPointBySerialNumber(params);
  }

  getMeterPointExistenceBySerial(
    name: string,
    nums: ApiDataShift = { limit: 10, offset: 0 },
    allowEmpty: boolean = true): Observable<boolean> {
    const params = this.setParams(nums, allowEmpty);
    params.queryParams.name = name;

    return this.getMeterPointBySerialNumber(params)
      .map<MeterPoint, boolean>((mp: MeterPoint) => {
        if (Object.keys(mp).length !== 0) {
          return true;
        } else {
          return false;
        }
      });
  }

  getMeterPointCount(filters?: MeterPointQueryParams, allowEmpty: boolean = false) {
    const params = this.getEmptyParams();
    if (filters) {
      const properties = ['id', 'name', 'active'];
      properties.forEach((value) => {
        if (filters.hasOwnProperty(value)) {
          // if filters contains some of this properties add them to queryParams
          // this is done to avoid sending undefined values which generates 400 http errors
          params.queryParams[value] = filters[value];
        }
      });
  }
    params.config.strict = !allowEmpty;
    return this.meterPointHTTPService.getMeterPointsListCount(params);
  }

  updateMeterPoint(item: MeterPoint,
    allowEmpty: boolean = false,
    options: { emitEvent: boolean } = { emitEvent: true }): Observable<MeterPoint> {
    const params = this.getEmptyParams();
    params.body = item;
    params.path.idMeterPoint = '' + item.idMeterPoint;
    params.config.strict = !allowEmpty;

    return this.meterPointHTTPService.putMeterPointsById(params)
      .do((updated: MeterPoint) => {
        const findIdx = this.meters.findIndex(meter => meter.idMeterPoint === updated.idMeterPoint);
        if (findIdx !== -1) {
          this.meters[findIdx] = Object.assign({}, updated);
        } else {
          console.warn('Unexisting cache meter point updated');
          this.meters.push(updated);
        }
        this.meterPointsSubject(options, updated);
      });
  }

  addMeterPoint(item: MeterPoint,
    allowEmpty: boolean = false,
    options: { emitEvent: boolean } = { emitEvent: true }): Observable<MeterPoint> {
    const params = this.getEmptyParams();
    params.body = item;
    params.config.strict = !allowEmpty;

    return this.meterPointHTTPService.postMeterPoints(params)
      .do((added: MeterPoint) => {
        this.meters.push(added);
        this.meterPointsSubject(options, added);
      });
  }

  deleteMeterPoint(idMeterPoint: number,
    allowEmpty: boolean = false,
    options: { emitEvent: boolean } = { emitEvent: true }) {
    const params = this.getEmptyParams();
    params.path.idMeterPoint = '' + idMeterPoint;
    params.config.strict = !allowEmpty;

    return this.meterPointHTTPService.deleteMeterPointsById(params)
      .do((deleted: MeterPoint) => {
        const findIdx = this.meters.findIndex(meter => meter.idMeterPoint === deleted.idMeterPoint);
        if (findIdx !== -1) {
          this.meters.splice(findIdx, 1);
        } else {
          console.warn('Unexisting meterpoint in cache deleted');
        }
        this.meterPointsSubject(options, deleted);
      });
  }

  private meterPointsSubject(options: { emitEvent: boolean }, updated: MeterPoint) {
    if (options.emitEvent) {
      this.meterPointSubject$.next(updated);
    }
  }

  getMeterPointCustomer(idMeterPoint: number, allowEmpty: boolean = false): Observable<Customer> {
    const params = this.getEmptyParams();
    params.path.idMeterPoint = '' + idMeterPoint;
    params.config.strict = !allowEmpty;

    return this.meterPointHTTPService.getMeterPointCustomer(params);
  }

  getMeterPointMeterData(
    idMeterPoint: number,
    limit: number = environment.defaultReqLimit,
    offset: number = 0,
    allowEmpty: boolean = true): Observable<MeterData[]> {

    const params = this.setParams({ limit: limit, offset: offset }, allowEmpty);
    params.path.idMeterPoint = '' + idMeterPoint;

    return this.meterPointHTTPService.getMeterPointMeterData(params);
  }

  getMeterPointMeasurementData(
    idMeterPoint: number,
    limit: number = environment.defaultReqLimit,
    offset: number = 0,
    allowEmpty: boolean = true): Observable<MeasurementData[]> {

    const params = this.setParams({ limit: limit, offset: offset }, allowEmpty);
    params.path.idMeterPoint = '' + idMeterPoint;

    return this.meterPointHTTPService.getMeterpointMesurementdata(params);
  }

  activateMeterPoint(id: number, options: { emitEvent: boolean } = { emitEvent: true }): Observable<MeterPoint> {
    return this.setStatusMeterPoint(true, options, id);
  }

  deactivateMeterPoint(id: number, options: { emitEvent: boolean } = { emitEvent: true }): Observable<MeterPoint> {
    return this.setStatusMeterPoint(false, options, id);
  }

  private setStatusMeterPoint(status: boolean, options, id): Observable<MeterPoint> {
    const params = this.setStatusParams(status, id);
    return this.setMeterPointActiveStatus(params, options);
  }

  private setStatusParams(status: boolean, id): MeterPointHttpParamG {
    const params = this.getEmptyParams();
    params.path.idMeterPoint = '' + id;
    params.body = { value: status } as BooleanResult;
    return params;
  }

  private setEnabledStatus(id: number, options: { emitEvent: boolean }, status: boolean) {
    const params = this.setStatusParams(status, id);
    return this.setMeterPointEnabledStatus(params, options);
  }

  checkedMeterPoint(id: number, options: { emitEvent: boolean } = { emitEvent: true }): Observable<MeterPoint> {
    return this.setEnabledStatus(id, options, true);
  }

  uncheckedMeterPoint(id: number, options: { emitEvent: boolean } = { emitEvent: true }): Observable<MeterPoint> {
    return this.setEnabledStatus(id, options, false);
  }

  getMeterPointWithMeterDataByIdMeterPoint(idMeterPoint: number, allowEmpty: boolean = false): Observable<MeterPoint> {
    const params = this.getEmptyParams();
    params.path.idMeterPoint = '' + idMeterPoint;
    params.config.strict = !allowEmpty;

    let mp: MeterPoint;
    return this.meterPointHTTPService.getMeterPointsById(params)
      .concatMap<MeterPoint, MeterData[]>((meter: MeterPoint) => {
        mp = meter;
        params.config.strict = false;
        return this.meterPointHTTPService.getMeterPointMeterData(params);
      })
      .concatMap<MeterData[], MeterPoint>((md: MeterData[]) => {
        mp.setMeterData(md);
        return Observable.of(mp);
      });
  }

  getMeterPointWithMeasurementDataByIdMeterPoint(idMeterPoint: number, allowEmpty: boolean = false): Observable<MeterPoint> {
    const params = this.getEmptyParams();
    params.path.idMeterPoint = '' + idMeterPoint;
    params.config.strict = !allowEmpty;

    let mp: MeterPoint;
    return this.meterPointHTTPService.getMeterPointsById(params)
      .concatMap<MeterPoint, MeasurementData[]>((meter: MeterPoint) => {
        mp = meter;
        params.config.strict = false;
        return this.meterPointHTTPService.getMeterpointMesurementdata(params);
      })
      .concatMap<MeasurementData[], MeterPoint>((md: MeasurementData[]) => {
        mp.setMeasurementData(md);
        return Observable.of(mp);
      });
  }

  getMetersListWithCustomer(
    nums: ApiDataShift = { limit: 10, offset: 0 },
    allowEmpty: boolean = true,
    filters?: MeterPointQueryParams): Observable<MeterPoint[]> {
    const params = this.setParams(nums, allowEmpty);

    if (filters) {
      if (filters.name) {
        params.queryParams.name = filters.name;
      }
    }

    return this.meterPointHTTPService.getMeterPointsList(params)
      .concatMap<MeterPoint[], MeterPoint[]>((meterList: MeterPoint[]) => {
        return Observable.of(meterList)
          .concatMap<MeterPoint[], MeterPoint[]>((mpList: MeterPoint[]) => {
            return Observable.from(mpList)
              .concatMap<MeterPoint, MeterPoint>((mp: MeterPoint, idx: number) => {
                return Observable.of(mp)
                  .filter(meter => meter.idCustomer !== undefined) /* Do not query unassigend customers */
                  .concatMap<MeterPoint, Customer>((meter: MeterPoint) => {
                    return this.getMeterPointCustomer(meter.idMeterPoint, false);
                  })
                  .concatMap<Customer, MeterPoint>((c: Customer) => {
                    meterList[idx].setCustomer(c);
                    return Observable.of(meterList[idx]);
                  });
              })
              .toArray() /* Wait for all to complete */
              .map(arr => meterList);
          });
      });
  }

  private getMeterPointBySerialNumber(params: MeterPointHttpParamG): Observable<MeterPoint> {
    let querySerial: string;
    try {
      querySerial = '' + params.queryParams.name;
    } catch (e) {
      throw new Error('missing serial qiery paraneter value');
    }
    return this.meterPointHTTPService.getMeterPointsList(params)
      .concatMap<MeterPoint[], MeterPoint>(mp => {
        return Observable.from(mp);
      })
      .filter(mp => mp.serialNumber === querySerial)
      .defaultIfEmpty({} as MeterPoint);
  }

  private setMeterPointActiveStatus(params: MeterPointHttpParamG, options: { emitEvent: boolean }): Observable<MeterPoint> {
    return this.meterPointHTTPService.putMeterPointActivation(params)
      .do(mp => {
        this.meterPointsSubject(options, mp);
      });
  }

  private setMeterPointEnabledStatus(params: MeterPointHttpParamG, options: { emitEvent: boolean }): Observable<MeterPoint> {
    return this.meterPointHTTPService.putMeterPointEnabled(params)
      .do(mp => {
        this.meterPointsSubject(options, mp);
      });
  }

  protected getEmptyParams(): MeterPointHttpParamG {
    const r: MeterPointHttpParamG = { queryParams: {}, path: {}, headers: {}, body: {} as MeterPoint, config: {} };
    return r;
  }

}
