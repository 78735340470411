import { IVeeRule } from '@shared/models/appModels/VeeRule.model';
import { VeeRuleSeverityEnum, VeeRuleTypeEnum } from '@shared/types/modelTypes/veeRuleTypes';
import { UtilityTypeEnum } from '@shared/types/utilityType';


export const veeRulesValidationMock: IVeeRule[] = [
  {
    id: 1, name: 'Meterpoint measurements - measurements comparison',
    description: 'Current reading < previous reading',
    rule_type: VeeRuleTypeEnum.VALIDATION,
    recommendation: 'Current measurement seems to be incorrect. Please provide correct measurement manually.',
    severity: VeeRuleSeverityEnum.INFO,
    priority: 1,
    switchable: true, enabled: true, media_type: UtilityTypeEnum.HEAT
  },
  {
    id: 2, name: 'Meterpoint measurements - dates comparison',
    rule_type: VeeRuleTypeEnum.VALIDATION,
    description: 'Current reading date < previous reading date',
    recommendation: 'Current measurement date seems to be incorrect. Please provide correct measurement manually.',
    severity: VeeRuleSeverityEnum.WARNING, priority: 1,
    switchable: true, enabled: true, media_type: UtilityTypeEnum.HEAT
  },
  {
    id: 3, name: 'Meterpoint measurements - usage comparison',
    rule_type: VeeRuleTypeEnum.VALIDATION,
    description: 'Current period consumption=0 and previous period consumption>0, excluding end of season',
    recommendation: 'Consumption seems to be incorrect. Please check all the measurements for such meterpoint.',
    severity: VeeRuleSeverityEnum.INFO, priority: 1,
    switchable: true, enabled: true, media_type: UtilityTypeEnum.HEAT
  },
  {
    id: 4, name: 'Meterpoint measurements - action codes sequence',
    rule_type: VeeRuleTypeEnum.VALIDATION,
     description: 'Checking if the sequence of action codes  (NORMAL, CONTROL, DISCONNECTION , INSTALLATION, FAILURE , INACCESIBLE, BAD) assigned to all readings from praticular meterpoint is correct',
     recommendation: 'Sequence of the actions taken on the meter or measurement flags order is incorrect. Please check all the measurements and inspect the meter on-site.',
     severity: VeeRuleSeverityEnum.ERROR, priority: 1,
     switchable: true, enabled: true, media_type: UtilityTypeEnum.HEAT
  },
  {
    id: 5, name: 'Sequence of the actions taken on the meter or measurement flags order is incorrect. Please check all the measurements and inspect the meter on-site.',
    rule_type: VeeRuleTypeEnum.VALIDATION,
     description: 'Assignment to the group of meters that should be read at the same time ',
     recommendation: 'Meterpoints was not measured in the same timeframe. Please check the assignment of the particular meterpoint to the correct group.',
     severity: VeeRuleSeverityEnum.ERROR, priority: 1,
     switchable: true, enabled: true, media_type: UtilityTypeEnum.HEAT
  },
  {
    id: 6, name: 'Meterpoint measurements - working time control',
    rule_type: VeeRuleTypeEnum.VALIDATION,
     description: 'If meter working time is equal to reading dates for such period',
     recommendation: 'Meterpoint working time does not match the time resulting from the measurement dates. Please check the measurement dates.',
     severity: VeeRuleSeverityEnum.WARNING, priority: 1,
     switchable: true, enabled: true, media_type: UtilityTypeEnum.HEAT
  },
  {
    id: 7, name: 'Meterpoint parameters - flow, energy, temperature cross check',
    rule_type: VeeRuleTypeEnum.VALIDATION,
     description: 'Flow>=0 and Energy=0 and Tin-Tout>X, Default X=3',
     recommendation: 'Meterpoint seems to working not properly. Please check all the measurements and inspect the meter on-site.',
     severity: VeeRuleSeverityEnum.WARNING, priority: 1,
     switchable: true, enabled: true, media_type: UtilityTypeEnum.HEAT
  },
  {
    id: 8, name: 'Meterpoint parameters - flow, energy, temperature cross check',
    rule_type: VeeRuleTypeEnum.VALIDATION,
     description: 'Flow>=0 and Energy=0 and Tin-Tout>X, Default X=3',
     recommendation: 'Meterpoint seems to working not properly. Please check all the measurements and inspect the meter on-site.',
     severity: VeeRuleSeverityEnum.WARNING, priority: 1,
     switchable: true, enabled: true, media_type: UtilityTypeEnum.HEAT
  },
  {
    id: 9, name: 'Meterpoint parameters - energy, temperature cross check',
    rule_type: VeeRuleTypeEnum.VALIDATION,
     description: 'Tin-Tout<0',
     recommendation: 'Meterpoint seems to working not properly. Please check all the measurements and inspect the meter on-site.',
     severity: VeeRuleSeverityEnum.WARNING, priority: 1,
     switchable: true, enabled: true, media_type: UtilityTypeEnum.HEAT
  },
  {
    id: 10, name: 'Meterpoint parameters - temperature IN-OUT difference',
    rule_type: VeeRuleTypeEnum.VALIDATION,
     description: 'Flow>=0 and Energy=0 and Tin-Tout>X, Default X=3',
     recommendation: 'Meterpoint seems to working not properly. Please check all the measurements and inspect the meter on-site.',
     severity: VeeRuleSeverityEnum.WARNING, priority: 1,
     switchable: true, enabled: true, media_type: UtilityTypeEnum.HEAT
  },
  {
    id: 11, name: 'Meterpoint error codes',
    rule_type: VeeRuleTypeEnum.VALIDATION,
     description: 'Verification of error codes from meterpoint',
     recommendation: 'Meterpoint seems to working not properly. Please check all the measurements and inspect the meter on-site.',
     severity: VeeRuleSeverityEnum.WARNING, priority: 1,
     switchable: true, enabled: false, media_type: UtilityTypeEnum.HEAT
  },
  {
    id: 12, name: 'Network balancing',
    rule_type: VeeRuleTypeEnum.VALIDATION,
     description: 'Network balancing for whole network and particular nodes accordingly to node type: BALANCING or METER->SUBMETERS',
     recommendation: 'Meterpoint seems to working not properly. Please check all the measurements and inspect the meter on-site.',
     severity: VeeRuleSeverityEnum.WARNING, priority: 1,
     switchable: true, enabled: false, media_type: UtilityTypeEnum.HEAT
  },
  {
    id: 13, name: 'Custom validation rule #1',
    rule_type: VeeRuleTypeEnum.VALIDATION,
     description: 'Custom validation rule #1',
     recommendation: 'Reason and recommendation for custom validation rule #1',
     severity: VeeRuleSeverityEnum.WARNING, priority: 1,
     switchable: true, enabled: false, media_type: UtilityTypeEnum.HEAT
  },
  {
    id: 14, name: 'Custom validation rule #2',
    rule_type: VeeRuleTypeEnum.VALIDATION,
     description: 'Custom validation rule #2',
     recommendation: 'Reason and recommendation for custom validation rule #2',
     severity: VeeRuleSeverityEnum.WARNING, priority: 1,
     switchable: true, enabled: false, media_type: UtilityTypeEnum.HEAT
  },
  {
    id: 15, name: 'Custom validation rule #3',
    rule_type: VeeRuleTypeEnum.VALIDATION,
     description: 'Custom validation rule #3',
     recommendation: 'Reason and recommendation for custom validation rule #3',
     severity: VeeRuleSeverityEnum.WARNING, priority: 1,
     switchable: true, enabled: false, media_type: UtilityTypeEnum.HEAT
  },
];

export const veeRulesEstimationMock: IVeeRule[] = [
  {
    id: 1, name: 'Data completness check',
    rule_type: VeeRuleTypeEnum.ESTIMATION,
    description: 'Data completness check',
    recommendation: 'Unable to calculate the consumption. Please check the measurements for such meterpoint or provide manually',
    severity: VeeRuleSeverityEnum.INFO, priority: 1,
    switchable: false, enabled: true, media_type: UtilityTypeEnum.HEAT
  },
  {
    id: 2, name: 'Data qualification for automated measurement calculation and estimation',
    rule_type: VeeRuleTypeEnum.ESTIMATION,
    description: 'Data qualification for automated measurement calculation and estimation',
    recommendation: 'Unable to calculate the consumption. Please check the measurements for such meterpoint or provide manually',
    severity: VeeRuleSeverityEnum.WARNING, priority: 1,
    switchable: false, enabled: true, media_type: UtilityTypeEnum.HEAT
  },
  {
    id: 3, name: 'Estimation of measurements - linear method',
    rule_type: VeeRuleTypeEnum.ESTIMATION,
    description: 'Estimation of measurements - linear method',
    recommendation: 'Unable to calculate the consumption. Please check the measurements for such meterpoint or provide manually',
    severity: VeeRuleSeverityEnum.WARNING, priority: 1,
    switchable: true, enabled: true, media_type: UtilityTypeEnum.HEAT
  },
  {
    id: 4, name: 'Estimation of measurements - analogous period method',
    rule_type: VeeRuleTypeEnum.ESTIMATION,
     description: 'Estimation of measurements - analogous period method',
     recommendation: 'Unable to calculate the consumption. Please check the measurements for such meterpoint or provide manually',
     severity: VeeRuleSeverityEnum.ERROR, priority: 1,
     switchable: true, enabled: true, media_type: UtilityTypeEnum.HEAT
  },
  {
    id: 5, name: 'stimation of measurements - mean method',
    rule_type: VeeRuleTypeEnum.ESTIMATION,
     description: 'Estimation of measurements - mean method',
     recommendation: 'Unable to calculate the consumption. Please check the measurements for such meterpoint or provide manually',
     severity: VeeRuleSeverityEnum.WARNING, priority: 1,
     switchable: true, enabled: true, media_type: UtilityTypeEnum.HEAT
  },
  {
    id: 6, name: 'Calculation of consumption using action codes assigned to reading or status of the meter',
    rule_type: VeeRuleTypeEnum.ESTIMATION,
     description: 'Calculation of consumption using action codes assigned to reading or status of the meter : NORMAL, CONTROL, DISCONNECTION , INSTALLATION, FAILURE , INACCESIBLE, BAD',
     recommendation: 'Unable to calculate the consumption. Please check the measurements for such meterpoint or provide manually',
     severity: VeeRuleSeverityEnum.WARNING, priority: 1,
     switchable: true, enabled: true, media_type: UtilityTypeEnum.HEAT
  },
  {
    id: 7, name: 'Calculation of consumption using meter overrun detection',
    rule_type: VeeRuleTypeEnum.ESTIMATION,
     description: 'Calculation of consumption using meter overrun detection',
     recommendation: 'Unable to calculate the consumption. Please check the measurements for such meterpoint or provide manually',
     severity: VeeRuleSeverityEnum.WARNING, priority: 1,
     switchable: true, enabled: true, media_type: UtilityTypeEnum.HEAT
  },
  {
    id: 8, name: 'Estimation using network balancing',
    rule_type: VeeRuleTypeEnum.ESTIMATION,
     description: 'Estimation using network balancing',
     recommendation: 'Unable to calculate the consumption. Please check the measurements for such meterpoint or provide manually',
     severity: VeeRuleSeverityEnum.WARNING, priority: 1,
     switchable: true, enabled: true, media_type: UtilityTypeEnum.HEAT
  },
  {
    id: 9, name: 'Custom estimation rule #1',
    rule_type: VeeRuleTypeEnum.ESTIMATION,
     description: 'Custom estimation rule #1',
     recommendation: 'Unable to calculate the consumption. Please check the measurements for such meterpoint or provide manually',
     severity: VeeRuleSeverityEnum.WARNING, priority: 1,
     switchable: true, enabled: true, media_type: UtilityTypeEnum.HEAT
  },
  {
    id: 10, name: 'Custom estimation rule #2',
    rule_type: VeeRuleTypeEnum.ESTIMATION,
     description: 'Custom estimation rule #2',
     recommendation: 'Unable to calculate the consumption. Please check the measurements for such meterpoint or provide manually',
     severity: VeeRuleSeverityEnum.WARNING, priority: 1,
     switchable: true, enabled: true, media_type: UtilityTypeEnum.HEAT
  },
  {
    id: 11, name: 'Custom estimation rule #3',
    rule_type: VeeRuleTypeEnum.ESTIMATION,
     description: 'Custom estimation rule #3',
     recommendation: 'Unable to calculate the consumption. Please check the measurements for such meterpoint or provide manually',
     severity: VeeRuleSeverityEnum.WARNING, priority: 1,
     switchable: true, enabled: true, media_type: UtilityTypeEnum.HEAT
  },
];
