<mat-form-field [formGroup]="fg" class="form-group">
  <mat-select formControlName="disableSelector" placeholder="{{assetLabels.disable}}">
    <mat-option value="customer">{{assetLabels.customer}}</mat-option>
    <mat-option value="meterpoint">{{assetLabels.meterpoint}}</mat-option>
    <mat-option value="both">{{assetLabels.both}}</mat-option>
  </mat-select>
</mat-form-field>

<div class="form-btn">
  <button type="button" mat-button color="primary" class="btn" (click)="dialogRef.close()">Cancel</button>
  <button type="button" *ngIf="this.fg.get('disableSelector').value !== null" mat-raised-button color="accent" class="btn" (click)="onDisable()">{{actionButton}}</button>
</div>
