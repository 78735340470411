import { Component, Inject, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MeasurementData, IMeasurementData, MeterPoint } from '@shared/models';
import { MeterPointDataStoreService } from '@shared/services/dataStore/meterPointDataStore.service';
import { CommonAppDataService } from '@shared/services/commonAppData.service';

import * as moment from 'moment';
import { statusFlagStringName, StatusFlagEnum, ValidationResultEnum } from '@shared/types/modelTypes/meterDataTypes';
import { measurementOriginNamesMap, MeasurementOriginEnum, VeeStatusEnum } from '@shared/types/modelTypes/measurementType';
import { Subscription } from 'rxjs/Subscription';
import { ApplicationUnitsDataStoreService } from '@shared/services/dataStore/applicationUnitsDataStore.service';
import { ApplicationUnits } from '@shared/models/applicationUnits.model';
import { Observable } from 'rxjs/Observable';
import { MeterValidator } from '@shared/validators/async-meter-validator';
import { IParameters, ArrayedParameters } from '@shared/types';
import { MediaTypeParameter } from '@shared/models/appModels/MediaTypeParameter.mode';
import { environment } from '@env/environment';
import { UploadLocalisation as loc } from '../../../../upload.localisation';
import { MeasurementDataModel } from '@shared/models/appModels/measurementDataModel.model';
import { UnitHelper } from '../../service/unitsHelper.service';
import { InteractiveTutorialService } from '@shared/services/interactiveTutorialService.service';
import { MeasurementsUploadInterativeTutorialService } from '../../../measurementsUploadInteractiveTutorialService.service';

@Component({
  selector: 'sv-readings-preview-add-dialog',
  templateUrl: 'readings-preview-add-dialog.component.html',
  styleUrls: ['readings-preview-add-dialog.component.sass'],
})

export class ReadingsPreviewAddDialogComponent implements OnInit, OnDestroy, AfterViewInit {

  readonly addMdIcon: string = 'add_box';
  readonly removeMdIcon: string = 'indeterminate_check_box';
  readonly selectStatusFlag = statusFlagStringName;
  readonly selectedMeasurementTypeNames = measurementOriginNamesMap;

  public name: string;
  public addReadingsForm: FormGroup;
  private element: MeasurementData;
  private mediaType$: Subscription;
  public idPosition: number;
  measurementsDefinitions: MeasurementDataModel[] = [];

  public units: ApplicationUnits;
  public params: MediaTypeParameter[];
  readonly labels = loc[environment.language].texts.measurement;
  showTutFunc;

  constructor(@Inject(MAT_DIALOG_DATA) private data: { meterData: MeasurementData, idPos: number },
    public dialogRef: MatDialogRef<ReadingsPreviewAddDialogComponent>,
    private meterDataStoreService: MeterPointDataStoreService,
    private cs: CommonAppDataService,
    private unitService: ApplicationUnitsDataStoreService,
    private router: Router,
    public unitHelper: UnitHelper,
    private interactiveTutorialService: InteractiveTutorialService,
    private measurementUploadInteractiveTutorialService: MeasurementsUploadInterativeTutorialService) {

    this.idPosition = data.idPos;
    this.addReadingsForm = new FormGroup({
      serialMeter: new FormControl('', [Validators.required], [MeterValidator.checkMeterPointExistance(this.meterDataStoreService)]),
      values: new FormArray([]),
      date: new FormControl(moment.utc(), Validators.required),
      selectedStatusFlagControl: new FormControl(this.selectStatusFlag[StatusFlagEnum.NORMAL].id, Validators.required),
      optionalValues: new FormArray([])
    });
  }

  ngOnInit() {
    this.unitService.getMeasurementDefinitions().subscribe(measDefs => {
      this.measurementsDefinitions = measDefs;
    });

    this.unitService.getApplicationUnits(this.cs.getCurrentMediaType())
      .concatMap(units => {
        this.units = units;
        return this.unitService.getMeasurementNonCumulatives();
      })
      .subscribe(params => {
        this.params = params;
        if (typeof (this.data.meterData) !== 'undefined') {
          this.element = this.data.meterData;
          /* Control assigmnet */
          this.date.setValue(moment.utc(this.element.timestamp));
          this.selectedStatusFlagControl.setValue(this.element.statusFlag);
          this.serialMeter.patchValue(this.element.meterPointRef.serialNumber);

          this.element.convertParamsToArray(this.element.measurementValues)
            .filter(mesVal => {
              const idx = this.units.units.findIndex(unit => unit.name === mesVal.name);
              return (idx !== -1);
            })
            .forEach((mesValues: ArrayedParameters) => {
              const matchedDescriptor = this.units.units.find(mp => mp.name === mesValues.name);
              this.values.push(this.element.generateValueControl(mesValues.name, mesValues.value, matchedDescriptor));
              if (typeof (matchedDescriptor) !== 'undefined') {
                (this.values.controls[this.values.length - 1] as FormGroup).get('name').disable();
              }
            });

          this.element.convertParamsToArray(this.element.measurementValues)
            .filter(mesVal => {
              const idx = this.units.units.findIndex(unit => unit.name === mesVal.name);
              return (idx === -1);
            })
            .forEach(optValue => {
              const matchedDescriptor = this.params.find(mp => mp.name === optValue.name);
              this.optionalValues.push(this.element.generateValueControl(optValue.name, optValue.value, matchedDescriptor));
              if (typeof (matchedDescriptor) !== 'undefined') {
                (this.optionalValues.controls[this.optionalValues.length - 1] as FormGroup).get('name').disable();
              }
            });
        } else { /* No input measurement: dialog opened in add-new context */
          this.units.units.forEach((unit, idx) => {
            this.values.push(new FormGroup({
              'name': new FormControl(unit.description),
              'value': new FormControl(''),
              'key': new FormControl(unit.name)
            }));
          });
          /* Add single demo parameter */
          this.addOptionalValueField();
        }
      });

  }

  get serialMeter(): AbstractControl {
    return this.addReadingsForm.get('serialMeter');
  }
  get values(): FormArray {
    return this.addReadingsForm.get('values') as FormArray;
  }

  get date(): AbstractControl {
    return this.addReadingsForm.get('date');
  }

  get selectedStatusFlagControl(): AbstractControl {
    return this.addReadingsForm.get('selectedStatusFlagControl');
  }

  get optionalValues(): FormArray {
    return this.addReadingsForm.get('optionalValues') as FormArray;
  }

  getValuesControls(): AbstractControl[] {
    return this.values.controls;
  }

  addOptionalValueField() {
    this.optionalValues.push(MeasurementData.generateEmptyParamControl());
  }

  deleteOptionalValueField(index: number): void {
    this.optionalValues.removeAt(index);
  }

  goClientData(): void {
    this.router.navigate(['upload/client-data']);
    this.dialogRef.close();
  }

  onFormSubmit(event): void {
    this.meterDataStoreService.getMeterPointBySerialMatch(this.serialMeter.value)
      .concatMap<MeterPoint, MeasurementData>((mp) => {

        const iMes: IMeasurementData = {
          idMeasurementData: '',
          idMeterPoint: mp.idMeterPoint,
          idPosition: this.idPosition,
          statusFlag: StatusFlagEnum.NORMAL,
          measurementOrigin: MeasurementOriginEnum.MANUALLY_ADDED,
          measurementValues: { ...this.getMeasurementValues(), ...this.getOptionalValues() },
          timestamp: +this.date.value || null,
          validationResult: ValidationResultEnum.VALID,
          userAcceptance: true,
          estimation: VeeStatusEnum.NOT_REQUIRED,
          validation: VeeStatusEnum.NOT_REQUIRED,
          recordValid: true,
        };
        let mes: MeasurementData;
        try {
          mes = new MeasurementData(iMes);
        } catch {
          throw new Error('Unable to generate measurmeent data');
        }
        return Observable.of(mes);
      })
      .subscribe((mesData: MeasurementData) => {
        this.dialogRef.close(mesData);
      });
  }

  private getMeasurementValues(): IParameters {
    return MeasurementData.parseParametersFromForm(this.values,
      { keyControlName: 'key', nameControlName: 'name', valueControlName: 'value' },
      { dropEmpty: true });
  }

  private getOptionalValues(): IParameters {
    this.optionalValues.controls.forEach((ctrlG: FormGroup) => {
      if (ctrlG.get('key').value !== null) { /* This is already existing parameter */
        const correcpondingDescription = this.params.find(mpp => mpp.name === ctrlG.get('key').value);
        if (typeof (correcpondingDescription) === 'undefined') { /* Parameter has no correspoding name/key in defined values */
          ctrlG.get('key').setValue(ctrlG.get('name').value);
        } else {
          ctrlG.get('key').setValue((correcpondingDescription).name);
        }
      } else { /* This is new parameter */
        /* Name reffers to already existing */
        const correcpondingDescription = this.params.find(mpp => mpp.description === ctrlG.get('name').value);
        if (typeof (correcpondingDescription) === 'undefined') { /* Such parameter does not exist */
          ctrlG.get('key').setValue(ctrlG.get('name').value);
        } else {
          ctrlG.get('key').setValue(correcpondingDescription.name);
        }
      }
    });
    return MeterPoint.parseParametersFromForm(this.optionalValues);
  }

  startInteractiveTutorial(){
    const steps = this.measurementUploadInteractiveTutorialService.getMeasurementUploadAddMeasurementModalInteractiveTutorialSteps(this.optionalValues.controls.length);
    this.interactiveTutorialService.startInteractiveTutorial(steps);
  }

  ngAfterViewInit(): void {
    this.showTutFunc = setTimeout(() => this.startInteractiveTutorial(), 1500);
  }

  ngOnDestroy(): void {
    try {
      this.mediaType$.unsubscribe();
    } catch (e) {

    } finally {
      clearTimeout(this.showTutFunc);
    }
  }

}

