<div ngFileDrop #fileDrop class="drop-container" (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput"
	[ngClass]="{ 'is-drop-over': dragOver }">

	<div class="import-overlay">
	</div>

	<h1>{{header}}</h1>

	<div class="upload-info-container">
		<div id="svUpload" class="text-import column text-highlight">
			{{uploadPrompt.part1}}
			<label class="btn-upload">
				{{uploadPrompt.part2}}
				<input #fileSelect ngFileSelect type="file" (uploadOutput)="onUploadOutput($event)"
					[uploadInput]="uploadInput">
			</label>
			{{uploadPrompt.part3}}
		</div>
		<div class="column">
			<div class="card">
				<div class="card-body overflow-hide">
					<h1>{{uploadProg}}</h1>
						<div *ngIf="displayableUpload.length > 0" class="card progress-card">
							<ng-container>
								<div class="upload-progress-container"
									*ngFor="let f of displayableUpload; let i = index;">
									<div class="elements-space-between">
										<span class="truncated-name" [matTooltip]="f.name" matTooltipClass="tooltip-not-truncated"
											matTooltipPosition="left">{{f.name}}</span>
										<span>{{ '  '+ humanizeBytes(f.size)}}</span>
									</div>
									<mat-progress-bar mode="determinate" [value]="f.progress?.data?.percentage">
									</mat-progress-bar>
									<div class="elements-space-between">
										<span>{{f.progress?.data?.percentage + '%' + ' done'}}</span>
										<span>{{ f.progress?.data?.speedHuman }}</span>
									</div>
								</div>
							</ng-container>
						</div>

					<div *ngIf="displayResults()" class="card alert" [ngClass]="getResultClass()">
						<span *ngIf="uploadResults.success">{{success}}</span>

						<span *ngIf="uploadResults.error">
							{{error}}: {{uploadResults?.errorCause}}

							<label class="link btn-upload">
								<a (click)="reuploadFileSelect()">{{reload}}</a>
							</label>
						</span>
					</div>
					<div class="button-on-bottom-container">
					<div class="tooltip-div" [matTooltip]="chooseFileToUpload" [matTooltipDisabled]="!btnDisabled()">
						<!-- div added because hints do not work on the disabled button -->
						<button id="svUploadBtn" type="button" (click)="startUpload()" [disabled]="btnDisabled()" mat-raised-button
							color="accent" class="btn">
							{{upload}}
						</button>
					</div>
					</div>

				</div>
			</div>
		</div>
	</div>

	<sv-asset-data-files-preview></sv-asset-data-files-preview>

	<sv-asset-data-preview></sv-asset-data-preview>
</div>