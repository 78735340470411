<!-- TODO: select correct icon (not always [0]) -->
<div class="timeline-point">
	<i class="material-icons notranslate">{{ entryArray[0].icoRef }}</i>
</div>

<div class="timeline-box">
	<div class="card-holder">
		<div class="card">
			<div class="card-header">
				<h6>
					<span *ngFor="let entry of entryArray">{{ entry.title }}</span>
				</h6>
				<div class="header-element">{{ getSmallestDueDate() | svDate }}</div>
			</div>

			<div class="card-body">
				<div *ngIf="isToday()" class="today-card">
					<sv-progress [labelBox]="true" 
						i18n-label="@@timeline/card/tasks completed label"
						label="Tasks completed"
						[completeness]="entryArray[getTodayIndex()].completeness"
						[unit]="entryArray[getTodayIndex()].unit">
					</sv-progress>
				</div>
				<div *ngIf="entryArray[0].type !== EventType.ANALYSIS">
					<div *ngIf="!entryArray[0].promptText && !isToday()" class="clear">
						<span *ngIf="entryArray[0].dataSuccess" class="data-text-block">
							<b>{{ getDataSuccess() }}</b> {{ entryArray[0].dataSuccess}}
						</span>

						<span *ngIf="entryArray[0].dataError && entryArray[0].type !== EventType.EXPORT"
							class="data-text-block">
							<b>{{ getDataError() }}</b> {{entryArray[0].dataError}}
						</span>
					</div>
				</div>
				<div *ngIf="entryArray[0].type === EventType.ANALYSIS">
					<div *ngIf="!entryArray[0].promptText && !isToday()" class="clear">
						<span *ngIf="entryArray[0].dataSuccess" class="data-text-block">
							<b>{{ getDataSuccess() }}</b> {{ entryArray[0].dataSuccess}}
						</span>

						<span *ngIf="entryArray[0].dataError" class="data-text-block">
							<b>{{ getDataError() }}</b> {{entryArray[0].dataError}}
						</span>

						<span *ngIf="entryArray[0].dataError2" class="data-text-block">
							<b>{{ entryArray[0].dataErrorValue2}}</b> {{ entryArray[0].dataError2}}
						</span>
					</div>
				</div>


				<div *ngIf="!entryArray[0].promptText && isToday()" class="clear">
					<span *ngIf="entryArray[0].dataSuccess" class="data-text-block">
						<b>{{ entryArray[getTodayIndex()]?.daysRemaining }}</b> {{ entryArray[0].dataSuccess }}
					</span>

					<span *ngIf="entryArray[0].dataError" class="data-text-block">
						<b>{{ entryArray[getTodayIndex()].delayedTasks }}</b> {{ entryArray[0].dataError }}
					</span>
				</div>

				<div *ngIf="entryArray[0].promptText" class="prompt-text">
					<i class="material-icons md-24 notranslate">info_outline</i>
					{{ entryArray[0].promptText }}
				</div>
			</div>

			<div *ngIf="entryArray[0].action" class="card-footer">
				<button type="button" (click)="actionButtonClicked(entryArray[0])" mat-raised-button class="btn"
					[disabled]="getButonDisabled(entryArray[0])">
					{{ entryArray[0].actionText }}
				</button>
			</div>
		</div>
	</div>
</div>