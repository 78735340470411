import { Injectable } from '@angular/core';
import { TimeGroup, ITimeGroup} from '@shared/models/appModels/timeGroup.model';
import { CommonAppDataService } from '@shared/services/commonAppData.service';
import { EndpointsEnum } from '@shared/types';
import { HttpClient } from '@angular/common/http';
import { TimeGroupHttpParamG } from '@shared/types/http/timeGroupHttpConfig';
import { RestTypeMapper } from '@shared/models/RestSupport';
import { Observable } from 'rxjs/Observable';
import { RequestService } from './requestService.class';

enum RequestEnum {
  'GET',
  'POST',
  'PUT',
  'DELETE'
}

@Injectable()
export class TimeGroupService extends RequestService {

  static timeGroupMapper: RestTypeMapper<ITimeGroup, TimeGroup> = new RestTypeMapper<ITimeGroup, TimeGroup>();

  constructor(http: HttpClient, cs: CommonAppDataService) {
    super(http, cs, EndpointsEnum.TIME_GROUPS);
  }

  requestTimeGroups(params: TimeGroupHttpParamG, thisApiID: string, request: RequestEnum): Observable<any> {
    if (this.MOCK_SERVICE) {
      throw new Error('Missing mock implementation');
    } else {
      if (request == RequestEnum.GET) {
        return this.getRequest(params, TimeGroupService.timeGroupMapper, thisApiID, TimeGroup);
      } else if (request == RequestEnum.POST) {
        const body = TimeGroupService.timeGroupMapper.mapObjectToInterface(params.body, true);
        return this.postRequest(body, params, TimeGroupService.timeGroupMapper, thisApiID, TimeGroup);
      } else if (request == RequestEnum.PUT) {
        const body = TimeGroupService.timeGroupMapper.mapObjectToInterface(params.body, true);
        return this.putRequest(body, params, TimeGroupService.timeGroupMapper, thisApiID, TimeGroup);
      } else if (request == RequestEnum.DELETE) {
        return this.deleteRequest(params, TimeGroupService.timeGroupMapper, thisApiID, TimeGroup);
      }
    }
  }

  getTimeGroupsList(params: TimeGroupHttpParamG): Observable<TimeGroup[]> {
    return this.requestTimeGroups(params, 'getTimeGroupsList', RequestEnum.GET);
  }

  postTimeGroups(params: TimeGroupHttpParamG): Observable<TimeGroup> {
    return this.requestTimeGroups(params, 'postTimeGroups', RequestEnum.POST);
  }

  getTimeGroupsById(params: TimeGroupHttpParamG): Observable<TimeGroup> {
    return this.requestTimeGroups(params, 'getTimeGroupsById', RequestEnum.GET);
  }

  putTimeGroupsById(params: TimeGroupHttpParamG): Observable<TimeGroup> {
    return this.requestTimeGroups(params, 'putTimeGroupsById', RequestEnum.PUT);
  }

  deleteTimeGroupsById(params: TimeGroupHttpParamG): Observable<TimeGroup> {
    return this.requestTimeGroups(params, 'deleteTimeGroupsById', RequestEnum.DELETE);
  }
}
