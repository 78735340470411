import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MeterPoint } from '@shared/models';
import { PositionDataStoreService } from '@shared/services/dataStore/positionDataStore.service';
import { MatStepper } from '@angular/material/stepper';
import { ObjectWrapper } from 'app/components/common-component/input-with-autocomplete-and-chips/input-with-autoComp-and-chips.component.component';
import { Subscription } from 'rxjs';
import { InteractiveTutorialService, NextComponentName } from '@shared/services/interactiveTutorialService.service';
import { ExportInteractiveTutorialService } from '../export-interactive-tutorial.service';
import { debounceTime } from 'rxjs/operators';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'sv-custom-csv-data',
    templateUrl: 'custom-csv-data.component.html',
    styleUrls: ['custom-csv-data.component.sass']
})

export class CustomCsvDataComponent implements OnInit, OnDestroy {

    meters: MeterPoint[] = [];
    fetchingData: boolean = false;
    allMeterPointsChecked: boolean;
    metersPlaceholder: string = $localize`:@@export/custom billings modal/add meter points:Add meter points...`;
    wrongMeterPoint: string = $localize`:@@export/custom billings modal/wrong meter error:There is no such meter point or it is already in the pick list.`;
    meterPointsRequired: string = $localize`:@@export/meterPoints required:MeterPoints are required.`;
    disableMeterPointsInput: boolean = false;

    @Input() idPosition: number;
    @Input() stepper: MatStepper;
    @Output() selectedMeterPointsEventEmitter = new EventEmitter<MeterPoint[]>();
    @Output() firstStepCompleted = new EventEmitter<boolean>();
    notValidValue: boolean = false;
    serialNumbers: ObjectWrapper[] = [];
    initComponent: boolean = false;
    canStartInteractiveTut$: Subscription;


    constructor(private positionDataStoreService: PositionDataStoreService,
      private cdr: ChangeDetectorRef,
      private interactiveTutorialServ: InteractiveTutorialService,
      private exportInteractiveTutorialService: ExportInteractiveTutorialService) {}

    ngOnInit() {
         this.positionDataStoreService.getPositionMeterPoints(this.idPosition, 8).subscribe(meters => {
            this.serialNumbers = this.generateObjectWrapper(meters);
            this.initComponent = true;
            this.cdr.markForCheck();
            this.cdr.detectChanges();
        });
        this.canStartInteractiveTut$ = this.interactiveTutorialServ.canGoToNextStepSubject.pipe(debounceTime(100)).subscribe(val => {
          if (val === NextComponentName.EXPORT_MEASUREMENTS_FIRST_STEP) {
            this.startInteractiveTutorial();
          }
        });
    }

    startInteractiveTutorial(){
      const steps = this.exportInteractiveTutorialService.getExportCustomCsvInteractiveTutorialSteps();
      this.interactiveTutorialServ.startInteractiveTutorial(steps);
    }

    ngOnDestroy() {
      if (this.canStartInteractiveTut$) {
        this.canStartInteractiveTut$.unsubscribe();
      }
    }

    generateObjectWrapper(meters: MeterPoint[]): ObjectWrapper[] {
      // Generate special object wrapper for child component
      let objWrapper: ObjectWrapper[] = [];
      return objWrapper = meters.map(value => {
      return { valueForDisplay: value.serialNumber, otherValues: value};
      });
    }

    emitMeterPoints() {
      // Emit picked meter points to parent component
      this.selectedMeterPointsEventEmitter.emit(this.meters);
    }

    onNextStep() {
        this.firstStepCompleted.emit(true);
        this.emitMeterPoints();
        this.interactiveTutorialServ.canGoToNextStepSubject.next( NextComponentName.EXPORT_MEASUREMENTS_SECOND_STEP);
        this.stepper.next();
    }

    onTypingEvent(enteredVal) {
      // Listen to typing event from child component
      if (enteredVal !== '' && enteredVal !== null) {
      this.fetchingData = true;
      // Based on typed value make api call to get data for autocomplete
      this.positionDataStoreService.getPositionMeterPointsByName(this.idPosition, enteredVal).subscribe( data => {
        this.fetchingData = false;
        this.serialNumbers = this.generateObjectWrapper(data);
        // trigger the change detector
        this.cdr.markForCheck();
        this.cdr.detectChanges();
      });
      } else {
        if (enteredVal !== null) {
          this.serialNumbers = [];
        }
      }
    }

      onSelectAllMeters(event) {
          this.allMeterPointsChecked = event.checked;
          if (event.checked) {
            this.meters = [];
            this.metersPlaceholder = $localize`:@@export/custom billings modal/selected all meter points:Selected all meter points.`;
            this.emitMeterPoints();
            this.disableMeterPointsInput = true;
          } else {
            this.metersPlaceholder = $localize`:@@export/custom billings modal/add meter points:Add meter points...`;
            this.emitMeterPoints();
            this.disableMeterPointsInput = false;
          }
      }

      selectedDataEvent(data: any) {
        // Listen to selected data event from child component
        this.meters = data;
        this.emitMeterPoints();
        if (data.length !== 0 || this.allMeterPointsChecked) {
          this.firstStepCompleted.emit(true);
        } else {
          this.firstStepCompleted.emit(false);
        }
      }

}
