// Angular modules
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MeasurementsUploadComponent } from '../upload/measurements/measurements-upload.component';
import { HomeComponent } from '../home/home.component';
import { NotFoundComponent } from '../not-found/not-found.component';
import { AssetDataUploadComponent } from '../upload/asset-data/asset-data.component';
import { ScheduleComponent } from '../schedule/schedule.component';
import { DataValidationPageComponent } from '../data-validation/page/data-validation-page.component';
import { DataAnalysisPageComponent } from '../data-analysis/page/data-analysis-page.component';
import { DataExplorationPageComponent } from '../data-exploration/page/data-exploration-page.component';
import { SettingsRulesComponent } from '../settings/settings-rules/settings-rules.component';
import { SettingsAlgorithmsComponent } from '../settings/settings-algorithms/settings-algorithms.component';
import { SettingsApplicationComponent } from '../settings/settings-application/settings-application.component';
import { ExportComponent } from '../export/export.component';
import { TemperaturesComponent } from '../upload/temperatures/temperatures.component';
import { SettingsDataModelComponent } from '../settings/settings-dataModel/settings-dataModel.component';



const routes: Routes = [
  // Sidebar direc links
  {path: 'home',    component: HomeComponent},
    {path: 'upload/readings',  component: MeasurementsUploadComponent },
    {path: 'upload/client-data',  component: AssetDataUploadComponent },
    {path: 'upload/temperatures',  component: TemperaturesComponent },
  {path: 'schedule',  component: ScheduleComponent },
  {path: 'data-validation',  component: DataValidationPageComponent },
  {path: 'data-analysis',  component: DataAnalysisPageComponent },
  {path: 'data-exploration',  component: DataExplorationPageComponent },
    {path: 'config/rules',  component: SettingsRulesComponent },
    {path: 'config/algo',  component: SettingsAlgorithmsComponent },
    {path: 'config/settings',  component: SettingsApplicationComponent },
    {path: 'config/dataModel',  component: SettingsDataModelComponent },
  {path: 'export',  component: ExportComponent },

  // Fallbacks
  {path: '',        redirectTo: '/home', pathMatch: 'full'},
  {path: '**',      component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
