import { Component } from "@angular/core";
import { Schedule } from "@shared/models";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "sv-home",
  templateUrl: "home.component.html",
  styleUrls: ["home.component.sass"],
})
export class HomeComponent {
  schedule: Schedule;
}
