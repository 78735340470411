import { RestEnumMpapper } from '@shared/models/RestSupport';
import { statusFlagStringName, MeterDataOriginEnum, getValidationResult } from '@shared/types/modelTypes/meterDataTypes';
import { INameMapper, IParameters } from '@shared/types/applicationTypes';
import { StatusFlagEnum, ValidationResultEnum } from '@shared/types/modelTypes/meterDataTypes';
import { IGetInterfaceType } from '@shared/types/applicationTypes';
import { BaseOptionalParamsMapper } from '@shared/models/baseOptionalParams.mode';
import { MeasurementData } from '@shared/models';

export class IMeterData {
  idMeterData: string; /* ID of praticular measurement */
  idMeterPoint: number; /* Unique ID of meter */
  idPosition: number;
  validationResult: ValidationResultEnum | string; /* Indication of record validation status */
  statusFlag: StatusFlagEnum | string; /* Flag of measurement, represent mesurement status */
  errorCode?: string; /* Error code meter returned */
  timestamp: number; /* Timestamp of measurement */
  timeStamp?: number;
  meterDataOrigin?: MeterDataOriginEnum | string;
  usageValues: IParameters; /* Array of usages measured by meter */
}

export class MeterData extends BaseOptionalParamsMapper implements IMeterData, IGetInterfaceType<IMeterData, MeterData>  {

  /* Class mappers */
  static StatusFlagMapper: RestEnumMpapper<typeof StatusFlagEnum> = new RestEnumMpapper<typeof StatusFlagEnum>();
  static OriginMapper: RestEnumMpapper<typeof MeterDataOriginEnum> = new RestEnumMpapper<typeof MeterDataOriginEnum>();
  static ValidationResultMapper: RestEnumMpapper<typeof ValidationResultEnum> = new RestEnumMpapper<typeof ValidationResultEnum>();

  idMeterData: string;
  idMeterPoint: number;
  idPosition: number;
  validationResult: ValidationResultEnum;
  statusFlag: StatusFlagEnum;
  errorCode?: string;
  timestamp: number;
  meterDataOrigin: MeterDataOriginEnum;
  usageValues: IParameters;

  /* FIXME: those fields are here to feed validation data screens only */
  meterPointId?: number;
  meterPointSerial?: string;
  measurementDataVals?: number[];
  measurmeentDataRef?: MeasurementData;

  constructor(iMeterData: IMeterData) {
    super();
    if (!iMeterData) { throw new TypeError('MeterData initialization: params is undefined !'); }

    this.idMeterData = (typeof(iMeterData.idMeterData) !== 'undefined') ? iMeterData.idMeterData : undefined;
    this.idMeterPoint = (typeof(iMeterData.idMeterPoint) !== 'undefined') ? iMeterData.idMeterPoint : undefined;
    this.idPosition = (typeof(iMeterData.idPosition) !== 'undefined') ? iMeterData.idPosition : undefined;

    this.validationResult = (typeof(iMeterData.validationResult) !== 'undefined') ?
    this.assignValidationResult(iMeterData.validationResult) : ValidationResultEnum.INVALID;
    // MeterData.ValidationResultMapper.getEnumAsNumber(ValidationResultType, iMeterData.validationFlag) : undefined;

    this.statusFlag = (typeof(iMeterData.statusFlag) !== 'undefined') ?
    this.assignStatusFlag(iMeterData.statusFlag) : StatusFlagEnum.ERROR;

    this.errorCode = (typeof(iMeterData.errorCode) !== 'undefined') ? iMeterData.errorCode : undefined;
    this.timestamp = (typeof(iMeterData.timestamp) !== 'undefined') ? iMeterData.timestamp : undefined;
    this.meterDataOrigin = this.assignOrigin(iMeterData.meterDataOrigin);
    this.usageValues = this.getParamsToRestApi(iMeterData.usageValues);
  }

  assignOrigin(eIn: MeterDataOriginEnum | string) {
    try {
      return MeterData.OriginMapper.getEnumAsNumber(MeterDataOriginEnum, eIn);
    } catch (e) {
      console.warn(e);
      return MeterDataOriginEnum.AUTO;
    }
  }

  assignValidationResult(eIn: ValidationResultEnum | string): ValidationResultEnum {
    try {
      return MeterData.ValidationResultMapper.getEnumAsNumber(ValidationResultEnum, eIn);
    } catch (e) {
      console.warn(e);
      return ValidationResultEnum.DEFAULT;
    }
  }

  assignStatusFlag(eIn: StatusFlagEnum | string): StatusFlagEnum {
    try {
      return MeterData.StatusFlagMapper.getEnumAsNumber(StatusFlagEnum, eIn);
    } catch (e) {
      return StatusFlagEnum.ERROR;
    }
  }

  setMeterPointSerial(serialIn: string): void {
    this.meterPointSerial = serialIn;
  }

  getSerial() {
    return this.meterPointSerial;
  }

  getValueUsageValue(key: string): number | undefined {
    try {
      if (this.isParamPresent(key, this.usageValues)) {
        return +(this.getParamByKey(key, this.usageValues)[key]);
      } else {
        return undefined;
      }
    } catch (e) {
      console.warn('Unable to parse to number parameter ', name);
      return undefined;
    }
  }

  getNameFlagStatus(): INameMapper {
    return statusFlagStringName[this.statusFlag];
  }

  getValidationFlagNames(): INameMapper {
    return getValidationResult(this.validationResult);
  }

  getValidationFlagNameString(): string {
    return getValidationResult(this.validationResult).name;
  }

  getAllMeterDataToString() {
    if (this.usageValues !== null && this.usageValues.length > 0) {
      let ret = '';
      Object.keys(this.usageValues).forEach(v => ret += '' + v + ' ' + 'test');
      return ret;
    }
  }

  setRelatedMeasurements(numIn: number[]) {
    this.measurementDataVals = numIn.slice(0);
  }

  getRelatedMesurements(ord: number = 0): number {
    if (this.measurementDataVals) {
      return this.measurementDataVals[ord];
    }
  }


  getInterface(webInterface: boolean = true): IMeterData {
    const emptyValue = (webInterface) ? null : undefined;
    return {
      idMeterData: this.idMeterData,
      idMeterPoint: this.idMeterPoint,
      idPosition: this.idPosition || emptyValue,
      validationResult: MeterData.ValidationResultMapper.getEnumAsString(ValidationResultEnum, this.validationResult),
      statusFlag:  MeterData.StatusFlagMapper.getEnumAsString(StatusFlagEnum, this.statusFlag),
      errorCode: this.errorCode || emptyValue,
      timestamp: this.timestamp,
      usageValues:  this.getParamsToRestApi(this.usageValues, emptyValue)
    };
  }
}
