// Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { CommonComponentsModule } from '../common-component/commonComponents.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatStepperModule } from '@angular/material/stepper';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
// This Module's Components
import { ExportComponent } from './export.component';
import { CommonPipeModule } from '../../utils/commonPipe.module';
import { CustomCsvDataComponent } from './generate-custom-csv-data/custom-csv-data.component';
import { CustomCsvDataNextStepComponent } from './generate-custom-csv-data-next-step/custom-csv-data-next-step.component';
import { CustomCsvDataFinalComponent } from './generate-custom-csv-data-final/custom-csv-data-final.component';
import { CustomBillingFileComponent } from './generate-custom-billing-file/generate-custom-billing-file.component';
import { GenerateCustomBillingFileFinalComponent } from './generate-custom-billing-file-final/generate-custom-billing-file-final.component';

@NgModule({
  imports: [
    CommonModule,
    CommonPipeModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatRippleModule,
    MatSelectModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    CommonComponentsModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatIconModule,
    MatCheckboxModule,
    MatStepperModule,
    MatRadioModule,
    MatBadgeModule,
    MatDatepickerModule,
    MatMomentDateModule
  ],
  declarations: [
    ExportComponent,
    CustomCsvDataComponent,
    CustomCsvDataNextStepComponent,
    CustomCsvDataFinalComponent,
    CustomBillingFileComponent,
    GenerateCustomBillingFileFinalComponent,
  ],
  exports: [
    ExportComponent,
    CustomCsvDataComponent,
    CustomCsvDataNextStepComponent,
    CustomCsvDataFinalComponent,
    CustomBillingFileComponent,
    GenerateCustomBillingFileFinalComponent,
  ]
})
export class ExportModule {}
