import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'sv-custom-billing-file-final',
    templateUrl: 'generate-custom-billing-file-final.component.html',
    styleUrls: ['generate-custom-billing-file-final.component.sass']
})

export class GenerateCustomBillingFileFinalComponent {

    @Input() from: FormControl;
    @Input() to: FormControl;
    @Input() selectedColumns: string[];
    @Input() displayHeadersInCsv: boolean;

    constructor() {}


    onFinalize() {
    }

}
