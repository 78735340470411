import { Injectable } from "@angular/core";
import { InteractiveTutorialService, TutorialButtonLabel } from "@shared/services/interactiveTutorialService.service";

@Injectable({ providedIn: "root" })
export class MeasurementsUploadInterativeTutorialService {
  constructor(private interativeTutServ: InteractiveTutorialService) {}

  getMeasurmeentsUploadMainComponentInteractiveTutorialSteps() {

    const step = this.interativeTutServ.generateStep(
      "#configBtn",
      "bottom-end",
      $localize`:@@interactive tutorial/measurements upload/main component/config btn:Click this button to define custom mapping from your data model to application data model. Start configuration by selecting your custom csv file.`,
      $localize`:@@interactive tutorial/measurements upload/main component/config btn title:Configure data model`,
      [TutorialButtonLabel.NEXT]
    );

    const step1 = this.interativeTutServ.generateStep(
      "#svSelect",
      "bottom-end",
      $localize`:@@interactive tutorial/measurements upload/main component/svSelect:To upload a file, start by selecting a schedule and position.`,
      $localize`:@@interactive tutorial/measurements upload/main component/svSelect title:Select schedule and position`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
    );

    const step2 = this.interativeTutServ.generateStep(
      "#svUpload",
      "bottom-end",
      $localize`:@@interactive tutorial/measurements upload/main component/svUpload:In the next step, select the appropriate csv file`,
      $localize`:@@interactive tutorial/measurements upload/main component/svUpload title:Select file`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
    );

    const step3 = this.interativeTutServ.generateStep(
      "#svUploadBtn",
      "bottom-end",
      $localize`:@@interactive tutorial/measurements upload/main component/svUploadBtn:Finalize your upload by clicking on this button.`,
      $localize`:@@interactive tutorial/measurements upload/main component/svUploadBtn title:Finalize upload`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
    );

    const step4 = this.interativeTutServ.generateStep(
      "#mainTable",
      "top",
      $localize`:@@interactive tutorial/measurements upload/main component/main table:This tables shows schedules with positions which contains data.`,
      $localize`:@@interactive tutorial/measurements upload/main component/main table title:Schedules table`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.COMPLETE]
    );

    const steps: any[] = [step, step1, step2, step3, step4];
    return steps;
  }

  getMeasurementsUploadMapperInteractiveTutorialSteps() {
    const step = this.interativeTutServ.generateStep(
      "#primaryModel",
      "bottom-end",
      $localize`:@@interactive tutorial/measurements upload/mapper/primary model:Expand this tab to see primary parmaters. You have to define mapping for them.`,
      $localize`:@@interactive tutorial/measurements upload/mapper/primary model title:Primary parameters`,
      [TutorialButtonLabel.NEXT]
    );

    const step1 = this.interativeTutServ.generateStep(
      "#optionalParameters",
      "bottom-end",
      $localize`:@@interactive tutorial/measurements upload/mapper/optional model:Expand this tab to see optional parmaters. You dont have to define mapping for them.`,
      $localize`:@@interactive tutorial/measurements upload/mapper/optional model title:Optional parameters`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
    );

    const step2 = this.interativeTutServ.generateStep(
      "#validateBtn",
      "bottom-end",
      $localize`:@@interactive tutorial/measurements upload/mapper/validate btn:Use this button to validate your mappings.`,
      $localize`:@@interactive tutorial/measurements upload/mapper/validate btn title:Validate mappings`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
    );

    const step3 = this.interativeTutServ.generateStep(
      "#mapBtn",
      "bottom-end",
      $localize`:@@interactive tutorial/measurements upload/mapper/map btn:Use this button to save your mappings.`,
      $localize`:@@interactive tutorial/measurements upload/mapper/map btn title:Save mappings`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.COMPLETE]
    );
    const steps: unknown[] = [step, step1, step2, step3];
    return steps;
  }

  getMeasurementUploadMapperPrimaryModelTabInteractiveTutorialSteps() {
    const step = this.interativeTutServ.generateStep(
      "#SpanElement0",
      "left",
      $localize`:@@interactive tutorial/measurements upload/mapper/primary tab/app data model:On the left side there are application data model variables.`,
      $localize`:@@interactive tutorial/measurements upload/mapper/primary tab/app data model title:Application data model variables`,
      [TutorialButtonLabel.NEXT]
    );

    const step1 = this.interativeTutServ.generateStep(
      "#myControl0",
      "right",
      $localize`:@@interactive tutorial/measurements upload/mapper/primary tab/client data model:On the right side there are your data model variables. in this input you should define the mapping of a specific variable from your model to the application model.`,
      $localize`:@@interactive tutorial/measurements upload/mapper/primary tab/client data model title:Your data model variables`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
    );

    const step2 = this.interativeTutServ.generateStep(
      "#primaryIcon0",
      "right",
      $localize`:@@interactive tutorial/measurements upload/mapper/primary tab/existing mapping icon:This icon informs you about existing mapping. Be carefull while changing this kind of mappings.`,
      $localize`:@@interactive tutorial/measurements upload/mapper/primary tab/existing mapping icon title:Existing mappings icon`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
    );

    const step3 = this.interativeTutServ.generateStep(
      "#myDeleteMappingBtn0",
      "right",
      $localize`:@@interactive tutorial/measurements upload/mapper/primary tab/delete btn:Use this button to delete mappings. (It is not recommended to delete the mappings of mandatory variables).`,
      $localize`:@@interactive tutorial/measurements upload/mapper/primary tab/delete btn title:Delete mappings button`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.COMPLETE]
    );
    const steps: unknown[] = [step, step1, step2, step3];
    return steps;
  }

  getMeasurementUploadFilesPreviewInteractiveTutorialSteps(
    rowId: number,
    positionfreezed: boolean,
    automaticSchedules: boolean
  ) {
    const step = this.interativeTutServ.generateStep(
      `#positionInfo${rowId}`,
      "top",
      $localize`:@@interactive tutorial/measurements upload/files preview/position info:Here you can see the total number of all loaded measurements, incorrect measurements and the number of files loaded per position.`,
      $localize`:@@interactive tutorial/measurements upload/files preview/position info title:Uploaded measurements info`,
      [TutorialButtonLabel.NEXT]
    );

    // const step1 = this.interativeTutServ.generateStep(
    //   `#automaticSched${rowId}`,
    //   "top",
    //   $localize`:@@interactive tutorial/measurements upload/files preview/automatic scheduler:By using this slider you can enable/disable automatic Scheduler feature.`,
    //   $localize`:@@interactive tutorial/measurements upload/files preview/automatic scheduler title:Automatic scheduler`,
    //   [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT],
    //   () => {
    //     if (automaticSchedules && positionfreezed) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   }
    // );

    const step2 = this.interativeTutServ.generateStep(
      `#reloadBtn${rowId}`,
      "top",
      $localize`:@@interactive tutorial/measurements upload/files preview/reload btn:Click onto this button in order to relad data on this position.`,
      $localize`:@@interactive tutorial/measurements upload/files preview/reload btn title:Reload data button`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
    );

    const step3 = this.interativeTutServ.generateStep(
      `#previewDataBtn${rowId}`,
      "top",
      $localize`:@@interactive tutorial/measurements upload/files preview/preview btn:Click onto this button in order to preview data on this position.`,
      $localize`:@@interactive tutorial/measurements upload/files preview/preview btn title:Preview data button`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
    );

    const step4 = this.interativeTutServ.generateStep(
      `#previewFileBtn${rowId}`,
      "top",
      $localize`:@@interactive tutorial/measurements upload/files preview/preview files btn:Click onto this button in order to preview files uploaded onto this position.`,
      $localize`:@@interactive tutorial/measurements upload/files preview/preview files btn title:Preview files button`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
    );

    const step5 = this.interativeTutServ.generateStep(
      `#startValidationBtn${rowId}`,
      "top",
      $localize`:@@interactive tutorial/measurements upload/files preview/start validation btn:Click onto this button in order to go to the validation screen.`,
      $localize`:@@interactive tutorial/measurements upload/files preview/start validation btn:Go to Validation button`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.COMPLETE]
    );
    const steps: unknown[] = [step, step2, step3, step4, step5];
    return steps;
  }

  getMeasurementUploadMeasurementPreviewInteractiveTutorialSteps() {
    const step = this.interativeTutServ.generateStep(
      `#filter`,
      "top",
      $localize`:@@interactive tutorial/measurements upload/measurements preview/filter:Here you can filter measurements by meter point ID.`,
      $localize`:@@interactive tutorial/measurements upload/measurements preview/filter title:MeterID filter`,
      [TutorialButtonLabel.NEXT],
      undefined,
      true
    );

    const step1 = this.interativeTutServ.generateStep(
      `#uploadMethod`,
      "top",
      $localize`:@@interactive tutorial/measurements upload/measurements preview/filter meas upload method:Here you can filter measurements by upload method.`,
      $localize`:@@interactive tutorial/measurements upload/measurements preview/filter meas upload method title:Upload method filter`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
    );

    const step2 = this.interativeTutServ.generateStep(
      `#addMeasurement`,
      "top",
      $localize`:@@interactive tutorial/measurements upload/measurements preview/add new measurement:By clicking onto this button you can add measurement manually.`,
      $localize`:@@interactive tutorial/measurements upload/measurements preview/add new measurement title:Add new measurement`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
    );

    const step3 = this.interativeTutServ.generateStep(
      `#measurementsTable`,
      "top",
      $localize`:@@interactive tutorial/measurements upload/measurements preview/measurements table:This table shows uploaded measurements.`,
      $localize`:@@interactive tutorial/measurements upload/measurements preview/measurements table title:Measurements table`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.COMPLETE]
    );
    const steps: unknown[] = [step, step1, step2, step3];
    return steps;
  }

  getMeasurementUploadAddMeasurementModalInteractiveTutorialSteps(
    optionalValues: number
  ) {
    const step = this.interativeTutServ.generateStep(
      "#measurementDetails",
      "right",
      $localize`:@@interactive tutorial/measurements upload/add measurement reading modal/details:This is where you define the most important information about the new reading. Those are: ID of the meter that has taken the reading, reading date, its status and the unit in which the readings are made.`,
      $localize`:@@interactive tutorial/measurements upload/add measurement reading modal/details title:Reading details`,
      [TutorialButtonLabel.NEXT]
    );

    const step1 = this.interativeTutServ.generateStep(
      "#addOptionalValue",
      "right",
      $localize`:@@interactive tutorial/measurements upload/add measurement reading modal/add optional value:By clicking this button, we can add a new optional value for reading. Optional values ​​are used to store additional information about the reading.`,
      $localize`:@@interactive tutorial/asset upload/add new asset modal/add optional value title:Adding optional values`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT]
    );

    const step2 = this.interativeTutServ.generateStep(
      "#deleteOptionalValue0",
      "right",
      $localize`:@@interactive tutorial/asset upload/add new asset modal/delete optional value:By clicking on this button, you can delete a given optional value.`,
      $localize`:@@interactive tutorial/asset upload/add new asset modal/delete optional value title:Deleting optional value`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT],
      () => {
        if (optionalValues > 0) {
          return true;
        } else {
          return false;
        }
      }
    );

    const step3 = this.interativeTutServ.generateStep(
      "#optionalValue0",
      "right",
      $localize`:@@interactive tutorial/asset upload/add new asset modal/optional value:Here you define the name and value of the new optional feature.`,
      $localize`:@@interactive tutorial/asset upload/add new asset modal/optional value title:Optional value`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.NEXT],
      () => {
        if (optionalValues > 0) {
          return true;
        } else {
          return false;
        }
      }
    );

    const step4 = this.interativeTutServ.generateStep(
      "#save",
      "right",
      $localize`:@@interactive tutorial/save btn:When you are ready, end the process by clicking on this button`,
      $localize`:@@interactive tutorial/save btn title:Save`,
      [TutorialButtonLabel.BACK, TutorialButtonLabel.COMPLETE],
      undefined,
      true
    );

    const steps: unknown[] = [step, step1, step2, step3, step4];
    return steps;
  }
}
