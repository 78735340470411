<div class="custom-container">

    <h3 i18n="@@export/custom billings modal/summary header">Summary:</h3>
    <br/>
    
    <div>
        <h4 i18n="@@export/selected columns">Selected columns:</h4>
        <mat-chip-list aria-label="Columns selection">
            <mat-chip *ngFor="let column of selectedColumns">{{column}}</mat-chip>
        </mat-chip-list>
    </div>
    <br/>
    <div>
        <h4 *ngIf="displayHeadersInCsv" i18n="@@export/headers in csv">Headers will be included in csv file.</h4>
        <h4 *ngIf="!displayHeadersInCsv" i18n="@@export/no headers in csv">Headers will not be included in csv file.</h4>
    </div>
    <br/>
    <div class="custom-btn">
        <button mat-raised-button color="accent"
                (click)="onFinalize()"
                class="btn btn-action"
                i18n="@@export/custom billings modal/export measurements"
                >
                Export measurements
        </button>
    </div>
    </div>