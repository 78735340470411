import { IApiEndpointConfig, GenericHttpParams } from '@shared/types/http/httpType';
import { VeeRule } from '@shared/models/appModels/VeeRule.model';

export const veeRuleApiConf: IApiEndpointConfig = {
  rootApiPath: 'vee_rules/',
  paths: [
    {
      swaggerId: 'getVeeRulesList',
      url: ' ',
      pathParams: [],
      responseIsArray: true,
    },
    {
      swaggerId: 'postVeeRule',
      url: ' ',
      pathParams: [],
      responseIsArray: true,
    },
    {
      swaggerId: 'getVeeRuleById',
      url: '{idVeeRule}/ ',
      pathParams: [{
        name: 'idVeeRule', pattern: '{idVeeRule}'
      }],
      responseIsArray: false,
    },
    {
      swaggerId: 'putVeeRuleById',
      url: '{idVeeRule}/ ',
      pathParams: [{
        name: 'idVeeRule', pattern: '{idVeeRule}'
      }],
      responseIsArray: false,
    },
    {
      swaggerId: 'deleteVeeRuleById',
      url: '{idVeeRule}/ ',
      pathParams: [{
        name: 'idVeeRule', pattern: '{idVeeRule}'
      }],
      responseIsArray: false,
    },
    {
      swaggerId: 'postValidateVeeRule',
      url: 'implementation',
      pathParams: [],
      responseIsArray: false,
    },
  ],
};

export interface VeeRuleHttpParamG extends GenericHttpParams<VeeRule> {
  path?: {
    idVeeRule?: string
  };
  queryParams?: {
    svUtilityType?: string;
    svVeeRuleType?: string;
  };
}

