import { IGetInterfaceType } from '@shared/types';

export interface ITemperatureData {
    avgOutTemp: number;
    timestamp: number;
}

export class TemperatureData implements ITemperatureData, IGetInterfaceType<ITemperatureData, TemperatureData> {

    avgOutTemp: number;
    timestamp: number;

    constructor(iTemperatureData: ITemperatureData) {
        this.avgOutTemp = (typeof (iTemperatureData.avgOutTemp) !== 'undefined') ? iTemperatureData.avgOutTemp : undefined;
        this.timestamp = (typeof (iTemperatureData.timestamp) !== 'undefined') ? iTemperatureData.timestamp : undefined;
    }

    getInterface(webInterface: boolean): ITemperatureData {
        return {
            avgOutTemp: this.avgOutTemp,
            timestamp: this.timestamp,
        };
    }

}
