import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { KeyCloakTokenResult } from '@shared/types/authTypes';
import { RolesEnum } from '@shared/types/authTypes';

declare let Keycloak: any;


@Injectable()
export class KeycloakService {

  static keycloakAuthObject: any;

  constructor() { }

   static init(): Promise<any> {
    KeycloakService.keycloakAuthObject = new Keycloak(environment.keyCloakConstructParams);

    KeycloakService.keycloakAuthObject.onTokenExpired = () => {
      console.warn('[Auth-service] Auth token expired');
      if (environment.authConfig.tokenAutoRefresh) {
        KeycloakService.getTokenSt()
        .then(() => {
          if (environment.authDebug) {console.log('Updated token via auto-refresh'); }
        }, (reason) => {
          if (environment.authDebug) {console.log('Failed token via auto-refresh: ', reason); }
        });
      }
    };

    KeycloakService.keycloakAuthObject.onAuthRefreshSuccess = () => {
      if (environment.authDebug) {console.warn('[Auth-service] Token refreshed'); }
    };

    KeycloakService.keycloakAuthObject.onAuthRefreshError = () => {
      if (environment.authDebug) { console.warn('[Auth-service] Auth token refresh error'); }
      setInterval(() => {
        KeycloakService.logout();
      }, 15000);
    };

    return new Promise((resolve, reject) => {
      KeycloakService.keycloakAuthObject.init( environment.keyCloakInitParams )
      .then(() => {
        console.error('Auth granted via KeyCloak service');
        console.log(KeycloakService.keycloakAuthObject);
        resolve(KeycloakService.keycloakAuthObject);
      })
      .catch(() => {
        console.error('Auth rejected');
        reject();
      });
    });
 }

  static logout(url: string = environment.keyCloakConstructParams.logoutUrl) {
    KeycloakService.keycloakAuthObject.logout(url);
  }

  static getUsername(): string {
    return KeycloakService.keycloakAuthObject.tokenParsed.preferred_username;
  }

  static getRole(): RolesEnum {
    if (KeycloakService.keycloakAuthObject && KeycloakService.keycloakAuthObject.tokenParsed && KeycloakService.keycloakAuthObject.tokenParsed.realm_access) {
      const roles: string[] = (<string[]>KeycloakService.keycloakAuthObject.tokenParsed.realm_access?.roles).map(role => role.toUpperCase());
      for (let i = 0; i < roles.length; i++) {
        if (typeof (RolesEnum[roles[i]]) !== 'undefined') {
          return RolesEnum[roles[i]];
        }
      }
    }
    
    return undefined;
  }

  static getFullName(): string {
    return KeycloakService.keycloakAuthObject.tokenParsed.name;
  }

  static isAuthenticated(): boolean {
    return KeycloakService.keycloakAuthObject.authenticated;
  }

  static getTokenSt(): Promise<KeyCloakTokenResult> {
    return new Promise((resolve, reject) => {
       if (KeycloakService.keycloakAuthObject && KeycloakService.keycloakAuthObject.token) {
          KeycloakService.keycloakAuthObject
             .updateToken(environment.authConfig.tokenValidUntilTime)
             .then((refreshed) => {
               console.log('Token refreshed: ', refreshed);
                resolve({
                  token: KeycloakService.keycloakAuthObject.token,
                  success: true}
                );
             })
             .catch(() => {
                resolve({
                  token: undefined,
                  success: false});
             });
       } else {
        resolve({
          token: undefined,
          success: false});
       }
    });
  }

  login(): Promise<any> {
    return new Promise((resolve, reject) => {
      KeycloakService.keycloakAuthObject.login()
     .success(() => {
       console.error('Auth granted');
       console.log(KeycloakService.keycloakAuthObject);
       KeycloakService.keycloakAuthObject = environment.keyCloakConstructParams.logoutUrl;
       resolve(KeycloakService.keycloakAuthObject);
     })
     .error(() => {
       console.error('Auth rejected');
       reject();
     });
   });
  }

  getToken(): Promise<KeyCloakTokenResult> {
    return new Promise((resolve, reject) => {
       if (KeycloakService.keycloakAuthObject && KeycloakService.keycloakAuthObject.token) {
          KeycloakService.keycloakAuthObject
             .updateToken(environment.authConfig.tokenValidUntilTime)
             .then((refreshed) => {
              console.log('Token refreshed: ', refreshed);
                resolve({
                  token: KeycloakService.keycloakAuthObject.token,
                  success: true}
                );
             })
             .catch(() => {
                resolve({
                  token: undefined,
                  success: false});
             });
       } else {
        resolve({
          token: undefined,
          success: false});
       }
    });
 }

 clearToken() {
   KeycloakService.keycloakAuthObject.clearToken();
 }
}
