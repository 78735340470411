import { UtilityTypeEnum, IGetInterfaceType } from '@shared/types';

export interface IAutomaticImportConfigForSchedulePosition {
    start_time: number;
    end_time: number;
    aggregation: string;
    positionId: number | string;
    utility_type: string | UtilityTypeEnum;
}

export class AutomaticImportConfigForSchedulePosition implements IAutomaticImportConfigForSchedulePosition,
 IGetInterfaceType<IAutomaticImportConfigForSchedulePosition, AutomaticImportConfigForSchedulePosition> {
    start_time: number;
    end_time: number;
    aggregation: string;
    positionId: number | string;
    utility_type: string | UtilityTypeEnum;

    constructor(iAutomaticImportConfigForSchedulePosition: IAutomaticImportConfigForSchedulePosition) {
        this.start_time = (typeof (iAutomaticImportConfigForSchedulePosition.start_time) !== 'undefined') ?
        iAutomaticImportConfigForSchedulePosition.start_time : undefined;

        this.end_time = (typeof (iAutomaticImportConfigForSchedulePosition.end_time) !== 'undefined') ?
        iAutomaticImportConfigForSchedulePosition.end_time : undefined;

        this.aggregation = (typeof (iAutomaticImportConfigForSchedulePosition.aggregation) !== 'undefined') ?
        iAutomaticImportConfigForSchedulePosition.aggregation : undefined;

        this.positionId = (typeof (iAutomaticImportConfigForSchedulePosition.positionId) !== 'undefined') ?
        iAutomaticImportConfigForSchedulePosition.positionId : undefined;

        this.utility_type = (typeof (iAutomaticImportConfigForSchedulePosition.utility_type) !== 'undefined') ?
        iAutomaticImportConfigForSchedulePosition.utility_type : undefined;
    }

    getInterface(webInterface: boolean): IAutomaticImportConfigForSchedulePosition {
        return {
            start_time: this.start_time,
            end_time: this.end_time,
            aggregation: this.aggregation,
            positionId: this.positionId,
            utility_type: this.utility_type,
        };
    }
 }
 