import { IApiEndpointConfig, GenericHttpParams } from '@shared/types';
import { MediaTypeParameter } from '@shared/models/appModels/MediaTypeParameter.mode';


export const ParametersApiConf: IApiEndpointConfig = {
  rootApiPath: 'model/',
  paths: [
    {/* 1 */
       swaggerId: 'getMeasurementDataModel',
       url: 'measurementdata',
       pathParams: [],
       responseIsArray: true},
     {/* 2 */
       swaggerId: 'getOperationalDataModel',
       url: 'operationaldata',
       pathParams: [],
       responseIsArray: true},
  ]
};

export interface IParameterQueryParams {
  svUtilityType?: string;
  sort?: string;
  name?: string;
}

export interface ParameterHttpParamG extends GenericHttpParams<MediaTypeParameter> {
  queryParams?: IParameterQueryParams;
  path?: {
  };
}

export interface ParameterHttpParamGToDeleteMapping extends GenericHttpParams<MediaTypeParameter> {
  measurementMap?: {fileMeasurementDefinition: string, measurementDefinitionId: number};
}
