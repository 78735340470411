// Those linkes are here
// to make comparison between environment and environment prod files nice and easy
// dont't be dick and don't remove them
// due to some complaining linter.
import { IEnvironment } from '@env/environmentIfc';
import { EndpointsEnum } from '@shared/types/http/endpointEnum';
import { UtilityTypeEnum } from '@shared/types/utilityType';
import { LanguagesEnum } from '@shared/types/localisation';

export const environment: IEnvironment = {
  language: LanguagesEnum.ENG,
  production: true,
  debug: false,
  authDebug: false,
  randomizeMock: true,
  backendPath: 'http://192.168.2.142:8080/',
  heatSupport: 'true',
  waterSupport: 'false',
  electricitySupport: 'false',
  automaticScheduler: 'true',
  defaultReqLimit: 50,
  defaultMediaType: UtilityTypeEnum.HEAT,
  defaultStatusDownloadData: false,
  veeStatusTimeout: 30000,
  servcesConfiguration: [
    { mockEnabled: false, time: 2000, verbose: true, endpointE: EndpointsEnum.SCHEDULES },
    { mockEnabled: false, time: 2000, verbose: true, endpointE: EndpointsEnum.POSITIONS },
    { mockEnabled: false, time: 2000, verbose: true, endpointE: EndpointsEnum.FILE_OPERATIONAL_DATA },
    { mockEnabled: false, time: 2000, verbose: true, endpointE: EndpointsEnum.FILE_METER_DATA },
    { mockEnabled: false, time: 100, verbose: true, endpointE: EndpointsEnum.CUSTOMER },
    { mockEnabled: false, time: 100, verbose: true, endpointE: EndpointsEnum.METER_POINT },
    { mockEnabled: false, time: 100, verbose: true, endpointE: EndpointsEnum.MEASUREMENT_DATA },
    { mockEnabled: false, time: 100, verbose: true, endpointE: EndpointsEnum.METER_DATA },
    { mockEnabled: false, time: 2000, verbose: true, endpointE: EndpointsEnum.VEE_STATUS },
    { mockEnabled: false, time: 100, verbose: true, endpointE: EndpointsEnum.VEE_RULE },
    { mockEnabled: false, time: 100, verbose: true, endpointE: EndpointsEnum.DATA_MODEL_TYPE },
    { mockEnabled: false, time: 100, verbose: true, endpointE: EndpointsEnum.TIME_GROUPS },
    { mockEnabled: false, time: 100, verbose: true, endpointE: EndpointsEnum.PARAMETERS },
    { mockEnabled: false, time: 100, verbose: true, endpointE: EndpointsEnum.ANALYSIS_ALGORITHM },
    { mockEnabled: false, time: 100, verbose: true, endpointE: EndpointsEnum.ANALYSIS_STATUS },
    { mockEnabled: false, time: 100, verbose: true, endpointE: EndpointsEnum.SENSOR_DATA },
    { mockEnabled: false, time: 100, verbose: false, endpointE: EndpointsEnum.FILE_TEMPERATUTE_DATA },
    { mockEnabled: false, time: 100, verbose: false, endpointE: EndpointsEnum.DATA_IMPORT },
  ],
  keyCloakConstructParams: {
    url: 'https://vpn.connectpoint.pl:2524/auth',
    realm: 'smartveeRealm',
    clientId: 'smartveeClient',
    'ssl-required': 'external',
    'public-client': true,
    'auth-server-url': 'https://vpn.connectpoint.pl:2524/auth',
    'confidential-port': 0,
    resource: 'smartveeClient',
    logoutUrl: 'http://localhost:4200/home',
    redirect_uri: 'http://localhost:4200/home'
  },
  keyCloakInitParams: {
    onLoad: 'login-required',
    promiseType: 'native'
  },
  authConfig: {
    authRequired: true,
    tokenValidUntilTime: 60,
    tokenAutoRefresh: true,
  },
  analysisStatusTimeout: 30000,
};
