import { INameMapper } from '@shared/types/applicationTypes';
import { environment as env } from '@env/environment';
import { typesLocalisation as loc } from '@shared/types/localisation';

/* This file contains supporting types/enums for meterData class/interface */

/* Status flag represents overall status of single measurement */
export enum StatusFlagEnum {
  'NORMAL' = 0,
  'CONTROL' = 1,
  'DISCONNECTION' = 2,
  'MOUNT' = 3,
  'FAILURE' = 4,
  'UNAVAILABLE' = 5,
  'ERROR' = 6,
  'ESTIMATED' = 7
}

/* Validation flag represents validation status of single meter measurement */
export enum ValidationResultEnum {
  'VALID' = 0,
  'INVALID' = 1,
  'AUTOCORRECTION' = 2,
  'MANUALCORRECTION' = 3,
  'UNDEFINED' = 4,
  'DEFAULT' = 5,
}

export enum ValidationTypes {
  'ALLRULES' = 0,
  'COMPLETENESSCHECKREADINGENERGY' = 1,
  'COMPLETENESSCHECKUSAGEENERGY' = 2,
  'MEASUREMENTCOMPARISION' = 3,
  'NEGATIVEUSAGE' = 4,
}

export enum MeterDataOriginEnum {
  'AUTO' = 0,
  'ESTIMATED' = 1,
  'EDITED' = 2,
  'OVERRUN' = 3
}

export function getValidationResults(): INameMapper[] {
  return [
    { id: ValidationResultEnum.VALID, name: $localize`:@@data validation/validation summary/valid:Valid`, classType: 'valid' },
    { id: ValidationResultEnum.INVALID, name: $localize`:@@data validation/validation summary/invalid:Invalid`, classType: 'invalid' },
    { id: ValidationResultEnum.AUTOCORRECTION, name: $localize`:@@data validation/validation summary/auto correction:Auto correction`, classType: 'corrected' },
    { id: ValidationResultEnum.MANUALCORRECTION, name: $localize`:@@data validation/validation summary/manual correction:Manual correction`, classType: 'corrected' },
    { id: ValidationResultEnum.UNDEFINED, name: $localize`:@@data validation/validation summary/undefined:Undefined status`, classType: 'undefined' },
  ];
}

export function getValidationResult(id: number): INameMapper {
  switch (id) {
    case ValidationResultEnum.VALID: {
      return { id: ValidationResultEnum.VALID, name: $localize`:@@data validation/validation summary/valid:Valid`, classType: 'valid' };
    }
    case ValidationResultEnum.INVALID: {
      return { id: ValidationResultEnum.INVALID, name: $localize`:@@data validation/validation summary/invalid:Invalid`, classType: 'invalid' };
    }
    case ValidationResultEnum.AUTOCORRECTION: {
      return { id: ValidationResultEnum.AUTOCORRECTION, name: $localize`:@@data validation/validation summary/auto correction:Auto correction`, classType: 'corrected' };
    }
    case ValidationResultEnum.MANUALCORRECTION: {
      return { id: ValidationResultEnum.MANUALCORRECTION, name: $localize`:@@data validation/validation summary/manual correction:Manual correction`, classType: 'corrected' };
    }
    case ValidationResultEnum.UNDEFINED: {
      return { id: ValidationResultEnum.UNDEFINED, name: $localize`:@@data validation/validation summary/undefined:Undefined status`, classType: 'undefined' };
    }
  }
}

export const statusFlagStringName: INameMapper[] = [
  {
    id: StatusFlagEnum.NORMAL,
    name: loc.statusFlagStringName[env.language].statusFlag.NORMAL,
    classType: 'normal',
  },
  {
    id: StatusFlagEnum.CONTROL,
    name: loc.statusFlagStringName[env.language].statusFlag.CONTROL,
    classType: 'control',
  },
  {
    id: StatusFlagEnum.DISCONNECTION,
    name: loc.statusFlagStringName[env.language].statusFlag.DISCONNECTION,
    classType: 'disconnection',
  },
  {
    id: StatusFlagEnum.MOUNT,
    name: loc.statusFlagStringName[env.language].statusFlag.MOUNT,
    classType: 'mount',
  },
  {
    id: StatusFlagEnum.FAILURE,
    name: loc.statusFlagStringName[env.language].statusFlag.FAILURE,
    classType: 'failure',
  },
  {
    id: StatusFlagEnum.UNAVAILABLE,
    name: loc.statusFlagStringName[env.language].statusFlag.UNAVAILABLE,
    classType: 'unavailable',
  },
  {
    id: StatusFlagEnum.ERROR,
    name: loc.statusFlagStringName[env.language].statusFlag.ERROR,
    classType: 'error',
  },
  {
    id: StatusFlagEnum.ESTIMATED,
    name: loc.statusFlagStringName[env.language].statusFlag.ESTIMATED,
    classType: 'estimated',
  }
];
