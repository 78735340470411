import { CommonAppDataService } from '@shared/services/commonAppData.service';
import { UploadFile, UploadInput, humanizeBytes, UploadOutput, NgFileDropDirective, NgFileSelectDirective } from '@ngx-uploader';
import { EventEmitter, ViewChild, ChangeDetectorRef, Directive } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { environment } from '@env/environment';
import { UploadLocalisation as loc } from './upload.localisation';
import { HttpErrorEnum } from '@shared/types/http/httpErrorEnums';
import { Subject } from 'rxjs';
import { REQUEST_TOO_LONG } from 'http-status-codes';


export interface UploadResult {
  success: boolean;
  error: boolean;
  errorCause?: string;
  pending: boolean;
}

export interface CountControl {
  preUpload: number;
  postUpload: number;
}

@Directive()
export class CommonUploadComponent<T> {

  @ViewChild(NgFileSelectDirective, { static: true }) selectFile: NgFileSelectDirective;
  @ViewChild(NgFileDropDirective, { static: true }) dropFile: NgFileDropDirective;

  readonly uploadPrompt: { [label: string]: string } = loc[environment.language].texts.common.uploadPrompt;
  readonly dropArea = loc[environment.language].texts.common.dropArea;
  readonly success = loc[environment.language].texts.common.succes;
  readonly error = loc[environment.language].texts.common.error;
  readonly reload = loc[environment.language].texts.common.reload;
  readonly uploadProg = loc[environment.language].texts.common.uploadProgress;
  readonly upload = loc[environment.language].texts.common.upload;
  readonly chooseFileToUpload = loc[environment.language].texts.common.chhoseFileToUpload;
  readonly tooManyFiles = loc[environment.language].texts.common.tooManyFiles;
  protected uploadResults: UploadResult;
  displayableUpload: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: (number) => string;
  dragOver: boolean;

  countFileSubject = new Subject<number>();

  protected uploadExecutor: (body: any, ...other) => Observable<T> = undefined;
  protected countHandler: () => Observable<number> = undefined;

  debugMode: boolean = environment.debug;

  protected countsControl: CountControl = {
    postUpload: undefined,
    preUpload: undefined,
  };

  protected autoUpload: boolean;

  constructor(private cs: CommonAppDataService, autoUpload: boolean = true, protected cdr: ChangeDetectorRef) {
    this.displayableUpload = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;
    this.uploadResults = {
      success: false,
      error: false,
      errorCause: '',
      pending: false,
    };
    this.autoUpload = autoUpload;
  }


  protected onSuccesfullUpload(output?: UploadOutput) {
    this.uploadInput.emit({ type: 'removeAll' });
    this.uploadResults.success = true;
    this.uploadResults.pending = false;
    this.uploadResults.error = false;
    this.dragOver = false;
  }

  protected onUnSuccesfullUpload(output?: UploadOutput) {
    this.uploadInput.emit({ type: 'removeAll' });
    this.uploadResults.success = false;
    this.uploadResults.pending = false;
    this.uploadResults.error = true;
    this.dragOver = false;
  }

  protected onAddedToQueue(output: UploadOutput) {
    this.countFileSubject.next(1);
    /* Below logic is fucked up. */
    if (this.displayableUpload.length > 0) {
      this.displayableUpload = [];
    }
    this.displayableUpload.push(output.file);
    this.hideResults();
  }

  protected onAllAddedToQueue(output: UploadOutput) {
    this.countFileSubject.next(1);
    if (!this.uploadResults.error && this.autoUpload) {
      const event: UploadInput = {
        type: 'uploadAll',
        uploadExecutor: this.uploadExecutor,
      };
      this.uploadResults.pending = true;
      this.countHandler()
        .subscribe((count: number) => {
          this.countsControl.preUpload = count;
          this.uploadInput.emit(event);
        });
    } else if (this.autoUpload) {
      this.clearElementRefsFiles();
      this.uploadInput.emit({ type: 'removeAll' });
    }
  }

  protected onRejected(output: UploadOutput) {
    this.countHandler()
      .subscribe((count: number) => {
        this.countsControl.postUpload = count;
        const pre = this.countsControl.preUpload;
        if (this.countsControl.postUpload === (pre + 1)) {
          /* FIXME: Hack to filter out false-negative 500 responses */
          /* Quering by file name shall be used insteda of dummy counts */
          this.onSuccesfullUpload();
          this.cdr.markForCheck();
          return;
        }
        let errorMessage: string = output.statusText;
        this.uploadResults.error = true;
        this.uploadResults.success = false;
        this.uploadResults.pending = false;
        this.displayableUpload = [];
        if (output.statusCode === REQUEST_TOO_LONG) {
          this.uploadResults.errorCause = this.getFailureCause({ oversize: true });
        } else if (errorMessage.includes(HttpErrorEnum[HttpErrorEnum.PARSE_WRONG_METERID])) {
          this.uploadResults.errorCause = this.getFailureCause({ meter: true });
        } else if (errorMessage.includes(HttpErrorEnum[HttpErrorEnum.PARSE_DATE_NOT_EXIST])) {
          this.uploadResults.errorCause = this.getFailureCause({ emptyDate: true });
        } else if (errorMessage.includes(HttpErrorEnum[HttpErrorEnum.PARSE_TEMPERATURE_NOT_EXIST])) {
          this.uploadResults.errorCause = this.getFailureCause({ emptyTemperature: true });
        } else if (output.statusText === 'tooManyFiles') {
          this.uploadResults.errorCause = this.tooManyFiles;
        } else {
          this.uploadResults.errorCause = this.getFailureCause({ server: true });
        }

        this.onUnSuccesfullUpload();
        this.countFileSubject.next(0);

        this.cdr.markForCheck();
      });
  }

  protected onRemoved(output: UploadOutput) {
    const idx = this.displayableUpload.findIndex(f => f.id === output.file.id);
    if (idx !== -1) {
      this.displayableUpload.splice(idx, 1);
    }
  }

  onRemoveAll(output: UploadOutput) {
    this.displayableUpload = [];
  }

  endDrag() {
    this.dragOver = true;
  }

  continueDrag() {
    this.dragOver = false;
  }

  public onUploadOutput(output: UploadOutput): void {
    switch (output.type) {
      case 'addedToQueue': {
        this.onAddedToQueue(output);
        break;
      }
      case 'allAddedToQueue': {
        this.onAllAddedToQueue(output);
        break;
      }
      case 'removed': {
        this.onRemoved(output);
        break;
      }
      case 'removedAll': {
        this.onRemoveAll(output);
        break;
      }
      case 'cancelled': {
        this.endDrag();
        break;
      }
      case 'dragOver': {
        this.endDrag();
        break;
      }
      case 'dragOut': {
        this.continueDrag();
        break;
      }
      case 'drop': {
        this.continueDrag();
        break;
      }
      case 'done': {
        this.onSuccesfullUpload(output);
        break;
      }
      case 'uploading': {
        break;
      }
      case 'rejected': {
        this.onRejected(output);
        break;
      }
    }
  }

  protected getFailureCause(root:
    {
      badExtension?: boolean, oversize?: boolean, server?: boolean,
      meter?: boolean, emptyDate?: boolean, emptyTemperature?: boolean
    }
  ): string {
    if (typeof (root.badExtension) !== 'undefined' && root.badExtension) {
      return loc[environment.language].texts.common.badExtension;
    } else if (typeof (root.oversize) !== 'undefined' && root.oversize) {
      return loc[environment.language].texts.common.oversize;
    } else if (typeof (root.server) !== 'undefined' && root.server) {
      return loc[environment.language].texts.common.server;
    } else if (typeof (root.emptyDate) !== 'undefined' && root.emptyDate) {
      return loc[environment.language].texts.common.emptyDate;
    } else if (typeof (root.emptyTemperature) !== 'undefined' && root.emptyTemperature) {
      return loc[environment.language].texts.common.emptyTemperature;
    } else if (typeof (root.meter) !== 'undefined' && root.meter) {
      return loc[environment.language].texts.common.wrongMeterId;
    } else {
      return loc[environment.language].texts.common.Unknown;
    }
  }

  protected getResultClass(): { [key: string]: boolean } {
    return {
      'alert-error': this.uploadResults.error,
      'alert-success': this.uploadResults.success,
    };
  }

  public displayResults(): boolean {
    return this.uploadResults.success || this.uploadResults.error;
  }

  protected hideResults() {
    this.uploadResults.success = false;
    // this.uploadResults.error = false;
  }

  reuploadFileSelect(idPosi: number): void {
    try {
      const event = new MouseEvent('click', { bubbles: true });
      (() => { this.selectFile.getElementRef().dispatchEvent(event); })();
    } catch (e) {
      throw new Error(e);
    }
  }

  clearElementRefsFiles() {
    this.selectFile.clearRefs();
    this.dropFile.clearRefs();
  }

}
