import { IMeterData } from '@shared/models/appModels/meterData.model';
import { UtilityTypeEnum } from '@shared/types/utilityType';
import { MeasurementTypeEnum, MeterNodeTypeEnum, MeterNodeTypeNames, MeasurementTypeNames } from '@shared/types/modelTypes/meterPointTypes';
import { IGetInterfaceType, IParameters } from '@shared/types/applicationTypes';
import { getUtilityTypeName } from '@shared/types/utilityTypeMap';
import { IMeasurementData, MeasurementData } from '@shared/models/appModels/measurementData.model';
import { MeterData } from '@shared/models/appModels/meterData.model';
import { Customer, ICustomer } from '@shared/models/appModels/customer.model';
import { RestEnumMpapper } from '@shared/models/RestSupport/restEnumMapper.model';
import { BaseOptionalParamsMapper } from '@shared/models/baseOptionalParams.mode';

export interface IMeterPoint {
  idMeterPoint: number;
  idCustomer?: number;
  idMasterMeterPoint?: number;
  serialNumber: string;
  measurementType: MeasurementTypeEnum | string;
  meterPointType: MeterNodeTypeEnum | string;
  meterPlacement: string;
  isMeterBilled: boolean;
  utilityType: UtilityTypeEnum | string;
  meterConfigMaxRead?: number;
  meterConfigDigits?: number;
  meterConfigDecimals?: number;
  idTimeGroup?: number;
  active: boolean;
  enabled?: boolean;
  optionalValues?: IParameters;
}

export class MeterPoint extends BaseOptionalParamsMapper implements IMeterPoint, IGetInterfaceType<IMeterPoint, MeterPoint> {

  /* Enum mappers */
  static MeasurementTypeMpaper: RestEnumMpapper<typeof MeasurementTypeEnum> = new RestEnumMpapper<typeof MeasurementTypeEnum>();
  static MeterNodeTypeMapper: RestEnumMpapper<typeof MeterNodeTypeEnum> = new RestEnumMpapper<typeof MeterNodeTypeEnum>();
  static MediaTypeMapper: RestEnumMpapper<typeof UtilityTypeEnum> = new RestEnumMpapper<typeof UtilityTypeEnum>();

  idMeterPoint: number;
  idCustomer: number;
  idMasterMeterPoint?: number;
  serialNumber: string;
  measurementType: MeasurementTypeEnum | string;
  meterPointType: MeterNodeTypeEnum | string;
  meterPlacement: string;
  isMeterBilled: boolean;
  utilityType: UtilityTypeEnum;
  meterConfigMaxRead?: number;
  meterConfigDigits?: number;
  meterConfigDecimals?: number;
  active: boolean;
  enabled?: boolean;
  idTimeGroup: number;
  optionalValues?: IParameters;

  /* Extension fields */
  customer?: Customer;
  meterData?: MeterData[];
  measurementsData?: MeasurementData[];
  timeGroupName?: string;

  constructor(iMeter: IMeterPoint, subI?: { iCustomer?: ICustomer, iMesData?: IMeasurementData[], iMeterData?: IMeterData[] }) {
    super();
    if (!iMeter) { throw new TypeError('MeterPoint initialization: params is undefined !'); }

    this.idMeterPoint = (typeof (iMeter.idMeterPoint) !== 'undefined') ? iMeter.idMeterPoint : undefined;
    this.idCustomer = (typeof (iMeter.idCustomer) !== 'undefined') ? iMeter.idCustomer : undefined;
    this.idMasterMeterPoint = (typeof (iMeter.idMasterMeterPoint) !== 'undefined') ? iMeter.idMasterMeterPoint : undefined;
    this.serialNumber = (typeof (iMeter.serialNumber) !== 'undefined') ? iMeter.serialNumber : undefined;
    this.measurementType = (typeof (iMeter.measurementType) !== 'undefined') ?
      this.assignMeasurementType(iMeter.measurementType) : this.assignMeasurementType(MeasurementTypeEnum.DEFAULT);
    this.meterPointType = (typeof (iMeter.meterPointType) !== 'undefined') ?
      this.assignNodeType(iMeter.meterPointType) : this.assignNodeType(MeterNodeTypeEnum.OTHER);
    this.meterPlacement = (typeof (iMeter.meterPlacement) !== 'undefined') ? iMeter.meterPlacement : undefined;
    this.isMeterBilled = (typeof (iMeter.isMeterBilled) !== 'undefined') ? iMeter.isMeterBilled : true;
    this.utilityType = (typeof (iMeter.utilityType) !== 'undefined') ?
      this.assignMediaType(iMeter.utilityType) : this.assignMediaType(UtilityTypeEnum.OTHER);
    this.meterConfigMaxRead = (typeof (iMeter.meterConfigMaxRead) !== 'undefined') ? iMeter.meterConfigMaxRead : undefined;
    this.meterConfigDigits = (typeof (iMeter.meterConfigDigits) !== 'undefined') ? iMeter.meterConfigDigits : undefined;
    this.meterConfigDecimals = (typeof (iMeter.meterConfigDecimals) !== 'undefined') ? iMeter.meterConfigDecimals : undefined;
    this.active = (typeof (iMeter.active) !== 'undefined') ? iMeter.active : undefined;
    this.enabled = (typeof (iMeter.enabled) !== 'undefined') ? iMeter.enabled : undefined;
    this.idTimeGroup = (typeof (iMeter.idTimeGroup) !== 'undefined') ? iMeter.idTimeGroup : undefined;
    this.optionalValues = this.getParamsToRestApi(iMeter.optionalValues);

    /* Extensions to model */
    if (subI && subI.iMesData) {
      try {
        this.measurementsData = (subI.iMesData) ? subI.iMesData.map(el => new MeasurementData(el)) : [];
      } catch (e) {
        this.measurementsData = undefined;
        throw e;
      }
    }
    if (subI && subI.iMeterData) {
      try {
        this.meterData = (subI.iMeterData) ? subI.iMeterData.map(el => new MeterData(el)) : [];
      } catch (e) {
        this.meterData = undefined;
        throw e;
      }
    }
    if (subI && subI.iCustomer) {
      try {
        this.customer = (subI.iCustomer) ? new Customer(subI.iCustomer) : {} as Customer;
      } catch (e) {
        this.customer = undefined;
        throw e;
      }
    }
  }

  private assignMeasurementType(eIn: MeasurementTypeEnum | string): MeasurementTypeEnum {
    try {
      return MeterPoint.MeasurementTypeMpaper.getEnumAsNumber(MeasurementTypeEnum, eIn);
    } catch (e) {
      console.warn(e);
      return MeasurementTypeEnum.DEFAULT;
    }
  }

  private assignNodeType(eIn: MeterNodeTypeEnum | string): MeterNodeTypeEnum {
    try {
      return MeterPoint.MeterNodeTypeMapper.getEnumAsNumber(MeterNodeTypeEnum, eIn);
    } catch (e) {
      console.warn(e);
      return MeterNodeTypeEnum.OTHER;
    }
  }

  private assignMediaType(eIn: UtilityTypeEnum | string): UtilityTypeEnum {
    try {
      return MeterPoint.MediaTypeMapper.getEnumAsNumber(UtilityTypeEnum, eIn);
    } catch (e) {
      console.warn(e);
      return UtilityTypeEnum.OTHER;
    }
  }

  getFirstMeasurementData(): MeasurementData {
    if (this.measurementsData && this.measurementsData.length > 0) {
      return this.measurementsData[0];
    }
  }

  getFirstMeterData(): MeterData {
    if (this.meterData && this.meterData.length > 0) {
      return this.meterData[0];
    }
  }

  getMeterDataCount(): number {
    if (this.meterData) {
      return this.meterData.length;
    }
  }

  setMeterData(metersData: MeterData[]) {
    if (typeof (this.meterData) === 'undefined') {
      this.meterData = [];
    }
    metersData.forEach(data => this.meterData.push(data));
  }

  setMeasurementData(measurmeentData: MeasurementData[]) {
    if (typeof (this.measurementsData) === 'undefined') {
      this.measurementsData = [];
    }
    measurmeentData.forEach(data => this.measurementsData.push(data));
  }

  setCustomer(customer: Customer) {
    if (Object.keys(customer).length === 0 && customer.constructor === Object) {
      this.customer = undefined;
    } else {
      this.customer = customer;
    }
  }

  getMesurementTypeaAsString(): string {
    return MeasurementTypeNames[this.measurementType].name;
  }

  getrMeterPointTypeAsString(): string {
    return MeterNodeTypeNames[this.meterPointType].name;
  }

  getUtilityTypeAsString(): string {
    return getUtilityTypeName(this.utilityType).name;
  }

  getCustomer() {
    return this.customer;
  }

  hasCosutomer(): boolean {
    if (typeof (this.customer) === 'undefined') {
      return false;
    } else {
      return true;
    }
  }

  getInterface(webInterface: boolean = true): IMeterPoint {
    const emptyValue = (webInterface) ? null : undefined;
    return {
      idMeterPoint: this.idMeterPoint,
      idCustomer: (this.idCustomer) ? this.idCustomer : emptyValue,
      idMasterMeterPoint: (this.idMasterMeterPoint) ? this.idMasterMeterPoint : emptyValue,
      serialNumber: this.serialNumber,
      measurementType: MeterPoint.MeasurementTypeMpaper.getEnumAsString(MeasurementTypeEnum, this.measurementType),
      meterPointType: MeterPoint.MeterNodeTypeMapper.getEnumAsString(MeterNodeTypeEnum, this.meterPointType),
      meterPlacement: this.meterPlacement,
      isMeterBilled: this.isMeterBilled,
      utilityType: MeterPoint.MediaTypeMapper.getEnumAsString(UtilityTypeEnum, this.utilityType),
      idTimeGroup: (this.idTimeGroup) ? this.idTimeGroup : emptyValue,
      active: this.active,
      enabled: this.enabled,
      meterConfigDecimals: (this.meterConfigDecimals) ? this.meterConfigDecimals : emptyValue,
      meterConfigDigits: (this.meterConfigDigits) ? this.meterConfigDigits : emptyValue,
      meterConfigMaxRead: (this.meterConfigMaxRead) ? this.meterConfigMaxRead : emptyValue,
      optionalValues: (this.optionalValues && Object.keys(this.optionalValues).length > 0) ? Object.assign({}, this.optionalValues) : emptyValue,
    };
  }

}
