// Angular modules import
import { Injectable } from '@angular/core';

// RjJS imports
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { FileOperationalData } from '@shared/models/appModels/fileOperationalData';

import { BaseDataStore, ApiDataShift } from '@shared/types/applicationTypes';
import { UtilityTypeEnum } from '@shared/types/utilityType';
import { GenericHttpParams, IFileUpload } from '@shared/types/http/httpType';
import { FileOperationalDataHttpParamG, FileOperationalDataQueryParameters } from '@shared/types/http/fileOperatinalDataHttpConfig';
import { FileOperationalDataService } from '@shared/services/http/fileOperationalData.service';
import { CommonAppDataService } from '@shared/services/commonAppData.service';

@Injectable()
export class FileOperationalDataStoreService extends BaseDataStore<GenericHttpParams<FileOperationalData>, FileOperationalDataHttpParamG> {

  private operationalDataSubject$: Subject<FileOperationalData> = new Subject<FileOperationalData>();
  private readonly operationalDataObservable$: Observable<FileOperationalData> = this.operationalDataSubject$.asObservable();

  constructor(
    private fileOperationalDataService: FileOperationalDataService,
    cs: CommonAppDataService
  ) {
    super(cs);
    /* Initialize mappers */
  }

  getChangeObservable(): Observable<FileOperationalData> {
    return this.operationalDataObservable$;
  }

  getSubject(): Subject<FileOperationalData> {
    return this.operationalDataSubject$;
  }


  getFileOperationaldataList(nums: ApiDataShift, allowEmpty: boolean = true, filters?: FileOperationalDataQueryParameters): Observable<FileOperationalData[]> {

    const params = this.getEmptyParams();
    params.queryParams.limit = '' + nums.limit;
    params.queryParams.offset = '' + nums.offset;
    params.config.strict = !allowEmpty;

    if (filters) {
      if (filters.sort) {
        params.queryParams.sort = '' + filters.sort;
      }
    }

    return this.getFileOperationalDataList(params);
  }

  addFileOperationalData(item: any, options: { emitEvent: boolean } = { emitEvent: true }): Observable<FileOperationalData> {
    const params = this.getEmptyParams();
    params.body = item;

    params.queryParams.svUtilityType = UtilityTypeEnum[this.cs.getCurrentMediaType()];

    return this.fileOperationalDataService.postFileOperationaldata(params)
      .do((updated: FileOperationalData) => {
        this.operationalDataSubject(options, updated);
      });
  }

  getFileOperationaldataById(allowEmpty: boolean = false): Observable<FileOperationalData> {

    const params = this.getEmptyParams();
    params.config.strict = !allowEmpty;

    return this.fileOperationalDataService.getFileOperationaldataById(params);
  }

  deleteFileOperationaldataById(allowEmpty: boolean = false, options: { emitEvent: boolean } = { emitEvent: true }): Observable<FileOperationalData> {
    const params = this.getEmptyParams();
    params.config.strict = !allowEmpty;

    return this.fileOperationalDataService.deleteFileOperationaldataById(params)
      .do((updated: FileOperationalData) => {
        this.operationalDataSubject(options, updated);
      });
  }

  private operationalDataSubject(options: { emitEvent: boolean }, updated: FileOperationalData) {
    if (options.emitEvent) {
      this.operationalDataSubject$.next(updated);
    }
  }

  getFileOperationalDataCount(
    filters?: { idFileOperationalData?: string[] }): Observable<number> {
    const params = this.getEmptyParams();
    if (filters) {
      params.queryParams.idFileOperationalData = filters.idFileOperationalData || undefined;
    }
    return this.fileOperationalDataService.getFileOperationaldataListCount(params);
  }

  private getFileOperationalDataList(params: FileOperationalDataHttpParamG) {
    return this.fileOperationalDataService.getFileOperationaldataList(params);
  }

  protected getEmptyParams(): FileOperationalDataHttpParamG {
    const r: FileOperationalDataHttpParamG = { body: {} as IFileUpload, headers: {}, path: {}, queryParams: {}, config: {} };
    return r;
  }

}
