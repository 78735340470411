import { Component, Input, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTabChangeEvent } from '@angular/material/tabs';

import { MeterPointDataStoreService } from '@shared/services/dataStore/meterPointDataStore.service';
import { ScheduleDataStoreService } from '@shared/services/dataStore/scheduleDataStore.service';
import { environment } from '@env/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PositionDataStoreService } from '@shared/services/dataStore/positionDataStore.service';
import { DataExploarationLocalisation as eLoc } from '../../data-exploration-localisation';
import { CommonAppDataService } from '@shared/services/commonAppData.service';
import { concatMap } from 'rxjs/operators';
import { MeterData } from '@shared/models';
import { Observable } from 'rxjs';
import { DataExplorationInteractiveTutorialService } from '../../data-exploration-interactive-tutorial.service';
import { InteractiveTutorialService } from '@shared/services/interactiveTutorialService.service';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'sv-detail',
  templateUrl: 'detail.component.html',
  styleUrls: ['detail.component.sass'],
})

export class DetailComponent implements OnInit, AfterViewInit, OnDestroy {
  dataFetched: boolean;
  dataLoading: boolean;
  dataLength: number;
  tableFrameURL: SafeResourceUrl;
  diagramFrameURL: SafeResourceUrl;
  readonly parametersField = eLoc[environment.language].texts.asset.assetDataPreview.parametersField;

  @Input() detail: { serialNumber: string; idMeterPoint: number; };
  @Input() scheduleId: number;
  @Input() positionId: number;
  @Input() aggregation: string;
  @Input() diagramId: number;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  readonly debugMode: boolean = environment.debug;
  scheduleSelect: FormControl;
  positionSelect: FormControl;
  @ViewChild(MatSort) sort: MatSort;
  startDate: number;
  endDate: number;
  tabURL = [];
  url: string;
  startTutFunc;

  constructor(
    public sanitizer: DomSanitizer,
    public meterPointDataStoreService: MeterPointDataStoreService,
    public scheduleDataStoreService: ScheduleDataStoreService,
    private positionDataStoreService: PositionDataStoreService,
    private communicationService: CommonAppDataService,
    private interactiveTutorialService: InteractiveTutorialService,
    private dataExplorationInteractiveTutorialService: DataExplorationInteractiveTutorialService
  ) {

  }

  ngOnInit() {
    this.setStartAndEndDate();
    this.tabURL = this.getURLByMedium();
  }

  startInteractiveTutorial(){
    const steps = this.dataExplorationInteractiveTutorialService.getDataExploartionDetailsInteractiveTutorialSteps();
    this.interactiveTutorialService.startInteractiveTutorial(steps);
  }

  ngAfterViewInit(){
    this.startTutFunc = setTimeout(() => this.startInteractiveTutorial(), 1500);
  }

  ngOnDestroy(){
    clearTimeout(this.startTutFunc);
  }

  setStartAndEndDate() {
    this.dataFetched = false;
    let meterDataCount;
    let first;
    this.positionDataStoreService.getPositionMeterDataCount(this.positionId, { idMeterPoint: this.detail.idMeterPoint }).pipe(
      concatMap<number, Observable<MeterData[]>>(mtrDataCount => {
        meterDataCount = mtrDataCount;
        return this.positionDataStoreService.getPositionMeterDataForSingleMeterPoint(this.positionId, this.detail.idMeterPoint, { limit: 1, offset: 0 });
      }),
      concatMap<MeterData[], Observable<MeterData[]>>(frst => {
        first = frst;
        return this.positionDataStoreService.getPositionMeterDataForSingleMeterPoint(this.positionId, this.detail.idMeterPoint, {
          limit: 1, offset: meterDataCount > 0 ? meterDataCount - 1 : meterDataCount
        });
      })
    ).subscribe(end => {
      if (first.length !== 0 && end.length !== 0) {
        this.startDate = first[0].timestamp;
        this.endDate = end[0].timestamp;
        this.dataFetched = true;
      }
    });
  }

  getURL() {
    this.url = environment.backendPath +
      this.tabURL[0] + this.startDate + this.tabURL[1] + this.endDate + this.tabURL[2] +
      this.positionId + this.tabURL[3] + this.detail.idMeterPoint + this.tabURL[4] + this.aggregation + this.tabURL[5] + this.tabURL[6] + this.tabURL[7] + this.tabURL[8];
    return this.url;
  }

  onTabClick(event: MatTabChangeEvent) {
    this.getURL();
  }

  getURLByMedium() {
    var path = "";
    if (this.diagramId == 5) {
      if (this.communicationService.getCurrentMediaType() === 0) {
        path = 'grafana/d/2hw6SCaZz/explorationdiagram?orgId=1&from=';
      } else if (this.communicationService.getCurrentMediaType() === 1) {
        path = 'grafana/d/4r2sqMPZk/explorationdiagram_water?orgId=1&from=';
      } else if (this.communicationService.getCurrentMediaType() === 2) {
        path = 'grafana/d/-wHUG7PWk/explorationdiagram_electricity?orgId=1&from=';
      }
      return [path, '&to=', '&var-actualPosition=', '&var-meterId=', '&var-agregation=',
        '&var-time=' + $localize`:Dashboard label in Grafana|Dashboard label in Grafana@@exploration/time:Date` +
        '&var-name=' + $localize`:Dashboard title in Grafana|Dashboard label in Grafana@@exploration/consumption for the meter:Consumption for the meter` +
        '&var-energy=' + $localize`:Dashboard label in Grafana|Dashboard label in Grafana@@export/export table header energy:Energy` +
        '&var-temperature=' + $localize`:Dashboard label in Grafana|Dashboard label in Grafana@@exploration/temperature:Temperature`,
        '&theme=light', '&kiosk', '&autofitpanels']
    } else if (this.diagramId == 6) {
      path = 'grafana/d/HY4ZPqvGz/flow-and-return-temperature?orgId=1&from=';
      return [path, '&to=', '&var-actualPosition=', '&var-meterId=', '&var-agregation=',
        '&var-time=' + $localize`:Dashboard label in Grafana|Dashboard label in Grafana@@exploration/time:Date` +
        '&var-name=' + $localize`:Dashboard title in Grafana|Dashboard title in Grafana@@exploration/flow and return temperature:Flow and return temperature` +
        '&var-tempin=' + $localize`:Dashboard label in Grafana|Dashboard label in Grafana@@exploration/tempIn:TempIn` +
        '&var-tempout=' + $localize`:Dashboard label in Grafana|Dashboard label in Grafana@@exploration/tempOut:TempOut`,
        '&theme=light', '&kiosk', '&autofitpanels']
    }
    return undefined
  }


}