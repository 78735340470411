import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { environment } from '@env/environment';

import { RestTypeMapper } from '@shared/models/RestSupport';
import { VeeRule, IVeeRule } from '@shared/models/appModels/VeeRule.model';

import { EndpointsEnum } from '@shared/types/http/endpointEnum';
import { UtilityTypeEnum } from '@shared/types/utilityType';
import { VeeRuleHttpParamG } from '@shared/types/http/veeRuleHttpConfig';
import { VeeRuleTypeEnum } from '@shared/types/modelTypes/veeRuleTypes';
/* MOCKS */
import { veeRulesValidationMock } from '@shared/mock/veeRule.mock';
import { CommonAppDataService } from '@shared/services/commonAppData.service';
import { RequestService } from './requestService.class';
import { Observer } from 'rxjs/Observer';
import { ValidateImplementation, IValidateImplementation } from '@shared/models/appModels/validateImplementation.model';
import { IValidateMeasurement } from '@shared/models/appModels/validateMeasurement.mode';

@Injectable()
export class VeeRuleService extends RequestService {

    rules_mock: IVeeRule[];
    estimationRules_mock: IVeeRule[];

    private veeRuleMapper: RestTypeMapper<IVeeRule, VeeRule>;
    private validateImplementationMapper: RestTypeMapper<IValidateImplementation, ValidateImplementation>;
    private validateImplementationMapperAny: RestTypeMapper<any, any>;


    /* Debug data */
    debugMode: boolean = environment.debug;

    constructor(
        http: HttpClient,
        cs: CommonAppDataService) {
        super(http, cs, EndpointsEnum.VEE_RULE);
        if (this.MOCK_SERVICE) {
            this.rules_mock = veeRulesValidationMock;
        } else {
            this.rules_mock = undefined;
        }
        /* Initialize type MAPPERS */
        this.veeRuleMapper = new RestTypeMapper<IVeeRule, VeeRule>();
        this.validateImplementationMapper = new RestTypeMapper<IValidateImplementation, ValidateImplementation>();
        this.validateImplementationMapperAny = new RestTypeMapper<any, any>();
    }

    getVeeRulesList(params: VeeRuleHttpParamG): Observable<VeeRule[]> {
        const thisApiID = 'getVeeRulesList';
        if (this.MOCK_SERVICE) {
            const rulesSet: IVeeRule[] = this.getRulesSet(params.queryParams.svVeeRuleType);
            const filteredRules: IVeeRule[] = rulesSet.filter(e => {
                return UtilityTypeEnum[e.media_type] === params.queryParams.svUtilityType || UtilityTypeEnum[e.media_type] === UtilityTypeEnum.OTHER;
            });
            return Observable.of(filteredRules.map(r => new VeeRule(r)));
        } else {
            return this.getRequest(params, this.veeRuleMapper, thisApiID, VeeRule);
        }
    }

    getVeeRuleById(params: VeeRuleHttpParamG): Observable<VeeRule> {
        const thisApiID = 'getVeeRuleById';
        if (this.MOCK_SERVICE) {
            const rulesSet: IVeeRule[] = this.getRulesSet(params.queryParams.svVeeRuleType);
            const intSingle: IVeeRule = rulesSet.filter(rule => rule.id === +params.path.idVeeRule)[0];
            return Observable.of(new VeeRule(intSingle));
        } else {
            return this.getRequest(params, this.veeRuleMapper, thisApiID, VeeRule);
        }
    }

    postVeeRule(params: VeeRuleHttpParamG): Observable<VeeRule> {
        const thisApiID = 'postVeeRule';
        if (this.MOCK_SERVICE) {
            return Observable.of(params.body);
        } else {
            const veeRule = new VeeRule(params.body);
            const body = this.veeRuleMapper.mapObjectToInterface(veeRule, true);
            return this.postRequest(body, params, this.veeRuleMapper, thisApiID, VeeRule);
        }
    }

    putVeeRuleById(params: VeeRuleHttpParamG): Observable<VeeRule> {
        const thisApiID = 'putVeeRuleById';
        if (this.MOCK_SERVICE) {
            const rulesSet: IVeeRule[] = this.getRulesSet(params.queryParams.svVeeRuleType);
            const intSingle: IVeeRule = rulesSet.filter(rule => rule.id === +params.path.idVeeRule)[0];
            return Observable.of(new VeeRule(intSingle));
        } else {
            const body = this.veeRuleMapper.mapObjectToInterface(params.body, true);
            return this.putRequest(body, params, this.veeRuleMapper, thisApiID, VeeRule);
        }
    }

    deleteVeeRuleById(params: VeeRuleHttpParamG): Observable<VeeRule> {
        const thisApiID = 'deleteVeeRuleById';
        if (this.MOCK_SERVICE) {
            const rulesSet: IVeeRule[] = this.getRulesSet(params.queryParams.svVeeRuleType);
            const intSingle: IVeeRule = rulesSet.filter(rule => rule.id === +params.path.idVeeRule)[0];
            return Observable.of(new VeeRule(intSingle));
        } else {
            return this.deleteRequest(params, this.veeRuleMapper, thisApiID, VeeRule);
        }
    }

    postValidateVeeRule(params: VeeRuleHttpParamG): Observable<any> /*Observable<ValidateImplementation>*/ {
        const thisApiID = 'postValidateVeeRule';
        if (this.MOCK_SERVICE) {
            return Observable.of(params.body);
        } else {
            const body = this.validateImplementationMapper.mapObjectToInterface(params.body, true);
            return this.postRequest(body, params, this.validateImplementationMapperAny, thisApiID, String);
        }
    }

    private getRulesSet(ruleType: string): IVeeRule[] {
        let rulesSet: IVeeRule[];
        if (ruleType === VeeRuleTypeEnum[VeeRuleTypeEnum.ESTIMATION]) {
            rulesSet = this.estimationRules_mock;
        } else if (ruleType === VeeRuleTypeEnum[VeeRuleTypeEnum.VALIDATION]) {
            rulesSet = this.rules_mock;
        } else {
            throw new Error('Incorrect parameter passed as Rule Type');
        }
        return rulesSet;
    }

}
