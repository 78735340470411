import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/interval';
import 'rxjs/add/operator/takeWhile';

import { BaseDataStore, GenericHttpParams, UtilityTypeEnum } from '@shared/types';
import { AnalysisStatusHttpParamG } from '@shared/types/http/analysisProcessHttpConfig';
import { AnalysisStatus } from '@shared/models/appModels/analysisStatus.model';
import { AnalysisStatusService } from '@shared/services/http/analysisStatus.service';
import { AnalysisProcessStageEnum, AnalysisProcessStatusEnum } from '@shared/types/modelTypes/analysisProcessTypes';
import { IAnalysisRequest } from '@shared/models/appModels/analysisStatus.model';
import { CommonAppDataService } from '@shared/services/commonAppData.service';


import { IPolledData } from '@shared/types/applicationTypes';


@Injectable()
export class AnalysisStatusDataStoreService extends BaseDataStore<GenericHttpParams<AnalysisStatus>, AnalysisStatusHttpParamG> {

  private analysisStatusDataSubject$: Subject<AnalysisStatus> = new Subject<AnalysisStatus>();
  private analysisStatusObservable$: Observable<AnalysisStatus> = this.analysisStatusDataSubject$.asObservable();

  constructor(
    private analysisStatusHttpService: AnalysisStatusService, cs: CommonAppDataService) {
    super(cs);
  }

  getChangeObservable(): Observable<AnalysisStatus> {
    return this.analysisStatusObservable$;
  }

  getAnalysisStateForPosition(
    idPosition: number,
    algorithmTypeEnum: string,
    allowEmpty: boolean = false
  ): Observable<AnalysisStatus> {
    const params = this.getEmptyParams();
    params.path.idPosition = '' + idPosition;
    params.queryParams.algorithmType = algorithmTypeEnum;
    params.config.strict = !allowEmpty;
    params.queryParams.svUtilityType = UtilityTypeEnum[this.cs.getCurrentMediaType()];
    return this.analysisStatusHttpService.getAnalysisProcessState(params);
  }

  setAnalysisStateForPosition(id: number, algorithmTypeEnum: string, state: AnalysisProcessStageEnum,
    aggregation: string, startDate: number,
    endDate: number, meterId: string, address: string, algorithmType: any,
    allowEmpty: boolean = true, options?: { emitEvent: boolean }): Observable<AnalysisStatus> {
    const params = this.getEmptyParams();
    params.path.idPosition = '' + id;
    params.queryParams.algorithmType = algorithmTypeEnum;
    params.queryParams.svUtilityType = UtilityTypeEnum[this.cs.getCurrentMediaType()];
    const p: IAnalysisRequest = {
      requestedStage: state, aggregation: aggregation,
      startDate: startDate, endDate: endDate, meterId: meterId, address: address,
      algorithmType: algorithmType, svUtilityType: UtilityTypeEnum[this.cs.getCurrentMediaType()]
    };
    params.body = new AnalysisStatus(p);
    params.config.strict = !allowEmpty;
    return this.analysisStatusHttpService.postAnalysisProcessState(params)
      .do((as: AnalysisStatus) => {
        if (options && options.emitEvent) {
          this.analysisStatusDataSubject$.next(as);
        }
      });
  }

  clearAnalysisStateForPosition(id: number, algorithmTypeEnum: string, options?: { emitEvent: boolean }): Observable<AnalysisStatus> {
    const params = this.getEmptyParams();
    params.path.idPosition = '' + id;
    params.queryParams.algorithmType = algorithmTypeEnum;
    params.queryParams.svUtilityType = UtilityTypeEnum[this.cs.getCurrentMediaType()];
    return this.analysisStatusHttpService.deleteAnalysisProcessState(params)
      .do((vs: AnalysisStatus) => {
        if (options && options.emitEvent) {
          this.analysisStatusDataSubject$.next(vs);
        }
      });
  }

  longPollingState(
    idPosition: number,
    algorithmTypeEnum: string,
    interval: number = 500,
    mockReq?: {
      state?: AnalysisProcessStageEnum, status?: AnalysisProcessStatusEnum,
      sequence?: boolean, aggregation: string, startDate: number, endDate: number,
      meterId: string, address: string, mockErorr?
    }): Observable<IPolledData<AnalysisStatus>> {
    return Observable.interval(interval)
      .concatMap<number, AnalysisStatus>((idx: number) => {
        return this.getAnalysisStateForPosition(idPosition, algorithmTypeEnum, true);
      })
      .concatMap<AnalysisStatus, IPolledData<AnalysisStatus>>((s: AnalysisStatus, idx: number) => {
        return Observable.of({ data: s, pollTime: idx * interval, positionId: idPosition });
      });
  }

  remoteStatusTriggering(
    idPosition: number,
    interval: number = 500,
    algorithmTypeEnum: string,
    processFn: (oIn: IPolledData<AnalysisStatus>) => Observable<IPolledData<AnalysisStatus>>,
    mockReq?: {
      state?: AnalysisProcessStageEnum, status?: AnalysisProcessStatusEnum,
      sequence?: boolean, aggregation: string, startDate: number, endDate: number,
      meterId: string, address: string
    }): Observable<IPolledData<AnalysisStatus>> {

    return Observable.interval(interval)
      .concatMap<number, AnalysisStatus>((idx: number) => {
        return this.getAnalysisStateForPosition(idPosition, algorithmTypeEnum, true);
      })
      .concatMap<AnalysisStatus, IPolledData<AnalysisStatus>>((s: AnalysisStatus, idx: number) => {
        return Observable.of({ data: s, pollTime: idx * interval });
      })
      .concatMap<IPolledData<AnalysisStatus>, IPolledData<AnalysisStatus>>((i) => {
        return processFn(i);
      });
  }

  protected getEmptyParams(): AnalysisStatusHttpParamG {
    const r: AnalysisStatusHttpParamG = { body: {} as AnalysisStatus, config: {}, headers: {}, path: {}, queryParams: {} };
    return r;
  }
}
