import { Component, Input, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Position, Schedule, MeterData } from '@shared/models';

import { MeterPointDataStoreService } from '@shared/services/dataStore/meterPointDataStore.service';
import { ScheduleDataStoreService } from '@shared/services/dataStore/scheduleDataStore.service';
import { PositionDataStoreService } from '@shared/services/dataStore/positionDataStore.service';
import { MeterDetailsDataSource } from './dataSource/meterDetailsDataSource';

import { MockListAlgorithms1, MockListAlgorithms2 } from '../../mock/listAlgorithms.mock';
import { typesLocalisation } from '@shared/types/localisation';
import { environment } from '@env/environment';
import { TableHost } from '@shared/types';
import { Observable } from 'rxjs/Observable';
import { IAnalysisAlgorithm, AnalysisAlgorithm } from '@shared/models/appModels/analysisAlgorithm.model';
import { AnalysisAlgorithmDataStoreService } from '@shared/services/dataStore/analysisAlgorithmsDataStore.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AnalysisLocalisation } from '../../../data-analysis-localisation';
import { Analysis } from '@shared/models/appModels/analysis.model';
import { ForecastingDetailsDataSource } from './dataSource/forecastingDetailsDataSource';
import { Forecasting } from '@shared/models/appModels/forecasting.model';
import { CommonAppDataService } from '@shared/services/commonAppData.service';
import { keyframes } from '@angular/animations';
import { InteractiveTutorialService } from '@shared/services/interactiveTutorialService.service';
import { DataAnalaysisInteractiveTutorialService } from '../../data-analysis-interactive-tutorial-service.service';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'sv-detail',
  templateUrl: 'detail.component.html',
  styleUrls: ['detail.component.sass'],
})

export class DetailComponent implements OnInit, AfterViewInit, OnDestroy, TableHost<any> {
  dataFetched: boolean;
  dataLoading: boolean;
  dataLength: number;
  algorithmType: string;
  algorithmName: string;
  @Input() actualAnalysisAlgorithm: IAnalysisAlgorithm;
  listAlgorithmsDetectionOfAnomalies: IAnalysisAlgorithm[] = [];
  listAlgorithmsForecasting: IAnalysisAlgorithm[] = [];
  @Input() detail: { serialNumber: string; idMeterPoint: number; };
  options: {} = {};
  dashbord: string;
  @Input() scheduleId: number;
  @Input() positionId: number;
  @Input() isDataInAlgorithm: Array<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  readonly debugMode: boolean = environment.debug;
  readonly anomalyMdIcon: string = 'error';
  readonly itemsPage = typesLocalisation.ItemsPage[environment.language].texts;
  readonly headers = AnalysisLocalisation[environment.language].texts.asset.assetDataPreview.parametersField;
  readonly energy;
  readonly noData = AnalysisLocalisation[environment.language].texts.asset.assetDataPreview.noData;
  readonly details = $localize`:@@widget/process details button label:Details`;
  graphDataAvailable: boolean = false;
  chartType: string;
  dataAvailable: boolean = false;
  filteredData: MeterData[] = [];
  chartData: MeterData[] = [];
  lineChartColors: Array<any>;
  isLegend = false;
  pointStyle1 = [];
  pointRadius1 = [];
  pointBackgroundColor1 = [];
  pointBorderColor1 = [];
  filterForm: FormGroup;
  scheduleSelect: FormControl;
  positionSelect: FormControl;
  @Input() start: any;
  @Input() end: any;
  urlJFrame: string;
  urlForecastingJFrame: string;
  @ViewChild(MatSort) sort: MatSort;
  @Input() analysisEvent: any;
  algorithmURL = ['0', '1', '2', '3', '4'];
  @Input() title: Map<string, string>;
  schedules: Schedule[];
  positions: Position[];

  actualSelect: {
    schedule?: number,
    position?: number
  };
  actual_position: number;
  idMeterPoint: number;

  displayedColumnsDetails: string[];
  forecastingDisplayedColumnsDetails: string[];
  dataSourceDetails: MeterDetailsDataSource;
  forecastingDataSourceDetails: ForecastingDetailsDataSource;

  listAlgorithms1: Array<any> = [];
  listAlgorithms2: Array<any> = [];
  listAlgorithms3: Array<any> = [];

  interactiveTutorialFunc;

  constructor(
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private router: Router,
    public meterPointDataStoreService: MeterPointDataStoreService,
    private positionDataStoreService: PositionDataStoreService,
    private analysisAlgorithmsService: AnalysisAlgorithmDataStoreService,
    public scheduleDataStoreService: ScheduleDataStoreService,
    private cs: CommonAppDataService,
    private interactiveTutorialService: InteractiveTutorialService,
    private dataAnalysisInteractiveTutorialService: DataAnalaysisInteractiveTutorialService
  ) {
    this.cs.getCurrentMediaType() !== 1 ? this.energy = this.headers.ENERGY : this.energy = this.headers.WATER;
  }
  isCorrectData(mesArr: any, mdAray: any, id: number) {
    if (mesArr.length === 0 || mesArr.length > 1) {
      throw new Error(`ouch at ${mesArr}`);
    } else {
      return Observable.of(mdAray[id]);
    }
  }
  ngOnInit() {
    this.actual_position = this.positionId;
    this.setVariable();
    this.analysisAlgorithmsService.getAnalysisAlgorithmsTypeDetectionOfAnomalies()
      .map<AnalysisAlgorithm[], AnalysisAlgorithm[]>((rules) => {
        return rules.sort((a, b) => a.idAnalysisAlgorithm - b.idAnalysisAlgorithm);
      })
      .concatMap<AnalysisAlgorithm[], AnalysisAlgorithm[]>((analysisAlgorithms: AnalysisAlgorithm[]) => {
        this.listAlgorithmsDetectionOfAnomalies = analysisAlgorithms.slice(0);
        return this.analysisAlgorithmsService.getAnalysisAlgorithmsTypeDetectionOfAnomalies();
      })
      .map<AnalysisAlgorithm[], AnalysisAlgorithm[]>((rules) => {
        return rules.sort((a, b) => a.idAnalysisAlgorithm - b.idAnalysisAlgorithm);
      })
      .subscribe((r: AnalysisAlgorithm[]) => {
        this.listAlgorithmsDetectionOfAnomalies = r.slice(0);
      },
        (e) => {
          console.error('Unable to fetch rules');
          console.error(e);
          this.listAlgorithmsDetectionOfAnomalies = (this.listAlgorithmsDetectionOfAnomalies.length === 0) ? [] : this.listAlgorithmsDetectionOfAnomalies;
        },
        () => {
          if (this.debugMode) { console.log('DetectionOfAnomalies algorithms fetch complete'); }
        });

    this.analysisAlgorithmsService.getAnalysisAlgorithmsTypeForecasting()
      .map<AnalysisAlgorithm[], AnalysisAlgorithm[]>((rules) => {
        return rules.sort((a, b) => a.idAnalysisAlgorithm - b.idAnalysisAlgorithm);
      })
      .concatMap<AnalysisAlgorithm[], AnalysisAlgorithm[]>((analysisAlgorithms: AnalysisAlgorithm[]) => {
        this.listAlgorithmsForecasting = analysisAlgorithms.slice(0);
        return this.analysisAlgorithmsService.getAnalysisAlgorithmsTypeForecasting();
      })
      .map<AnalysisAlgorithm[], AnalysisAlgorithm[]>((rules) => {
        return rules.sort((a, b) => a.idAnalysisAlgorithm - b.idAnalysisAlgorithm);
      })
      .subscribe((r: AnalysisAlgorithm[]) => {
        this.listAlgorithmsForecasting = r.slice(0);
      },
        (e) => {
          console.error('Unable to fetch rules');
          console.error(e);
          this.listAlgorithmsForecasting = (this.listAlgorithmsForecasting.length === 0) ? [] : this.listAlgorithmsForecasting;
        },
        () => {
          if (this.debugMode) { console.log('Forecasting algorithms fetch complete'); }
        });

    this.route.queryParams.subscribe(params => {
      const positionId = +params.position || -1;
      const scheduleId = +params.schedule || -1;
      this.paginator._intl.itemsPerPageLabel = this.itemsPage.itemsPage;
      this.paginator._intl.nextPageLabel = this.itemsPage.nextPage;
      this.paginator._intl.previousPageLabel = this.itemsPage.previousPage;
      this.paginator._intl.lastPageLabel = this.itemsPage.lastPage;
      this.paginator._intl.firstPageLabel = this.itemsPage.firstPage;
      if (positionId !== -1) {
        this.actual_position = +params.position;
        this.positionDataStoreService.getPositionById(positionId).subscribe(
          (v: Position) => {
            // tslint:disable-next-line:max-line-length
            this.dataSourceDetails = new MeterDetailsDataSource(this.positionDataStoreService, this.positionId, this.detail.idMeterPoint,
              this.paginator, this.sort, this.algorithmType);
            this.forecastingDataSourceDetails = new ForecastingDetailsDataSource(this.positionDataStoreService, this.positionId, this.detail.idMeterPoint,
              this.paginator, this.sort);
            this.loadData();
            if (v.idPosition !== positionId) {
              throw new Error('Data Conflict');
            } else {
              this.positionId = v.idPosition;
            }
          }, (error: any) => this.router.navigate(['/schedule']),
          () => { });
      }
      if (scheduleId !== -1) {
        this.scheduleId = scheduleId;
      }
    }
    );
    this.listAlgorithms1 = MockListAlgorithms1;
    this.listAlgorithms2 = MockListAlgorithms2;

    this.displayedColumnsDetails = ['date', 'temp', 'energy', 'percentage'];
    this.forecastingDisplayedColumnsDetails = ['energy', 'period'];
  }

  setVariable() {
    this.algorithmURL[0] = environment.backendPath + 'grafana/d-solo/2V4pQETiz/3sigmaheat?panelId=2&orgId=1&from=' +
      this.end + '&to=' + this.start + '&var-myMeter=' + this.detail.serialNumber +
      '&var-intervalMin=' + $localize`:Dashboard label in Grafana|Dashboard label in Grafana@@analysis/summary/intervalMin:The minimum value of the interval` +
      '&var-intervalMax=' + $localize`:Dashboard label in Grafana|Dashboard label in Grafana@@analysis/summary/intervalMax:The maximum value of the interval` +
      '&var-deviation=' + $localize`:Dashboard label in Grafana|Dashboard label in Grafana@@analysis/summary/deviation:Deviation` +
      '&var-measurementValue=' + $localize`:Dashboard label in Grafana|Dashboard label in Grafana@@analysis/summary/measurementValue:Measurement value` + '&var-myAlgorithm=';
    this.algorithmURL[1] = '&var-actualPosition=' + this.positionId + '&theme=light';
    this.algorithmURL[2] = environment.backendPath + 'grafana/d-solo/QSBQ7yemk/periodicprofile?orgId=1&from=' +
      this.end + '&to=' + this.start + '&var-myMeter=' + this.detail.serialNumber +
      '&var-intervalMin=' + $localize`:Dashboard label in Grafana|Dashboard label in Grafana@@analysis/summary/intervalMin:The minimum value of the interval` +
      '&var-intervalMax=' + $localize`:Dashboard label in Grafana|Dashboard label in Grafana@@analysis/summary/intervalMax:The maximum value of the interval` +
      '&var-energy=' + $localize`:Dashboard label in Grafana|Dashboard label in Grafana@@export/export table header energy:Energy` +
      '&var-myAlgorithm=' + $localize`:Dashboard title in Grafana|Dashboard title in Grafana@@analysis/summary/periodic profile:Periodic profile`;
    this.algorithmURL[3] = '&panelId=2';
    this.algorithmURL[4] = '&kiosk&autofitpanels';
    this.algorithmURL[5] = '&var-name=';
  }
  ngAfterViewInit(){
    this.interactiveTutorialFunc = setTimeout(()=>this.startInteractiveTutorial(), 1500);
  }

  startInteractiveTutorial(){
    const steps = this.dataAnalysisInteractiveTutorialService.getDataAnalysisSummaryDetailsInteractiveTutorialSteps(this.actualAnalysisAlgorithm.algorithmType);
    this.interactiveTutorialService.startInteractiveTutorial(steps);
  }

  ngOnDestroy(): void {
    clearTimeout(this.interactiveTutorialFunc);
  }

  loadData(): Observable<any[]> {
    this.dataLoading = true;
    if (this.actualAnalysisAlgorithm.algorithmType === 0) {
      return this.positionDataStoreService.getPositionAnalysis(this.positionId,
        {
          idMeterPoint: this.detail.idMeterPoint,
          algorithm: this.algorithmType, anomaly: false
        })
        .concatMap<Analysis[], Analysis[]>((paged: Analysis[]) => {
          return this.positionDataStoreService.getPositionAnalysisCount(this.positionId,
            {
              idMeterPoint: this.detail.idMeterPoint,
              algorithm: this.algorithmType, anomaly: false
            })
            .concatMap<number, Analysis[]>((length) => {
              this.dataLength = length;
              return Observable.of(paged);
            })
            .concatMap<Analysis[], Analysis>((mdAray: Analysis[]) => {
              return Observable.from(mdAray)
                .concatMap<Analysis, Analysis>((md: Analysis, id) => {
                  /* FIXME: data shall be fetched not only for single position, but whole history - no API support */
                  return this.positionDataStoreService.getPositionAnalysis(this.positionId,
                    {
                      idMeterPoint: this.detail.idMeterPoint,
                      algorithm: this.algorithmType, anomaly: false
                    })
                    .concatMap<Analysis[], Analysis>((mesArr: Analysis[]) => {
                      return this.isCorrectData(mesArr, mdAray, id);
                    });
                });
            })
            .toArray()
            .do(v => {
              this.dataLoading = false;
            });
        });
    } else {
      return this.positionDataStoreService.getPositionForecasting(this.positionId,
        {
          idMeterPoint: this.detail.idMeterPoint,
        })
        .concatMap<Forecasting[], Forecasting[]>((paged: Forecasting[]) => {
          return this.positionDataStoreService.getPositionForecastingCount(this.positionId,
            {
              idMeterPoint: this.detail.idMeterPoint
            })
            .concatMap<number, Forecasting[]>((length) => {
              this.dataLength = length;
              return Observable.of(paged);
            })
            .concatMap<Forecasting[], Forecasting>((mdAray: Forecasting[]) => {
              return Observable.from(mdAray)
                .concatMap<Forecasting, Forecasting>((md: Forecasting, id) => {
                  /* FIXME: data shall be fetched not only for single position, but whole history - no API support */
                  return this.positionDataStoreService.getPositionForecasting(this.positionId,
                    {
                      idMeterPoint: this.detail.idMeterPoint
                    })
                    .concatMap<Forecasting[], Forecasting>((mesArr: Forecasting[]) => {
                      return this.isCorrectData(mesArr, mdAray, id);
                    });
                });
            })
            .toArray()
            .do(v => {
              this.dataLoading = false;
            });
        });
    }
  }

  onPaginatorEvents(paginator: MatPaginator): Observable<any[]> {
    return paginator.page
      .concatMap<PageEvent, any[]>((pageEvent: PageEvent) => {
        return this.loadData();
      });
  }

  onExternalDataChangeEvent(changeInductors: Observable<any>[]): Observable<any[]> {
    return Observable.merge(...changeInductors)
      .concatMap<any, any[]>((v: any) => {
        return this.loadData();
      });
  }

  onFilterEvents(): Observable<any[]> {
    return this.sort.sortChange
      .concatMap<Sort, any[]>((change: Sort) => {
        return this.loadData();
      });
  }

  onClearFilters(): Observable<any[]> {
    throw new Error('Method not implemented.');
  }

  getURL() {
    this.loadData();
    return this.urlJFrame = this.algorithmURL[0] + this.getTranslatedAlgorithmName(this.algorithmName) + this.algorithmURL[1] + this.algorithmURL[4] + this.algorithmURL[5] + this.algorithmName;
  }

  getForecastingURL() {
    this.loadData();
    return this.urlForecastingJFrame = this.algorithmURL[2] + this.algorithmURL[1] + this.algorithmURL[3] + this.algorithmURL[4];
  }

  getDataSourceLength(): number {
    if (this.actualAnalysisAlgorithm.algorithmType === 0 && this.dataSourceDetails) {
      return this.dataSourceDetails.paginatorLength;
    } else if (this.actualAnalysisAlgorithm.algorithmType === 1 && this.forecastingDataSourceDetails) {
      return this.forecastingDataSourceDetails.paginatorLength;
    }
  }

  isData() {
    return this.dataSourceDetails && this.dataSourceDetails.filteredData && this.dataSourceDetails.filteredData.length > 0;
  }

  isForecastingData() {
    return this.forecastingDataSourceDetails && this.forecastingDataSourceDetails.filteredData && this.forecastingDataSourceDetails.filteredData.length > 0;
  }

  isLoading(): boolean {
    if (!this.dataSourceDetails || !this.forecastingDataSourceDetails) {
      return true;
    }
  }

  onTabClick(event: MatTabChangeEvent) {
    this.graphDataAvailable = false;
    this.algorithmName = event.tab.textLabel;
    this.algorithmType = this.getTranslatedAlgorithmName(event.tab.textLabel);
    if (this.actualAnalysisAlgorithm.algorithmType === 0) {
      this.dataSourceDetails = new MeterDetailsDataSource(this.positionDataStoreService, this.positionId, this.detail.idMeterPoint,
        this.paginator, this.sort, this.algorithmType);
      this.loadData();
      if (this.dataSourceDetails && this.dataSourceDetails.filteredData && this.dataSourceDetails.filteredData.length > 0) {
        this.changeData(this.dataSourceDetails.filteredData);
      }
      this.displayedColumnsDetails = ['date', 'temp', 'energy', 'percentage'];
      this.getURL();
    } else {
      this.forecastingDataSourceDetails = new ForecastingDetailsDataSource(this.positionDataStoreService, this.positionId, this.detail.idMeterPoint,
        this.paginator, this.sort);
      this.loadData();
      if (this.forecastingDataSourceDetails && this.forecastingDataSourceDetails.filteredData && this.forecastingDataSourceDetails.filteredData.length > 0) {
        this.changeData(this.forecastingDataSourceDetails.filteredData);
      }
      this.forecastingDisplayedColumnsDetails = ['energy', 'period'];
      this.getForecastingURL();
    }
  }

  changeData(data: any) {
    const size = data.length;
    data.sort((a, b) => a.timestamp - b.timestamp);
    this.analysisEvent.startDate = data[size - 1].timestamp;
  }

  isDataAvailable(): boolean {
    if (this.dataAvailable) {
      return true;
    }
  }

  isDataInAlgorithms(algorithm: IAnalysisAlgorithm) {
    if (this.isDataInAlgorithm) {
      // tslint:disable-next-line: max-line-length
      return this.isDataInAlgorithm.find(v => v.nameAlgorithm === algorithm.name && v.positionId === this.positionId && v.idMeterPoint === this.detail.idMeterPoint);
    }
  }

  getTranslatedAlgorithmName(name: string) {
    return this.title.get(name);
  }
}

