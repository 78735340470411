import { Component, OnInit, AfterViewInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';

import 'rxjs/add/operator/filter';

import 'rxjs/add/operator/mergeMap';
import { UploadInput } from '@ngx-uploader';

import { PositionDataStoreService } from '@shared/services/dataStore/positionDataStore.service';
import { Position, PositionEvent } from '@shared/models';
// Env
import { environment } from '@env/environment';
import { UploadLocalisation as loc } from '../upload.localisation';
import { PositionTransitions } from '@shared/types/modelTypes/positionTypes';
import { FileMeterData } from '@shared/models/appModels/fileMeasurementData.model';
import { FileMeterDataStoreService } from '@shared/services/dataStore/fileMeterDataDataStore.service';

import { CommonUploadComponent } from '../CommonUpload.class';
import { CommonAppDataService } from '@shared/services/commonAppData.service';
import { EventType, EventStatus } from '@shared/types';
import { Observable } from 'rxjs/Observable';
import { VeeStatusDataStoreService } from '@shared/services/dataStore/veeProcessDataStore.service';
import { INavigationContext } from '@shared/models/navigationCtx.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MeasurementMapperComponent } from './measurements-mapper/measurements-mapper.component';
import { typesLocalisation } from '@shared/types/localisation';
import { concatMap, debounceTime, map, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { InteractiveTutorialService } from '@shared/services/interactiveTutorialService.service';
import { MeasurementsUploadInterativeTutorialService } from './measurementsUploadInteractiveTutorialService.service';



@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,

  // tslint:disable-next-line:component-selector
  selector: 'sv-readings',
  templateUrl: 'measurements-upload.component.html',
  styleUrls: ['../upload.sass'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(.4, 0, .2, 1)')),
    ]),
  ],
})

export class MeasurementsUploadComponent extends CommonUploadComponent<FileMeterData> implements OnInit, OnDestroy, AfterViewInit {

  /* Labels definitions */
  readonly header = loc[environment.language].texts.measurement.componentTitle;
  buttonText: string = loc[environment.language].texts.measurement.uploadButton.ELSE;
  dataPreviewPositionId: number;
  dataPreviewFilePositionId: number;
  dataPreviewFileId: number;
  importedMeasurementsCount: number = 0;
  anomalyCount: number = 0;
  showDetails: boolean;

  navigationContext: INavigationContext;

  fileDropOptions = {};
  fileSelectOptions = {};
  fileInPosition = [];
  fileList: Array<FileMeterData> = [];
  /* current selections */
  currentSelection: {
    scheduleId?: number,
    positionId?: number,
  };

  readonly numberOfImportedMeasurements = loc[environment.language].texts.measurement.numberOfImportedMeasurements;
  readonly numberOfMeasurementsWithoutReadingValue = loc[environment.language].texts.measurement.numberOfMeasurementsWithoutReadingValue;
  readonly uploadButton = loc[environment.language].texts.measurement.uploadButton;
  readonly reuploadNotSupported = loc[environment.language].texts.measurement.reuploadNotSupported;
  readonly dataModelTxt = typesLocalisation.MeasurementDefinitionMapper[environment.language].texts;
  reuploadDisabled: boolean;
  veeRunPosition: boolean;
  countFileSubs: Subscription;
  uploadButtonTextSubs: Subscription;
  showTutorialSubscription: Subscription;

  constructor(
    private positionDataStoreService: PositionDataStoreService,
    private fileMeterDataStoreService: FileMeterDataStoreService,
    private veeStatusDataStoreService: VeeStatusDataStoreService,
    private router: Router,
    cdr: ChangeDetectorRef,
    cs: CommonAppDataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private interactiveTutorialService: InteractiveTutorialService,
    private measurUploadInterTutServ: MeasurementsUploadInterativeTutorialService
  ) {
    super(cs, false, cdr);
    this.navigationContext = {};
    this.currentSelection = { scheduleId: undefined, positionId: undefined };
    this.dataPreviewPositionId = undefined;
    this.dataPreviewFilePositionId = undefined;
    this.dataPreviewFileId = undefined;
    this.uploadExecutor = (body) =>
    this.fileMeterDataStoreService.addFileMeterData(body, this.currentSelection.positionId, { emitEvent: true });
    this.countHandler = () => this.fileMeterDataStoreService.getFileMeterDataListCount();
    this.countFileSubs = this.countFileSubject.subscribe((filesInQueue) => {
      // this is situation when error occurred during send process and user tries to drag another file omitting clicking reload button
      if (filesInQueue === 1 && this.uploadResults.error) {
        this.reuploadFileSelect(this.currentSelection.positionId);
      }
    });
  }

  getUploadResults() {
    return this.uploadResults;
  }

  resetComponentData() {
    this.navigationContext = {};
    this.dataPreviewPositionId = undefined;
    this.dataPreviewFilePositionId = undefined;
    this.dataPreviewFileId = undefined;
    this.uploadExecutor = (body) =>
            this.fileMeterDataStoreService.addFileMeterData(body, this.currentSelection.positionId, { emitEvent: true });
    this.countHandler = () => this.fileMeterDataStoreService.getFileMeterDataListCount();
  }

  ngAfterViewInit() {
    this.getFileList();
    this.cdr.detectChanges();
  }

  private startInteractiveTutorial() {
    const steps = this.measurUploadInterTutServ.getMeasurmeentsUploadMainComponentInteractiveTutorialSteps();
    this.interactiveTutorialService.startInteractiveTutorial(steps);
  }

  ngOnInit() {
    this.showTutorialSubscription = this.interactiveTutorialService.showTutorialSubject.pipe(debounceTime(1500)).subscribe(isActive => {
      if (this.interactiveTutorialService.getActiveComponent(this.route.routeConfig.path) && isActive) {
      this.startInteractiveTutorial();
      }
    });
  }

  changePreviewId(ctx: INavigationContext): void {
    if (this.dataPreviewPositionId !== ctx.position || this.showDetails) {
      this.dataPreviewPositionId = ctx.position;
    } else {
      this.dataPreviewPositionId = undefined;
    }
    this.dataPreviewFileId = ctx.file;
    this.showDetails = false;
  }

  changePreviewFileId(ctx: INavigationContext): void {
    if (this.dataPreviewFilePositionId !== ctx.position || !this.showDetails) {
      this.dataPreviewFilePositionId = ctx.position;
    } else {
      this.dataPreviewFilePositionId = undefined;
    }
    this.showDetails = true;

  }

  getFileList(): void {
      this.fileMeterDataStoreService.getFileMeterDataList({limit: 0, offset: 0})
        .subscribe((v: FileMeterData[]) => {
          this.fileList = v;
        });
  }

  previewIsVisible(): boolean {
    return this.dataPreviewPositionId !== undefined;
  }

  previewFileIsVisible(): boolean {
    return this.dataPreviewFilePositionId !== undefined;
  }

  onSuccesfullUpload() {
    let updatedPos;
    super.onSuccesfullUpload();
    this.positionDataStoreService.getPositionById(this.currentSelection.positionId).pipe(
      concatMap<Position, Observable<Position>>((pos: Position) => {
        /* Modify position status */
        pos = Position.positionStateMachine(pos, PositionTransitions.UPLOAD_FINISH);
        return this.positionDataStoreService.updatePosition(pos);
      }),
      concatMap<Position, Observable<FileMeterData[]>>((updatedPosition: Position) => {
        updatedPos = updatedPosition;
        return this.fileMeterDataStoreService.getFileMeterDataForPosition(updatedPosition.idPosition).pipe(tap(fileMeterData => {
          const last = fileMeterData[fileMeterData.length - 1];
          this.importedMeasurementsCount = last.quantity;
          this.anomalyCount = last.anomalies;
        }));
      })).subscribe(() => {
        this.getFileList();
        this.cdr.markForCheck();
      },
        (error) => {
          throw new Error(error);
        },
        () => { this.uploadResults.pending = false; });
  }

  startUpload(): void {
    this.importedMeasurementsCount = 0;
    this.anomalyCount = 0;
    const event: UploadInput = {
      type: 'uploadAll',
      uploadExecutor: (file) => this.fileMeterDataStoreService.addFileMeterData(
        file,
        this.currentSelection.positionId,
        { emitEvent: true }),
    };
    this.uploadResults.pending = true;
    this.uploadInput.emit(event);
  }

  ifValidSelect() {
    return (this.currentSelection.positionId && this.currentSelection.scheduleId);
  }

  getUploadButtonText(id: number): Observable<string> {
    return this.positionDataStoreService.getPositionById(id, false).pipe(
      map<Position, string>((p: Position) => {
        const events: PositionEvent[] = p.getEvents();
        if (events[EventType.UPLOAD].status === EventStatus.DONE) {
          this.reuploadDisabled = true;
          return this.uploadButton.ELSE;
        } else {
          this.reuploadDisabled = false;
          return this.uploadButton.ELSE;
        }
      }));
  }

  handleNavigation(event: INavigationContext) {
    const scheduleId: string = '' + event.schedule;
    const positionId: string = '' + event.position;
    this.router.navigate(['/data-validation'], { queryParams: { schedule: scheduleId, position: positionId } });
  }

  handleSelection(e: { idSchedule?: number, idPosition?: number }) {
    this.currentSelection.scheduleId = e.idSchedule;
    this.currentSelection.positionId = e.idPosition;
    if (typeof(this.currentSelection.positionId) !== 'undefined') {
    this.veeStatusDataStoreService.getVeeStateForPosition(this.currentSelection.positionId, true).subscribe(v => {
      if (v.stage === undefined) {
        this.veeRunPosition = false;
      } else {
        this.veeRunPosition = true;
      }
      if (this.currentSelection.positionId && this.currentSelection.scheduleId) {
        this.uploadButtonTextSubs = this.getUploadButtonText(this.currentSelection.positionId)
          .subscribe((label) => {
            this.buttonText = label;
            this.cdr.markForCheck();
          });
      }
    }, e => {
      this.veeRunPosition = false;
    });
    }
  }

  clearData() {
    this.uploadResults.error = false;
    this.uploadResults.errorCause = '';
    this.uploadResults.pending = false;
    this.uploadResults.success = false;
    this.importedMeasurementsCount = 0;
    this.anomalyCount = 0;

    this.resetComponentData();

    this.cdr.detectChanges();
  }

  reuploadFileSelect(idPos: number) {
    let url = this.router.url;
    let root = this.router.parseUrl(url).root.children;
    if (idPos === undefined) {
      try {
        this.clearData();
      } catch (e) {
        console.error(e);
      }
    } else {
      this.currentSelection.positionId = idPos;
      this.positionDataStoreService.getPositionById(idPos, false)
        .subscribe((p: Position) => {
          this.currentSelection.scheduleId = p.idSchedule;
          url = this.router.url;
          root = this.router.parseUrl(url).root.children;
          try {
            this.clearData();
          } catch (e) {
            console.error(e);
           }
        });
    }
  }

  onFileSelected(event) {
    const fileExtension = event.target.files[0].name.split('.').pop();
    if (fileExtension === 'csv') {
      const reader = new FileReader();
      let fileValueTxt: string | ArrayBuffer;
      reader.onload = (e) => {
        const text = reader.result;
        fileValueTxt = text;
        let dialogRef: MatDialogRef<MeasurementMapperComponent>;
        dialogRef = this.dialog.open(MeasurementMapperComponent, {
          panelClass: 'dialog-overlay',
          data: {
            csvFileTxt: fileValueTxt
          },
          autoFocus: false,
          minWidth: '600px !important',
        });
        dialogRef.afterClosed().subscribe(
          (data) => {
          }
        );
      };
      reader.readAsText(event.target.files[0]);
    } else {
      const snackBarRef = this.snackBar.open(this.dataModelTxt.WRONG_FILE_FORMAT);
      snackBarRef._dismissAfter(3000);
    }
  }

  ngOnDestroy() {
    if (this.countFileSubs) {
    this.countFileSubs.unsubscribe();
    }
    if (this.uploadButtonTextSubs) {
      this.uploadButtonTextSubs.unsubscribe();
    }
    if (this.showTutorialSubscription) {
      this.showTutorialSubscription.unsubscribe();
    }
   }
}
