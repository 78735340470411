/* Angular imports */
import { Component, OnInit, Output, EventEmitter, OnDestroy, AfterViewInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

// application classes & types
import { TimelineEntry, CardType } from "@shared/models/timelineEntry.model";
import { TodayTimelineEntry } from "@shared/models/timelineEntryToday";
import { Schedule, ISchedule } from "@shared/models/appModels/schedule.model";
import { Position, IPosition } from "@shared/models/appModels/position.model";

/* services */
import { TimelineSupportService } from "./timelineSupport.service";
import { ScheduleDataStoreService } from "@shared/services/dataStore/scheduleDataStore.service";

import { ScheduleDialogsService } from "../schedule/service/schedule-dialog.service";
import {
  concatMap,
  tap,
  share,
  map,
  startWith,
  shareReplay,
  distinctUntilChanged,
  filter,
  pluck,
  debounceTime,
  withLatestFrom,
  catchError,
  finalize,
  delay,
  switchMap,
} from "rxjs/operators";
import {
  merge,
  fromEvent,
  combineLatest,
  Observable,
  of,
  Subject,
  OperatorFunction,
  iif,
  Subscription,
  concat,
} from "rxjs";
import { EventType, EventStatus, DateOffsets } from "@shared/types";
import * as moment from "moment";
import { includes, pipe, pluck as rpluck } from "ramda";
import { InteractiveTutorialService } from "@shared/services/interactiveTutorialService.service";
import { TimelineInteractiveTutorialService } from "./timelineInteractiveTutorialService.service";

type FormConstants = "new";
enum SortOrder {
  ASC,
  DESC,
}

function sortTimelineEvents(
  unsorted: TimelineEntry[],
  sortOrder: SortOrder = SortOrder.ASC
) {
  unsorted.sort((a: TimelineEntry, b: TimelineEntry) => {
    let res = a.dueDate.diff(b.dueDate);
    if (res === 0 && a.cardType === CardType.TODAY) {
      res = -1;
    } else if (res === 0 && b.cardType === CardType.TODAY) {
      res = 1;
    }
    return sortOrder == SortOrder.ASC ? res : -res;
  });
}

function createMockSchedule(): Schedule {
  /* Generate mock entries for empty schedule presentation - this is visualisation only */
  const events = [];
  const types = [
    EventType.UPLOAD,
    EventType.VALIDATION,
    EventType.EXPORT,
    EventType.ANALYSIS,
  ];
  for (let i = 0; i < types.length; ++i) {
    events.push({
      date: +moment.utc().add(DateOffsets[i], "day"),
      typeOf: types[i],
      status: EventStatus.TODO,
    });
  }
  const position = new Position({
    events: events,
    orderInSchedule: 1,
  } as IPosition);
  return new Schedule({ closed: false } as ISchedule, [position]);
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: "sv-timeline",
  templateUrl: "./timeline.component.html",
  styleUrls: ["./timeline.component.sass"],
})
export class TimelineComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output("currentSchedule")
  currentSchedule: EventEmitter<Schedule> = new EventEmitter();

  scheduleLoading$: Subject<boolean> = new Subject();

  schedules$: Observable<Schedule[]>;
  hasNoSchedules$: Observable<boolean>;
  hasNoPositions$: Observable<boolean>;
  selectedSchedule$: Observable<Schedule>;
  overlay$: Observable<boolean>;
  entries$: Observable<TimelineEntry[][]>;
  sortOrder$: Observable<SortOrder>;
  showTutorialSubscription: Subscription;

  facings: string[] = ["right", "left"];
  timelineLeft: number = 0;
  noSchedules: boolean;

  scheduleSelector: FormControl;

  constructor(
    private timelineService: TimelineSupportService,
    private scheduleDataStoreService: ScheduleDataStoreService,
    private router: Router,
    private route: ActivatedRoute,
    private scheduleDialog: ScheduleDialogsService,
    private interactiveTutorialService: InteractiveTutorialService,
    private timelineInteractiveTutorialService: TimelineInteractiveTutorialService
  ) {}

  ngOnInit() {
    this.scheduleSelector = new FormControl();
    const dummySchedule = createMockSchedule();

    this.selectedSchedule$ = this.scheduleSelector.valueChanges.pipe(
      distinctUntilChanged(),
      tap(
        async (selection: Schedule | FormConstants) =>
          await this.handleSelection(selection)
      ),
      filter((selection) => selection instanceof Schedule),
      map((selection) => selection as Schedule),
      concatMap((schedule) => this.fetchSchedulePositions(schedule)),
      tap((schedule) => this.currentSchedule.emit(schedule)),
      share()
    );

    this.sortOrder$ = fromEvent(window, "resize").pipe(
      debounceTime(500),
      pluck("target", "innerWidth"),
      distinctUntilChanged(),
      startWith(window.innerWidth),
      map((width) => (width >= 1224 ? SortOrder.ASC : SortOrder.DESC)),
      distinctUntilChanged()
    );

    const scheduleName$ = this.scheduleSelector.valueChanges.pipe(
      debounceTime(500),
      filter(
        (value) =>
          !(value instanceof Schedule || ["new", undefined].includes(value as FormConstants))
      ),
      distinctUntilChanged()
    );

    const schedules$ = merge(
      scheduleName$,
      this.scheduleDataStoreService.getChangeObservable()
    ).pipe(this.fetchSchedules());

    const initialSchedule$ = of(undefined).pipe(
      this.fetchSchedules(true),
      tap((schedules) => this.selectInitialSchedule(schedules)),
      share()
    );

    this.schedules$ = merge(schedules$, initialSchedule$).pipe(
      catchError((_) => of([])),
      share()
    );
    this.hasNoSchedules$ = merge(
      initialSchedule$,
      this.scheduleDataStoreService.getChangeObservable()
    ).pipe(
      switchMap(() => this.scheduleDataStoreService.getSchedulesCount()),
      map((count) => count === 0),
      catchError((_) => of(true)),
      tap(v => this.timelineInteractiveTutorialService.hasNoSchedulesSubject.next(v)),
      share()
    );

    this.hasNoPositions$ = this.selectedSchedule$.pipe(
      pluck("positions"),
      map((positions) => (positions?.length ?? 0) === 0),
      distinctUntilChanged(),
      tap(v => this.timelineInteractiveTutorialService.hasNoPositionsSubject.next(v)),
      shareReplay()
    );

    const rawEntries$ = this.selectedSchedule$.pipe(
      startWith(dummySchedule),
      concatMap((schedule) =>
        this.timelineService.convertPositionsToTimelineEvents(schedule)
      )
    );

    this.overlay$ = concat(this.hasNoPositions$, this.hasNoSchedules$).pipe(
      startWith(true),
      distinctUntilChanged()
    );

    this.entries$ = combineLatest([rawEntries$, this.sortOrder$]).pipe(
      withLatestFrom(
        this.hasNoPositions$.pipe(startWith(false)),
        this.selectedSchedule$.pipe(startWith(dummySchedule))
      ),
      filter(([_1, hasNoPositions, ..._]) => !hasNoPositions),
      map(([[entries, sortOrder], _, schedule]) => {
        entries = entries.slice();
        entries.push(TodayTimelineEntry.generateToday(schedule));
        sortTimelineEvents(entries, sortOrder);
        return this.timelineService.stackTimelineEvents(entries);
      })
    );
  }

  ngAfterViewInit(){
    this.showTutorialSubscription = this.interactiveTutorialService.showTutorialSubject.pipe(debounceTime(1500),switchMap(isActive => {
      if (this.interactiveTutorialService.getActiveComponent(this.route.routeConfig.path) && isActive) {
        return this.startInteractiveTutorial();
      } else {
        return of([]);
      }
    })).subscribe(steps => {
      if(steps.length > 0){
      this.interactiveTutorialService.startInteractiveTutorial(steps);
      }
    });
  }

  startInteractiveTutorial(): Observable<unknown[]>{
    return this.timelineInteractiveTutorialService.getTimelineInteractiveTutorialSteps().pipe(debounceTime(1500));
  }

  private fetchSchedules(
    fetchInitialFlag: boolean = false
  ): OperatorFunction<any, Schedule[]> {
    return pipe(
      delay(0),
      concatMap(() => {
        this.scheduleLoading$.next(true);
        let name = this.scheduleSelector.value;
        if (name === null) {
          name = "";
        } else if (name instanceof Schedule) {
          name = name.name;
        } else {
          name = "%" + name;
        }
        return this.scheduleDataStoreService
          .getAllSchedulesNoPositions(
            {
              limit: 8,
              offset: 0,
            },
            true,
            { name: name, sort: "DESC" }
          )
          .pipe(
            delay(300),
            finalize(() => this.scheduleLoading$.next(false))
          );
      }),
      map((schedules) => schedules.filter((schedule) => !schedule.closed)),
      concatMap((schedules) =>
        iif(
          () => fetchInitialFlag && !this.containsInitialSchedule(schedules),
          this.scheduleDataStoreService
            .getScheduleById(this.getInitialScheduleId())
            .pipe(
              map((schedule) => {
                schedules.splice(0, 0, schedule);
                return schedules;
              }),
              catchError(() => of(schedules))
            ),
          of(schedules)
        )
      )
    );
  }

  private containsInitialSchedule(schedules: Schedule[]): boolean {
    const selectedScheduleId = this.getInitialScheduleId();
    if (Number.isNaN(selectedScheduleId)) {
      return true;
    }

    return includes(selectedScheduleId, rpluck("idSchedule", schedules));
  }

  private getInitialScheduleId(): number {
    return +this.route.snapshot.queryParams["schedule"];
  }

  private fetchSchedulePositions(schedule: Schedule): Observable<Schedule> {
    return this.scheduleDataStoreService
      .getSchedulePositions(schedule.idSchedule)
      .pipe(
        map((positions) => {
          schedule.positions = positions;
          return schedule;
        })
      );
  }

  private selectInitialSchedule(schedules: Schedule[]): void {
    const scheduleId = this.getInitialScheduleId();
    let selectedSchedule = this.scheduleSelector.value;
    for (const schedule of schedules) {
      if (
        (scheduleId != undefined && schedule?.idSchedule === scheduleId) ||
        selectedSchedule?.idSchedule === schedule?.idSchedule
      ) {
        selectedSchedule = schedule;
        break;
      }
    }

    if (!selectedSchedule && schedules?.length > 0) {
      selectedSchedule = schedules[0];
    }

    if (selectedSchedule) {
      this.scheduleSelector.setValue(selectedSchedule);
    }
  }

  private async handleSelection(value: FormConstants | Schedule) {
    if ((value as FormConstants) === "new") {
      await this.handleNewScheduleSelection();
    } else {
      this.handleScheduleSelection(value as Schedule);
    }
  }

  private async handleNewScheduleSelection() {
    await this.scheduleDialog
      .invokeCreateScheduleModal()
      .pipe(
        filter((schedule) => schedule !== undefined),
        concatMap((schedule) =>
          this.scheduleDataStoreService.addSchedule(schedule[0])
        )
      )
      .toPromise()
      .then((schedule) => this.scheduleSelector.setValue(schedule));
  }

  private handleScheduleSelection(schedule: Schedule): void {
    if (!schedule || schedule.idSchedule === undefined) {
      return;
    }
    this.router.navigate([], {
      queryParams: { schedule: schedule.idSchedule },
    });
  }

  scheduleName(controlValue: Schedule | FormConstants): string {
    if ((controlValue as FormConstants) === "new") {
      return null;
    }

    return (controlValue as Schedule)?.getScheduleName();
  }

  onCreateScheduleEvents(): void {
    const controlValue = this.scheduleSelector.value;
    if ((controlValue as FormConstants) !== "new") {
      this.router.navigate(["/schedule"], {
        queryParams: {
          position: this.route.snapshot.queryParams["position"],
        },
      });
      return;
    }

    this.router.navigate(["/schedule"]);
  }

  ngOnDestroy(): void {
    if(this.showTutorialSubscription){
      this.showTutorialSubscription.unsubscribe();
    }
  }

  // TODO: slider timeline
  onPrev() {
    if (this.timelineLeft < 0) {
      this.timelineLeft += 100;
    }
  }

  onNext() {
    this.timelineLeft -= 100;
  }
}
