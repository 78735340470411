import { RestEnumMpapper } from '@shared/models/RestSupport/restEnumMapper.model';
import { EventType, EventStatus, IGetInterfaceType } from '@shared/types';

export interface IPositionEvent {
  date: number;
  typeOf: EventType | string;
  status: EventStatus | string;
}

export class PositionEvent implements IPositionEvent, IGetInterfaceType<IPositionEvent, PositionEvent> {

    /* Mappers to map enum back end forth to string <-> number */
    private static typeOfEnumMapper: RestEnumMpapper<typeof EventType> = new RestEnumMpapper<typeof EventType>();
    private static statusEnumMapper: RestEnumMpapper<typeof EventStatus> = new RestEnumMpapper<typeof EventStatus>();

  date: number;
  typeOf: EventType;
  status: EventStatus;
  positionIndex?: number;

  constructor(iPosEvent: IPositionEvent, posRefNumber: number) {
    this.date = (iPosEvent && typeof(iPosEvent.date) !== 'undefined') ? iPosEvent.date : undefined;
    this.typeOf = (iPosEvent && typeof(iPosEvent.typeOf) !== 'undefined') ?
                    PositionEvent.typeOfEnumMapper.getEnumAsNumber(EventType, iPosEvent.typeOf) as EventType : undefined;
    this.status = (iPosEvent && typeof(iPosEvent.status) !== 'undefined') ?
                    PositionEvent.statusEnumMapper.getEnumAsNumber(EventStatus, iPosEvent.status) as EventStatus : undefined;
    this.positionIndex = posRefNumber || undefined;
  }

  setPositionIndex(idx: number) {
    this.positionIndex = idx;
  }

  getPositionIndex(): number {
    return this.positionIndex;
  }

  getInterface(webInterface: boolean = true): IPositionEvent {
    return {
      date: this.date,
      typeOf: PositionEvent.typeOfEnumMapper.getEnumAsString(EventType, this.typeOf),
      status: PositionEvent.statusEnumMapper.getEnumAsString(EventStatus, this.status),
    };
  }

}
