import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GrafanaDashboardComponent } from './grafana-dashboard.component';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        MatIconModule,
        MatButtonModule,
    ],
    declarations: [
        GrafanaDashboardComponent
    ],
    exports: [
        GrafanaDashboardComponent
    ],
  })
  export class GrafanaModule { }