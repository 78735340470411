import { Component, Input, AfterViewInit } from "@angular/core";
import { TableHost } from "@shared/types";
import { Observable } from "rxjs/Observable";
import { MatPaginator } from "@angular/material/paginator";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { PositionEvent } from "@shared/models";
import { PositionEventsDetailsDataSource } from "./datasource/eventDetailsDataSource";
import { Position } from "@shared/models/appModels/position.model";
import { CommonAppDataService } from "@shared/services/commonAppData.service";
import { ScheduleSupportService } from "../../service/scheduleSupport.service";

@Component({
  selector: "sv-event-details",
  templateUrl: "./event-details.component.html",
  styleUrls: ["./event-details.component.sass"],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class EventDetailsComponent implements AfterViewInit, TableHost<PositionEvent> {


  dataFetched: boolean;
  dataLoading: boolean;
  dataLength: number;

  @Input() inputPosition: Position;
  dataSource: PositionEventsDetailsDataSource;
  displayedColumnsDetails: string[] = ['eventName', 'eventDetails', 'eventState'];
  constructor(
    private cs: CommonAppDataService,
    private scheduleSupportService: ScheduleSupportService
  ) {
    this.dataSource = new PositionEventsDetailsDataSource();
  }
  ngAfterViewInit(): void {
    this.loadData()
      .delay(1) /* FIXME: dirty hack to avoid ExpressionChangedAfterItHasBeenCheckedError */
      .subscribe((pagedData: PositionEvent[]) => {
        this.dataSource.setNewData(pagedData);
      });
  }

  loadData(
    limit?: number,
    offset?: number,
    otherParams?: { [name: string]: any }
  ): Observable<PositionEvent[]> {
    return Observable.of(this.inputPosition.getEvents());
  }

  getNameForEvent(event: PositionEvent): string {
    const eventTypeName = this.scheduleSupportService.getEventTypeName(event.typeOf);
    return $localize`:position event caption|Position event caption@@schedules/position event caption:${eventTypeName}:EVENT_TYPE_NAME: #${
      this.inputPosition.orderInSchedule
    }:POSITION_INDEX:`;
  }

  getNameForEventStatus(event: PositionEvent): string {
    return this.scheduleSupportService.getEventStatusName(event.status);
  }

  onPaginatorEvents(paginator: MatPaginator): Observable<PositionEvent[]> {
    throw new Error("Method not implemented.");
  }

  onExternalDataChangeEvent(
    changeInductors: Observable<any>[]
  ): Observable<PositionEvent[]> {
    throw new Error("Method not implemented.");
  }

  onFilterEvents(): Observable<PositionEvent[]> {
    throw new Error("Method not implemented.");
  }

  onClearFilters(): Observable<PositionEvent[]> {
    throw new Error("Method not implemented.");
  }
}
