<div class="card progress">
	<ng-container *ngIf="showProcessProgression === true">
		<div class="col" *ngFor="let step of analysisProcessSteps; index as idx">
			<div class="space">
				<i *ngIf="step.statusEnum === AnalysisProcessStatusEnum.DONE"
					class="material-icons notranslate">check</i>
				<i *ngIf="step.statusEnum === AnalysisProcessStatusEnum.FAILED"
					class="material-icons notranslate">error</i>
				<mat-spinner *ngIf="step.statusEnum === AnalysisProcessStatusEnum.IN_PROGRESS" [strokeWidth]="3"
					[diameter]="20" class="notranslate"></mat-spinner>
			</div>
			<p *ngIf="step.statusEnum !== AnalysisProcessStatusEnum.FAILED; else alternativeBlock">
				{{step.stage + ': ' + step.status}}
			</p>
			<ng-template #alternativeBlock>
				<p>
					{{step.stage + ': ' + step.status +' - ' + dataMissing}}
				</p>
			</ng-template>
		</div>
	</ng-container>
</div>
<div class="btn-box right-btn">
	<button *ngIf="analysisButtonState.isVisible && (firstAnalysis || analysisProgress)"
		[disabled]="analysisButtonState.isDisabled || !showProcessProgression" (click)="onAnalysisAction(true)"
		mat-raised-button color="accent" class="btn">{{analysisButtonState.text}}
	</button>
	<button (click)="newAnalysis()" [disabled]="analysisButtonState.isDisabled || !showProcessProgression && !analysisD"
		mat-raised-button color="accent" class="btn">
		{{buttonLabel}}
	</button>
	<button *ngIf="analysisDone" [disabled]="(listEnabledAlgorithm.length === 0)" (click)="showSummary()"
		[disabled]="analysisProgress || analysisFailed" mat-raised-button color="accent" class="btn">
		{{results}}
	</button>
</div>