<div class="container timeline" [ngClass]="{ 'timeline-overlay': (overlay$ | async) }">

	<ng-template #loading>
		<div class="loading">
			<mat-spinner strokeWidth="3" diameter="20"></mat-spinner>
		</div>
	</ng-template>

	<form *ngIf="schedules$ | async as schedules; else loading" class="row">
		<mat-form-field id="scheduleSelect" class="col form-group">
			<i matPrefix class="material-icons notranslate">search</i>
			<input type="text" placeholder="Schedule"
				i18n-placeholder="@@application/schedule filter name"
				[formControl]="scheduleSelector" matInput [matAutocomplete]="auto">
			<mat-autocomplete #auto="matAutocomplete" [displayWith]="scheduleName">
				<mat-option disabled *ngIf="scheduleLoading$ | async" class="small">
					<span matPrefix style="vertical-align: middle">
						<mat-spinner diameter="14" style="display: inline-block; margin-right: 5px;"></mat-spinner>
					</span>
					<span i18n="@@timeline/loading schedules list option name">
						Loading suggestions...
					</span>
				</mat-option>
				<mat-option *ngFor="let schedule of schedules" [value]="schedule">{{ schedule.name }}</mat-option>
				<mat-option disabled *ngIf="!(scheduleLoading$ | async) && schedules.length === 0" 
					class="small" i18n="@@timeline/empty schedules list option name">No matching schedules found.</mat-option>
				<mat-option value="new" i18n="@@timeline/add new schedule option name">
					<i class="material-icons inline-material notranslate">add</i>Add new schedule</mat-option>
			</mat-autocomplete>
		</mat-form-field>
	</form>

	<div *ngIf="overlay$ | async" class="add-new-box">
		<a id="svAddPositionBtn" *ngIf="hasNoPositions$ | async" (click)="onCreateScheduleEvents()"
			mat-raised-button color="accent" class="btn" 
			i18n="@@timeline/add new position button label"><i class="material-icons">add</i>Add new position</a>
		<a id="svAddSchedulesBtn" *ngIf="hasNoSchedules$ | async" (click)="onCreateScheduleEvents()" 
			mat-raised-button color="accent" class="btn" i18n="@@timeline/add new schedule button label"><i class="material-icons">add</i>Add new schedule</a>
	</div>

	<div id="svTimeline" *ngIf="(entries$ | async) as entries" class="timeline-nav">
		<ul [style.margin-left]="timelineLeft+'px'">
			<li *ngFor="let entry of entries; let i = index">
				<sv-timeline-card [entryArray]="entry" [facing]="facings[i % 2]">
				</sv-timeline-card>
			</li>
		</ul>

		<div *ngIf="entries.length > 5 || timelineLeft < 0" class="navigation">
			<div class="prev" [class.active]="timelineLeft < 0" (click)="onPrev()">
				<i class="material-icons notranslate">chevron_left</i>
			</div>

			<div class="next" [class.active]="timelineLeft <= 0" (click)="onNext()">
				<i class="material-icons notranslate">chevron_right</i>
			</div>
		</div>
	</div>

</div>
