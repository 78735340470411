import { Component, OnInit } from "@angular/core";
import { environment } from "@env/environment";

@Component({
  selector: "sv-nodes-preview",
  templateUrl: "./nodes-preview.component.html",
  styleUrls: ["./nodes-preview.component.sass"],
})
export class NodesPreviewComponent implements OnInit {
  iframeUrl = `${environment.backendPath}grafana/d/vZ43AdxGk/node-preview?orgId=1&theme=light&kiosk&autofitpanels`;

  constructor() {}

  ngOnInit(): void {}
}
