import { IGetInterfaceType } from '@shared/types/applicationTypes';
import { EventType } from '@shared/types/modelTypes/eventType';
import { RestEnumMpapper } from '@shared/models/RestSupport/restEnumMapper.model';

export interface IPositionAction {
  idPositionAction: number;
  eventType: EventType | string;
  idPosition: number;
  count: number;
  beginDate: number;
  endDate: number;
}

export class PositionAction implements IPositionAction, IGetInterfaceType<IPositionAction, PositionAction> {
  private static typeOfEnumMapper: RestEnumMpapper<typeof EventType> = new RestEnumMpapper<typeof EventType>();

  idPositionAction: number;
  eventType: EventType;
  idPosition: number;
  count: number;
  beginDate: number;
  endDate: number;

  constructor(iPa: IPositionAction) {
    if (!iPa) { throw new TypeError('PositionAction initialization: params is undefined !'); }

    this.idPositionAction = (typeof (iPa.idPositionAction) !== 'undefined') ? iPa.idPositionAction : undefined;
    // this.eventType =      (iPa && typeof(iPa.eventType) !== 'undefined')?  iPa.eventType as EventType : undefined
    this.eventType = (typeof (iPa.eventType) !== 'undefined') ? PositionAction.typeOfEnumMapper.getEnumAsNumber(EventType, iPa.eventType) : undefined;
    this.idPosition = (typeof (iPa.idPosition) !== 'undefined') ? iPa.idPosition : undefined;
    this.count = (typeof (iPa.count) !== 'undefined') ? iPa.count : undefined;
    this.beginDate = (typeof (iPa.beginDate) !== 'undefined') ? iPa.beginDate : undefined;
    this.endDate = (typeof (iPa.endDate) !== 'undefined') ? iPa.endDate : undefined;
  }

  getInterface(webInterface: boolean = true): IPositionAction {
    return {
      idPosition: this.idPosition,
      beginDate: this.beginDate,
      count: this.count,
      endDate: this.endDate,
      eventType: PositionAction.typeOfEnumMapper.getEnumAsString(EventType, this.eventType),
      idPositionAction: this.idPositionAction
    };
  }


}
