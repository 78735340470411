import { Injectable } from '@angular/core';
import { MeasurementDataModel } from '@shared/models/appModels/measurementDataModel.model';

@Injectable({providedIn: 'root'})
export class UnitHelper {

    public  getElementUnit(name: string, measurementsDefinitions: MeasurementDataModel[]): MeasurementDataModel | string {
        let measDef: MeasurementDataModel;
        if (measurementsDefinitions) {
         measDef = measurementsDefinitions.find((element) => element.name === name);
        }
        if (measDef) {
          return measDef.unit;
        } else {
          return '';
        }
      }
}