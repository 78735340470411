import 'rxjs/add/observable/merge';

import { TableDataSource } from '@shared/models/tableDataSource.model';
import { MeasurementData, IMeasurementData } from '@shared/models';

export class MeasurementDataTabbed extends MeasurementData {
  constructor(iIn: IMeasurementData) {
    super(iIn);
    this.rowId = iIn.idMeasurementData;
  }

  detailRow: boolean;
  rowId: string;
}

export class MeterDataSource extends TableDataSource<MeasurementData, MeasurementDataTabbed> {

  private setParams(detailRow: boolean, measurementData: MeasurementData, baseMesurement: MeasurementData) {
    const element: MeasurementDataTabbed = new MeasurementDataTabbed(measurementData);
    element.detailRow = detailRow;
    element.meterDataRef = baseMesurement.meterDataRef;
    element.meterPointRef = baseMesurement.meterPointRef;
    element.idMeterPoint = baseMesurement.idMeterPoint;
    element.idEstimationRule = baseMesurement.idEstimationRule;
    element.measurementValues = baseMesurement.getMeasurmementData();
    return element;
  }

  protected renderData(data: MeasurementData[]): MeasurementDataTabbed[] {
    const rows = [];
    data.forEach((baseMesurement, idx) => {
      const topElement: MeasurementDataTabbed = this.setParams(false, baseMesurement, baseMesurement);
      const detailElement: MeasurementDataTabbed = this.setParams(true, topElement, baseMesurement);
      rows.push(topElement, detailElement);
    });
    return rows;
  }
}
