import { TableDataSource } from '@shared/models/tableDataSource.model';
import { TemperatureData } from '@shared/models/appModels/temperatureData.model';


export class TemperatureDataSource extends TableDataSource<TemperatureData, TemperatureData> {
  constructor() { super(); }

  protected renderData(data: TemperatureData[]): TemperatureData[] {
    return data;
  }
}
