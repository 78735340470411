<div class="container">
	<h1 i18n="export panel title|The title of export section@@export/export panel title">Data Export</h1>

	<sv-select (selectionEvent)="handleSchedulePositionSelection($event)"></sv-select>

	<div class="card table-filter">
		<div class="card-header input-filter" *ngIf="exportButtonVisible">
			<form [formGroup]="selectDate">
				<div class="row">
					<mat-form-field id="startDate" class="col">
						<input matInput [matDatepicker]="startDatePicker" placeholder="Start date"
							i18n-placeholder="@@export/start date placeholder" formControlName="from">
						<mat-datepicker #startDatePicker></mat-datepicker>
						<mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
					</mat-form-field>

					<mat-form-field id="endDate" class="col">
						<input matInput [matDatepicker]="endDatePicker" placeholder="End date"
							i18n-placeholder="@@export/end date placeholder" formControlName="to">
						<mat-datepicker #endDatePicker></mat-datepicker>
						<mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
					</mat-form-field>
				</div>
			</form>
			<div class="elements-on-right">
				<mat-radio-group *ngIf="dataOnPositionAvailable" [(ngModel)]="selectedExportOption" aria-label="Select an option" (change)="selectedExportChange($event)">
					<mat-radio-button id="basicExport" checked value="GenerateCsvFile" i18n="@@export/custom billings modal/export measurements">Export measurements</mat-radio-button>
					<!-- <mat-radio-button value="GenerateCustomBilling" i18n="@@export/generate custom billings button label">Generate custom billing data</mat-radio-button> -->
				</mat-radio-group>

				<div matTooltip="Freeze the data first." i18n-matTooltip="freeze data tooltip|A button tooltip that suggests freezing the analyzed data@@export/freeze data tooltip" [matTooltipDisabled]="!exportDisabled"
				matTooltipPosition="below" matTooltipShowDelay="500">
				<button id="exportBillingData" mat-raised-button color="accent" class="btn" [disabled]="exportDisabled"
					(click)="onDownloadBillingFile()" i18n="generate billing data button label|The label of a button that triggers generation of billing data@@export/export billings button label">
					Export billing data
				</button>
				</div>
			</div>

		</div>
		<div id="wizard" *ngIf="selectedExportOption==='GenerateCsvFile' && dataOnPositionAvailable"> 
			  <mat-horizontal-stepper linear #stepper="matHorizontalStepper">
				<mat-step [completed]="firstStepCompletedBoolean" errorMessage="MeterPoints are required!">
					<ng-template matStepLabel i18n="@@export/select meter points">Select meter points</ng-template>
				  <sv-custom-csv-data (firstStepCompleted)="firstStepCompleted($event)" (selectedMeterPointsEventEmitter)="selectedMeterPoints($event)" [idPosition]="selectedPosition" [stepper]="stepper"></sv-custom-csv-data>
				</mat-step>
				<mat-step [completed]="secondStepCompletedBoolean">
					<ng-template matStepLabel i18n="@@export/select variables">Select variables</ng-template>
				  <sv-custom-csv-data-next-step (secondStepCompleted)="secondStepCompleted($event)" (selectedVariablesEventEmitter)="selectedVariables($event)" [data]="selectedMeterPointsTab" [stepper]="stepper"></sv-custom-csv-data-next-step>
				</mat-step>
				<mat-step>
					<ng-template matStepLabel i18n="@@export/custom billings modal/summary">Summary</ng-template>
				  <sv-custom-csv-data-final [from]="from" [to]="to" [selectedPosition]="selectedPosition" [variables]="selectedVariablesTab" [meterPoints]="selectedMeterPointsTab"></sv-custom-csv-data-final>
				</mat-step>
			  </mat-horizontal-stepper>
		</div>
		<div *ngIf="selectedExportOption==='GenerateCustomBilling' && !exportDisabled"> 
			<mat-horizontal-stepper linear #stepper="matHorizontalStepper">
			  <mat-step [completed]="firstStepCompletedBoolean">
				<ng-template matStepLabel i18n="@@export/select columns">Select columns</ng-template>
				<sv-custom-billing-file (displayHeadersInCsvFile)="displayHeadersInCsvFile($event)" (selectedColumnsInCsvFile)="selectedColumnsInCsvFile($event)" (firstStepCompleted)="firstStepCompleted($event)" [stepper]="stepper"></sv-custom-billing-file>
			  </mat-step>
			  <mat-step >
				  <ng-template matStepLabel i18n="@@export/custom billings modal/summary">Summary</ng-template>
				  <sv-custom-billing-file-final  [from]="from" [to]="to" [selectedColumns]="selectedColumnsToCustomBilling" [displayHeadersInCsv]="displayHeadersInCsv"></sv-custom-billing-file-final>
			  </mat-step>
			</mat-horizontal-stepper>
	  </div>
	</div>