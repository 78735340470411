import { IApiEndpointConfig, GenericHttpParams } from '@shared/types/http/httpType';
import { MeasurementData } from '@shared/models/appModels/measurementData.model';

export const MeasurementDataApiConf: IApiEndpointConfig = {
  rootApiPath: 'measurementdata/',
  paths: [
    {/* 1 */
      swaggerId: 'getMeasurementDataList',
      url: '',
      pathParams: [],
      responseIsArray: true
    },
    {/* 2 */
      swaggerId: 'postMeasurementData',
      url: '',
      pathParams: [],
      responseIsArray: false
    },
    {/* 4 */
      swaggerId: 'putMeasurementDataById',
      url: '',
      pathParams: [],
      responseIsArray: false
    },
    {/* 5 */
      swaggerId: 'deleteMeasurementDataById',
      url: 'id/{idMeasurementData}',
      pathParams: [{ name: 'idMeasurementData', pattern: '{idMeasurementData}' }],
      responseIsArray: false
    },
    {/* 6 */
      swaggerId: 'exportMeasurements',
      url: 'export',
      pathParams: [],
      responseIsArray: false
    }
  ]
};

export interface MeasurementDataHttpParamG extends GenericHttpParams<MeasurementData> {
  queryParams?: {
    /* Required */
    limit?: string;
    offset?: string;
    /* Optional */
    id?: string[];
    from?: string;
    to?: string;
    meterId?: string;
    svUtilityType?: string;
    idPosition?: string;
    invalid?: string;
  };
  path?: {
    idMeasurementData?: string
  };
}
