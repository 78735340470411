export const SchedulePositionSelectorLocalisation = [
  {
    lang: 'ENG',
    texts: {
      control: {
        creationText: { SCHEDULE: 'Create new schedule', POSITION: 'Create new position' },
        emptyText: { SCHEDULE: 'No schedules...', POSITION: 'No positions...' },
        placeholder: { SCHEDULE: 'Schedules', POSITION: 'Positions' }
      }
    },
  },
  {
    lang: 'GER',
    texts: {
      control: {
        creationText: { SCHEDULE: 'Neuen Zeitplan erstellen', POSITION: 'Neuen Eintrag anlegen' },
        emptyText: { SCHEDULE: 'Keine Zeitpläne...', POSITION: 'Keine Einträge...' },
        placeholder: { SCHEDULE: 'Zeitplan', POSITION: 'Eintrag' }
      }
    },
  },
  {
    lang: 'PL',
    texts: {
      control: {
        creationText: { SCHEDULE: 'Utwórz nowy harmonogram', POSITION: 'Utwórz nową pozycję' },
        emptyText: { SCHEDULE: 'Brak harmonogramów...', POSITION: 'Brak pozycji...' },
        placeholder: { SCHEDULE: 'Harmonogramy', POSITION: 'Pozycje' }
      }
    },
  }
];

