<form [formGroup]="scheduleForm" (ngSubmit)="onSubmitNewSchedule()" novalidate>
	<div class="container-form">
		<div class="row">
			<mat-form-field id="scheduleName" class="col">
					<input matInput placeholder="Name" i18n-placeholder="name placeholder|A placeholder for schedule name input control@@schedules/schedule name placeholder" formControlName="scheduleNameControl"
					name="positionName">
				<span matSuffix i18n="schedule number|The number of a schedule@@schedules/schedule number">#{{nextScheduleNumber || 1 //i18n(ph='SCHEDULE_NUMBER')}}</span>
			</mat-form-field>
		</div>

		<h4 class="caption caption-btn">
			<ng-container i18n="positions group caption|A caption for the control group containing schedule's positions@@schedules/positions group caption">Schedule's positions</ng-container>
			<a id="addPosition" (click)="addPosition()" mat-icon-button color="primary" class="btn-new-position"
				matTooltip="Add a new position to this schedule"
				i18n-matTooltip="add position tooltip|A tooltip text for adding a new position to the schedule@@schedules/add position tooltip"
				matTooltipPosition="left">
				<i class="material-icons notranslate">add_box</i>
			</a>
		</h4>

		<div *ngIf="schedulePositionsFormArray.length === 0">
			<span
				i18n="no positions label|A label displayed when there are no positions in the schedule@@schedules/no positions label">No
				positions in schedule.</span>
		</div>
		<mat-accordion>
			<mat-expansion-panel id="positionConfig" [expanded]="isLast"
				*ngFor="let position of schedulePositionsFormArray.controls; index as positionIndex; last as isLast;"
				(opened)="openExpansionPanel(positionIndex)" formArrayName="positions" hideToggle="true">
				<mat-expansion-panel-header class="header-btn">
					<mat-panel-title>
						{{ getNameForPosition(positionIndex) }}
					</mat-panel-title>

					<mat-panel-description>
						<a id="deletePosition" (click)="removePosition($event, positionIndex)" mat-icon-button color="primary"
							matTooltip="Remove position"
							i18n-matTooltip="remove position tooltip|A tooltip text for removing a position from the schedule@@schedules/remove position tooltip"
							matTooltipPosition="left">
							<i class="material-icons notranslate">indeterminate_check_box</i>
						</a>
					</mat-panel-description>
				</mat-expansion-panel-header>

				<div [formGroupName]="positionIndex">
					<div formArrayName="events" class="schedule-events">
						<div *ngFor="let event of position.controls.events.controls; index as eventIndex"
							[formGroupName]="eventIndex" class="row">
							<p class="col date-text">
								<span class="label"
									i18n="event name placeholder|A placeholder for event name label@@schedules/event name placeholder">Action</span>
								<span class="data">{{ getNameForEvent(event, positionIndex) }}</span>
							</p>
							<mat-form-field class="col">
								<input matInput [matDatepicker]="picker" formControlName="date">
								<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
								<mat-datepicker #picker></mat-datepicker>
							</mat-form-field>
						</div>
					</div>
					<mat-slide-toggle *ngIf="automaticScheduler" formControlName="activeScheduler"
						(click)="offPositionsSchedulers(position, positionIndex)">
						<span
							i18n="automatic scheduler label|Automatic scheduler radio button label@@schedules/automatic scheduler label">Automatic
							scheduler</span>
					</mat-slide-toggle>

					<ng-container *ngIf="position?.controls?.activeScheduler?.value">
						<div class="row">
							<div class="col date-text date-fields">
								<mat-form-field>
									<span class="label" i18n="@@schedules/modal/date from">Begin date</span>
									<input id="dateFrom{{positionIndex}}" matInput type="date" name="DateFrom"
										formControlName="dateFrom" [max]="currentDate">
									<mat-error *ngIf="scheduleForm?.invalid">
										<div *ngIf="startDateIsNotBeforeEndDate"
											i18n="@@schedules/modal/begin date error">The start date must precede the
											end date.</div>
									</mat-error>
								</mat-form-field>
							</div>
							<br />
							<div class="col date-text date-fields">
								<mat-form-field>
									<span class="label" i18n="@@schedules/modal/date to">End date</span>
									<input id="dateTo{{positionIndex}}" matInput type="date" name="DateTo"
										formControlName="dateTo">
									<mat-error *ngIf="scheduleForm?.invalid">
										<div *ngIf="endDateIsNotAfterStartDate" i18n="@@schedules/modal/end date error">
											The end date must be after the start date.</div>
									</mat-error>
								</mat-form-field>
							</div>
							<br />
							<div class="col">
								<mat-form-field>
									<mat-label i18n="@@schedules/modal/aggregation/select aggregation type">Select
										aggregation type</mat-label>
									<mat-select formControlName="aggregation">
										<mat-option value="1h" i18n="@@schedules/modal/aggregation/hour">hour
										</mat-option>
										<mat-option value="1d" i18n="@@schedules/modal/aggregation/day">day</mat-option>
										<mat-option value="0"
											i18n="@@schedules/modal/aggregation/data without aggregation">data without
											aggregation</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
						</div>
						<mat-error
							*ngIf="activeSchedulerConfigError || getMeterPointByPostitionIdCount(positionIndex)===0"
							i18n="@@schedules/modal/lack of data in this period">
							No data in the given period for this item.
						</mat-error>
						<mat-info
							*ngIf="!activeSchedulerConfigError && getMeterPointByPostitionIdCount(positionIndex)!==undefined && getMeterPointByPostitionIdCount(positionIndex)!==0"
							i18n="@@schedules/modal/selected meter points">
							In the selected period, readout data was found for
							{{getMeterPointByPostitionIdCount(positionIndex) //i18n(ph='SELECTED_METER_POINTS')}} /
							{{allActiveMeterPoints //i18n(ph='ALL_METER_POINTS')}} active meters
						</mat-info>
						<div class="btn-right validate-data-btn">
							<button type="button" (click)="validateData(positionIndex)" mat-raised-button color="accent"
								class="btn" i18n="@@schedules/modal/validate date">Validate data</button>
						</div>
					</ng-container>

				</div>
			</mat-expansion-panel>
		</mat-accordion>

		<div class="form-btn right-btn">
			<div matTooltip="Please validate all auto import items."
				[matTooltipDisabled]="(scheduleForm.valid)&& !activeSchedulerConfigError && !blockSaveBtn"
				i18n-matTooltip="@@schedules/modal/tooltip/validate positions" matTooltipPosition="left">
				<button id="saveBtn"
					[disabled]="(scheduleForm.invalid && !scheduleForm.pristine && !scheduleForm.untouched)|| activeSchedulerConfigError || blockSaveBtn"
					type="submit" mat-raised-button color="accent" class="btn btn-action"
					i18n="save button label|Save button label@@schedules/save button label">Save</button>
			</div>
		</div>

	</div>
</form>