import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

import { ReadingsPreviewAddDialogComponent } from '../dialog/readings-preview-add-dialog/readings-preview-add-dialog.component';
import { ReadingsPreviewViewDialogComponent } from '../dialog/readings-preview-view-dialog/readings-preview-view-dialog.component';
import { MeasurementData } from '@shared/models';
import { DialogTypeEnum } from '@shared/types/formType';
import { VeeRule } from '@shared/models/appModels/VeeRule.model';

@Injectable()
export class MeasurementsPreviewDialogsService {

  constructor(private dialog: MatDialog) { }

  public openCreateAndEditModal(data: {mesData: MeasurementData, position: number}, name: string = 'Add new'): Observable<any> {
    let dialogRef: MatDialogRef<ReadingsPreviewAddDialogComponent, any>;
    dialogRef = this.dialog.open(ReadingsPreviewAddDialogComponent, {
                panelClass: 'dialog-overlay',
                data: {
                  meterData: data.mesData,
                  idPos: data.position
                }
              });
    dialogRef.componentInstance.name = name;
    return dialogRef.afterClosed();
  }

  public openViewModal(tab: [MeasurementData, VeeRule], name: string = 'View'): Observable<any> {
    let dialogRef: MatDialogRef<ReadingsPreviewViewDialogComponent, any>;
    dialogRef = this.dialog.open(ReadingsPreviewViewDialogComponent, {
                panelClass: 'dialog-overlay',
                data: {
                  meterData: tab[0],
                  veeRule: tab[1]
                }
              });
    dialogRef.componentInstance.name = name;
    return dialogRef.afterClosed();
  }


  public invoke(data: MeasurementData, name = 'Add new', action: DialogTypeEnum = DialogTypeEnum.CREATE_AND_EDIT): Observable<any> {
    switch (action) {
      case DialogTypeEnum.VIEW: {
        let dialogRef: MatDialogRef<ReadingsPreviewViewDialogComponent, any>;
        dialogRef = this.dialog.open(ReadingsPreviewViewDialogComponent, {
                    panelClass: 'dialog-overlay',
                    data: {
                      meterData: data
                    }
                  });
        dialogRef.componentInstance.name = name;
        return dialogRef.afterClosed();
      }
      case DialogTypeEnum.CREATE_AND_EDIT: {
        let dialogRef: MatDialogRef<ReadingsPreviewAddDialogComponent, any>;
        dialogRef = this.dialog.open(ReadingsPreviewAddDialogComponent, {
                    panelClass: 'dialog-overlay',
                    data: {
                      meterData: data
                    }
                  });
        dialogRef.componentInstance.name = name;
        return dialogRef.afterClosed();
      }
    }
  }
}
