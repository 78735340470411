import { RestEnumMpapper } from '@shared/models/RestSupport';
import { IGetInterfaceType } from '@shared/types/applicationTypes';
import { AnalysisProcessStageEnum, AnalysisProcessStatusEnum } from '@shared/types/modelTypes/analysisProcessTypes';
import { AnalysisAlgorithmTypeEnum } from '@shared/types/modelTypes/analysisAlgorithmTypes';
import { UtilityTypeEnum } from '@shared/types/utilityType';

export interface IAnalysisStatus {
  stage?: AnalysisProcessStageEnum | string;
  status?: AnalysisProcessStatusEnum | string;
  aggregation?: string;
  startDate?: number;
  endDate?: number;
  meterId?: string;
  address?: string;
  algorithmType?: string;
  svUtilityType?: UtilityTypeEnum | string;
}

export interface IAnalysisRequest {
  requestedStage?: AnalysisProcessStageEnum | string;
  aggregation?: string;
  startDate?: number;
  endDate?: number;
  meterId?: string;
  address?: string;
  algorithmType?: string;
  svUtilityType?: UtilityTypeEnum | string;
}

export class AnalysisStatus implements IAnalysisStatus, IAnalysisRequest, IGetInterfaceType<IAnalysisStatus | IAnalysisRequest, AnalysisStatus> {

  static AnalysisProcessStateMapper: RestEnumMpapper<typeof AnalysisProcessStageEnum> = new RestEnumMpapper<typeof AnalysisProcessStageEnum>();
  static AnalysisStatusMapper: RestEnumMpapper<typeof AnalysisProcessStatusEnum> = new RestEnumMpapper<typeof AnalysisProcessStatusEnum>();
  static MediaTypeMapper: RestEnumMpapper<typeof UtilityTypeEnum> = new RestEnumMpapper<typeof UtilityTypeEnum>();

  requestedStage?: AnalysisProcessStageEnum | string;
  stage?: AnalysisProcessStageEnum | string;
  status?: AnalysisProcessStatusEnum | string;
  aggregation?: string;
  startDate?: number;
  endDate?: number;
  meterId?: string;
  address?: string;
  algorithmType?: string;
  svUtilityType?: UtilityTypeEnum | string;

  static generateAnalysisDoneState(type: string | AnalysisAlgorithmTypeEnum): AnalysisStatus {
    if (type === 'DETECTION_OF_ANOMALIES') {
      return new AnalysisStatus({ stage: AnalysisProcessStageEnum.DETECTION_OF_ANOMALIES, status: AnalysisProcessStatusEnum.DONE });
    } else {
      return new AnalysisStatus({ stage: AnalysisProcessStageEnum.FORECASTING, status: AnalysisProcessStatusEnum.DONE });
    }

  }

  assgnMediaType(eIn: UtilityTypeEnum | string): UtilityTypeEnum {
    try {
      return AnalysisStatus.MediaTypeMapper.getEnumAsNumber(UtilityTypeEnum, eIn);
    } catch (e) {
      return undefined;
    }
  }

  constructor(iAnalysisProcess: (IAnalysisRequest | IAnalysisStatus)) {

    this.requestedStage = (typeof ((<IAnalysisRequest>iAnalysisProcess).requestedStage) !== 'undefined') ?
      this.assignStage((<IAnalysisRequest>iAnalysisProcess).requestedStage) : undefined;

    this.algorithmType = (typeof ((<IAnalysisRequest>iAnalysisProcess).algorithmType) !== 'undefined') ?
      (<IAnalysisRequest>iAnalysisProcess).algorithmType : undefined;

    this.aggregation = (typeof ((<IAnalysisRequest>iAnalysisProcess).aggregation) !== 'undefined') ?
      (<IAnalysisRequest>iAnalysisProcess).aggregation : undefined;

    this.startDate = (typeof ((<IAnalysisRequest>iAnalysisProcess).startDate) !== 'undefined') ?
      (<IAnalysisRequest>iAnalysisProcess).startDate : undefined;

    this.endDate = (typeof ((<IAnalysisRequest>iAnalysisProcess).endDate) !== 'undefined') ?
      (<IAnalysisRequest>iAnalysisProcess).endDate : undefined;

    this.meterId = (typeof ((<IAnalysisRequest>iAnalysisProcess).meterId) !== 'undefined') ?
      (<IAnalysisRequest>iAnalysisProcess).meterId : undefined;

    this.address = (typeof ((<IAnalysisRequest>iAnalysisProcess).address) !== 'undefined') ?
      (<IAnalysisRequest>iAnalysisProcess).address : undefined;

    this.aggregation = (typeof ((<IAnalysisStatus>iAnalysisProcess).aggregation) !== 'undefined') ?
      (<IAnalysisStatus>iAnalysisProcess).aggregation : undefined;

    this.startDate = (typeof ((<IAnalysisStatus>iAnalysisProcess).startDate) !== 'undefined') ?
      (<IAnalysisStatus>iAnalysisProcess).startDate : undefined;

    this.endDate = (typeof ((<IAnalysisStatus>iAnalysisProcess).endDate) !== 'undefined') ?
      (<IAnalysisStatus>iAnalysisProcess).endDate : undefined;

    this.meterId = (typeof ((<IAnalysisStatus>iAnalysisProcess).meterId) !== 'undefined') ?
      (<IAnalysisStatus>iAnalysisProcess).meterId : undefined;

    this.stage = (typeof ((<IAnalysisStatus>iAnalysisProcess).stage) !== 'undefined') ?
      this.assignStage((<IAnalysisStatus>iAnalysisProcess).stage) : undefined;

    this.status = (typeof ((<IAnalysisStatus>iAnalysisProcess).status) !== 'undefined') ?
      this.assignStatus((<IAnalysisStatus>iAnalysisProcess).status) : undefined;

    this.address = (typeof ((<IAnalysisStatus>iAnalysisProcess).address) !== 'undefined') ?
      (<IAnalysisStatus>iAnalysisProcess).address : undefined;

    this.algorithmType = (typeof ((<IAnalysisStatus>iAnalysisProcess).algorithmType) !== 'undefined') ?
      (<IAnalysisStatus>iAnalysisProcess).algorithmType : undefined;

    this.svUtilityType = (typeof ((<IAnalysisStatus>iAnalysisProcess).svUtilityType) !== 'undefined') ?
      this.assgnMediaType((<IAnalysisStatus>iAnalysisProcess).svUtilityType) : undefined;
  }

  assignStage(eIn: AnalysisProcessStageEnum | string): AnalysisProcessStageEnum {
    try {
      return AnalysisStatus.AnalysisProcessStateMapper.getEnumAsNumber(AnalysisProcessStageEnum, eIn);
    } catch (e) {
      return undefined;
    }
  }

  assignStatus(eIn: AnalysisProcessStatusEnum | string): AnalysisProcessStatusEnum {
    try {
      return AnalysisStatus.AnalysisStatusMapper.getEnumAsNumber(AnalysisProcessStatusEnum, eIn);
    } catch (e) {
      return undefined;
    }
  }


  getInterface(webInterface: boolean): IAnalysisStatus | IAnalysisRequest {
    const ret: (IAnalysisStatus | IAnalysisRequest) = {};

    /* This is the ugliest piece of code i have ever wrote in my life. */
    if (typeof ((<IAnalysisRequest>this).requestedStage) !== 'undefined') {
      (<IAnalysisRequest>ret).requestedStage = AnalysisStatus.AnalysisProcessStateMapper.getEnumAsString(AnalysisProcessStageEnum, this.requestedStage);
    }
    if (typeof ((<IAnalysisStatus>this).algorithmType) !== 'undefined') {
      (<IAnalysisStatus>ret).algorithmType = this.algorithmType;
    }
    if (typeof ((<IAnalysisStatus>this).aggregation) !== 'undefined') {
      (<IAnalysisStatus>ret).aggregation = this.aggregation;
    }
    if (typeof ((<IAnalysisStatus>this).startDate) !== 'undefined') {
      (<IAnalysisStatus>ret).startDate = this.startDate;
    }

    if (typeof ((<IAnalysisStatus>this).endDate) !== 'undefined') {
      (<IAnalysisStatus>ret).endDate = this.endDate;
    }

    if (typeof ((<IAnalysisStatus>this).meterId) !== 'undefined') {
      (<IAnalysisStatus>ret).meterId = this.meterId;
    }

    if (typeof ((<IAnalysisStatus>this).address) !== 'undefined') {
      (<IAnalysisStatus>ret).address = this.address;
    }

    if (typeof ((<IAnalysisRequest>this).aggregation) !== 'undefined') {
      (<IAnalysisRequest>ret).aggregation = this.aggregation;
    }
    if (typeof ((<IAnalysisRequest>this).startDate) !== 'undefined') {
      (<IAnalysisRequest>ret).startDate = this.startDate;
    }

    if (typeof ((<IAnalysisRequest>this).endDate) !== 'undefined') {
      (<IAnalysisRequest>ret).endDate = this.endDate;
    }

    if (typeof ((<IAnalysisRequest>this).meterId) !== 'undefined') {
      (<IAnalysisRequest>ret).meterId = this.meterId;
    }

    if (typeof ((<IAnalysisRequest>this).address) !== 'undefined') {
      (<IAnalysisRequest>ret).address = this.address;
    }

    if (typeof ((<IAnalysisStatus>this).stage) !== 'undefined') {
      (<IAnalysisStatus>ret).stage = AnalysisStatus.AnalysisProcessStateMapper.getEnumAsString(AnalysisProcessStageEnum, this.stage);
    }
    if (typeof ((<IAnalysisRequest>this).algorithmType) !== 'undefined') {
      (<IAnalysisRequest>ret).algorithmType = this.algorithmType;
    }
    if (typeof ((<IAnalysisStatus>this).status) !== 'undefined') {
      (<IAnalysisStatus>ret).stage = AnalysisStatus.AnalysisStatusMapper.getEnumAsString(AnalysisProcessStatusEnum, this.status);
    }
    if (typeof ((<IAnalysisStatus>this).svUtilityType) !== 'undefined') {
      (<IAnalysisStatus>ret).svUtilityType = AnalysisStatus.MediaTypeMapper.getEnumAsString(UtilityTypeEnum, this.svUtilityType);
    }
    if (typeof ((<IAnalysisRequest>this).svUtilityType) !== 'undefined') {
      (<IAnalysisRequest>ret).svUtilityType = AnalysisStatus.MediaTypeMapper.getEnumAsString(UtilityTypeEnum, this.svUtilityType);
    }
    return ret;
  }

}
