import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { saveAs } from "file-saver";
import { MeterPoint } from '@shared/models';
import { MeasurementDataModel } from '@shared/models/appModels/measurementDataModel.model';
import * as moment from 'moment';
import { MeasurementDataStoreService } from '@shared/services/dataStore/measurementDataDataStore.service';
import { LocaleService } from '@shared/services/localeService.service';
import { Subscription } from 'rxjs';
import { InteractiveTutorialService, NextComponentName } from '@shared/services/interactiveTutorialService.service';
import { ExportInteractiveTutorialService } from '../export-interactive-tutorial.service';
import { debounceTime } from 'rxjs/operators';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'sv-custom-csv-data-final',
    templateUrl: 'custom-csv-data-final.component.html',
    styleUrls: ['custom-csv-data-final.component.sass']
})

export class CustomCsvDataFinalComponent implements OnInit, OnDestroy {


    @Input() meterPoints: MeterPoint[];
    @Input() variables: MeasurementDataModel[];
    @Input() from: FormControl;
    @Input() to: FormControl;
    @Input() selectedPosition: number;

    canStartInteractiveTut$: Subscription;

    constructor(
        private measurementDataService: MeasurementDataStoreService,
        private localeSErv: LocaleService,
        private interactiveTutorialServ: InteractiveTutorialService,
        private exportInteractiveTutorialService: ExportInteractiveTutorialService) {
    }

    ngOnInit(): void {
      this.canStartInteractiveTut$ = this.interactiveTutorialServ.canGoToNextStepSubject.pipe(debounceTime(1000)).subscribe(val => {
        if (val === NextComponentName.EXPORT_MEASUREMENTS_SUMMARY) {
          this.startInteractiveTutorial();
        }
      });
    }

    ngOnDestroy() {
      if (this.canStartInteractiveTut$) {
        this.canStartInteractiveTut$.unsubscribe();
      }
    }

    startInteractiveTutorial(){
      const steps = this.exportInteractiveTutorialService.getExportCustomCsvSummaryInteractiveTutorialSteps();
      this.interactiveTutorialServ.startInteractiveTutorial(steps);
    }

    onFinalize() {
        const from = new Date(this.from.value).getTime();
        const to = new Date(this.to.value).getTime();
        const meterPointsIds = this.meterPoints.map(el => {
          return el.idMeterPoint;
        });
        const names = this.variables.map( el => {
          return el.name;
        });
        const lang = this.localeSErv.getLanguage();
        const positionId = this.selectedPosition;
        this.measurementDataService.getMeasurementCsvFile(meterPointsIds, names, positionId, lang, from, to).subscribe( data => {
          saveAs(data, `measurements_${moment(new Date()).format('YYYY-MM-DD')}.csv`);
        });
        }
    }

