// Angular modules import
import { Injectable } from '@angular/core';
// RjJS imports
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/from';
import 'rxjs/add/operator/map';


/* Datatypes */
import { MeterData } from '@shared/models/appModels/meterData.model';

/* Services */
import { MeterDataHTTPService } from '@shared/services/http/meterData.service';
import { CommonAppDataService } from '@shared/services/commonAppData.service';

import { environment } from '@env/environment';
import { BaseDataStore, ApiDataShift, MeterDataFilters, SortType } from '@shared/types/applicationTypes';
import { GenericHttpParams } from '@shared/types/http/httpType';
import { MeterDataHttpParamG } from '@shared/types/http/meterDataHttpConfig';
import { UtilityTypeEnum } from '@shared/types';

@Injectable()
export class MeterDataStoreService extends BaseDataStore<GenericHttpParams<MeterData>, MeterDataHttpParamG> {

  readonly debugMode: boolean = environment.debug;

  /* Cache tables */
  cacheMeterData: MeterData[] = [];

  private customerDataSubject$: Subject<MeterData> = new Subject<MeterData>();
  private customerDataObservable$: Observable<MeterData> = this.customerDataSubject$.asObservable();

  constructor(private meterDataService: MeterDataHTTPService, cs: CommonAppDataService) {
    super(cs);
  }

  getChangeObservable(): Observable<MeterData> {
    return this.customerDataObservable$;
  }

  getAllMeterData(
    nums: ApiDataShift = { limit: environment.defaultReqLimit, offset: 0 },
    filters?: MeterDataFilters,
    allowEmpty: boolean = true): Observable<MeterData[]> {
    const params: MeterDataHttpParamG = this.getEmptyParams();
    params.queryParams.offset = '' + nums.offset;
    params.queryParams.limit = '' + nums.limit;
    params.config.strict = !allowEmpty;
    params.queryParams.svUtilityType = UtilityTypeEnum[this.cs.getCurrentMediaType()];

    if (filters) {
      if (filters.from) {
        params.queryParams.from = '' + filters.from;
      }
      if (filters.to) {
        params.queryParams.to = '' + filters.to;
      }
      if (filters.sort) {
        params.queryParams.sort = this.setSortingOrderParam(filters.sort);
      }

    }
    return this.meterDataService.getMeterdataList(params);
  }

  private setSortingOrderParam(sort: SortType): string {
    const sVal = sort.toUpperCase() as SortType;
    if (sVal === 'ASC' || sVal === 'DESC') {
      return sVal;
    } else {
      throw new Error('Incorrect sort params');
    }
  }

  getMeterdataListCount(from: number, to: number , svUtilitType: string , limit: number, offset: number): Observable<number> {
    const params = this.getEmptyParams();
    params.queryParams.from = '' + from;
    params.queryParams.to = '' + to;
    params.queryParams.svUtilityType =  svUtilitType;
    params.queryParams.limit = '' + limit;
    params.queryParams.offset = '' + offset;
    return this.meterDataService.getMeterdataListCount(params);
  }

  getMeterDataById(id: number, allowEmpty: boolean = false): Observable<MeterData> {
    const params: MeterDataHttpParamG = this.getEmptyParams();
    params.path.idMeterData = '' + id;
    params.config.strict = !allowEmpty;

    return this.meterDataService.getMeterdataById(params);
  }

  addMeterData(item: MeterData, options: { emitEvent: boolean } = { emitEvent: true }): Observable<MeterData> {
    const params: MeterDataHttpParamG = this.getEmptyParams();
    params.body = item;

    return this.meterDataService.postMeterData(params)
      .do((added: MeterData) => {
        this.cacheMeterData.push(added);
        if (options.emitEvent) {
          this.customerDataSubject$.next(item);
        }
      });
  }

  updateMeterData(item: MeterData, options: { emitEvent: boolean } = { emitEvent: true }): Observable<MeterData> {
    const params: MeterDataHttpParamG = this.getEmptyParams();
    params.body = item;
    params.path.idMeterData = '' + item.idMeterData;

    return this.meterDataService.putMeterDataById(params)
      .do((updated: MeterData) => {
        this.cacheMeterData.forEach((el: MeterData, idx: number) => {
          if (el.idMeterData === item.idMeterData) { this.cacheMeterData[idx] = item; }
        });
        if (options.emitEvent) {
          this.customerDataSubject$.next(item);
        }
      });
  }

  deleteMeterData(id: string, options: { emitEvent: boolean } = { emitEvent: true }): Observable<MeterData> {
    const params: MeterDataHttpParamG = this.getEmptyParams();
    params.path.idMeterData = '' + id;

    return this.meterDataService.deleteMeterDataById(params)
      .do((deleted: MeterData) => {
        const findId = this.cacheMeterData.findIndex(el => el.idMeterData === deleted.idMeterData);
        if (findId !== -1) {
          this.cacheMeterData.splice(findId, 1);
        }
        if (options.emitEvent) {
          this.customerDataSubject$.next(deleted);
        }
      });
  }

  /* FIXME: data exploration */
  getData(): Observable<MeterData[]> {
    return this.meterDataService.getData();
  }

  getPreValidData(): Observable<MeterData[]> {
    return this.meterDataService.getPreValidData();
  }

  getDataById(id: number): Observable<MeterData> {
    return this.meterDataService.getDataById(id);
  }

  getDataByIds(ids: Array<number>): Observable<MeterData[]> {
    return this.meterDataService.getDataByIds(ids);
  }

  getDataByMeterId(id: number): Observable<MeterData[]> {
    return this.meterDataService.getMeterDataByMeterId(id);
  }

  getDataCoutInPositionByPositionId(idPosition: number): Observable<number> {
    const params = this.getEmptyParams();
    params.path.idPosition = '' + idPosition;
    return this.meterDataService.getCountsForPosition(params);
  }

  getMeterDataByPostionId(id: number, limit: number = 50, offset: number = 0): Observable<MeterData[]> {
    const params = this.getEmptyParams();
    params.path.idMeterData = '' + id;
    params.queryParams.limit = '' + limit;
    params.queryParams.offset = '' + offset;
    return this.meterDataService.getMeterDataByPostionId(params);
  }

  protected getEmptyParams(): MeterDataHttpParamG {
    const r: MeterDataHttpParamG = { body: {} as MeterData, headers: {}, path: {}, queryParams: {}, config: {} };
    return r;
  }

}
