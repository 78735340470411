

export abstract class BaseModel {
  constructor() { }

  protected assignSimpleType<T>(iType: T, fallbackValue?: T) {
    return typeof(iType) !== 'undefined' ? iType : fallbackValue;
  }

  protected assignEnum() {
    throw new Error('Unimplemented method');
  }
}
