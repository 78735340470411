import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { DataImportService } from '../http/dataImport.service';
import { AutomaticImportConfigForSchedulePosition } from '@shared/models/appModels/automaticImportConfigurationForSchedulePosition.model';
import { AutomaticImportConfigurationHttpParamG } from '@shared/types/http/automaticImportConfigForSchedulePositionHttpConfig';


@Injectable({providedIn: 'root'})
export class DataImportDataStoreService {

    constructor(private dataImportDataStoreService: DataImportService) { }

    postAutomaticImportConfigurationForSchedulePosition(config: AutomaticImportConfigForSchedulePosition[])
        : Observable<AutomaticImportConfigForSchedulePosition[]> {
        const params: AutomaticImportConfigurationHttpParamG = this.getEmptyParams();
        params.body = config;
        return this.dataImportDataStoreService.postAutomaticImportConfigurationForSchedulePosition(params);
    }

    protected getEmptyParams(): AutomaticImportConfigurationHttpParamG {
        const r: AutomaticImportConfigurationHttpParamG = {
            body: {} as AutomaticImportConfigForSchedulePosition,
            headers: {}, path: {}, queryParams: {}, config: {}
        };
        return r;
    }
}
