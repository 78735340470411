import { IApplicationUnits } from '@shared/models/applicationUnits.model';
import { IMediaTypeUnit } from '@shared/models/appModels/MediaTypeUnit.model';
import { IMediaTypeParameter } from '@shared/models/appModels/MediaTypeParameter.mode';

export const parameterMeasurement_mock: IMediaTypeParameter[] = [
  {name: 'worktime', description: 'Operational time', cumulative: true, type: 'DOUBLE'},
  {name: 'flow', description: 'Measured flow', cumulative: true, type: 'DOUBLE'},
  {name: 'temperatureIn', description: 'Inlet temperature', cumulative: false, type: 'DOUBLE'},
];

export const parameterMeterPoint_mock: IMediaTypeParameter[] = [
  {name: 'powerOrdered', description: 'Power ordered by customer', cumulative: false, type: 'INTEGER'}
];

