<div id="analysisWidgetContainer">
<div class="card-header">
	<h3 i18n="@@analysis/analysis panel title">Data Analysis</h3>
	<span class="header-element">
		<i class="material-icons notranslate">timeline</i>
	</span>
</div>

<div class="card-body">
	<div *ngIf="lastAnalysedPosition$ | async as position; else noAnalysis">
		<div *ngIf="analysisData$ | async as data">
			<h4 i18n="@@analysis/anomaly detection header">Anomaly Detection</h4>
			<div class="content">
				<table>
					<tr>
						<td i18n="@@analysis/anomalies/anomalies count label">Anomalies</td>
						<td class="center">
							<mat-spinner *ngIf="data === 'loading'; else anomaliesContent" diameter="14"></mat-spinner>
							<ng-template #anomaliesContent>{{ data.anomaliesCount || "-" }}</ng-template>
						</td>
					</tr>
					<tr>
						<td i18n="@@analysis/anomalies/analysed meters count label">Analysed meters</td>
						<td class="center">
							<mat-spinner *ngIf="data === 'loading'; else totalContent" diameter="14"></mat-spinner>
							<ng-template #totalContent>{{ data.analysedMetersCount }}</ng-template>
						</td>
					</tr>
				</table>

				<div [ngClass]="{'overlay': data === 'loading'}">
					<sv-pie-chart [datasets]="[{'data': [data.anomaliesCount || 0, data.meterCount - (data.analysedMetersCount || 0)]}]"
						[labels]="chartLabels">
					</sv-pie-chart>
				</div>
			</div>

			<h4 i18n="@@analysis/forecasting header">Forecasting</h4>
			<div class="content">
				<div>
					<table>
						<tr>
							<td i18n="@@analysis/widget/forecasting/forecasted meters count label">Forecasted meters</td>
							<td class="center">
								<mat-spinner *ngIf="data === 'loading'; else forecastedContent" diameter="14"></mat-spinner>
								<ng-template #forecastedContent>{{ data.forecastedMetersCount || "-" }}</ng-template>
							</td>
						</tr>
						<tr>
							<td i18n="@@analysis/widget/forecasting/all meters count label">Meters</td>
							<td class="center">
								<mat-spinner *ngIf="data === 'loading'; else totalForecastingContent" diameter="14"></mat-spinner>
								<ng-template #totalForecastingContent>{{ data.meterCount }}</ng-template>
							</td>
						</tr>
					</table>
				</div>

				<div class="text-center">
					<a (click)="navigateToAnalysis(position)" mat-raised-button color="primary" class="btn"
					i18n="@@widget/process details button label">Details</a>
				</div>
			</div>
		</div>
	</div>
	<ng-template #noAnalysis>
		<div class="content">
			<div class="info" i18n="@@analysis/widget/no analysis process label">
				No data analysis process for this schedule.
			</div>
		</div>
	</ng-template>	
</div>
</div>