import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  ChangeDetectionStrategy,
  OnDestroy,
  Input,
  AfterViewInit,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormBuilder, FormArray, FormControl } from "@angular/forms";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatPaginator } from "@angular/material/paginator";

/* External libraries */
import "rxjs/add/operator/switchMap";
import "rxjs/add/operator/mergeAll";

import { Observable } from "rxjs/Observable";

import { MeasurementDataTabbed } from "../dataSource/meterDataSource";

/* Application services */
import { MeterPointDataStoreService } from "@shared/services/dataStore/meterPointDataStore.service";
import { PositionDataStoreService } from "@shared/services/dataStore/positionDataStore.service";
import { MeasurementDataStoreService } from "@shared/services/dataStore/measurementDataDataStore.service";
import { CommonAppDataService } from "@shared/services/commonAppData.service";

/* Application datatypes */
import { MeterPoint, MeasurementData, Position } from "@shared/models";
import {
  ValidationResultEnum,
  MeasurementOriginEnum,
  PositionTransitions,
  VeeStatusEnum,
  UtilityTypeEnum,
  INameMapper,
} from "@shared/types";
import {
  getValidationResults,
  ValidationTypes,
} from "@shared/types/modelTypes/meterDataTypes";

// Env
import { environment } from "@env/environment";
import { DataValidationLocalisation as loc } from "../../data-validation-localisation";
import { ApplicationUnitsDataStoreService } from "@shared/services/dataStore/applicationUnitsDataStore.service";
import { ApplicationUnits } from "@shared/models/applicationUnits.model";
import { VeeStatusDataStoreService } from "@shared/services/dataStore/veeProcessDataStore.service";
import {
  VeeStatus,
  IVeeRequest,
} from "@shared/models/appModels/VeeStatus.model";
import {
  VeeProcessStageEnum,
  VeeProcessStatusEnum,
} from "@shared/types/modelTypes/veeProcessTypes";
import { typesLocalisation } from "@shared/types/localisation";
import { VeeRule, IVeeRule } from "@shared/models/appModels/VeeRule.model";
import { VeeRulesDataStoreService } from "@shared/services/dataStore/veeRulesDataStore";
import { VeeRuleTypeEnum } from "@shared/types/modelTypes/veeRuleTypes";
// Rxjs 6
import { Observer, Subject, Subscriber, Subscription } from 'rxjs';
import { concatMap, map, concat, mergeMap, tap, debounceTime } from 'rxjs/operators';
import { of, merge, from } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MeasurementDataModel } from '@shared/models/appModels/measurementDataModel.model';
import { ValidationDetailColumn } from './detail/detail.component';
import { InteractiveTutorialService, NextComponentName } from '@shared/services/interactiveTutorialService.service';
import { DataValidationInteractiveTutorialService } from '../../data-validation-interactive-tutorial-steps';
import { DataValidationTranslationsHelper } from '../service/data-validation-translations-helper.service';


interface Counter {
  value: number;
  pending: boolean;
}
interface CheckMeasurement {
  check: boolean;
  measurementData: MeasurementData;
}
@Component({
  selector: "sv-data-validation-summary",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "data-validation-summary.component.html",
  styleUrls: ["data-validation-summary.component.sass"],
  animations: [
    trigger("detailExpand", [
      state(
        "collapsed",
        style({ height: "0", minHeight: "0", visibility: "hidden" })
      ),
      state("expanded", style({ height: "*", visibility: "visible" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(.4, 0, .2, 1)")
      ),
    ]),
  ],
})
export class DataValidationSummaryComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @Input() scheduleIdIn: number;
  @Input() positionIdIn: number;
  private _componentVisible;
  @Input()
  get componentVisible(): boolean {
    return this._componentVisible;
  }
  set componentVisible(isVisible: boolean) {
    this._componentVisible = isVisible;
  }
  @Output() allValid: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild("filter") filter: ElementRef;
  @ViewChild(MatExpansionPanel, { static: true })
  expansionPanel: MatExpansionPanel;
  canStartInteractiveTut$: Subscription

  readonly debugMode: boolean = environment.debug;
  readonly backendPath: string = environment.backendPath;

  readonly seeDetailsMdIcon: string = "expand_more";
  readonly hideDetailsMdIcon: string = "expand_less";
  idNumberFilter: number = undefined;
  readonly menuMdIcon: string = "menu";
  readonly checkIcon: string = "check";
  readonly configureMdIcon: string = "mode_edit";

  readonly rules = $localize`:@@data validation/all rules:All Rules`;
  readonly noDataFound = $localize`:@@data validation/summary/no data:N/A`;
  readonly energy;
  readonly allRules = $localize`:@@data validation/all rules:All Rules`;
  readonly itemsPage = typesLocalisation.ItemsPage[environment.language].texts;

  changeFilterForm: FormGroup;
  dataSourceNew: MatTableDataSource<MeterPoint> = new MatTableDataSource();
  expandedElement: any = null;
  correctedValues: Map<string, number>;
  currentCheckBoxState: boolean;
  currentRadioBtnValue: string = this.allRules;
  loadAllInvalids: boolean;
  allInvalidData: MeasurementData[];
  choosenRules: number[] = [];
  checkedInvalids: Array<CheckMeasurement>;
  checkedInvalidsCopy: Array<CheckMeasurement>;
  existsValidators: Array<number>;
  rulesArray: IVeeRule[] = [];
  displayedColumns: string[] = ["meterId"];
  allInvalidUnchecked: boolean = false;
  applicationUnits: ApplicationUnits;
  primaryValues: MeasurementDataModel[];
  currentMediaType: ValidationDetailColumn;

  dataLength: number = 0;
  dataFetched: boolean = false;
  dataLoading: boolean = true;

  private radioBtnListSubs$: Subscription;
  rowsCheckStates: FormArray;
  showTutorialSubscription: Subscription;

  /* Enums local copies */
  ValidationResultEnum: typeof ValidationResultEnum = ValidationResultEnum;
  validationFlagNames: INameMapper[] = getValidationResults();

  counters: { [key: string]: Counter } = {
    TOTAL: { value: undefined, pending: true },
    [ValidationResultEnum[ValidationResultEnum.INVALID]]: {
      value: undefined,
      pending: true,
    },
    [ValidationResultEnum[ValidationResultEnum.AUTOCORRECTION]]: {
      value: undefined,
      pending: true,
    },
    [ValidationResultEnum[ValidationResultEnum.MANUALCORRECTION]]: {
      value: undefined,
      pending: true,
    },
  };

  goToDisabled: boolean = true;
  freezeDisabled: boolean = true;
  positionFrozen: boolean = false;
  listRulesValidation: IVeeRule[] = [];
  filterObj = { showAll: true, valid: false, invalid: { check: false, rules: [] }, autoCorrection: false, manualCorrection: false };
  positionFrozenObj = { frozen: false, firstValue: true };
  askForCheckboxesValuesBehSubject: Subject<boolean> = new Subject();
  clearResponseFunc;
  constructor(
    private router: Router,
    private meterPointDataStoreService: MeterPointDataStoreService,
    private positionDataStoreService: PositionDataStoreService,
    private measurementService: MeasurementDataStoreService,
    private cs: CommonAppDataService,
    private fb: FormBuilder,
    private unitsService: ApplicationUnitsDataStoreService,
    private cdr: ChangeDetectorRef,
    private veeProcessService: VeeStatusDataStoreService,
    private route: ActivatedRoute,
    private veeRuleService: VeeRulesDataStoreService,
    private dataValidationTranslationsHelper: DataValidationTranslationsHelper,
    private interactiveTutorialService: InteractiveTutorialService,
    private dataValidationInteractiveTutorialService: DataValidationInteractiveTutorialService
  ) {
    this.existsValidators = new Array<number>();
    this.resetCheckedRulesState();
    this.changeFilterForm = this.fb.group({
      options: ["SHOW_ALL"],
      meter: [""],
      location: [""],
      validationTypes: new FormControl(),
      checkedRule: [this.currentRadioBtnValue],
    });

    this.changeFilterForm.controls["validationTypes"].disable();
    this.correctedValues = new Map<string, number>();
    this.currentCheckBoxState = true;
    this.loadAllInvalids = true;
    this.allInvalidData = new Array<MeasurementData>();
    this.checkedInvalids = new Array<CheckMeasurement>();
    this.checkedInvalidsCopy = new Array<CheckMeasurement>();
    if (this.cs.getCurrentMediaType() !== 1) {
      this.energy = $localize`:@@data validation/summary/energy:Energy`;
      this.currentMediaType = ValidationDetailColumn.ENERGY;
    } else {
      this.energy = $localize`:@@data validation/summary/water:Volume`;
      this.currentMediaType = ValidationDetailColumn.VOLUME;
    }
  }

  onFilterMeterPointsEvent() {
    let filteredValue;
    let filter;
    let foundMeters: MeterPoint[] = [];
    return this.changeFilterForm.valueChanges.pipe(
      debounceTime(500),
      concatMap<string, Observable<number>>(v => {
        filteredValue = v;
        filter = this.changeFilterForm.get("options").value === "SHOW_ALL" ? {} :
          (this.changeFilterForm.get("options").value === "INVALID" && filteredValue.checkedRule !== this.allRules ?
            { validationResult: this.changeFilterForm.get("options").value, idVeeRule: this.choosenRules[0] } :
            { validationResult: this.changeFilterForm.get("options").value })
        return this.positionDataStoreService.getPositionMeterPointsCount(this.positionIdIn, true, filter);
      }),
      concatMap<number, Observable<MeterPoint[]>>((count) => {
        return this.positionDataStoreService
          .getPositionMeterPoints(this.positionIdIn, count, 0, true, filter)
          .pipe(
            concatMap<MeterPoint[], Observable<MeterPoint[]>>((meters) => {
              foundMeters = meters.filter((meter) => {
                const condition: boolean = meter.meterPlacement !== undefined ? (meter.serialNumber.toLowerCase().includes(filteredValue.meter.toLowerCase())
                  && meter.meterPlacement?.toLowerCase().includes(filteredValue.location.toLowerCase())) :
                  meter.serialNumber.toLowerCase().includes(filteredValue.meter.toLowerCase()) && filteredValue.location === '';
                  if (condition) {
                  return meter;
                }
              });
              return of(foundMeters);
            })
          );
      })
    );
  }

  onRefreshCounters(event) {
    this.loadCounters().subscribe((resp) => {
      this.cdr.detectChanges();
    });
  }

  onNoDataInSomeOfDetailsTable(event) {
    this.changeFilterForm.updateValueAndValidity();
  }

  onMeasurementInputClickedValue(value) {
    this.correctedValues.set(value.key, +value.value);
  }

  onResponseCheckboxesValues() {
    this.clearResponseFunc = setTimeout(() => {
      const setToSend: MeasurementData[] = new Array<MeasurementData>();
      const usagesType = this.applicationUnits.units.find(
        (v) => v.name === this.currentMediaType
      );
      let filter = this.checkedInvalids.filter(ci => this.dataSourceNew.data.find(d=> d.idMeterPoint === ci.measurementData.idMeterPoint && ci.check) )
      filter.forEach((v, i) => {
        if (v.check) {
          v.measurementData.measurementValues[
            usagesType.name
          ] = this.correctedValues.get(v.measurementData.idMeasurementData);
          v.measurementData.measurementOrigin = MeasurementOriginEnum.EDITED;
          v.measurementData.validationResult =
            ValidationResultEnum.MANUALCORRECTION;
          setToSend.push(v.measurementData);
        }
      });
      this.checkedInvalidsCopy = JSON.parse(
        JSON.stringify(filter)
      );
      if (setToSend.length > 0) {
        this.measurementService
          .updateMeasurementData(setToSend, false, { emitEvent: true })
          .pipe(
            concatMap<MeasurementData[], Observable<boolean>>((mes) => {
              if (this.changeFilterForm.get("meter").value !== "") {
                return this.positionDataStoreService
                  .getPositionMeasurementDataCount(this.positionIdIn, {
                    validationResult: ValidationResultEnum.INVALID,
                    idMeterPoint: this.changeFilterForm.get("meter").value,
                  })
                  .pipe(map((cnt) => cnt === 0));
              } else {
                return this.positionDataStoreService
                  .getPositionMeasurementDataCount(this.positionIdIn, {
                    validationResult: ValidationResultEnum.INVALID,
                  })
                  .pipe(map((cnt) => cnt === 0));
              }
            })
          )
          .subscribe((allValid: boolean) => {
            this.resetCheckedRulesState();
            if (allValid) {
              this.freezeDisabled = false;
              this.cdr.markForCheck();
            }
            this.changeFilterForm.get("meter").value !== ""
              ? this.setAllReadingsData({
                validationResult: ValidationResultEnum.INVALID,
                idMeterPoint: this.changeFilterForm.get("meter").value,
              })
              : this.setAllReadingsData({
                validationResult: ValidationResultEnum.INVALID,
              });
            this.loadCounters().subscribe((o) => {
              this.cdr.markForCheck();
            });
          });
      }
    }, 100);
  }

  ngOnInit() {
    this.veeProcessService
      .getVeeStateForPosition(this.positionIdIn, false)
      .subscribe(
        (status) => {
          if (status.stage === VeeProcessStageEnum.FREEZE) {
            this.positionFrozen = true;
            this.goToDisabled = false;
            this.freezeDisabled = true;
            this.expandedElement = null;
          }
        },
        (err) => console.log(err),
        () => {
          this.loadDataFunc();
        }
      );

    this.veeRuleService
      .getVeeRules()
      .pipe(
        map<VeeRule[], VeeRule[]>((rules) => {
          return rules.sort((a, b) => a.priority - b.priority);
        })
      )
      .subscribe(
        (veeRules: VeeRule[]) => {
          const list = veeRules
            .slice(0)
            .filter(
              (v) =>
                v.media_type ===
                UtilityTypeEnum[this.cs.getCurrentMediaType()] ||
                v.media_type === UtilityTypeEnum[UtilityTypeEnum.OTHER]
            );
          this.listRulesValidation = list.filter(
            (v) => v.rule_type === VeeRuleTypeEnum[VeeRuleTypeEnum.VALIDATION]
          );
        },
        (e) => {
          console.error("Unable to fetch rules");
          console.error(e);
          this.listRulesValidation =
            this.listRulesValidation.length === 0
              ? []
              : this.listRulesValidation;
        }
      );
    this.loadCounters().subscribe((counters) => { });
    this.initAppUnits();

    this.onFilterMeterPointsEvent()
      .pipe(
        map<MeterPoint[], MeterPoint[]>((meters) => {
          meters.forEach((met) => {
            met["idPosition"] = this.positionIdIn;
            met["positionFreezed"] = this.positionFrozen;
          });
          return meters;
        })
      )
      .subscribe((meters) => {
        this.dataSourceNew.data = meters;
        this.dataSourceNew.paginator = this.paginator;
        this.dataLength = meters.length;
        this.cdr.detectChanges();
      });

  }

  expandPanel(
    matExpansionPanel: MatExpansionPanel,
    event: Event,
    element
  ): void {
    event.stopPropagation(); // Preventing event bubbling
    element["isVisible"] = matExpansionPanel.expanded;
    this.cdr.detectChanges();
  }

  onAllInvalidReadingsUnchecked(allReadingsUnchecked: boolean) {
    this.allInvalidUnchecked = allReadingsUnchecked;
  }

  onCheckedInvalidsReadings(resp: CheckMeasurement[]) {
    this.checkedInvalidsCopy.forEach((element) => {
      resp.forEach((el) => {
        if (
          element.measurementData.idMeasurementData ===
          el.measurementData.idMeasurementData
        ) {
          element.check = el.check;
        }
      });
    });
    const atLeastOneChecked = this.checkedInvalidsCopy.find(
      (el) => el.check === true
    );
    if (typeof atLeastOneChecked === "undefined") {
      this.allInvalidUnchecked = true;
    } else {
      this.allInvalidUnchecked = false;
    }
  }

  ngAfterViewInit(): void {
    this.paginator._intl.itemsPerPageLabel = $localize`:items per page label|A label used in a widget responsible
    for splitting records in a table into multiple pages@@paging/items per page:Items per page`;
    this.paginator._intl.nextPageLabel = $localize`:next page tooltip|A tooltip for a paging widget button@@paging/next page:Next page`;
    this.paginator._intl.previousPageLabel = $localize`:previous page tooltip|A tooltip for a paging widget button@@paging/previous page:Previous page`;

    this.positionDataStoreService
      .getPositionMeasurementDataCount(this.positionIdIn, {
        validationResult: ValidationResultEnum.INVALID,
      })
      .subscribe((num: number) => {
        this.positionDataStoreService
          .getPositionMeasurementData(
            this.positionIdIn,
            { limit: num, offset: 0 },
            false,
            { validationResult: ValidationResultEnum.INVALID }
          )
          .subscribe((m: MeasurementData[]) => {
            this.existsValidators.push(0);
            this.allInvalidData = m;
            this.allInvalidData.forEach((element) => {
              this.checkedInvalids.push({
                check: true,
                measurementData: element,
              });
              if (
                element.idValidationRule &&
                !this.existsValidators.find(
                  (v) => v === element.idValidationRule
                )
              ) {
                this.existsValidators.push(element.idValidationRule);
              }
            });
            this.checkedInvalidsCopy = JSON.parse(
              JSON.stringify(this.checkedInvalids)
            );
            this.showTutorialSubscription = this.interactiveTutorialService.showTutorialSubject
              .pipe(debounceTime(1500))
              .subscribe((isActive) => {
                if (
                  this.interactiveTutorialService.getActiveComponent(
                    this.route.routeConfig.path
                  ) &&
                  isActive
                ) {
                  this.startInteractiveTutorial();
                }
              });
            this.canStartInteractiveTut$ = this.interactiveTutorialService.canGoToNextStepSubject.pipe(debounceTime(100)).subscribe(componentName => {
              if (componentName === NextComponentName.DATA_VALIDATION_SUMMARY && this.componentVisible) {
                this.startInteractiveTutorial();
              }
            })
            this.cdr.markForCheck();
          });
      });

    this.loadCounters()
      .pipe(
        concat<number, MeasurementData[]>(
          new Observable((sub: Subscriber<MeasurementData[]>) => {
            if (
              this.counters[ValidationResultEnum[ValidationResultEnum.INVALID]]
                .value === 0 &&
              !this.positionFrozen
            ) {
              this.freezeDisabled = false;
              this.allValid.emit(true);
            }
            sub.complete();
            this.cdr.markForCheck();
          })
        )
      )
      .subscribe(
        () => {
          this.cdr.markForCheck();
        },
        (err) => {
          if (this.debugMode) {
            console.log(err);
          }
        },
        () => {
          this.dataFetched = true;
          this.cdr.markForCheck();
        }
      );

    this.onFilterEvents().subscribe((filter: string) => {
      if (this.debugMode) {
        console.warn(`New Data triggered by filtering event`);
      }
      switch (filter) {

        case "VALID": {
          this.globalFilterCommunication(true, false, false, false, false);
          break;
        }
        case "INVALID": {
          this.globalFilterCommunication(false, true, false, false, false);
          break;
        }
        case "AUTOCORRECTION": {
          this.globalFilterCommunication(false, false, false, false, true);
          break;
        }
        case "MANUALCORRECTION": {
          this.globalFilterCommunication(false, false, false, true, false);
          break;
        }
        default: {
          this.globalFilterCommunication(false, false, true, false, false);
          break;
        }
      }
    });
  }

  ifCollectiveCorrectionBtnDisabled() {
    if (
      this.changeFilterForm.get("options").value !== "INVALID" ||
      !this.loadAllInvalids ||
      this.checkedInvalids.length === 0 ||
      this.allInvalidUnchecked
    ) {
      return true;
    } else {
      return false;
    }
  }

  globalFilterCommunication(
    valid: boolean,
    invalid: boolean,
    showaAll: boolean,
    manualCorr: boolean,
    autoCorr: boolean
  ) {
    this.filterObj = {
      showAll: showaAll,
      valid: valid,
      invalid: { check: invalid, rules: this.choosenRules },
      autoCorrection: autoCorr,
      manualCorrection: manualCorr,
    };
  }

  onFreezData() {
    const request: IVeeRequest = {
      requestedStage: VeeProcessStageEnum.FREEZE,
      requestedStatus: VeeProcessStatusEnum.IN_PROGRESS,
    };
    this.veeProcessService
      .setVeeStateForPosition(
        this.positionIdIn,
        new VeeStatus(request),
        false,
        { emitEvent: true }
      )
      .pipe(
        concatMap<VeeStatus, Observable<Position>>(() => {
          return this.positionDataStoreService
            .getPositionById(this.positionIdIn)
            .pipe(
              concatMap<Position, Observable<Position>>((p: Position) => {
                const pos = Position.positionStateMachine(
                  p,
                  PositionTransitions.VALIDATION_FREEZED
                );
                pos.activeScheduler = false;
                return this.positionDataStoreService.updatePosition(pos, {
                  emitEvent: false,
                });
              })
            );
        })
      )
      .subscribe(
        () => {
          this.positionFrozen = true;
          this.positionFrozenObj = { frozen: true, firstValue: false };
          this.goToDisabled = false;
          this.freezeDisabled = true;
          this.cdr.markForCheck();
        },
        (err) => {
          console.error(err);
        },
        () => { }
      );
  }

  private initAppUnits() {
    this.unitsService
      .getMeasurementDefinitions()
      .subscribe((measDefinitions) => {
        this.primaryValues = measDefinitions.filter((val) => val.isPrimary);
      });
    this.unitsService
      .getApplicationUnits(this.cs.getCurrentMediaType())
      .subscribe((units) => {
        this.applicationUnits = units;
      });
  }

  private counterCounterObservableFactory(
    vr?: ValidationResultEnum | undefined
  ): Observable<number> {
    return new Observable((obs: Observer<void>) => {
      if (typeof vr === "undefined") {
        this.counters.TOTAL.pending = true;
      } else {
        this.counters[ValidationResultEnum[vr]].pending = true;
      }
      obs.next(undefined);
      obs.complete();
    }).pipe(
      mergeMap(() => {
        if (typeof vr === "undefined") {
          return this.positionDataStoreService.getPositionMeasurementDataCount(
            this.positionIdIn
          );
        } else if (vr === ValidationResultEnum.AUTOCORRECTION) {
          /* God have mercy in this... */
          return this.positionDataStoreService.getPositionMeasurementDataCount(
            this.positionIdIn,
            { estimation: VeeStatusEnum.DONE }
          );
        } else {
          return this.positionDataStoreService.getPositionMeasurementDataCount(
            this.positionIdIn,
            { validationResult: vr }
          );
        }
      }),
      tap((num: number) => {
        if (typeof vr === "undefined") {
          this.counters.TOTAL.value = num;
          this.counters.TOTAL.pending = false;
        } else {
          this.counters[ValidationResultEnum[vr]].value = num;
          this.counters[ValidationResultEnum[vr]].pending = false;
        }
      })
    );
  }

  private startInteractiveTutorial() {
    const steps = this.dataValidationInteractiveTutorialService.getDataValidationSummaryInteractiveTutorialSteps();
    this.interactiveTutorialService.startInteractiveTutorial(steps);
  }

  loadCounters(): Observable<number> {
    const Obs: Observable<any>[] = [
      this.counterCounterObservableFactory(),
      this.counterCounterObservableFactory(ValidationResultEnum.INVALID),
      this.counterCounterObservableFactory(ValidationResultEnum.AUTOCORRECTION),
      this.counterCounterObservableFactory(
        ValidationResultEnum.MANUALCORRECTION
      ),
    ];

    return merge(...Obs);
  }

  loadDataFunc() {
    this.dataLoading = true;
    this.positionDataStoreService.getPositionMeterPointsCount(this.positionIdIn).pipe(
      concatMap<number, Observable<MeterPoint[]>>((count) => {
        return this.positionDataStoreService
          .getPositionMeterPoints(this.positionIdIn, count, 0)
          .pipe(
            map<MeterPoint[], MeterPoint[]>((meters) => {
              meters.forEach((meter) => {
                meter["idPosition"] = this.positionIdIn;
                meter["positionFreezed"] = this.positionFrozen;
              });
              return meters;
            })
          );
      })
    )
      .subscribe((meters) => {
        this.dataSourceNew.data = meters;
        this.dataSourceNew.paginator = this.paginator;
        this.dataLength = meters.length;
        this.positionFrozenObj = {
          frozen: this.positionFrozen,
          firstValue: false,
        };
        this.dataLoading = false;
      });
  }

  onFilterEvents(): Observable<string> {
    return this.changeFilterForm.get("options").valueChanges;
  }

  existValidator(validator: string): boolean {
    return this.existsValidators.indexOf(ValidationTypes[validator]) !== -1;
  }

  isThisRowExpanded(row: any): boolean {
    return this.expandedElement !== null
      ? this.expandedElement.rowId === row.rowId
      : false;
  }

  handleMainRowClick(rowElement: any, actual?: any) {
    const element = event.target as HTMLElement;
    if (
      !(
        element.classList.contains("mat-checkbox-label") ||
        element.classList.contains("mat-checkbox-inner-container")
      )
    ) {
      if (
        this.expandedElement !== null &&
        this.expandedElement.rowId === rowElement.rowId
      ) {
        /* Same element clicked twice, hide expansion */
        console.warn("Hiding same");
        this.expandedElement = null;
      } else {
        /* New element clicked, expand it */
        console.warn("Estting new");
        this.expandedElement = rowElement;
      }
      return this.expandedElement;
    }
  }

  rowCanBeExpanded(index: number, row: any): boolean {
    return row.detailRow === true;
  }

  alwaysTrue(): boolean {
    return false;
  }

  isThisDetailElement(row: any): boolean {
    return (
      this.expandedElement !== null && this.expandedElement.rowId === +row.rowId
    );
  }

  /* FIXME: this is calling like a crazy by change detectino */
  detailsVisible(detail) {
    return this.expandedElement !== null
      ? detail.rowId === this.expandedElement.rowId
      : false;
  }

  canIRender(element) {
    return element["isVisible"] ? true : false;
  }

  isLoading(): boolean {
    if (this.dataSourceNew /* && this.dataSource.filteredData.length === 0 */) {
      return true;
    }
  }

  onGoToAnalysis(): void {
    this.setQuery("/data-analysis");
  }

  onGoToExport(): void {
    this.setQuery("/export");
  }

  setQuery(path: string) {
    const queryParams = this.route.snapshot.queryParams;
    if (
      (queryParams["schedule"] &&
        queryParams["schedule"] !== this.scheduleIdIn) ||
      (queryParams["position"] && queryParams["position"] !== this.positionIdIn)
    ) {
      console.warn(
        "Incompatible schedule and/or position id in control and in query parameter. Context will be set according to control"
      );
    }
    this.router.navigate([path], {
      queryParams: { schedule: this.scheduleIdIn, position: this.positionIdIn },
    });
  }

  ngOnDestroy(): void {
    clearTimeout(this.clearResponseFunc);
    try {
      this.radioBtnListSubs$.unsubscribe();
    } catch (e) {
      if (this.debugMode) {
        console.log(e);
      }
    }
    if (this.showTutorialSubscription) {
      this.showTutorialSubscription.unsubscribe();
    }
    if (this.canStartInteractiveTut$) {
      this.canStartInteractiveTut$.unsubscribe();
    }
  }

  getDisplayableBadge(element: MeasurementData) {
    if (element) {
      return element.getValidationFlagNameString();
    }
  }

  onCollectiveManualCorrection($event: MouseEvent) {
    this.onResponseCheckboxesValues();
    const setToSend: MeasurementData[] = new Array<MeasurementData>();
    this.askForCheckboxesValuesBehSubject.next(true);
  }

  setAllReadingsData(filterFlag: any) {
    this.positionDataStoreService
      .getPositionMeasurementDataCount(this.positionIdIn, filterFlag)
      .subscribe((num: number) => {
        this.checkedInvalids = [];
        this.checkedInvalidsCopy = [];
        this.existsValidators = new Array<number>();
        this.existsValidators.push(0);
        this.positionDataStoreService
          .getPositionMeasurementData(
            this.positionIdIn,
            { limit: num, offset: 0 },
            false,
            filterFlag
          )
          .subscribe((m: MeasurementData[]) => {
            this.allInvalidData = m;
            let tempArr = [];
            this.allInvalidData.forEach((element) => {
              tempArr.push({
                check: this.currentCheckBoxState,
                measurementData: element,
              });
              if (
                element.idValidationRule &&
                !this.existsValidators.find(
                  (v) => v === element.idValidationRule
                )
              ) {
                this.existsValidators.push(element.idValidationRule);
              }
              this.cdr.markForCheck();
            });
            this.checkedInvalids = tempArr;
            this.checkedInvalidsCopy = JSON.parse(
              JSON.stringify(this.checkedInvalids)
            );
          });
        this.changeFilterForm.controls["options"].setValue(
          this.changeFilterForm.get("options").value
        );
      });
  }
  onSelectUnSelect() {
    if (
      /*!this.choosenRules.includes(0)*/ this.choosenRules.indexOf(0) === -1
    ) {
      let filteringObject: any;
      this.changeFilterForm.get("meter").value !== ""
        ? (filteringObject = {
          validationResult: this.changeFilterForm.get("options").value,
        })
        : (filteringObject = {
          validationResult: this.changeFilterForm.get("options").value,
          idMeterPoint: this.changeFilterForm.get("meter").value,
        });

      this.positionDataStoreService
        .getPositionMeasurementDataCount(this.positionIdIn, filteringObject)
        .subscribe((count: number) => {
          return this.positionDataStoreService
            .getPositionMeasurementData(
              this.positionIdIn,
              { limit: count, offset: 0 },
              true,
              filteringObject
            )
            .subscribe((v: MeasurementData[]) => {
              this.currentCheckBoxState = !this.currentCheckBoxState;
              const measurementDataArray = v.filter(
                (k) => this.choosenRules.indexOf(k.idValidationRule) !== -1
              );
              this.checkedInvalids.forEach((element) => {
                measurementDataArray.filter(
                  (f) =>
                    f.idMeasurementData ===
                    element.measurementData.idMeasurementData
                ).length !== 0
                  ? (element.check = this.currentCheckBoxState)
                  : (element.check = false);
              });
              this.checkedInvalidsCopy = JSON.parse(
                JSON.stringify(this.checkedInvalids)
              );
              this.onCheckedInvalidsReadings(this.checkedInvalids);
              this.cdr.markForCheck();
            });
        });
    } else {
      this.currentCheckBoxState = !this.currentCheckBoxState;
      this.checkedInvalids.forEach((e) => {
        e.check = this.currentCheckBoxState;
      });
      this.checkedInvalidsCopy = JSON.parse(
        JSON.stringify(this.checkedInvalids)
      );
      this.onCheckedInvalidsReadings(this.checkedInvalids);
      this.cdr.markForCheck();
    }
  }

  onOffMeter(meter: MeasurementData, event: MatCheckboxChange) {
    if (
      this.checkedInvalids &&
      this.checkedInvalids.length !== 0 &&
      this.checkedInvalids.find(
        (v) => v.measurementData.idMeasurementData === meter.idMeasurementData
      )
    ) {
      this.checkedInvalids.find(
        (v) => v.measurementData.idMeasurementData === meter.idMeasurementData
      ).check = event.checked;
    }
  }

  getStatusChecked(row: MeasurementDataTabbed) {
    if (
      this.checkedInvalids &&
      this.checkedInvalids.length !== 0 &&
      this.checkedInvalids.find(
        (v) => v.measurementData.idMeasurementData === row.idMeasurementData
      )
    ) {
      return this.checkedInvalids.find(
        (v) => v.measurementData.idMeasurementData === row.idMeasurementData
      ).check;
    }
  }

  enableRuleChoice() {
    this.changeFilterForm.controls["validationTypes"].enable();
  }

  disableRuleChoice() {
    this.changeFilterForm.controls["validationTypes"].disable();
    this.changeFilterForm.controls["validationTypes"].setValue(undefined);
  }

  getRulesKeys(): Array<string> {
    const keys = Object.keys(ValidationTypes);
    return keys.slice(keys.length / 2);
  }

  getRulesValues(): Array<number> {
    const keys = Object.keys(ValidationTypes);
    const k = keys.slice(0, keys.length / 2).map(function (item) {
      return parseInt(item, 10);
    });
    return k;
  }

  onRuleRadioBtnChange(j: number) {
    if (this.loadAllInvalids) {
      this.choosenRules = [];
      let rulesList: IVeeRule[] = [];
      this.rulesArray.forEach((v, i) => {
        if (i === j) {
          rulesList = this.listRulesValidation.filter(
            (rule) => this.existsValidators.indexOf(rule.id) !== -1
          );
          if (i === 0) {
            this.currentRadioBtnValue = "All Rules";
            for (let p = 0; p < rulesList.length; p++) {
              if (!this.choosenRules.includes(rulesList[p].id)) {
                this.choosenRules.push(rulesList[p].id);
              }
            }
          } else {
            const rule = rulesList[i - 1];
            if (rule) {
              if (rule.rule_type === 'VALIDATION') {
                this.currentRadioBtnValue = this.dataValidationTranslationsHelper.loadValidationNameTranslation(rule.id) || rule.name;
              } else if (rule.rule_type === 'ESTIMATION') {
                this.currentRadioBtnValue = this.dataValidationTranslationsHelper.loadEstimationNameTranslation(rule.id) || rule.name;
              }
            }
            if (!this.choosenRules.includes(rulesList[i - 1].id)) {
              this.choosenRules.push(rulesList[i - 1].id);
            }
          }
        }
      });
      this.globalFilterCommunication(false, true, false, false, false);
      this.changeFilterForm.controls["options"].setValue("INVALID");
    }
  }

  resetCheckedPositionsState() {
    const usagesType = this.applicationUnits.units.find(
      (v) => v.name === this.currentMediaType
    );
    this.currentCheckBoxState = true;
    this.correctedValues = new Map<string, number>();
    from(this.allInvalidData).subscribe((c) => {
      this.correctedValues.set(
        c.idMeasurementData,
        c.getMeasurementDataSingle(usagesType.name)
      ); // input values
    });
  }

  resetCheckedRulesState() {
    this.rulesArray = [];
    this.choosenRules = [];
    this.veeRuleService.getVeeRules().subscribe((rules) => {
      this.positionDataStoreService
        .getPositionMeasurementDataCount(this.positionIdIn, {
          validationResult: ValidationResultEnum.INVALID,
        })
        .subscribe((num: number) => {
          this.positionDataStoreService
            .getPositionMeasurementData(
              this.positionIdIn,
              { limit: num, offset: 0 },
              false,
              { validationResult: ValidationResultEnum.INVALID }
            )
            .subscribe((m: MeasurementData[]) => {
              this.existsValidators.push(0);
              this.allInvalidData = m;
              this.allInvalidData.forEach((element) => {
                if (
                  element.idValidationRule &&
                  !this.existsValidators.find(
                    (v) => v === element.idValidationRule
                  )
                ) {
                  this.existsValidators.push(element.idValidationRule);
                }
              });
              // Remove duplicates
              this.existsValidators = this.existsValidators.filter(
                (item, index) => this.existsValidators.indexOf(item) === index
              );
              this.existsValidators.sort((a: number, b: number) => a - b);
              rules = rules.filter(
                (v) =>
                  v.rule_type === VeeRuleTypeEnum[VeeRuleTypeEnum.VALIDATION] &&
                  this.existsValidators.indexOf(v.id) !== -1
              );
              if (!this.rulesArray.includes(undefined)) {
                this.rulesArray.push(undefined);
              }
              for (let k = 0; k < rules.length; k++) {
                if (this.rulesArray.find((el) => el?.id === rules[k]?.id) === undefined) {
                  this.rulesArray.push(rules[k]);
                }
                if (!this.choosenRules.includes(rules[k].id)) {
                  this.choosenRules.push(rules[k].id);
                }
              }
              this.changeFilterForm.get("options").updateValueAndValidity();
              this.currentRadioBtnValue = "All Rules";
            });
        });
    });
  }

  getRadioBtnDisplayName(id: number) {
    if (typeof id !== "undefined") {
      if (this.rulesArray) {
        const rule = this.rulesArray.find((elem) => {
          if (elem) {
            return elem.id === id;
          }
        });
        if (rule) {
          if (rule.rule_type === 'VALIDATION') {
            return this.dataValidationTranslationsHelper.loadValidationNameTranslation(rule.id) || rule.name;
          } else if (rule.rule_type === 'ESTIMATION') {
            return this.dataValidationTranslationsHelper.loadEstimationNameTranslation(rule.id) || rule.name;
          }
        }
      }
    }
  }
}
