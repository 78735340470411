<div class="container">

	<h1 i18n="@@validation/validation panel title">Data Validation</h1>
	<sv-select (selectionEvent)="onSchedulePositionSelect($event)" [createNewAllowed]="true"></sv-select>

	<mat-accordion class="expansion-box">
		<button id="newRuleBtn" (click)="createRule($event)" mat-raised-button color="accent" class="btn btn-create-new"
			i18n="@@data validation/new rule">New Rule
		</button>
		<mat-expansion-panel id="validationRules" [expanded]="panelsState.panelOpenStateValidation">
			<mat-expansion-panel-header i18n="@@data validation/rules labels/validation">
				<i class="material-icons notranslate">{{rulesMdIcon}}</i>Validation rules
			</mat-expansion-panel-header>

			<form>
				<ul class="card-body list-group" dnd-sortable-container [sortableData]="listRulesValidation"
					[dropZones]="['validation']">
					<li *ngFor="let item of listRulesValidation; let i_validatoin = index" [dragEnabled]="true"
						dnd-sortable [sortableIndex]="i_validatoin"
						(onDropSuccess)="onDataDrop($event, item, VeeRuleTypeEnum.VALIDATION, i_validatoin)">
						<mat-checkbox [checked]="item.enabled" [disabled]="!item.switchable"
							(change)="onRuleClick($event, item)"
							[matTooltip]="dataValidationTranslationsHelper.loadValidationDescriptionTranslation(item.id) || item.description"
							matTooltipPosition="below" matTooltipShowDelay="500">
							{{dataValidationTranslationsHelper.loadValidationNameTranslation(item.id) || item.name}}
						</mat-checkbox>
					</li>
				</ul>
			</form>
		</mat-expansion-panel>

		<mat-expansion-panel id="estimationRules" [expanded]="panelsState.panelOpenStateEstimation">
			<mat-expansion-panel-header i18n="@@data validation/rules labels/estimation">
				<i class="material-icons notranslate">{{rulesMdIcon}}</i>Estimation rules
			</mat-expansion-panel-header>

			<form>
				<ul class="card-body list-group" dnd-sortable-container [sortableData]="listRulesEstimation"
					[dropZones]="['estimation']">
					<li *ngFor="let item of listRulesEstimation; let i_estimation = index" [dragEnabled]="true"
						dnd-sortable [sortableIndex]="i_estimation"
						(onDropSuccess)="onDataDrop($event, item, VeeRuleTypeEnum.ESTIMATION, i_estimation)">
						<mat-checkbox [checked]="item.enabled" [disabled]="!item.switchable"
							(change)="onRuleClick($event, item)"
							[matTooltip]="dataValidationTranslationsHelper.loadEstimationDescriptionTranslation(item.id) || item.description"
							matTooltipPosition="below" matTooltipShowDelay="500">
							{{ dataValidationTranslationsHelper.loadEstimationNameTranslation(item.id) || item.name }}
						</mat-checkbox>
					</li>
				</ul>
			</form>
		</mat-expansion-panel>
	</mat-accordion>

	<div class="card progress">
		<ng-container *ngIf="showProcessProgression === true">
			<div class="col" *ngFor="let step of veeProcessSteps; index as idx">
				<div class="space">
					<i *ngIf="step.statusEnum === VeeProcessStatusEnum.DONE"
						class="material-icons notranslate">check</i>
					<i *ngIf="step.statusEnum === VeeProcessStatusEnum.FAILED"
						class="material-icons notranslate">error</i>
					<mat-spinner *ngIf="step.statusEnum === VeeProcessStatusEnum.IN_PROGRESS" [strokeWidth]="3"
						[diameter]="20" class="notranslate"></mat-spinner>
				</div>
				<p
					*ngIf="step.statusEnum === VeeProcessStatusEnum.IN_PROGRESS && step.stage === validationLabel && countMeasurementData > 0 ; else elseBlock">
					{{countMeasurementData - countNotValidatedMeasurementData}} / {{countMeasurementData +' ' +
					step.stage + ': ' + step.status}}
				</p>
				<ng-template #elseBlock>
					<p>{{step.stage + ': ' + step.status}}</p>
				</ng-template>
			</div>
		</ng-container>
	</div>

	<div class="btn-box right-btn">
		<div [matTooltip]="getButtonTooltip()" [matTooltipDisabled]="!showFreezeTooltip && !showImportTooltip"
			matTooltipPosition="below" matTooltipShowDelay="500">
			<button id="validationBtn" *ngIf="validationButtonState.isVisible" [disabled]="validationButtonState.isDisabled"
				(click)="onValidationAction()" mat-raised-button color="accent"
				class="btn">{{validationButtonState.text}}
			</button>
		</div>
		<button id="seeResultsBtn" *ngIf="seeResultsButtonState.isVisible" [disabled]="seeResultsButtonState.isDisabled"
			(click)="onToggleShowResults()" mat-raised-button color="accent" class="btn marginLeft">{{seeResultsButtonState.text}}
		</button>
		<button id ="uploadBtn" *ngIf="uploadButton.isVisible" [disabled]="uploadButton.isDisabled" (click)="onUpload()"
			mat-raised-button color="primary" class="btn marginLeft">{{uploadButton.text}}
		</button>
	</div>

	<sv-data-validation-summary *ngIf="toggleValidation" [componentVisible]="toggleValidation" [positionIdIn]="positionId" [scheduleIdIn]="scheduleId"></sv-data-validation-summary>
</div>
