import { MeterPoint, MeasurementData } from '@shared/models';
import { TableDataSource } from '@shared/models/tableDataSource.model';

export interface IFilterData {
  manual: boolean;
  file: boolean;
}

export class MeasurementDataTabbed extends MeasurementData {
  meterPointRef: MeterPoint;
  _serial: string;
}

export class MeasurementDataPreviewDataSource extends TableDataSource<MeasurementData, MeasurementDataTabbed> {

  protected renderData(data: MeasurementData[]): MeasurementDataTabbed[] {
    const ret: MeasurementDataTabbed[] = [];
    data.forEach(d => {
      const cpy: MeasurementDataTabbed = new MeasurementDataTabbed(d.getInterface(false));
      cpy.meterPointRef = d.meterPointRef;
      cpy._serial = d.meterPointRef.serialNumber;
      ret.push(cpy);
    });
    return ret;
  }
}
