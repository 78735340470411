import { OnInit, Component, Input, Output, EventEmitter, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MeterPoint } from '@shared/models';
import { MeasurementDataModel } from '@shared/models/appModels/measurementDataModel.model';
import { ApplicationUnitsDataStoreService } from '@shared/services/dataStore/applicationUnitsDataStore.service';
import { MatStepper } from '@angular/material/stepper';
import { ObjectWrapper } from 'app/components/common-component/input-with-autocomplete-and-chips/input-with-autoComp-and-chips.component.component';
import { InteractiveTutorialService, NextComponentName } from '@shared/services/interactiveTutorialService.service';
import { Subscription } from 'rxjs';
import { ExportInteractiveTutorialService } from '../export-interactive-tutorial.service';
import { debounceTime } from 'rxjs/operators';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'sv-custom-csv-data-next-step',
    templateUrl: 'custom-csv-data-next-step.component.html',
    styleUrls: ['custom-csv-data-next-step.component.sass']
})

export class CustomCsvDataNextStepComponent implements OnInit, OnDestroy {

    variables: MeasurementDataModel[] = [];
    variablesForComponent: ObjectWrapper[] = [];
    allVariablesInitial: ObjectWrapper[] = [];
    fetchingData: boolean = false;
    canStartInteractiveTut$: Subscription;

    @Input() data: MeterPoint[];
    @Input() stepper: MatStepper;
    @Output() selectedVariablesEventEmitter = new EventEmitter<MeasurementDataModel[]>();
    @Output() secondStepCompleted = new EventEmitter<boolean>();

    canGenerateComponent: boolean = false;
    addVariablesPlaceholder: string = $localize`:@@export/custom billings modal/add data model variables:Add data model variables...`;
    wrongVariable: string = $localize`:@@export/custom billings modal/wrong variable:There is no such variable or it is already in the pick list.`;
    variablesRequired: string = $localize`:@@export/variables required:Variables are required.`;

    constructor(private appUnitsDataService: ApplicationUnitsDataStoreService,
        private cdr: ChangeDetectorRef,
        private interactiveTutorialServ: InteractiveTutorialService,
        private exportInteractiveTutorialSteps: ExportInteractiveTutorialService) {}

    ngOnInit() {
        this.canStartInteractiveTut$ = this.interactiveTutorialServ.canGoToNextStepSubject.pipe(debounceTime(1000)).subscribe(val => {
            if (val === NextComponentName.EXPORT_MEASUREMENTS_SECOND_STEP) {
              this.startInteractiveTutorial();
            }
          });
        this.appUnitsDataService.getMeasurementDefinitions()
            .subscribe(
                data => {
                    data.sort((a, b) => {
                        if (a.id > b.id) {
                            return 1;
                        }
                        if (b.id > a.id) {
                            return -1;
                        }
                        return 0;
                    });
                    data = data.filter((v,i,a)=>a.findIndex(t=>(t.name === v.name))===i)
                    this.variablesForComponent = data.map( el => {
                        return { valueForDisplay: el.name, otherValues: el};
                    });
                    this.allVariablesInitial = this.variablesForComponent.slice();
                    this.canGenerateComponent = true;
                    this.cdr.markForCheck();
                    this.cdr.detectChanges();
                },
                (e) => console.log(e),
                () => console.log('Fetching Measurement Definition Complete!')
            );
    }

    ngOnDestroy() {
        if (this.canStartInteractiveTut$) {
            this.canStartInteractiveTut$.unsubscribe();
        }
    }

    startInteractiveTutorial() {
        const steps = this.exportInteractiveTutorialSteps.getExportCustomCsvNextStepInteractiveTutorialSteps();
        this.interactiveTutorialServ.startInteractiveTutorial(steps);
    }


    emitVariables() {
        this.selectedVariablesEventEmitter.emit(this.variables);
    }

    onGenerate() {
        this.secondStepCompleted.emit(true);
        this.emitVariables();
        this.interactiveTutorialServ.canGoToNextStepSubject.next(NextComponentName.EXPORT_MEASUREMENTS_SUMMARY);
        this.stepper.next();
    }

    selectedDataEvent(variables: MeasurementDataModel[]) {
        this.variables = variables;
        this.emitVariables();
        if (variables.length !== 0) {
            this.secondStepCompleted.emit(true);
          } else {
            this.secondStepCompleted.emit(false);
          }
    }

    onTypingEvent(value) {
        this.fetchingData = true;
        if (value !== null && value !== '') {
            let filterValue;
            filterValue = value.toLowerCase();
            const allVariablesCopy = this.allVariablesInitial.slice();
            this.variablesForComponent = allVariablesCopy.filter(el => el.otherValues.name.toLowerCase().indexOf(filterValue) === 0);
            this.fetchingData = false;
        } else {
            this.variablesForComponent = this.allVariablesInitial;
            this.fetchingData = false;
        }
    }

}
