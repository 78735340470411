import { Component, OnInit, Output, Input, EventEmitter, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Router, ActivatedRoute } from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSliderChange } from '@angular/material/slider';


/* External libraries */
import 'rxjs/add/operator/switchMap';
import { Observable } from 'rxjs/Observable';

/* Application services */
import { PositionDataStoreService } from '@shared/services/dataStore/positionDataStore.service';
import { CustomerDataStoreService } from '@shared/services/dataStore/customerDataStore.service';
import { ReadlistDataSource } from '../dataSource/readlistDataSource';
import { DynamicColumnsHandler, DynamicColumn } from '@shared/models/viewModels/tableDynamicColumns';

/* Application datatypes */
import { Position } from '@shared/models/appModels/position.model';
import { MeterPointDataSource } from './detail/dataSource/meterPointDataSource';

// Env
import { environment } from '@env/environment';
import { MeterPoint } from '@shared/models/appModels/meterPoint.model';
import { typesLocalisation } from '@shared/types/localisation';
import { AnalysisLocalisation as loc } from '../../data-analysis-localisation';
import { TableHost, NestedTableHost } from '@shared/types';
import { Subscriber } from 'rxjs/Subscriber';
import { IAnalysisAlgorithm, AnalysisAlgorithm } from '@shared/models/appModels/analysisAlgorithm.model';
import { AnalysisLocalisation } from '../../data-analysis-localisation';
import { AnalysisStatus } from '@shared/models/appModels/analysisStatus.model';
import { AnalysisStatusDataStoreService } from '@shared/services/dataStore/analysisProcessDataStore.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AnalysisAlgorithmDataStoreService } from '@shared/services/dataStore/analysisAlgorithmsDataStore.service';
import { MeterPointDataStoreService } from '@shared/services/dataStore/meterPointDataStore.service';
import { CommonAppDataService } from '@shared/services/commonAppData.service';
import { InteractiveTutorialService } from '@shared/services/interactiveTutorialService.service';
import { DataAnalaysisInteractiveTutorialService } from '../data-analysis-interactive-tutorial-service.service';

enum ColumnGroupsEnum {
  'META_DATA' = 0,
  'VALUES' = 1
}

interface DataInAlgorithm {
  nameAlgorithm: string;
  isData: boolean;
  positionId: number;
  idMeterPoint: number;
}
@Component({

  // tslint:disable-next-line:component-selector
  selector: 'sv-data-analysis-summary',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'data-analysis-summary.component.html',
  styleUrls: ['data-analysis-summary.component.sass'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(.4, 0, .2, 1)')),
    ]),
  ],
})
export class DataAnalysisSummaryComponent implements OnInit, OnDestroy, AfterViewInit, TableHost<MeterPoint>, NestedTableHost<MeterPoint> {

  countRecords: number;
  @Output() buttonNewAnalysisClick = new EventEmitter();
  @Output() buttonAction = new EventEmitter();
  @Input() @Output() analysisEvent: any;
  @Input() scheduleId: number;
  @Input() positionId: number;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('filter') filter: ElementRef;
  @Input() analysisStatus: AnalysisStatus;
  @Input() analysisD: boolean;
  @Output() @Input() actualAnalysisAlgorithm: IAnalysisAlgorithm;
  @Input() listAlgorithmsDetectionOfAnomalies: IAnalysisAlgorithm[];
  @Input() listAlgorithmsForecasting: IAnalysisAlgorithm[];
  @Input() @Output() title: Map<string, string>;
  listEnabledAlgorithm: IAnalysisAlgorithm[] = [];
  isDataInAlgorithm = new Array<DataInAlgorithm>();
  flag: boolean;
  iterator: number;
  readonly debugMode: boolean = environment.debug;
  dataSource: ReadlistDataSource;
  meterP: MeterPoint;
  @Output() start: any;
  @Output() end: any;
  sliderValue: number = 0;
  firstChange: boolean = true;
  readonly seeDetailsMdIcon: string = 'expand_more';
  readonly hideDetailsMdIcon: string = 'expand_less';
  readonly tableHeaders = loc[environment.language].texts.asset.assetDataPreview.tableHeaders;
  readonly noData = AnalysisLocalisation[environment.language].texts.asset.assetDataPreview.noData;
  readonly fieldsName = AnalysisLocalisation[environment.language].texts.asset.assetDataPreview.parametersField;
  readonly summary = AnalysisLocalisation[environment.language].texts.summary.summary;
  readonly deviation = AnalysisLocalisation[environment.language].texts.summary.deviation;
  readonly buttonLabel = loc[environment.language].texts.summary.buttonLabel;
  readonly userSelected = loc[environment.language].texts.summary.userSelected;
  //// Table
  columns: DynamicColumnsHandler<MeterPoint>;
  dataSourceAnalysis: MeterPointDataSource;
  expandedElement: any = null;
  actualShowElement: number;
  showProcessProgression: boolean = false;
  readonly itemsPage = typesLocalisation.ItemsPage[environment.language].texts;
  actual_position = 0;
  analysisMeterPoints = new Array<MeterPoint>();
  analysisMeterPointsFilter = new Array<MeterPoint>();
  analysisMeterPointsSlider = new Array<MeterPoint>();
  countMeterPoint: number;
  changeFilterForm: FormGroup;
  idNumberFilter: number = undefined;
  mapMeterPoint = new Map<MeterPoint, number>();
  dataFetched: boolean;
  dataLoading: boolean;
  dataLength: number;
  tutorialFunc;
  isExpansionDetailRow = (index, row) => row.hasOwnProperty('detailRow');
  isExpandedElement = (row: MeterPoint) => (this.actualShowElement === row.idMeterPoint && this.expandedElement !== null);
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private analysisProcessService: AnalysisStatusDataStoreService,
    public customerDataStoreService: CustomerDataStoreService,
    private positionDataStoreService: PositionDataStoreService,
    private analysisAlgorithmsService: AnalysisAlgorithmDataStoreService,
    private meterPointsStoreService: MeterPointDataStoreService,
    private interactiveTutorialService: InteractiveTutorialService,
    private dataAnalysisInteractiveTutorialService: DataAnalaysisInteractiveTutorialService
  ) {
    this.flag = true;
    this.iterator = 0;
    this.changeFilterForm = this.fb.group({
      meter: [''],
      address: ['']
    });
  }
  private generateBaseDynamicColumns(): DynamicColumn<MeterPoint>[] {
    const dataColumns: DynamicColumn<MeterPoint>[] = [
      {
        group: ColumnGroupsEnum.META_DATA,
        sort: false,
        columnDef: 'idMeter',
        header: this.tableHeaders.METER_ID,
        type: 'idMeter',
        valueKey: 'idMeter',
        classType: (row: MeterPoint) => `bla`,
        generateCellContent: (row: MeterPoint) => `bla`
      },
      {
        group: ColumnGroupsEnum.META_DATA,
        sort: false,
        columnDef: 'location',
        header: this.tableHeaders.LOCATION,
        type: 'location',
        valueKey: 'location',
        classType: (row: MeterPoint) => `bla`,
        generateCellContent: (row: MeterPoint) => `bla`
      },
      {
        group: ColumnGroupsEnum.META_DATA,
        sort: false,
        columnDef: 'measurementType',
        header: this.tableHeaders.MES_TYPE,
        type: 'measurementType',
        valueKey: 'measurementType',
        classType: (row: MeterPoint) => `bla`,
        generateCellContent: (row: MeterPoint) => `bla`
      },
      {
        group: ColumnGroupsEnum.META_DATA,
        sort: false,
        columnDef: 'customer',
        header: this.tableHeaders.CUSTOMER,
        type: 'customer',
        valueKey: 'customer',
        classType: (row: MeterPoint) => `bla`,
        generateCellContent: (row: MeterPoint) => `bla`
      },
      {
        group: ColumnGroupsEnum.META_DATA,
        sort: false,
        columnDef: 'segment',
        header: this.tableHeaders.SEGMENT,
        type: 'segment',
        valueKey: 'segment',
        classType: (row: MeterPoint) => `bla`,
        generateCellContent: (row: MeterPoint) => `bla`
      }
    ];
    this.cdr.markForCheck();
    return dataColumns;
  }

  ngOnInit() {
    this.columns = new DynamicColumnsHandler<MeterPoint>([], this.generateBaseDynamicColumns(), ['action']);
    this.route.queryParams.subscribe(params => {
      const positionId = +params.position || -1;
      const scheduleId = +params.schedule || -1;
      this.paginator._intl.itemsPerPageLabel = this.itemsPage.itemsPage;
      this.paginator._intl.nextPageLabel = this.itemsPage.nextPage;
      this.paginator._intl.previousPageLabel = this.itemsPage.previousPage;
      this.paginator._intl.lastPageLabel = this.itemsPage.lastPage;
      this.paginator._intl.firstPageLabel = this.itemsPage.firstPage;
      if (positionId !== -1) {
        this.actual_position = +params.position;
        this.positionId = this.actual_position;
        this.positionDataStoreService.getPositionById(positionId).subscribe(
          (v: Position) => {
            this.dataSourceAnalysis = new MeterPointDataSource();
            this.dataSourceAnalysis.setNewData(this.analysisMeterPoints);
            this.cdr.markForCheck();
          }, (error: any) => this.router.navigate(['/schedule']),
          () => { });
      }
      if (scheduleId !== -1) {
        this.scheduleId = scheduleId;
      }
    });
    if (this.actualAnalysisAlgorithm.algorithmType === 0) {
      this.listEnabledAlgorithm = this.listAlgorithmsDetectionOfAnomalies.filter(algorithm => algorithm.enabled === true);
      this.positionDataStoreService.getPositionAnalysisCount(this.positionId, { anomaly: false }).subscribe(num => {
        this.countMeterPoint = num;
        this.cdr.markForCheck();
      }, e => {
      });
    } else {
      this.positionDataStoreService.getPositionForecastingCount(this.positionId, { anomaly: false }).subscribe(num => {
        this.listEnabledAlgorithm = this.listAlgorithmsForecasting.filter(algorithm => algorithm.enabled === true);
        this.countMeterPoint = num;
        this.cdr.markForCheck();
      }, e => {
      });
    }

    this.dataLoading = true;
    this.changeFilterForm.valueChanges.subscribe(change => {
      this.firstChange = false;
      const limit = this.paginator.pageSize;
      const offset = this.paginator.pageIndex * this.paginator.pageSize;
      if (!this.dataSourceAnalysis) { return; }
      if (change.meter === '') {
        change.address === '' ?
          this.analysisMeterPoints = this.analysisMeterPointsFilter :
          this.analysisMeterPoints = this.analysisMeterPointsFilter.filter(a => a.meterPlacement &&
            a.meterPlacement.toLocaleLowerCase().includes(change.address.toLocaleLowerCase()));
        this.dataSourceAnalysis.setNewData(this.analysisMeterPoints);
        this.loadData(limit, offset);
        this.cdr.markForCheck();
      } else {
        change.address === '' ?
          this.analysisMeterPoints = this.analysisMeterPointsFilter.filter(a =>
            a.serialNumber.toLocaleLowerCase().startsWith(change.meter.toLocaleLowerCase())) :
          this.analysisMeterPoints = this.analysisMeterPointsFilter.filter(a => a.meterPlacement &&
            a.meterPlacement.toLocaleLowerCase().includes(change.address.toLocaleLowerCase()) &&
            a.serialNumber.toLocaleUpperCase().startsWith(change.meter.toLocaleUpperCase()));
        this.dataSourceAnalysis.setNewData(this.analysisMeterPoints);
        this.loadData(limit, offset);
        this.cdr.markForCheck();
      }

      this.analysisMeterPointsSlider = this.analysisMeterPoints;
      if (this.actualAnalysisAlgorithm.algorithmType === 0) {
        this.mapMeterPoint.clear();
        this.positionDataStoreService.getPositionAnalysis(this.positionId, { anomaly: false }).subscribe(v => {
          v.forEach(element => {
            if (this.analysisMeterPoints.find(a => a.idMeterPoint === element.idMeterPoint)) {
              const meter = this.analysisMeterPoints.find(a => a.idMeterPoint === element.idMeterPoint);
              const tmp = v.filter(a => a.idMeterPoint === meter.idMeterPoint);
              tmp.sort((a, b) => b.measurementDiff - a.measurementDiff);
              this.mapMeterPoint.set(meter, tmp[0].measurementDiff);
            }
          });
          this.setAnalysisMeterPoints();
        }, e => {
        });
      }
    }, e => {
    });
  }
  onPaginatorEvents(paginator: MatPaginator): Observable<any[]> {
    return paginator.page
      .concatMap<PageEvent, MeterPoint[]>((pageEvent: PageEvent) => {
        const limit = pageEvent.pageSize;
        const offset = pageEvent.pageIndex * pageEvent.pageSize;
        return this.loadData(limit, offset);
      });
  }

  private getAnalysisAlgorithms() {
    this.listAlgorithmsDetectionOfAnomalies = [];
    this.listAlgorithmsForecasting = [];
    this.analysisAlgorithmsService.getAnalysisAlgorithmsTypeDetectionOfAnomalies()
      .map<AnalysisAlgorithm[], AnalysisAlgorithm[]>((rules) => {
        return rules.sort((a, b) => a.idAnalysisAlgorithm - b.idAnalysisAlgorithm);
      })
      .concatMap<AnalysisAlgorithm[], AnalysisAlgorithm[]>((analysisAlgorithms: AnalysisAlgorithm[]) => {
        this.listAlgorithmsDetectionOfAnomalies = analysisAlgorithms.slice(0);
        return this.analysisAlgorithmsService.getAnalysisAlgorithmsTypeDetectionOfAnomalies();
      })
      .map<AnalysisAlgorithm[], AnalysisAlgorithm[]>((rules) => {
        return rules.sort((a, b) => a.idAnalysisAlgorithm - b.idAnalysisAlgorithm);
      })
      .subscribe((r: AnalysisAlgorithm[]) => {
        this.listAlgorithmsDetectionOfAnomalies = r.slice(0);
        if (this.listAlgorithmsDetectionOfAnomalies && this.listAlgorithmsDetectionOfAnomalies.find(f => f.enabled === true)) {
          this.actualAnalysisAlgorithm = r.slice(0)[0];
          this.listEnabledAlgorithm = this.listAlgorithmsDetectionOfAnomalies.filter(algorithm => algorithm.enabled === true);
        }
      },
        (e) => {
          console.error('Unable to fetch rules');
          console.error(e);
          this.listAlgorithmsDetectionOfAnomalies = (this.listAlgorithmsDetectionOfAnomalies.length === 0) ? [] : this.listAlgorithmsDetectionOfAnomalies;
        },
        () => {
          if (this.debugMode) { console.log('DetectionOfAnomalies algorithms fetch complete'); }
        });

    this.analysisAlgorithmsService.getAnalysisAlgorithmsTypeForecasting()
      .map<AnalysisAlgorithm[], AnalysisAlgorithm[]>((rules) => {
        return rules.sort((a, b) => a.idAnalysisAlgorithm - b.idAnalysisAlgorithm);
      })
      .concatMap<AnalysisAlgorithm[], AnalysisAlgorithm[]>((analysisAlgorithms: AnalysisAlgorithm[]) => {
        this.listAlgorithmsForecasting = analysisAlgorithms.slice(0);
        return this.analysisAlgorithmsService.getAnalysisAlgorithmsTypeForecasting();
      })
      .map<AnalysisAlgorithm[], AnalysisAlgorithm[]>((rules) => {
        return rules.sort((a, b) => a.idAnalysisAlgorithm - b.idAnalysisAlgorithm);
      })
      .subscribe((r: AnalysisAlgorithm[]) => {
        this.listAlgorithmsForecasting = r.slice(0);
        if (this.listAlgorithmsForecasting && this.listAlgorithmsForecasting.find(f => f.enabled === true)) {
          this.actualAnalysisAlgorithm = r.slice(0)[0];
          this.listEnabledAlgorithm = this.listAlgorithmsForecasting.filter(algorithm => algorithm.enabled === true);
        }
      },
        (e) => {
          console.error('Unable to fetch rules');
          console.error(e);
          this.listAlgorithmsForecasting = (this.listAlgorithmsForecasting.length === 0) ? [] : this.listAlgorithmsForecasting;
        },
        () => {
          if (this.debugMode) { console.log('Forecasting algorithms fetch complete'); }
        });
  }
  onExternalDataChangeEvent(changeInductors: Observable<any>[]): Observable<any[]> {
    return Observable.merge(...changeInductors)
      .concatMap<any, MeterPoint[]>((v: any) => {
        const limit = this.paginator.pageSize;
        const offset = this.paginator.pageIndex * limit;
        return this.loadData(limit, offset);
      });
  }

  onFilterEvents(): Observable<any[]> {
    return this.changeFilterForm.get('meter').valueChanges
      .concatMap<string, MeterPoint[]>((filter: string) => {
        /* Hide any expanded element */
        this.expandedElement = null;
        this.paginator.pageIndex = 0;
        const limit = this.paginator.pageSize;
        const offset = this.paginator.pageIndex * limit;
        return this.loadData(limit, offset);
      });
  }

  onClearFilters(): Observable<any[]> {
    this.expandedElement = null;
    const limit = this.paginator.pageSize;
    const offset = this.paginator.pageIndex * limit;
    return this.loadData(limit, offset);
  }

  isThisRowExpanded(row: any): boolean {
    if (this.expandedElement !== null) {
      return this.expandedElement.rowId === row.rowId;
    } else { /* No expanded element */
      return false;
    }
  }

  handleMainRowClick(rowElement: any, actual?: any) {
    if (this.expandedElement !== null
      && this.expandedElement.rowId === rowElement.rowId) {
      /* Same element clicked twice, hide expansion */
      console.warn('Hiding same');
      this.expandedElement = null;
    } else {
      /* New element clicked, expand it */
      console.warn('Estting new');
      this.expandedElement = rowElement;
    }
    return this.expandedElement;
  }

  rowCanBeExpanded(index: number, row: any): boolean {
    return (row.detailRow === true);
  }

  alwaysTrue(index: number, row: any): boolean {
    return false;
  }

  isThisDetailElement(row: any): boolean {
    return (this.expandedElement !== null
      && this.expandedElement.rowId === +row.rowId);
  }
  loadCounters(): Observable<number> {
    let Obs: Observable<any>[] = [
    ];
    return Observable.merge(...Obs);
  }



  loadData(limit: number, offset: number): Observable<any[]> {
    this.dataLoading = true;
    let initialObservable: Observable<MeterPoint[]>;
    this.actualAnalysisAlgorithm.algorithmType === 0 ?
      this.positionDataStoreService.getPositionAnalysisCount(this.positionId, { anomaly: false }).subscribe(v => {
        this.countRecords = v;
        this.cdr.markForCheck();
      }, e => {
      }) : this.positionDataStoreService.getPositionForecastingCount(this.positionId, { anomaly: false }).subscribe(v => {
        this.countRecords = v;
        this.cdr.markForCheck();
      }, e => {
      });

    let tmp = new Array<MeterPoint>();
    let i = 0;
    this.analysisMeterPoints.forEach(element => {
      if (i >= offset && i < (limit + offset)) {
        tmp.push(element);
      }
      i = i + 1;
    });
    initialObservable = Observable.of(tmp);
    initialObservable.subscribe(v => {
      this.cdr.markForCheck();
    });
    this.buttonAction.emit(false);
    return initialObservable;
  }

  initialData(element: any, initalMap: boolean) {
    const isTypeDetection = (this.actualAnalysisAlgorithm.algorithmType === 0);
    let flag = false;
    let ids = [];
    element.forEach(v => {
      if (!ids.find(id => id === v.idMeterPoint)) {
        ids.push(v.idMeterPoint);
      }
    });
    this.meterPointsStoreService.getMeterPointsListAll({ limit: ids.length, offset: 0 }, true, ids).subscribe(p => {
      element.forEach(e => {
        if (isTypeDetection ?
          (p.find(f => f.idMeterPoint === e.idMeterPoint && this.listEnabledAlgorithm.filter(a => a.name === e.algorithm).length !== 0)) :
          p.find(f => f.idMeterPoint === e.idMeterPoint)) {
          if (!flag) {
            flag = true;
            const sort = element.filter(a => a.analysedPositionId === this.positionId && a.idMeterPoint === e.idMeterPoint);
            const size = sort.length;
            sort.sort((a, b) => a.timestamp - b.timestamp);
            const datum = sort[size - 1].timestamp;
            this.start = datum;
            this.end = sort[0].timestamp;
          }
          (isTypeDetection) ?
            this.meterP = p.find(f => f.idMeterPoint === e.idMeterPoint && this.listEnabledAlgorithm.filter(a => a.name === e.algorithm).length !== 0) :
            this.meterP = p.find(f => f.idMeterPoint === e.idMeterPoint);

          this.isDataInAlgorithm.push({
            nameAlgorithm: isTypeDetection ? e.algorithm : 'periodic profile',
            isData: true,
            positionId: e.analysedPositionId,
            idMeterPoint: e.idMeterPoint
          });
          if ((isTypeDetection) ?
            (!this.analysisMeterPoints.find(z => z.idMeterPoint === this.meterP.idMeterPoint &&
              this.listEnabledAlgorithm.filter(a => a.name === e.algorithm).length !== 0)) :
            (!this.analysisMeterPoints.find(z => z.idMeterPoint === this.meterP.idMeterPoint))) {
            this.analysisMeterPoints.push(this.meterP);
            this.analysisMeterPointsFilter.push(this.meterP);
            this.analysisMeterPointsSlider.push(this.meterP);
          }
        }
        if (this.analysisMeterPoints.find(a => a.idMeterPoint === e.idMeterPoint) && initalMap === true) {
          const meter = this.analysisMeterPoints.find(a => a.idMeterPoint === e.idMeterPoint);
          const tmp = element.filter(a => a.idMeterPoint === meter.idMeterPoint);
          tmp.sort((a, b) => b.measurementDiff - a.measurementDiff);
          this.mapMeterPoint.set(meter, tmp[0].measurementDiff);
        }
      });
      this.firstLoadData();
    });
  }

  startInteractiveTutorial(){
    const steps = this.dataAnalysisInteractiveTutorialService.getDataAnalysisSummaryInteractiveTutorialSteps(this.actualAnalysisAlgorithm.algorithmType);
    this.interactiveTutorialService.startInteractiveTutorial(steps);
  }

  ngAfterViewInit(): void {
    this.getAnalysisAlgorithms();
    this.isDataInAlgorithm = [];
    if (this.analysisD) {
      this.analysisEvent.aggregation = this.analysisStatus.aggregation;
      this.analysisEvent.meter = this.analysisStatus.meterId;
      this.analysisEvent.startDate = this.analysisStatus.startDate;
      this.analysisEvent.endDate = this.analysisStatus.endDate;
      this.analysisEvent.address = this.analysisStatus.address;
    }
    this.paginator._intl.itemsPerPageLabel = this.itemsPage.itemsPage;
    this.paginator._intl.nextPageLabel = this.itemsPage.nextPage;
    this.paginator._intl.previousPageLabel = this.itemsPage.previousPage;

    if (this.actualAnalysisAlgorithm.algorithmType === 0) {
      this.positionDataStoreService.getPositionAnalysis(this.positionId, { anomaly: false }).subscribe(element => {
        this.mapMeterPoint.clear();
        this.initialData(element, true);
      }, e => {
      });

    } else {
      this.positionDataStoreService.getPositionForecasting(this.positionId).subscribe(element => {
        this.initialData(element, false);
      }, e => {
        console.log('error', e);
      });
    }
    this.helperMetods();
    this.cdr.detectChanges();
    this.tutorialFunc = setTimeout(()=> this.startInteractiveTutorial(), 1500); 
  }

  ngOnDestroy(): void {
    clearTimeout(this.tutorialFunc);
  }

  firstLoadData() {
    const limit = this.paginator.pageSize;
    const offset = this.paginator.pageIndex * this.paginator.pageSize;
    this.loadData(limit, offset)
      .concatMap<MeterPoint[], MeterPoint[]>(pagedData => {
        this.dataSourceAnalysis.setNewData(pagedData);
        return this.loadCounters()
          .concat<number, MeterPoint[]>(
            Observable.create((sub: Subscriber<MeterPoint[]>) => {
              sub.next(pagedData);
              sub.complete();
            })
          );
      })
      .concatMap<MeterPoint[], MeterPoint[]>(medS => {
        let algorithmType: string;
        (this.actualAnalysisAlgorithm &&
          this.actualAnalysisAlgorithm.algorithmType === 'DETECTION_OF_ANOMALIES') ? algorithmType = 'DETECTION_OF_ANOMALIES' : algorithmType = 'FORECASTING';
        return this.analysisProcessService.getAnalysisStateForPosition(this.positionId, algorithmType, false)
          .concatMap<AnalysisStatus, MeterPoint[]>(status => {
            return Observable.of(medS);
          });
      })
      .subscribe((pagedData: MeterPoint[]) => {
        this.cdr.markForCheck();
      },
        (err) => { if (this.debugMode) { console.log(err); } },
        () => {
          this.dataFetched = true;
          this.cdr.markForCheck();
        });
  }

  helperMetods() {

    this.loadCounters()
      .subscribe(v => { });

    this.onPaginatorEvents(this.paginator)
      .subscribe((pagedData: MeterPoint[]) => {
        if (this.debugMode) { console.warn(`New Data triggered by paginator event`); }
        this.dataSourceAnalysis.setNewData(pagedData);
        this.cdr.markForCheck();
      });
    this.onFilterEvents()
      .subscribe((pagedData: MeterPoint[]) => {
        if (this.debugMode) { console.warn(`New Data triggered by filtering event`); }
        this.dataSourceAnalysis.setNewData(pagedData);
        this.cdr.markForCheck();
      });

    this.onExternalDataChangeEvent([this.positionDataStoreService.getChangeObservable()])
      .concatMap<MeterPoint[], MeterPoint[]>(pagedData => {
        this.dataSourceAnalysis.setNewData(pagedData);
        return this.loadCounters()
          .concat(Observable.of(pagedData));
      })
      .subscribe((pagedData: MeterPoint[]) => {
        this.cdr.markForCheck();
      },
        (e) => { },
        () => { });
  }

  getDataSourceLength(): number {
    if (this.analysisMeterPoints) {
      return this.analysisMeterPoints.length;
    }
  }

  isLoading(): boolean {
    if (!this.dataSourceAnalysis || !this.analysisMeterPoints) {
      return true;
    }
  }

  getCountAll(): Observable<number> {
    return Observable.of(this.analysisMeterPoints.length);
  }

  handleIfRowClick(row: MeterPoint): boolean {
    return this.expandedElement === row;
  }

  handleHeaderRowClick(row: MeterPoint): void {
    if (this.expandedElement === row) {
      this.expandedElement = null;
    } else {
      this.expandedElement = row;
      this.actualShowElement = row.idMeterPoint;
    }
  }

  handleHeaderRowEqualExpandedElementClick(row): string {
    return row.element === this.expandedElement ? 'expanded' : 'collapsed';
  }

  detailsVisible(detail) {
    return (this.expandedElement !== null) ? detail.rowId === this.expandedElement.rowId : false;
  }

  setAnalysisMeterPoints() {
    let tmpMeterPoint = new Array<MeterPoint>();
    const limit = this.paginator.pageSize;
    const offset = this.paginator.pageIndex * this.paginator.pageSize;
    this.analysisMeterPointsSlider.forEach(element => {
      if (this.mapMeterPoint.has(element) && this.mapMeterPoint.get(element) >= this.sliderValue) {
        tmpMeterPoint.push(element);
      }
    });
    this.analysisMeterPoints = tmpMeterPoint;
    this.dataSourceAnalysis.setNewData(this.analysisMeterPoints);
    this.loadData(limit, offset);
    this.cdr.markForCheck();
  }

  changeSliderValue(event: MatSliderChange) {
    this.sliderValue = event.value;
    this.setAnalysisMeterPoints();
  }

}
