
import { UploadFile, UploadProgress} from '@ngx-uploader';

import { RestEnumMpapper } from '@shared/models/RestSupport/restEnumMapper.model';
import { IGetInterfaceType, FileExtensionEnum } from '@shared/types/applicationTypes';


export interface IFileMeterData {
  scheduleName?: string;
  positionName?: string;
  idFileMeterData: number;
  idPosition: number;
  name: string;
  quantity: number;
  anomalies: number;
  creationDate: number;
  file: FileExtensionEnum | string;
  size: number;
}

export class FileMeterData implements IFileMeterData, UploadFile, IGetInterfaceType<IFileMeterData, FileMeterData> {

  private static fileExtEnumMapper: RestEnumMpapper<typeof FileExtensionEnum> = new RestEnumMpapper<typeof FileExtensionEnum>();
  /* Main REST api */
  idFileMeterData: number;
  idPosition: number;
  quantity: number;
  anomalies: number;
  creationDate: number;
  file: FileExtensionEnum | string;
  /* Shared properties */
  name: string;
  size: number;
  /* Ng-uploader extension */
  id: string;
  fileIndex: number;
  type: string;
  form: FormData;
  progress: UploadProgress;
  lastModifiedDate: Date;

  scheduleName?: string;
  positionName?: string;

  constructor(iFile: IFileMeterData ) {
    if (!iFile) { throw new TypeError('Customer initialization: params is undefined !'); }

    this.idFileMeterData = (typeof(iFile.idFileMeterData) !== 'undefined') ? iFile.idFileMeterData : undefined;
    this.idPosition = (typeof(iFile.idPosition) !== 'undefined') ? iFile.idPosition : undefined;
    this.quantity = (typeof(iFile.quantity) !== 'undefined') ? iFile.quantity : undefined;
    this.anomalies = (typeof(iFile.anomalies) !== 'undefined') ? iFile.anomalies : undefined;
    this.creationDate = (typeof(iFile.creationDate) !== 'undefined') ? iFile.creationDate : undefined;
    this.file = (typeof(iFile.file) !== 'undefined') ? FileMeterData.fileExtEnumMapper.getEnumAsNumber(FileExtensionEnum, iFile.file) : undefined;
    this.name = (typeof(iFile.name) !== 'undefined') ? iFile.name : undefined;
    this.size = (typeof(iFile.size) !== 'undefined') ? iFile.size : undefined;

    this.id =  '' + this.idFileMeterData;
    this.fileIndex = undefined;
    this.type = FileMeterData.fileExtEnumMapper.getEnumAsString(FileExtensionEnum, this.file);
    this.form = undefined;
    this.progress = undefined;
    this.lastModifiedDate = new Date(this.creationDate);

    this.positionName = undefined;
    this.scheduleName = undefined;
  }

  getInterface(webInterface: boolean = true): IFileMeterData {
    const emptyValue = (webInterface) ? null : undefined;
    return {
      idFileMeterData: this.idFileMeterData,
      anomalies: this.anomalies,
      idPosition: this.idPosition,
      creationDate: this.creationDate,
      file: FileMeterData.fileExtEnumMapper.getEnumAsString(FileExtensionEnum, this.file),
      name: this.name,
      quantity: this.quantity,
      size: (typeof(this.size) !== 'undefined') ? this.size : emptyValue
    };
  }

}
