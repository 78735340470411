// Angular modules import
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// RjJS imports
import { Observer } from 'rxjs/Observer';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/catch';

import { environment } from '@env/environment';

import { MeterPoint, IMeterPoint } from '@shared/models/appModels/meterPoint.model';
import { ICustomer, Customer } from '@shared/models/appModels/customer.model';
import { RestTypeMapper } from '@shared/models/RestSupport/restTypeMapper.model';

import { CustomerHttpParamG } from '@shared/types/http/customersHttpConfig';
import { EndpointsEnum } from '@shared/types/http/endpointEnum';
import { BooleanResult } from '@shared/types/http/httpType';

import { CommonAppDataService } from '@shared/services/commonAppData.service';
/* Datasource */
import { MockCustomers } from '@shared/mock';
import { RequestService } from './requestService.class';

@Injectable()
export class CustomerService extends RequestService {

  /* Mock datat storage */
  private customersMock: ICustomer[];

  /* Data Rest <-> Object Mappers */
  private customerMapper: RestTypeMapper<ICustomer, Customer>;
  private meterPointMapper: RestTypeMapper<IMeterPoint, MeterPoint>;

  /* Service debug conifguration */
  readonly debugMode: boolean = environment.debug;

  constructor(http: HttpClient, cs: CommonAppDataService) {
    super(http, cs, EndpointsEnum.CUSTOMER);
    if (this.MOCK_SERVICE) {
      this.customersMock = MockCustomers.slice(0);
    } else {
      this.customersMock = undefined;
    }
    /* Mappers initialization */
    this.customerMapper = new RestTypeMapper<ICustomer, Customer>();
    this.meterPointMapper = new RestTypeMapper<IMeterPoint, MeterPoint>();
  }

  getCustomersList(params: CustomerHttpParamG): Observable<Customer[]> {
    const thisApiID = 'getCustomersList';
    if (this.MOCK_SERVICE) {
      if (this.debugMode) { console.log(this.customersMock); }
      return Observable.of(this.customersMock)
        .map<ICustomer[], Customer[]>((listData: ICustomer[]): Customer[] => listData.map((x: ICustomer) => new Customer(x)))
        .delay<Customer[]>(this.MOCK_TIME);
    } else {
      return this.getRequest(params, this.customerMapper, thisApiID, Customer);
    }
  }

  postCustomers(params: CustomerHttpParamG): Observable<Customer> {
    const thisApiID = 'postCustomers';
    if (this.MOCK_SERVICE) {
      const item: Customer = params.body;
      const maxId = Math.max(...this.customersMock.map(cus => cus.idCustomer));
      item.idCustomer = maxId + 1;
      this.customersMock.forEach((el: Customer, idx: number) => {
        if (el.idCustomer === item.idCustomer) { throw new Error(`Customer with ID ${el.idCustomer} is already existing`); }
      });
      this.customersMock.push(item.getInterface(false));
      const obs: Observable<Customer> = Observable.of(item);
      return obs;
    } else {
      const bodyToSend: ICustomer = this.customerMapper.mapObjectToInterface(params.body);
      return this.postRequest(bodyToSend, params, this.customerMapper, thisApiID, Customer);
    }
  }

  getCustomerById(params: CustomerHttpParamG): Observable<Customer> {
    const thisApiID = 'getCustomersById';
    if (this.MOCK_SERVICE) {
      const id = +params.path.idCustomer;
      return Observable.of(new Object())
        .mapTo(this.customersMock)
        .map<ICustomer[], ICustomer>((schArr: ICustomer[]): ICustomer => schArr.find((x: ICustomer) => x.idCustomer === id))
        .delay<ICustomer>(this.MOCK_TIME)
        .map<ICustomer, Customer>((item: ICustomer): Customer => new Customer(item))
        .catch((error: any) => Observable.throw(error || new Error('Server error')));
    } else {
      return this.getRequest(params, this.customerMapper, thisApiID, Customer);
    }
  }

  putCustomersById(params: CustomerHttpParamG): Observable<Customer> {
    const thisApiID = 'putCustomersById';
    if (this.MOCK_SERVICE) {
      const item = params.body as Customer;
      this.customersMock.forEach((el: Customer, idx: number) => {
        if (el.idCustomer === item.idCustomer) { this.customersMock[idx] = item; }
      });
      const obs: Observable<Customer> = Observable.of(item);
      return obs;
    } else {
      const bodyToSend = this.customerMapper.mapObjectToInterface(params.body);
      return this.putRequest(bodyToSend, params, this.customerMapper, thisApiID, Customer);
    }
  }

  deleteCustomersById(params: CustomerHttpParamG): Observable<Customer> {
    const thisApiID = 'deleteCustomersById';
    if (this.MOCK_SERVICE) {
      const customerToDelete = params.body as Customer;
      return Observable.create((observer: Observer<Customer>) => {
        const id = this.customersMock.findIndex(el => el.idCustomer === customerToDelete.idCustomer);
        if (id !== -1) {
          const deleted = this.customersMock.splice(id, 1)[0];
          observer.next(new Customer(deleted));
          observer.complete();
        } else {
          if (params.config && params.config.strict === true) {
            observer.error('Unexisting ID');
          } else {
            observer.next({} as Customer);
          }
        }
      });
    } else {
      return this.deleteRequest(params, this.customerMapper, thisApiID, Customer);
    }
  }

  putCustomerActivation(params: CustomerHttpParamG): Observable<Customer> {
    const thisApiID = 'putCustomerActivation';
    if (this.MOCK_SERVICE) {
      return Observable.create((observer: Observer<Customer>) => {
        const serchId = +params.path.idCustomer;
        const id = this.customersMock.findIndex(el => el.idCustomer === serchId);
        if (id !== -1) {
          this.customersMock[id].active = (params.body as BooleanResult).value;
          observer.next(new Customer(this.customersMock[id]));
          observer.complete();
        } else {
          observer.error('Unexisting ID');
        }
      });
    } else {
      const bodyToSend = params.body as BooleanResult;
      return this.putRequest(bodyToSend, params, this.customerMapper, thisApiID, Customer);
    }
  }

  getCustomerMeterPoints(params: CustomerHttpParamG): Observable<MeterPoint[]> {
    const thisApiID = 'getCustomerMeterPoints';
    if (this.MOCK_SERVICE) {
      throw new Error('No mock implementation');
    } else {
      return this.getRequest(params, this.meterPointMapper, thisApiID, MeterPoint);
    }
  }

  getCustomersListCount(params: CustomerHttpParamG): Observable<number> {
    const thisApiID = 'getCustomersListCount';
    if (this.MOCK_SERVICE) {
      throw new Error('No mock implementation');
    } else {
      return this.getCountRequest(params, thisApiID);
    }
  }
}
