import { Injectable } from "@angular/core";
import {
  InteractiveTutorialService,
  NextComponentName,
  TutorialButtonLabel,
} from "@shared/services/interactiveTutorialService.service";
import { BehaviorSubject, of, Subscription } from "rxjs";
import { Observable } from "rxjs";
import { switchMap } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class TimelineInteractiveTutorialService {
  hasNoPositionsSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  hasNoPositions: boolean;
  hasNoSchedulesSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  hasNoSchedules: boolean;
  timelineSubscription: Subscription;

  constructor(private interactiveTutorialServ: InteractiveTutorialService) {}

  timelineComponentCondition(): Observable<boolean> {
    return this.hasNoPositionsSubject.pipe(
      switchMap((val) => {
        this.hasNoPositions = val;
        return this.hasNoSchedulesSubject;
      })
    );
  }

  getTimelineInteractiveTutorialSteps(): Observable<unknown[]> {
    return this.timelineComponentCondition().pipe(
      switchMap((val) => {
        this.hasNoSchedules = val;
        const step1 = this.interactiveTutorialServ.generateStep(
          "#svAddPositionBtn",
          "bottom",
          $localize`:@@interactive tutorial/timeline/no position:The schedule you selected has no positions.In order to create position, click on this button.`,
          $localize`:@@interactive tutorial/timeline/no position title:Lack of positions`,
          [
            TutorialButtonLabel.BACK,
            TutorialButtonLabel.COMPLETE_NEXT_COMPONENT,
          ],
          () => {
            if (this.hasNoPositions) {
              return true;
            } else {
              return false;
            }
          },
          undefined,
          {
            complete: () => {
              this.interactiveTutorialServ.canGoToNextStepSubject.next(
                NextComponentName.TIMELINE_DATA_VALIDATION_WIDGET
              );
            },
          }
        );
        const step2 = this.interactiveTutorialServ.generateStep(
          "#svAddSchedulesBtn",
          "bottom",
          $localize`:@@interactive tutorial/timeline/no schedules:You currently have no schedules. To continue working with the application, create a schedule that has an position. You can click on this button in order to do this.`,
          $localize`:@@interactive tutorial/timeline/no schedules title:Lack of schedules`,
          [
            TutorialButtonLabel.BACK,
            TutorialButtonLabel.COMPLETE_NEXT_COMPONENT,
          ],
          () => {
            if (this.hasNoSchedules) {
              return true;
            } else {
              return false;
            }
          },
          undefined,
          {
            complete: () => {
              this.interactiveTutorialServ.canGoToNextStepSubject.next(
                NextComponentName.TIMELINE_DATA_VALIDATION_WIDGET
              );
            },
          }
        );
        const step3 = this.interactiveTutorialServ.generateStep(
          "#svTimeline",
          "bottom",
          $localize`:@@interactive tutorial/timeline/timeline graph:The timeline presents events related to working with data in the application (e.g. import, validation or data analysis). Thanks to it, we can quickly check at what stage of work with data we are currently.`,
          $localize`:@@interactive tutorial/timeline/timeline graph title:Timeline`,
          [
            TutorialButtonLabel.BACK,
            TutorialButtonLabel.COMPLETE_NEXT_COMPONENT,
          ],
          () => {
            if (!this.hasNoPositions && !this.hasNoSchedules) {
              return true;
            } else {
              return false;
            }
          },
          undefined,
          {
            complete: () => {
              this.interactiveTutorialServ.canGoToNextStepSubject.next(
                NextComponentName.TIMELINE_DATA_VALIDATION_WIDGET
              );
            },
          }
        );
        const step = this.interactiveTutorialServ.generateStep(
          "#scheduleSelect",
          "bottom",
          $localize`:@@interactive tutorial/timeline/schedules selector:Using this field you can select the schedule that interests you.When you do this, an event timeline will be generated representing the validation events etc.. If you do not have a schedule or it does not have an position, you will be asked to create them.`,
          $localize`:@@interactive tutorial/timeline/schedules selector title:Schedule selection`,
          [TutorialButtonLabel.NEXT]
        );
        const steps: unknown[] = [step, step1, step2, step3];
        return of(steps);
      })
    );
  }

  getDataValidationTimelineWidgetTutorialSteps() {
    const step = this.interactiveTutorialServ.generateStep(
      "#validationWidgetContainer",
      "top",
      $localize`:@@interactive tutorial/timeline/timeline validation widget:The validation widget presents information about the ongoing and previous data validation of the current schedule.`,
      $localize`:@@interactive tutorial/timeline/timeline validation widget title:Validation widget`,
      [TutorialButtonLabel.COMPLETE_NEXT_COMPONENT],
      undefined,
      undefined,
      {
        complete: () => {
          this.interactiveTutorialServ.canGoToNextStepSubject.next(
            NextComponentName.TIMELINE_DATA_ANALYSIS_WIDGET
          );
        },
      }
    );
    const steps: unknown[] = [step];
    return steps;
  }
  getDataAnalysisTimelineWidgetTutorialSteps() {
    const step = this.interactiveTutorialServ.generateStep(
      "#analysisWidgetContainer",
      "top",
      $localize`:@@interactive tutorial/timeline/timeline analysis widget:The analysis widget provides information on the analysis of data on the current schedule.`,
      $localize`:@@interactive tutorial/timeline/timeline analysis widget title:Analysis widget`,
      [TutorialButtonLabel.COMPLETE_NEXT_COMPONENT],
      undefined,
      undefined,
      {
        complete: () => {
          this.interactiveTutorialServ.canGoToNextStepSubject.next(
            NextComponentName.TIMELINE_DATA_EXPLORATION_WIDGET
          );
        },
      }
    );
    const steps: unknown[] = [step];
    return steps;
  }

  getDataExplorationTimelineWidgetTutorialSteps() {
    const step = this.interactiveTutorialServ.generateStep(
      "#dataExplorationWidgetContainer",
      "top",
      $localize`:@@interactive tutorial/timeline/timeline exploration widget:The exploration widget presents a list of available exploration dashboards.`,
      $localize`:@@interactive tutorial/timeline/timeline exploration widget title:Exploration widget`,
      [TutorialButtonLabel.COMPLETE]
    );
    const steps: unknown[] = [step];
    return steps;
  }
}
