import { Component, Input, OnDestroy, OnInit } from "@angular/core";

import { EventStatus, EventType } from "@shared/types";
import { PositionDataStoreService } from "@shared/services/dataStore/positionDataStore.service";
import { Router } from "@angular/router";
import { Schedule, Position } from "@shared/models";
import { AnalysisStatusDataStoreService } from "@shared/services/dataStore/analysisProcessDataStore.service";
import {
  map,
  shareReplay,
  distinctUntilChanged,
  startWith,
  switchMap,
  concatMap,
  delay,
  catchError,
  debounceTime,
} from "rxjs/operators";
import { forkJoin, Subject, Observable, of, Subscription } from "rxjs";
import { InteractiveTutorialService, NextComponentName } from "@shared/services/interactiveTutorialService.service";
import { TimelineInteractiveTutorialService } from "app/components/timeline/timelineInteractiveTutorialService.service";

interface IAnalysisStatistics {
  meterCount: number;
  analysedMetersCount: number;
  anomaliesCount: number;
  forecastedMetersCount: number;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: "sv-data-analysis-widget",
  templateUrl: "data-analysis-widget.component.html",
  styleUrls: ["data-analysis-widget.component.sass"],
})
export class DataAnalysisWidgetComponent implements OnInit, OnDestroy {
  private currentSchedule$: Subject<Schedule> = new Subject();

  lastAnalysedPosition$: Observable<Position>;
  analysisData$: Observable<IAnalysisStatistics | "loading">;

  chartLabels: string[] = [
    $localize`:@@analysis/anomalies/anomalies count label:Anomalies`,
    $localize`:@@analysis/anomalies/valid count label:Valid`,
  ];

  canStartInteractiveTut$: Subscription;

  @Input() set schedule(schedule: Schedule) {
    this.currentSchedule$.next(schedule);
  }

  constructor(
    private router: Router,
    private positionDataStoreService: PositionDataStoreService,
    private analysisStatusDataStoreService: AnalysisStatusDataStoreService,
    private interactiveTutorialServ: InteractiveTutorialService,
    private timelineInteractiveTutorialServ: TimelineInteractiveTutorialService,
  ) {
    this.lastAnalysedPosition$ = this.currentSchedule$.pipe(
      map(this.getMostRecentDone, this),
      distinctUntilChanged(),
      catchError((_) => of(undefined)),
      shareReplay()
    );
    this.analysisData$ = this.lastAnalysedPosition$.pipe(
      concatMap((position) =>
          this.fetchAnalysisStats(position).pipe(
          delay(500),
          startWith("loading" as "loading"),
          catchError((_) => of({} as IAnalysisStatistics))
        )
      )
    );
  }

  ngOnInit(){
    this.canStartInteractiveTut$ = this.interactiveTutorialServ.canGoToNextStepSubject.pipe(debounceTime(100)).subscribe(val => {
      if(val === NextComponentName.TIMELINE_DATA_ANALYSIS_WIDGET){
        this.startInteractiveTutorial();
      }
    })
  }

  private startInteractiveTutorial(){
    const steps = this.timelineInteractiveTutorialServ.getDataAnalysisTimelineWidgetTutorialSteps();
    this.interactiveTutorialServ.startInteractiveTutorial(steps);
  }

  ngOnDestroy(){
    if(this.canStartInteractiveTut$){
      this.canStartInteractiveTut$.unsubscribe();
    }
  }

  private isCompleted(status: EventStatus): boolean {
    return [EventStatus.DONE, EventStatus.CLOSED].includes(status);
  }

  private getMostRecentDone(schedule: Schedule): Position {
    let result: Position = undefined;
    if (!schedule) {
      return result;
    }
    for (let p of schedule.getPositions()) {
      const events = p.events;
      if (
        !this.isCompleted(events[EventType.VALIDATION].status) ||
        !this.isCompleted(events[EventType.ANALYSIS].status)
      ) {
        continue;
      }

      if (
        result === undefined ||
        events[EventType.ANALYSIS].date < result.events[EventType.ANALYSIS].date
      ) {
        result = p;
      }
    }
    return result;
  }

  private fetchAnalysisStats(
    position: Position
  ): Observable<IAnalysisStatistics> {
    const id = position.idPosition;

    const meterCount$ = this.positionDataStoreService
      .getPositionMeterPointsCount(id)
      .pipe(shareReplay());
    const analysedMeterCount$ = this.analysisStatusDataStoreService
      .getAnalysisStateForPosition(id, "DETECTION_OF_ANOMALIES", true)
      .pipe(
        switchMap((status) => {
          if (![undefined, "", "all"].includes(status.meterId)) {
            return of(status.meterId.split(",").length);
          }
          return meterCount$;
        })
      );

    const forecastedCount$ = this.analysisStatusDataStoreService
      .getAnalysisStateForPosition(id, "FORECASTING", true)
      .pipe(
        switchMap((status) => {
          if (status.meterId === undefined) {
            return of(0);
          }
          return this.positionDataStoreService.getPositionForecastingCount(id);
        })
      );

    return forkJoin([
      meterCount$,
      analysedMeterCount$,
      this.positionDataStoreService.getPositionAnalysisCount(id, {
        anomaly: true,
      }),
      forecastedCount$,
    ]).pipe(
      map(
        ([
          meterCount,
          analysedMetersCount,
          anomaliesCount,
          forecastedMetersCount,
        ]) => ({
          meterCount: meterCount,
          anomaliesCount: anomaliesCount,
          analysedMetersCount: analysedMetersCount,
          forecastedMetersCount: forecastedMetersCount,
        })
      )
    );
  }

  navigateToAnalysis(position: Position): void {
    if (!position) {
      return;
    }
    this.router.navigate(["/data-analysis"], {
      queryParams: { position: position.idPosition },
      queryParamsHandling: "merge",
    });
  }
}
