
import { IGetInterfaceType } from '@shared/types/applicationTypes';
import { UtilityTypeEnum } from '@shared/types/utilityType';
import { MediaTypeUnit } from '@shared/models/appModels/MediaTypeUnit.model';
import { MediaTypeParameter } from '@shared/models/appModels/MediaTypeParameter.mode';
import { BaseModel } from '@shared/models/baseModel';

export interface IApplicationUnits {
  units: {
    unit: string;
    description: string;
    name: string;
    isBilled: boolean
  }[];
}

export interface UnitsInfo {
  unit: string;
  description: string;
  name: string;
  isBilled: boolean;
  columnDef: string;
  header: string;
  idx?: number;
}

export class ApplicationUnits extends BaseModel implements IApplicationUnits, IGetInterfaceType<IApplicationUnits, ApplicationUnits> {

  name: UtilityTypeEnum | string;
  id: number;
  units: UnitsInfo[];

  constructor(iIn: IApplicationUnits) {
    super();
    if (!iIn) { throw new TypeError('MediaType initialization: params is undefined !'); }

    this.units = iIn.units.map((el, id) => {
      return {
        unit: el.unit,
        description: el.description,
        name: el.name,
        isBilled: el.isBilled,
        columnDef: '',
        header: '',
        idx: id,
      };
    });
    this.units.forEach((unit, idx) => {
      unit.columnDef = this.generateColumnDef(idx);
      unit.header = this.generateColumnHeader(idx);
    });
  }

  static GenerateFromApi(IunitIn: MediaTypeUnit[], IcumulativeParams: MediaTypeParameter[]): ApplicationUnits {

    const mainUnitInfo: UnitsInfo[] = IunitIn.map<UnitsInfo>((u: MediaTypeUnit, idx: number) => {
      return {
        isBilled: true,
        name: u.name,
        unit: u.unit,
        description: u.description,
        columnDef: '',
        header: '',
        idx: idx,
      };
    });

    const baseIdx = mainUnitInfo.length;

    const otherUnitInfo: UnitsInfo[] = IcumulativeParams.map((p: MediaTypeParameter, idx: number) => {
      return {
        isBilled: false,
        name: p.name,
        unit: p.unit,
        description: p.description,
        columnDef: '',
        header: '',
        idx: idx + baseIdx,
      } as UnitsInfo;
    });

    const IappUnits: IApplicationUnits = {

      units: [...mainUnitInfo, ...otherUnitInfo]
    };
    return new ApplicationUnits(IappUnits);
  }

  generateColumnDef(id) {
    return this.units[id].name;
  }

  generateColumnHeader(id) {
    return this.units[id].name + ' [' + this.units[id].unit + ']';
  }

  getInterface(webInterface: boolean): IApplicationUnits {
    const emptyValue = (webInterface) ? null : undefined;
    const ret: IApplicationUnits = {units: []};
    this.units.forEach(unit => {
      ret.units.push({
        unit: (typeof(unit.unit) !== 'undefined') ? unit.unit : emptyValue,
        description: (typeof(unit.description) !== 'undefined') ? unit.description : emptyValue,
        name: (typeof(unit.name) !== 'undefined') ? unit.name : emptyValue,
        isBilled: (typeof(unit.isBilled) !== 'undefined') ? unit.isBilled : emptyValue,
      });
    });
    return ret;
  }

}
