// Angular modules import
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Application services
import { CommonAppDataService } from '@shared/services/commonAppData.service';

// RjJS imports
import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import 'rxjs/add/operator/catch';

import { IPosition, Position } from '@shared/models/appModels/position.model';
import { RestTypeMapper } from '@shared/models/RestSupport';
import { MeterData, IMeterData } from '@shared/models/appModels/meterData.model';
import { MeterPoint, IMeterPoint } from '@shared/models/appModels/meterPoint.model';
import { MeasurementData, IMeasurementData } from '@shared/models/appModels/measurementData.model';
import { PositionHttpParamG, PositionHttpParams } from '@shared/types/http/positionHttpConfig';
import { EndpointsEnum } from '@shared/types/http/endpointEnum';

/* Datasource */
import { MockPosition } from '@shared/mock/position.mock';
import { MockMeterPoint_Plain } from '@shared/mock/meterPoint.mock';
import { getMockMeasurmeentData } from '@shared/mock/measurementData.mock';
import { getMockMeterData } from '@shared/mock/meterData.mock';

import { environment } from '@env/environment';
import { Analysis, IAnalysis } from '@shared/models/appModels/analysis.model';
import { Forecasting, IForecasting } from '@shared/models/appModels/forecasting.model';
import { RequestService } from './requestService.class';
import { of } from 'rxjs';



@Injectable()
export class PositionHTTPService extends RequestService {

  /* Mocks */
  private positionsMock: IPosition[];
  private metersMock: IMeterPoint[];
  private measurementMock: IMeasurementData[];
  private meterDataMock: IMeterData[];
  private analysisMock: IAnalysis[];
  private forecastingMock: IForecasting[];

  /* Data Rest <-> Object Mappers */
  private positionMapper: RestTypeMapper<IPosition, Position>;

  private meterDataMapper: RestTypeMapper<IMeterData, MeterData>;
  private measurementDataMapper: RestTypeMapper<IMeasurementData, MeasurementData>;
  private meterPointMapper: RestTypeMapper<IMeterPoint, MeterPoint>;
  private analysisMapper: RestTypeMapper<IAnalysis, Analysis>;
  private forecastingMapper: RestTypeMapper<IForecasting, Forecasting>;

  readonly debugMode: boolean = environment.debug;
  /* Used for unsupported API endpoints */
  readonly patchApi: boolean = false;

  constructor(http: HttpClient,
    cs: CommonAppDataService) {

    super(http, cs, EndpointsEnum.POSITIONS);
    if (this.MOCK_SERVICE || this.patchApi) {
      this.positionsMock = MockPosition;
      this.metersMock = MockMeterPoint_Plain;
      this.measurementMock = getMockMeasurmeentData();
      this.meterDataMock = getMockMeterData();
    } else {
      /* Set to undefiend to trip-off service in case of miss-use */
      this.positionsMock = undefined;
      this.metersMock = undefined;
      this.measurementMock = undefined;
      this.meterDataMock = undefined;
    }

    /* Mappers init */
    this.positionMapper = new RestTypeMapper<IPosition, Position>();
    this.meterDataMapper = new RestTypeMapper<IMeterData, MeterData>();
    this.measurementDataMapper = new RestTypeMapper<IMeasurementData, MeasurementData>();
    this.meterPointMapper = new RestTypeMapper<IMeterPoint, MeterPoint>();
    this.analysisMapper = new RestTypeMapper<IAnalysis, Analysis>();
    this.forecastingMapper = new RestTypeMapper<IForecasting, Forecasting>();
  }

  getPositionsList(params: PositionHttpParamG = { queryParams: { limit: '50', offset: '0' } }): Observable<Position[]> {
    const thisApiID: string = 'getPositionsList';
    if (this.MOCK_SERVICE) {
      return Observable.create((observer: Observer<Position[]>) => {
        /* TODO: filtering logic on mocks */
        observer.next(this.positionMapper.mapInterfaceToObject(this.positionsMock, Position) as Position[]);
        observer.complete();
      })
        .delay(this.MOCK_TIME);
    } else {
      return this.getRequest(params, this.positionMapper, thisApiID, Position);
    }
  }

  postPositions(params: PositionHttpParamG): Observable<Position> {
    const thisApiID: string = 'postPositions';
    if (this.MOCK_SERVICE) {
      const newPosition: Position = params.body;
      return Observable.create((observer: Observer<Position>) => {
        const idArray = this.positionsMock.map(pos => pos.idPosition);
        const maxId = Math.max(...idArray);
        newPosition.idPosition = maxId + 1;
        const duplicateIdx: number = this.positionsMock.findIndex((pos: IPosition) => pos.idPosition === newPosition.idPosition);
        if (duplicateIdx !== -1) {
          observer.error(`Position with ID ${newPosition.idPosition} already existing - server erorr`);
        } else {
          this.positionsMock.push(this.positionMapper.mapObjectToInterface(newPosition));
          observer.next(newPosition);
          observer.complete();
        }
      });
    } else {
      const reqBody = this.positionMapper.mapObjectToInterface(params.body);
      return this.postRequest(reqBody, params, this.positionMapper, thisApiID, Position);
    }
  }

  getPositionsById(params: PositionHttpParamG): Observable<Position> {
    const thisApiID: string = 'getPositionsById';
    if (this.MOCK_SERVICE) {
      const id = +params.path.idPosition;
      return Observable.create((observer: Observer<Position>) => {
        const findIdx: number = this.positionsMock.findIndex((pos: Position) => pos.idPosition === id);
        if (findIdx !== -1) {
          const retPosition: Position = this.positionMapper.mapInterfaceToObject(this.positionsMock[findIdx], Position) as Position;
          observer.next(retPosition);
          observer.complete();
        } else {
          if (params.config && params.config.strict === true) {
            return Observable.throw('Non-existing position by ID');
          } else {
            return Observable.of({} as Position);
          }
        }
      });
    } else {
      return this.getRequest(params, this.positionMapper, thisApiID, Position);
    }
  }

  deletePositionsById(params: PositionHttpParamG): Observable<Position> {
    const thisApiID: string = 'deletePositionsById';
    if (this.MOCK_SERVICE) {
      return Observable.create((observer: Observer<Position>) => {
        const idx: number = this.positionsMock.findIndex(val => (+params.path.idPosition === val.idPosition));
        let returnBody: Position;
        if (idx !== -1) {
          returnBody = new Position(this.positionsMock.splice(idx, 1)[0]);
        } else {
          returnBody = undefined;
          observer.error(`Trying to delete non-existing position with id ${+params.path.idPosition}`);
        }
        observer.next(returnBody);
        observer.complete();
      });
    } else {
      return this.deleteRequest(params, this.positionMapper, thisApiID, Position);
    }
  }

  putPositionsById(params: PositionHttpParamG): Observable<Position> {
    const thisApiID: string = 'putPositionsById';
    const body: Position = params.body;
    if (this.MOCK_SERVICE) {
      return Observable.create((observer: Observer<Position>) => {

        const bodyIfc: IPosition = body as IPosition;
        const idx: number = this.positionsMock.findIndex((val) => (bodyIfc.idPosition === val.idPosition));
        if (idx !== -1) {
          const ret = this.positionMapper.mapInterfaceToObject(this.positionsMock.splice(idx, 1, body), Position) as Position;
          observer.next(ret);
          observer.complete();
        } else {
          observer.error(`Trying to update non-existing position with ID: ${body.idPosition}`);
        }
      });
    } else {
      const reqBody = this.positionMapper.mapObjectToInterface(params.body);
      return this.putRequest(reqBody, params, this.positionMapper, thisApiID, Position);
    }
  }

  getPositionsMeterdataById(params: PositionHttpParamG = { queryParams: { limit: '50', offset: '0' } }): Observable<MeterData[]> {
    const thisApiID = 'getPositionsMeterdataById';
    if (this.MOCK_SERVICE) {
      const relatedData = this.meterDataMock.filter(mes => mes.idPosition === +params.path.idPosition);
      const limited = relatedData.slice(+params.queryParams.offset, +params.queryParams.offset + (+params.queryParams.limit));
      return Observable.of(this.meterDataMapper.mapInterfaceToObject(limited, MeterData) as MeterData[]);
    } else {
      if (this.patchApi) {
        console.warn('Patched version of API called');
        let relatedData = this.meterDataMock.filter(mes => mes.idPosition === +params.path.idPosition);
        if (params.queryParams.idMeterPoint) {
          relatedData = relatedData.filter(med => med.idMeterPoint === +params.queryParams.idMeterPoint);
        }
        if (params.queryParams.from) {
          relatedData = relatedData.filter(med => med.timestamp >= +params.queryParams.from);
        }
        if (params.queryParams.to) {
          relatedData = relatedData.filter(med => med.timestamp <= +params.queryParams.to);
        }
        if (params.queryParams.sort) {
          switch (params.queryParams.sort) {
            case 'asc': {
              relatedData.sort((a, b) => a.timestamp - b.timestamp);
              break;
            }
            case 'desc': {
              relatedData.sort((a, b) => b.timestamp - a.timestamp);
              break;
            }
          }
        }
        const limited = relatedData.slice(+params.queryParams.offset, +params.queryParams.offset + (+params.queryParams.limit));
        return Observable.of(this.meterDataMapper.mapInterfaceToObject(limited, MeterData) as MeterData[]);
      } else {
        return this.getRequest(params, this.meterDataMapper, thisApiID, MeterData);
      }
    }
  }

  getPositionsMeterdataByIdCount(params: PositionHttpParamG = { queryParams: { limit: '50', offset: '0' } }): Observable<number> {
    return this.getPositionsMeterdataByIdSumOrCount(params, 'getPositionsMeterdataByIdCount');
  }

  getPositionsMeterdataByIdSumOrCount(params: PositionHttpParamG = { queryParams: { limit: '50', offset: '0' } }, thisApiID: string): Observable<number> {
    if (this.MOCK_SERVICE) {
      const relatedData = this.meterDataMock.filter(mes => mes.idPosition === +params.path.idPosition);
      return Observable.of(relatedData.length);
    } else {
      if (this.patchApi) {
        console.warn('Patched version of API called');
        let relatedData = this.meterDataMock.filter(mes => mes.idPosition === +params.path.idPosition);
        if (params.queryParams.idMeterPoint) {
          relatedData = relatedData.filter(med => med.idMeterPoint === +params.queryParams.idMeterPoint);
        }
        if (params.queryParams.from) {
          relatedData = relatedData.filter(med => med.timestamp >= +params.queryParams.from);
        }
        if (params.queryParams.to) {
          relatedData = relatedData.filter(med => med.timestamp <= +params.queryParams.to);
        }
        return Observable.of(relatedData.length);
      } else {
        return this.getCountRequest(params, thisApiID);
      }
    }
  }

  getPositionsAnalysisById(params: PositionHttpParams): Observable<Analysis[]> {
    const thisApiID = 'getPositionsAnalysisById';
    if (this.MOCK_SERVICE) {
      return Observable.of(this.analysisMapper.mapInterfaceToObject(this.analysisMock, Analysis) as Analysis[]);
    } else {
      if (this.patchApi) {
        console.warn('Patched version of API called');
        let relatedData = this.analysisMock;
        if (params.queryParams.idMeterPoint) {
          relatedData = relatedData.filter(med => med.idMeterPoint === +params.queryParams.idMeterPoint);
        }
        if (params.queryParams.analysedPositionId) {
          relatedData = relatedData.filter(med => med.analysedPositionId === +params.queryParams.analysedPositionId);
        }
        if (params.queryParams.algorithm) {
          relatedData = relatedData.filter(med => med.algorithm === params.queryParams.algorithm);
        }
        if (params.queryParams.anomaly) {
          relatedData = relatedData.filter(med => med.measurementDiff !== 0);
        }
        return Observable.of(this.analysisMapper.mapInterfaceToObject(relatedData, Analysis) as Analysis[]);
      } else {
        return this.getRequest(params, this.analysisMapper, thisApiID, Analysis);
      }
    }
  }

  getPositionsAnalysisByIdCount(params: PositionHttpParams): Observable<number> {
    const thisApiID = 'getPositionsAnalysisByIdCount';
    if (this.MOCK_SERVICE) {
      return of(this.analysisMock.length);
    } else {
      if (this.patchApi) {
        console.warn('Patched version of API called');
        let relatedData = this.analysisMock;
        if (params.queryParams.idMeterPoint) {
          relatedData = relatedData.filter(med => med.idMeterPoint === +params.queryParams.idMeterPoint);
        }
        if (params.queryParams.analysedPositionId) {
          relatedData = relatedData.filter(med => med.analysedPositionId === +params.queryParams.analysedPositionId);
        }
        if (params.queryParams.algorithm) {
          relatedData = relatedData.filter(med => med.algorithm === params.queryParams.algorithm);
        }
        if (params.queryParams.anomaly) {
          relatedData = relatedData.filter(med => med.measurementDiff !== 0);
        }
        return of(relatedData.length);
      } else {
        return this.getCountRequest(params, thisApiID);
      }
    }
  }

  getPositionsForecastingById(params: PositionHttpParams): Observable<Forecasting[]> {
    const thisApiID = 'getPositionsForecastingById';
    if (this.MOCK_SERVICE) {
      return Observable.of(this.forecastingMapper.mapInterfaceToObject(this.forecastingMock, Forecasting) as Forecasting[]);
    } else {
      if (this.patchApi) {
        console.warn('Patched version of API called');
        let relatedData = this.forecastingMock;
        if (params.queryParams.idMeterPoint) {
          relatedData = relatedData.filter(med => med.idMeterPoint === +params.queryParams.idMeterPoint);
        }
        if (params.queryParams.analysedPositionId) {
          relatedData = relatedData.filter(med => med.analysedPositionId === +params.queryParams.analysedPositionId);
        }
        // limited.sort((a, b) => a.timestamp - b.timestamp);
        return Observable.of(this.forecastingMapper.mapInterfaceToObject(relatedData, Forecasting) as Forecasting[]);
      } else {
        return this.getRequest(params, this.forecastingMapper, thisApiID, Forecasting);
      }
    }
  }

  getPositionsForecastingByIdCount(params: PositionHttpParams): Observable<number> {
    const thisApiID = 'getPositionsForecastingByIdCount';
    if (this.MOCK_SERVICE) {
      return of(this.forecastingMock.length);
    } else {
      if (this.patchApi) {
        console.warn('Patched version of API called');
        let relatedData = this.forecastingMock;
        if (params.queryParams.idMeterPoint) {
          relatedData = relatedData.filter(med => med.idMeterPoint === +params.queryParams.idMeterPoint);
        }
        if (params.queryParams.analysedPositionId) {
          relatedData = relatedData.filter(med => med.analysedPositionId === +params.queryParams.analysedPositionId);
        }
        return of(relatedData.length);
      } else {
        return this.getCountRequest(params, thisApiID);
      }
    }
  }
  getPositionsMeasurementsDataById(params: PositionHttpParamG = { queryParams: { limit: '50', offset: '0' } }): Observable<MeasurementData[]> {
    const thisApiID = 'getPositionsMeasurementsDataById';
    if (this.MOCK_SERVICE) {
      const relatedData = this.measurementMock.filter(mes => mes.idPosition === +params.path.idPosition);
      const limited = relatedData.slice(+params.queryParams.offset, +params.queryParams.offset + (+params.queryParams.limit));
      return Observable.of(limited.map(d => new MeasurementData(d)));
    } else {
      if (this.patchApi) {
        console.warn('Patched version of API called');
        let relatedData = this.measurementMock.filter(mes => mes.idPosition === +params.path.idPosition);
        if (params.queryParams.idMeterPoint) {
          relatedData = relatedData.filter(med => med.idMeterPoint === +params.queryParams.idMeterPoint);
        }
        const limited = relatedData.slice(+params.queryParams.offset, +params.queryParams.offset + (+params.queryParams.limit));
        return Observable.of(limited.map(d => new MeasurementData(d)));
      } else {
        return this.getRequest(params, this.measurementDataMapper, thisApiID, MeasurementData);
      }
    }
  }

  getPositionsMeasurementsDataByIdCount(params: PositionHttpParamG = { queryParams: { limit: '50', offset: '0' } }): Observable<number> {
    const thisApiID = 'getPositionsMeasurementsDataByIdCount';
    if (this.MOCK_SERVICE) {
      const relatedData = this.measurementMock.filter(mes => mes.idPosition === +params.path.idPosition);
      return Observable.of(relatedData.length);
    } else {
      if (this.patchApi) {
        console.warn('Patched version of API called');
        const relatedData = this.measurementMock.filter(mes => mes.idPosition === +params.path.idPosition);
        return Observable.of(relatedData.length);
      } else {
        return this.getCountRequest(params, thisApiID);
      }
    }
  }

  getPositionsMeterpointsById(params: PositionHttpParamG = { queryParams: { limit: '50', offset: '0' } }): Observable<MeterPoint[]> {
    const thisApiID = 'getPositionsMeterpointsById';
    if (this.MOCK_SERVICE) {
      /* FIlter out only measurements related to this position */
      const relatedData = this.measurementMock.filter(mes => mes.idPosition === +params.path.idPosition);
      const meterIds: number[] = relatedData.map(d => d.idMeterPoint);
      const uniqueMeters = meterIds.filter((item, i, ar) => ar.indexOf(item) === i);
      const meters = uniqueMeters.map(mId => {
        const idx = this.metersMock.findIndex(meterMock => meterMock.idMeterPoint === mId);
        if (idx !== -1) {
          return this.metersMock[idx];
        }
      });
      return Observable.of(this.meterPointMapper.mapInterfaceToObject(meters, MeterPoint) as MeterPoint[]);
      // throw new Error('Mocking unsupported');
    } else {
      if (this.patchApi) {
        console.warn('Patched version of API called');
        /* FIlter out only measurements related to this position */
        const relatedData = this.measurementMock.filter(mes => mes.idPosition === +params.path.idPosition);
        const meterIds: number[] = relatedData.map(d => d.idMeterPoint);
        const uniqueMeters = meterIds.filter((item, i, ar) => ar.indexOf(item) === i);
        const meters = uniqueMeters.map(mId => {
          const idx = this.metersMock.findIndex(meterMock => meterMock.idMeterPoint === mId);
          if (idx !== -1) {
            return this.metersMock[idx];
          } else {
            throw new Error('Id error');
          }
        });
        return Observable.of(this.meterPointMapper.mapInterfaceToObject(meters, MeterPoint) as MeterPoint[]);
      } else {
        return this.getRequest(params, this.meterPointMapper, thisApiID, MeterPoint);
      }
    }
  }

  getPositionsMeterpointsByIdCount(params: PositionHttpParamG = { queryParams: { limit: '50', offset: '0' } }): Observable<number> {
    const thisApiID = 'getPositionsMeterpointsByIdCount';
    if (this.MOCK_SERVICE) {
      /* FIlter out only measurements related to this position */
      const relatedData = this.measurementMock.filter(mes => mes.idPosition === +params.path.idPosition);
      /* Get unique list of meter ID's */
      const meterIds: number[] = relatedData.map(d => d.idMeterPoint);
      const uniqueMeters = meterIds.filter((item, i, ar) => ar.indexOf(item) === i);
      return of(uniqueMeters.length);
    } else {
      if (this.patchApi) {
        console.warn('Patched version of API called');
        throw new Error('Patching unsupported for this API');
      } else {
        return this.getCountRequest(params, thisApiID);
      }
    }
  }

  getPositionsCount(params: PositionHttpParamG): Observable<number> {
    const thisApiID = 'getPositionsCount';
    if (this.MOCK_SERVICE) {
      return Observable.create((observer: Observer<number>) => {
        const numbers: number[] = this.positionsMock.map((s: Position) => s.idPosition);
        const max = Math.max(...numbers);
        observer.next(max);
        observer.complete();
      });
    } else {
      return this.getCountRequest(params, thisApiID);
    }
  }
}
