import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/distinct';


import { VeeRule, IVeeRule } from '@shared/models/appModels/VeeRule.model';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { VeeRuleTypeEnum, VeeRuleSeverityEnum } from '@shared/types/modelTypes/veeRuleTypes';
import { UtilityTypeEnum } from '@shared/types';
import { DataValidationCreateNextStepComponent } from '../createNextStep/data-validation-create-next-step.component';
import { VeeRulesDataStoreService } from '@shared/services/dataStore/veeRulesDataStore';
import { InteractiveTutorialService } from '@shared/services/interactiveTutorialService.service';
import { DataValidationInteractiveTutorialService } from '../../data-validation-interactive-tutorial-steps';


@Component({
  selector: 'sv-data-validation-create',
  templateUrl: 'data-validation-create.component.html',
  styleUrls: ['data-validation-create.component.sass']
})
export class DataValidationCreateComponent implements OnInit, OnDestroy {


  occupiedNames: string[] = [];
  nextStepBtnDisbaled: boolean = false;
  ruleForm: FormGroup;
  startTutFunc;

  constructor(@Inject(MAT_DIALOG_DATA) private data: { veeRule: VeeRule },
  public dialogRef: MatDialogRef<DataValidationCreateComponent>,
  public dialogRefNextStep: MatDialogRef<DataValidationCreateNextStepComponent>,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private veeRuleService: VeeRulesDataStoreService,
    private interactiveTutorialService: InteractiveTutorialService,
    private dataValidationInteractiveTutorial: DataValidationInteractiveTutorialService
  ) {
    this.ruleForm = this.fb.group({
      'name': new FormControl(null, [Validators.required, this.forbiddenNames.bind(this)]),
      'description': new FormControl(null, [Validators.required]),
      'type': new FormControl(VeeRuleTypeEnum[VeeRuleTypeEnum.VALIDATION]), // [VeeRuleTypeEnum[VeeRuleTypeEnum.VALIDATION]],
      'recommendation': new FormControl(null, [Validators.required]),
    });
  }
  ngOnInit() {
    this.veeRuleService.getVeeRules().map(
      value => {
        let names = [];
                value.forEach(e => {
                    let name: string = '';
                    name = e.name;
                    names.push(name);
                });
                return names;
      }
    ).subscribe(
      value => {
        this.occupiedNames = value;
        this.startTutFunc = setTimeout(() => this.startInteractiveTutorial(), 1500);
    },
    );

    }

    startInteractiveTutorial(){
      const steps = this.dataValidationInteractiveTutorial.getDataValidationCreateRuleInteractiveTutorialSteps();
      this.interactiveTutorialService.startInteractiveTutorial(steps);
    }

    onSubmitNewRule($event, ruleForm) {

        const formModel = this.ruleForm.value;
        const rule: IVeeRule = {} as IVeeRule;
        rule.name = formModel.name;
        rule.recommendation = formModel.recommendation;
        rule.implementation = '';
        rule.rule_type = formModel.type;
        rule.description = formModel.description;
        rule.enabled = true;
        rule.severity = VeeRuleSeverityEnum[VeeRuleSeverityEnum.ERROR];
        rule.switchable = true;
        rule.media_type = UtilityTypeEnum[UtilityTypeEnum.OTHER];
        this.nextStepBtnDisbaled = true;
        this.dialogRefNextStep = this.dialog.open(DataValidationCreateNextStepComponent, {
          panelClass: 'dialog-overlay',
          data: {
              veeRule: rule
          },
          autoFocus: false
      });
      this.dialogRefNextStep.afterClosed().subscribe(
        value => {
            this.dialogRef.close(value);
        }
      );
      }

      forbiddenNames(control: FormControl): {[s: string]: boolean } {
        if (this.occupiedNames.indexOf(control.value) !== -1) {
            return {'nameIsOccupied': true};
        }
        return null;
      }

      ngOnDestroy(): void {
        clearTimeout(this.startTutFunc);
      }
}
