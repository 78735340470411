
export interface INavigationContext {
  schedule?: number;
  position?: number;
  file?: number;
  used?: boolean;
}

export class NavigationContext implements INavigationContext {
  schedule: number;
  position: number;
  file: number;
  used: boolean;
  constructor(iIn: INavigationContext) {
    this.schedule = iIn.schedule;
    this.position = iIn.position;
    this.file = iIn.file;
    this.used = iIn.used;
  }
 }
