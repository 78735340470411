<div class="custom-container">
<div id="summary">
<h3 i18n="@@export/custom billings modal/summary header">Summary:</h3>
<br/>

<div>
    <h4 *ngIf="meterPoints?.length!==0" i18n="@@export/custom billings modal/selected meters">Selected meter points:</h4>
    <h4 *ngIf="meterPoints?.length===0" i18n="@@export/custom billings modal/selected all meter points">Selected all meter points.</h4>
    <mat-chip-list aria-label="Meter points selection">
        <mat-chip *ngFor="let meter of meterPoints">{{meter.serialNumber}}</mat-chip>
    </mat-chip-list>
</div>
<br/>
<div>
    <h4 i18n="@@export/custom billings modal/selected variables">Selected data model variables:</h4>
    <mat-chip-list aria-label="data model variables selection">
        <mat-chip *ngFor="let variable of variables">{{variable.name}}</mat-chip>
    </mat-chip-list>
</div>
<br/>
</div>
<div class="custom-btn">
    <button id="exportBtn" mat-raised-button color="accent"
            class="btn btn-action"
            (click)="onFinalize()"
            i18n="@@export/custom billings modal/export measurements"
            >
            Export measurements
    </button>
</div>
</div>