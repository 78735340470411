import { Component } from '@angular/core';

@Component({

    // tslint:disable-next-line:component-selector
    selector: 'sv-settings-rules',
    templateUrl: 'settings-rules.component.html',
    styleUrls: ['settings-rules.component.scss']
})
export class SettingsRulesComponent {

}
