import { Component, OnDestroy } from '@angular/core';
import { OnInit } from '@angular/core';

import { CommonAppDataService } from '@shared/services/commonAppData.service';
import { InteractiveTutorialService } from '@shared/services/interactiveTutorialService.service';
import { Subscription } from 'rxjs/Subscription';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit, OnDestroy {

  activitySubs$: Subscription;
  showTutorial$: Subscription;
  currentActivity: string;

  constructor(
    private communicationService: CommonAppDataService,
    private interactiveTutorialService: InteractiveTutorialService) {
  }

  ngOnInit(): void {
    this.activitySubs$ = this.communicationService.getObservable('activity')
      .subscribe((act) => {
        this.currentActivity = act;
      });
      this.showTutorial$ = this.interactiveTutorialService.showTutorialSubject.subscribe(show => {
        this.interactiveTutorialService.showTutorialHints = show;
    });
  }


  mainAppClick(e) {
    this.sidebarHideOnClick(e);
  }

  sidebarHideOnClick(e) {
    let sidebarHoldRequest = false;
    const path = e.path || (e.composedPath && e.composedPath()) || this.composedPath(e.target);
    for (let i = 0; i < path.length; i++) {
      if (path[i].id) {
        if (path[i].id !== 'sv-app-sidebar') {
          sidebarHoldRequest = true;
          break;
        }
      }
    }
    if (!sidebarHoldRequest) {
      this.communicationService.sendMessageToSubject('sidebar', false);
    }
  }

  composedPath(el): any {
    const path = [];
    while (el) {
      path.push(el);
      if (el.tagName === 'HTML') {
        path.push(document);
        path.push(window);
        return path;
      }
      el = el.parentElement;
    }
    return path;
  }

  ngOnDestroy() {
    try {
      this.activitySubs$.unsubscribe();
    } catch (e) {

    }
    if(this.showTutorial$){
      this.showTutorial$.unsubscribe();
    }
  }

}
