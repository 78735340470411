import { environment } from "@env/environment";

import { RestEnumMpapper } from "@shared/models/RestSupport/restEnumMapper.model";
import { MeterPoint } from "@shared/models/appModels/meterPoint.model";

import {
  IGetInterfaceType,
  INameMapper,
  IParameters,
} from "@shared/types/applicationTypes";
import {
  MeasurementOriginEnum,
  VeeStatusEnum,
} from "@shared/types/modelTypes/measurementType";
import {
  getValidationResult,
  StatusFlagEnum
} from "@shared/types/modelTypes/meterDataTypes";
import {
  measurementOriginNamesMap,
  estimationStatusNamesMap,
  validationStatusNamesMap,
} from "@shared/types/modelTypes/measurementType";
import {
  statusFlagStringName,
  ValidationResultEnum,
} from "@shared/types/modelTypes/meterDataTypes";
import { BaseOptionalParamsMapper } from "@shared/models/baseOptionalParams.mode";
import { MeterData } from "@shared/models";

export interface IMeasurementData {
  idMeasurementData: string;
  idMeterPoint: number;
  idPosition: number;
  idFileMeterData?: number;
  statusFlag: StatusFlagEnum | string;
  measurementOrigin: MeasurementOriginEnum | string;
  errorCode?: string;
  measurementValues: IParameters;
  timestamp: number;
  validationResult: ValidationResultEnum | string;
  userAcceptance: boolean;
  estimation: VeeStatusEnum | string;
  validation: VeeStatusEnum | string;
  recordValid: boolean;
  idValidationRule?: number;
  idEstimationRule?: number;
}

export class MeasurementData
  extends BaseOptionalParamsMapper
  implements
    IMeasurementData,
    IGetInterfaceType<IMeasurementData, MeasurementData> {
  /* Class mappers */
  static StatusFlagMapper: RestEnumMpapper<
    typeof StatusFlagEnum
  > = new RestEnumMpapper<typeof StatusFlagEnum>();
  static OriginMapper: RestEnumMpapper<
    typeof MeasurementOriginEnum
  > = new RestEnumMpapper<typeof MeasurementOriginEnum>();
  static ValidationResultMapper: RestEnumMpapper<
    typeof ValidationResultEnum
  > = new RestEnumMpapper<typeof ValidationResultEnum>();
  static VeeStatusMapper: RestEnumMpapper<
    typeof VeeStatusEnum
  > = new RestEnumMpapper<typeof VeeStatusEnum>();

  idMeasurementData: string;
  idMeterPoint: number;
  idPosition: number;
  idFileMeterData?: number;
  statusFlag: StatusFlagEnum;
  measurementOrigin: MeasurementOriginEnum;
  errorCode?: string;
  measurementValues: IParameters;
  timestamp: number;
  validationResult: ValidationResultEnum;
  userAcceptance: boolean;
  estimation: VeeStatusEnum;
  validation: VeeStatusEnum;
  recordValid: boolean;
  idValidationRule?: number;
  idEstimationRule?: number;

  meterPointRef: MeterPoint;
  meterDataRef: MeterData;

  constructor(IMeas: IMeasurementData) {
    super();
    if (!IMeas) {
      throw new TypeError(
        "MeasurementData initialization: missing input interface as constructor argument!"
      );
    }

    this.idMeasurementData = this.assignSimpleType<string>(
      IMeas.idMeasurementData
    );

    this.idMeterPoint = this.assignSimpleType<number>(IMeas.idMeterPoint);

    this.idPosition = this.assignSimpleType<number>(IMeas.idPosition);

    this.idFileMeterData = this.assignSimpleType<number>(IMeas.idFileMeterData);

    this.statusFlag =
      typeof IMeas.statusFlag !== "undefined"
        ? this.assignStatusFlag(IMeas.statusFlag)
        : this.assignStatusFlag(StatusFlagEnum.NORMAL);

    this.measurementOrigin =
      typeof IMeas.measurementOrigin !== "undefined"
        ? this.assignMeasurementOrgin(IMeas.measurementOrigin)
        : this.assignMeasurementOrgin(MeasurementOriginEnum.FROM_FILE);

    this.errorCode = this.assignSimpleType<string>(IMeas.errorCode);

    this.measurementValues = this.getParamsToRestApi(IMeas.measurementValues);

    this.timestamp = this.assignSimpleType<number>(IMeas.timestamp);

    this.validationResult =
      typeof IMeas.validationResult !== "undefined"
        ? this.assignValidationResult(IMeas.validationResult)
        : this.assignValidationResult(ValidationResultEnum.DEFAULT);

    this.userAcceptance = this.assignSimpleType<boolean>(IMeas.userAcceptance);

    this.estimation =
      typeof IMeas.estimation !== "undefined"
        ? this.assignEstimation(IMeas.estimation)
        : this.assignEstimation(VeeStatusEnum.UNDEFINED);

    this.validation =
      typeof IMeas.validation !== "undefined"
        ? this.assignValidation(IMeas.estimation)
        : this.assignEstimation(VeeStatusEnum.UNDEFINED);

    this.recordValid = this.assignSimpleType<boolean>(IMeas.recordValid);

    this.idValidationRule = this.assignSimpleType<number>(
      IMeas.idValidationRule
    );

    this.idEstimationRule = this.assignSimpleType<number>(
      IMeas.idEstimationRule
    );
  }

  private assignValidationResult(
    eIn: ValidationResultEnum | string
  ): ValidationResultEnum {
    try {
      return MeasurementData.ValidationResultMapper.getEnumAsNumber(
        ValidationResultEnum,
        eIn
      );
    } catch (e) {
      if (environment.debug) {
        console.warn(e);
      }
      return ValidationResultEnum.DEFAULT;
    }
  }

  private assignEstimation(eIn: VeeStatusEnum | string): VeeStatusEnum {
    try {
      return MeasurementData.VeeStatusMapper.getEnumAsNumber(
        VeeStatusEnum,
        eIn
      );
    } catch (e) {
      if (environment.debug) {
        console.warn(e);
      }
      return VeeStatusEnum.UNDEFINED;
    }
  }

  private assignValidation(eIn: VeeStatusEnum | string): VeeStatusEnum {
    return this.assignEstimation(eIn);
  }

  private assignStatusFlag(eIn: StatusFlagEnum | string): StatusFlagEnum {
    try {
      return MeasurementData.StatusFlagMapper.getEnumAsNumber(
        StatusFlagEnum,
        eIn
      );
    } catch (e) {
      if (environment.debug) {
        console.warn(e);
      }
      return StatusFlagEnum.ERROR;
    }
  }

  private assignMeasurementOrgin(
    eIn: MeasurementOriginEnum | string
  ): MeasurementOriginEnum {
    try {
      return MeasurementData.OriginMapper.getEnumAsNumber(
        MeasurementOriginEnum,
        eIn
      );
    } catch (e) {
      if (environment.debug) {
        console.warn(e);
      }
      return MeasurementOriginEnum.FROM_FILE;
    }
  }

  getValidationFlagNames(): INameMapper {
    return getValidationResult(this.validationResult);
  }

  getEstiamtionFlagNameString(): string {
    return estimationStatusNamesMap[this.estimation].name;
  }

  getValidationFlagNameString(): string {
    return getValidationResult(this.validationResult).name;
  }

  getRelatedMesurements(name: string): number | undefined {
    if (this.measurementValues) {
      return +this.measurementValues[name];
    }
  }

  getAllMeasurementsDataToString() {
    if (typeof this.measurementValues !== "undefined") {
      let ret = "";
      Object.keys(this.measurementValues).forEach((v) => (ret += v));
      return ret;
    }
  }

  isRecordInvalid(): boolean {
    return this.validationResult === ValidationResultEnum.INVALID;
  }

  isRecordCorrected(): boolean {
    return (
      this.validationResult === ValidationResultEnum.AUTOCORRECTION ||
      this.estimation === VeeStatusEnum.DONE
    );
  }

  getMeasurmementData(): IParameters {
    return Object.assign(this.measurementValues, {});
  }

  getMeasurementDataSingle(key: string): number | undefined {
    try {
      if (this.isParamPresent(key, this.measurementValues)) {
        return +this.getParamByKey(key, this.measurementValues)[key];
      } else {
        return undefined;
      }
    } catch (e) {
      console.warn("Unable to parse to number parameter ", key);
      return undefined;
    }
  }

  getMeasurementOrigin(): INameMapper {
    return measurementOriginNamesMap[this.measurementOrigin];
  }

  getStatusFlag(): INameMapper {
    return statusFlagStringName[this.statusFlag];
  }

  getValidationStatus(): INameMapper {
    return validationStatusNamesMap[this.validation];
  }

  getEstimationStatus(): INameMapper {
    return estimationStatusNamesMap[this.estimation];
  }

  getValidity(): boolean {
    return this.recordValid;
  }

  getTimestamp(): number {
    return this.timestamp;
  }

  getInterface(webInterface: boolean = true): IMeasurementData {
    const emptyValue = webInterface ? null : undefined;
    return {
      idMeasurementData: this.idMeasurementData || emptyValue,
      idMeterPoint: this.idMeterPoint,
      idPosition: this.idPosition,
      idFileMeterData: this.idFileMeterData,
      statusFlag: MeasurementData.StatusFlagMapper.getEnumAsString(
        StatusFlagEnum,
        this.statusFlag
      ),
      measurementOrigin: MeasurementData.OriginMapper.getEnumAsString(
        MeasurementOriginEnum,
        this.measurementOrigin
      ),
      errorCode: this.errorCode || emptyValue,
      measurementValues: this.getParamsToRestApi(
        this.measurementValues,
        emptyValue
      ),
      timestamp: this.timestamp,
      validationResult: MeasurementData.ValidationResultMapper.getEnumAsString(
        ValidationResultEnum,
        this.validationResult
      ),
      userAcceptance: this.userAcceptance,
      estimation: MeasurementData.VeeStatusMapper.getEnumAsString(
        VeeStatusEnum,
        this.estimation
      ),
      validation: MeasurementData.VeeStatusMapper.getEnumAsString(
        VeeStatusEnum,
        this.validation
      ),
      idValidationRule: this.idValidationRule || emptyValue,
      idEstimationRule: this.idEstimationRule || emptyValue,
      recordValid: this.recordValid,
    };
  }
}
