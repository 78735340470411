
/* Enumerator to obtain correct configuration form http service configuration array */
export enum EndpointsEnum {
  'SCHEDULES' = 0,
  'POSITIONS' = 1,
  'FILE_OPERATIONAL_DATA' = 2,
  'FILE_METER_DATA' = 3,
  'CUSTOMER' = 4,
  'METER_POINT' = 5,
  'MEASUREMENT_DATA' = 6,
  'METER_DATA' = 7,
  'VEE_STATUS' = 8,
  'VEE_RULE' = 9,
  'DATA_MODEL_TYPE' = 10,
  'TIME_GROUPS' = 11,
  'PARAMETERS' = 12,
  'ANALYSIS_ALGORITHM' = 13,
  'ANALYSIS_STATUS' = 14,
  'SENSOR_DATA' = 15,
  'FILE_TEMPERATUTE_DATA' = 16,
  'DATA_IMPORT' = 17,
}
