import { BaseDataStore, GenericHttpParams } from '@shared/types';
import { ParameterHttpParamG } from '@shared/types/http/parameterHttpConfig';
import { Injectable } from '@angular/core';
import { MeasurementDataModel } from '@shared/models/appModels/measurementDataModel.model';
import { Observable } from 'rxjs/Observable';
import { MeasurementDefinitionMappingService } from '../http/measurementDefinitionMapping.service';
import { CommonAppDataService } from '../commonAppData.service';

@Injectable()
export class MeasurementDefinitionMappingDataStoreService extends BaseDataStore<GenericHttpParams<Map<string, MeasurementDataModel>>, ParameterHttpParamG> {

    constructor(private measurementDefinitionMappingService: MeasurementDefinitionMappingService,
        cs: CommonAppDataService) {
            super(cs);
    }

    protected getEmptyParams(): ParameterHttpParamG {
        const r: ParameterHttpParamG = { queryParams: {}, path: {}, body: {} };
        return r;
    }

    putMeasurementDataModel(body): Observable<any> {
        const params = this.getEmptyParams();
        params.body = body;
        return this.measurementDefinitionMappingService.putMeasurementDataModel(params);
    }

}
