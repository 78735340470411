import { Injectable } from "@angular/core";

@Injectable({providedIn: 'root'})

export class DataValidationTranslationsHelper {

    public loadEstimationDescriptionTranslation(choice: number): string {
        switch (choice) {
          case 6: {
            return $localize`:@@data validation/estimation/description/6:Estimation of measurements - mean method.`;
          }
          case 5: {
            return $localize`:@@data validation/estimation/description/5:Estimation of measurements - linear method.`;
          }
          case 7: {
            return $localize`:@@data validation/estimation/description/7:Calculation of consumption using meter overrun detection.`;
          }
        }
      }

      public loadEstimationRecommendationTranslation(choice: number): string {
        switch (choice) {
          case 6: {
            return $localize`:@@data validation/estimation/recommendation/6:Unable to calculate the consumption. Please check the measurements for such meterpoint or provide manually.`;
          }
          case 5: {
            return $localize`:@@data validation/estimation/recommendation/5:Unable to calculate the consumption. Please check the measurements for such meterpoint or provide manually.`;
          }
          case 7: {
            return $localize`:@@data validation/estimation/recommendation/7:Unable to calculate the consumption. Please check the measurements for such meterpoint or provide manually.`;
          }
        }
      }

      public loadEstimationNameTranslation(choice: number): string {
        switch (choice) {
            case 6: {
              return $localize`:@@data validation/estimation/name/6:Estimation of measurements - mean method`;
            }
            case 5: {
              return $localize`:@@data validation/estimation/name/5:Estimation of measurements - linear method`;
            }
            case 7: {
              return $localize`:@@data validation/estimation/name/7:Calculation of consumption using meter overrun detection`;
            }
          }
      }

      public loadValidationDescriptionTranslation(choice: number): string {
        switch (choice) {
            case 1: {
              return $localize`:@@data validation/validation/description/1:Lack of measurement values in current reading.`;
            }
            case 2: {
              return $localize`:@@data validation/validation/description/2:Lack of measurement values in current usage.`;
            }
            case 3: {
              return $localize`:@@data validation/validation/description/3:Current reading energy < previous reading energy.`;
            }
            case 4: {
                return $localize`:@@data validation/validation/description/4:Current usage energy < 0.`;
              }
          }
      }

      public loadValidationNameTranslation(choice: number): string {
        switch (choice) {
            case 1: {
              return $localize`:@@data validation/validation/name/1:Measurement data completness check - reading energy`;
            }
            case 2: {
              return $localize`:@@data validation/validation/name/2:Measurement data completness check - usage energy`;
            }
            case 3: {
              return $localize`:@@data validation/validation/name/3:Meterpoint measurements - measurements comparison`;
            }
            case 4: {
                return $localize`:@@data validation/validation/name/4:Meterpoint measurements - negative usage`;
              }
          }
      }

      public loadValidationRecommendationTranslation(choice: number): string {
        switch (choice) {
          case 1: {
            return $localize`:@@data validation/validation/recommendation/1:Lack of measurement values in current reading. Please provide correct measurement manually.`;
          }
          case 2: {
            return $localize`:@@data validation/validation/recommendation/2:Lack of measurement values in current usage. Please provide correct measurement manually.`;
          }
          case 3: {
            return $localize`:@@data validation/validation/recommendation/3:Current measurement seems to be incorrect. Please provide correct measurement manually.`;
          }
          case 4: {
            return $localize`:@@data validation/validation/recommendation/4:Consumption seems to be incorrect and Meterpoint has no upper bound limit. Please check all the measurements for such meterpoint.`;
          }
        }
      }

}