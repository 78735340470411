import { Component } from '@angular/core';
@Component({

  // tslint:disable-next-line:component-selector
  selector: 'sv-not-found',
  templateUrl: 'not-found.component.html',
  styleUrls: ['not-found.component.scss']
})
export class NotFoundComponent {
}
