// Angular modules import
import { Injectable } from "@angular/core";
// RjJS imports
import { Observable } from "rxjs/Observable";
import { Subject } from "rxjs/Subject";

import "rxjs/add/observable/of";
import "rxjs/add/observable/from";
import "rxjs/add/operator/map";

/* Datatypes */
import { MeasurementData } from "@shared/models/appModels/measurementData.model";

import { environment } from "@env/environment";
import { MeasurementDataService } from "@shared/services/http/measurementData.service";
import { BaseDataStore } from "@shared/types/applicationTypes";
import { GenericHttpParams } from "@shared/types/http/httpType";
import { MeasurementDataHttpParamG } from "@shared/types/http/measurementDataHttpConfig";
import { CommonAppDataService } from "@shared/services/commonAppData.service";
import { UtilityTypeEnum } from "@shared/types";

@Injectable()
export class MeasurementDataStoreService extends BaseDataStore<
  GenericHttpParams<MeasurementData>,
  MeasurementDataHttpParamG
> {
  readonly debugMode: boolean = environment.debug;

  private measurementDataSubject$: Subject<MeasurementData> = new Subject<MeasurementData>();
  private measurementDataObservable$: Observable<MeasurementData> = this.measurementDataSubject$.asObservable();

  private measurementDataSubject1$: Subject<MeasurementData[]> = new Subject<
    MeasurementData[]
  >();
  private measurementDataObservable1$: Observable<
    MeasurementData[]
  > = this.measurementDataSubject1$.asObservable();

  /* Cache tables */
  measurmeentDataCahce: MeasurementData[] = [];

  constructor(
    private measurementDataService: MeasurementDataService,
    cs: CommonAppDataService
  ) {
    super(cs);
  }

  getChangeObservable(): Observable<MeasurementData> {
    return this.measurementDataObservable$;
  }

  getAllMeasurementData(
    idPosition: number,
    svUtilityType: UtilityTypeEnum,
    limit: number = environment.defaultReqLimit,
    offset: number = 0,
    allowEmpty: boolean = true
  ): Observable<MeasurementData[]> {
    const params: MeasurementDataHttpParamG = this.getEmptyParams();
    params.queryParams.offset = "" + offset;
    params.queryParams.limit = "" + limit;
    params.queryParams.idPosition = "" + idPosition;
    params.queryParams.svUtilityType = UtilityTypeEnum[svUtilityType];
    params.config.strict = !allowEmpty;

    return this.measurementDataService.getMeasurementDataList(params);
  }

  getMeasurementDataId(
    id: string,
    allowEmpty: boolean = false
  ): Observable<MeasurementData> {
    const params: MeasurementDataHttpParamG = this.getEmptyParams();
    params.path.idMeasurementData = "" + id;
    params.config.strict = !allowEmpty;

    return this.measurementDataService.getMeasurementDataById(params);
  }

  addCMeasurementData(
    item: MeasurementData,
    options: { emitEvent: boolean } = { emitEvent: true }
  ): Observable<MeasurementData> {
    const params: MeasurementDataHttpParamG = this.getEmptyParams();
    params.body = item;
    params.queryParams.svUtilityType =
      UtilityTypeEnum[this.cs.getCurrentMediaType()];

    return this.measurementDataService
      .postMeasurementData(params)
      .do((added: MeasurementData) => {
        this.measurmeentDataCahce.push(added);
        if (options.emitEvent) {
          this.measurementDataSubject$.next(item);
        }
      });
  }

  // tslint:disable-next-line: max-line-length
  updateMeasurementData(
    items: MeasurementData[],
    allowEmpty: boolean = false,
    options: { emitEvent: boolean } = { emitEvent: true }
  ): Observable<MeasurementData[]> {
    let params: MeasurementDataHttpParamG[] = new Array<MeasurementDataHttpParamG>();

    items.forEach((i, index) => {
      let param: MeasurementDataHttpParamG = this.getEmptyParams();
      param.body = items[index];
      param.config.strict = !allowEmpty;
      param.queryParams.svUtilityType =
        UtilityTypeEnum[this.cs.getCurrentMediaType()];
      params.push(param);
    });

    return this.measurementDataService
      .putMeasurementDataById(params)
      .do((updated: MeasurementData[]) => {
        this.measurmeentDataCahce.forEach(
          (el: MeasurementData, idx: number) => {
            items.forEach((i, index) => {
              if (el.idMeasurementData === items[index].idMeasurementData) {
                this.measurmeentDataCahce[idx] = items[index];
              }
            });
          }
        );
        if (options.emitEvent) {
          this.measurementDataSubject1$.next(items);
        }
      });
  }

  // updateMeasurementData(item: MeasurementData, allowEmpty: boolean = false, options: {emitEvent: boolean} = {emitEvent: true}): Observable<MeasurementData> {
  //   const params: MeasurementDataHttpParamG = this.getEmptyParams();
  //   params.body = item;
  //   params.config.strict = !allowEmpty;
  //   params.queryParams.svUtilityType = UtilityTypeEnum[this.cs.getCurrentMediaType()];

  //   return this.mesurementDataService.putMeasurementDataById(params)
  //   .do((updated: MeasurementData) => {
  //     this.measurmeentDataCahce.forEach((el: MeasurementData, idx: number) => {
  //       if (el.idMeasurementData === item.idMeasurementData) { this.measurmeentDataCahce[idx] = item; }
  //     });
  //     if (options.emitEvent) {
  //       this.measurementDataSubject$.next(item);
  //     }
  //   });
  // }

  deleteMeasurementData(
    id: string,
    allowEmpty: boolean = false,
    options: { emitEvent: boolean } = { emitEvent: true }
  ): Observable<MeasurementData> {
    const params: MeasurementDataHttpParamG = this.getEmptyParams();
    params.path.idMeasurementData = "" + id;
    params.config.strict = !allowEmpty;

    return this.measurementDataService
      .deleteMeasurementDataById(params)
      .do((deleted: MeasurementData) => {
        const findId = this.measurmeentDataCahce.findIndex(
          (el) => el.idMeasurementData === deleted.idMeasurementData
        );
        if (findId !== -1) {
          this.measurmeentDataCahce.splice(findId, 1);
        }
        if (options.emitEvent) {
          this.measurementDataSubject$.next(deleted);
        }
      });
  }

  getMeasurementCsvFile(
    meterPointsIds: number[],
    fieldNames: string[],
    positionId: number,
    locale: string,
    startTime: number,
    endTime: number,
    includeUnits?: boolean,
    includeHeader?: boolean
  ): Observable<File> {
    const params = this.getEmptyParams();
    if(startTime !== 0 && endTime !== 0) {
    params.queryParams.from = startTime + "";
    params.queryParams.to = endTime + "";
    }
    const exportConfig = {
      meterPointIds: meterPointsIds,
      fieldNames: fieldNames,
      positionId: positionId,
      locale: locale.substr(0, 2),
      includeHeader: includeHeader ?? true,
      includeUnits: includeUnits ?? true,
    };
    params.body = exportConfig;
    return this.measurementDataService.getMeasurementCsvFile(params);
  }

  private getMeasurementDataList(params): Observable<MeasurementData[]> {
    return this.measurementDataService.getMeasurementDataList(params);
  }

  protected getEmptyParams(): MeasurementDataHttpParamG {
    const r: MeasurementDataHttpParamG = {
      body: {} as MeasurementData,
      headers: {},
      path: {},
      queryParams: {},
      config: {},
    };
    return r;
  }
}
