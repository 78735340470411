// Angular modules
import { NgModule } from "@angular/core";
import { CommonPipeModule } from "app/utils/commonPipe.module";
import { CommonModule } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// Material design controls
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatButtonModule } from "@angular/material/button";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatMenuModule } from "@angular/material/menu";
import { MatAutocompleteModule } from "@angular/material/autocomplete";

import { ScheduleComponent } from "./schedule.component";
import { ScheduleEditComponent } from "./edit/schedule-edit.component";
import { ScheduleCreateComponent } from "./create/schedule-create.component";

import { ScheduleSupportService } from "./service/scheduleSupport.service";
import { ScheduleDialogsService } from "./service/schedule-dialog.service";
import { ScheduleDetailsComponent } from "./schedule-details/schedule-details.component";
import { EventDetailsComponent } from "./schedule-details/event-details/event-details.component";

@NgModule({
  imports: [
    CommonModule,
    CommonPipeModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatMomentDateModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatExpansionModule,
    MatButtonModule,
    MatMenuModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
  ],
  declarations: [
    ScheduleComponent,
    ScheduleEditComponent,
    ScheduleCreateComponent,
    ScheduleDetailsComponent,
    EventDetailsComponent,
  ],
  exports: [
    ScheduleComponent,
    ScheduleEditComponent,
    ScheduleCreateComponent,
    ScheduleDetailsComponent,
    EventDetailsComponent,
  ],
  entryComponents: [ScheduleEditComponent, ScheduleCreateComponent],
  providers: [ScheduleSupportService, ScheduleDialogsService],
})
export class ScheduleModule {}
