export const AnalysisLocalisation = [
  {
    lang: 'ENG',
    texts: {
      page: {
        header: 'ANALYSIS ALGORITHMS',
        dataAnalysis: 'Data Analysis',
        forecasting: 'Forecasting',
        all: 'All data',
      },
      asset: {
        assetDataPreview: {
          header: 'Asset list',
          tableHeaders: {
            METER_ID: 'Meter ID',
            LOCATION: 'Location',
            MES_TYPE: 'Measurement type',
            CUSTOMER: 'Customer',
            SEGMENT: 'Segment',
            ACtiONS: 'Actions',
          },
          parametersField: {
            METER_ID: 'Meter ID',
            AGGREGATION: 'Aggregation',
            DateFrom: 'Date from',
            DateTo: 'Date to',
            DAY: 'day',
            MONTH: 'month',
            HOUR: 'hour',
            /*NEW*/
            ENERGY: 'Energy',
            WATER: 'Volume',
            PERCENTAGE: 'Deviation of the measurement value',
            METER: 'Meter:',
            AVG_TEMP: 'The average outside temperature',
            PERIOD: 'Period'
          },
          parametrsLabel: 'Parameters:',
          buttonLabel: 'Start Analysis',
          /*NEW*/
          upload: 'upload',
          restart: 'Restart analysis',
          ongoing: 'Ongoing...',
          noData: 'No data found.',
          select: 'select/unselect all',
          noTemperatures: 'No temperatures for the given date range'

        }
      },
      summary: {
        buttonLabel: 'Start new analysis',
        /*NEW*/
        showResults: 'Show Results',
        summary: 'Summary',
        userSelected: 'User Selected',
        deviation: 'Deviation above'
      },
    },
  },
  {
    lang: 'GER',
    texts: {
      page: {
        header: 'ANALYSE ALGORITHMEN',
        dataAnalysis: 'Datenanalyse',
        forecasting: 'Prognose',
        all: 'Alle Daten',
      },
      asset: {
        assetDataPreview: {
          header: 'Assetliste',
          tableHeaders: {
            METER_ID: 'Zähler ID',
            LOCATION: 'Adresse',
            MES_TYPE: 'Messart',
            CUSTOMER: 'Kunde',
            SEGMENT: 'Segment',
            ACTIONS: 'Aktionen',
          },
          parametersField: {
            METER_ID: 'Zähler ID',
            AGGREGATION: 'Anhäufung',
            DateFrom: 'Startdatum',
            DateTo: 'Enddatum',
            DAY: 'Tag',
            MONTH: 'Monat',
            HOUR: 'Stunde',
            /*NEW*/
            ENERGY: 'Energie',
            WATER: 'Volumen',
            PERCENTAGE: 'Abweichung des Messwertes',
            METER: 'Zähler:',
            AVG_TEMP: 'Die durchschnittliche Außentemperatur',
            PERIOD: 'Zeitraum'
          },
          parametrsLabel: 'Parameter:',
          buttonLabel: 'Starten Analyse',
          /*NEW*/
          upload: 'hochladen',
          restart: 'Analyse neu starten',
          ongoing: 'Laufend...',
          noData: 'Keine Daten gefunden.',
          select: 'alle auswählen/abwählen',
          noTemperatures: 'Keine Temperaturen für den angegebenen Zeitraum'
        }
      },
      summary: {
        buttonLabel: 'Starten neue Analyse',
        /*NEW*/
        showResults: 'Zeige Ergebnisse',
        summary: 'Zusammenfassung',
        userSelected: 'Hat Ausgwählt',
        deviation: 'Abweichung oben'
      },
    },
  },
  {
    lang: 'PL',
    texts: {
      page: {
        header: 'ANALIZA DANYCH',
        dataAnalysis: 'Analiza danych',
        forecasting: 'Prognozy',
        all: 'Wszystkie dane',
      },
      asset: {
        assetDataPreview: {
          header: 'Czytaj',
          tableHeaders: {
            METER_ID: 'ID Licznika',
            LOCATION: 'Lokalizacja',
            MES_TYPE: 'Rodzaj pomiaru',
            CUSTOMER: 'Klient',
            SEGMENT: 'Segment',
            ACTIONS: 'Akcje',
          },
          parametersField: {
            METER_ID: 'ID Licznika',
            AGGREGATION: 'Agregacja',
            DateFrom: 'Data od',
            DateTo: 'Data do',
            DAY: 'dzień',
            MONTH: 'miesiąc',
            HOUR: 'godzina',
            /*NEW*/
            ENERGY: 'Energia',
            WATER: 'Objętość',
            PERCENTAGE: 'Odchylenie wartości pomiaru',
            METER: 'Licznik:',
            AVG_TEMP: 'Średnia temperatura zewnętrzna',
            PERIOD: 'Okres'
          },
          parametrsLabel: 'Parametery:',
          buttonLabel: 'Rozpocznij Analizę',
          /*NEW*/
          upload: 'Przekaż plik',
          restart: 'Ponownie uruchom analizę',
          ongoing: 'W trakcie...',
          noData: 'Brak danych.',
          select: 'Wybierz/odznacz wszystkie',
          noTemperatures: 'Brak temperatur dla podanego zakresu dat'
        }
      },
      summary: {
        buttonLabel: 'Rozpocznij nową analizę',
        /*NEW*/
        showResults: 'Pokaż wyniki',
        summary: 'Podsumowanie',
        userSelected: 'Wybór Użytkownika',
        deviation: 'Odchylenie powyżej'
      },
    },
  },
];
