import { Component, OnInit, Input, AfterViewInit, ViewChild } from '@angular/core';
import { Schedule } from '@shared/models/appModels/schedule.model';
import { TableHost, NestedTableHost } from '@shared/types';
import { MatPaginator } from '@angular/material/paginator';
import { Observable } from 'rxjs/Observable';
import { PositionDataStoreService } from '@shared/services/dataStore/positionDataStore.service';
import { ScheduleDataStoreService } from '@shared/services/dataStore/scheduleDataStore.service';
import { CommonAppDataService } from '@shared/services/commonAppData.service';
import { ScheduleDetailsDataSource, TabbedPosition } from './datasource/scheduleDetailsDataSource';
import { Position } from '@shared/models/appModels/position.model';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'sv-schedule-details',
  templateUrl: './schedule-details.component.html',
  styleUrls: ['./schedule-details.component.sass'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ScheduleDetailsComponent implements OnInit, TableHost<Position>, AfterViewInit, NestedTableHost<Position> {

  @Input() inputSchedule: Schedule;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  expandedElement: Position;
  dataSource: ScheduleDetailsDataSource;
  displayedColumnsDetails: string[] = ['positionName'];

  dataLength: number;
  dataFetched: boolean;
  dataLoading: boolean;

  constructor(private cs: CommonAppDataService, private positionDsService: PositionDataStoreService, private scheduleDsService: ScheduleDataStoreService) {
    this.expandedElement = null;
    this.dataFetched = false;

  }

  ngOnInit() {
    this.dataSource = new ScheduleDetailsDataSource();
  }

  ngAfterViewInit(): void {

    this.loadData()
      .subscribe((pagedData: Position[]) => {
        this.dataSource.setNewData(pagedData);
      },
        (err) => { },
        () => { this.dataFetched = true; });
  }

  loadData(limit?: number, offset?: number, otherParams?: { [name: string]: any; }): Observable<Position[]> {
    return this.scheduleDsService.getSchedulePositions(this.inputSchedule.idSchedule);
  }

  onPaginatorEvents(paginator: MatPaginator): Observable<Position[]> {
    throw new Error('Method not implemented.');
  }
  onExternalDataChangeEvent(changeInductors: Observable<any>[]): Observable<Position[]> {
    throw new Error('Method not implemented.');
  }
  onFilterEvents(): Observable<Position[]> {
    throw new Error('Method not implemented.');
  }
  onClearFilters(): Observable<Position[]> {
    throw new Error('Method not implemented.');
  }

  dataAvailable(): boolean {
    return this.dataFetched;
  }

  isThisRowExpanded(row: TabbedPosition): boolean {
    if (this.expandedElement !== null) {
      return this.expandedElement.idPosition === row.idPosition;
    } else { /* No expanded element */
      return false;
    }
  }

  handleMainRowClick(rowElement: TabbedPosition): Position {
    if (this.expandedElement !== null
      && this.expandedElement.idPosition === rowElement.idPosition) {
      /* Same element clicked twice, hide expansion */
      this.expandedElement = null;
    } else {
      /* New element clicked, expand it */
      this.expandedElement = rowElement;
    }
    return this.expandedElement;
  }

  rowCanBeExpanded(index: number, row: TabbedPosition): boolean {
    return row.detailRow;
  }

  isThisDetailElement(row: TabbedPosition) {
    return (this.expandedElement !== null
      && this.expandedElement.idPosition === +row.idPosition);
  }

  detailsVisible(detail: TabbedPosition) {
    if (this.expandedElement !== null) {
      return detail.idPosition === this.expandedElement.idPosition;
    } else {
      return false;
    }
  }

}
